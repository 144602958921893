import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import {
  getDiscontinuedItemsReplacements,
  getDiscontinuedItemsReplacementsActionConstants,
} from 'store/items-replacements/actions';
import { IAction } from 'types/actions';

export interface IGetDiscontinuedItemsReplacementsPayload {
  skus: string;
}

export function* getDiscontinuedItemsReplacementsHandler({
  payload: { skus },
}: IAction<IGetDiscontinuedItemsReplacementsPayload>) {
  const url = `${Endpoints.CATALOG_SKUS}/${skus}`;
  yield put(doRequest(getDiscontinuedItemsReplacements, url, 'get'));
}

export default function* getDiscontinuedItemsReplacementsSaga() {
  yield takeLatest(
    getDiscontinuedItemsReplacementsActionConstants[Actions.REQUEST],
    getDiscontinuedItemsReplacementsHandler
  );
}
