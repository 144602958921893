import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ContentstackText } from 'components/contentstack';
import { CorButton } from 'components/cor-button';

import './modal-footer.scss';

interface IModalFooterProps extends PropsWithChildren {
  hasCancelButton?: boolean;
  hasCloseButton?: boolean;
  closeButtonHandler?: () => void;
  cancelButtonHandler?: () => void;
  saveButtonHandler?: () => void;
  contentstackPath?: string;
  className?: string;
}
export const ModalFooter: FC<IModalFooterProps> = ({
  hasCancelButton,
  hasCloseButton,
  contentstackPath,
  closeButtonHandler,
  cancelButtonHandler,
  className,
  children,
}) => {
  return (
    <div className={classNames('modal-footer', className)}>
      {hasCancelButton && (
        <CorButton className="text modal-footer__cancel-button" onClick={cancelButtonHandler}>
          <ContentstackText contentKey={`${contentstackPath}.cancel_button_label`} />
        </CorButton>
      )}
      {hasCloseButton && (
        <CorButton color="secondary" className="modal-footer__close-button" onClick={closeButtonHandler}>
          <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
        </CorButton>
      )}
      {children}
    </div>
  );
};
