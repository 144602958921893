import { createSelector } from 'reselect';
import { IState } from '../root-reducer';
import { IInternalUserQuotesState, IQuotesState } from './reducer';

export const selectQuotesEntity = (state: IState): IQuotesState => state.app.quotes;

export const selectSkuInfo = createSelector(selectQuotesEntity, (quotes) => quotes.skuInfo);
export const selectSkuInfoLoading = createSelector(selectSkuInfo, ({ skuInfoLoading }) => skuInfoLoading);
export const selectSkuInfoDetails = createSelector(selectSkuInfo, ({ skuDetails }) => skuDetails);
export const selectCustomerQuotes = createSelector(selectQuotesEntity, (quotes) => quotes.customerQuotes);
export const selectCustomerQuotesList = createSelector(selectCustomerQuotes, ({ quotes }) => quotes);
export const selectCustomerQuotesLoading = createSelector(selectCustomerQuotes, ({ loading }) => loading);

export const selectQuoteOrderSummary = createSelector(selectQuotesEntity, ({ quoteOrderSummary }) => quoteOrderSummary);

export const getInternalUserQuotesEntity = createSelector(
  selectQuotesEntity,
  ({ internalUserQuotes }): IInternalUserQuotesState => internalUserQuotes
);

export const selectApproveQuoteForm = createSelector(
  getInternalUserQuotesEntity,
  ({ approveQuoteForm }) => approveQuoteForm
);

export const selectActiveQuoteDeliveryDate = createSelector(
  getInternalUserQuotesEntity,
  ({ selectedQuote }) => selectedQuote.estimatedDeliveryDates
);

export const selectQuotesPaginationCurrent = createSelector(
  getInternalUserQuotesEntity,
  ({ offset, limit }) => offset / limit
);

export const selectQuotesPaginationTotal = createSelector(getInternalUserQuotesEntity, ({ total, limit }) =>
  Math.ceil(total / limit)
);

export const selectQuotesRequested = createSelector(getInternalUserQuotesEntity, ({ requested }) => requested);
