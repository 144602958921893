import { FormikErrors } from 'formik';
import { SchemaOf, ValidationError } from 'yup';
import { ICustomerLoginValues } from 'components/forms/customer-login-form';
import { IAccountRetrievalValues } from 'features/customer-registration-form/account-retrieval-form';
import { IOptOutFormValues } from 'pages/do-not-sell-my-info/components/opt-out-form';
import { ICsrChangeEmailFormValues } from 'pages/dashboard/components/customer-locations-item/components/change-email-address-form';
import { IAddCreditCardValues } from 'components/forms/add-payment-card-form';
import { IBankAccountFormValues } from 'components/modals/add-new-bank-account-modal';
import { IMakeDepositValues } from 'components/modals/make-deposit-modal';

export const validateFormik = (
  values:
    | ICustomerLoginValues
    | IAccountRetrievalValues
    | IOptOutFormValues
    | ICsrChangeEmailFormValues
    | IAddCreditCardValues
    | IBankAccountFormValues
    | IMakeDepositValues,
  ValidationSchema: SchemaOf<object, any>,
  setHasErrors: (hasErrors: boolean) => void
) => {
  let errors = {};
  setHasErrors(false);
  try {
    ValidationSchema.validateSync(values, { abortEarly: false });
  } catch (error) {
    setHasErrors(true);
    errors = (error as ValidationError).inner;
  }

  return errors;
};

export const filterFormikErrors = (errors: FormikErrors<any>, errorKey: string) => {
  const { [errorKey]: deletedKey, ...otherKeys } = errors;
  return otherKeys;
};
