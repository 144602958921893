import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { deleteShoppingListItem, deleteShoppingListItemActionConstants } from 'store/shopping-lists/actions';
import { IAction } from 'types/actions';

export interface IDeleteShoppingListItemSagaHandlerPayload {
  sku: string;
  shoppingListId: string;
  filteredLineItems?: string[];
}

export function* deleteShoppingListItemSagaHandler({ payload }: IAction<IDeleteShoppingListItemSagaHandlerPayload>) {
  const { shoppingListId, sku, filteredLineItems } = payload;
  yield put(
    doRequest(deleteShoppingListItem, `${Endpoints.SHOPPING_LISTS}/${shoppingListId}/items/${sku}`, 'delete', {
      filteredLineItems,
    })
  );
}

export default function* deleteShoppingListItemSaga() {
  yield takeLatest(deleteShoppingListItemActionConstants[Actions.REQUEST], deleteShoppingListItemSagaHandler);
}
