import qs from 'qs';
import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getDeliveryDates, getDeliveryDatesConstants } from 'store/quotes/actions';
import { IAction } from 'types/actions';

export interface IGetDeliveryDatesPayload {
  locationId: string;
  quoteExpireDate: string;
}

export function* getDeliveryDatesHandler({
  payload: { locationId, quoteExpireDate },
}: IAction<IGetDeliveryDatesPayload>) {
  const url = `${Endpoints.DASHBOARD_QUOTES}/delivery-dates?${qs.stringify(
    {
      locationId,
      quoteExpireDate,
    },
    { skipNulls: true }
  )}`;

  yield put(doRequest(getDeliveryDates, url, 'get'));
}

export default function* getDeliveryDatesSaga() {
  yield takeLatest(getDeliveryDatesConstants[Actions.REQUEST], getDeliveryDatesHandler);
}
