import { FC, CSSProperties, PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import './sticky-view.scss';

interface IStickyViewProps extends PropsWithChildren {
  offsetTop?: number;
  className?: string;
  disableSticky?: boolean;
  dynamicOffset?: number;
  style?: CSSProperties;
}

export const StickyView: FC<IStickyViewProps> = ({
  children,
  className,
  offsetTop = 0,
  disableSticky = false,
  dynamicOffset = 0,
  style,
}) => {
  const stickyViewRef = useRef(null) as RefObject<HTMLDivElement>;
  const contentRef = useRef(null) as RefObject<HTMLDivElement>;
  const [sticky, setSticky] = useState(false);
  const stickyViewClassName = classNames(
    'sticky-view',
    {
      'sticky-view--sticky': sticky,
    },
    className
  );

  const handlePageScroll = () => {
    let currentOffsetTop = 0;
    let pageScrollTop = window.scrollY;

    if (stickyViewRef.current) {
      currentOffsetTop = stickyViewRef.current.getBoundingClientRect().top - offsetTop;
    }

    if (!disableSticky && stickyViewRef.current && contentRef.current && pageScrollTop > 0 && currentOffsetTop < 0) {
      stickyViewRef.current.style.height = `${stickyViewRef.current.offsetHeight}px`;
      contentRef.current.style.top = `${offsetTop}px`;
      setSticky(true);
    } else if (stickyViewRef.current) {
      stickyViewRef.current.style.height = 'auto';
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll);
    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    };
  });

  useEffect(() => {
    handlePageScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableSticky, dynamicOffset, offsetTop]);

  return (
    <div className={stickyViewClassName} ref={stickyViewRef} style={style}>
      <div className="sticky-view__content" ref={contentRef}>
        {children}
      </div>
    </div>
  );
};
