import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { IPersonalInformation } from 'types/user-info';
import { updatePersonalInfo, updatePersonalInfoActionConstants } from '../actions';

export function* updatePersonalInfoSagaHandler({ payload }: IAction<IPersonalInformation>) {
  yield put(
    doRequest<IPersonalInformation>(updatePersonalInfo, Endpoints.UPDATE_ACCOUNT_PERSONAL_INFO, 'put', payload)
  );
}

export default function* updatePersonalInfoSaga() {
  yield takeLatest(updatePersonalInfoActionConstants[Actions.REQUEST], updatePersonalInfoSagaHandler);
}
