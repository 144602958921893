import { takeLatest, select, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { orderBy } from 'lodash-es';
import qs from 'qs';
import searchProductsActionTypes from 'store/search-products/action-types';
import {
  productsEntitySelector,
  selectSearchProductsSelectedCategories,
  selectSearchProductsSelectedFacets,
  selectTemporarySearchProductsSelectedCategories,
  selectTemporarySearchSelectedFacets,
} from 'store/search-products/selectors';
import { isEqualSelectedFacets, mapSelectedFacetsToSearchParams } from 'utils/facet-utils';
import { setSearchTemporarySelectedFilters, setSearchSelectedFilters } from 'store/search-products/actions';

export function* initSearchTemporaryFiltersSagaHandler() {
  const searchProductsStore = yield select(productsEntitySelector);
  yield put(setSearchTemporarySelectedFilters(searchProductsStore));
}

export function* confirmSearchTemporaryFiltersSagaHandler() {
  const searchProductsStore = yield select(productsEntitySelector);
  const selectedFacets = yield select(selectTemporarySearchSelectedFacets);
  const selectedCategories = yield select(selectTemporarySearchProductsSelectedCategories);
  const prevSelectedFacets = yield select(selectSearchProductsSelectedFacets);
  const prevSelectedCategories = yield select(selectSearchProductsSelectedCategories);
  const wasFacetsChanged = !isEqualSelectedFacets(selectedFacets, prevSelectedFacets);
  const wasCategoriesChanged =
    JSON.stringify(orderBy(selectedCategories)) !== JSON.stringify(orderBy(prevSelectedCategories));

  const routerLocation = yield select((state) => state.router.location);
  const updatedSelectedFacetsSearchParams = mapSelectedFacetsToSearchParams(selectedFacets);

  const updatedMeta = {
    offset: wasFacetsChanged || wasCategoriesChanged ? 0 : searchProductsStore.searchMeta.offset,
  };

  const searchParams = qs.parse(routerLocation.search, { ignoreQueryPrefix: true });

  const updatedSearchParams = {
    ...searchParams,
    query: searchParams.query,
    categories: selectedCategories,
    facets: updatedSelectedFacetsSearchParams,
    offset: wasFacetsChanged || wasCategoriesChanged ? undefined : searchProductsStore.searchMeta.offset,
  };

  yield put(
    setSearchSelectedFilters({
      ...searchProductsStore,
      selectedFacets,
      categories: searchProductsStore.temporaryCategories,
      facets: searchProductsStore.temporaryFacets,
      meta: { ...searchProductsStore.searchMeta, ...updatedMeta },
    })
  );
  yield put(
    push({
      ...routerLocation,
      search: qs.stringify(updatedSearchParams),
    })
  );
}

export default function* updateSearchTemporaryFiltersSaga() {
  yield takeLatest(
    searchProductsActionTypes.INIT_SEARCH_TEMPORARY_SELECTED_FILTERS,
    initSearchTemporaryFiltersSagaHandler
  );
  yield takeLatest(
    searchProductsActionTypes.CONFIRM_SEARCH_TEMPORARY_SELECTED_FILTERS,
    confirmSearchTemporaryFiltersSagaHandler
  );
}
