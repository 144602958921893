import './cor-two-column.scss';

import classNames from 'classnames';
import { CorTypography } from 'corporate-ui';
import React, { FC } from 'react';
import { ICSCorButton, ICSCorImage, ICSCorVideo } from 'corporate-interfaces';
import { CorContentColumn } from 'corporate-components/cor-two-column/components/cor-content-column';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { useContent } from 'hooks/use-content';
import { ContentstackContext } from 'context/contentstack';

export type ContainerBgColor = 'White' | 'Light Tan';

export type ColumnType = '50/50' | 'Inset' | 'CTA';

export enum ColumnTypeMapper {
  '50/50' = 'fifty-fifty',
  'Inset' = 'inset',
  'CTA' = 'cta',
}

export interface ICSTwoColumnContentReference {
  container_background_color: ContainerBgColor;
  description: string;
  headline: string;
  two_column_content: ICSTwoColumnContent[];
}

export interface ICSTwoColumnContent {
  two_column_type: ColumnType;
  right_column: ICSCorColumnContent;
  left_column: ICSCorColumnContent;
  include_vertical_rule: boolean;
}

export interface ICSCorColumnContent {
  icon_text_group?: {
    button?: ICSCorButton;
    text?: string;
    icon?: {
      title?: string;
      url?: string;
    };
  };
  text?: string;
  image?: ICSCorImage;
  video?: ICSCorVideo;
}

export interface ICSCorTwoColumnData {
  container_background_color: ContainerBgColor;
  two_column_content_reference: ICSTwoColumnContentReference[];
}

export const CorTwoColumn: FC = () => {
  const { getContentByKey } = useContent();
  const data: ICSCorTwoColumnData = getContentByKey('two_column_content', {} as ICSCorTwoColumnData);

  const headline = data?.two_column_content_reference?.[0]?.headline || '';
  const description = data?.two_column_content_reference?.[0]?.description || '';
  const entryBackgroundColor = data?.two_column_content_reference?.[0]?.container_background_color || '';
  const containerColorCssModifier = `cor-two-column--${(
    (data?.container_background_color || entryBackgroundColor)?.replace(' ', '-') || 'white'
  ).toLowerCase()}`;
  const contentInstances = data?.two_column_content_reference?.[0]?.two_column_content || [];

  if (!data && !contentInstances.length) {
    return null;
  }

  return (
    <div className={classNames('cor-two-column', containerColorCssModifier)}>
      <div className={'grid-container'}>
        {(headline || description) && (
          <div className={'cor-two-column__head'}>
            <CorTypography className={'cor-two-column__headline'} variant={'h2'} component={'div'}>
              {headline}
            </CorTypography>
            {description && (
              <div className="cor-two-column__rich-text cor-rich-text">
                <CorContentstackHtml contentKey={'two_column_content.two_column_content_reference[0].description'} />
              </div>
            )}
          </div>
        )}

        {contentInstances.length > 0 &&
          contentInstances.map((instance, index) => {
            const { two_column_type, left_column, right_column, include_vertical_rule } = instance;
            const variant = ColumnTypeMapper[two_column_type];
            return (
              <div
                key={index.toString()}
                className={classNames('cor-two-column__container', `cor-content-${variant}__container`)}
              >
                {left_column && (
                  <ContentstackContext.Provider value={left_column}>
                    <CorContentColumn column={left_column} variant={variant} position={'left'} />
                  </ContentstackContext.Provider>
                )}

                {include_vertical_rule && two_column_type === 'CTA' && left_column && right_column && (
                  <div className="cor-two-column__separator" />
                )}

                {right_column && (
                  <ContentstackContext.Provider value={right_column}>
                    <CorContentColumn column={right_column} variant={variant} position={'right'} />
                  </ContentstackContext.Provider>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
