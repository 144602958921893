import { useEffect, useState } from 'react';

export const useEnvironment = () => {
  const [isProduction, setIsProduction] = useState(false);
  const [isDevelopment, setIsDevelopment] = useState(false);

  useEffect(() => {
    let result = /\/\/(?:[\w-]+(?:qa|local|dev|test|stage)|localhost[\w-]*)/.test(window.location.href);
    setIsDevelopment(result);
    setIsProduction(!result);
  }, []);

  return {
    isDevelopment,
    isProduction,
  };
};
