import {
  subDays,
  subMonths,
  startOfDay,
  startOfMonth,
  startOfTomorrow,
  subYears,
  addMonths,
  getYear,
  addDays,
  getMonth,
  getDate,
} from 'date-fns';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { SHOP_LANGUAGES } from 'constants/shop-locales';

export const getDaysRange = (days: number) => {
  const dateRangeTo = zonedTimeToUtc(startOfTomorrow(), 'UTC').toISOString();
  const dateRangeFrom = zonedTimeToUtc(startOfDay(subDays(startOfTomorrow(), days)), 'UTC').toISOString();

  return {
    dateTimeFrom: dateRangeFrom,
    dateTimeTo: dateRangeTo,
  };
};

export const getDaysInterval = (days: number) => {
  const dateRangeFrom = zonedTimeToUtc(startOfTomorrow(), 'UTC').toISOString();
  const dateRangeTo = zonedTimeToUtc(startOfDay(addDays(new Date(), days)), 'UTC').toISOString();

  return {
    dateTimeFrom: dateRangeFrom,
    dateTimeTo: dateRangeTo,
  };
};

export const getLastMonthsRange = (month: number) => {
  const dateRangeTo = zonedTimeToUtc(startOfTomorrow(), 'UTC').toISOString();
  const dateRangeFrom = zonedTimeToUtc(startOfDay(subMonths(startOfTomorrow(), month)), 'UTC').toISOString();

  return {
    dateTimeFrom: dateRangeFrom,
    dateTimeTo: dateRangeTo,
  };
};

export const getThisMonthLastYearRange = () => {
  const firstDay = zonedTimeToUtc(subYears(startOfTomorrow(), 1), 'UTC').toISOString();
  const lastDay = zonedTimeToUtc(startOfDay(startOfMonth(addMonths(new Date(firstDay), 1))), 'UTC').toISOString();

  return {
    dateTimeFrom: firstDay,
    dateTimeTo: lastDay,
  };
};

export const getFormattedDeliveryDate = (
  expectedDate: string,
  short: boolean = false,
  showShortDays: boolean = false,
  showLongMonths: boolean = false
) => {
  const transformedExpectedDate = expectedDate.replace(/-/g, '/');
  const deliveryDate = new Date(transformedExpectedDate);

  const monthFormat = showLongMonths ? 'MMMM' : 'MMM';
  const dayFormat = showShortDays ? 'E.' : 'EEEE';

  return expectedDate
    ? short
      ? format(deliveryDate, `${monthFormat} d, yyyy`)
      : format(deliveryDate, `${dayFormat} ${monthFormat} d, yyyy`)
    : '';
};

export const getFormattedDateWithoutYear = (expectedDate: string) =>
  expectedDate ? format(new Date(expectedDate.replace(/-/g, '/')), 'eeee MMM d') : '';

export const getFormattedExpireDate = (date: Date) =>
  date ? format(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()), 'MM/dd/yy') : '';

export const getReformattedExpireDate = (date: Date) => (date ? format(date, 'yyyy-MM-dd') : '');

export const formatDate = (date: Date) => (date ? format(date, 'M/d/yyyy') : '');

export const formatDateLocalized = (
  date: string,
  months: { [key: string]: string },
  locale: SHOP_LANGUAGES = SHOP_LANGUAGES.ENGLISH_UNITED_STATES
) => {
  const dateTransformed = new Date(date.replace(/-/g, '/'));

  const monthNumber = getMonth(dateTransformed);
  const month = (months[`month_${monthNumber}`] ?? format(dateTransformed, 'MMM')).toUpperCase();
  const year = getYear(dateTransformed);
  const day = getDate(dateTransformed);

  return locale === SHOP_LANGUAGES.FRENCH_CANADA ? `${day} ${month}, ${year}` : `${month} ${day}, ${year}`;
};

export const getTomorrowsDate = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const year = tomorrow.getFullYear();
  const month = (tomorrow.getMonth() + 1).toString().padStart(2, '0');
  const day = tomorrow.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};
