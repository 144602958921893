import { all, spawn } from 'redux-saga/effects';
import addCreditCardSaga from './add-credit-card';
import getPaymentMethodsSaga from './get-payment-methods';
import addNewBankAccountSaga from './add-new-bank-account';
import deleteCreditCardSaga from './delete-credit-card';
import removeBankAccountSaga from './remove-bank-account';
import addNewBankAccountEftPadSaga from './add-new-bank-account-eft-pad';
import removeBankAccountEftPadSaga from './remove-bank-account-eft-pad';

export default function* paymentMethodsSagas() {
  yield all([
    spawn(addCreditCardSaga),
    spawn(getPaymentMethodsSaga),
    spawn(deleteCreditCardSaga),
    spawn(addNewBankAccountSaga),
    spawn(removeBankAccountSaga),
    spawn(removeBankAccountEftPadSaga),
    spawn(addNewBankAccountEftPadSaga),
  ]);
}
