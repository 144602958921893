import './cor-filter-drawer-opener-button.scss';

import React from 'react';
import { IconWithHover } from 'components/icon-with-hover';
import iconFiltersBlack from 'assets/images/icons/icon-filters-black.svg';
import { useContent } from 'hooks/use-content';

const PREFIX_KEY = 'content_list.content_or_filtered_list.0';
const LABELS_KEY = `${PREFIX_KEY}.labels.0.key_value_pair.value`;

const CorFilterDrawerOpenerButton = ({ onToggleDrawer = () => {} }) => {
  const { getLabelsByKey } = useContent();
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});

  return (
    <button className="cor-filter-drawer-opener-button" onClick={onToggleDrawer}>
      {labels?.filters || 'Filters'}{' '}
      <IconWithHover icon={iconFiltersBlack} hoverIcon={iconFiltersBlack} alt="Filters Icon" />
    </button>
  );
};

export default React.memo(CorFilterDrawerOpenerButton);
