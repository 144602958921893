import { combineReducers } from 'redux';
import { Actions } from 'constants/actions.enum';
import { ILocation } from 'types/locations';
import { submitLoginActionConstants, getLocationsActionConstants } from 'store/customer-login/actions';
import { validateRegistrationTokenActionConstants } from 'store/customer-registration/actions';
import { createReducer } from 'store/reducer-creator';
import { IFormWithServerErrors } from './actions';
import types from './action-types';

export interface IClientState extends IFormWithServerErrors {
  isClientIdle: boolean;
  loginAttemptCounter: number;
}

export interface ICustomerLoginState {
  customerLoginForm: IClientState;
  customerLocations: ILocation[];
}

const customerLoginFormInitialState = {
  isSubmitting: false,
  serverErrorId: '',
  isClientIdle: false,
  loginAttemptCounter: 0,
};

export const customerLoginInitialState: ICustomerLoginState = {
  customerLoginForm: customerLoginFormInitialState,
  customerLocations: [],
};

export const customerLoginForm = createReducer<IClientState>(
  {
    [submitLoginActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isSubmitting: true,
      serverErrorId: '',
    }),
    [submitLoginActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
      loginAttemptCounter: 0,
    }),
    [submitLoginActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: error.errorMessageId || '',
      loginAttemptCounter: error.loginAttemptCounter,
    }),
    [types.RESET_LOGIN_ATTEMPT_COUNTER]: (state) => ({
      ...state,
      loginAttemptCounter: 0,
    }),
    [types.CLEAR_SERVER_ERRORS]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
    }),
    [validateRegistrationTokenActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: error.errorMessageId || '',
    }),
    [types.SET_CLIENT_IDLE_STATE]: (state, { payload: { idle } }) => ({
      ...state,
      isClientIdle: idle,
    }),
  },
  customerLoginFormInitialState
);

export const customerLocations = createReducer<ILocation[]>(
  {
    [submitLoginActionConstants[Actions.SUCCESS]]: (state, { payload }) => payload.customerLocations,
    [getLocationsActionConstants[Actions.SUCCESS]]: (state, { payload }) => payload.customerLocations,
  },
  []
);

export default combineReducers({
  customerLoginForm,
  customerLocations,
});
