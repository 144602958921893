import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getLocationsByMsrIdForMsm, getLocationsByMsrIdForMsmConstants } from 'store/dashboard/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IMsrListByNamePayload {
  msrId: string;
}

export function* getLocationsByMsrIdForMsmHandler({ payload: { msrId } }: IAction<IMsrListByNamePayload>) {
  const query = excludeSpecialCharacters(msrId?.trim());

  yield put(doRequest(getLocationsByMsrIdForMsm, `${Endpoints.DASHBOARD_MSM_MSR_SEARCH}/${query}/locations`, 'get'));
}

export default function* getLocationsByMsrIdForMsmHandlerSaga() {
  yield takeLatest(getLocationsByMsrIdForMsmConstants[Actions.REQUEST], getLocationsByMsrIdForMsmHandler);
}
