import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Routes } from 'constants/routes.enum';
import { stripLeadingZeros } from 'utils/string-format';
import { formatCurrency } from 'utils/currency-format';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { IPriceModel } from 'types/product';
import { HierarchyAccountsSpecialOrderMessage } from 'components/hierarchy-accounts-special-order-message';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { selectIsKeyAccountUser } from 'store/auth/selectors';

import './discontinued-item-info.scss';

export interface IDiscontinuedItemInfoProps {
  contentstackPath: string;
  name: string;
  sku: string;
  productKey: string;
  price?: IPriceModel;
  isReplacementSpecial?: boolean;
  isRenderStatus?: boolean;
  renderStatus: () => ReactNode;
}

export const DiscontinuedItemInfo: FC<IDiscontinuedItemInfoProps> = ({
  contentstackPath,
  name,
  sku,
  productKey,
  price,
  isReplacementSpecial,
  renderStatus,
  isRenderStatus = true,
}) => {
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);
  const { isMobile } = useBreakpoint();

  return (
    <div className="discontinued-item-info">
      <div className="discontinued-item-info__info">
        <p className="discontinued-item-info__name">{name}</p>
        <div className="discontinued-item-info__info-wrapper">
          {isRenderStatus && <div className="discontinued-item-info__status">{renderStatus()}</div>}
          <Link className="discontinued-item-info__number" to={`${Routes.ProductDetailsPage}/${productKey}`}>
            <ContentstackText contentKey={`${contentstackPath}.sku_number_label`} /> {stripLeadingZeros(sku)}
          </Link>
        </div>
        {isKeyAccountUser && isReplacementSpecial && isMobile && <HierarchyAccountsSpecialOrderMessage />}
        {price && (
          <p className={classNames('discontinued-item-info__price', { 'price-status': isRenderStatus })}>
            {formatCurrency(price)}
          </p>
        )}
      </div>
    </div>
  );
};
