enum invoicesActionTypes {
  GET_INVOICES = 'GET_INVOICES',
  GET_SURCHARGE = 'GET_SURCHARGE',
  GET_INVOICES_SUMMARY = 'GET_INVOICES_SUMMARY',
  SET_SELECTED_INVOICES = 'SET_SELECTED_INVOICES',
  SET_INVOICE_CHANGE_REASON = 'SET_INVOICE_CHANGE_REASON',
  UPDATE_CONFIRMED_BALANCE = 'UPDATE_CONFIRMED_BALANCE',
  SET_DEFAULT_CONFIRMED_BALANCE = 'SET_DEFAULT_CONFIRMED_BALANCE',
  INVOICES_PAGINATION_CHANGE = 'INVOICES_PAGINATION_CHANGE',
  RESET_PAYMENT_SUMMARY_CALCULATIONS = 'RESET_PAYMENT_SUMMARY_CALCULATIONS',
  SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD',
  GET_INVOICES_TOTAL_AMOUNT = 'GET_INVOICES_TOTAL_AMOUNT',
}

export default invoicesActionTypes;
