import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getLocations, getLocationsActionConstants } from 'store/customer-login/actions';

export function* getLocationsSagaHandler() {
  yield put(doRequest(getLocations, Endpoints.CUSTOMER_LOCATIONS, 'get'));
}

export default function* getLocationsSaga() {
  yield takeLatest(getLocationsActionConstants[Actions.REQUEST], getLocationsSagaHandler);
}
