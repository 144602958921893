import { FC, PropsWithChildren } from 'react';
import { BackToTop } from 'components/back-to-top';
import 'corporate-components/cor-layout/cor-page-layout.scss';

export interface ICorpPageLayout extends PropsWithChildren {
  header: FC;
  footer: FC;
  classModifier?: string;
}

export const CorPageLayout: FC<ICorpPageLayout> = ({
  header: Header,
  children: main,
  footer: Footer,
  classModifier,
}) => {
  return (
    <div
      className={`cor-page-layout ${classModifier ? 'cor-page-layout--' + classModifier : ''} grid-y`}
      data-testid="page-layout"
    >
      <Header />
      <main className="cor-page-layout__main">{main}</main>
      <footer className="cor-page-layout__footer">
        <Footer />
      </footer>
      <BackToTop />
    </div>
  );
};
