import { IAlgoliaProps, useAlgoliaQuery } from 'queries/use-algolia-query';
import React from 'react';

export const ALGOLIA_DEFAULT_PAGE_TYPE = 'all';
export const ALGOLIA_DEFAULT_PAGE_NUMBER = 1;
export const ALGOLIA_DEFAULT_PAGE_OFFSET = 12;

const useAlgolia = ({
  page = ALGOLIA_DEFAULT_PAGE_NUMBER,
  pageType = ALGOLIA_DEFAULT_PAGE_TYPE,
  query = '',
  facets = {},
  pageOffset = ALGOLIA_DEFAULT_PAGE_OFFSET,
  isTotalShopProductsCounted = false,
}) => {
  const algoliaParams: IAlgoliaProps = React.useMemo(() => {
    const limit = pageOffset;
    const offset = pageOffset * (page - 1);
    const categoryKeys = [ALGOLIA_DEFAULT_PAGE_TYPE].includes(pageType) ? [] : [pageType];
    const facetsFormatted = Object.keys(facets)?.reduce((acc: { key: string; values: string[] }[], curr: string) => {
      if (!JSON.parse(facets?.[curr] || '[]')?.length) return [...acc];

      return [...acc, { key: curr, values: JSON.parse(facets?.[curr]) }];
    }, []);

    return {
      limit,
      offset,
      text: query,
      facets: facetsFormatted,
      filters: [],
      sortOptions: {},
      categoryKeys,
      pageOffset,
      isTotalShopProductsCounted,
    };
  }, [facets, isTotalShopProductsCounted, page, pageOffset, pageType, query]);

  const algoliaQuery = useAlgoliaQuery(algoliaParams);

  return algoliaQuery;
};

export default useAlgolia;
