import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { addNewBankAccountEftPadActionConstants, addNewBankAccountEftPad } from 'store/payment-methods/actions';

export interface IAddNewBankAccountEftPadPayload {
  accountNumber: string;
  accountType: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  paymentConsent: true;
  routingNumber: string;
  state: string;
  zip: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: (messageId: string) => void;
}

export function* addNewBankAccountEftPadOnSuccessSagaHandler({ payload }: IAction<IAddNewBankAccountEftPadPayload>) {
  yield take(addNewBankAccountEftPadActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* addNewBankAccountEftPadOnFailSagaHandler({ payload }: IAction<IAddNewBankAccountEftPadPayload>) {
  const { error } = yield take(addNewBankAccountEftPadActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack && error) {
    payload.onFailCallBack(error.errorMessageId);
  }
}

export function* addNewBankAccountEftPadSagaHandler({ payload }: IAction<IAddNewBankAccountEftPadPayload>) {
  yield put(
    doRequest<IAddNewBankAccountEftPadPayload>(
      addNewBankAccountEftPad,
      Endpoints.BANK_ACCOUNTS_EFT_PAD,
      'post',
      payload
    )
  );
}

export default function* addNewBankAccountSaga() {
  yield takeLatest(
    addNewBankAccountEftPadActionConstants[Actions.REQUEST],
    addNewBankAccountEftPadOnSuccessSagaHandler
  );
  yield takeLatest(addNewBankAccountEftPadActionConstants[Actions.REQUEST], addNewBankAccountEftPadOnFailSagaHandler);
  yield takeLatest(addNewBankAccountEftPadActionConstants[Actions.REQUEST], addNewBankAccountEftPadSagaHandler);
}
