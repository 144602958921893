import './cor-footer-mobile.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { Routes } from 'constants/routes.enum';
import { useContent } from 'hooks/use-content';
import {
  selectIsAuthorised,
  selectIsInvoiceOnlyCustomer,
  selectIsTsrUser,
  selectIsUserCountryCanada,
} from 'store/auth/selectors';
import { useGetUserRole } from 'hooks/use-get-user-role';
import { CorFooterNewsletter } from '../cor-footer/components';
import { CorFooterAccordion, CorFooterLegal, CorFooterShopProducts, CorFooterSocialNetworks } from './components';

const MENU_LINKS_KEY = 'footer_link_group.footer_link_set.menu_link';
const SHOP_PRODUCTS_CTA_KEY = 'shop_products_cta';
const ACCOUNT_CTA_KEY = 'account_cta';

const MobileCorporateFooterVariant = ({ onLogout = (e: React.SyntheticEvent<HTMLAnchorElement>) => {} }) => {
  const { getContentByKey } = useContent();
  const menuLinks = getContentByKey(MENU_LINKS_KEY, []);
  const accountCTA: any = getContentByKey(ACCOUNT_CTA_KEY, {});
  const shopProductsCTA: any = getContentByKey(SHOP_PRODUCTS_CTA_KEY, {});
  const isTsrUser = useSelector(selectIsTsrUser);
  const isUserLoggedIn = useSelector(selectIsAuthorised);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const isUserCountryCanada = useSelector(selectIsUserCountryCanada);
  const { isSuperAdminOrAdmin } = useGetUserRole();

  return (
    <div className="cor-footer-mobile">
      <CorFooterSocialNetworks />

      <ul className="cor-footer-mobile__menu-links">
        {menuLinks?.map((menuLink: any, key) => {
          if (
            (isTsrUser || (!isSuperAdminOrAdmin && !isUserCountryCanada && !isInvoiceOnlyUser && isUserLoggedIn)) &&
            menuLink?.menu_section_title?.href.includes(Routes.AccountPayments)
          ) {
            return null;
          }

          return (
            <li className="cor-footer-mobile__menu-link" key={key.toString()}>
              <CorFooterAccordion contentKey={`${MENU_LINKS_KEY}[${key}]`} menuItems={menuLink?.menu_link_set} />
            </li>
          );
        })}
      </ul>
      {(accountCTA?.include_account_cta || shopProductsCTA?.include_shop_cta) && (
        <CorFooterShopProducts onLogout={onLogout} />
      )}
      <CorFooterNewsletter className="grid-container" />
      <CorFooterLegal />
    </div>
  );
};

export default React.memo(MobileCorporateFooterVariant);
