import './cor-checkbox-field.scss';

import { FC } from 'react';
import { Field, useField, FieldAttributes, ErrorMessage } from 'formik';
import { template } from 'lodash-es';
import classNames from 'classnames';
import { CorTypography } from 'corporate-ui';

export interface ICorCheckboxField extends FieldAttributes<any> {
  label?: string;
  name: string;
  isRequired?: boolean;
}

export const CorCheckboxField: FC<ICorCheckboxField> = ({ label, isRequired = false, className, ...props }) => {
  const [field, meta] = useField(props);
  const compiled = template(label);
  const checkboxFieldClass = classNames('cor-checkbox-field', className, {
    'cor-checkbox-field--error': meta.error && meta.touched,
  });

  return (
    <div className={checkboxFieldClass}>
      <label className="cor-checkbox-field__label">
        <Field type="checkbox" className="cor-checkbox__input" {...props} />
        <span className="cor-checkbox__checkmark" />
        <CorTypography variant="navigation-body-2" component="span" className="cor-checkbox-field__label-text">
          {compiled()}
          {isRequired ? '*' : ''}
        </CorTypography>
      </label>
      <div className="cor-checkbox-field__error-message__container">
        <ErrorMessage className="cor-checkbox-field__error-message" name={field.name} component="p" />
      </div>
    </div>
  );
};
