import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getLocationsByName, getLocationsByNameConstants } from 'store/dashboard/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IMsrDashboardPayload {
  searchText?: string;
  limit: number;
  offset: number;
}

export function* getLocationsByNameHandler({
  payload: { searchText = '', limit, offset },
}: IAction<IMsrDashboardPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);

  yield put(
    doRequest(
      getLocationsByName,
      `${Endpoints.DASHBOARD_MSR_LOCATIONS_BY_NAME}?searchText=${searchQuery}&limit=${limit}&offset=${offset}`,
      'get'
    )
  );
}

export default function* getLocationsByNameSaga() {
  yield takeLatest(getLocationsByNameConstants[Actions.REQUEST], getLocationsByNameHandler);
}
