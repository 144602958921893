import { PAGINATION_SETTINGS } from 'constants/pagination.enum';
import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import { IOrderHistory } from 'types/order-history';
import { rootReducerActionTypes } from 'store/root-reducer-actions';
import types from 'store/account/action-types';
import * as actions from 'store/account/actions';

export const orderHistoryInitialState = {
  limit: PAGINATION_SETTINGS.defaultLimit,
  offset: PAGINATION_SETTINGS.defaultOffset,
  orders: [],
  total: 0,
  loading: false,
};

interface IPaginationPayload {
  payload: {
    current: number;
  };
}

interface IOrderHistoryPayload {
  payload: IOrderHistory;
}

export interface IOrderHistoryState extends IOrderHistory {
  loading: boolean;
}

export const orderHistory = createReducer<IOrderHistoryState>(
  {
    [actions.getOrderHistoryActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getOrderHistoryActionConstants[Actions.SUCCESS]]: (state, { payload }: IOrderHistoryPayload) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [actions.getOrderHistoryActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.ORDER_HISTORY_PAGINATION_CHANGE]: (state, { payload }: IPaginationPayload) => ({
      ...state,
      offset: state.limit * payload.current,
    }),
    [rootReducerActionTypes.RESET_LOCATION_RELATED_DATA]: () => orderHistoryInitialState,
    [actions.getAccountInformationActionConstants[Actions.SUCCESS]]: (state) => state,
  },
  orderHistoryInitialState
);
