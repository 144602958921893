import { FC, PropsWithChildren } from 'react';
import { PageLayout } from 'components/layout';
import { BasicHeader } from 'components/layout/basic-header';
import { BasicFooter } from 'components/layout/basic-footer';

interface IBasicLayoutProps extends PropsWithChildren {}

export const BasicLayout: FC<IBasicLayoutProps> = ({ children }) => (
  <PageLayout classModifier="default" header={BasicHeader} footer={BasicFooter}>
    {children}
  </PageLayout>
);
