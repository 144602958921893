import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useContent } from 'hooks/use-content';
import { IconWithHover } from 'components/icon-with-hover';
import iconPhone from 'assets/images/icons/icon-phone.svg';
import iconEmail from 'assets/images/icons/icon-email.svg';
import iconUserOutlined from 'assets/images/icons/icon-user-outlined.svg';
import 'corporate-components/cor-layout/cor-page-layout.scss';
import { selectSalesRepInformationEntity } from 'store/account/selectors';

import './cor-sales-rep.scss';

export interface ICorSalesRep {
  showPhone: boolean;
  isHeader: boolean;
}

const LABELS_KEY = 'footer_labels[0].key_value_pair.value';

export const CorSalesRep: FC<ICorSalesRep> = ({ showPhone = false, isHeader = false }) => {
  const FALLBACK_SALES_REPRESENTATIVE_KEY = isHeader ? 'authorized_common[0].support_block.[0]' : 'support_block.0';

  const [salesRep, setSalesRep] = useState<{ email: string; name: string; phone: string }>();
  const { getLabelsByKey, getContentByKey } = useContent();
  const { dcPhoneNumber = '', salesRepInfo } = useSelector(selectSalesRepInformationEntity);
  const fallbackSalesRepresentative = getContentByKey<any>(FALLBACK_SALES_REPRESENTATIVE_KEY, {});
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});

  useMemo(() => {
    const phone = dcPhoneNumber || fallbackSalesRepresentative?.phone?.title;
    const email = salesRepInfo?.email || fallbackSalesRepresentative?.email;
    const name = salesRepInfo?.name || '';

    setSalesRep({ phone, email, name });
  }, [dcPhoneNumber, fallbackSalesRepresentative?.email, fallbackSalesRepresentative?.phone?.title, salesRepInfo]);

  return (
    <section className={`cor-sales-rep${isHeader ? ' is-header' : ''}`}>
      <h4 className="title">{labels?.sales_representative || 'YOUR SALES REPRESENTATIVE'}</h4>
      <ul className="info">
        {!!salesRep?.name?.trim() && (
          <li>
            <span className="icon-container">
              <IconWithHover icon={iconUserOutlined} hoverIcon={iconUserOutlined} alt="User Icon" />
            </span>{' '}
            <span className="rep-name">{salesRep?.name}</span>
          </li>
        )}
        {!!salesRep?.email && (
          <li>
            <span className="icon-container">
              <IconWithHover icon={iconEmail} hoverIcon={iconEmail} alt="Email Icon" />
            </span>{' '}
            <a className="rep-email" href={`mailto:${salesRep?.email}`}>
              {salesRep?.email}
            </a>
          </li>
        )}
      </ul>
      {showPhone && !!salesRep?.phone && (
        <>
          <ul className="info">
            <h4 className="cor-footer__menu-link__sub-title">{labels?.customer_service || 'CUSTOMER SERVICE'}</h4>
            <li>
              <span className="icon-container">
                <IconWithHover icon={iconPhone} hoverIcon={iconPhone} alt="Email Icon" />
              </span>{' '}
              <a className="rep-phone" href={`tel:${salesRep?.phone}`}>
                {salesRep?.phone}
              </a>
            </li>
          </ul>
        </>
      )}
    </section>
  );
};
