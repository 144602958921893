import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { nanoid } from 'nanoid';
import { makeRequest } from 'store/api/sagas';
import { Endpoints } from 'constants/endpoints.enum';
import { selectIsAuthorised } from 'store/auth/selectors';
import { selectLineItems } from 'store/shopping-cart/selectors';
import { useLocalStorage } from './use-local-storage';

export const useFetchProductSolutionCarouselItems = (skuList: { product_sku: string }[]) => {
  const isUserLoggedIn = useSelector(selectIsAuthorised);
  const [locale] = useLocalStorage('locale', null);
  const queryKeyId = useRef(nanoid());
  const productsInCart = useSelector(selectLineItems);

  const getProducts = () => {
    if (skuList.length > 0 && isUserLoggedIn) {
      return makeRequest({
        url: `${Endpoints.PRODUCT_SOLUTION_CAROUSEL_PRODUCTS}/${skuList.join(',')}?globalLocale=${locale}`,
        method: 'get',
        withCredentials: true,
      });
    }
  };

  const { data } = useQuery({
    queryKey: ['productCarousel', queryKeyId?.current],
    queryFn: getProducts,
  });

  return {
    carouselProducts: data?.data.map((product) => ({
      ...product,
      isInCart: product.isInCart || productsInCart.some((cartItem) => cartItem.itemNumber === product.sku),
    })),
  };
};
