import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getRecentOrdersInfoConstants, getRecentOrdersInfo } from 'store/home/actions';
import { IAction } from 'types/actions';

export interface IGetRecentOrdersPayload {
  limit: number;
}

export function* getRecentOrdersInfoHandler({ payload: { limit } }: IAction<IGetRecentOrdersPayload>) {
  const url = `${Endpoints.ORDER_HISTORY}/?limit=${limit}&offset=0&sortDirection=DESC&sortFieldName=DATE_CREATED`;
  yield put(doRequest(getRecentOrdersInfo, url, 'get'));
}

export default function* getRecentOrdersInfoSaga() {
  yield takeLatest(getRecentOrdersInfoConstants[Actions.REQUEST], getRecentOrdersInfoHandler);
}
