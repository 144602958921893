import { ICSCorVideo } from 'corporate-interfaces';

export type CorContentstackVideoAsset = {
  isEmpty: boolean;
  title?: string;
  url?: string;
  mute?: boolean;
  fallbackImage?: string;
  isYoutube?: boolean;
  mimetype?: string;
};

export interface ICSCorVideoProps extends ICSCorVideo {
  fallbackImage?: string;
  fallback_image?: any;
}

export const corContentstackVideoLinkResolver = (video: ICSCorVideoProps): CorContentstackVideoAsset => {
  const {
    youtube_video_,
    brandfolder_video,
    contentstack_video = null,
    video_title,
    fallback_image,
    fallbackImage,
    ...rest
  } = video;
  const isYoutube: boolean = !!youtube_video_?.videoId;
  const thumb = fallbackImage || fallback_image || undefined;

  // There may be a legacy video being used from contentstack.
  // If so, issue a log and return an object with isEmpty: true.
  // The component useing it may need to be republished.
  if (contentstack_video) {
    console.log(
      `[CONTENTSTACK VIDEO DETECTED] -- Please move: ${
        contentstack_video?.url || contentstack_video
      } to the Brandfolder.`
    );
    return {
      isEmpty: true,
    };
  }
  const data = isYoutube ? youtube_video_ : brandfolder_video[0];
  const vid: any = isYoutube ? data : data?.attributes || null;
  const mimeType: string = isYoutube ? 'youtube' : data?.mimetype;

  return !!vid
    ? {
        isEmpty: false,
        ...rest,
        url: isYoutube ? `https://www.youtube.com/watch?v=${vid.videoId}` : vid.cdn_url,
        title: video_title,
        fallbackImage: isYoutube ? thumb : vid.thumbnail_url || thumb,
        isYoutube,
        mimetype: mimeType,
      }
    : {
        isEmpty: true,
      };
};
