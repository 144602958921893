import { FC, useState } from 'react';

export interface IIconWithHoverProps {
  icon: string;
  hoverIcon: string;
  className?: string;
  alt?: string;
  onClick?(): void;
}

export const IconWithHover: FC<IIconWithHoverProps> = ({ icon, hoverIcon, className, alt, onClick }) => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = (): void => {
    setHover(true);
  };

  const onMouseLeave = (): void => {
    setHover(false);
  };

  return (
    <img
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      src={hover ? hoverIcon : icon}
      alt={alt}
      onClick={onClick}
    />
  );
};
