import { CorDirections } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-directions';
import React, { ReactNode } from 'react';
import { get, isArray } from 'lodash-es';
import {
  CorCardList,
  CorHero,
  CorTwoColumn,
  CorForm,
  CorThreeColumn,
  CorAccordionComponent,
  CorLeadership,
  CorRichContentComponent,
  CorVideoComponent,
  CorFeaturedContent,
  CorFilteredList,
  CorDistributionMap,
  CorNewsListing,
  CorImage,
} from 'corporate-components';
import { ProductSolutionCarousel } from 'corporate-components/cor-pages/corporate/pages/standard/components';
import { CorButtonsGroup } from 'components/cor-buttons-group';
import { ContentstackContext } from 'context/contentstack';

/**
 * NEED TO REMOVE AFTER WE INCLUDE THE ACTUAL REACT COMPONENTS NEEDED
 * Component used as an Example for ticket: WSI-160
 * These components needs to be replaced with the imported components references
 * Components will handle their own data structure
 * Example:
 *
 * const Hero: FC = () => {
 *   // Example about how to use the useContent Hook
 *   const { getContentByKey } = useContent();
 *   return (
 *     <>
 *       Hero example to get the property: "display_breadcrumb_on_hero" :{' '}
 *       {JSON.stringify(getContentByKey('hero.display_breadcrumb_on_hero', ''))}
 *     </>
 *   );
 * };
 *
 * **/

type ComponentsHashMapType = Record<string, React.FC<any> | [string, Record<string, React.FC<any>>]>;

/**
 * Maps Modular Blocks Keys Props with the React Components
 */
const MODULAR_BLOCKS_COMPONENTS_MAPPING: ComponentsHashMapType = {
  hero: CorHero,
  two_column_content: CorTwoColumn,
  card_list: CorCardList,
  three_column_content: CorThreeColumn,
  form: CorForm,
  rich_text: CorRichContentComponent,
  accordion: CorAccordionComponent,
  button: CorButtonsGroup,
  leadership: CorLeadership,
  video: CorVideoComponent,
  featured_content: CorFeaturedContent,
  product_solution_carousel: ProductSolutionCarousel,
  content_list: [
    'content_or_filtered_list.0._content_type_uid',
    { filtered_list: CorFilteredList, news_listing: CorNewsListing },
  ],
  directions: CorDirections,
  distribution_map: CorDistributionMap,
  image: CorImage,
};

const getComponent = (modularBlock: any) => {
  const [currentKey] = Object.keys(modularBlock);
  const MAPPER_VALUE = MODULAR_BLOCKS_COMPONENTS_MAPPING[currentKey];

  /* Simple reference is Key-Value based. */
  if (!isArray(MAPPER_VALUE)) return MAPPER_VALUE;

  /* Complex reference is Tuple based which contains: 1) string path 2) hash map of components. */
  const [path, mapper]: any = MAPPER_VALUE;
  const contentType = get(modularBlock?.[currentKey], path);

  return mapper?.[contentType];
};

/**
 * Uses modular block reference from Contentstack to render the React component.
 * It also nests the Contentstack context with the content from the content type and modular configuration,
 * reference for the nested context approach:
 * https://medium.com/@NickIannelli/nested-context-the-underrated-aspect-thats-probably-missing-from-your-react-app-16e73f7d1
 * @param modularBlock
 * @param index
 * @type  {modularBlock: any, index.ts: number}
 */
export const ComponentResolver = (modularBlock: any, index: number, common: any): ReactNode => {
  const [currentKey] = Object.keys(modularBlock);
  const Component = getComponent(modularBlock);

  if (!currentKey) return <div key={index}>[{currentKey}] key cannot be found</div>;
  if (!Component) return <div key={index}>[{currentKey}] Component has no mapped reference</div>;

  return (
    <ContentstackContext.Provider key={index} value={{ ...modularBlock, common }}>
      <Component />
    </ContentstackContext.Provider>
  );
};
