import 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-ingredients-list/cor-ingredients-list.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { CorContentstackHtml, CorContentstackLink } from 'corporate-components/cor-contentstack';
import { CorTwoColumnDetails } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-two-column-details';
import { CorTypography } from 'corporate-ui';
import { FC } from 'react';
import { jumpLinkLabelToAnchor } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-jump-links';
import { useContent } from 'hooks/use-content';
import { selectIsAuthorised } from 'store/auth/selectors';
import { Routes } from 'constants/routes.enum';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';

export const CorIngredientsList: FC = () => {
  const { getContentByKey } = useContent();
  const label = getContentByKey('ingredients_list_label', '');
  const backgroundColor = getContentByKey('ingredients_list_background_color', '');
  const backgroundColorCSSModifier = backgroundColor
    ? `cor-ingredients-list--${backgroundColor}`.replace(' ', '-').toLowerCase()
    : null;
  const anchor = `${jumpLinkLabelToAnchor(label)}`;
  const isLoggedInUser = useSelector(selectIsAuthorised);
  const [locale] = useLocalStorage('locale', null);

  return (
    <div
      id={anchor}
      data-testid={'cor-ingredients-list'}
      className={classNames('cor-ingredients-list', backgroundColorCSSModifier)}
    >
      <CorTwoColumnDetails>
        <CorTypography
          data-testid={'cor-ingredients-list__title'}
          className={'cor-ingredients-list__title'}
          variant={'h2'}
          color={'dark-gray'}
        >
          {label}
        </CorTypography>
        <div>
          <CorContentstackHtml
            data-testid={'cor-ingredients-list__ingredients-list'}
            className={'cor-ingredients-list__ingredients-list'}
            contentKey={'ingredients_list'}
          />
          {!isLoggedInUser && !isInternationalSite(locale) && (
            <CorContentstackLink
              data={{
                href: Routes.LoginPage,
                title: getContentByKey('common[0].shared_recipe_detail_page[0].shop_ingredients_button_label', ''),
              }}
              className="cor-button cor-ingredients-list__shop-ingredients"
            />
          )}
        </div>
      </CorTwoColumnDetails>
    </div>
  );
};
