import { FC, PropsWithChildren } from 'react';
import { StandardPage, InsightPage, NewsPage, RecipesPage } from 'corporate-components/cor-pages/corporate/pages';
import { CorporatePageType } from 'corporate-constants/page-types.constants';
import { useContent } from 'hooks/use-content';

export const LAYOUT_PAGE_MAPPING = {
  [CorporatePageType.Insights]: InsightPage,
  [CorporatePageType.News]: NewsPage,
  [CorporatePageType.Standard]: StandardPage,
  [CorporatePageType.Recipes]: RecipesPage,
};

export interface IPageResolverProps extends PropsWithChildren {}

export const PageResolver: FC<IPageResolverProps> = ({ children }) => {
  const { getContentByKey } = useContent();
  const corporatePageType = getContentByKey('contentTypeName', '');
  const Page = LAYOUT_PAGE_MAPPING[corporatePageType] || null;

  return Page && <Page>{children || null}</Page>;
};
