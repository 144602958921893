import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { updateShoppingListItem, updateShoppingListItemActionConstants } from 'store/shopping-lists/actions';
import { IAction } from 'types/actions';

export interface IUpdateShoppingListItemSagaHandlerPayload {
  sku: string;
  shoppingListId: string;
  quantity: number;
  filteredLineItems?: string[];
}

export function* updateShoppingListItemSagaHandler({ payload }: IAction<IUpdateShoppingListItemSagaHandlerPayload>) {
  const { shoppingListId, sku, quantity, filteredLineItems } = payload;
  yield put(
    doRequest(updateShoppingListItem, `${Endpoints.SHOPPING_LISTS}/${shoppingListId}/items/${sku}`, 'put', {
      quantity,
      filteredLineItems,
    })
  );
}

export default function* updateShoppingListItemSaga() {
  yield takeLatest(updateShoppingListItemActionConstants[Actions.REQUEST], updateShoppingListItemSagaHandler);
}
