import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ProductLabel } from 'components/product-label';
import { ProductImage, ISize } from 'components/product-image';

import './ordered-product-image.scss';

export interface IOrderedProductImage extends PropsWithChildren {
  title: string;
  desktopSize: ISize;
  mobileSize?: ISize;
  src: string;
  className?: string;
  variant?: string;
  label?: string;
}

export const OrderedProductImage: FC<IOrderedProductImage> = ({
  title,
  src,
  mobileSize,
  desktopSize,
  children,
  variant = '',
  label = '',
  className: extraClass,
  ...restProps
}) => {
  const { isMobile } = useBreakpoint();
  const imageSize = isMobile && mobileSize ? mobileSize : desktopSize;
  const className = classNames('ordered-product-image__wrapper', extraClass);

  return (
    <div
      className={className}
      style={{ height: `${imageSize.height}px`, width: `${imageSize.width}px` }}
      {...restProps}
    >
      <ProductImage src={src} altText={title} mobileSize={mobileSize} desktopSize={desktopSize} />
      {variant.length ? (
        <div className="ordered-product-image__label" data-testid="ordered-product-label">
          <ProductLabel variant={variant}>{label}</ProductLabel>
        </div>
      ) : null}
    </div>
  );
};
