import { action } from 'typesafe-actions';
import { createRequestActions } from 'store/action-creator';
import types from 'store/similar-items/action-types';
import { createConstants } from 'store/actions-constants-creator';

export const getSimilarItemsConstants = createConstants(types.GET_SIMILAR_ITEMS);
export const getRequestQuoteSimilarItemsConstants = createConstants(types.GET_REQUEST_QUOTE_SIMILAR_ITEMS);

export const getSimilarItems = createRequestActions(getSimilarItemsConstants);
export const clearSimilarItems = () => action(types.CLEAR_SIMILAR_ITEMS);
export const getRequestQuoteSimilarItems = createRequestActions(getRequestQuoteSimilarItemsConstants);
export const clearRequestQuoteSimilarItems = () => action(types.CLEAR_REQUEST_QUOTE_SIMILAR_ITEMS);
