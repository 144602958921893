import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import { ISalesRepInformation } from 'types/user-info';
import * as actions from '../actions';

export interface ISalesRepInformationState extends ISalesRepInformation {
  loading: boolean;
}

export const salesRepInformationInitialState = {
  loading: false,
};

export const salesRepInformation = createReducer<ISalesRepInformationState>(
  {
    [actions.getSalesRepInformationActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getSalesRepInformationActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      loading: false,
      ...payload,
    }),
    [actions.getSalesRepInformationActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  salesRepInformationInitialState
);
