import { put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getYouMayAlsoNeedItems, getYouMayAlsoNeedItemsConstants } from 'store/you-may-also-need-items/actions';
import { Actions } from 'constants/actions.enum';

export interface IGetYouMayAlsoNeedItemsPayload {
  skus: string;
}

export function* getYouMayAlsoNeedItemsHandler({ payload: { skus } }: IAction<IGetYouMayAlsoNeedItemsPayload>) {
  const url = `${Endpoints.AVAILABLE_CATALOG_SKUS}/${skus}`;
  yield put(doRequest(getYouMayAlsoNeedItems, url, 'get'));
}

export default function* getYouMayAlsoNeedItemsSaga() {
  yield takeLatest(getYouMayAlsoNeedItemsConstants[Actions.REQUEST], getYouMayAlsoNeedItemsHandler);
}
