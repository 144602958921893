import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useFormikContext } from 'formik';
import { filterEmptyDynamicRules } from 'corporate-components/cor-form/cor-form-helper';
import { duplicateIndex, CorFormDynamicLogic } from 'corporate-components/cor-form/cor-form.constants';
import { dynamicRulesLogic } from 'corporate-components/cor-form/cor-form-helper';

interface IFormObserverProps {
  dynamicFormControls: any[];
  setDynamicFormControls: (value) => void;
  VALIDATION_SCHEMA: any;
}

export const FormObserver = ({
  dynamicFormControls,
  setDynamicFormControls,
  VALIDATION_SCHEMA,
}: IFormObserverProps) => {
  const { values, setFieldValue }: any = useFormikContext();

  const duplicatedDynamicIds = dynamicFormControls
    .map((field) => {
      return !isEmpty(filterEmptyDynamicRules(field?.dynamic_rule?.value)) ? field?.form_field?.field_name : null;
    })
    .filter((field, index, fields) => {
      return fields.indexOf(field) !== index;
    });

  const validateFormik = () => {
    try {
      VALIDATION_SCHEMA.validateSync(values, { abortEarly: false });
    } catch (error) {}
  };

  const dynamicLogicMatchCase = (dynamicLogic, rules, value) => {
    return dynamicRulesLogic[dynamicLogic || CorFormDynamicLogic.OR](rules, value);
  };

  const setDynamicFields = (value) => {
    const mutable = dynamicFormControls?.map((field, index) => {
      if (isEmpty(filterEmptyDynamicRules(field?.dynamic_rule?.value))) {
        field['dynamic_field'] = false;

        return field;
      }

      //create unique Id for duplicates
      if (field?.form_field?.field_name && duplicatedDynamicIds.some((id) => id === field?.form_field?.field_name)) {
        field.form_field.field_name = `${field.form_field.field_name}${duplicateIndex}${index}`;
      }

      const isMatchDynamicRule = dynamicLogicMatchCase(field?.select_dynamic_logic, field?.dynamic_rule?.value, value);

      field['dynamic_field'] = isMatchDynamicRule ? false : true;

      if (!isMatchDynamicRule && VALIDATION_SCHEMA?.fields[field?.form_field?.field_name]) {
        VALIDATION_SCHEMA.fields[field?.form_field?.field_name] = undefined;
        !isEmpty(value[field?.form_field?.field_name]) && setFieldValue(field?.form_field?.field_name, '');
      }

      return field;
    });

    setDynamicFormControls(mutable);
  };

  useEffect(() => {
    if (values && !isEmpty(values)) {
      setDynamicFields(values);
      validateFormik();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
};
