import { FC, createElement } from 'react';
import { get, template } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';

export interface IContentstackHtmlComponentProps {
  contentKey: string;
  component?: keyof JSX.IntrinsicElements;
  interpolateParams?: object;
}

export const CorContentstackHtml: FC<IContentstackHtmlComponentProps & React.HTMLProps<HTMLElement>> = ({
  contentKey,
  component = 'div',
  interpolateParams = {},
  ...props
}) => {
  const content = useContentstackContent();
  let htmlContent = get(content, contentKey, '');
  const html = [
    ['&lt;%', '<%'],
    ['%&gt;', '%>'],
    [
      '(.*src=")(.*\\/\\/images.contentstack.io\\/v3\\/assets.*?)(".*)',
      `$1$2?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}$3`,
    ],
    ['<iframe', '<div class="cor-rte-video"><iframe'],
    ['</iframe>', '</iframe></div>'],
  ].reduce((acc, replacementPair) => acc.replace(new RegExp(replacementPair[0], 'g'), replacementPair[1]), htmlContent);

  const compiled = template(html);

  return createElement(component, {
    ...props,
    dangerouslySetInnerHTML: { __html: compiled(interpolateParams) },
  });
};
