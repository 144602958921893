import { FC } from 'react';
import { isEmpty } from 'lodash-es';
import { ICSCorImage } from 'corporate-interfaces';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { useContent } from 'hooks/use-content';

import './cor-image.scss';

const CorImage: FC = () => {
  const { getContentByKey } = useContent();
  const image: ICSCorImage = getContentByKey('image', {});
  const imageAsset = image ? corContentstackImageLinkResolver(image) : undefined;

  const imageElement = <img src={imageAsset?.url} alt={imageAsset?.image_alt_text} className="cor-image__img" />;

  const hasLink = !isEmpty(imageAsset?.link_url) && imageAsset?.link_url?.href;
  const target = imageAsset?.open_in_a_new_tab ? '_blank' : '_self';

  return (
    <div className="cor-image">
      {hasLink ? (
        <a
          href={imageAsset?.link_url?.href}
          target={target}
          title={imageAsset?.link_url?.title}
          className="cor-image__link"
        >
          {imageElement}
        </a>
      ) : (
        imageElement
      )}
    </div>
  );
};

export default CorImage;
