import React from 'react';
import { MobilePanelCloser } from 'components/layout/cor-header/components/mobile-panel-closer';
import { AlgoliaSearch } from 'components/layout/cor-header/components/algolia-search';
import { handleOpenMenuBodyStyling } from 'components/layout/cor-header/utilities/handle-menus';

import './mobile-search-panel.scss';

interface IMobileSearchPanelProps {
  setIsSearchMobileOpen: (isOpen: boolean) => void;
  label: string;
}

export const MobileSearchPanel: React.FC<IMobileSearchPanelProps> = ({ setIsSearchMobileOpen, label }) => {
  handleOpenMenuBodyStyling({ overflow: 'hidden' });

  const handlePanelClose = () => {
    setIsSearchMobileOpen(false);
    handleOpenMenuBodyStyling({ overflow: 'auto' });
  };

  return (
    <div className="mobile-search-panel">
      <MobilePanelCloser label={label} handleMenuOpen={handlePanelClose}>
        <AlgoliaSearch id={'utilitiySearchIn'} expandable={false} afterSearch={handlePanelClose} isLoggedIn />
      </MobilePanelCloser>
    </div>
  );
};
