import { combineReducers } from 'redux';
import { Actions } from 'constants/actions.enum';
import { PAGINATION_SETTINGS } from 'constants/pagination.enum';
import { createReducer } from 'store/reducer-creator';
import { IElementByDueDate, IElementByDueDateWithAssignedAccounts, IElementByName } from 'types/dashboard';
import { IUserPersonalInformation } from 'types/user-info';
import { impersonationStopActionConstants } from 'store/auth/actions';
import { MSM_VIEWS } from 'constants/msm-view.enum';
import { CSR_WITH_ASSIGNED_ACCOUNTS_VIEWS } from 'constants/csr-with-assigned-accounts-views.enum';
import { DashboardQueryTypes } from 'constants/dashboard-query-types.enum';
import * as authActions from 'store/auth/actions';
import { csrCustomerForms } from './reducers';
import * as actions from './actions';
import types from './action-types';
import { csrCustomerFormInitialState } from './reducers/csr-customer-email-form';

export interface ILocations {
  total: number;
  offset: number;
  limit: number;
  elementsByName: IElementByName[];
}

export interface ILocationsWithAssignedAccounts {
  total: number;
  offset: number;
  limit: number;
  elementsByName: IElementByName[];
}

export interface IMSMLocation {
  elements: IElementByName[];
  msrDashboardInfo: {
    activeCustomers: number;
    locationsAddress: string[];
    newCustomers: number;
    totalCustomers: number;
  };
  msrName: string;
  msrId: string;
}
interface IMsrOption {
  employeeId: string;
  name: string;
}

export interface ILocationsForMsm {
  dashboardElements: IMSMLocation[];
  totalLocations: number;
}

export interface ILocationsByDayOfWeekWithAssignedAccounts {
  elementsByOrderDueDate: IElementByDueDateWithAssignedAccounts[];
}

export interface ILocationsByDayOfWeek {
  elementsByOrderDueDate: IElementByDueDate[];
}

export interface IElementByRecentOrder {
  orderDueDate: string;
  orderPlacedDate: string;
  elementsByName: IElementByName[];
}

export interface ICsrLocationsWithAssignedAccountsState {
  locationsByName: ILocationsWithAssignedAccounts;
  locationsByRecentOrders: ILocationsByRecentOrdersWithAssignedAccounts[];
  locationsByDayOfWeek: ILocationsByDayOfWeekWithAssignedAccounts;
}

export interface ILocationsByRecentOrders {
  elementsByOrderDueDate: IElementByName[];
  orderDueDate: string;
  orderPlacedDate: string;
  elementsByName: IElementByName[];
}

export interface ILocationsByRecentOrdersWithAssignedAccounts {
  elementsByOrderDueDate: IElementByName[];
  orderDueDate: string;
  orderPlacedDate: string;
  elementsByName: IElementByName[];
}

export interface IFormWithServerErrors {
  isSubmitting: boolean;
  serverError?: string;
  serverErrorId?: string;
  isDirty?: boolean;
}

export interface IDashboardListingState {
  loading: boolean;
  locationsByName: ILocations;
  locationsByRecentOrders: ILocationsByRecentOrders[];
  locationsByMsrName: ILocations;
  locationsByAccountNumber: ILocations;
  locationsByDayOfWeek: ILocationsByDayOfWeek;
  locationsForMsm: ILocationsForMsm;
  msrList: IMsrOption[];
  isAccountNumberSearch: boolean;
  activeMsmView: typeof MSM_VIEWS.CUSTOMERS | typeof MSM_VIEWS.TEAM;
  activeCsrWithAssignedAccountsView:
    | typeof CSR_WITH_ASSIGNED_ACCOUNTS_VIEWS.ALL_CUSTOMERS
    | typeof CSR_WITH_ASSIGNED_ACCOUNTS_VIEWS.ASSIGNED_CUSTOMERS;
  csrLocationsWithAssignedAccounts: ICsrLocationsWithAssignedAccountsState;
  currentSortingOptionCsrWithAssignedAccounts:
    | typeof DashboardQueryTypes.LocationsByOrderDate
    | typeof DashboardQueryTypes.LocationsByName
    | typeof DashboardQueryTypes.RecentOrdersByDate;
}

export interface ICsrCustomerForms {
  csrCustomerEmailForm: IFormWithServerErrors;
  csrCustomerPasswordForm: IFormWithServerErrors;
  hasUnsavedChanges: boolean;
}

export interface IDashboardState {
  dashboardListing: IDashboardListingState;
  csrCustomerForms: ICsrCustomerForms;
  impersonationStoppedOnce: boolean;
}

export const locationsInitialState = {
  total: 0,
  limit: PAGINATION_SETTINGS.defaultLimit,
  offset: PAGINATION_SETTINGS.defaultOffset,
  elementsByName: [],
};

export const locationsDayOfWeekInitialState = {
  elementsByOrderDueDate: [],
};

export const locationsForMsmInitialState = {
  dashboardElements: [],
  totalLocations: 0,
};

export const csrLocationsWithAssignedAccountsInitialState = {
  locationsByName: locationsInitialState,
  locationsByRecentOrders: [],
  locationsByDayOfWeek: locationsDayOfWeekInitialState,
};

export const dashboardListingInitialState: IDashboardListingState = {
  loading: false,
  locationsByName: locationsInitialState,
  locationsByRecentOrders: [],
  locationsByDayOfWeek: locationsDayOfWeekInitialState,
  locationsForMsm: locationsForMsmInitialState,
  msrList: [],
  locationsByMsrName: locationsInitialState,
  locationsByAccountNumber: locationsInitialState,
  isAccountNumberSearch: false,
  activeMsmView: MSM_VIEWS.CUSTOMERS,
  activeCsrWithAssignedAccountsView: CSR_WITH_ASSIGNED_ACCOUNTS_VIEWS.ALL_CUSTOMERS,
  csrLocationsWithAssignedAccounts: csrLocationsWithAssignedAccountsInitialState,
  currentSortingOptionCsrWithAssignedAccounts: DashboardQueryTypes.LocationsByOrderDate,
};

export const csrCustomerFormsInitialState: ICsrCustomerForms = {
  csrCustomerEmailForm: csrCustomerFormInitialState,
  csrCustomerPasswordForm: csrCustomerFormInitialState,
  hasUnsavedChanges: false,
};

export const dashboardInitialState: IDashboardState = {
  dashboardListing: dashboardListingInitialState,
  csrCustomerForms: csrCustomerFormsInitialState,
  impersonationStoppedOnce: false,
};

const dashboardListing = createReducer<IDashboardListingState>(
  {
    [actions.getLocationsByDayOfWeekConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getLocationsByDayOfWeekConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      locationsByDayOfWeek: { ...payload },
      loading: false,
    }),
    [actions.getLocationsForMsmConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getLocationsForMsmConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      locationsForMsm: { ...payload },
      loading: false,
    }),
    [actions.getLocationsByMsrIdForMsmConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      locationsForMsm: { ...payload },
      loading: false,
    }),
    [actions.getLocationsByRecentOrdersConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      locationsByRecentOrders: payload.elementsByOrderDueDate.map((el: IElementByRecentOrder) => ({
        // TODO: either change this on the backend or build an interface that accommodates the actual payload
        ...el,
        orderDueDate: el.orderPlacedDate,
        elementsByOrderDueDate: el.elementsByName,
      })),
      loading: false,
    }),
    [actions.getMsrLocationsCartStatusAndOrdersForMsmConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      locationsForMsm: {
        ...state.locationsForMsm,
        dashboardElements: [
          ...state.locationsForMsm.dashboardElements.map((dashboardElement: IMSMLocation) =>
            dashboardElement.msrId === payload.msrId
              ? {
                  ...dashboardElement,
                  elements: [
                    ...dashboardElement.elements.map((element: IElementByName) => {
                      const updatedInfo = payload.cartStatusAndOrdersMap[element.location.locationId] || {};
                      return { ...element, ...updatedInfo };
                    }),
                  ],
                }
              : dashboardElement
          ),
        ],
      },
    }),
    [actions.getLocationsByRecentOrdersConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getLocationsByRecentOrdersConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getLocationsByNameConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getLocationsByNameConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      locationsByName: { ...payload },
      loading: false,
    }),
    [actions.getLocationsByNameConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.DASHBOARD_MSR_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      locationsByName: {
        ...state.locationsByName,
        offset: state.locationsByName.limit * payload.current,
      },
    }),
    [actions.getLocationsForMsrConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getLocationsForMsrConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getLocationsForMsrConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      locationsByMsrName: { ...payload },
      isAccountNumberSearch: false,
      loading: false,
    }),
    [actions.getLocationsByAccountNumberConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getLocationsByAccountNumberConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getLocationsByAccountNumberConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      locationsByAccountNumber: { ...payload },
      isAccountNumberSearch: true,
      loading: false,
    }),
    [actions.getMsrListByNameConstants[Actions.SUCCESS]]: (state, { payload: { msrSuggestions } }) => ({
      ...state,
      msrList: msrSuggestions,
    }),
    [actions.getMsrListByNameForMsmConstants[Actions.SUCCESS]]: (state, { payload: { msrSuggestions } }) => ({
      ...state,
      msrList: msrSuggestions,
    }),
    [types.LOCATIONS_BY_MSR_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      locationsByMsrName: {
        ...state.locationsByMsrName,
        offset: state.locationsByMsrName.limit * payload.current,
      },
    }),
    [types.LOCATIONS_BY_ACCOUNT_NUMBER_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      locationsByAccountNumber: {
        ...state.locationsByAccountNumber,
        offset: state.locationsByAccountNumber.limit * payload.current,
      },
    }),
    [types.CLEAR_CSR_LOCATIONS]: (state) => ({
      ...state,
      locationsByAccountNumber: dashboardListingInitialState.locationsByAccountNumber,
      locationsByMsrName: dashboardListingInitialState.locationsByMsrName,
      isAccountNumberSearch: false,
      loading: false,
    }),
    [types.SET_ACTIVE_MSM_VIEW]: (state, { payload: { activeMsmView } }) => ({
      ...state,
      activeMsmView: activeMsmView,
    }),
    [impersonationStopActionConstants[Actions.SUCCESS]]: () => dashboardListingInitialState,

    [types.UPDATE_ACCOUNT_FOR_ALL_LOADED_LOCATIONS]: (state, action) => {
      const locationsKey = action.payload.isAccountNumberSearch ? 'locationsByAccountNumber' : 'locationsByMsrName';

      const performUpdate = (user: IUserPersonalInformation) =>
        user.id === action.payload.accountId ? { ...user, ...action.payload.accountUpdates } : user;

      return {
        ...state,
        [locationsKey]: {
          ...state[locationsKey],
          elementsByName: state[locationsKey].elementsByName.map((locationItem) => ({
            ...locationItem,
            location: {
              ...locationItem.location,
              superAdmin: performUpdate(locationItem.location.superAdmin),
              admins: locationItem.location.admins.map((account) => performUpdate(account)),
              users: locationItem.location.users.map((account) => performUpdate(account)),
            },
          })),
        },
      };
    },

    [types.CONFIRM_CSR_ACCOUNT_UPDATE]: (state, action) => {
      const locationsKey = action.payload.isAccountNumberSearch ? 'locationsByAccountNumber' : 'locationsByMsrName';
      const performUpdate = (user: IUserPersonalInformation) =>
        user.id === action.payload.accountId ? { ...user, ...action.payload.accountUpdates } : user;

      return {
        ...state,
        [locationsKey]: {
          ...state[locationsKey],
          elementsByName: state[locationsKey].elementsByName.map((locationItem) => {
            if (locationItem.location.locationId === action.payload.locationId) {
              return {
                ...locationItem,
                location: {
                  ...locationItem.location,
                  superAdmin: performUpdate(locationItem.location.superAdmin),
                  admins: locationItem.location.admins.map((account) => performUpdate(account)),
                  users: locationItem.location.users.map((account) => performUpdate(account)),
                },
              };
            }

            return locationItem;
          }),
        },
      };
    },
    [actions.getCsrLocationsWithAssignedAccountsByDayOfWeekConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getCsrLocationsWithAssignedAccountsByDayOfWeekConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getCsrLocationsWithAssignedAccountsByDayOfWeekConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      csrLocationsWithAssignedAccounts: {
        ...state.csrLocationsWithAssignedAccounts,
        locationsByDayOfWeek: { ...payload },
      },
      loading: false,
    }),
    [actions.getCsrLocationsWithAssignedAccountsByNameConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getCsrLocationsWithAssignedAccountsByNameConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      csrLocationsWithAssignedAccounts: {
        ...state.csrLocationsWithAssignedAccounts,
        locationsByName: { ...payload },
      },
      loading: false,
    }),
    [actions.getCsrLocationsWithAssignedAccountsByNameConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getCsrLocationsWithAssignedAccountsByRecentOrdersConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      csrLocationsWithAssignedAccounts: {
        ...state.csrLocationsWithAssignedAccounts,
        locationsByRecentOrders: payload.elementsByOrderDueDate.map((el: IElementByRecentOrder) => ({
          // TODO: either change this on the backend or build an interface that accommodates the actual payload
          ...el,
          orderDueDate: el.orderPlacedDate,
          elementsByOrderDueDate: el.elementsByName,
        })),
      },
      loading: false,
    }),
    [actions.getCsrLocationsWithAssignedAccountsByRecentOrdersConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getCsrLocationsWithAssignedAccountsByRecentOrdersConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.SET_ACTIVE_CSR_WITH_ASSIGNED_ACCOUNTS_VIEW]: (
      state,
      { payload: { activeCsrWithAssignedAccountsView } }
    ) => ({
      ...state,
      activeCsrWithAssignedAccountsView,
    }),
    [types.SET_CURRENT_SORTING_OPTION_CSR_WITH_ASSIGNED_ACCOUNTS_VIEW]: (
      state,
      { payload: { currentSortingOptionCsrWithAssignedAccounts } }
    ) => ({
      ...state,
      currentSortingOptionCsrWithAssignedAccounts,
    }),
    [types.CSR_WITH_ASSIGNED_ACCOUNTS_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      csrLocationsWithAssignedAccounts: {
        ...state.csrLocationsWithAssignedAccounts,
        locationsByName: {
          ...state.csrLocationsWithAssignedAccounts.locationsByName,
          offset: state.locationsByName.limit * payload.current,
        },
      },
    }),
  },
  dashboardListingInitialState
);

const impersonationStoppedOnce = createReducer<boolean>(
  {
    [authActions.impersonationStartActionConstants[Actions.SUCCESS]]: () => false,
    [authActions.impersonationStopActionConstants[Actions.SUCCESS]]: () => true,
  },
  false
);

export default combineReducers({
  dashboardListing,
  csrCustomerForms,
  impersonationStoppedOnce,
});
