import { FC } from 'react';

import './cor-product-new-arrival-badge.scss';

export interface ICorProductNewArrivalBadgeProp {
  labelcontentKey: Record<string, string> | undefined;
}

export const CorProductNewArrivalBadge: FC<ICorProductNewArrivalBadgeProp> = ({ labelcontentKey }) => {
  return <span className="cor-product-new-arrival-badge">{labelcontentKey?.new_arrival_badge}</span>;
};
