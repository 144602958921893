import { IOrderSummary } from 'types/order-details';
import { DEFAULT_PRICE_MODEL } from 'constants/price.enum';

export const DEFAULT_PRICE_SUMMARY_MODEL: IOrderSummary = {
  subTotal: DEFAULT_PRICE_MODEL,
  totalItemsQuantity: 0,
  shippingSurcharge: DEFAULT_PRICE_MODEL,
  totalTaxes: DEFAULT_PRICE_MODEL,
  additionalCharges: DEFAULT_PRICE_MODEL,
  total: DEFAULT_PRICE_MODEL,
  additionalChargesExtended: {},
  discountsExtended: {},
  totalDiscounts: {},
};
