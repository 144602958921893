import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getInvoicesSummary, getInvoicesSummaryActionConstants } from 'store/invoices/actions';
import { PaymentMethods } from 'constants/payment-methods.enum';

export interface IGetInovicesSummaryPayload {
  invoiceBalances: number[];
  countryCode: string;
  paymentMethod: string;
}

export function* getInvoicesSummarySagaHandler({ payload }: IAction<IGetInovicesSummaryPayload>) {
  const includeSurcharges = payload?.paymentMethod === PaymentMethods.CREDIT_CARD;

  yield put(
    doRequest<IGetInovicesSummaryPayload>(
      getInvoicesSummary,
      `${Endpoints.INVOICES_SUMMARY}?includeSurcharges=${includeSurcharges}`,
      'post',
      payload
    )
  );
}

export default function* getInvoicesSummarySaga() {
  yield takeLatest(getInvoicesSummaryActionConstants[Actions.REQUEST], getInvoicesSummarySagaHandler);
}
