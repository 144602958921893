import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ContentstackText, ContentstackMessage } from 'components/contentstack';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { selectIsHouseAccountUser } from 'store/auth/selectors';
import { stripLeadingZeros } from 'utils/string-format';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';

import './add-special-order-item-to-cart-error-modal.scss';

export interface IAddSpecialOrderItemToCartErrorModalProps extends IModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRequestQuoteCtaClick?: () => void;
  skuName: string;
  itemNumber: string;
}

export const AddSpecialOrderItemToCartErrorModal: FC<IAddSpecialOrderItemToCartErrorModalProps> = ({
  isOpen,
  onClose,
  onRequestQuoteCtaClick,
  skuName,
  itemNumber,
}) => {
  const contentstackPath = 'modals.0.add_special_order_item_to_cart_error_modal.0';
  const isHouseAccountUser = useSelector(selectIsHouseAccountUser);

  return (
    <Modal isOpen={isOpen} className="add-special-order-item-to-cart-error-modal" onClose={onClose} withBackdropClick>
      <ModalHeader titleContentstackPath={`${contentstackPath}.heading`} />
      <div className="add-special-order-item-to-cart-error-modal__message">
        <ContentstackMessage
          type="error"
          messageId="MSG048"
          interpolateParams={{ skuName, itemNumber: stripLeadingZeros(itemNumber) }}
        />
      </div>
      <ModalFooter className="add-special-order-item-to-cart-error-modal__footer">
        <CorButton className="add-special-order-item-to-cart-error-modal__cancel-button link-1" onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.cancel_button_label`} />
        </CorButton>
        {!isHouseAccountUser && (
          <CorButton
            className="add-special-order-item-to-cart-error-modal__request-quote-button"
            onClick={onRequestQuoteCtaClick}
          >
            <ContentstackText contentKey={`${contentstackPath}.request_quote_button_label`} />
          </CorButton>
        )}
      </ModalFooter>
    </Modal>
  );
};
