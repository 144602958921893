import { FC, PropsWithChildren, RefObject, useRef } from 'react';
import classNames from 'classnames';
import { useBodyScrollLock } from 'hooks/use-body-scroll-lock';
import { useOutsideClick } from 'hooks/use-outside-click';

import './full-screen-banner.scss';

export interface IFullScreenBannerProps extends PropsWithChildren {
  className?: string;
  offsetTop?: number;
  isOpen: boolean;
  onClose: () => void;
  toggleRef: RefObject<any>;
}

export const FullScreenBanner: FC<IFullScreenBannerProps> = ({
  className,
  offsetTop = 0,
  isOpen,
  onClose,
  children,
  toggleRef,
}) => {
  const menuRef = useRef<any>();

  useBodyScrollLock(isOpen);
  useOutsideClick(menuRef, isOpen, onClose, true, toggleRef);

  return (
    <section
      ref={menuRef}
      className={classNames(className, 'full-screen-banner', {
        'full-screen-banner--is-open': isOpen,
      })}
      style={{
        top: `${offsetTop}px`,
      }}
    >
      {isOpen && children}
    </section>
  );
};
