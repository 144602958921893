import { FC } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { ICarouselProduct } from 'types/product';
import { SkuProductCard } from 'components/product-card/sku-product-card';
import { useBreakpoint } from 'hooks/use-breakpoint';
import {
  useHideGlobalModalHandler,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';
import { ProductsCarouselArrow } from './components/products-carousel-arrow';

import './products-carousel.scss';

const DESKTOP_SLIDES_COUNT = 4;
const MOBILE_SLIDES_COUNT = 2;
const SINGLE_SLIDE_COUNT = 1;

interface IProductsCarousel {
  title?: string;
  subtitle?: string;
  products: ICarouselProduct[];
  id?: string;
  className?: string;
  usageContext?: string;
  autoplay?: boolean;
  autoplaySpeed?: number;
  contentStackPath?: string;
  minimumShowSlidesCount?: number;
}

export const ProductsCarousel: FC<IProductsCarousel> = ({
  title,
  subtitle,
  products,
  id,
  className,
  usageContext,
  autoplay = false,
  autoplaySpeed = 0,
  contentStackPath,
  minimumShowSlidesCount,
}) => {
  const { isDesktop } = useBreakpoint();

  const showRequestQuoteSuccessModal = useRequestQuoteSuccessMessageModal();
  const hideGlobalModal = useHideGlobalModalHandler();

  const showRequestQuoteModal = useRequestQuoteModal({
    requestQuoteSuccessCallBack: () => showRequestQuoteSuccessModal(),
    requestQuoteFailCallBack: hideGlobalModal,
  });

  const onRequestQuoteCtaClick = (skuKey: string) => () => showRequestQuoteModal({ skuKey });

  const productCarouselSettings = {
    dots: true,
    speed: 500,
    slidesToShow: products.length >= DESKTOP_SLIDES_COUNT ? DESKTOP_SLIDES_COUNT : products.length,
    slidesToScroll: DESKTOP_SLIDES_COUNT,
    touchMove: false,
    dotsClass: 'products-carousel__dots slick-dots',
    prevArrow: <ProductsCarouselArrow />,
    nextArrow: <ProductsCarouselArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: products.length >= MOBILE_SLIDES_COUNT ? MOBILE_SLIDES_COUNT : products.length,
          slidesToScroll: MOBILE_SLIDES_COUNT,
          arrows: false,
          touchMove: true,
          dots: true,
        },
      },
    ],
  };

  const showCarousel = minimumShowSlidesCount
    ? products.length >= minimumShowSlidesCount
    : isDesktop
    ? products.length >= DESKTOP_SLIDES_COUNT
    : products.length >= MOBILE_SLIDES_COUNT;

  return showCarousel ? (
    <div id={id} className={classNames('products-carousel', className)}>
      {title && (
        <header>
          <h2>{title}</h2>
          {subtitle && <h3>{subtitle}</h3>}
        </header>
      )}
      <Slider
        className="products-carousel__slider"
        {...productCarouselSettings}
        autoplay={autoplay}
        autoplaySpeed={Number(autoplaySpeed)}
      >
        {products.map((product: ICarouselProduct) => (
          <div className="products-carousel__item-wrapper" key={product.sku}>
            <div
              className={classNames('products-carousel__item', {
                'products-carousel__item-single': isDesktop && products.length === SINGLE_SLIDE_COUNT,
              })}
            >
              <SkuProductCard
                {...product}
                overrideTooltipPosition={false}
                usageContext={usageContext}
                onRequestQuoteCtaClick={onRequestQuoteCtaClick(product.sku)}
                contentStackPath={contentStackPath}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  ) : null;
};
