import { put, takeLatest } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { resendVerification, resendVerificationActionConstants } from 'store/customer-registration/actions';
import { IAction } from 'types/actions';

export interface IResendVerificationPayload {
  accountId: string;
}

function* resendVerificationSagaHandler({ payload: { accountId } }: IAction<IResendVerificationPayload>) {
  yield put(doRequest(resendVerification, `${Endpoints.DASHBOARD_EMPLOYEE}/${accountId}/resend-verification`, 'post'));
}

export default function* resendVerificationSaga() {
  yield takeLatest(resendVerificationActionConstants[Actions.REQUEST], resendVerificationSagaHandler);
}
