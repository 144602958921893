import { put, takeLatest } from 'redux-saga/effects';
import { doRequest } from 'store/api/actions';
import { getSiteKey, getSiteKeyActionConstants } from 'store/captcha/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';

export function* getSiteKeySagaHandler() {
  yield put(doRequest<string>(getSiteKey, `${Endpoints.CAPTCHA_SITE_KEY}`, 'get'));
}

export default function* getSiteKeySaga() {
  yield takeLatest(getSiteKeyActionConstants[Actions.REQUEST], getSiteKeySagaHandler);
}
