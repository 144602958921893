import { FC } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { CorButton } from 'components/cor-button';
import { CustomTooltip } from 'components/custom-tooltip';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { selectIsKeyAccountUser, selectIsUserLocationDeleted } from 'store/auth/selectors';
import { Permissions } from 'constants/permissions';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { PermissionAccess } from 'components/permission-access';
import { HierarchyAccountsSpecialOrderMessage } from 'components/hierarchy-accounts-special-order-message';

import './alternative-items-line-item-buttons.scss';

export interface IAlternativeItemsLineItemButtonsProps {
  contentstackPath: string;
  sku: string;
  isSpecialOrder: boolean;
  isDiscontinued: boolean;
  replacementItems?: string[];
  active: boolean;
  disabled: boolean;
  onAddToCartClick: () => void;
  onRequestQuoteCtaClick?: () => void;
}

export const AlternativeItemsLineItemButtons: FC<IAlternativeItemsLineItemButtonsProps> = ({
  contentstackPath,
  sku,
  isSpecialOrder,
  isDiscontinued,
  replacementItems,
  active,
  disabled,
  onAddToCartClick,
  onRequestQuoteCtaClick,
}) => {
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);

  const addToCartButtonClass = classNames('alternative-items-line-item-buttons__add-to-cart-btn', {
    active,
    disabled,
  });

  return !isSpecialOrder ? (
    <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
      <CorButton
        className={addToCartButtonClass}
        color="tertiary"
        disabled={disabled || isUserLocationDeleted || (isDiscontinued && isEmpty(replacementItems))}
        onClick={onAddToCartClick}
      >
        <ContentstackText contentKey={`${contentstackPath}.add_to_cart_button_label${active ? '_active' : ''}`} />
      </CorButton>
    </PermissionAccess>
  ) : isKeyAccountUser ? (
    <HierarchyAccountsSpecialOrderMessage />
  ) : (
    <div className="alternative-items-line-item-buttons__request-quote">
      <CorButton
        color="tertiary"
        className="alternative-items-line-item-buttons__request-quote-btn"
        disabled={isUserLocationDeleted}
        onClick={onRequestQuoteCtaClick}
      >
        <ContentstackText contentKey={`${contentstackPath}.request_quote_button_label`} />
      </CorButton>
      <CustomTooltip tooltipId={`similar-items-special-order-${sku}`} overrideTooltipPosition={false}>
        <ContentstackMessage type="tooltips" messageId="MSG107" />
      </CustomTooltip>
    </div>
  );
};
