import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash-es';
import { useIdleTimer } from 'react-idle-timer';
import { history } from 'store';
import { Routes } from 'constants/routes.enum';
import { setIsClientIdle } from 'store/customer-login/actions';
import { selectIsClientIdle } from 'store/customer-login/selectors';
import { selectIsLoggedInUser } from 'store/auth/selectors';
import { useGetTranslatedUrl } from 'hooks/use-get-translated-url';

export const SessionTimer: FC = () => {
  const dispatch = useDispatch();
  const timeout = 1000 * 60 * 15.6;
  const isClientIdle = useSelector(selectIsClientIdle);
  const { getTranslatedUrl } = useGetTranslatedUrl();

  const loginPageUrl = getTranslatedUrl({
    usEnglishUrl: Routes.LoginPage,
    caFrenchUrl: Routes.CanadaFrenchLoginPage,
  });

  const setSessionTimeout = () => {
    history.push(loginPageUrl);
    dispatch(setIsClientIdle(true));
    localStorage.removeItem('session');
  };

  const setSessionActive = () => {
    if (
      isClientIdle &&
      (window.location.pathname.includes(Routes.LoginPage) ||
        window.location.pathname.includes(Routes.CanadaFrenchLoginPage))
    ) {
      throttle(() => {
        dispatch(setIsClientIdle(false));
      }, 250);
    }
  };

  const isLoggedInUser = useSelector(selectIsLoggedInUser);

  const { pause, resume } = useIdleTimer({
    timeout: timeout,
    onIdle: setSessionTimeout,
    onActive: setSessionActive,
  });

  useEffect(() => {
    if (isLoggedInUser) {
      resume();
    } else {
      pause();
    }
  }, [dispatch, isLoggedInUser, resume, pause]);

  return null;
};
