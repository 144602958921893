import { IAlgoliaContentTypesEnum, IAlgoliaTagsEnum } from 'queries/use-algolia-query';

export const SearchResultsContentTypeQuantityMap = new Map([
  [IAlgoliaContentTypesEnum.InsightsDetailPage, 3],
  [IAlgoliaContentTypesEnum.RecipeDetailPage, 4],
]);

export const SearchResultsTitleQuantityMap = new Map([
  [IAlgoliaTagsEnum.FAQ, 3],
  [IAlgoliaTagsEnum.CorporateProducts, 4],
]);

export const SearchResultsContentTypeCardsClassNameMap = new Map([
  [IAlgoliaContentTypesEnum.InsightsDetailPage, 'search-results-page__cards--insights'],
  [IAlgoliaContentTypesEnum.RecipeDetailPage, 'search-results-page__cards--recipes'],
]);

export const SearchResultsTagsCardsClassNameMap = new Map([
  [IAlgoliaTagsEnum.FAQ, 'search-results-page__cards--faqs'],
  [IAlgoliaTagsEnum.CorporateProducts, 'search-results-page__cards--recipes'],
]);
