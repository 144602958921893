import { action } from 'typesafe-actions';
import { ILocation } from 'types/locations';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export interface ICustomerLoginPayload {
  customerLocations: ILocation[];
}

export interface IFormWithServerErrors {
  isSubmitting: boolean;
  serverErrors?: string[];
  serverErrorId: string;
}

export interface IErrorPayload {
  data: {
    errorMessages: string[];
  };
}

export const submitLoginActionConstants = createConstants(types.SUBMIT_LOGIN);
export const getLocationsActionConstants = createConstants(types.GET_CUSTOMER_LOCATIONS);
export const getAnalyticsVariablesActionConstants = createConstants(types.GET_ANALYTICS_VARIABLES);
export const submitLocationActionConstants = createConstants(types.SUBMIT_LOCATION);
export const submitLocationInvoiceOnlyActionConstants = createConstants(types.SUBMIT_LOCATION_INVOICE_ONLY);

export const submitLogin = createRequestActions(submitLoginActionConstants);
export const getLocations = createRequestActions(getLocationsActionConstants);
export const getAnalyticsVariables = createRequestActions(getAnalyticsVariablesActionConstants);
export const submitLocation = createRequestActions(submitLocationActionConstants);
export const submitLocationInvoiceOnly = createRequestActions(submitLocationInvoiceOnlyActionConstants);

export const clearServerErrors = () => action(types.CLEAR_SERVER_ERRORS, {});

export const resetLoginAttemptCounter = () => action(types.RESET_LOGIN_ATTEMPT_COUNTER, {});

export const setIsClientIdle = (idle: boolean) => action(types.SET_CLIENT_IDLE_STATE, { idle });
