const commonContentTypes = [
  'common.environment_message',
  'common.impersonation',
  'common.site_wide_banner',
  'common.product_characteristics.product_status_labels',
  'common.shared_recipe_detail_page',
  'common.months_short',
];
const authorizedCommonContentTypes = [
  'authorized_common.sales_representative',
  'authorized_common.customer_notifications',
  'authorized_common.support_block',
];
const modalsCommonContentTypes = [
  'modals',
  'modals.location_change_confirmation_modal',
  'modals.notification_list_modal',
  'modals.geolocation_redirect_modal',
  'modals.geolocation_redirect_modal.countries_list',
];
const checkoutPageHeaderContentTypes = ['header'];
const productCharacteristicsContentTypes = [
  'product_characteristics.product_status_labels',
  'product_characteristics.product_indicators',
  'product_characteristics.product_indicators.seasonal_indicators',
];
const requestQuoteModalsContentTypes = [
  'modals.request_quote_modal',
  'modals.request_quote_success_modal',
  'modals.request_quote_modal.similar_items.alternative_items_line_item',
];
const discontinuedModalsContentTypes = ['modals.discontinued_item_modal'];

const shoppingListModalsContentTypes = [
  'modals.create_shopping_list_modal',
  'modals.add_to_shopping_list_modal',
  'modals.success_error_modal',
  'modals.add_to_modal',
  'modals.add_to_shopping_list_error_modal',
  'modals.reorder_success_modal',
];

const messagesContentTypes = [
  'notifications.messages',
  'tooltips.messages',
  'success.messages',
  'error.messages',
  'error_two.messages',
];
// signifies that the url is dynamic and must be generated by the router using route params. value is meaningless, as the url field gets clobbered if dynamic anyway
const UseDynamicUrlFromRouteParams = '';

const corporateBaseModularBlocks = [
  'breadcrumbs',
  'modular_blocks.hero.hero',
  'modular_blocks.two_column_content.two_column_content_reference',
  'modular_blocks.rich_text.rich_text',
  'modular_blocks.button.button',
];

export const contentstackConfig = {
  home: {
    contentTypeName: 'home_page',
    url: 'home',
    include: [
      'page_content.order_updates',
      'page_content.buy_it_again',
      'page_content.order_updates.recent_orders.reorder_success_pop_up',
      'page_content.add_to_cart_messages',
      'page_content.news_section.news_and_notifications_list.news_and_notifications',
      'page_content.a_spot_banner',
      'page_content.products_carousel',
      'page_content.product_list.product_item',
      'page_content.navigation_tiles_list',
      'page_content.navigation_tiles_list.navigation_tile_item',
      'page_content.navigation_tiles_list.navigation_tile_item.video',
      'page_content.order_cutoff_notification',
      'page_content.shopping_list',
      'page_content.shopping_list.empty_shopping_list.guide_tiles',
      'page_content.similar_items.alternative_items_line_item',
      'modals.video_modal',
      'modals.reorder_success_modal',
      'modals.add_to_cart_messages',
      'modals.success_error_modal',
      'modals.how_to_create_shopping_list_modal',
      'modals.how_to_create_shopping_list_modal.guide_section.guide_section_tiles.guide_tiles',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...discontinuedModalsContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...messagesContentTypes,
      ...shoppingListModalsContentTypes,
    ],
  },
  login: {
    contentTypeName: 'sign_in_page',
    url: 'login',
    include: [
      'breadcrumbs',
      'login_form',
      'login_create_account_tab',
      'marketing_content',
      'marketing_content.benefits_section.features',
      'modals.locked_account_warning_modal',
      'modals.where_did_you_find_us_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  selectLocation: {
    contentTypeName: 'select_location_page',
    url: 'select_location',
    include: [...commonContentTypes, ...messagesContentTypes],
  },
  registration: {
    contentTypeName: 'create_account_page',
    url: 'registration',
    include: [
      'breadcrumbs',
      'marketing_content',
      'marketing_content.benefits_section.features',
      'login_create_account_tab',
      'page_content.stepper.step_1.validate_user_without_invoice_form',
      'page_content.stepper.step_1.account_retrieval_form',
      'page_content.stepper.step_3.personal_information_form',
      ...commonContentTypes,
      ...messagesContentTypes,
    ],
  },
  addUserRegistration: {
    contentTypeName: 'registration_page_add_user',
    url: 'customer-invite',
    include: ['breadcrumbs', 'page_content.personal_information_form', ...commonContentTypes, ...messagesContentTypes],
  },
  registrationSuccess: {
    contentTypeName: 'registration_success_page',
    url: 'registration_success',
    include: [
      'breadcrumbs',
      'login_create_account_tab',
      'login_form',
      'marketing_content',
      'marketing_content.benefits_section.features',
      ...commonContentTypes,
      ...messagesContentTypes,
    ],
  },
  account: {
    contentTypeName: 'account_page',
    url: 'account',
    include: [
      'rich_text_messages.messages',
      'breadcrumbs',
      'tabs.personal_info.content.breadcrumbs',
      'tabs.order_history.content',
      'tabs.order_history.content.breadcrumbs',
      'tabs.order_history.content.order_history_order_list_item',
      'tabs.order_history.content.order_history_product_list_item',
      'subscriptions',
      'tabs.order_history.content.reorder_success_pop_up',
      'tabs.my_info.content.breadcrumbs',
      'tabs.my_info.content',
      'tabs.locations.content',
      'tabs.locations.content.admin_breadcrumbs',
      'tabs.locations.content.user_breadcrumbs',
      'tabs.quotes.content',
      'tabs.quotes.content.breadcrumbs',
      'tabs.quotes.content.quotes_line_item.quote_statuses',
      'tabs.order_history.content.add_to_cart_messages',
      'tabs.shopping_lists.content',
      'tabs.shopping_lists.content.breadcrumbs',
      'tabs.shopping_lists.content.shopping_lists_shopping_list_item',
      'tabs.shopping_lists.content.guide_section.guide_section_tiles.guide_tiles',
      'tabs.shopping_lists.content.navigation_section.navigation_section_tiles.navigation_tiles',
      'tabs.payment.content',
      'tabs.payment.content.header',
      'tabs.payment.content.payment_methods',
      'tabs.payment.content.invoices',
      'tabs.payment.content.invoices.payment_summary',
      'tabs.payment.content.invoices.payment_summary.breadcrumbs',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...discontinuedModalsContentTypes,
      ...messagesContentTypes,
    ],
  },
  account_modals: {
    contentTypeName: 'modals',
    url: 'modals',
    include: [
      'how_to_create_shopping_list_modal',
      'how_to_create_shopping_list_modal.guide_section.guide_section_tiles.guide_tiles',
      'success_error_modal',
      'edit_info_modal',
      'edit_info_modal.edit_account_personal_info_form',
      'change_password_modal',
      'change_password_modal.change_password_form',
      'add_special_order_item_to_cart_error_modal',
      'add_exceeded_item_to_cart_modal',
      'proprietary_item_modal',
      'customer_view_quote_modal',
      'cancel_quote_modal',
      'reject_quote_modal',
      'quote_order_summary_modal',
      'quote_order_summary_modal.order_summary',
      'add_to_shopping_list_error_modal',
      'create_shopping_list_modal',
      'create_shopping_list_modal.shopping_list_name_uniqueness_error',
      'add_to_shopping_list_modal',
      'add_shopping_list_to_cart_modal',
      'add_to_modal',
      'import_shopping_list_modal',
      'edit_user_info_modal',
      'edit_user_info_modal.edit_info_form',
      'location_users_info_modal',
      'invite_new_user_modal',
      'remove_user_confirmation_modal',
      'delete_shopping_list_confirmation_modal',
      'reorder_success_modal',
      'add_to_cart_messages',
      'select_option_modal',
      'modify_your_order_modal',
      'add_out_of_stock_to_cart_error_modal',
      'add_new_bank_account_modal',
      'add_new_bank_account_eft_pad_modal',
      'add_new_bank_account_eft_pad_modal.consent_section',
      'add_new_bank_account_modal.consent_section_ach',
      'remove_payment_bank_account_modal',
      'add_payment_card_modal',
      'remove_payment_card_modal',
      'add_payment_card_modal.payment_card_form',
      'add_payment_card_modal.payment_card_form.consent_section',
      'shortpay_reasons_modal',
      'schedule_payment_modal',
      'make_deposit_modal',
      'autopay_modal',
      'autopay_opt_out_modal',
    ],
  },
  plp: {
    contentTypeName: 'product_listing_page',
    url: 'plp',
    include: [
      'breadcrumbs',
      'product_group_selector',
      'page_content.product_list.product_item',
      'page_content.similar_items.alternative_items_line_item',
      'modals.create_shopping_list_modal',
      'modals.create_shopping_list_modal.shopping_list_name_uniqueness_error',
      'modals.add_to_shopping_list_modal',
      'modals.add_to_shopping_list_variant_selector_modal',
      'modals.quick_shop_modal',
      'modals.success_error_modal',
      'modals.select_option_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...messagesContentTypes,
    ],
  },
  resetPasswordVerification: {
    contentTypeName: 'reset_password_email_verification_page',
    url: 'reset_password_verification',
    include: [
      'page_content.email_verification_form',
      'rich_text_messages.messages',
      ...commonContentTypes,
      ...messagesContentTypes,
    ],
  },
  resetPassword: {
    contentTypeName: 'reset_password_page',
    url: 'reset_password',
    include: ['page_content.reset_password_form', ...commonContentTypes, ...messagesContentTypes],
  },
  updatePassword: {
    contentTypeName: 'update_password_page',
    url: 'update_password',
    include: [...commonContentTypes, ...messagesContentTypes],
  },
  emailVerification: {
    contentTypeName: 'resend_verification_email_page',
    url: 'email_verification',
    include: [
      'rich_text_messages.messages',
      'page_content.email_verification_form',
      ...commonContentTypes,
      ...messagesContentTypes,
    ],
  },
  shoppingCart: {
    contentTypeName: 'shopping_cart_page',
    url: 'shopping_cart',
    include: [
      'breadcrumbs',
      'rich_text_messages.messages',
      'page_content.order_cutoff_notification',
      'page_content.product_recommendation_carousel.product_item',
      'page_content.shopping_cart_item',
      'page_content.checkout_error',
      'modals.create_shopping_list_modal.shopping_list_name_uniqueness_error',
      'modals.success_error_modal',
      'modals.remove_all_confirmation_modal',
      'modals.add_to_shopping_list_modal',
      'order_minimum_notifications',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...discontinuedModalsContentTypes,
      ...messagesContentTypes,
    ],
  },
  checkout: {
    contentTypeName: 'checkout_page',
    url: 'checkout',
    include: [
      'breadcrumbs',
      'page_content.pick_up_delivery_info',
      'page_content.delivery_details_section',
      'page_content.order_summary_section',
      'order_details_general_info',
      ...checkoutPageHeaderContentTypes,
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...messagesContentTypes,
    ],
  },
  orderConfirmation: {
    contentTypeName: 'order_confirmation',
    url: 'order_confirmation',
    include: [
      'breadcrumbs',
      'page_content.delivery_details',
      'page_content.order_summary',
      'page_content.product_list.line_item',
      'modals.create_shopping_list_modal.shopping_list_name_uniqueness_error',
      'modals.success_error_modal',
      'modals.add_to_shopping_list_modal',
      'page_content.pick_up_delivery_info',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...messagesContentTypes,
    ],
  },
  orderDetails: {
    contentTypeName: 'order_details_page',
    url: 'order_details',
    include: [
      'breadcrumbs',
      'page_content.order_summary',
      'page_content.pick_up_delivery_info',
      'page_content.purchased_products.reorder_success_pop_up',
      'page_content.delivery_details_section',
      'page_content.general_info',
      'page_content.purchased_products_section',
      'page_content.order_details_purchased_product_item',
      'modals.add_to_shopping_list_modal',
      'modals.select_option_modal',
      'modals.add_exceeded_item_to_cart_modal',
      'modals.add_special_order_item_to_cart_error_modal',
      'modals.proprietary_item_modal',
      'modals.create_shopping_list_modal',
      'modals.create_shopping_list_modal.shopping_list_name_uniqueness_error',
      'modals.success_error_modal',
      'modals.cancel_order_modal',
      'modals.order_cancellation_denied_modal',
      'modals.timestamp_modal',
      'modals.reorder_success_modal',
      'modals.add_to_cart_messages',
      'modals.add_selection_to_cart_or_list_modal',
      'modals.modify_your_order_modal',
      'modals.add_out_of_stock_to_cart_error_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...discontinuedModalsContentTypes,
      ...messagesContentTypes,
    ],
  },
  dashboard: {
    contentTypeName: 'dashboard_page',
    url: 'dashboard',
    include: [
      'header',
      'footer',
      'footer.copyright_block',
      'tsr_dashboard',
      'account_statuses',
      'rich_text_messages.messages',
      'page_content.dashboard_location_item',
      'special_order_quotes_dashboard',
      'modals.success_error_modal',
      'modals.select_location_user_modal',
      'modals.cancel_quote_request_with_reason_modal',
      'modals.cancel_quote_with_reason_modal',
      'modals.manage_request_modal',
      'modals.view_quote_details_modal',
      'modals.customer_location_item_edit_modal',
      'modals.customer_location_item_edit_modal.email_address_tab.csr_change_email_form',
      'modals.customer_location_item_edit_modal.password_tab.csr_change_password_form',
      'modals.sort_modal',
      'modals.select_option_modal',
      'modals.timestamp_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  accessDenied: {
    contentTypeName: 'access_denied_page',
    url: 'access-denied',
    include: ['header', 'footer', 'footer.copyright_block', ...commonContentTypes, ...messagesContentTypes],
  },
  doNotSellMyInfo: {
    contentTypeName: 'do_not_sell_my_info_page',
    url: 'do-not-sell-my-info',
    include: [
      'breadcrumbs',
      'page_content',
      'opt_out_form',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  unauthorizedDoNotSellMyInfo: {
    contentTypeName: 'do_not_sell_my_info_page_unauthorized',
    url: 'do-not-sell-my-info',
    include: ['breadcrumbs', 'page_content', 'opt_out_form', ...commonContentTypes, ...messagesContentTypes],
  },
  productDetails: {
    contentTypeName: 'product_details_page',
    url: 'product-details',
    include: [
      'breadcrumbs',
      'product_group_selector',
      'page_content.product_attributes',
      'page_content.product_attributes.additional_resources.additional_resources',
      'page_content.product_information',
      'page_content.product_attributes.dietary_considerations_images',
      'page_content.product_attributes.dietary_preferences_icons',
      'page_content.product_attributes.nutrition.product_nutrition_attributes',
      'page_content.product_replacement.alternative_items_line_item',
      'page_content.similar_items.alternative_items_line_item',
      'modals.video_modal',
      'modals.create_shopping_list_modal.shopping_list_name_uniqueness_error',
      'modals.add_to_shopping_list_modal',
      'modals.reorder_success_modal',
      'modals.select_option_modal',
      'modals.add_to_cart_messages',
      'modals.success_error_modal',
      'modals.create_shopping_list_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...messagesContentTypes,
    ],
  },
  shoppingListDetails: {
    contentTypeName: 'shopping_list_details_page',
    url: 'shopping-list-details',
    include: [
      'breadcrumbs',
      'page_content.guide_section.guide_section_tiles.guide_tiles',
      'page_content.navigation_section.navigation_section_tiles.navigation_tiles',
      'page_content.shopping_list_total_section',
      'page_content.shopping_list_product_info',
      'modals.add_exceeded_item_to_cart_modal',
      'modals.edit_shopping_list_name_modal',
      'modals.edit_shopping_list_name_modal.edit_shopping_list_name_form',
      'modals.edit_shopping_list_name_modal.shopping_list_name_uniqueness_error',
      'modals.success_error_modal',
      'modals.add_shopping_list_to_cart_modal',
      'modals.delete_shopping_list_confirmation_modal',
      'modals.add_to_cart_messages',
      'modals.select_shopping_list_details_view_modal',
      'modals.select_option_modal',
      'modals.add_special_order_item_to_cart_error_modal',
      'modals.add_out_of_stock_to_cart_error_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...discontinuedModalsContentTypes,
      ...shoppingListModalsContentTypes,
      ...messagesContentTypes,
    ],
  },
  paymentPlatform: {
    contentTypeName: 'payment_platform_page',
    url: 'payments',
    include: [
      'breadcrumbs',
      'header',
      'payment_methods',
      'invoices',
      'payment_summary',
      'modals.add_payment_card_modal',
      'modals.make_deposit_modal',
      'modals.remove_payment_card_modal',
      'modals.success_error_modal',
      'modals.add_new_bank_account_modal',
      'modals.add_payment_card_modal.payment_card_form',
      'modals.remove_payment_bank_account_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  paymentHistory: {
    contentTypeName: 'account_payment_history',
    url: 'payment-history',
    include: [
      'breadcrumbs',
      'modals.cancel_scheduled_payment_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  paymentDetails: {
    contentTypeName: 'payment_details_page',
    url: 'payment-details',
    include: [
      'breadcrumbs',
      'header',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  error404: {
    contentTypeName: 'error_404',
    url: '404',
    include: [
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  unauthorizedError404: {
    contentTypeName: 'error_404',
    url: '404-unauthorized',
    include: [...commonContentTypes, ...messagesContentTypes],
  },
  article: {
    contentTypeName: 'article_page',
    url: UseDynamicUrlFromRouteParams,
    include: [
      'breadcrumbs',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  additionalResourcesPage: {
    contentTypeName: 'additional_resources_page',
    url: UseDynamicUrlFromRouteParams,
    include: [
      'breadcrumbs',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  promotionalPage: {
    contentTypeName: 'promotional_pages',
    url: UseDynamicUrlFromRouteParams,
    include: [
      'page_content.product_list.product_item',
      'top_section.video',
      'navigation_tiles_list',
      'navigation_tiles_list.navigation_tile_item',
      'navigation_tiles_list.navigation_tile_item.video',
      'modals.video_modal',
      'breadcrumbs',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...messagesContentTypes,
    ],
  },
  newsAndNotificationsPage: {
    contentTypeName: 'news_and_notifications_page',
    url: 'news-and-notifications',
    include: [
      'breadcrumbs',
      'page_content.news_and_notifications_list.news_and_notifications',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  helpAndResourcesPage: {
    contentTypeName: 'help_and_resources_page',
    url: 'help-resources',
    include: [
      'breadcrumbs',
      'how_to_video_section.video',
      'featured_section.navigation_tiles',
      'featured_section.navigation_tiles.navigation_tile_item',
      'featured_section.navigation_tiles.navigation_tile_item.video',
      'modals.video_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...messagesContentTypes,
    ],
  },
  corporateRecipesPage: {
    contentTypeName: 'recipe_detail_page',
    url: '',
    include: [
      'related_recipes_section.related_recipe',
      'related_recipes_section.see_all_button',
      'additional_notes_section.chef_bio',
      'modals.success_error_modal',
      'modals.reorder_success_modal',
      'modals.select_option_modal',
      'modals.add_to_shopping_list_modal',
      'modals.add_to_cart_messages',
      'modals.add_special_order_item_to_cart_error_modal',
      'modals.add_out_of_stock_to_cart_error_modal',
      'modals.create_shopping_list_modal',
      'modals.create_shopping_list_modal.shopping_list_name_uniqueness_error',
      ...corporateBaseModularBlocks,
      ...messagesContentTypes,
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...requestQuoteModalsContentTypes,
    ],
  },
  corporateInsightsPage: {
    contentTypeName: 'insights_detail_page',
    url: '',
    include: [
      ...corporateBaseModularBlocks,
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      'related_insights_section.related_insights',
      'related_insights_section.see_all_button',
    ],
  },
  corporateNewsPage: {
    contentTypeName: 'news_page',
    url: '',
    include: [
      ...corporateBaseModularBlocks,
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      'modular_blocks.three_column_content.three_column_content',
      'related_news_section.related_news',
      'related_news_section.see_all_button',
    ],
  },
  corporatePage: {
    contentTypeName: 'standard_page',
    url: '',
    include: [
      ...corporateBaseModularBlocks,
      ...messagesContentTypes,
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      'common.0.loading_spinner',
      'modular_blocks.three_column_content.three_column_content',
      'modular_blocks.card_list.card_list',
      'modular_blocks.accordion.accordion',
      'modular_blocks.product_solution_carousel',
      'modular_blocks.product_solution_carousel.product_item',
      'modular_blocks.form.form',
      'modular_blocks.form.form.error_messages.messages',
      'modular_blocks.leadership.leadership',
      'modular_blocks.featured_content.featured_content.references.modular_blocks.hero.hero',
      'modular_blocks.content_list.content_or_filtered_list',
      'modular_blocks.content_list.content_or_filtered_list.labels',
      'modular_blocks.distribution_map.distribution_map.distribution_centers_1.distribution_center',
      'modular_blocks.distribution_map.distribution_map.distribution_centers_2.distribution_center',
    ],
  },
  searchResults: {
    contentTypeName: 'search_page',
    url: UseDynamicUrlFromRouteParams,
    include: [
      'breadcrumbs',
      'global_facets',
      'labels',
      'page_content.product_replacement.alternative_items_line_item',
      'page_content.similar_items.alternative_items_line_item',
      'modals.quick_shop_modal',
      'modals.success_error_modal',
      ...commonContentTypes,
      ...modalsCommonContentTypes,
      ...authorizedCommonContentTypes,
      ...productCharacteristicsContentTypes,
      ...requestQuoteModalsContentTypes,
      ...messagesContentTypes,
    ],
  },
  livePreviewPage: {
    contentTypeName: '',
    url: '',
    include: [...corporateBaseModularBlocks, ...commonContentTypes, ...modalsCommonContentTypes],
  },
};

export const IMAGES_COMPRESSION_LEVEL = '70';
export const IMAGES_FORMAT = 'jpg';
