import { createReducer } from 'store/reducer-creator';
import { Actions } from 'constants/actions.enum';
import * as actions from './actions';

export interface ISchedulePaymentState {
  ctPaymentId: string;
  soldTo: string;
}

export const schedulePaymentInitialState: ISchedulePaymentState = {
  ctPaymentId: '',
  soldTo: '',
};

export const schedulePaymentReducer = createReducer<ISchedulePaymentState>(
  {
    [actions.scheduleCreditCardPaymentActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { ctPaymentId, soldTo } }
    ) => ({
      ...state,
      ctPaymentId: ctPaymentId,
      soldTo: soldTo,
    }),
    [actions.scheduleBankAccountPaymentActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { ctPaymentId, soldTo } }
    ) => ({
      ...state,
      ctPaymentId: ctPaymentId,
      soldTo: soldTo,
    }),
    [actions.cancelScheduledPaymentActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { ctPaymentId, soldTo } }
    ) => ({
      ...state,
      ctPaymentId: ctPaymentId,
      soldTo: soldTo,
    }),
    [actions.schedulePadPaymentActionConstants[Actions.SUCCESS]]: (state, { payload: { ctPaymentId, soldTo } }) => ({
      ...state,
      ctPaymentId: ctPaymentId,
      soldTo: soldTo,
    }),
  },
  schedulePaymentInitialState
);
