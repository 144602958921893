import './insight.page.scss';
import { FC, PropsWithChildren } from 'react';
import { ICSCorImage } from 'corporate-interfaces';
import { CorDetailHeroBanner, CorDetailRelatedContent, CorShareSocial } from 'corporate-components';
import { useContent } from 'hooks/use-content';
import { CorFeaturedContentContentTypeEnum } from 'constants/cor-featured-content.enum';

export const InsightPage: FC<PropsWithChildren> = ({ children }) => {
  const { getContentByKey } = useContent();
  const publicationDate = getContentByKey('publication_date', '');
  // this is to remove the timezone from the date if the timezome comes in the string. ex: 2020-04-24T04:00:00Z
  // so we will only print "2020-04-24" and if it does not have timezone date will be printed as it is.
  const dateAuthor: string =
    publicationDate.indexOf('T') !== -1 ? publicationDate?.slice(0, publicationDate.indexOf('T')) : publicationDate;
  return (
    <>
      <CorDetailHeroBanner
        heading={getContentByKey('title', '')}
        tags={getContentByKey('superheading', '')}
        description={getContentByKey('short_description', '')}
        image={getContentByKey('banner_image', {} as ICSCorImage)}
        dateAuthor={dateAuthor}
        iconTitleGroup={getContentByKey('icon_title_group', [])}
      />
      {children || null}
      <CorShareSocial />
      <CorDetailRelatedContent
        baseKey="related_insights_section"
        relatedContentKey="related_insights_section.related_insights"
        contentType={CorFeaturedContentContentTypeEnum.Insights}
        className="cor-detail-related-content__insight"
        titleExclusion={getContentByKey('title', '')}
      />
    </>
  );
};
