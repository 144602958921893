import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import trunc from 'trunc-html';
import { get, template } from 'lodash-es';
import { ContentstackText, ContentstackRichTextMessage, IMessage } from 'components/contentstack';
import { CorButton } from 'components/cor-button';
import { generateTemporaryPassword } from 'store/account/actions';
import { updateAccountForAllLoadedLocations } from 'store/dashboard/actions';
import { selectCsrAccountAtLocation, selectIsAccountNumberSearch } from 'store/dashboard/selectors';
import iconNoticeYellow from 'assets/images/icons/icon-notice-yellow.svg';
import { ContentstackMessage } from 'components/contentstack/contentstack-message';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { ICsrFormProps } from '../customer-location-edit-section';

import './change-password-form.scss';

interface ITempPasswordParams {
  internalId: string;
  onSuccess: (password: string) => void;
}

export const ChangePasswordForm: FC<ICsrFormProps> = ({ accountAtLocation, onCancel, locationId }) => {
  const contentstackEditModalPath = 'modals.0.customer_location_item_edit_modal.0';
  const contentstackPath = `${contentstackEditModalPath}.password_tab.csr_change_password_form.0`;
  const dispatch = useDispatch();
  const [hasTemporaryPassword, setHasTemporaryPassword] = useState(false);
  const [temporaryPassword, setTemporaryPassword] = useState<string | null>(null);
  const [isTemporaryPasswordVisible, setIsTemporaryPasswordVisible] = useState(false);
  const [canGeneratePassword, setCanGeneratePassword] = useState(true);
  const [isTemporaryPasswordWarningExpanded, setIsTemporaryPasswordWarningExpanded] = useState(false);
  const isAccountNumberSearch = useSelector(selectIsAccountNumberSearch);
  const { isMobile } = useBreakpoint();

  const selectedUser = useSelector(selectCsrAccountAtLocation(accountAtLocation.id, locationId, isAccountNumberSearch));

  const content = useContentstackContent();
  const messages: IMessage[] = get(content, 'rich_text_messages.0.messages', []);
  const temporaryPasswordWarningHtml = get(
    messages?.find((message) => message.message_id === 'MSG055') as IMessage | null,
    'message_content',
    ''
  );
  const WARNING_CHARACTERS_MAX = 88;
  const temporaryPasswordWarningText = trunc(temporaryPasswordWarningHtml, temporaryPasswordWarningHtml.length).text;

  const passwordWarningTextHasMoreCharacters = temporaryPasswordWarningText.length > WARNING_CHARACTERS_MAX;
  const temporaryPasswordWarningTextCut = passwordWarningTextHasMoreCharacters
    ? `${trunc(temporaryPasswordWarningHtml, WARNING_CHARACTERS_MAX).html}`
    : temporaryPasswordWarningHtml;
  const temporaryPasswordWarningTextCutCompiled = template(temporaryPasswordWarningTextCut);

  const sendRequest = () => {
    dispatch(
      generateTemporaryPassword.request<ITempPasswordParams>({
        internalId: accountAtLocation.id,
        onSuccess: onTempPasswordReceived,
      })
    );
  };

  useEffect(() => {
    setCanGeneratePassword(selectedUser.isVerified);
    setHasTemporaryPassword(!!selectedUser.temporaryPasswordCreatedAt);
  }, [selectedUser]);

  const onTempPasswordReceived = (password: string) => {
    setTemporaryPassword(password);
    setHasTemporaryPassword(true);
    setIsTemporaryPasswordVisible(true);

    dispatch(
      updateAccountForAllLoadedLocations(accountAtLocation.id, isAccountNumberSearch, {
        temporaryPasswordCreatedAt: new Date().toString(), // format doesn't matter, as long as something is here until a refresh
      })
    );
  };

  return (
    <div className="change-password-form">
      {!canGeneratePassword ? (
        <div className="change-password-form__generate-password-forbidden-warning">
          <img src={iconNoticeYellow} alt="notice" />
          <ContentstackMessage type="notifications" messageId="MSG056" />
        </div>
      ) : (
        hasTemporaryPassword && (
          <div
            className={classNames('temporary-password-instructions', {
              'temporary-password-instructions--collapsed': isMobile && !isTemporaryPasswordWarningExpanded,
            })}
          >
            <img src={iconNoticeYellow} alt="notice" />
            <div className="contents">
              {!isTemporaryPasswordWarningExpanded && isMobile ? (
                <>
                  <span
                    className="temporary-password-instructions__text-cut"
                    dangerouslySetInnerHTML={{
                      __html: temporaryPasswordWarningTextCutCompiled(),
                    }}
                  />
                </>
              ) : (
                <ContentstackRichTextMessage
                  className="temporary-password-instructions__message-text"
                  messageId="MSG055"
                />
              )}
              {isMobile && !isTemporaryPasswordWarningExpanded && passwordWarningTextHasMoreCharacters && (
                <CorButton
                  onClick={() => setIsTemporaryPasswordWarningExpanded(true)}
                  className="temporary-password-instructions__show-more text"
                >
                  <ContentstackText contentKey={`${contentstackPath}.show_more_button_label`} />
                </CorButton>
              )}
            </div>
          </div>
        )
      )}

      <div className="change-password-form__current-password">
        <p className="change-password-form__current-password-label">
          <ContentstackText contentKey={`${contentstackPath}.password_label`} />{' '}
        </p>
        {hasTemporaryPassword && isTemporaryPasswordVisible ? (
          <span className="temporary-password">{temporaryPassword}</span>
        ) : (
          <span className="change-password-form__current-password--hidden">
            <ContentstackText contentKey={`${contentstackPath}.hidden_password_content`} />
          </span>
        )}
      </div>
      <div className="change-password-form__spacer" />
      <div className="change-password-form__buttons grid-x align-end">
        <CorButton onClick={sendRequest} disabled={!canGeneratePassword}>
          <ContentstackText contentKey={`${contentstackPath}.generate_password_button_caption`} />
        </CorButton>
        <CorButton color="secondary" onClick={onCancel}>
          <ContentstackText contentKey={`${contentstackPath}.close_button_caption`} />
        </CorButton>
      </div>
    </div>
  );
};
