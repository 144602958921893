import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getCategoriesNavigation, getCategoriesNavigationActionConstants } from 'store/categories-navigation/actions';

export function* getCategoriesNavigationSagaHandler() {
  yield put(doRequest(getCategoriesNavigation, Endpoints.CATALOG_CATEGORIES_TREE, 'get'));
}

export default function* getCategoriesNavigationSaga() {
  yield takeLatest(getCategoriesNavigationActionConstants[Actions.REQUEST], getCategoriesNavigationSagaHandler);
}
