import React, { FC, PropsWithChildren } from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './cor-hero-carousel.scss';

export interface ICorHeroCarouselProps extends PropsWithChildren {}

export const CorHeroCarousel: FC<ICorHeroCarouselProps> = ({ children }) => {
  return (
    <div data-testid={'cor-hero-carousel'} className={'cor-hero-carousel'}>
      <Swiper modules={[Autoplay, Pagination]} autoplay={{ delay: 5000 }} loop={true} pagination={true} threshold={10}>
        {children &&
          React.Children.map(children, (child, index: number) => (
            <SwiperSlide key={index.toString()}>{child}</SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
