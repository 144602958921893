import { FC, useRef, useState } from 'react';
import { get } from 'lodash-es';
import classNames from 'classnames';
import { MagnifierContainer, MagnifierPreview, MagnifierZoom } from '@ricarso/react-image-magnifiers';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { IContentstackImage } from 'components/contentstack';
import { detectTouchDevice } from 'utils/detect-touch-device';
import { useImageLoading } from 'hooks/use-image-loading';
import { LoadingIndicator } from 'components/loading-indicator';
import productPlaceholderSrc from 'assets/images/product-image-fallback.svg';

import './product-image.scss';

export interface ISize {
  height: number;
  width: number;
}

export interface IProductImageProps {
  src?: string;
  altText?: string;
  mobileSize?: ISize;
  desktopSize: ISize;
  zoomedMobileSize?: ISize;
  zoomedDesktopSize?: ISize;
  withLoading?: boolean;
  withOverlay?: boolean;
}

export const ProductImage: FC<IProductImageProps> = ({
  src,
  altText,
  mobileSize,
  desktopSize,
  zoomedDesktopSize,
  zoomedMobileSize,
  withLoading = false,
  withOverlay = false,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const { isMobile, isCustomTouchDevice } = useBreakpoint();
  const [isZooming, setIsZooming] = useState(false);
  const imageClass = classNames('product-image', {
    'product-image--zooming': isZooming,
  });
  const imageMediaClass = classNames('product-image__media', {
    'product-image__media--with-overlay': withOverlay,
  });

  const imageSize =
    detectTouchDevice() && zoomedMobileSize ? zoomedMobileSize : isMobile && mobileSize ? mobileSize : desktopSize;

  const imageQuality = detectTouchDevice() && zoomedMobileSize ? 90 : 70;
  const largeImageSize = zoomedDesktopSize ? zoomedDesktopSize : desktopSize;
  const imageSrc = src && injectImageSizeIntoSrc(src, imageSize, imageQuality);
  const largeImageSrc = src && injectImageSizeIntoSrc(src, largeImageSize, 90);
  const { imageLoaded } = useImageLoading(imageSrc);
  const content = useContentstackContent();
  const searchIcon: IContentstackImage = get(content, 'page_content.cursor_icon', {});

  const handleImageFallback = () => {
    if (imageRef.current) {
      imageRef.current.src = productPlaceholderSrc;
    }
  };

  const renderImageWithZoom = () =>
    imageSrc && (
      <MagnifierContainer>
        <div className="product-image__media-container">
          <MagnifierPreview
            imageSrc={imageSrc}
            cursorStyle={`url(${searchIcon.url}) 12 12,auto`}
            overlayOpacity={0}
            overlayBoxOpacity={0}
            onZoomStart={() => setIsZooming(true)}
            onZoomEnd={() => setIsZooming(false)}
          />
        </div>
        <div className="product-image__zoomed-container">
          <MagnifierZoom style={{ height: '340px', width: '440px' }} imageSrc={largeImageSrc || ''} />
        </div>
      </MagnifierContainer>
    );

  return (
    <div className={imageClass}>
      {withLoading ? (
        imageSrc && imageLoaded ? (
          <div className={imageMediaClass}>
            <img src={imageSrc} alt={altText} ref={imageRef} onError={handleImageFallback} />
          </div>
        ) : (
          <LoadingIndicator compact />
        )
      ) : (
        <>
          <div className="product-image__placeholder" />
          {imageSrc && (
            <div className={imageMediaClass}>
              {zoomedDesktopSize && isCustomTouchDevice ? (
                renderImageWithZoom()
              ) : (
                <img src={imageSrc} alt={altText} ref={imageRef} onError={handleImageFallback} />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

function injectImageSizeIntoSrc(src: string, size: ISize, quality: number = 70): string {
  return src.replace(
    /^(.*\/\/images.salsify.com\/image\/upload\/.*)(\/.*)/,
    `$1/w_${size.width},h_${size.height},q_${quality}$2`
  );
}
