const tiffImageUrlRegex = '.(tif?f)(/?.*?)$';
const tiffExtensionRegex = 'tif?f';
const fileRegex = new RegExp('[^.]+$');

export const contentStackImageTifExtensionHelper = (brandFolderImageUrl: string, queryParams: string) => {
  const match = brandFolderImageUrl.match(tiffImageUrlRegex);
  const isQueryParams = match?.[2];

  return brandFolderImageUrl.replace(
    new RegExp(tiffImageUrlRegex),
    `.jpg${isQueryParams ? `$2&${queryParams}` : `?${queryParams}`}`
  );
};

export const isTifExtension = (ext: string) => {
  return new RegExp(tiffExtensionRegex).test(ext);
};

export const getImageExtension = (image) => image && image.match(fileRegex)[0];
