import { put, takeLatest } from 'redux-saga/effects';
import { ISearchKey } from 'types/guest';
import { getSearchKeys, getSearchKeysActionConstants } from 'store/guest/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { getLocaleQuery } from 'utils/get-locale-params';
import { doRequest } from 'store/api/actions';
import { Actions } from 'constants/actions.enum';

export function* getSearchKeysSagaHandler() {
  yield put(doRequest<ISearchKey>(getSearchKeys, `${Endpoints.SEARCH_KEY}${getLocaleQuery()}`, 'get'));
}

export default function* getGuestSaga() {
  yield takeLatest(getSearchKeysActionConstants[Actions.REQUEST], getSearchKeysSagaHandler);
}
