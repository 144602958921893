import './cor-filter-dropdown-options.scss';

import React from 'react';
import { useAlgoliaQueryParams } from 'hooks';
import { ALGOLIA_DEFAULT_PAGE_NUMBER } from 'hooks/use-algolia';
import { CorFilterDropdownOption } from '../cor-filter-dropdown-option';
import { ICorFilterDropdownProps } from '../cor-filter-dropdown/cor-filter-dropdown.interface';

const MAX_OPTIONS_ROW = 15;

const CorFilterDropdownOptions = ({ facetKey, facetTerms = [] }: ICorFilterDropdownProps) => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const [selectionsState, setSelectionsState] = React.useState<string[]>(() =>
    JSON.parse(queryParams?.facets?.[facetKey as string] || '[]')
  );

  const handleSelection = React.useCallback(
    (facetTerm) => () => {
      setSelectionsState((prev) =>
        prev.includes(facetTerm) ? prev.filter((current) => ![current].includes(facetTerm)) : [...prev, facetTerm]
      );
    },
    []
  );

  React.useEffect(() => {
    setQueryParams({
      facets: {
        ...(queryParams?.facets || {}),
        [facetKey as string]: !!selectionsState?.length ? JSON.stringify(selectionsState) : undefined,
      },
      page: ALGOLIA_DEFAULT_PAGE_NUMBER,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsState, facetKey]);

  const optionsColumns = Math.ceil((facetTerms?.length || 0) / MAX_OPTIONS_ROW);

  return (
    <div className="cor-filter-dropdown-options">
      {!!facetTerms?.length && (
        <div
          className="cor-filter-dropdown-options__checkboxes"
          style={{ gridTemplateColumns: `repeat(${optionsColumns}, 1fr)` }}
        >
          {facetTerms?.map((facetTerm) => (
            <CorFilterDropdownOption
              label={facetTerm}
              key={facetTerm}
              isSelected={selectionsState.includes(facetTerm)}
              onCheckboxClick={handleSelection(facetTerm)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(CorFilterDropdownOptions);
