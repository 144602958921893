import './cor-filtered-list-empty.scss';

import { CorTypography } from 'corporate-ui';
import React from 'react';
import { useContent } from 'hooks/use-content';

const PREFIX_KEY = 'content_list.content_or_filtered_list.0';
const LABELS_KEY = `${PREFIX_KEY}.labels.0.key_value_pair.value`;

const CorFilteredListEmpty = () => {
  const { getLabelsByKey } = useContent();
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});

  return (
    <div className="cor-filtered-list__empty">
      <CorTypography variant="h2" component="h2">
        {labels?.not_found_title || 'No Results Found'}
      </CorTypography>
      <CorTypography variant="body-large">
        {labels?.not_found_description || 'Please try another search to see results.'}
      </CorTypography>
    </div>
  );
};

export default React.memo(CorFilteredListEmpty);
