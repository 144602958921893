import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { changeCustomerPassword, changeCustomerPasswordConstants } from 'store/dashboard/actions';

interface IChangePasswordData {
  newPassword: string;
}

export interface IChangePasswordPayload extends IChangePasswordData {
  accountId: string;
  onSuccessCallback?: () => void;
}

export function* changeCustomerPasswordHandler({
  payload: { accountId, ...requestData },
}: IAction<IChangePasswordPayload>) {
  yield put(
    doRequest<IChangePasswordData>(
      changeCustomerPassword,
      `${Endpoints.DASHBOARD_CSR_CUSTOMERS}/${accountId}/passwords`,
      'put',
      requestData
    )
  );
}

export function* changeCustomerPasswordSuccessHandler({ payload }: IAction<IChangePasswordPayload>) {
  yield take(changeCustomerPasswordConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export default function* changeCustomerPasswordSaga() {
  yield takeLatest(changeCustomerPasswordConstants[Actions.REQUEST], changeCustomerPasswordHandler);
  yield takeLatest(changeCustomerPasswordConstants[Actions.REQUEST], changeCustomerPasswordSuccessHandler);
}
