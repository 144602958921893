import { takeLatest, call, cancelled } from 'redux-saga/effects';
import axios from 'axios';
import { take } from 'lodash-es';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { apiSagaHandler } from 'store/api/sagas';
import { getOrderHistoryProductsActionConstants, getOrderHistoryProducts } from 'store/account/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IOrderHistoryProductsPayload {
  limit: number;
  offset: number;
  dateTimeFrom: string;
  dateTimeTo: string;
  searchText?: string;
  onSuccessCallback?: () => void;
}

export function* getOrderHistoryProductsHandler({
  payload: { limit, offset, dateTimeFrom, dateTimeTo, searchText },
}: IAction<IOrderHistoryProductsPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);
  const url = `${
    Endpoints.ORDER_HISTORY_PRODUCTS
  }/?limit=${limit}&offset=${offset}&dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}${
    searchQuery ? `&productSearchText=${encodeURIComponent(searchQuery)}` : ''
  }`;
  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  try {
    yield call(apiSagaHandler, doRequest(getOrderHistoryProducts, url, 'get', undefined, undefined, cancelToken));
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
}

export function* getOrderHistoryProductsOnSuccessCallbackHandler({ payload }: IAction<IOrderHistoryProductsPayload>) {
  yield take(getOrderHistoryProductsActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export default function* getOrderHistoryProductsSaga() {
  yield takeLatest(getOrderHistoryProductsActionConstants[Actions.REQUEST], getOrderHistoryProductsHandler);
  yield takeLatest(
    getOrderHistoryProductsActionConstants[Actions.REQUEST],
    getOrderHistoryProductsOnSuccessCallbackHandler
  );
}
