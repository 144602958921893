import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useGlobalModalState } from 'hooks/use-global-modal-state';

// Global Modal component
// Single instance of this component is defined in top of components tree
// Consumes global modal state from context
// Used to mount/unmount modal, show/hide modal with animation
// See https://github.com/dawn-foods-digital/b2b-storefront/pull/2342 for additional info and examples of use
export const GlobalModal: FC = () => {
  const {
    modal: { isOpen, config: { ModalComponent, modalComponentProps = {} } = {} },
    hideGlobalModal,
  } = useGlobalModalState();

  return (
    <CSSTransition in={isOpen} timeout={400} classNames="modal-wrapper" unmountOnExit>
      {ModalComponent ? <ModalComponent isOpen={isOpen} onClose={hideGlobalModal} {...modalComponentProps} /> : <></>}
    </CSSTransition>
  );
};
