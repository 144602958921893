import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { Routes } from 'constants/routes.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { verifyEmailByToken, verifyEmailByTokenActionConstants, setInvalidTokenError } from '../actions';

export interface IVerifyEmailByTokenPayload {
  token: string;
}

export function* verifyEmailByTokenSagaHandler({ payload }: IAction<IVerifyEmailByTokenPayload>) {
  yield put(
    doRequest<IVerifyEmailByTokenPayload>(
      verifyEmailByToken,
      `${Endpoints.CUSTOMER_EMAIL_VALIDATION_BY_TOKEN}/${payload.token}`,
      'post'
    )
  );
}

export function* verifyEmailByTokenFailSagaHandler({ error }: any) {
  yield put(setInvalidTokenError(error.errorMessageId));
  yield put(push(getLocalizedShopUrl(Routes.EmailVerificationPage)));
}

export default function* verifyEmailByTokenSaga() {
  yield takeLatest(verifyEmailByTokenActionConstants[Actions.REQUEST], verifyEmailByTokenSagaHandler);
  yield takeLatest(verifyEmailByTokenActionConstants[Actions.FAIL], verifyEmailByTokenFailSagaHandler);
}
