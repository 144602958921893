import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getProductDetailsActionConstants = createConstants(types.GET_PRODUCT_DETAILS);
export const getSkuSubtotalPriceConstants = createConstants(types.GET_SKU_SUBTOTAL_PRICE);
export const putRecentlyViewedItemConstants = createConstants(types.PUT_RECENTLY_VIEWED_ITEM);
export const getProductRecipesActionConstants = createConstants(types.GET_PRODUCT_RECIPES);

export const getProductDetails = createRequestActions(getProductDetailsActionConstants);
export const getSkuSubtotalPrice = createRequestActions(getSkuSubtotalPriceConstants);
export const putRecentlyViewedItem = createRequestActions(putRecentlyViewedItemConstants);
export const getProductRecipes = createRequestActions(getProductRecipesActionConstants);

export const resetProductDetails = () => action(types.RESET_PRODUCT_DETAILS);

export const setSelectedSku = (sku: string) => action(types.SET_SELECTED_SKU, { sku });
// TODO: P12020-12794 remove after done
// export const setDefaultProductQuantity = () =>
//   action(types.SET_PRODUCT_QUANTITY, productDetailsInitialState.productQuantity);
export const setProductQuantity = (quantity: number) => action(types.SET_PRODUCT_QUANTITY, quantity);
export const clearProductRecipes = () => action(types.CLEAR_PRODUCT_RECIPES);
