import './cor-footer-legal.scss';

import React from 'react';
import { CorContentstackLink } from 'corporate-components/cor-contentstack';
import { CorRegionSelector } from 'corporate-components/cor-region-selector';
import { ContentstackText } from 'components/contentstack';
import { useContent } from 'hooks/use-content';

const FOOTER_LEGAL_LINKS_KEY = 'footer_legal_links';

const CorFooterLegal = () => {
  const { getContentByKey } = useContent();
  const footerLegalLinks = getContentByKey(FOOTER_LEGAL_LINKS_KEY, []);

  return (
    <div className="cor-footer__legal cor-footer-mobile__legal" data-testid="cor-footer-mobile__legal">
      <div className="cor-footer-mobile__legal-links" data-testid="legal__links">
        {footerLegalLinks.map(({ opens_in_a_new_tab = false }, index) => (
          <CorContentstackLink
            key={index.toString()}
            contentKey={`${FOOTER_LEGAL_LINKS_KEY}[${index}].link_url`}
            className="links__link"
            opensInNewTab={opens_in_a_new_tab}
          />
        ))}
        <CorRegionSelector direction="up" />
      </div>
      <div className="legal__text cor-footer-mobile__legal-text" data-testid="legal__text">
        <p>
          <ContentstackText
            contentKey="copyright_block[0].text"
            interpolateParams={{ currentYear: new Date().getFullYear() }}
          />
        </p>
      </div>
    </div>
  );
};

export default React.memo(CorFooterLegal);
