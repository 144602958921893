import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import * as actions from './actions';
import types from './action-types';

export interface IResetPasswordState {
  emailIsSent: boolean;
  passwordIsCreated: boolean;
  tokenIsValidating: boolean;
  linkExpired: boolean;
}

export const resetPasswordInitialState: IResetPasswordState = {
  emailIsSent: false,
  passwordIsCreated: false,
  tokenIsValidating: false,
  linkExpired: false,
};

const resetPassword = createReducer<IResetPasswordState>(
  {
    [actions.sendVerificationEmailActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      emailIsSent: true,
    }),
    [types.RESET_PASSWORD_RESET_EMAIL_SEND_STATUS]: (state) => ({
      ...state,
      emailIsSent: false,
    }),
    [actions.sendNewPasswordActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      passwordIsCreated: true,
    }),
    [actions.validateTokenActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      tokenIsValidating: true,
    }),
    [actions.validateTokenActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      tokenIsValidating: false,
    }),
    [actions.validateTokenActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      tokenIsValidating: false,
    }),
    [types.SET_RESET_PASSWORD_LINK_EXPIRED]: (state, { payload: linkExpired }) => ({
      ...state,
      linkExpired,
    }),
  },
  resetPasswordInitialState
);

export default resetPassword;
