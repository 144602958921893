import { takeLatest, put, take } from 'redux-saga/effects';
import { isUndefined, omitBy } from 'lodash-es';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { submitCreditCardPayment, submitCreditCardPaymentActionConstants } from 'store/payment-transactions/actions';
import { IPaymentDocument } from 'components/modals/schedule-payment-modal';

export interface ISubmitCreditCardPaymentPayload {
  cardLastFourDigits: string;
  cardType: string;
  isDeposit?: boolean;
  depositComment?: string;
  depositAmount?: number;
  paymentDocuments: IPaymentDocument[];
  scheduledDate?: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: (messageId: string) => void;
}

export function* submitCreditCardPaymentSuccessSagaHandler({ payload }: IAction<ISubmitCreditCardPaymentPayload>) {
  yield take(submitCreditCardPaymentActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* submitCreditCardPaymentFailSagaHandler({ payload }: IAction<ISubmitCreditCardPaymentPayload>) {
  const { error } = yield take(submitCreditCardPaymentActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack && error) {
    payload.onFailCallBack(error.errorMessageId);
  }
}

export function* submitCreditCardPaymentSagaHandler({
  payload: { paymentDocuments, cardLastFourDigits, cardType, scheduledDate, isDeposit, depositComment, depositAmount },
}: IAction<ISubmitCreditCardPaymentPayload>) {
  const requestPayload = omitBy(
    {
      paymentDocuments,
      cardLastFourDigits,
      cardType,
      scheduledDate,
      isDeposit,
      depositComment,
      depositAmount,
    },
    isUndefined
  );

  yield put(
    doRequest<Partial<ISubmitCreditCardPaymentPayload>>(
      submitCreditCardPayment,
      Endpoints.SUBMIT_CREDIT_CARD_PAYMENT,
      'post',
      requestPayload
    )
  );
}

export default function* submitCreditCardPaymentSaga() {
  yield takeLatest(submitCreditCardPaymentActionConstants[Actions.REQUEST], submitCreditCardPaymentSuccessSagaHandler);
  yield takeLatest(submitCreditCardPaymentActionConstants[Actions.REQUEST], submitCreditCardPaymentFailSagaHandler);
  yield takeLatest(submitCreditCardPaymentActionConstants[Actions.REQUEST], submitCreditCardPaymentSagaHandler);
}
