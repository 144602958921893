import { put, take, takeLatest } from 'redux-saga/effects';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { Actions } from 'constants/actions.enum';
import { scheduleBankAccountPayment, scheduleBankAccountPaymentActionConstants } from 'store/schedule-payment/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { IPaymentDocument } from 'components/modals/schedule-payment-modal';

export interface IScheduleBankAccountPaymentPayload {
  paymentDocuments: IPaymentDocument[];
  accountType: string;
  publicAccountNumber: string;
  publicRoutingNumber: string;
  scheduledDate: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: () => void;
}

export function* scheduleBankAccountPaymentOnSuccessSagaHandler({
  payload,
}: IAction<IScheduleBankAccountPaymentPayload>) {
  yield take(scheduleBankAccountPaymentActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* scheduleBankAccountPaymentOnFailSagaHandler({ payload }: IAction<IScheduleBankAccountPaymentPayload>) {
  yield take(scheduleBankAccountPaymentActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack) {
    payload.onFailCallBack();
  }
}

export function* scheduleBankAccountPaymentHandler({
  payload: { paymentDocuments, accountType, publicAccountNumber, publicRoutingNumber, scheduledDate },
}: IAction<IScheduleBankAccountPaymentPayload>) {
  const url = `${Endpoints.SUBMIT_ACH_PAYMENT}`;
  yield put(
    doRequest(scheduleBankAccountPayment, url, 'post', {
      paymentDocuments,
      accountType,
      publicAccountNumber,
      publicRoutingNumber,
      scheduledDate,
    })
  );
}

export default function* scheduleBankAccountPaymentSaga() {
  yield takeLatest(
    scheduleBankAccountPaymentActionConstants[Actions.REQUEST],
    scheduleBankAccountPaymentOnSuccessSagaHandler
  );
  yield takeLatest(
    scheduleBankAccountPaymentActionConstants[Actions.REQUEST],
    scheduleBankAccountPaymentOnFailSagaHandler
  );
  yield takeLatest(scheduleBankAccountPaymentActionConstants[Actions.REQUEST], scheduleBankAccountPaymentHandler);
}
