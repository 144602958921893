import './cor-footer-newsletter.scss';

import React from 'react';
import classNames from 'classnames';
import { CorContentstackLink } from 'corporate-components';
import { ICorContentstackLink } from 'corporate-components';
import { ContentstackText } from 'components/contentstack';
import { CorFooterNewsletterModal } from 'components/modals/cor-footer-newsletter-modal';
import { useContent } from 'hooks/use-content';

const CorFooterNewsletter = ({ className = '' }) => {
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const { getContentByKey } = useContent();
  const buttonContent = getContentByKey<ICorContentstackLink>('newsletter_signup.button.button', {});
  const isOpenInNewTab: boolean = getContentByKey('newsletter_signup.button.open_in_a_new_tab', true);

  const handleCloseModal = React.useCallback(() => setIsModalOpened(false), []);
  const handleOpenModal = React.useCallback(() => setIsModalOpened(true), []);

  return (
    <div className={classNames('cor-footer-newsletter', className)} data-testid="cor-footer-newsletter">
      <h3 className="cor-footer__menu-link__title" data-testid="cor-footer__menu-link__title">
        <ContentstackText contentKey="newsletter_signup.title" />
      </h3>
      <p className="newsletter__description" data-testid="newsletter__description">
        <ContentstackText contentKey="newsletter_signup.description" />
      </p>
      {buttonContent?.href && (
        <button className="newsletter__submit">
          <CorContentstackLink
            data-testid={'newsletter__submit-link'}
            contentKey={''}
            data={buttonContent}
            aria-label={buttonContent?.title}
            opensInNewTab={isOpenInNewTab}
          >
            {buttonContent?.title}
          </CorContentstackLink>
          <span className="cor-text-link__caret__isolated" />
        </button>
      )}

      {!buttonContent?.href && (
        <button className="newsletter__submit" onClick={handleOpenModal}>
          <ContentstackText contentKey="newsletter_signup.button.button.title" />
          <span className="cor-text-link__caret__isolated" />
        </button>
      )}

      <CorFooterNewsletterModal isOpen={isModalOpened} onClose={handleCloseModal} contentKey="newsletter_form.0" />
    </div>
  );
};

export default React.memo(CorFooterNewsletter);
