import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContent } from 'hooks/use-content';
import { IUserPersonalInformation } from 'types/user-info';
import { ChangeEmailAddressForm } from 'pages/dashboard/components/customer-locations-item/components/change-email-address-form';
import { ChangePasswordForm } from 'pages/dashboard/components/customer-locations-item/components/change-password-form';
import { AccountActions } from 'pages/dashboard/components/customer-locations-item/components/account-actions';
import { PageLeaveConfirmationModal } from 'components/page-leave-confirmation-modal';
import { AccountStatuses } from 'constants/account-statuses';
import { selectHasUnsavedChanges } from 'store/dashboard/selectors';
import { setHasUnsavedChanges } from 'store/dashboard/actions';
import { AccountStatusBadge } from '../account-status-badge';

import './customer-location-edit-section.scss';

export enum CustomerLocationEditTabsEnum {
  EmailAddress,
  Password,
  AccountActions,
}

export interface ICsrFormProps {
  accountAtLocation: IUserPersonalInformation;
  locationId: string;
  onCancel: () => void;
  reopenCsrEditUserInfoModal?: () => void;
  setLocationInEditMode?: () => void;
}

export interface ICustomerLocationEditTab {
  label: string;
  value: CustomerLocationEditTabsEnum;
  component: FC<ICsrFormProps>;
  disabled?: boolean;
}

export const CustomerLocationEditSection: FC<ICsrFormProps> = ({
  accountAtLocation,
  locationId,
  onCancel,
  reopenCsrEditUserInfoModal,
  setLocationInEditMode,
}) => {
  const contentstackPath = 'modals.0.customer_location_item_edit_modal.0';
  const dispatch = useDispatch();
  const { isDesktop } = useBreakpoint();
  const { getContentByKey } = useContent();
  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');
  const [selectedTab, setSelectedTab] = useState<CustomerLocationEditTabsEnum>(
    CustomerLocationEditTabsEnum.EmailAddress
  );
  const hasUnsavedChanges = useSelector(selectHasUnsavedChanges);

  const customerLocationEditTabsConfig: ICustomerLocationEditTab[] = [
    {
      label: getContentByKey(`${contentstackPath}.email_address_tab.title`, ''),
      value: CustomerLocationEditTabsEnum.EmailAddress,
      component: ChangeEmailAddressForm,
    },
    {
      label: getContentByKey(`${contentstackPath}.password_tab.title`, ''),
      value: CustomerLocationEditTabsEnum.Password,
      component: ChangePasswordForm,
    },
    {
      label: getContentByKey(`${contentstackPath}.account_actions_tab.title`, ''),
      value: CustomerLocationEditTabsEnum.AccountActions,
      component: AccountActions,
      disabled: accountAtLocation.accountStatus !== AccountStatuses.LOCKED,
    },
  ];

  const createTabSelectHandler = useCallback(
    (tab: CustomerLocationEditTabsEnum) => () => {
      let transitionIsConfirmed = true;

      if (hasUnsavedChanges) {
        transitionIsConfirmed = window.confirm(leavingPageModalText);
      }

      if (transitionIsConfirmed) {
        dispatch(setHasUnsavedChanges(false));
        setSelectedTab(tab);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, hasUnsavedChanges]
  );

  useEffect(() => {
    return () => {
      dispatch(setHasUnsavedChanges(false));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(false));
  }, [dispatch, selectedTab]);

  useEffect(() => {
    setSelectedTab(CustomerLocationEditTabsEnum.EmailAddress);
  }, [accountAtLocation.id]);

  const selectedTabConfig = customerLocationEditTabsConfig.find(({ value }) => value === selectedTab);
  const renderCustomerLocationEditNavItem = ({ label, value, disabled }: ICustomerLocationEditTab) => (
    <div
      key={value}
      onClick={createTabSelectHandler(value)}
      className={classNames('customer-location-edit-section__tabs-item', {
        active: value === selectedTab && !disabled,
        disabled: disabled,
      })}
    >
      {label}
    </div>
  );

  return (
    <div className="customer-location-edit-section">
      <div className="customer-location-edit-section__header">
        {isDesktop &&
          [AccountStatuses.LOCKED, AccountStatuses.UNVERIFIED].includes(accountAtLocation.accountStatus) && (
            <AccountStatusBadge accountStatus={accountAtLocation.accountStatus} />
          )}
        <ul className="customer-location-edit-section__tabs">
          {customerLocationEditTabsConfig.map(renderCustomerLocationEditNavItem)}
        </ul>
      </div>
      <div className="customer-location-edit-section__content">
        {selectedTabConfig && (
          <selectedTabConfig.component
            accountAtLocation={accountAtLocation}
            locationId={locationId}
            onCancel={onCancel}
            reopenCsrEditUserInfoModal={reopenCsrEditUserInfoModal}
            setLocationInEditMode={setLocationInEditMode}
          />
        )}
      </div>
      {hasUnsavedChanges && <PageLeaveConfirmationModal />}
    </div>
  );
};
