import { FC, useState } from 'react';
import classNames from 'classnames';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { CustomCheckbox } from 'components/custom-checkbox';
import iconFavorites from 'assets/images/icons/icon-heart.svg';

import './add-to-shopping-list-modal-list-item.scss';

export interface IAddToShoppingListModalListItemProps {
  name: string;
  totalItems: number;
  id: string;
  onChangeCheckbox: (sku: string, quantity: number) => void;
  isFavorites: boolean;
  disabled?: boolean;
}

export const AddToShoppingListModalListItem: FC<IAddToShoppingListModalListItemProps> = ({
  name,
  totalItems,
  id,
  onChangeCheckbox,
  isFavorites,
  disabled,
}) => {
  const contentstackPath = 'modals.0.add_to_shopping_list_modal.0';
  const className = classNames('add-to-shopping-list-modal-list-item', {
    'add-to-shopping-list-modal-list-item--favorites': isFavorites,
    'add-to-shopping-list-modal-list-item--disabled': disabled,
  });

  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    setIsSelected(!isSelected);
    onChangeCheckbox(id, totalItems);
  };

  return (
    <div className={className}>
      <div className="add-to-shopping-list-modal-list-item__form">
        <div className="add-to-shopping-list-modal-list-item__list-name">
          <CustomCheckbox
            ariaLabel={name}
            id={`list-${id}`}
            className="add-to-shopping-list-modal-list-item__list-checkbox"
            selected={isSelected}
            disabled={disabled}
            onCheckboxClick={handleClick}
          />
          {isFavorites ? (
            <>
              <div
                className="add-to-shopping-list-modal-list-item__favorites-icon"
                style={{ maskImage: `url(${iconFavorites})`, WebkitMaskImage: `url(${iconFavorites})` }}
              />
              <ContentstackText contentKey={`${contentstackPath}.favorites_list_name`} />
            </>
          ) : (
            name
          )}
        </div>
        <div className="add-to-shopping-list-modal-list-item__items-number">
          <p className="add-to-shopping-list-modal-list-item__items-number-label">
            <ContentstackText contentKey={`${contentstackPath}.number_of_items_label`} />
          </p>
          <p className="add-to-shopping-list-modal-list-item__items-number-value">{totalItems}</p>
        </div>
      </div>
    </div>
  );
};
