import { Fragment } from 'react';
import { Layouts } from 'constants/layouts.enum';
import { DefaultLayout } from './default-layout';
import { AuthLayout } from './auth-layout';
import { CheckoutPageLayout } from './checkout-page-layout';
import { InternalUserLayout } from './internal-user-layout';
import { BasicLayout } from './basic-layout';
import { CorporateLayout } from './corporate-layout';
import { LivePreviewLayout } from './live-preview-layout';
import { AccountLayout } from './account-layout';

export const LayoutsMap = {
  [Layouts.Default]: DefaultLayout,
  [Layouts.Auth]: AuthLayout,
  [Layouts.Checkout]: CheckoutPageLayout,
  [Layouts.InternalUser]: InternalUserLayout,
  [Layouts.Basic]: BasicLayout,
  [Layouts.Corporate]: CorporateLayout,
  [Layouts.LivePreview]: LivePreviewLayout,
  [Layouts.AccountLayout]: AccountLayout,
};

export const getLayoutComponent = (layout: Layouts) => LayoutsMap[layout] || DefaultLayout;

export const getAvailableLayoutComponent = (
  layout: Layouts | undefined,
  unauthorizedLayout: Layouts | undefined,
  isPublic: boolean,
  isAuthenticated: boolean
) => {
  const publicLayoutType = isAuthenticated ? layout : unauthorizedLayout;
  const layoutType = isPublic ? publicLayoutType : layout;

  return layoutType ? getLayoutComponent(layoutType) : Fragment;
};
