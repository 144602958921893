const tiffImageUrlRegex = '.(tif?f)(/?.*?)$';
const tiffExtensionRegex = 'tif?f';

export const corContentStackImageTifExtensionHelper = (brandFolderImageUrl: string, queryParams: string) => {
  const match = brandFolderImageUrl.match(tiffImageUrlRegex);
  const isQueryParams = match?.[2];

  return brandFolderImageUrl.replace(
    new RegExp(tiffImageUrlRegex),
    `.jpg${isQueryParams ? `$2&${queryParams}` : `?${queryParams}`}`
  );
};

export const isTifExtension = (ext: string) => {
  return new RegExp(tiffExtensionRegex).test(ext);
};

export const isTifImage = (image) => {
  const imageExtension = image.extension || image.cdn_url.match(tiffImageUrlRegex)?.[1];
  return imageExtension && isTifExtension(imageExtension);
};
