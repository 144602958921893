import { combineReducers } from 'redux';
import { isEmpty } from 'lodash-es';
import { Actions } from 'constants/actions.enum';
import { ILineItem } from 'types/line-item';
import { IPriceModel, IShoppingListProduct, IShoppingList } from 'types/product';
import { createReducer } from 'store/reducer-creator';
import { PAGINATION_SETTINGS } from 'constants/pagination.enum';
import { IDeactivatedLineItemDetails, IProprietaryItemsWithoutPriceDetails } from 'types/order-details';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import * as actions from './actions';
import types from './action-types';

export interface IShoppingListDetails {
  id: string;
  items: IShoppingListProduct[];
  name: string;
  subtotal: IPriceModel | null;
  totalItems: number;
  totalQuantity: number;
  type: string;
  suggestedByDawn: boolean;
  endDate?: string;
}

export interface IShoppingListsInfo {
  limit: number;
  offset: number;
  shoppingLists: IShoppingList[];
  marketingShoppingLists: IShoppingList[];
  total: number;
  loading: boolean;
}

const shoppingListsInfoInitialState = {
  limit: PAGINATION_SETTINGS.defaultLimit,
  offset: PAGINATION_SETTINGS.defaultOffset,
  shoppingLists: [],
  marketingShoppingLists: [],
  total: 0,
  loading: false,
};

export interface IShoppingListsState {
  shoppingListsInfo: IShoppingListsInfo;
}

export interface IShoppingListDetailsInfo {
  shoppingListDetails: IShoppingListDetails;
  loading: boolean;
}

export interface IShoppingListsState {
  shoppingListDetailsInfo: IShoppingListDetailsInfo;
  shoppingListsInfo: IShoppingListsInfo;
  isProductBeingUpdated: boolean;
  isShoppingListBeingCreated: boolean;
  itemsBeingAddedToSL: boolean;
  hasShoppingListNameDuplicateError: boolean;
  addedItemsNotificationInfo: IAddedItemsNotificationInfo;
}

export interface IAddedItemsNotificationInfo {
  shoppingListsCounter?: number;
  shoppingListName?: string;
  isCreatingNewList?: boolean;
  addedToListItemsCounter?: number;
  deactivatedLineItems?: IDeactivatedLineItemDetails[];
  proprietarySkusWithoutPrice?: IProprietaryItemsWithoutPriceDetails[];
  deactivatedShoppingListLineItems?: IDeactivatedLineItemDetails[];
}

export const shoppingListsInitialState: IShoppingListsState = {
  isProductBeingUpdated: false,
  isShoppingListBeingCreated: false,
  itemsBeingAddedToSL: false,
  hasShoppingListNameDuplicateError: false,
  shoppingListsInfo: shoppingListsInfoInitialState,
  shoppingListDetailsInfo: {
    shoppingListDetails: {
      id: '',
      items: [],
      name: '',
      subtotal: null,
      totalItems: 0,
      totalQuantity: 0,
      type: '',
      suggestedByDawn: false,
    },
    loading: false,
  },
  addedItemsNotificationInfo: {
    shoppingListsCounter: 0,
    shoppingListName: '',
    addedToListItemsCounter: 0,
    deactivatedLineItems: [],
    proprietarySkusWithoutPrice: [],
    deactivatedShoppingListLineItems: [],
  },
};

const updateItemsOnAddToCartFail = (error: any, state: IShoppingListDetailsInfo) => {
  if (error.status === 400 && !isEmpty(error.shortSupplyItems)) {
    return {
      ...state,
      shoppingListDetails: {
        ...state.shoppingListDetails,
        items: state.shoppingListDetails.items.map((item) => ({
          ...item,
          maximumQuantity:
            error?.shortSupplyItems &&
            error.shortSupplyItems.some((shortSupplyItem: any) => item.itemNumber.includes(shortSupplyItem.sku))
              ? 0
              : item.maximumQuantity || undefined,
        })),
      },
    };
  } else {
    return {
      ...state,
    };
  }
};

const updateItemsOnAddToCartSuccess = (payload: any, state: IShoppingListDetailsInfo) => {
  if (!isEmpty(payload.shortSupplyItems)) {
    return {
      ...state,
      shoppingListDetails: {
        ...state.shoppingListDetails,
        items: state.shoppingListDetails.items.map((item) => ({
          ...item,
          maximumQuantity: payload.shortSupplyItems.some((shortSupplyItem: any) =>
            item.itemNumber.includes(shortSupplyItem.sku)
          )
            ? 0
            : item.maximumQuantity || undefined,
        })),
      },
    };
  } else {
    return {
      ...state,
    };
  }
};

const updateShoppingListsInfoOnAddToCartSuccess = (payload: any, state: IShoppingListsInfo) => {
  if (!isEmpty(payload.shortSupplyItems)) {
    return {
      ...state,
      shoppingLists: [
        ...state.shoppingLists.map((shoppingList) => ({
          ...shoppingList,
          items: shoppingList.items.map((shoppingListItem) =>
            payload.shortSupplyItems.some((shortSupplyItem: any) =>
              shoppingListItem.itemNumber.includes(shortSupplyItem.sku)
            )
              ? { ...shoppingListItem, maximumQuantity: 0 }
              : shoppingListItem
          ),
        })),
      ],
      marketingShoppingLists: [
        ...state.marketingShoppingLists.map((marketingShoppingList) => ({
          ...marketingShoppingList,
          items: marketingShoppingList.items.map((marketingShoppingListItem) =>
            payload.shortSupplyItems.some((shortSupplyItem: any) =>
              marketingShoppingListItem.itemNumber.includes(shortSupplyItem.sku)
            )
              ? { ...marketingShoppingListItem, maximumQuantity: 0 }
              : marketingShoppingListItem
          ),
        })),
      ],
    };
  } else {
    return {
      ...state,
    };
  }
};

const shoppingListsInfo = createReducer<IShoppingListsInfo>(
  {
    [actions.getShoppingListsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getShoppingListsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [actions.getShoppingListsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getMarketingShoppingListsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getMarketingShoppingListsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      marketingShoppingLists: payload.shoppingLists,
      loading: false,
    }),
    [actions.getMarketingShoppingListsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getAllShoppingListsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getAllShoppingListsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      shoppingLists: payload.shoppingLists,
      loading: false,
    }),
    [actions.getAllShoppingListsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.addShoppingListToCartActionConstants[Actions.SUCCESS]]: (state, { payload }) =>
      updateShoppingListsInfoOnAddToCartSuccess(payload, state),
    [types.SHOPPING_LISTS_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      offset: state.limit * payload.current,
    }),
  },
  shoppingListsInitialState.shoppingListsInfo
);

const shoppingListDetailsInfo = createReducer<IShoppingListDetailsInfo>(
  {
    [actions.getShoppingListDetailsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getShoppingListDetailsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      shoppingListDetails: payload,
      loading: false,
    }),
    [actions.updateShoppingListNameActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      shoppingListDetails: {
        ...state.shoppingListDetails,
        name: payload.name,
      },
      loading: false,
    }),
    [actions.getShoppingListDetailsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.updateShoppingListItemActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      shoppingListDetails: payload,
    }),
    [actions.deleteShoppingListItemActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      shoppingListDetails: payload,
    }),
    [actions.addShoppingListToCartActionConstants[Actions.SUCCESS]]: (state, { payload }) =>
      updateItemsOnAddToCartSuccess(payload, state),
    [shoppingCartActions.addMultipleProductsToCartActionConstants[Actions.SUCCESS]]: (state, { payload }: any) =>
      updateItemsOnAddToCartSuccess(payload, state),
    [shoppingCartActions.addProductToCartActionConstants[Actions.FAIL]]: (state, { error }: any) =>
      updateItemsOnAddToCartFail(error, state),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      shoppingListDetails: {
        ...state.shoppingListDetails,
        items: state.shoppingListDetails.items.map((product) => ({
          ...product,
          isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.itemNumber),
        })),
      },
    }),
    [types.RESET_SHOPPING_LIST_DETAILS]: () => shoppingListsInitialState.shoppingListDetailsInfo,
    [types.UPDATE_SHOPPING_LIST_DETAILS_ITEMS_ORDER]: (
      state,
      { payload: { itemsWithUpdatedOrder } }: { payload: { itemsWithUpdatedOrder: IShoppingListProduct[] } }
    ) => ({
      ...state,
      shoppingListDetails: {
        ...state.shoppingListDetails,
        items: [...itemsWithUpdatedOrder],
      },
    }),
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      shoppingListDetails: {
        ...state.shoppingListDetails,
        items: state.shoppingListDetails.items.map((product) => ({
          ...product,
          isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.itemNumber),
        })),
      },
    }),
  },
  shoppingListsInitialState.shoppingListDetailsInfo
);

const isProductBeingUpdated = createReducer<boolean>(
  {
    [actions.addProductToFavoritesActionConstants[Actions.REQUEST]]: () => true,
    [actions.addProductToFavoritesActionConstants[Actions.SUCCESS]]: () => false,
    [actions.addProductToFavoritesActionConstants[Actions.FAIL]]: () => false,
    [actions.removeProductFromFavoritesActionConstants[Actions.REQUEST]]: () => true,
    [actions.removeProductFromFavoritesActionConstants[Actions.SUCCESS]]: () => false,
    [actions.removeProductFromFavoritesActionConstants[Actions.FAIL]]: () => false,
    [actions.updateShoppingListItemActionConstants[Actions.REQUEST]]: () => true,
    [actions.updateShoppingListItemActionConstants[Actions.SUCCESS]]: () => false,
    [actions.updateShoppingListItemActionConstants[Actions.FAIL]]: () => false,
    [actions.deleteShoppingListItemActionConstants[Actions.REQUEST]]: () => true,
    [actions.deleteShoppingListItemActionConstants[Actions.SUCCESS]]: () => false,
    [actions.deleteShoppingListItemActionConstants[Actions.FAIL]]: () => false,
    [actions.deleteShoppingListActionConstants[Actions.REQUEST]]: () => true,
    [actions.deleteShoppingListActionConstants[Actions.SUCCESS]]: () => false,
    [actions.deleteShoppingListActionConstants[Actions.FAIL]]: () => false,
  },
  shoppingListsInitialState.isProductBeingUpdated
);

const isShoppingListBeingCreated = createReducer<boolean>(
  {
    [actions.createEmptyShoppingListActionConstants[Actions.REQUEST]]: () => true,
    [actions.createEmptyShoppingListActionConstants[Actions.SUCCESS]]: () => false,
    [actions.createEmptyShoppingListActionConstants[Actions.FAIL]]: () => false,
    [actions.createShoppingListWithProductsActionConstants[Actions.REQUEST]]: () => true,
    [actions.createShoppingListWithProductsActionConstants[Actions.SUCCESS]]: () => false,
    [actions.createShoppingListWithProductsActionConstants[Actions.FAIL]]: () => false,
    [actions.createShoppingListWithProductsFromMarketingShoppingListActionConstants[Actions.REQUEST]]: () => true,
    [actions.createShoppingListWithProductsFromMarketingShoppingListActionConstants[Actions.SUCCESS]]: () => false,
    [actions.createShoppingListWithProductsFromMarketingShoppingListActionConstants[Actions.FAIL]]: () => false,
  },
  shoppingListsInitialState.isShoppingListBeingCreated
);

const itemsBeingAddedToSL = createReducer<boolean>(
  {
    [actions.addProductsFromMarketingShoppingListActionConstants[Actions.REQUEST]]: () => true,
    [actions.addProductsFromMarketingShoppingListActionConstants[Actions.SUCCESS]]: () => false,
    [actions.addProductsFromMarketingShoppingListActionConstants[Actions.FAIL]]: () => false,
    [actions.addItemsToShoppingListsActionConstants[Actions.REQUEST]]: () => true,
    [actions.addItemsToShoppingListsActionConstants[Actions.SUCCESS]]: () => false,
    [actions.addItemsToShoppingListsActionConstants[Actions.FAIL]]: () => false,
  },
  shoppingListsInitialState.itemsBeingAddedToSL
);

const addedItemsNotificationInfo = createReducer<IAddedItemsNotificationInfo>(
  {
    [types.SET_ADDED_ITEMS_NOTIFICATION_INFO]: (state, { payload }) => payload,
  },
  shoppingListsInitialState.addedItemsNotificationInfo
);

const hasShoppingListNameDuplicateError = createReducer<boolean>(
  {
    [types.HAS_SHOPPING_LIST_NAME_DUPLICATE_ERROR]: (state, { payload }) => payload,
    [types.RESET_HAS_SHOPPING_LIST_NAME_DUPLICATE_ERROR]: () =>
      shoppingListsInitialState.hasShoppingListNameDuplicateError,
  },
  shoppingListsInitialState.hasShoppingListNameDuplicateError
);

export default combineReducers({
  shoppingListDetailsInfo,
  isProductBeingUpdated,
  isShoppingListBeingCreated,
  itemsBeingAddedToSL,
  shoppingListsInfo,
  addedItemsNotificationInfo,
  hasShoppingListNameDuplicateError,
});
