import { useEffect, useMemo, useState } from 'react';
import { template, isEmpty } from 'lodash-es';
import { useAlgolia, useAlgoliaQueryParams } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { parseAlgoliaPagination } from 'corporate-utils/cor-algolia-helpers';
import { CorTypography } from 'corporate-ui';
import * as contentStackConstant from 'corporate-components/cor-filtered-list/cor-filtered-list-constants';
import { FilterMenuTypeEnum } from 'corporate-components/cor-filtered-list/cor-filtered-list.config';
import useCorFilteredList from 'corporate-components/cor-filtered-list/use-cor-filtered-list';
import { FilterMenuTypeMap } from 'corporate-components/cor-filtered-list/cor-filtered-list.mapper';
import {
  CorFilterDrawerOpenerButton,
  CorFiltersDrawer,
  CorClearFilterButton,
  CorFilterSearchTerm,
  CorFilterDropdown,
  CorFilteredListCards,
  CorFilteredListEmpty,
  CorFilterButton,
} from 'corporate-components/cor-filtered-list/components';
import { CorChip } from 'components/cor-chip';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { selectTemporaryFacets } from 'store/cor-temporary-facets/selectors';
import { clearTemporarySelectedFacets, setTemporarySelectedFilters } from 'store/cor-temporary-facets/actions';
import { ALGOLIA_DEFAULT_PAGE_NUMBER } from 'hooks/use-algolia';
import { useContent } from 'hooks/use-content';
import { IFacet } from 'types/facets';

import './cor-filtered-list.scss';

const CorFilteredList = () => {
  const dispatch = useDispatch();
  const { getContentByKey, getLabelsByKey } = useContent();
  const { isMobile } = useBreakpoint();
  const { drawer, chips } = useCorFilteredList();
  const [facets, setFacets] = useState<IFacet[]>([]);
  const temporaryFacets = useSelector(selectTemporaryFacets);

  const filterMenuType = getContentByKey<FilterMenuTypeEnum>(
    contentStackConstant.FILTER_MENU_TYPE_KEY,
    FilterMenuTypeEnum.Recipes
  );
  const labels = getLabelsByKey<Record<string, string>>(contentStackConstant.LABELS_KEY, {});
  const pageType = FilterMenuTypeMap.get(filterMenuType);

  const pageOffset = useMemo(
    () => (isMobile ? contentStackConstant.PAGINATION_OFFSET.mobile : contentStackConstant.PAGINATION_OFFSET.desktop),
    [isMobile]
  );

  useEffect(() => {
    return () => {
      dispatch(clearTemporarySelectedFacets());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { queryParams } = useAlgoliaQueryParams();

  const algoliaQuery = useAlgolia({
    page: queryParams?.page || ALGOLIA_DEFAULT_PAGE_NUMBER,
    pageType,
    query: queryParams?.query || '',
    facets: queryParams?.facets,
    pageOffset,
  });

  const algoliaData = algoliaQuery?.data?.globalPageTypeContents?.[0];
  const algoliaMetaData = algoliaQuery?.data?.globalPageTypeMeta;

  const setFacetTerms = () => {
    let facets = {};

    algoliaData?.facets.forEach((facet) => {
      if (facet.key) facets[facet.key] = Object.keys(facet.terms);
    });

    return facets;
  };

  useEffect(() => {
    if (algoliaData?.facets) dispatch(setTemporarySelectedFilters(setFacetTerms()));
    if (algoliaData?.facets && isEmpty(facets)) setFacets(algoliaData?.facets);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algoliaData?.facets]);

  return (
    <>
      <div className="cor-filtered-list__header">
        <div className="grid-container">
          <CorTypography variant="h2" component="h1">
            {algoliaData?.title}
          </CorTypography>
          {!isMobile && !isEmpty(facets) && (
            <div className="cor-filtered-list__filters">
              {facets?.map((facet) => {
                if (isEmpty(facet)) return null;
                const facetTerms = temporaryFacets[facet.key];
                const isBooleanControl = facetTerms?.some((facetTerm) => ['true', 'false'].includes(facetTerm));
                const Component = isBooleanControl ? CorFilterButton : CorFilterDropdown;

                if (
                  !facetTerms?.length ||
                  (isBooleanControl && !facetTerms?.some((facetTerm) => ['true'].includes(facetTerm)))
                )
                  return null;

                return (
                  <Component
                    key={facet.key}
                    buttonLabel={facet.label}
                    facetKey={facet.key}
                    facetTerms={isBooleanControl ? ['true'] : facetTerms}
                    pageType={pageType}
                  />
                );
              })}
            </div>
          )}
          <div className="cor-filtered-list__header-controls">
            <CorFilterSearchTerm />
            {isMobile && !isEmpty(facets) && <CorFilterDrawerOpenerButton onToggleDrawer={drawer.handleOpenDrawer} />}
          </div>
        </div>
      </div>
      <div className="cor-filtered-list__body">
        <div className="grid-container">
          <div className="cor-filtered-list__body-header">
            {chips.hasChips && (
              <div className="cor-filtered-list__chips">
                {chips.chips?.map(({ facetTerm, facetKey }) => {
                  const isBooleanChip = ['true'].includes(facetTerm);
                  const facet = facets?.filter((facet) => facet?.key === facetKey)[0];

                  return (
                    <CorChip
                      key={`${facetKey}_${facetTerm}`}
                      label={isBooleanChip ? facet?.label : facetTerm}
                      onClick={chips.handleRemoveChip({
                        facetKey,
                        facetTerm: isBooleanChip ? 'true' : facetTerm,
                      })}
                    />
                  );
                })}
                <CorClearFilterButton onClick={chips.handleClearFilter} />
              </div>
            )}

            {!!algoliaMetaData?.page?.total_results && (
              <CorTypography variant="navigation-subheadings" className="cor-filtered-list__pagination-quantity">
                {template(labels?.pagination_results || '<%= from %>-<%= to %> of <%= total %> Results')(
                  parseAlgoliaPagination(algoliaMetaData)
                )}
              </CorTypography>
            )}
          </div>
          {algoliaQuery?.isSuccess && !!algoliaMetaData?.page?.total_results && (
            <CorFilteredListCards algoliaMetaData={algoliaMetaData} algoliaCards={algoliaData?.c_cards} />
          )}
          {(algoliaQuery?.isSuccess || algoliaQuery.isError) && !algoliaMetaData?.page?.total_results && (
            <CorFilteredListEmpty />
          )}
        </div>
      </div>
      {isMobile && drawer.isDrawerOpened && <CorFiltersDrawer onClose={drawer.handleCloseDrawer} />}
    </>
  );
};

export default CorFilteredList;
