import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import {
  getOrderDetailsActionConstants,
  cancelOrderActionConstants,
  getOrderDetails,
  cancelOrder,
  setOrderCancellationDenied,
} from 'store/account/actions';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';

export interface IOrderDetailsPayload {
  ctId: string;
}

export function* getOrderDetailsHandler({ payload: { ctId } }: IAction<IOrderDetailsPayload>) {
  yield put(doRequest(getOrderDetails, `${Endpoints.ORDER_HISTORY}/${ctId}`, 'get'));
}

export function* cancelOrderHandler({ payload: { ctId } }: IAction<IOrderDetailsPayload>) {
  yield put(doRequest(cancelOrder, `${Endpoints.ORDER_HISTORY}/${ctId}`, 'delete'));
}

export function* cancelOrderFailHandler() {
  yield put(setOrderCancellationDenied(true));
}

export default function* orderDetailsSaga() {
  yield takeLatest(getOrderDetailsActionConstants[Actions.REQUEST], getOrderDetailsHandler);
  yield takeLatest(cancelOrderActionConstants[Actions.REQUEST], cancelOrderHandler);
  yield takeLatest(cancelOrderActionConstants[Actions.FAIL], cancelOrderFailHandler);
}
