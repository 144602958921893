import { FC } from 'react';
import { keys } from 'lodash-es';
import { IAdditionalChargesExtended } from 'types/additional-charges';
import { CustomTooltip } from 'components/custom-tooltip';
import { formatCurrency, relocateCurrencyMinusSign } from 'utils/currency-format';
import { ContentstackMessage } from 'components/contentstack/contentstack-message';

export interface IAdditionalChargesTooltipProps {
  additionalChargesExtended: IAdditionalChargesExtended;
}

export const AdditionalChargesTooltip: FC<IAdditionalChargesTooltipProps> = ({ additionalChargesExtended }) => {
  const validAdditionalChargesKeys = keys(additionalChargesExtended).filter((key: string) => {
    const additionalChargesValue = additionalChargesExtended[key];

    return (
      additionalChargesValue.currencySymbol &&
      additionalChargesValue.amount &&
      Number(additionalChargesValue.amount) !== 0
    );
  });

  return validAdditionalChargesKeys.length ? (
    <CustomTooltip tooltipId="additionalCharges">
      <ContentstackMessage type="tooltips" messageId="MSG040" />
      <ul>
        {validAdditionalChargesKeys.map((key) => (
          <li key={key}>
            {key}: {relocateCurrencyMinusSign(formatCurrency(additionalChargesExtended[key]))}
          </li>
        ))}
      </ul>
    </CustomTooltip>
  ) : (
    <CustomTooltip tooltipId="additionalCharges">
      <ContentstackMessage type="tooltips" messageId="MSG039" />
    </CustomTooltip>
  );
};
