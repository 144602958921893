import { combineReducers } from 'redux';
import { Actions } from 'constants/actions.enum';
import { IPersonalInformation } from 'types/user-info';
import { createReducer } from 'store/reducer-creator';
import * as actions from './actions';
import types from './action-types';
import { IOrderHistoryState, orderHistory, orderHistoryInitialState } from './reducers/order-history';
import {
  IOrderHistoryProductsState,
  orderHistoryProducts,
  orderHistoryProductsInitialState,
} from './reducers/order-history-products';
import {
  IOrderHistoryProductsAllSkusState,
  orderHistoryProductsAllSkusInitialState,
  orderHistoryProductsAllSkus,
} from './reducers/order-history-all-products';
import {
  ISalesRepInformationState,
  salesRepInformation,
  salesRepInformationInitialState,
} from './reducers/sales-rep-information';
import { IOrderDetailsState, orderDetails, orderDetailsInitialState } from './reducers/order-details';
import {
  accountProfileInfo,
  accountProfileInfoInitialState,
  IAccountProfileInfoState,
} from './reducers/account-information';

export interface IPersonalInfoState extends IPersonalInformation {
  changePasswordFormServerError: string;
  changePasswordFormIsSubmitting: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface IEditingUserInformation {
  loading: boolean;
  allowToUpdateAccountType?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  accountTypes?: IAccountType[];
  locations?: ILocationInformation[];
  updatedLocations?: IUpdatedLocations;
  hasOrderSubscription?: boolean;
  hasQuoteSubscription?: boolean;
}

export interface IAccountType {
  accountType: string;
  isSelected: boolean;
}

export interface IUpdatedLocations {
  removedLocations: ILocationInformation[];
  assignedLocations: ILocationInformation[];
}

export interface ILocationInformation {
  name: string;
  locationId: string;
  isSelected: boolean;
  isDeleted: boolean;
}

export const editingUserInformationInitialState = {
  loading: false,
};

export const accountAndOrdersInfoState = {
  accountProfileInfo: accountProfileInfoInitialState,
  orderHistory: orderHistoryInitialState,
  orderDetails: orderDetailsInitialState,
  orderHistoryProducts: orderHistoryProductsInitialState,
};

export const invitationEmailInitialState = false;

export interface IAccountState {
  accountProfileInfo: IAccountProfileInfoState;
  orderHistory: IOrderHistoryState;
  orderHistoryProducts: IOrderHistoryProductsState;
  orderHistoryProductsAllSkus: IOrderHistoryProductsAllSkusState;
  orderDetails: IOrderDetailsState;
  editingUserInformation: IEditingUserInformation;
  salesRepInformation: ISalesRepInformationState;
  isInvitationEmailSent: boolean;
}

export const accountInitialState: IAccountState = {
  accountProfileInfo: accountProfileInfoInitialState,
  orderHistory: orderHistoryInitialState,
  orderHistoryProducts: orderHistoryProductsInitialState,
  orderHistoryProductsAllSkus: orderHistoryProductsAllSkusInitialState,
  orderDetails: orderDetailsInitialState,
  editingUserInformation: editingUserInformationInitialState,
  salesRepInformation: salesRepInformationInitialState,
  isInvitationEmailSent: invitationEmailInitialState,
};
export const editingUserInformation = createReducer<IEditingUserInformation>(
  {
    [actions.getUserInformationActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [actions.updateUserInfoActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      updatedLocations: payload,
    }),
    [types.CLEAR_UPDATED_USER_INFO]: () => ({
      ...editingUserInformationInitialState,
    }),
  },
  editingUserInformationInitialState
);

const isInvitationEmailSent = createReducer<boolean>(
  {
    [actions.resendInvitationToPendingUsersActionConstants[Actions.REQUEST]]: () => true,
    [types.RESET_INVITATION_EMAIL_STATUS]: () => false,
  },
  false
);

export default combineReducers<IAccountState>({
  accountProfileInfo,
  orderDetails,
  orderHistory,
  orderHistoryProducts,
  orderHistoryProductsAllSkus,
  editingUserInformation,
  salesRepInformation,
  isInvitationEmailSent,
});
