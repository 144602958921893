import * as Yup from 'yup';

interface ICorValidationMessages {
  required?: string;
  wrongFormat?: string;
  doNotMatch?: string;
}
const phonePattern = /^\d{10}$/;

export const CorBasicValidator = Yup.string();

export const CorPhoneValidator = ({ wrongFormat = '' }: ICorValidationMessages) =>
  Yup.string().matches(phonePattern, wrongFormat);

export const CorEmailValidator = ({ wrongFormat }: ICorValidationMessages) =>
  Yup.string().email(wrongFormat).min(1, wrongFormat).max(254, wrongFormat);

export const CorIsCheckedValidator = Yup.boolean();

export const CorConfirmValidator = ({ field_name, wrongFormat }) =>
  Yup.string().oneOf([Yup.ref(field_name), null], wrongFormat);
