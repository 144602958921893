import { FC, ReactNode } from 'react';
import { Field, ErrorMessage, useField, FieldAttributes } from 'formik';
import classNames from 'classnames';
import './cor-text-field.scss';
import { CorTypography } from 'corporate-ui';

export interface ICorTextField extends FieldAttributes<any> {
  name: string;
  type?: string;
  label?: string;
  adornment?: string;
  literal?: boolean;
  className?: string;
  renderHintContent?: () => ReactNode;
  highlight?: boolean;
  isRequired?: boolean;
}

export const CorTextField: FC<ICorTextField> = ({
  label = '',
  adornment,
  type = 'text',
  literal = false,
  renderHintContent,
  className,
  children,
  highlight,
  isRequired = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  const textFieldClass = classNames('cor-text-field', className, {
    'cor-text-field--error': meta.error && meta.touched,
    'cor-text-field--literal': literal,
  });

  const inputGroupClass = classNames('cor-text-field__input', {
    'cor-text-field__input-highlight': highlight,
  });

  const renderInput = () => {
    return adornment || literal ? (
      <div className="cor-text-field__input-decorated">
        <span className="cor-text-field__input-adornment">{adornment}</span>
        <Field type={type} className="cor-text-field__input" disabled={literal} value={field.value} {...props} />
      </div>
    ) : (
      <div className="cor-text-field__input-group">
        <Field type={type} className={inputGroupClass} {...props} />
        {children}
      </div>
    );
  };

  return (
    <div className={textFieldClass}>
      <CorTypography
        color="mid-gray"
        variant="small-caps"
        component="label"
        className="cor-text-field__label"
        htmlFor={field.name}
      >
        {!!label ? label.concat(isRequired ? '*' : '') : ''}
      </CorTypography>
      {renderInput()}
      <div className="cor-text-field__error-message__container">
        <ErrorMessage className="cor-text-field__error-message" name={field.name} component="p" />
      </div>
    </div>
  );
};
