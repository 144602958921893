import { put, takeLatest } from 'redux-saga/effects';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { getPaymentDetailsActionConstants, getPaymentDetails } from 'store/payment-details/actions';
import { Actions } from 'constants/actions.enum';

export interface IGetPaymentDetailsPayload {
  paymentId: string;
}

export function* getPaymentDetailsSagaHandler({ payload }: IAction<IGetPaymentDetailsPayload>) {
  yield put(doRequest(getPaymentDetails, `${Endpoints.PAYMENT_HISTORY}/${payload.paymentId}/details`, 'get', payload));
}

export default function* getPaymentDetailsSaga() {
  yield takeLatest(getPaymentDetailsActionConstants[Actions.REQUEST], getPaymentDetailsSagaHandler);
}
