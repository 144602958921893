import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'constants/routes.enum';
import { CorButton } from 'components/cor-button';
import { ContentstackImage, ContentstackText } from 'components/contentstack';

import './empty-order-updates.scss';

export const EmptyOrderUpdates: FC = () => {
  const history = useHistory();
  const handleStartShoppingBtnClick = () => history.push(Routes.ProductListPage);

  return (
    <div className="empty-order-updates">
      <div className="empty-order-updates__title">
        <ContentstackText contentKey="page_content.order_updates.0.empty_order_updates.message" />
      </div>
      <div className="empty-order-updates__icon">
        <ContentstackImage contentKey="page_content.order_updates.0.empty_order_updates.image" />
      </div>
      <div className="empty-order-updates__start-shopping-btn">
        <CorButton data-testid="empty-order-updates-start-shopping-btn" onClick={handleStartShoppingBtnClick}>
          <ContentstackText contentKey="page_content.order_updates.0.empty_order_updates.button" />
        </CorButton>
      </div>
    </div>
  );
};
