import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useContent } from 'hooks/use-content';
import { isExternalUrl } from 'utils/is-external-url';
import { Modal } from 'components/modals/common/modal';
import { ContentstackText } from 'components/contentstack';
import { CorButton } from 'components/cor-button';
import { CustomRadioButton } from 'components/custom-radio-button';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './where-did-you-find-us-modal.scss';

export interface IWhereDidYouFindUsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IWhereDidYouFindUsOption {
  title: string;
  url: string;
}

export const WhereDidYouFindUsModal: FC<IWhereDidYouFindUsModalProps> = ({ isOpen, onClose }) => {
  const { getContentByKey } = useContent();
  const [selectedOption, setSelectedOption] = useState<IWhereDidYouFindUsOption | null>(null);
  const history = useHistory();

  const contentstackPath = 'modals.0.where_did_you_find_us_modal.0';
  const radioButtonsList = getContentByKey(`${contentstackPath}.links`, []);

  const clearSelectedOptionAndClose = () => {
    setSelectedOption(null);
    onClose();
  };

  const handleSubmit = () => {
    if (!selectedOption?.url) {
      clearSelectedOptionAndClose();
      return;
    }

    if (isExternalUrl(selectedOption.url)) {
      window.open(selectedOption.url, '_blank', 'noopener, noreferrer');
      clearSelectedOptionAndClose();
      return;
    }

    history.push(selectedOption.url);
  };

  return (
    <Modal
      isOpen={isOpen}
      className="where-did-you-find-us-modal"
      size="medium"
      onClose={clearSelectedOptionAndClose}
      withBackdropClick
    >
      <ModalHeader titleContentstackPath={`${contentstackPath}.heading`} />
      <div className="where-did-you-find-us-modal__content">
        <p className="where-did-you-find-us-modal__description">
          <ContentstackText contentKey={`${contentstackPath}.description`} />
        </p>
        <div className="where-did-you-find-us-modal__radio-buttons">
          {radioButtonsList.map(({ link_item: { title, url } }, index) => {
            return (
              <CustomRadioButton
                checked={selectedOption?.title === title}
                onChange={() => setSelectedOption({ title, url })}
                key={index}
              >
                {title}
              </CustomRadioButton>
            );
          })}
        </div>
      </div>
      <ModalFooter className="where-did-you-find-us-modal__footer">
        <CorButton className="text where-did-you-find-us-modal__go-back" onClick={clearSelectedOptionAndClose}>
          <ContentstackText contentKey={`${contentstackPath}.go_back_button_label`} />
        </CorButton>
        <CorButton
          className="primary where-did-you-find-us-modal__close"
          onClick={handleSubmit}
          disabled={!selectedOption?.title}
        >
          <ContentstackText contentKey={`${contentstackPath}.submit_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
