import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useContent } from 'hooks/use-content';
import { selectSkuReplacementsData } from 'store/items-replacements/selectors';
import { ProductReplacementWrapper as AlternativeItemsWrapper } from 'pages/product-details/components/product-replacement/components/product-replacement-wrapper';
import { EquivalentProductsContext } from 'context/product-replacement-context';
import { AlternativeItemsLineItem } from 'components/alternative-items-line-item/alternative-items-line-item';

export const ProductReplacement: FC = () => {
  const contentstackPath = 'page_content.product_replacement';
  const { getContentByKey } = useContent();
  const { productReplacementRef } = useContext(EquivalentProductsContext);
  const skuReplacementsData = useSelector(selectSkuReplacementsData);

  return skuReplacementsData[0] ? (
    <div className="product-replacement" ref={productReplacementRef}>
      <AlternativeItemsWrapper title={getContentByKey<string>(`${contentstackPath}.title`, '')}>
        <AlternativeItemsLineItem alternativeItem={skuReplacementsData[0]} isInCart={skuReplacementsData[0].isInCart} />
      </AlternativeItemsWrapper>
    </div>
  ) : null;
};
