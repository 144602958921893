export enum DASHBOARD_QUOTES_STATUSES {
  ALL_STATUSES = 'ALL_STATUSES',
  REQUESTED = 'REQUESTED',
  REVIEW_STARTED = 'REVIEW_STARTED',
  APPROVED_BY_DAWN = 'APPROVED_BY_DAWN',
  ACCEPTED_BY_CUSTOMER = 'ACCEPTED_BY_CUSTOMER',
  REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
  CANCELLED_BY_DAWN = 'CANCELLED_BY_DAWN',
  CANCELLED_BY_DAWN_AFTER_APPROVE = 'CANCELLED_BY_DAWN_AFTER_APPROVE',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  EXPIRED = 'EXPIRED',
  SUBMITTED = 'SUBMITTED',
}
