import axios from 'axios';
import { takeLatest, call, cancelled } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { Limit } from 'constants/limit.enum';
import { ISortParams } from 'types/product-sort-options';
import { apiSagaHandler } from 'store/api/sagas';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { ISelectedFacet } from 'types/facets';
import { searchProducts, searchProductsActionConstants } from 'store/search-products/actions';
import { excludeSpecialCharacters } from 'utils/string-format';
import { getLocaleQuery } from 'utils/get-locale-params';

export interface ISearchPayload {
  facets: ISelectedFacet[];
  limit: Limit;
  offset: number;
  sortOptions: ISortParams;
  text?: string;
  categoryKeys: string[];
}

export function* searchProductsSagaHandler({ payload }: IAction<ISearchPayload>) {
  const data = {
    ...payload,
    text: excludeSpecialCharacters(payload.text),
  };
  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  try {
    yield call(
      apiSagaHandler,
      doRequest<ISearchPayload>(
        searchProducts,
        `${Endpoints.SEARCH_PRODUCTS}${getLocaleQuery()}`,
        'post',
        data,
        undefined,
        cancelToken
      )
    );
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
}

export default function* searchProductsSaga() {
  yield takeLatest(searchProductsActionConstants[Actions.REQUEST], searchProductsSagaHandler);
}
