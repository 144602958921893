import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from 'store/shopping-lists/action-types';
import { IShoppingListProduct } from 'types/product';
import { IAddedItemsNotificationInfo } from './reducer';

export const addProductToFavoritesActionConstants = createConstants(types.ADD_TO_FAVORITES);
export const removeProductFromFavoritesActionConstants = createConstants(types.REMOVE_FROM_FAVORITES);
export const getShoppingListsActionConstants = createConstants(types.GET_SHOPPING_LISTS);
export const getAllShoppingListsActionConstants = createConstants(types.GET_SHOPPING_LISTS_SORTED_BY_NAME);
export const getMarketingShoppingListsActionConstants = createConstants(types.GET_MARKETING_SHOPPING_LISTS);
export const updateShoppingListNameActionConstants = createConstants(types.UPDATE_SHOPPING_LIST_NAME);
export const getShoppingListDetailsActionConstants = createConstants(types.GET_SHOPPING_LIST_DETAILS);
export const updateShoppingListItemActionConstants = createConstants(types.UPDATE_SHOPPING_LIST_ITEM);
export const deleteShoppingListItemActionConstants = createConstants(types.DELETE_SHOPPING_LIST_ITEM);
export const addShoppingListToCartActionConstants = createConstants(types.ADD_LIST_TO_CART);
export const addItemsToShoppingListsActionConstants = createConstants(types.ADD_ITEMS_TO_SHOPPING_LISTS);
export const addProductsFromMarketingShoppingListActionConstants = createConstants(
  types.ADD_ITEMS_FROM_MARKETING_SHOPPING_LISTS
);
export const createShoppingListWithProductsActionConstants = createConstants(types.CREATE_SHOPPING_LIST_WITH_PRODUCTS);
export const createShoppingListWithProductsFromMarketingShoppingListActionConstants = createConstants(
  types.CREATE_SHOPPING_LIST_WITH_PRODUCTS_FROM_MARKETING_SHOPPING_LISTS
);
export const createEmptyShoppingListActionConstants = createConstants(types.CREATE_EMPTY_SHOPPING_LIST);
export const importShoppingListActionConstants = createConstants(types.IMPORT_SHOPPING_LIST);
export const deleteShoppingListActionConstants = createConstants(types.DELETE_SHOPPING_LIST);
export const changeLineItemsOrderActionConstants = createConstants(types.CHANGE_LINE_ITEMS_ORDER);
export const changeShoppingListsOrderActionConstants = createConstants(types.CHANGE_SHOPPING_LISTS_ORDER);

export const updateShoppingListItem = createRequestActions(updateShoppingListItemActionConstants);
export const deleteShoppingListItem = createRequestActions(deleteShoppingListItemActionConstants);
export const deleteShoppingList = createRequestActions(deleteShoppingListActionConstants);

export const changePaginationCurrent = (current: number) => {
  return action(types.SHOPPING_LISTS_PAGINATION_CHANGE, {
    current,
  });
};

export const setAddedItemsNotificationInfo = ({
  shoppingListsCounter,
  shoppingListName,
  isCreatingNewList,
  addedToListItemsCounter,
  deactivatedLineItems,
  deactivatedShoppingListLineItems,
  proprietarySkusWithoutPrice,
}: IAddedItemsNotificationInfo) => {
  return action(types.SET_ADDED_ITEMS_NOTIFICATION_INFO, {
    shoppingListsCounter,
    shoppingListName,
    isCreatingNewList,
    addedToListItemsCounter,
    deactivatedLineItems,
    deactivatedShoppingListLineItems,
    proprietarySkusWithoutPrice,
  });
};

export const addProductToFavorites = createRequestActions(addProductToFavoritesActionConstants);
export const removeProductFromFavorites = createRequestActions(removeProductFromFavoritesActionConstants);
export const getShoppingListDetails = createRequestActions(getShoppingListDetailsActionConstants);
export const getShoppingLists = createRequestActions(getShoppingListsActionConstants);
export const getMarketingShoppingLists = createRequestActions(getMarketingShoppingListsActionConstants);
export const getAllShoppingLists = createRequestActions(getAllShoppingListsActionConstants);
export const updateShoppingListName = createRequestActions(updateShoppingListNameActionConstants);
export const addItemsToShoppingLists = createRequestActions(addItemsToShoppingListsActionConstants);
export const addProductsFromMarketingShoppingList = createRequestActions(
  addProductsFromMarketingShoppingListActionConstants
);
export const createShoppingListWithProducts = createRequestActions(createShoppingListWithProductsActionConstants);
export const createShoppingListWithProductsFromMarketingShoppingList = createRequestActions(
  createShoppingListWithProductsFromMarketingShoppingListActionConstants
);
export const importShoppingList = createRequestActions(importShoppingListActionConstants);
export const createEmptyShoppingList = createRequestActions(createEmptyShoppingListActionConstants);
export const addShoppingListToCart = createRequestActions(addShoppingListToCartActionConstants);
export const changeLineItemsOrder = createRequestActions(changeLineItemsOrderActionConstants);
export const changeShoppingListsOrder = createRequestActions(changeShoppingListsOrderActionConstants);

export const updateProductFavoritesStatus = (sku: string, addedToFavorites: boolean) =>
  action(types.UPDATE_PRODUCT_FAVORITES_STATUS, { sku, addedToFavorites });

export const resetShoppingListDetails = () => action(types.RESET_SHOPPING_LIST_DETAILS);

export const setShoppingListNameDuplicateError = (hasShoppingListNameDuplicateError: boolean) =>
  action(types.HAS_SHOPPING_LIST_NAME_DUPLICATE_ERROR, hasShoppingListNameDuplicateError);

export const updateShoppingListDetailsItemsOrder = (itemsWithUpdatedOrder: IShoppingListProduct[]) =>
  action(types.UPDATE_SHOPPING_LIST_DETAILS_ITEMS_ORDER, { itemsWithUpdatedOrder });

export const resetHasShoppingListNameDuplicateError = () => action(types.RESET_HAS_SHOPPING_LIST_NAME_DUPLICATE_ERROR);
