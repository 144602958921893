import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { IUserInformation } from 'types/user-info';
import { getUserInformation, getUserInformationActionConstants } from '../actions';

export interface IGetUserInfoPayload {
  userId: string;
}

export function* getUserInformationSagaHandler({ payload: { userId } }: IAction<IGetUserInfoPayload>) {
  yield put(doRequest<IUserInformation>(getUserInformation, `${Endpoints.UPDATE_USER_PERSONAL_INFO}/${userId}`, 'get'));
}

export default function* getUserInformationSaga() {
  yield takeLatest(getUserInformationActionConstants[Actions.REQUEST], getUserInformationSagaHandler);
}
