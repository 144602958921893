import './cor-leadership.scss';

import React, { FC, useState } from 'react';
import { CorContentstackHtml } from 'corporate-components';
import { ICSCorImage } from 'corporate-interfaces';
import { CorTypography } from 'corporate-ui';
import { CorLeadershipTile } from 'corporate-components/cor-leadership/components/';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ContentstackContext } from 'context/contentstack';

const BASE_KEY = 'leadership.leadership[0]';

export type TeamMemberCardType = {
  image: ICSCorImage;
  name: string;
  biography: string;
  title: string;
};

export interface ICSLeadershipData {
  group_title: string;
  group_description: string;
  team_member_card: TeamMemberCardType[];
}

export const CorLeadership: FC = () => {
  const [active, setActive] = useState<string>();
  const { getContentByKey } = useContent();
  const leadershipData: ICSLeadershipData = getContentByKey(BASE_KEY, {} as ICSLeadershipData);
  const { team_member_card, group_description, group_title } = leadershipData;
  const { isMobile } = useBreakpoint();

  if (!team_member_card.length) {
    return null;
  }

  return (
    <div className="cor-leadership">
      <div className={'cor-leadership__container'}>
        {(group_title || group_description) && (
          <div data-testid={'cor-leadership__head'} className={'cor-leadership__head'}>
            {group_title && (
              <CorTypography variant={isMobile ? 'h1' : 'h2'} color={'dark-gray'} className={'cor-leadership__title'}>
                {group_title}
              </CorTypography>
            )}
            {group_description && (
              <CorContentstackHtml
                contentKey={`${BASE_KEY}.group_description`}
                className={'cor-leadership__description'}
              />
            )}
          </div>
        )}
        <div data-testid={'cor-leadership__tiles'} className="cor-leadership__tiles">
          {team_member_card.map((member, idx) => {
            const { biography, image, title, name } = member;
            const id = idx.toString();
            return (
              <ContentstackContext.Provider value={member} key={id}>
                <CorLeadershipTile
                  key={id}
                  id={id}
                  tilesWrapper={'.cor-leadership__tiles'}
                  description={biography}
                  picture={image}
                  jobTitle={title}
                  name={name}
                  isActive={active === id}
                  activeStateHandler={() => setActive(id)}
                  closeHandler={() => setActive('')}
                />
              </ContentstackContext.Provider>
            );
          })}
        </div>
      </div>
    </div>
  );
};
