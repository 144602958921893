import { ISkuSeasonalIndicator } from 'types/product';
import { useContent } from './use-content';

export const useProductSeasonalIndicator = (seasonalBadgeId?: string) => {
  const { getContentByKey } = useContent();

  if (!seasonalBadgeId) {
    return;
  }

  const seasonalIndicators = getContentByKey(
    'product_characteristics.product_indicators[0].seasonal_indicators[0].indicators',
    []
  );

  const seasonalIndicator: { [key: string]: ISkuSeasonalIndicator } =
    seasonalIndicators.find((item) => item['seasonal_label']['indicator_id'] === seasonalBadgeId) || {};

  return seasonalIndicator['seasonal_label'];
};
