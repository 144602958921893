import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { getDiscontinuedItemsReplacements } from 'store/items-replacements/actions';
import { selectDiscontinuedItemsWithReplacements } from 'store/items-replacements/selectors';
import { ISkuInformation } from 'types/order-details';

export const useGetOriginalToReplacementsMap = (
  shouldBeCalled: boolean,
  originalToReplacementItemsMap?: {
    [key: string]: string;
  }
) => {
  const dispatch = useDispatch();

  const discontinuedItemsWithReplacements = useSelector(selectDiscontinuedItemsWithReplacements);

  const originalToReplacementMap = useMemo(
    () =>
      originalToReplacementItemsMap &&
      discontinuedItemsWithReplacements &&
      Object.keys(originalToReplacementItemsMap)
        .map((originalSku) => ({
          original: discontinuedItemsWithReplacements.find(({ sku }) => sku === originalSku),
          replacement: discontinuedItemsWithReplacements.find(
            ({ sku }) => sku === originalToReplacementItemsMap[originalSku]
          ),
        }))
        .filter(
          (
            originalToReplacementMapItem
          ): originalToReplacementMapItem is {
            original: ISkuInformation;
            replacement: ISkuInformation;
          } => !isEmpty(originalToReplacementMapItem.original) && !isEmpty(originalToReplacementMapItem.replacement)
        ),
    [originalToReplacementItemsMap, discontinuedItemsWithReplacements]
  );

  useEffect(() => {
    if (originalToReplacementItemsMap && Object.keys(originalToReplacementItemsMap).length && shouldBeCalled) {
      dispatch(
        getDiscontinuedItemsReplacements.request({
          skus: [...Object.keys(originalToReplacementItemsMap), ...Object.values(originalToReplacementItemsMap)].join(
            ','
          ),
        })
      );
    }
  }, [dispatch, originalToReplacementItemsMap, shouldBeCalled]);

  return originalToReplacementMap;
};
