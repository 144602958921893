import { FC } from 'react';
import { ContentstackHtml } from 'components/contentstack/contentstack-html';
import './internal-user-footer.scss';

export const InternalUserFooter: FC = () => {
  return (
    <div className="internal-user-footer">
      <div className="grid-container">
        <div className="internal-user-footer__copyright">
          <small className="copyright">
            <ContentstackHtml
              contentKey="footer[0].copyright_block[0].text"
              interpolateParams={{ currentYear: new Date().getFullYear() }}
              component="span"
            />
          </small>
        </div>
      </div>
    </div>
  );
};
