import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ISkuInformation } from 'types/order-details';
import { DiscontinuedItemInfo } from 'features/discontinued-items-with-replacements';
import { ContentstackMessage } from 'components/contentstack';
import { ProductReplacementLineItemButtons } from 'pages/product-details/components/product-replacement/components/product-replacement-line-item-buttons';
import { useAddToCart } from 'hooks/use-add-to-cart';
import { ProductStatusLabels } from 'components/product-status-labels';
import { selectIsKeyAccountUser } from 'store/auth/selectors';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { HierarchyAccountsSpecialOrderMessage } from 'components/hierarchy-accounts-special-order-message';

import './discontinued-with-replacement-item.scss';

export interface IDiscontinuedWithReplacementItemProps {
  contentstackPath: string;
  original: ISkuInformation;
  replacement: ISkuInformation;
  createRequestQuoteClickHandler: (sku: string) => () => void;
}

export const DiscontinuedWithReplacementItem: FC<IDiscontinuedWithReplacementItemProps> = ({
  contentstackPath,
  original,
  replacement,
  createRequestQuoteClickHandler,
}) => {
  const contentstackProductReplacementPath = `${contentstackPath}.product_replacement_line`;
  const { isAddToCartButtonActive, isAddToCartButtonDisabled, createAddToCartClickHandler } = useAddToCart();
  const isShortSupplySkuReplacement = replacement.maximumQuantity === 0;
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);
  const { isDesktop } = useBreakpoint();

  return (
    <div className="discontinued-with-replacement-item">
      <div className="discontinued-with-replacement-item__discontinued-item">
        <DiscontinuedItemInfo
          contentstackPath={contentstackProductReplacementPath}
          name={original.name}
          sku={original.sku}
          productKey={original.productKey}
          renderStatus={() => (
            <ProductStatusLabels size="small" isSpecialOrder={original.isSpecialOrder} isDiscontinued />
          )}
        />
      </div>
      <p className="discontinued-with-replacement-item__alternate-title">
        <ContentstackMessage type="error" messageId="MSG021b" />
      </p>
      <div className="discontinued-with-replacement-item__alternate-item">
        <DiscontinuedItemInfo
          contentstackPath={contentstackProductReplacementPath}
          name={replacement.name}
          sku={replacement.sku}
          productKey={replacement.productKey}
          isReplacementSpecial={Boolean(replacement.isSpecialOrder)}
          price={replacement.price}
          isRenderStatus={Boolean(replacement.isSpecialOrder || replacement.isPhasingOut)}
          renderStatus={() => (
            <ProductStatusLabels
              size="small"
              isSpecialOrder={replacement.isSpecialOrder}
              isPhasingOut={replacement.isPhasingOut}
            />
          )}
        />
      </div>
      <div className="discontinued-with-replacement-item__alternate-item__button">
        {isKeyAccountUser && Boolean(replacement.isSpecialOrder) && isDesktop ? (
          <HierarchyAccountsSpecialOrderMessage />
        ) : (
          !(isKeyAccountUser && Boolean(replacement.isSpecialOrder)) && (
            <ProductReplacementLineItemButtons
              contentstackPath={contentstackProductReplacementPath}
              sku={replacement.sku}
              isSpecialOrder={Boolean(replacement.isSpecialOrder)}
              active={isAddToCartButtonActive}
              disabled={isAddToCartButtonDisabled || isShortSupplySkuReplacement}
              onAddToCartClick={createAddToCartClickHandler(replacement.sku)}
              onRequestQuoteClick={createRequestQuoteClickHandler(replacement.sku)}
            />
          )
        )}
      </div>
    </div>
  );
};
