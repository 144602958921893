import { FC } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectIsOrderCutoffNotificationVisible } from 'store/order-cut-off/selectors';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { ContentstackHtml } from 'components/contentstack/contentstack-html';
import iconCalendarGray from 'assets/images/icons/icon-order-calendar-gray.svg';
import iconCalendarYellow from 'assets/images/icons/icon-order-calendar-yellow.svg';

import './order-pickup-message.scss';

export interface IOrderPickupMessageProps {
  fullSize?: boolean;
}

export const OrderPickupMessage: FC<IOrderPickupMessageProps> = ({ fullSize }) => {
  const isCutoffNotificationVisible = useSelector(selectIsOrderCutoffNotificationVisible);

  return (
    <div className={classNames('order-pickup-message', { 'order-pickup-message--full-size': fullSize })}>
      <div className="order-pickup-message__content">
        <div className="order-pickup-message__title-wrapper">
          <div className="order-pickup-message__icon">
            <img src={isCutoffNotificationVisible ? iconCalendarGray : iconCalendarYellow} alt="calendar-icon" />
          </div>
          <div className="order-pickup-message__title">
            <ContentstackHtml contentKey="page_content.order_updates.0.order_pick_up.order_pickup_time_message" />
          </div>
        </div>

        <div className="order-pickup-message__note">
          <ContentstackText contentKey="page_content.order_updates.0.order_pick_up.order_pickup_time_note" />
        </div>
      </div>
    </div>
  );
};
