import { useContent } from 'hooks/use-content';
import { IBreadcrumb } from 'types/breadcrumbs';
import { IBreadcrumbProps } from 'components/breadcrumbs';

export const mapContentStackBreadcrumbs = (breadcrumbs: IBreadcrumb[]): IBreadcrumbProps[] =>
  breadcrumbs.map(({ link }) => ({ name: link?.title, url: link?.href }));

export const useBreadcrumbs = () => {
  const { getContentByKey } = useContent();
  const breadcrumbs = getContentByKey<IBreadcrumb[]>('breadcrumbs', []);

  return {
    breadcrumbs: mapContentStackBreadcrumbs(breadcrumbs),
  };
};
