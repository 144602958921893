import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import iconNotice from 'assets/images/icons/icon-notice.svg';

import './sku-quantity-limitation-alert.scss';

export interface ISkuQuantityLimitationAlertProps extends PropsWithChildren {
  className?: string;
}

export const SkuQuantityLimitationAlert: FC<ISkuQuantityLimitationAlertProps> = ({ children, className }) => {
  return (
    <div className={classNames('sku-quantity-limitation-alert', className)}>
      <img src={iconNotice} alt="icon notice" className="sku-quantity-limitation-alert__icon" />
      {children}
    </div>
  );
};
