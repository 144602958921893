import { FC, SyntheticEvent, HTMLAttributes, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';

import './cor-button.scss';

type iconType = string | object;
type iconAlignType = boolean;

export interface ICorButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  color?: 'primary' | 'secondary' | 'tertiary' | string;
  className?: string;
  onClick?: (event: SyntheticEvent) => void;
  type?: 'button' | 'submit' | 'reset';
  icon?: iconType;
  isIconOnly?: boolean;
  iconPosition?: 'left' | 'right';
  hoverIcon?: iconType;
  disabled?: boolean;
}

export const CorButton: FC<ICorButtonProps> = ({
  color = 'primary',
  className,
  onClick,
  type = 'button',
  children,
  icon = '',
  isIconOnly = false,
  iconPosition = 'left',
  hoverIcon = '',
  disabled = false,
  ...buttonProps
}) => {
  const [hover, setHover] = useState(false);
  const [locale] = useLocalStorage('locale', null);
  const nonEnglishInternationalSite = isInternationalSite(locale) && !String(locale).includes('en');

  const onMouseEnter = (): void => {
    setHover(true);
  };

  const onMouseLeave = (): void => {
    setHover(false);
  };

  const handleClick = (event: SyntheticEvent): void => {
    if (onClick) {
      onClick(event);
    }
  };

  const getIcon = (): iconType => {
    const currentIcon = hover && hoverIcon ? hoverIcon : icon;

    if (currentIcon && typeof currentIcon === 'string') {
      return <img src={currentIcon} data-testid="button icon" alt="button icon" />;
    }

    return currentIcon;
  };

  const resultClass: string = classNames('cor-button', color?.toLowerCase(), className, {
    'cor-button--withIcon': icon,
    'cor-button--is-international': nonEnglishInternationalSite,
  });

  const isLeftIcon: iconAlignType = iconPosition === 'left' || isIconOnly;
  const isRightIcon: iconAlignType = iconPosition === 'right' && !isIconOnly;

  return (
    <button
      className={resultClass}
      onClick={handleClick}
      type={type}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      data-testid="button"
      {...buttonProps}
    >
      <>
        {isLeftIcon ? getIcon() : null}
        {!isIconOnly ? <span className="content">{children}</span> : null}
        {isRightIcon ? getIcon() : null}
      </>
    </button>
  );
};
