import { ContentstackImage, ContentstackMessage } from 'components/contentstack';

import './hierarchy-accounts-special-order-message.scss';

export const HierarchyAccountsSpecialOrderMessage = () => (
  <div className="hierarchy-accounts-special-order-message">
    <div className="hierarchy-accounts-special-order-message__img-wrapper">
      <ContentstackImage contentKey="common[0].error_warning_icon" />
    </div>
    <ContentstackMessage type="notifications" messageId="MSG162" />
  </div>
);
