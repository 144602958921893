import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import './product-selector-button.scss';

export interface IProductSelectorButtonProps extends PropsWithChildren {
  isActive: boolean;
  className?: string;
  onVariantSelect: () => void;
  title?: string;
}

export const ProductSelectorButton: FC<IProductSelectorButtonProps> = ({
  isActive,
  className,
  onVariantSelect,
  children,
  title,
}) => (
  <button
    type="button"
    className={classNames('product-selector-button', className, {
      'product-selector-button--active': isActive,
    })}
    title={title}
    onClick={onVariantSelect}
  >
    {children}
  </button>
);
