import { useSelector } from 'react-redux';
import { FC, useMemo } from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import IconUser from 'assets/images/icons/icon-user.svg';
import { CustomDropdown } from 'components/custom-dropdown';
import { selectIsUserLocationDeleted } from 'store/auth/selectors';
import { IOptionType } from 'components/custom-dropdown';

import './account-menu-select.scss';

export interface IAccountMenuProps {
  welcomeMessage: string;
  accountMenuOptions: IOptionType[];
  logoutHandler: () => void;
}

export const AccountMenuSelect: FC<IAccountMenuProps> = ({ welcomeMessage, accountMenuOptions, logoutHandler }) => {
  const history = useHistory();
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);

  const renderOption = ({ children, ...props }: any) => {
    return (
      <components.Option className="account-menu__option" {...props}>
        <div className="dropdown-menu-item">{children}</div>
      </components.Option>
    );
  };

  const MenuDropdown = useMemo(() => {
    return (
      <CustomDropdown
        inputId="account-menu__dropdown--id"
        items={accountMenuOptions}
        renderOption={renderOption}
        dropdownIcon={IconUser}
        value={{
          label: welcomeMessage,
          value: welcomeMessage,
        }}
        onChange={(props: any) => {
          props.href ? history.push(props.href) : logoutHandler();
        }}
        className={classNames('account-menu__dropdown', {
          'account-menu__dropdown--has-deleted-location': isUserLocationDeleted,
        })}
        hasValueAlt
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountMenuOptions, isUserLocationDeleted, logoutHandler]);

  return <>{MenuDropdown}</>;
};
