import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { sendNewPassword, sendNewPasswordActionConstants } from 'store/reset-password/actions';
import { IAction } from 'types/actions';

export interface ISendNewPasswordPayload extends IData {
  token: string;
}

interface IData {
  password: string;
  confirmPassword: string;
}

export function* sendNewPasswordSagaHandler({ payload: { token, ...requestData } }: IAction<ISendNewPasswordPayload>) {
  yield put(doRequest<IData>(sendNewPassword, `${Endpoints.PASSWORDS}/${token}`, 'put', requestData));
}

export default function* sendNewPasswordSaga() {
  yield takeLatest(sendNewPasswordActionConstants[Actions.REQUEST], sendNewPasswordSagaHandler);
}
