import { put, take, takeLatest } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { updateShoppingListName, updateShoppingListNameActionConstants } from 'store/shopping-lists/actions';

export interface IUpdateNamePayload {
  shoppingListId: string;
  name: string;
  onFailCallback: (status: number) => void;
}

export function* updateShoppingListNameFailSagaHandler({ payload }: IAction<IUpdateNamePayload>) {
  const { error } = yield take(updateShoppingListNameActionConstants[Actions.FAIL]);
  if (payload.onFailCallback && error) {
    payload.onFailCallback(error.status);
  }
}

export function* updateShoppingListNameSagaHandler({ payload }: IAction<IUpdateNamePayload>) {
  yield put(
    doRequest(updateShoppingListName, `${Endpoints.SHOPPING_LISTS}/${payload.shoppingListId}`, 'put', {
      name: payload.name,
    })
  );
}

export default function* updateShoppingListNameSaga() {
  yield takeLatest(updateShoppingListNameActionConstants[Actions.REQUEST], updateShoppingListNameSagaHandler);
  yield takeLatest(updateShoppingListNameActionConstants[Actions.REQUEST], updateShoppingListNameFailSagaHandler);
}
