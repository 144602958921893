import { takeLatest, put, all, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { addItemsToShoppingLists, addItemsToShoppingListsActionConstants } from 'store/shopping-lists/actions';
import { IDeactivatedLineItemDetails } from 'types/order-details';

export interface IListLineItem {
  quantity: number;
  sku: string;
}

export interface IAddProductsToShoppingListsPayload {
  lineItems: IListLineItem[];
  selectedShoppingLists: string[];
  onSuccessCallback: (
    selectedShoppingLists: string[],
    addedItemsCounter: number,
    deactivatedLineItems: IDeactivatedLineItemDetails[]
  ) => void;
  onFailCallback: (deactivatedLineItems: string[]) => void;
}

export function* addProductsToShoppingListsSuccessSagaHandler({
  payload,
}: IAction<IAddProductsToShoppingListsPayload>) {
  const {
    payload: { addedItemsCounter, deactivatedLineItems },
  } = yield take(addItemsToShoppingListsActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback(payload.selectedShoppingLists, addedItemsCounter, deactivatedLineItems);
  }
}

export function* addProductsToShoppingListsFailSagaHandler({ payload }: IAction<IAddProductsToShoppingListsPayload>) {
  const { error } = yield take(addItemsToShoppingListsActionConstants[Actions.FAIL]);
  if (payload.onFailCallback) {
    payload.onFailCallback(error.deactivatedItemModel?.skus || []);
  }
}

export function* addProductsToShoppingListsHandler({
  payload: { lineItems, selectedShoppingLists },
}: IAction<IAddProductsToShoppingListsPayload>) {
  yield all(
    selectedShoppingLists.map(function* (listId) {
      yield put(
        doRequest(addItemsToShoppingLists, `${Endpoints.SHOPPING_LISTS}/${listId}/items`, 'put', { lineItems })
      );
    })
  );
}

export default function* addProductsToShoppingListsSaga() {
  yield takeLatest(addItemsToShoppingListsActionConstants[Actions.REQUEST], addProductsToShoppingListsHandler);
  yield takeLatest(
    addItemsToShoppingListsActionConstants[Actions.REQUEST],
    addProductsToShoppingListsSuccessSagaHandler
  );
  yield takeLatest(addItemsToShoppingListsActionConstants[Actions.REQUEST], addProductsToShoppingListsFailSagaHandler);
}
