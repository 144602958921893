import { action } from 'typesafe-actions';
import types from 'store/auth/action-types';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';

export const getUserInfoActionConstants = createConstants(types.GET_USER_INFO);
export const getUserInfo = createRequestActions(getUserInfoActionConstants);

export const impersonationStartActionConstants = createConstants(types.IMPERSONATION_START);
export const impersonationStart = createRequestActions(impersonationStartActionConstants);

export const impersonationStopActionConstants = createConstants(types.IMPERSONATION_STOP);
export const impersonationStop = createRequestActions(impersonationStopActionConstants);

export const unloadUser = action(types.UNLOAD_USER);
export const logout = action(types.LOGOUT);
