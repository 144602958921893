import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getMsrListByNameForMsm, getMsrListByNameForMsmConstants } from 'store/dashboard/actions';

export interface IMsrListByNamePayload {
  msrName: string;
}

export function* getMsrListByNameHandler({ payload: { msrName } }: IAction<IMsrListByNamePayload>) {
  yield put(doRequest(getMsrListByNameForMsm, `${Endpoints.DASHBOARD_MSM_MSR_SEARCH}/${msrName}`, 'get'));
}

export default function* getMsrListByNameHandlerSaga() {
  yield takeLatest(getMsrListByNameForMsmConstants[Actions.REQUEST], getMsrListByNameHandler);
}
