import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const optOutFormActionConstants = createConstants(types.OPT_OUT);

export const optOutForm = createRequestActions(optOutFormActionConstants);

export const resetOptOutFormState = () => action(types.RESET_OPT_OUT_FORM_STATE);
