import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import {
  resendEmailToVerifyAccount,
  resendEmailToVerifyAccountActionConstants,
} from 'store/customer-registration/actions';
import { IAction } from 'types/actions';

export interface IResendEmailPayload {
  email: string;
}

function* resendEmailSagaHandler({ payload: { email } }: IAction<IResendEmailPayload>) {
  yield put(doRequest(resendEmailToVerifyAccount, `${Endpoints.CUSTOMERS}/${email}`, 'post'));
}

export default function* resendEmailToVerifyAccountSaga() {
  yield takeLatest(resendEmailToVerifyAccountActionConstants[Actions.REQUEST], resendEmailSagaHandler);
}
