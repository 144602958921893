import { FC } from 'react';
import classNames from 'classnames';
import { ITabsProps, Tabs } from 'components/tabs';

import './tabs-scrollable.scss';

export const TabsScrollable: FC<ITabsProps> = ({ tabsList }) => {
  const tabsClasses = classNames('tabs-scrollable');

  return (
    <div className={tabsClasses}>
      <Tabs tabsList={tabsList} withScrollIntoView />
    </div>
  );
};
