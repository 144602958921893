import { FC, PropsWithChildren } from 'react';
import { CorFooter } from 'corporate-components';
import { PageLayout } from 'components/layout';
import { CorHeader } from 'components/layout/cor-header';

interface IAuthLayoutProps extends PropsWithChildren {}

export const AuthLayout: FC<IAuthLayoutProps> = ({ children }) => (
  <PageLayout classModifier="auth" header={CorHeader} footer={CorFooter}>
    {children}
  </PageLayout>
);
