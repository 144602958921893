import { algoliaQueryMapper, algoliaQueryShopProductsMapper } from './algolia-query.mapper';
import {
  IAlgoliaRawResponse,
  IAlgoliaResponse,
  IAlgoliaResponseMeta,
  IAlgoliaContentTypesEnum,
  IAlgoliaTagsEnum,
} from './use-algolia-query.interface';

const ALGOLIA_DEFAULT_META_PAGE = {
  total_results: 0,
  total_pages: 0,
  current: 0,
  size: 0,
};

interface IAlgoliaQuerySelectProps {
  categoryKeys: string[];
  pageOffset: number;
  isTotalShopProductsCounted?: boolean;
}

const algoliaQuerySelect = ({
  categoryKeys = [],
  pageOffset,
  isTotalShopProductsCounted = false,
}: IAlgoliaQuerySelectProps) => (response: IAlgoliaRawResponse): IAlgoliaResponse => {
  const sanitizedResponse = categoryKeys?.length
    ? response?.globalPageTypeContents?.filter((entry) => {
        if (entry.categories?.[0]?.categoryName !== IAlgoliaContentTypesEnum.StandardPage)
          return categoryKeys?.includes(entry.categories?.[0]?.categoryName);

        const categoryTag = entry?.url.split('?page_type=')?.[1];
        return categoryKeys?.includes(categoryTag || '');
      })
    : response?.globalPageTypeContents;

  const page = sanitizedResponse?.reduce(
    (prev: IAlgoliaResponseMeta['page'], curr) => {
      const total_results = (prev?.total_results || 0) + curr.total;

      return {
        total_results,
        total_pages: Math.ceil(total_results / pageOffset),
        current: curr.offset / pageOffset + 1,
        size: pageOffset,
      };
    },
    {
      ...ALGOLIA_DEFAULT_META_PAGE,
      ...(isTotalShopProductsCounted && { total_results: response?.plpProducts?.total || 0 }),
    }
  );

  const globalPageTypeContents = sanitizedResponse?.map((entry) => {
    const categoryTag = entry?.url.split('?page_type=')?.[1] as IAlgoliaTagsEnum;
    return {
      ...entry,
      c_cards: entry?.pageType?.map((pageType) => algoliaQueryMapper({ entry: pageType, categoryTag: categoryTag })),
    };
  });

  const customShopProductCards = response?.plpProducts?.result?.map((entry) => algoliaQueryShopProductsMapper(entry));

  return {
    globalPageTypeMeta: {
      page,
    },
    globalPageTypeContents,
    c_shop_product_cards: customShopProductCards || [],
    total: response?.plpProducts?.total || 0,
    totalSkus: response?.plpProducts?.totalSkus || 0,
  };
};

export default algoliaQuerySelect;
