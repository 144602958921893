import React from 'react';
import classNames from 'classnames';
import { stringify } from 'qs';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CorSelect } from 'corporate-ui';
import { IAlgoliaContentTypesEnum } from 'queries/use-algolia-query';
import { useAlgoliaQueryParams } from 'hooks';
import { ICSSearchResultFilterButtons } from 'corporate-interfaces';
import { Routes } from 'constants/routes.enum';
import { selectIsAuthorised } from 'store/auth/selectors';
import useAlgolia, { ALGOLIA_DEFAULT_PAGE_NUMBER, ALGOLIA_DEFAULT_PAGE_TYPE } from 'hooks/use-algolia';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContent } from 'hooks/use-content';
import { CorFilterButton } from '../cor-filter-button';

const includeTotal = ({ label = '', total = 0 }) => `${label} (${total})`;

const GLOBAL_FACETS_KEY = 'global_facets.0.page_types';
const LABELS_KEY = 'labels.0.key_value_pair.value';
const SHOP_PRODUCTS_KEY = 'SHOP_PRODUCTS';

const CorFilterButtons = () => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const { isMobile } = useBreakpoint();
  const { getContentByKey, getLabelsByKey } = useContent();
  const isLoggedInUser = useSelector(selectIsAuthorised);
  const history = useHistory();
  const algoliaQuery = useAlgolia({ query: queryParams?.query || '', isTotalShopProductsCounted: true });

  const algoliaMetaData = algoliaQuery?.data?.globalPageTypeMeta;
  const algoliaData = algoliaQuery?.data?.globalPageTypeContents;

  const handleShopProductsSeeAllButton = React.useCallback(() => {
    if (!queryParams?.query) return history.push(Routes.ProductListPage);

    history.push(`${Routes.ProductSearchPage}?${stringify({ query: queryParams.query })}`);
  }, [history, queryParams?.query]);

  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});
  const pageTypeParam = queryParams?.page_type || ALGOLIA_DEFAULT_PAGE_TYPE;
  const contentStackPageTypes = getContentByKey<ICSSearchResultFilterButtons>(GLOBAL_FACETS_KEY, [])?.map((entry) => ({
    value: [IAlgoliaContentTypesEnum.StandardPage].includes(entry.type) ? entry.tag : entry.type,
    label: entry.title,
  }));
  const contentStackPageTypesSorted = React.useMemo(
    () =>
      !!contentStackPageTypes?.length
        ? contentStackPageTypes
            ?.filter((entry) => !!entry)
            ?.map((entry) => ({
              ...entry,
              label: includeTotal({
                label: entry.label,
                total: algoliaData?.find(
                  (algoliaEntry) =>
                    ([IAlgoliaContentTypesEnum.StandardPage].includes(algoliaEntry?.categories?.[0]?.categoryName)
                      ? algoliaEntry?.url.split('?page_type=')?.[1]
                      : algoliaEntry?.categories?.[0]?.categoryName) === entry.value
                )?.total,
              }),
            }))
        : [],
    [algoliaData, contentStackPageTypes]
  );
  const shopProductsPageType = isLoggedInUser
    ? [
        {
          value: SHOP_PRODUCTS_KEY,
          label: includeTotal({ label: labels?.shop_products || 'Shop Products', total: algoliaQuery?.data?.total }),
        },
      ]
    : [];
  const allPageType = {
    value: ALGOLIA_DEFAULT_PAGE_TYPE,
    label: includeTotal({
      label: labels?.all_results || 'All Results',
      total: algoliaMetaData?.page?.total_results,
    }),
  };
  const pageTypes = [allPageType, ...shopProductsPageType, ...contentStackPageTypesSorted];
  const currentPageType = {
    value: pageTypeParam || ALGOLIA_DEFAULT_PAGE_TYPE,
    label: pageTypes?.find((entry) => [entry.value].includes(pageTypeParam))?.label || '',
  };

  return (
    <section
      className={classNames('grid-container search-results-page__container search-results-page__filter-buttons', {
        'search-results-page__filter-buttons--hidden':
          algoliaQuery.isLoading ||
          algoliaQuery.isError ||
          (algoliaQuery.isSuccess && !algoliaMetaData?.page?.total_results),
      })}
    >
      {isMobile && pageTypes?.length > 0 && (
        <CorSelect
          isSearchable={false}
          options={pageTypes}
          value={currentPageType}
          onChangeHandler={(option) => {
            if (SHOP_PRODUCTS_KEY === option.value) return handleShopProductsSeeAllButton();
            setQueryParams(() => ({
              page_type: option.value,
              page: ALGOLIA_DEFAULT_PAGE_NUMBER,
            }));
          }}
        />
      )}
      {!isMobile &&
        pageTypes?.length > 0 &&
        pageTypes?.map((entry) => (
          <CorFilterButton
            buttonLabel={entry.label}
            isSelected={[entry.value].includes(pageTypeParam)}
            handleOnClick={() => {
              if (SHOP_PRODUCTS_KEY === entry.value) return handleShopProductsSeeAllButton();
              setQueryParams(() => ({
                page_type: entry.value,
                page: ALGOLIA_DEFAULT_PAGE_NUMBER,
              }));
            }}
            key={entry.value}
          />
        ))}
    </section>
  );
};

export default React.memo(CorFilterButtons);
