import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';

export const selectCaptcha = (state: IState) => state.app.captcha;

export const selectSiteKey = createSelector(selectCaptcha, ({ siteKey, loading, failed }) => ({
  siteKey,
  loading,
  failed,
}));
