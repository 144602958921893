import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import types from 'store/quotes/action-types';
import { createRequestActions } from 'store/action-creator';

export const getQuotesActionConstants = createConstants(types.GET_QUOTES);
export const requestQuoteActionConstants = createConstants(types.REQUEST_QUOTE);
export const getSkuInfoActionConstants = createConstants(types.GET_SKU_INFO);
export const getInternalUserQuotesActionConstants = createConstants(types.GET_INTERNAL_USER_QUOTES);
export const cancelQuoteActionConstants = createConstants(types.CANCEL_QUOTE);
export const reviewQuoteActionConstants = createConstants(types.REVIEW_QUOTE);
export const approveQuoteActionConstants = createConstants(types.APPROVE_QUOTE);
export const getDeliveryDatesConstants = createConstants(types.GET_DELIVERY_DATES);
export const rejectQuoteActionConstants = createConstants(types.REJECT_QUOTE);
export const acceptQuoteActionConstants = createConstants(types.ACCEPT_QUOTE);
export const submitQuoteOrderActionConstants = createConstants(types.SUBMIT_QUOTE_ORDER);
export const dismissQuoteActionConstants = createConstants(types.DISMISS_QUOTE);
export const getQuotesRequestedConstants = createConstants(types.GET_QUOTES_REQUESTED);

export const getQuotes = createRequestActions(getQuotesActionConstants);
export const requestQuote = createRequestActions(requestQuoteActionConstants);
export const getSkuInfo = createRequestActions(getSkuInfoActionConstants);
export const getInternalUserQuotes = createRequestActions(getInternalUserQuotesActionConstants);
export const cancelQuote = createRequestActions(cancelQuoteActionConstants);
export const reviewQuote = createRequestActions(reviewQuoteActionConstants);
export const approveQuote = createRequestActions(approveQuoteActionConstants);
export const getDeliveryDates = createRequestActions(getDeliveryDatesConstants);
export const rejectQuote = createRequestActions(rejectQuoteActionConstants);
export const acceptQuote = createRequestActions(acceptQuoteActionConstants);
export const submitQuoteOrder = createRequestActions(submitQuoteOrderActionConstants);
export const dismissQuote = createRequestActions(dismissQuoteActionConstants);
export const getQuotesRequested = createRequestActions(getQuotesRequestedConstants);

export const resetSkuInfoDetails = () => action(types.RESET_SKU_INFO_DETAILS);

export const changeInternalUserQuotesPaginationCurrent = (current: number) =>
  action(types.INTERNAL_USER_QUOTES_PAGINATION_CHANGE, {
    current,
  });

export const clearApproveQuoteFormServerError = () => action(types.CLEAR_APPROVE_QUOTE_SERVER_ERROR);
