import { createReducer } from 'store/reducer-creator';
import types from './action-types';

export interface ICorTemporaryRecipesFilterFacets {
  [key: string]: string;
}

export interface ICorTemporaryInsightsFilterFacets {
  [key: string]: string;
}

export interface ICorTemporaryFilterFacets {
  facets: ICorTemporaryRecipesFilterFacets | ICorTemporaryInsightsFilterFacets | null;
}

export const corTemporaryFilterFacetsInitialState: ICorTemporaryFilterFacets = { facets: null };

const corTemporaryFilterFacets = createReducer<ICorTemporaryFilterFacets>(
  {
    [types.SET_TEMPORARY_FILTERS_FACETS]: (state, { payload }) => payload,
    [types.CLEAR_TEMPORARY_FILTERS_FACETS]: () => corTemporaryFilterFacetsInitialState,
  },
  corTemporaryFilterFacetsInitialState
);

export default corTemporaryFilterFacets;
