import { template } from 'lodash-es';

export const getShareLink = (url: string, title: string, shareUrl: string) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  let html = [
    ['&lt;%', '<%'],
    ['%&gt;', '%>'],
  ].reduce((acc, replacementPair) => acc.replace(new RegExp(replacementPair[0], 'g'), replacementPair[1]), shareUrl);
  const compiled = template(html);

  return compiled({ url: encodedUrl, title: encodedTitle });
};
