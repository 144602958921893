import React, { FC } from 'react';
import { ContentstackContext } from 'context/contentstack';
import { CorAccordionItem, ICSAccordionItemStep } from './components/accordion-item';

export interface ICSCorAccordionInstance {
  accordion_step?: ICSAccordionItemStep[];
  tab_title: string;
}

export interface ICorAccordionProps {
  id: number;
  accordion: ICSCorAccordionInstance;
}

export const CorAccordion: FC<ICorAccordionProps> = ({ id, accordion }) => {
  return (
    <div className={'cor-accordion'} id={id.toString()}>
      {accordion.accordion_step?.map((step, stepIdx) => (
        <ContentstackContext.Provider value={step} key={stepIdx.toString()}>
          <CorAccordionItem step={step} stepIdx={stepIdx} />
        </ContentstackContext.Provider>
      ))}
    </div>
  );
};
