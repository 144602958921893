import { FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { getElementTopOffset } from 'utils/get-element-offset-top';
import 'corporate-components/cor-rich-content-component/cor-rich-content-component.scss';
import { useContent } from 'hooks/use-content';

export const CorRichContentComponent: FC = () => {
  const { getContentByKey } = useContent();
  const data: any = getContentByKey('rich_text', {} as any);

  const containerColorCssModifier = `cor-rich-content--${(
    data?.container_background_color?.replace(' ', '-') || ''
  ).toLowerCase()}`;

  const handleRichTextClick = (event: SyntheticEvent) => {
    if (!(event.target instanceof HTMLAnchorElement)) {
      return;
    }

    const targetElementId = event.target.hash.substr(1);
    const targetElement = document.getElementById(targetElementId);

    if (targetElement) {
      const targetElementOffsetTop = getElementTopOffset(targetElement);

      event.preventDefault();
      window.scrollTo(0, targetElementOffsetTop);
    }
  };

  return (
    <div className={'cor-rich-content-component'}>
      <CorContentstackHtml
        contentKey="rich_text.rich_text"
        className={classNames('cor-rich-content', 'grid-container', containerColorCssModifier, 'cor-rich-text')}
        onClick={handleRichTextClick}
      />
    </div>
  );
};
