import { AnyAction } from 'redux';
import { stringify } from 'qs';
import { isEmpty } from 'lodash-es';
import { takeLatest, put } from 'redux-saga/effects';
import { push, replace } from 'redux-first-history';
import { history } from 'store';
import { Routes } from 'constants/routes.enum';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { submitLocationInvoiceOnly, submitLogin, submitLoginActionConstants } from 'store/customer-login/actions';
import { IAction } from 'types/actions';
import { Roles } from 'constants/roles.enum';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { allowedShopLocales } from 'constants/shop-locales';
import { ILocationState } from 'types/router';
import { CorContentStackLanguages } from 'constants/cor-locale.enum';
import { ISubmitLocationPayload } from './submit-location';
import { loadGASessionParams } from './get-session-params';

export interface ISubmitLoginPayload {
  email: string;
  password: string;
}

export function* submitLoginSagaHandler({ payload }: IAction<ISubmitLoginPayload>) {
  yield put(doRequest<ISubmitLoginPayload>(submitLogin, Endpoints.LOGIN, 'post', payload));
}

export function* submitLoginSuccessSagaHandler({ payload: { customerLocations, roles } }: AnyAction) {
  const query = localStorage.getItem('query');
  const locale = localStorage.getItem('locale');
  const isInternationalLocale = locale ? allowedShopLocales.includes(JSON.parse(locale)) : false;

  const isInvoiceOnlyUser = roles.includes(Roles.SUPER_ADMIN_INVOICE_ONLY);

  if (!isEmpty(customerLocations)) {
    if (query && !isInvoiceOnlyUser) {
      if (customerLocations.length <= 1) {
        history.push(`${Routes.ProductSearchPage}?${stringify({ query: query })}`);
        localStorage.removeItem('query');
      } else {
        yield put(push({ pathname: Routes.SelectLocationPage, state: history.location.state }));
      }
    } else {
      if (customerLocations.length <= 1) {
        yield loadGASessionParams();

        if (isInvoiceOnlyUser || isInternationalLocale) {
          const { from } = (history.location.state as ILocationState) ?? {};

          const isCanadaFrenchLocale = locale?.includes(CorContentStackLanguages.FRENCH_CANADA);
          const accountPaymentsRoute = isCanadaFrenchLocale
            ? getLocalizedShopUrl(Routes.CanadaFrenchPayments)
            : getLocalizedShopUrl(Routes.AccountPayments);

          yield put(push(from?.pathname ? from : accountPaymentsRoute));
        } else {
          const { from } = (history.location.state || { from: { pathname: Routes.ShopHomePage } }) as ILocationState;

          if (
            from.pathname === Routes.HomePage ||
            from.pathname === Routes.EmailVerificationPage ||
            from.pathname === Routes.ResetPasswordVerificationPage
          ) {
            from.pathname = Routes.ShopHomePage;
          }

          yield put(replace(from));
        }
      } else {
        if (isInvoiceOnlyUser || isInternationalLocale) {
          yield put(
            submitLocationInvoiceOnly.request<ISubmitLocationPayload>({
              locationNumber: customerLocations[0].locationId,
            })
          );
        } else {
          yield put(push({ pathname: Routes.SelectLocationPage, state: history.location.state }));
        }
      }
    }
  }
}

export default function* submitLoginSaga() {
  yield takeLatest(submitLoginActionConstants[Actions.REQUEST], submitLoginSagaHandler);
  yield takeLatest(submitLoginActionConstants[Actions.SUCCESS], submitLoginSuccessSagaHandler);
}
