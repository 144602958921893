import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { deleteAllCartItems, deleteAllCartItemsActionConstants } from 'store/shopping-cart/actions';

export function* deleteAllCartItemsSagaHandler() {
  yield put(doRequest(deleteAllCartItems, Endpoints.CART_REMOVE_ALL, 'delete'));
}

export default function* deleteAllCartItemsSaga() {
  yield takeLatest(deleteAllCartItemsActionConstants[Actions.REQUEST], deleteAllCartItemsSagaHandler);
}
