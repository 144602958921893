import { omit, flatten, isEmpty, get, isEqual, orderBy, isArray } from 'lodash-es';
import {
  IFilter,
  ISearchParamFilter,
  ISelectedFacet,
  ISearchFacets,
  IFacet,
  FacetLabelsEnum,
  SelectedFacetLabelsEnum,
} from 'types/facets';
import { ISearchCategory } from 'store/search-products/reducer';

export const getUpdatedFacets = (facetsList: ISelectedFacet[], facetItem: IFilter, facetToUpdate: number) => {
  const updatedFacets = facetsList.map((facetParam: ISelectedFacet, index: number) => {
    if (index !== facetToUpdate) {
      return facetParam;
    }
    if (facetParam.values?.includes(facetItem.value)) {
      return {
        ...facetParam,
        values: facetParam.values.filter((value: string) => value !== facetItem.value),
      };
    }
    return { ...facetParam, values: facetParam.values?.concat(facetItem.value) };
  });
  return updatedFacets;
};

export const getUpdatedSearchFacets = (updatedSearchFacets: ISearchFacets) => {
  return Object.keys(updatedSearchFacets)
    .filter((key) => updatedSearchFacets[key].length > 0)
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: updatedSearchFacets[key],
      };
    }, {});
};

export const updateFacetsSearch = (searchFacets: ISearchFacets, facetItem: ISearchParamFilter) => {
  if (isEmpty(searchFacets)) {
    return { [facetItem.key]: [facetItem.value] };
  }
  const existingFacetItem = searchFacets[facetItem.key];
  if (!isEmpty(existingFacetItem)) {
    let updatedSearchFacets: ISearchFacets = {};
    if (existingFacetItem.includes(facetItem.value)) {
      updatedSearchFacets = {
        ...searchFacets,
        [facetItem.key]: existingFacetItem.filter((value: string) => value !== facetItem.value),
      };
    } else {
      updatedSearchFacets = {
        ...searchFacets,
        [facetItem.key]: existingFacetItem.concat(facetItem.value),
      };
    }

    return getUpdatedSearchFacets(updatedSearchFacets);
  }

  return { ...searchFacets, [facetItem.key]: [facetItem.value] };
};

export const removeFacetsSearch = (searchFacets: ISearchFacets, facetItem: ISearchParamFilter) => {
  const existingFacetItem = get(searchFacets, facetItem.key);
  if (existingFacetItem) {
    let updatedSearchFacets: ISearchFacets = {
      ...searchFacets,
      [facetItem.key]: existingFacetItem.filter((value: string) => value !== facetItem.value),
    };

    return getUpdatedSearchFacets(updatedSearchFacets);
  }

  return { ...searchFacets };
};

export const decorateFacets = (facets: IFacet[]) => {
  return facets
    .map((facet) => ({
      ...facet,
      values: Object.keys(facet.terms),
      entries: Object.entries(facet.terms).map((nameWithCount) => ({
        name: nameWithCount[0],
        count: nameWithCount[1],
      })),
    }))
    .filter((facet) => facet.label !== FacetLabelsEnum.PRODUCT_STATUS);
};

export const parseFacetsSearch = (searchFacets: ISearchFacets) => {
  let mergedFacets: Partial<ISelectedFacet>[] = [];
  for (let facetKey in searchFacets) {
    mergedFacets.push({
      key: facetKey,
      values: searchFacets[facetKey],
    });
  }

  return flatten(
    mergedFacets.map((facet) =>
      isArray(facet.values)
        ? facet.values?.map((item) => {
            return { ...omit(facet, 'values'), value: item };
          })
        : []
    )
  );
};

export const getFlattenCategoriesList = (categories: ISearchCategory[]): ISearchCategory[] => {
  return categories
    ? categories.reduce(
        (result: ISearchCategory[], item: ISearchCategory) => [
          ...result,
          item,
          ...getFlattenCategoriesList(item.children || []),
        ],
        []
      )
    : [];
};

export const getSelectedCategoriesList = (categories: ISearchCategory[]): ISearchCategory[] => {
  return getFlattenCategoriesList(categories).filter((item) => item.isSelected) || [];
};

export const mergeSelectedFacets = (initialData: ISelectedFacet[], payloadFacets: IFilter[]) => {
  let newSelectedFacets: ISelectedFacet[] = initialData;
  payloadFacets.forEach((facet: IFilter) => {
    const facetIndex = newSelectedFacets.findIndex((item) => item?.key === facet.key);

    if (facetIndex > -1) {
      const selectedFacets = getUpdatedFacets(newSelectedFacets, facet, facetIndex);
      newSelectedFacets = [...selectedFacets.filter((item) => item.values?.length !== 0)];
      return;
    }

    newSelectedFacets = [...newSelectedFacets, { ...omit(facet, 'value'), values: [facet.value] }];
  });

  return [...newSelectedFacets];
};

export const mergeFacetsFromResponse = (initialData: ISelectedFacet[], payloadFacets: IFacet[]) => {
  const selectedFacetsResponse: ISelectedFacet[] = flatten(
    payloadFacets
      .filter((facet: IFacet) => facet.selectedValues.length > 0)
      .filter((facet) => facet.key !== SelectedFacetLabelsEnum.ARTICLE_STATUS)
      .map((facet: IFacet) => {
        return { key: facet.key, values: facet.selectedValues };
      })
  );

  if (!isEqual(orderBy(initialData, 'key'), orderBy(selectedFacetsResponse, 'key'))) {
    return selectedFacetsResponse;
  }

  return initialData;
};

export const getSwitchedDawnBrandFacets = (
  availableDawnBrandFacets: IFilter[],
  dawnBrandFacetsSelectedValues: string[]
) => {
  return availableDawnBrandFacets
    .map((brand) => brand.value)
    .filter((item) => !dawnBrandFacetsSelectedValues?.includes(item))
    .map((item) => {
      return { key: 'brand', value: item };
    });
};

export const getUpdatedDawnBrandsSearch = (
  isDawnBrandChecked: boolean,
  availableDawnBrandFacets: IFilter[],
  dawnBrandFacetsSelectedValues: string[],
  searchParams: any
) => {
  if (!isDawnBrandChecked) {
    const switchedDawnBrands: IFilter[] = getSwitchedDawnBrandFacets(
      availableDawnBrandFacets,
      dawnBrandFacetsSelectedValues
    );
    let updatedSearchParams = searchParams.facets;
    switchedDawnBrands.forEach((item) => (updatedSearchParams = updateFacetsSearch(updatedSearchParams, item)));
    updatedSearchParams = {
      ...searchParams,
      offset: undefined,
      facets: updatedSearchParams,
    };
    return { switchedDawnBrands, updatedSearchParams };
  } else {
    const { brand, ...searchFacets } = searchParams.facets;
    const updatedSearchParams = {
      ...searchParams,
      offset: undefined,
      facets: {
        ...searchFacets,
        brand: brand.filter(
          (brandItem: string) => !availableDawnBrandFacets.map((item) => item.value).includes(brandItem)
        ),
      },
    };
    return { updatedSearchParams };
  }
};

export const updateSelectedFacetsOnDawnBrandsReset = (state: ISelectedFacet[], dawnBrands: string[]) => {
  return state
    .map((item) =>
      item.key === 'brand'
        ? {
            key: item.key,
            values: item.values.filter((innerItem) => !dawnBrands.includes(innerItem)),
          }
        : item
    )
    .filter((item) => !isEmpty(item.values));
};

export const mapSelectedFacetsToSearchParams = (facets: ISelectedFacet[]) => {
  let facetsSearchParams = {};
  const mappedFacets = facets.reduce((acc: IFilter[], facet) => {
    facet.values.forEach((value) => {
      acc.push({
        key: facet.key,
        value,
      });
    });

    return acc;
  }, []);
  mappedFacets.forEach((item) => (facetsSearchParams = updateFacetsSearch(facetsSearchParams, item)));

  return facetsSearchParams;
};

export const isEqualSelectedFacets = (facets: ISelectedFacet[], facetsToCompareWith: ISelectedFacet[]): boolean => {
  if (facets.length !== facetsToCompareWith.length) {
    return false;
  }

  const sortedFacets = orderBy(facets, 'key');
  const sortedFacetsToCompareWith = orderBy(facetsToCompareWith, 'key');

  return sortedFacetsToCompareWith.every((facet, index) => {
    const sortedFacetOfCurrentIndex = sortedFacets[index];

    if (facet.key !== sortedFacetOfCurrentIndex.key) {
      return false;
    }

    if (facet.values.length !== sortedFacetOfCurrentIndex.values.length) {
      return false;
    }

    return JSON.stringify(facet.values) === JSON.stringify(sortedFacetOfCurrentIndex.values);
  });
};

export const getFacetsExceptArticleStatus = (facets: IFilter[]): IFilter[] =>
  facets.filter((facet) => facet.key !== 'article_status');
