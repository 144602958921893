import { useSelector } from 'react-redux';
import { Roles } from 'constants/roles.enum';
import { selectUserRole } from 'store/auth/selectors';

export const useGetUserRole = () => {
  const userRole = useSelector(selectUserRole);
  const isSuperAdmin = userRole.includes(Roles.SUPER_ADMIN);
  const isSuperAdminOrAdmin = [Roles.SUPER_ADMIN, Roles.ADMIN].some((role) => userRole.includes(role));

  const isSuperAdminOrAdminOrInvoiceOnly = [
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.SUPER_ADMIN_INVOICE_ONLY,
  ].some((role) => userRole.includes(role));

  return {
    isSuperAdminOrAdmin,
    isSuperAdmin,
    isSuperAdminOrAdminOrInvoiceOnly,
  };
};
