import { isEmpty } from 'lodash-es';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { getFormattedDateWithoutYear } from 'utils/date-format';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { selectOrderCutOffInfo } from 'store/order-cut-off/selectors';
import iconClose from 'assets/images/icons/icon-close-white.svg';
import iconCloseRed from 'assets/images/icons/icon-close-red.svg';
import {
  clearCutOffNotificationTimer,
  cutoffNotificationViewed,
  getCartExpiredNotificationViewed,
  getCutOffNotificationTimer,
  showNotificationTimer,
  setNotificationTimerExpired,
} from 'store/order-cut-off/actions';
import { ContentstackHtml } from 'components/contentstack/contentstack-html';
import { StickyView } from 'components/sticky-view';
import { StickySectionsKeys } from 'context/sticky-sections-heights-context';
import { useStickyOffsets } from 'hooks/use-sticky-offsets';
import { OrderCutoffTimer } from 'components/order-cutoff-notification/components/order-cutoff-timer/order-cutoff-timer';

import './order-cutoff-notification.scss';

export interface IOrderCutoffNotificationProps {
  page?: string;
  toggleSticky?: boolean;
}

type contentPathMapOptions = {
  [key: string]: {
    time_up_message: string;
    message: string;
    title: string;
    expired_title: string;
  };
};

export const OrderCutoffNotification: FC<IOrderCutoffNotificationProps> = ({
  page = AccessDefinitions.SHOPPING_CART_ACTIONS,
  toggleSticky,
}) => {
  const { isMobile, isDesktop } = useBreakpoint();
  const dispatch = useDispatch();
  const contentStackPath = 'page_content.order_cutoff_notification[0]';
  const { cutOffNotificationTimer, deliveryDates } = useSelector(selectOrderCutOffInfo);
  const [isTimeUp, setTimesUp] = useState(false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [isOrderCutoffBannerVisible, setOrderCutoffBannerVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const { offset } = useStickyOffsets({
    ref,
    sectionKey: StickySectionsKeys.orderCutoffNotification,
  });

  const deliveryDate = deliveryDates.length === 2 ? deliveryDates[0] : '';
  const nextDeliveryDate = deliveryDates.length === 2 ? deliveryDates[1] : deliveryDates[0];
  const isPastCutoffDate = isEmpty(deliveryDates);

  const isHomePage = page === AccessDefinitions.HOME_ACTIONS;
  const closeButton = isTimeUp && !isHomePage ? iconCloseRed : iconClose;

  const onCompleteTimer = () => {
    if (isPastCutoffDate) {
      setOrderCutoffBannerVisible(false);
    }
    setTimesUp(true);
  };

  const contentPathMap: contentPathMapOptions = {
    [AccessDefinitions.HOME_ACTIONS]: {
      time_up_message: 'MSG074',
      message: `${contentStackPath}.homepage_message`,
      title: `${contentStackPath}.homepage_notification_title`,
      expired_title: `${contentStackPath}.homepage_expired_title`,
    },
    [AccessDefinitions.SHOPPING_CART_ACTIONS]: {
      time_up_message: 'MSG076',
      message: 'MSG075',
      title: `${contentStackPath}.notification_title`,
      expired_title: `${contentStackPath}.expired_title`,
    },
  };

  const onCloseButtonClick = useCallback(() => {
    if (isTimeUp) {
      dispatch(getCartExpiredNotificationViewed.request({ reload: isHomePage }));
      setTimesUp(false);
    } else {
      dispatch(cutoffNotificationViewed.request({ reload: isHomePage }));
    }
    setOrderCutoffBannerVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isTimeUp]);

  useEffect(() => {
    if (cutOffNotificationTimer !== 0) {
      setOrderCutoffBannerVisible(true);
    } else if (!isPastCutoffDate) {
      setTimesUp(true);
    } else {
      setOrderCutoffBannerVisible(false);
    }
  }, [cutOffNotificationTimer, deliveryDates, isPastCutoffDate]);

  useEffect(() => {
    dispatch(
      getCutOffNotificationTimer.request({
        isTimerRequired: !isHomePage,
        showNextOrderDueDate: isHomePage,
      })
    );

    return () => {
      dispatch(clearCutOffNotificationTimer());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(showNotificationTimer(isOrderCutoffBannerVisible));
  }, [dispatch, isOrderCutoffBannerVisible]);

  useEffect(() => {
    dispatch(setNotificationTimerExpired(isTimeUp));
  }, [dispatch, isTimeUp]);

  useEffect(() => {
    if (isTimeUp) {
      isPastCutoffDate ? setOrderCutoffBannerVisible(false) : setOrderCutoffBannerVisible(true);
    }
  }, [isPastCutoffDate, isTimeUp]);

  const toggleMessage = () => {
    setIsMessageVisible(!isMessageVisible);
  };

  const showMessage = () => (
    <div className="order-cutoff-notification__message">
      {isTimeUp ? (
        isHomePage ? (
          <ContentstackText contentKey={`${contentStackPath}.homepage_expired_subtitle`} />
        ) : (
          <ContentstackHtml
            contentKey={`${contentStackPath}.expired_cart_message`}
            interpolateParams={{ nextDeliveryDate: getFormattedDateWithoutYear(nextDeliveryDate) }}
          />
        )
      ) : (
        !isHomePage && (
          <ContentstackHtml
            contentKey={`${contentStackPath}.cart_message`}
            interpolateParams={{
              deliveryDate: getFormattedDateWithoutYear(deliveryDate),
              nextDeliveryDate: getFormattedDateWithoutYear(nextDeliveryDate),
            }}
          />
        )
      )}
    </div>
  );

  const renderContext = () => (
    <>
      <div className="order-cutoff-notification__title">
        {isTimeUp ? (
          <ContentstackText contentKey={contentPathMap[page].expired_title} />
        ) : (
          <ContentstackText contentKey={contentPathMap[page].title} />
        )}
      </div>
      <div className="order-cutoff-notification__timer-container">
        <OrderCutoffTimer onComplete={onCompleteTimer} timerData={cutOffNotificationTimer} />
        {isMobile && (
          <button className="order-cutoff-notification__close-button" onClick={onCloseButtonClick}>
            <img src={closeButton} alt="close-button" />
          </button>
        )}
      </div>
    </>
  );

  const renderOrderCutoff = () => (
    <>
      {isOrderCutoffBannerVisible && (
        <div
          className={classNames('order-cutoff-notification', {
            'order-cutoff-notification--home-page': isHomePage,
            'order-cutoff-notification--expired': isTimeUp,
          })}
          ref={ref}
        >
          {isMobile && !isHomePage && (
            <div
              className={classNames('order-cutoff-notification__toggle-arrow', {
                'order-cutoff-notification__toggle-arrow-expanded': isMessageVisible,
                'order-cutoff-notification__toggle-arrow-collapsed': !isMessageVisible,
              })}
              onClick={toggleMessage}
            />
          )}
          <div className="order-cutoff-notification__context">
            {isMobile && isMessageVisible && showMessage()}

            {isHomePage ? (
              <div className="order-cutoff-notification__general-info">{renderContext()}</div>
            ) : (
              renderContext()
            )}
            {(isDesktop || isHomePage) && showMessage()}
          </div>
          {isDesktop && (
            <button className="order-cutoff-notification__close-button" onClick={onCloseButtonClick}>
              <img src={closeButton} alt="close-button" />
            </button>
          )}
        </div>
      )}
    </>
  );

  const renderCutoffSticky = () => (
    <StickyView offsetTop={offset} disableSticky={!isDesktop && !toggleSticky}>
      {renderOrderCutoff()}
    </StickyView>
  );

  return <>{toggleSticky ? renderCutoffSticky() : renderOrderCutoff()}</>;
};
