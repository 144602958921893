enum searchProductsActionTypes {
  SEARCH_PRODUCTS = 'SEARCH_PRODUCTS',
  CLEAR_SEARCH_STATE = 'CLEAR_SEARCH_STATE',
  SEARCH_PRODUCTS_PAGINATION_CHANGE = 'SEARCH_PRODUCTS_PAGINATION_CHANGE',
  SET_SEARCH_PRODUCTS_SORT_PARAMS = 'SET_SEARCH_PRODUCTS_SORT_PARAMS',
  SET_SEARCH_PRODUCTS_LIMIT = 'SET_SEARCH_PRODUCTS_LIMIT',
  SET_SEARCH_SELECTED_FACETS = 'SET_SEARCH_SELECTED_FACETS',
  CLEAR_SEARCH_SELECTED_FILTERS = 'CLEAR_SEARCH_SELECTED_FILTERS',
  SET_SEARCH_SELECTED_CATEGORIES = 'SET_SEARCH_SELECTED_CATEGORIES',
  GET_SEARCH_SUGGESTIONS = 'GET_SEARCH_SUGGESTIONS',
  CLEAR_SEARCH_SUGGESTIONS = 'CLEAR_SEARCH_SUGGESTIONS',
  RESET_SEARCH_DAWN_BRANDS = 'RESET_SEARCH_DAWN_BRANDS',
  //Temporary selected filters (categories, facets) for mobile filters feature
  GET_SEARCH_TEMPORARY_FILTERS_INFO = 'GET_SEARCH_TEMPORARY_FILTERS_INFO',
  INIT_SEARCH_TEMPORARY_SELECTED_FILTERS = 'INIT_SEARCH_TEMPORARY_SELECTED_FILTERS',
  SET_SEARCH_TEMPORARY_SELECTED_FILTERS = 'SET_SEARCH_TEMPORARY_SELECTED_FILTERS',
  CONFIRM_SEARCH_TEMPORARY_SELECTED_FILTERS = 'CONFIRM_SEARCH_TEMPORARY_SELECTED_FILTERS',
  RESET_SEARCH_TEMPORARY_SELECTED_FILTERS = 'RESET_SEARCH_TEMPORARY_SELECTED_FILTERS',
  CLEAR_SEARCH_TEMPORARY_SELECTED_FILTERS = 'CLEAR_SEARCH_TEMPORARY_SELECTED_FILTERS',
  SET_SEARCH_TEMPORARY_SELECTED_CATEGORIES = 'SET_SEARCH_TEMPORARY_SELECTED_CATEGORIES',
  SET_SEARCH_TEMPORARY_SELECTED_FACETS = 'SET_SEARCH_TEMPORARY_SELECTED_FACETS',
  SET_SEARCH_SELECTED_FILTERS = 'SET_SEARCH_SELECTED_FILTERS',
}

export default searchProductsActionTypes;
