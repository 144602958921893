import { UTILITY_LINK_TYPES } from 'components/layout/cor-header/cor-header.constants';
import { Routes } from 'constants/routes.enum';
import { IMainMenuLink } from 'components/layout/cor-header/cor-header.interface';
import { ICustomerNotificationItem } from 'types/notifications';

export const getTotalNotifications = (notifications: any[]) =>
  Object.values(notifications).map((notificationsInner) => {
    return Object.values(notificationsInner).reduce((count, notification: any) => {
      return count + notification.count;
    }, 0);
  });

export const getUtilityLinkTypes = (url: string): string | undefined => {
  return (
    (url === Routes.ShoppingCartPage && UTILITY_LINK_TYPES.CART) ||
    (url === Routes.NewsAndNotificationsPage && UTILITY_LINK_TYPES.NOTIFICATIONS) ||
    undefined
  );
};

export const convertNotifications = (notifications: ICustomerNotificationItem[]): IMainMenuLink[] => {
  return notifications && notifications.length
    ? notifications.map((notification) => {
        return {
          openInNewTab: false,
          children: [],
          label: `<div class="notification">${notification?.title}<p>(${notification?.count})</p></div>`,
          url: '',
        };
      })
    : [];
};

// Update menuItems..
// provide counts for the ones that require them..
// showInNav allows the desktop and mobile utilitynavs to filter their items...
// this can, likely, be cleaned up.
export const handleUpdateUtilityDataWithCounts = (data, notifications, cartCount, isMobile, isLoggedIn) => {
  return {
    ...data,
    menuItems: data.menuItems?.reduce((memo: any, menuItem) => {
      if (menuItem.url === Routes.ShoppingCartPage) {
        return [...memo, { ...menuItem, counter: cartCount, showInNav: true }];
      }
      if (menuItem.url === Routes.NewsAndNotificationsPage) {
        const totalItems: any[] = getTotalNotifications(notifications);
        return totalItems[0] && totalItems[0] > 0
          ? [
              ...memo,
              {
                ...menuItem,
                counter: totalItems[0],
                notifications: notifications,
                menuPanelItems: !!notifications ? convertNotifications(notifications.notificationItems) : [],
                showInNav: true,
                showInMobileNav: true,
                isHiddenInMobileStickyHeader: true,
                menuPanelTitle: menuItem.text,
              },
            ]
          : memo;
      }
      return [
        ...memo,
        {
          ...menuItem,
          showInNav:
            !isLoggedIn && (!isMobile || [Routes.LoginPage, Routes.CanadaFrenchLoginPage].includes(menuItem.url)),
        },
      ];
    }, []),
  };
};
