import { FC, FormEvent, useEffect, useState } from 'react';
import { useContent } from 'hooks/use-content';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { CustomRadioButton } from 'components/custom-radio-button';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './cancel-quote-with-reason-modal.scss';

export interface IReason {
  reason: {
    label: string;
  };
}

export interface ICancelQuoteWithReasonModalProps extends IModalProps {
  contentstackPath: string;
  onCancel: (reason?: string) => void;
  reasonRequired?: boolean;
}

export const CancelQuoteWithReasonModal: FC<ICancelQuoteWithReasonModalProps> = ({
  contentstackPath,
  isOpen,
  onClose,
  onCancel,
  reasonRequired = true,
}) => {
  const { getContentByKey } = useContent();
  const cancelReasons = getContentByKey<IReason[]>(`${contentstackPath}.cancel_reasons`, []);
  const [selectedCancelReason, setSelectedCancelReason] = useState<string | null>(null);
  const [otherReasonChecked, setOtherReasonChecked] = useState(false);
  const [otherReason, setOtherReason] = useState('');

  const createRadioButtonCheckHandler = (reason: string) => () => {
    setOtherReasonChecked(false);

    if (reason === selectedCancelReason) {
      setSelectedCancelReason(null);
    } else {
      setSelectedCancelReason(reason);
    }
  };

  const handleTextAreaChange = (e: FormEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.currentTarget.value);
  };

  const handleOtherReasonCheck = () => {
    setSelectedCancelReason(null);
    setOtherReasonChecked(!otherReasonChecked);
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedCancelReason(null);
      setOtherReason('');
      setOtherReasonChecked(false);
    }
  }, [isOpen]);

  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');

  const closeModalHandler = () => {
    if (selectedCancelReason || otherReason) {
      const closingIsAllowed = window.confirm(leavingPageModalText);
      if (closingIsAllowed) {
        onClose && onClose();
      }
      return;
    }

    onClose && onClose();
  };

  return (
    <Modal className="cancel-quote-with-reason-modal" isOpen={isOpen} onClose={closeModalHandler} withBackdropClick>
      <ModalHeader titleContentstackPath={`${contentstackPath}.modal_title`} />
      <div className="cancel-quote-with-reason-modal__content">
        <p className="cancel-quote-with-reason-modal__description">
          <ContentstackText contentKey={`${contentstackPath}.description`} />
        </p>
        {cancelReasons.map(({ reason: { label } }, i) => (
          <CustomRadioButton
            checked={selectedCancelReason === label}
            onChange={createRadioButtonCheckHandler(label)}
            key={i}
            uncheckable={!reasonRequired}
          >
            {label}
          </CustomRadioButton>
        ))}
        <CustomRadioButton checked={otherReasonChecked} onChange={handleOtherReasonCheck} uncheckable={!reasonRequired}>
          <ContentstackText contentKey={`${contentstackPath}.other_reason_label`} />
        </CustomRadioButton>
        <textarea
          placeholder={getContentByKey<string>(`${contentstackPath}.other_reason_placeholder`, '')}
          rows={3}
          maxLength={300}
          value={otherReason}
          onChange={handleTextAreaChange}
          disabled={!otherReasonChecked}
        />
      </div>
      <ModalFooter className="cancel-quote-with-reason-modal__footer">
        <CorButton className="link-1 cancel-quote-with-reason-modal__cancel-button" onClick={closeModalHandler}>
          <ContentstackText contentKey={`${contentstackPath}.close_modal_button_label`} />
        </CorButton>
        <CorButton
          className="cancel-quote-with-reason-modal__cancel-request-button"
          disabled={reasonRequired && !(selectedCancelReason || otherReason)}
          onClick={() => onCancel(selectedCancelReason || otherReason)}
        >
          <ContentstackText contentKey={`${contentstackPath}.submit_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
