import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';
import { RecentOrdersSlider } from 'features/order-updates/components/recent-orders-slider';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useReorder } from 'hooks/use-reorder';
import { selectRecentOrdersInfoOrders } from 'store/home/selectors';
import { CorButton } from 'components/cor-button';
import { Routes } from 'constants/routes.enum';
import { Permissions } from 'constants/permissions';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { ORDER_STATUSES_ENUM } from 'constants/order-statuses.enum';
import { PermissionAccess } from 'components/permission-access';
import { stripLeadingZeros } from 'utils/string-format';
import { useAllItemsAddedToCartResultMessageModal } from 'hooks/use-global-modal';
import iconChevron from 'assets/images/icons/icon-chevron-black.svg';
import { getRecentOrdersInfo } from 'store/home/actions';
import { IGetRecentOrdersPayload } from 'store/home/sagas/get-recent-orders';
import { EmptyOrderUpdates } from 'features/order-updates/components/empty-order-updates';
import { reorderProducts } from 'store/shopping-cart/actions';
import { IReorderProductToCartPayload } from 'store/shopping-cart/sagas/reorder-products-to-cart';
import { selectIsUserLocationDeleted } from 'store/auth/selectors';

import './recent-orders.scss';

export const RecentOrders: FC = () => {
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const recentOrders = useSelector(selectRecentOrdersInfoOrders);
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const contentstackPath = 'page_content.order_updates.0.recent_orders';

  useReorder();
  const showAllItemsAddedToCartResultMessageModal = useAllItemsAddedToCartResultMessageModal();

  useEffect(() => {
    if (recentOrders === null) {
      dispatch(
        getRecentOrdersInfo.request<IGetRecentOrdersPayload>({
          limit: 6,
        })
      );
    }
  }, [dispatch, recentOrders]);

  const renderMobileView = () => (
    <div className="recent-orders">
      <div className="recent-orders__slider">
        <RecentOrdersSlider />
      </div>
      <div className="recent-orders__link recent-orders__see-all">
        <Link to={`${Routes.AccountOrderHistoryPage}`}>
          <ContentstackText contentKey={`${contentstackPath}.see_all_link_text`} />
          <img src={iconChevron} alt="icon expand" />
        </Link>
      </div>
    </div>
  );

  const handleReorder = (orderId: string) =>
    dispatch(
      reorderProducts.request<IReorderProductToCartPayload>({
        orderId,
        onFailCallback: () => {
          showAllItemsAddedToCartResultMessageModal({ messages: { successfullyAddedItems: 0, errors: ['MSG025'] } });
        },
      })
    );

  return isEmpty(recentOrders) ? (
    <EmptyOrderUpdates />
  ) : isMobile ? (
    renderMobileView()
  ) : (
    <div className="recent-orders">
      <div className="grid-x">
        <div className="recent-orders__column-name recent-orders__order-date">
          <ContentstackText contentKey={`${contentstackPath}.order_date_label`} />
        </div>
        <div className="recent-orders__column-name recent-orders__order-number">
          <ContentstackText contentKey={`${contentstackPath}.order_number_label`} />
        </div>
        <div className="recent-orders__column-name recent-orders__order-status">
          <ContentstackText contentKey={`${contentstackPath}.order_status_label`} />
        </div>
        <div className="recent-orders__link recent-orders__see-all">
          <Link to={`${Routes.AccountOrderHistoryPage}`}>
            <ContentstackText contentKey={`${contentstackPath}.see_all_link_text`} />
            <img src={iconChevron} alt="icon expand" />
          </Link>
        </div>
      </div>

      {recentOrders?.map(({ orderNumber, orderDate, orderStatus, ctId, isQuoteOrder }) => (
        <div className="recent-orders__order medium-12 grid-x" key={ctId}>
          <div className="recent-orders__order-info-block recent-orders__order-date">{orderDate}</div>
          <div className="recent-orders__order-info-block recent-orders__order-number">
            {!orderNumber || orderStatus === ORDER_STATUSES_ENUM.SUBMITTED ? (
              <ContentstackText contentKey={`${contentstackPath}.order_number_unavailable`} />
            ) : (
              stripLeadingZeros(orderNumber)
            )}
          </div>
          <div className="recent-orders__order-info-block recent-orders__order-status">{orderStatus}</div>
          <div className="recent-orders__order-info-block recent-orders__order-actions">
            {!isQuoteOrder && (
              <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
                <CorButton
                  disabled={isUserLocationDeleted}
                  className="recent-orders__reorder-button"
                  color="text"
                  onClick={() => handleReorder(ctId)}
                >
                  <ContentstackText contentKey={`${contentstackPath}.reorder_button_label`} />
                </CorButton>
              </PermissionAccess>
            )}
            <Link className="recent-orders__link" to={`${Routes.OrderDetails}/${ctId}`}>
              <ContentstackText contentKey={`${contentstackPath}.view_link_text`} />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
