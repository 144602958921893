import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { BuyItAgainSkusSlider } from 'features/order-updates/components/buy-it-again-skus-slider';
import { Routes } from 'constants/routes.enum';
import iconChevron from 'assets/images/icons/icon-chevron-black.svg';
import { getBuyItAgainProducts } from 'store/home/actions';
import { selectBuyItAgainProducts } from 'store/home/selectors';
import { ContentstackText } from 'components/contentstack';
import { EmptyOrderUpdates } from 'features/order-updates/components/empty-order-updates';
import { ORDER_VIEWS } from 'constants/order-view.enum';
import { BuyItAgainItem } from './buy-it-again-item';

import './buy-it-again.scss';

export const BuyItAgain = () => {
  const { isDesktop } = useBreakpoint();
  const buyItAgainProducts = useSelector(selectBuyItAgainProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (buyItAgainProducts === null) {
      dispatch(getBuyItAgainProducts.request());
    }
  }, [dispatch, buyItAgainProducts]);

  return isEmpty(buyItAgainProducts) ? (
    <EmptyOrderUpdates />
  ) : isDesktop ? (
    <div className="buy-it-again">
      <div className="buy-it-again__see-all-link">
        <Link
          to={`${Routes.AccountOrderHistoryPage}?offset=0&selectedView=${ORDER_VIEWS.PRODUCT}&sortingValue=2`}
          className="buy-it-again__see-all"
        >
          <ContentstackText contentKey="page_content.buy_it_again[0].see_all_link_text" />
          <img src={iconChevron} alt="icon expand" />
        </Link>
      </div>
      {buyItAgainProducts?.map((lineItem) => (
        <BuyItAgainItem lineItem={lineItem} key={lineItem.number} />
      ))}
    </div>
  ) : (
    <div className="buy-it-again--mobile">
      <BuyItAgainSkusSlider />
      <div className="buy-it-again__see-all">
        <Link to={`${Routes.AccountOrderHistoryPage}?offset=0&selectedView=${ORDER_VIEWS.PRODUCT}&sortingValue=2`}>
          <ContentstackText contentKey="page_content.buy_it_again[0].see_all_link_text" />
          <img src={iconChevron} alt="icon expand" />
        </Link>
      </div>
    </div>
  );
};
