import { FC, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { omit, isEqual, isEmpty } from 'lodash-es';
import { components, MultiValue, SingleValue } from 'react-select';
import { CustomDropdown, IOptionType } from 'components/custom-dropdown';
import { IPersonalInformation, IUserAtLocationInfo } from 'types/user-info';
import { UserRole } from 'types/roles';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { Roles } from 'constants/roles.enum';
import { TextField } from 'components/forms/text-field';
import { FormWithValidationOnSubmit, IFormState } from 'components/forms/form-with-validation-on-submit';
import { ErrorNotification } from 'components/forms/error-notification';
import { PageLeaveConfirmationModal } from 'components/page-leave-confirmation-modal';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { CustomTooltip } from 'components/custom-tooltip';
import { useContent } from 'hooks/use-content';
import { nameValidator, phoneValidator, createValidationSchema } from 'utils/validation';
import { getUserInformation } from 'store/account/actions';
import { selectEditingUserInformation } from 'store/account/selectors';
import { ILocationInformation } from 'store/account/reducer';
import { Subscriptions } from 'pages/account/components/subscriptions';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';
import { SubmitButton } from 'components/forms/submit-button';

import './edit-locations-user-info-modal.scss';

const PATH_TO_MODAL = 'modals.0.edit_user_info_modal.0';
const PATH_TO_FORM = `${PATH_TO_MODAL}.edit_info_form.0`;

export interface IEditUserInfoValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  locations: IDropdownOptions[];
  accountType: UserRole;
}

export interface IDropdownOptions {
  value: string;
  label: string;
  isSelected: boolean;
  isDeleted: boolean;
}

export interface IEditLocationsUserInfoModalProps extends IModalProps {
  id: string;
  currentAccountType: UserRole;
  onSave: (
    personalInfo: IEditUserInfoValues,
    allLocations: IDropdownOptions[],
    resetForm: () => void,
    activeUser: IUserAtLocationInfo
  ) => void;
  activeUser: IUserAtLocationInfo;
}

/**
 * This modal is used on Locations/Users page to edit users' info
 */
export const EditLocationsUserInfoModal: FC<IEditLocationsUserInfoModalProps> = ({
  isOpen = false,
  id,
  onClose = () => {},
  onSave,
  currentAccountType,
  activeUser,
}) => {
  const { getContentByKey, getMessageText } = useContent();
  const dispatch = useDispatch();
  const user = useSelector(selectEditingUserInformation);

  const { firstName, lastName, phoneNumber, email, locations } = user;

  const userLocations: IDropdownOptions[] =
    locations?.map((location: ILocationInformation) => {
      return {
        value: location.locationId,
        label: location.name,
        isSelected: location.isSelected,
        isDeleted: location.isDeleted,
      };
    }) || [];

  const defaultSelectedLocations = userLocations?.filter((location: IDropdownOptions) => location.isSelected);

  const [selectedLocations, setSelectedLocations] = useState<IDropdownOptions[]>(defaultSelectedLocations);

  const multiValueTitle = getContentByKey(`${PATH_TO_FORM}.location_access_multi_value_title`, '');
  const validationSchema = createValidationSchema({
    firstName: nameValidator({
      required: getMessageText('error', 'MSG004'),
      wrongFormat: getMessageText('error', 'MSG005'),
    }),
    lastName: nameValidator({
      required: getMessageText('error', 'MSG004'),
      wrongFormat: getMessageText('error', 'MSG005'),
    }),
    phoneNumber: phoneValidator({
      required: getMessageText('error', 'MSG008'),
      wrongFormat: getMessageText('error', 'MSG009'),
    }),
  });

  const selectedRole =
    currentAccountType === Roles.ADMIN
      ? getContentByKey<string>(`${PATH_TO_FORM}.admin_option`, '')
      : getContentByKey<string>(`${PATH_TO_FORM}.user_option`, '');

  const currentRole: IOptionType = {
    value: currentAccountType,
    label: selectedRole,
  };

  const [accountTypeOption, setAccountTypeOption] = useState<IOptionType>(currentRole);

  useEffect(() => {
    setAccountTypeOption(currentRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountType]);

  const accountRenderOption = ({ children, ...props }: any) => {
    return (
      <components.Option className="account-dropdown" {...props}>
        <div className="account-dropdown__item">{children}</div>
      </components.Option>
    );
  };

  const accountTypes = [
    { value: Roles.ADMIN, label: getContentByKey<string>(`${PATH_TO_FORM}.admin_option`, '') },
    { value: Roles.USER, label: getContentByKey<string>(`${PATH_TO_FORM}.user_option`, '') },
  ];

  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');

  const initialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    email: email || '',
    accountType: currentRole.value,
    locations: defaultSelectedLocations,
  };

  const handleFormSubmit = (values: IEditUserInfoValues, { resetForm }: FormikHelpers<IEditUserInfoValues>) => {
    onSave(values, userLocations, resetForm, activeUser);
  };

  useEffect(() => {
    if (!isEmpty(defaultSelectedLocations)) {
      setSelectedLocations(defaultSelectedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    if (id && isOpen) {
      dispatch(getUserInformation.request({ userId: id }));
    }
  }, [dispatch, id, isOpen]);

  const closeModalHandler = (dataWasChanged: boolean, resetForm: () => void) => {
    if (dataWasChanged) {
      const closingIsAllowed = window.confirm(leavingPageModalText);

      if (closingIsAllowed) {
        resetForm();
        onClose();
      }
      return;
    }

    onClose();
  };

  const formContainsEmptyRequiredFields = (formValues: IPersonalInformation) => {
    return Object.values(omit(formValues, 'email')).some((value) => value === '');
  };

  const locationsRenderOption = ({ children, ...props }: any) => (
    <components.Option className="locations" {...props}>
      <>
        {props.data.isDeleted && props.data.isSelected ? (
          <div className="locations-dropdown__item--deleted">
            *{children} <ContentstackText contentKey="common[0].deleted_location_label" />
          </div>
        ) : (
          <div className="locations-dropdown__item"> {children}</div>
        )}
      </>
    </components.Option>
  );

  return (
    <div className="edit-locations-user-info-modal">
      <Formik
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleFormSubmit}
      >
        {({ dirty, isValid, handleSubmit, values, isSubmitting, setErrors, errors, resetForm, setFieldValue }) => (
          <FormWithValidationOnSubmit
            className="edit-locations-user-info-modal__form"
            onChange={(event) => {
              const fieldName = (event.target as HTMLInputElement).name;
              setErrors(omit(errors, fieldName));
            }}
            {...{ handleSubmit, isValid, dirty }}
          >
            {({ invalidFormIsSubmitted }: IFormState) => {
              return (
                <>
                  <Modal
                    className="edit-locations-user-info-modal__content"
                    isOpen={isOpen}
                    onClose={() =>
                      closeModalHandler(dirty || !isEqual(defaultSelectedLocations, values.locations), resetForm)
                    }
                    size="large"
                    withBackdropClick
                  >
                    <ModalHeader titleContentstackPath={`${PATH_TO_MODAL}.heading`} />
                    <div className="edit-locations-user-info-modal__body">
                      {invalidFormIsSubmitted && (
                        <ErrorNotification
                          className="edit-locations-user-info-modal__general-error"
                          message={getMessageText('error', 'MSG019')}
                        />
                      )}

                      <div className="edit-locations-user-info-modal__form-fields">
                        <TextField
                          className="edit-locations-user-info-modal__field"
                          id="firstName"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.first_name_field_label`, '')}
                          name="firstName"
                        />
                        <TextField
                          className="edit-locations-user-info-modal__field"
                          id="lastName"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.last_name_field_label`, '')}
                          name="lastName"
                        />
                        <TextField
                          className="edit-locations-user-info-modal__field"
                          id="phoneNumber"
                          type="tel"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.phone_number_user_info`, '')}
                          name="phoneNumber"
                        />
                        <div className="edit-locations-user-info-modal__field">
                          <div className="edit-locations-user-info-modal__email-field-label">
                            <ContentstackText contentKey={`${PATH_TO_FORM}.email_field_label`} />
                          </div>
                          <div className="edit-locations-user-info-modal__email-field-value">{values.email}</div>
                        </div>

                        <div className="edit-locations-user-info-modal__field">
                          <div className="edit-locations-user-info-modal__account-field-label">
                            <ContentstackText contentKey={`${PATH_TO_FORM}.account_type_field_label`} />
                            <CustomTooltip tooltipId="account-type-tooltip">
                              <p>
                                <ContentstackMessage type="tooltips" messageId="MSG090a" />
                              </p>
                              <p>
                                <ContentstackMessage type="tooltips" messageId="MSG090b" />
                              </p>
                            </CustomTooltip>
                          </div>
                          {user.allowToUpdateAccountType ? (
                            <CustomDropdown
                              inputId="edit-locations-user-info-modal__account-dropdown--id"
                              className="edit-locations-user-info-modal__account-dropdown"
                              name="accountType"
                              items={accountTypes}
                              defaultValue={currentRole}
                              value={accountTypeOption}
                              isDisabled={!user.allowToUpdateAccountType}
                              renderOption={accountRenderOption}
                              onChange={(accountTypeOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
                                const newOption = accountTypeOption as IOptionType;
                                setFieldValue('accountType', newOption.value);
                                setAccountTypeOption(newOption);
                              }}
                            />
                          ) : (
                            <p className="edit-locations-user-info-modal__account-field-text">{currentRole.label}</p>
                          )}
                        </div>

                        <div className="edit-locations-user-info-modal__field">
                          <p className="edit-locations-user-info-modal__locations-label text-field__label">
                            <ContentstackText contentKey={`${PATH_TO_FORM}.location_access_label`} />
                            <CustomTooltip tooltipId="locations-tooltip">
                              <ContentstackMessage type="tooltips" messageId="MSG121" />
                            </CustomTooltip>
                          </p>
                          <CustomDropdown
                            inputId="edit-locations-user-info-modal__locations-dropdown--id"
                            name="locations"
                            items={userLocations}
                            renderOption={locationsRenderOption}
                            placeholder={getContentByKey<string>(`${PATH_TO_FORM}.location_access_placeholder`, '')}
                            value={selectedLocations}
                            isMulti
                            multiValueTitle={multiValueTitle}
                            closeMenuOnSelect={userLocations.length === 1}
                            onChange={(locationsOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
                              const newOption = locationsOption as IDropdownOptions[];

                              newOption.forEach((item) => (item.isSelected = true));
                              setFieldValue('locations', newOption);
                              setSelectedLocations(newOption);
                            }}
                            className={'edit-locations-user-info-modal__locations-dropdown'}
                          />
                        </div>
                      </div>
                      <Subscriptions
                        hasOrderSubscription={user.hasOrderSubscription}
                        hasQuoteSubscription={user.hasQuoteSubscription}
                      />
                    </div>

                    <ModalFooter>
                      <CorButton
                        className="text edit-locations-user-info-modal__cancel-btn"
                        onClick={() =>
                          closeModalHandler(dirty || !isEqual(defaultSelectedLocations, values.locations), resetForm)
                        }
                      >
                        <ContentstackText contentKey={`${PATH_TO_FORM}.cancel_button_title`} />
                      </CorButton>
                      <SubmitButton
                        className="edit-locations-user-info-modal__submit-btn"
                        isDisabled={
                          formContainsEmptyRequiredFields(values) ||
                          isSubmitting ||
                          (!dirty && isEqual(defaultSelectedLocations, values.locations))
                        }
                      >
                        <ContentstackText contentKey={`${PATH_TO_FORM}.save_button_user_info`} />
                      </SubmitButton>
                    </ModalFooter>

                    {(dirty || !isEqual(defaultSelectedLocations, values.locations)) && <PageLeaveConfirmationModal />}
                  </Modal>
                </>
              );
            }}
          </FormWithValidationOnSubmit>
        )}
      </Formik>
    </div>
  );
};
