import { FC } from 'react';
import { Modal } from 'components/modals/common/modal';
import { ContentstackMessage } from 'components/contentstack';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './create-shopping-list-success-message-modal.scss';

export interface ICreateShoppingListSuccessMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  listName: string;
  onListNameClick: () => void;
}

export const CreateShoppingListSuccessMessageModal: FC<ICreateShoppingListSuccessMessageModalProps> = ({
  isOpen,
  onClose,
  listName,
  onListNameClick,
}) => {
  const contentstackPath = 'modals.0.success_error_modal.0';

  return (
    <Modal
      size="small"
      className="create-shopping-list-success-modal"
      isOpen={isOpen}
      onClose={onClose}
      withBackdropClick
    >
      <ModalHeader iconType="success" contentstackPath={contentstackPath}>
        <ContentstackMessage type="success" messageId="MSG081a" />
        <span className="create-shopping-list-success-modal__link" onClick={onListNameClick}>
          {' '}
          {listName}{' '}
        </span>
        <ContentstackMessage type="success" messageId="MSG081b" />
      </ModalHeader>
      <ModalFooter contentstackPath={contentstackPath} closeButtonHandler={onClose} hasCloseButton />
    </Modal>
  );
};
