import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { ICustomerLoginState } from './reducer';

export const selectCustomerLoginEntity = (state: IState): ICustomerLoginState => state.app.customerLogin;

export const selectCustomerLoginForm = createSelector(
  selectCustomerLoginEntity,
  (customerLoginEntity) => customerLoginEntity.customerLoginForm
);

export const selectCustomerLoginFormServerErrorId = createSelector(
  selectCustomerLoginForm,
  (customerLoginForm) => customerLoginForm.serverErrorId
);

export const selectCustomerLoginFormLoginAttemptCounter = createSelector(
  selectCustomerLoginForm,
  (customerLoginForm) => customerLoginForm.loginAttemptCounter
);

export const selectCustomerLoginFormIsSubmitting = createSelector(
  selectCustomerLoginForm,
  (customerLoginForm) => customerLoginForm.isSubmitting
);

export const selectCustomerLocations = createSelector(selectCustomerLoginEntity, (customerLoginEntity) => {
  return customerLoginEntity.customerLocations.map((item) => {
    return { value: item.locationId, label: item.name, isDeleted: item.isDeleted };
  });
});

export const selectIsClientIdle = createSelector(
  selectCustomerLoginForm,
  (customerLoginForm) => customerLoginForm.isClientIdle
);
