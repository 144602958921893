import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import {
  addProductToFavorites,
  addProductToFavoritesActionConstants,
  updateProductFavoritesStatus,
} from 'store/shopping-lists/actions';
import { IAction } from 'types/actions';

export interface IAddProductToFavoritesPayload {
  sku: string;
  quantity: number;
  onSuccessCallBack?: () => void;
  onFailCallBack?: (errorStatus: number) => void;
}

export function* addProductToFavoritesOnSuccessCallBackSagaHandler({
  payload,
}: IAction<IAddProductToFavoritesPayload>) {
  yield take(addProductToFavoritesActionConstants[Actions.SUCCESS]);
  yield put(updateProductFavoritesStatus(payload.sku, true));
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* addProductToFavoritesOnFailCallBackSagaHandler({ payload }: IAction<IAddProductToFavoritesPayload>) {
  const { error } = yield take(addProductToFavoritesActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack) {
    payload.onFailCallBack(error.status);
  }
}

export function* addProductToFavoritesSagaHandler({ payload }: IAction<IAddProductToFavoritesPayload>) {
  const { sku, quantity } = payload;
  yield put(
    doRequest(addProductToFavorites, Endpoints.FAVORITES_LIST_ITEMS, 'put', {
      lineItems: [{ sku, quantity }],
    })
  );
}

export default function* addProductToFavoritesSaga() {
  yield takeLatest(
    addProductToFavoritesActionConstants[Actions.REQUEST],
    addProductToFavoritesOnSuccessCallBackSagaHandler
  );
  yield takeLatest(addProductToFavoritesActionConstants[Actions.REQUEST], addProductToFavoritesSagaHandler);
  yield takeLatest(
    addProductToFavoritesActionConstants[Actions.REQUEST],
    addProductToFavoritesOnFailCallBackSagaHandler
  );
}
