enum accountActionTypes {
  GET_ACCOUNT_INFORMATION = 'GET_ACCOUNT_INFORMATION',
  UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO',
  GET_USER_INFORMATION = 'GET_USER_INFORMATION',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  CLEAR_UPDATED_USER_INFO = 'CLEAR_UPDATED_USER_INFO',
  DELETE_USER = 'DELETE_USER',
  INVITE_NEW_USER = 'INVITE_NEW_USER',
  SET_ACCOUNT_PASSWORD = 'SET_ACCOUNT_PASSWORD',
  GET_SALES_REP_INFORMATION = 'GET_SALES_REP_INFORMATION',
  CLEAR_CHANGE_PASSWORD_FORM_SERVER_ERROR = 'CLEAR_CHANGE_PASSWORD_FORM_SERVER_ERROR',
  CLOSE_CHANGE_PASSWORD_MODAL = 'CLOSE_CHANGE_PASSWORD_MODAL',
  GET_ORDER_HISTORY = 'GET_ORDER_HISTORY',
  GET_ORDER_DETAILS = 'GET_ORDER_DETAILS',
  ORDER_HISTORY_PAGINATION_CHANGE = 'ORDER_HISTORY_PAGINATION_CHANGE',
  ORDER_HISTORY_PRODUCTS_PAGINATION_CHANGE = 'ORDER_HISTORY_PRODUCTS_PAGINATION_CHANGE',
  CANCEL_ORDER = 'CANCEL_ORDER',
  SET_ORDER_CANCELLATION_DENIED = 'SET_ORDER_CANCELLATION_DENIED',
  GET_ORDER_HISTORY_PRODUCTS = 'GET_ORDER_HISTORY_PRODUCTS',
  SET_ACTIVE_ORDER_VIEW = 'SET_ACTIVE_ORDER_VIEW',
  SET_ACTIVE_PAYMENT_VIEW = 'SET_ACTIVE_PAYMENT_VIEW',
  UPDATE_PASSWORD_FROM_TEMPORARY = 'UPDATE_PASSWORD_FROM_TEMPORARY',
  GENERATE_TEMPORARY_PASSWORD = 'GENERATE_TEMPORARY_PASSWORD',
  RESEND_INVITATION_TO_PENDING_USERS = 'RESEND_INVITATION_TO_PENDING_USERS',
  RESET_INVITATION_EMAIL_STATUS = 'RESET_INVITATION_EMAIL_STATUS',
  GET_ALL_SKUS_PRODUCTS_LIST = 'GET_ALL_SKUS_PRODUCTS_LIST',
  CLEAR_ALL_SKUS_PRODUCTS_LIST = 'CLEAR_ALL_SKUS_PRODUCTS_LIST',
  SET_ACTIVE_EDIT_USER_ID = 'SET_ACTIVE_EDIT_USER_ID',
}

export default accountActionTypes;
