import './cor-featured-content.scss';

import React from 'react';
import classNames from 'classnames';
import { CorTypography } from 'corporate-ui';
import { CorContentstackButton } from 'corporate-components/cor-contentstack';
import { ContentstackContext } from 'context/contentstack';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import useFeaturedContent from './use-featured-content';

const ENTRIES_KEY = 'entries';

const CorFeaturedContentCards = () => {
  const { entries, CardsVariant, CardVariant, cardDisplayNumber, isSmallCardVariant } = useFeaturedContent();

  return (
    <ContentstackContext.Provider value={{ entries, card_display_number: cardDisplayNumber }}>
      <CardsVariant isSmallCardVariant={isSmallCardVariant}>
        {entries?.map((_, index) => (
          <CardVariant contentKeyBase={`${ENTRIES_KEY}[${index}]`} key={index.toString()} />
        ))}
      </CardsVariant>
    </ContentstackContext.Provider>
  );
};

const BACKGROUND_COLOR_KEY = 'featured_content.featured_content.container_background_color';
const HEADER_TEXT_ALIGNMENT_KEY = 'featured_content.text_alignment';
const TITLE_KEY = 'featured_content.title';
const DESCRIPTION_KEY = 'featured_content.description';
const BUTTON_KEY = 'featured_content.button';

const CorFeaturedContent = ({ className = '' }) => {
  const { getBackgroundColorByKey, getContentByKey } = useContent();
  const { isMobile } = useBreakpoint();

  const backgroundColor = getBackgroundColorByKey<string>(BACKGROUND_COLOR_KEY, '');
  const isHeaderCentered = isMobile || /center/i.test(getContentByKey(HEADER_TEXT_ALIGNMENT_KEY, 'left'));
  const title = getContentByKey(TITLE_KEY, '');
  const description = getContentByKey(DESCRIPTION_KEY, '');
  const button: any = getContentByKey(BUTTON_KEY, {});

  return (
    <div
      className={classNames(
        'cor-featured-content',
        { [`cor-bg-color__${backgroundColor}`]: !!backgroundColor },
        { [className]: !!className }
      )}
    >
      {(!!title || !!description || !!Object.keys(button).length) && (
        <div className="cor-featured-content__header grid-container">
          {(!!title || !!description) && (
            <div className="cor-featured-content__header__text">
              {!!title && (
                <CorTypography
                  variant="h2"
                  component="h2"
                  className={classNames({ 'cor-text-center': isHeaderCentered })}
                >
                  {title}
                </CorTypography>
              )}
              {!!description && (
                <CorTypography
                  variant={isMobile ? 'body-regular' : 'body-large'}
                  className={classNames({ 'cor-text-center': isHeaderCentered })}
                  color="mid-gray"
                >
                  {description}
                </CorTypography>
              )}
            </div>
          )}

          {!!button?.button?.title && !isHeaderCentered && (
            <CorContentstackButton contentKey={BUTTON_KEY} className="cor-featured-content__button" />
          )}
        </div>
      )}

      <CorFeaturedContentCards />

      {!!button?.button?.title && isHeaderCentered && (
        <CorContentstackButton contentKey={BUTTON_KEY} className="cor-featured-content__button" />
      )}
    </div>
  );
};

export default React.memo(CorFeaturedContent);
