import { CorFormDynamicLogic } from 'corporate-components/cor-form/cor-form.constants';

export const filterEmptyDynamicRules = (rules) => {
  return rules
    ?.map(
      ({ key, value }) =>
        key &&
        value && {
          value: key,
          label: value,
        }
    )
    .filter((value) => value);
};

export const deleteIndexForDuplicates = (field_name, index) => {
  return field_name?.lastIndexOf(index) !== -1
    ? field_name?.slice(0, field_name?.lastIndexOf(index) - field_name?.length)
    : field_name;
};

export const dynamicRulesLogic = {
  [CorFormDynamicLogic.OR]: (rules, formValue) => {
    return rules?.some(
      (rule) => rule?.value?.toString().toLowerCase() === formValue[rule?.key]?.toString()?.toLowerCase()
    );
  },
  [CorFormDynamicLogic.AND]: (rules, formValue) => {
    return rules?.every(
      (rule) => rule?.value?.toString().toLowerCase() === formValue[rule?.key]?.toString()?.toLowerCase()
    );
  },
  [CorFormDynamicLogic.AND_NOT]: (rules, formValue) => {
    return (
      rules?.[0]?.value?.toString().toLowerCase() === formValue[rules?.[0]?.key]?.toString()?.toLowerCase() &&
      rules?.[1]?.value?.toString().toLowerCase() !== formValue[rules?.[1]?.key]?.toString()?.toLowerCase()
    );
  },
};
