import { useMemo } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';

export const useAlgoliaSearch = (
  sourceId: string,
  applicationId: string,
  apiKey: string,
  indexName: string,
  hitsPerPage: number
) => {
  const searchClient = useMemo(() => algoliasearch(applicationId, apiKey), [applicationId, apiKey]);

  return {
    sourceId,
    getItemInputValue({ item }) {
      return item.query;
    },
    getItems({ query }) {
      return getAlgoliaResults({
        searchClient,
        queries: [
          {
            indexName: indexName,
            query,
            params: {
              hitsPerPage,
            },
          },
        ],
      });
    },
  };
};
