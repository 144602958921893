import { combineReducers } from 'redux';
import { IAlgoliaContentTypesEnum, IPageType } from 'queries/use-algolia-query';
import { ILineItem } from 'types/line-item';
import { Actions } from 'constants/actions.enum';
import { IGroupSelector, IPriceModel, ISku } from 'types/product';
import { ITaxonomyItem } from 'types/catalog';
import { createReducer } from 'store/reducer-creator';
import shoppingListsActionTypes from 'store/shopping-lists/action-types';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import * as actions from './actions';
import types from './action-types';

export interface IProductDetails {
  name: string;
  productKey: string;
  skus: ISku[];
  taxonomy: ITaxonomyItem[];
  groupSelectors?: IGroupSelector[];
}

export interface IProductInfo {
  productInfo: IProductDetails;
  loading: boolean;
}

export interface IProductSkuSubtotal {
  loading: boolean;
  subtotal: IPriceModel | null;
}

export interface IProductDetailsState {
  productInfo: IProductInfo;
  productQuantity: number;
  productSkuSubtotal: IProductSkuSubtotal;
  productRecipes: IPageType[];
}

export const productDetailsInitialState: IProductDetailsState = {
  productInfo: {
    productInfo: {
      name: '',
      productKey: '',
      skus: [],
      taxonomy: [],
    },
    loading: false,
  },
  productQuantity: 1,
  productSkuSubtotal: {
    loading: false,
    subtotal: null,
  },
  productRecipes: [],
};

const productInfo = createReducer<IProductInfo>(
  {
    [actions.getProductDetailsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getProductDetailsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      productInfo: payload,
      loading: false,
    }),
    [actions.getProductDetailsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      productInfo: null,
      loading: false,
    }),
    [types.SET_SELECTED_SKU]: (state, { payload: { sku } }) => ({
      ...state,
      productInfo: {
        ...state.productInfo,
        skus: state.productInfo
          ? state.productInfo?.skus.map((item) => ({
              ...item,
              isSelected: item.sku === sku,
            }))
          : [],
      },
    }),
    [shoppingListsActionTypes.UPDATE_PRODUCT_FAVORITES_STATUS]: (state, { payload }) => ({
      ...state,
      productInfo: {
        ...state.productInfo,
        skus: state.productInfo?.skus.map((item) =>
          item.sku === payload.sku ? { ...item, isInFavoritesShoppingList: payload.addedToFavorites } : item
        ),
      },
    }),
    [types.RESET_PRODUCT_DETAILS]: () => productDetailsInitialState.productInfo,
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      productInfo: {
        ...state.productInfo,
        skus: state.productInfo?.skus.map((skuItem) => ({
          ...skuItem,
          isInCart: lineItems.some((cartItem) => cartItem.itemNumber === skuItem.sku),
        })),
      },
    }),
  },
  productDetailsInitialState.productInfo
);

const productQuantity = createReducer<number>(
  {
    [types.SET_PRODUCT_QUANTITY]: (state, { payload }) => payload,
    [types.RESET_PRODUCT_DETAILS]: () => productDetailsInitialState.productQuantity,
  },
  productDetailsInitialState.productQuantity
);

const productSkuSubtotal = createReducer<IProductSkuSubtotal>(
  {
    [actions.getSkuSubtotalPriceConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getSkuSubtotalPriceConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      loading: false,
      subtotal: payload,
    }),
    [actions.getSkuSubtotalPriceConstants[Actions.FAIL]]: () => ({
      loading: false,
      subtotal: null,
    }),
    [types.RESET_PRODUCT_DETAILS]: () => productDetailsInitialState.productSkuSubtotal,
  },
  productDetailsInitialState.productSkuSubtotal
);

const productRecipes = createReducer<IPageType[]>(
  {
    [actions.getProductRecipesActionConstants[Actions.SUCCESS]]: (state, { payload: { globalPageTypeContents } }) => {
      const recipes =
        globalPageTypeContents?.find(
          (item) => item?.categories?.[0]?.categoryName === IAlgoliaContentTypesEnum.RecipeDetailPage
        )?.pageType || [];
      return [...recipes];
    },
    [types.CLEAR_PRODUCT_RECIPES]: () => [],
  },
  productDetailsInitialState.productRecipes
);

export default combineReducers({
  productInfo,
  productQuantity,
  productSkuSubtotal,
  productRecipes,
});
