import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { IUserInformation } from 'types/user-info';
import { updateUserInfo, updateUserInfoActionConstants } from '../actions';

export interface IUpdateUserInfoPayload {
  data: IUserInformation;
  onSuccessCallback: () => void;
  onFailCallback: (status: number) => void;
}

export function* updateUserInfoSagaHandler({ payload }: IAction<IUpdateUserInfoPayload>) {
  yield put(doRequest<IUserInformation>(updateUserInfo, Endpoints.UPDATE_USER_PERSONAL_INFO, 'put', payload.data));
}

export function* updateUserInfoSuccessCallBackSagaHandler({ payload }: IAction<IUpdateUserInfoPayload>) {
  yield take(updateUserInfoActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    yield payload.onSuccessCallback();
  }
}

export function* updateUserInfoFailCallBackSagaHandler({ payload }: IAction<IUpdateUserInfoPayload>) {
  const { error } = yield take(updateUserInfoActionConstants[Actions.FAIL]);
  if (payload.onFailCallback && error) {
    payload.onFailCallback(error.status);
  }
}

export default function* updatePersonalInfoSaga() {
  yield takeLatest(updateUserInfoActionConstants[Actions.REQUEST], updateUserInfoSagaHandler);
  yield takeLatest(updateUserInfoActionConstants[Actions.REQUEST], updateUserInfoSuccessCallBackSagaHandler);
  yield takeLatest(updateUserInfoActionConstants[Actions.REQUEST], updateUserInfoFailCallBackSagaHandler);
}
