import { put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getRequestQuoteSimilarItems, getRequestQuoteSimilarItemsConstants } from 'store/similar-items/actions';
import { Actions } from 'constants/actions.enum';
import { IGetSimilarItemsPayload } from './get-similar-items';

export function* getRequestQuoteSimilarItemsHandler({ payload: { skus } }: IAction<IGetSimilarItemsPayload>) {
  const url = `${Endpoints.CATALOG_SKUS}/${skus}`;
  yield put(doRequest(getRequestQuoteSimilarItems, url, 'get'));
}

export default function* getRequestQuoteSimilarItemsSaga() {
  yield takeLatest(getRequestQuoteSimilarItemsConstants[Actions.REQUEST], getRequestQuoteSimilarItemsHandler);
}
