import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { useContent } from 'hooks/use-content';
import { useSuccessErrorMessageModal } from 'hooks/use-global-modal';
import { useQueryString } from 'hooks/use-query-string';
import { ITransaction } from 'store/payment-history/reducer';
import { selectPaymentHistory } from 'store/payment-history/selectors';
import { cancelScheduledPayment } from 'store/schedule-payment/actions';
import { IGetPaymentHistory } from 'store/payment-history/sagas/get-payment-history';
import { getPaymentHistory } from 'store/payment-history/actions';
import { ICancelScheduledPaymentPayload } from 'store/schedule-payment/sagas/cancel-scheduled-payment';
import { usePaymentFormatters } from 'hooks/use-payment-formatters';
import iconBank from 'assets/images/icons/icon-bank.svg';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';
import { ContentstackHtml, ContentstackMessage, ContentstackText } from 'components/contentstack';
import { PaymentMethods } from 'constants/payment-methods.enum';
import { CreditCardImageUrl } from 'constants/credit-card-type-imge-url';
import { getAccountHolderTypeKey } from 'utils/account-helper';

import './cancel-scheduled-payment-modal.scss';

export interface ICancelScheduledPaymentModalProps extends IModalProps {
  paymentTransaction: ITransaction;
}
export const CancelScheduledPaymentModal: FC<ICancelScheduledPaymentModalProps> = ({
  paymentTransaction,
  onClose,
  isOpen,
}) => {
  const { formatDate } = usePaymentFormatters();
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();

  const contentstackPath = 'tabs[6].payment.content[0].payment_history[0].modals.[0].cancel_scheduled_payment_modal[0]';

  const {
    amount,
    date,
    currency,
    paymentMethod,
    paymentProvider,
    accountNumber,
    accountType,
    ctId,
  } = paymentTransaction;

  const { search } = useLocation();
  let [searchParams] = useQueryString(search);
  const { limit } = useSelector(selectPaymentHistory);
  const { formatLineItemCurrency } = usePaymentFormatters();

  const showCancelScheduledPaymentSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
    modalTitle: getContentByKey(`${contentstackPath}.success_message_title`, ''),
  });

  const showCancelScheduledPaymentFailModal = useSuccessErrorMessageModal({
    type: 'error',
    modalTitle: getContentByKey(`${contentstackPath}.error_message_title`, ''),
    children: <ContentstackMessage messageId={'MSG189'} type="error" />,
    autoClose: false,
    hasCrossButton: true,
  });
  const onFailCancelScheduledPayment = () => {
    showCancelScheduledPaymentFailModal();
  };

  const onSuccessCancelSheduledPayment = () => {
    showCancelScheduledPaymentSuccessModal();
    dispatch(
      getPaymentHistory.request<IGetPaymentHistory>({
        offset: searchParams.offset,
        limit,
      })
    );
  };

  const onProceedClick = () => {
    dispatch(
      cancelScheduledPayment.request<ICancelScheduledPaymentPayload>({
        transactionId: ctId,
        onSuccessCallBack: onSuccessCancelSheduledPayment,
        onFailCallBack: onFailCancelScheduledPayment,
      })
    );
  };

  return (
    <>
      <Modal withBackdropClick isOpen={isOpen} onClose={onClose} className="cancel-scheduled-payment-modal">
        <ModalHeader>
          <ContentstackText contentKey={`${contentstackPath}.header`} />
        </ModalHeader>

        <div className="cancel-scheduled-payment-modal__content-wrapper">
          <div className="cancel-scheduled-payment-modal__subtitle">
            <ContentstackHtml contentKey={`${contentstackPath}.subtitle`} />
          </div>
          <div className="cancel-scheduled-payment-modal__content">
            <div className="cancel-scheduled-payment-modal__date">
              <div className="cancel-scheduled-payment-modal__label">
                <ContentstackText contentKey={`${contentstackPath}.scheduled_date`} />
                <span className="cancel-scheduled-payment-modal__value">{formatDate(date)}</span>
              </div>
            </div>
            <div className="cancel-scheduled-payment-modal__amount">
              <div className="cancel-scheduled-payment-modal__label">
                <ContentstackText contentKey={`${contentstackPath}.amount`} />
                <span className="cancel-scheduled-payment-modal__value">
                  {`${formatLineItemCurrency(currency, amount)} ${currency}`}
                </span>
              </div>
            </div>
            <div className="cancel-scheduled-payment-modal__account-number">
              <div className="cancel-scheduled-payment-modal__label">
                <ContentstackText contentKey={`${contentstackPath}.account_number`} />
                <span className="cancel-scheduled-payment-modal__value">...{accountNumber}</span>
              </div>
            </div>
            <div className="cancel-scheduled-payment-modal__account-holder-type">
              <div className="cancel-scheduled-payment-modal__label">
                <ContentstackText contentKey={`${contentstackPath}.account_holder_type`} />
                <span className="cancel-scheduled-payment-modal__value">
                  <ContentstackText contentKey={getAccountHolderTypeKey(accountType, paymentMethod)} />
                </span>
              </div>

              <div className="cancel-scheduled-payment-modal__type-img">
                {paymentMethod === PaymentMethods.CREDIT_CARD ? (
                  <>
                    {paymentProvider && (
                      <img
                        className="cancel-scheduled-payment-modal__payment-method-icon"
                        src={CreditCardImageUrl[paymentProvider]}
                        alt="card type"
                      />
                    )}
                  </>
                ) : (
                  <>
                    <img
                      className="cancel-scheduled-payment-modal__payment-method-icon"
                      src={iconBank}
                      alt="bank icon"
                    />
                  </>
                )}{' '}
              </div>
            </div>
          </div>
        </div>

        <ModalFooter hasCancelButton contentstackPath={contentstackPath} cancelButtonHandler={onClose}>
          <CorButton
            color="primary"
            className="cancel-scheduled-payment-modal__proceed-button"
            onClick={onProceedClick}
          >
            <ContentstackText contentKey={`${contentstackPath}.proceed_button_label`} />
          </CorButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
