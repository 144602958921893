import 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-jump-links/cor-jump-links.scss';

import React, { FC } from 'react';
import { useContent } from 'hooks/use-content';
import { ContentstackText } from 'components/contentstack';

export const jumpLinkLabelToAnchor = (label: string) => label?.replace(/[^0-9a-zA-Z]/g, '-').toLowerCase() || '';

export const CorJumpLinks: FC = () => {
  const { getContentByKey } = useContent();
  const modularBlocks = getContentByKey('modular_blocks', []);
  const directions: any = modularBlocks.filter((block) => 'directions' in block)[0];
  const jumpLinksButtons = [
    {
      id: 'ingredients',
      include: getContentByKey('include_jumplinks', false),
      label: getContentByKey('ingredients_list_label', ''),
      anchor: `${jumpLinkLabelToAnchor(getContentByKey('ingredients_list_label', ''))}`,
    },
    {
      id: 'directions',
      include: directions?.directions?.directions_2_column_group?.include_jumplink || '',
      label: directions?.directions?.directions_2_column_group?.jumplink_label || '',
      anchor: `${jumpLinkLabelToAnchor(directions?.directions?.directions_2_column_group?.jumplink_label)}`,
    },
    {
      id: 'notes',
      include: getContentByKey('additional_notes_section.include_jumplink', false),
      label: getContentByKey('additional_notes_section.jumplink_label', ''),
      anchor: `${jumpLinkLabelToAnchor(getContentByKey('additional_notes_section.jumplink_label', ''))}`,
    },
  ].filter((link) => link?.include);

  const scrollToAnchor = (currentTarget, anchor) => {
    const $rootPage = currentTarget.closest('.corporate');
    const $element = $rootPage.querySelector(`#${anchor}`);

    if ($element) {
      window.scrollTo({
        top: $element.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  if (!jumpLinksButtons?.length) {
    return null;
  }

  return (
    <div data-testid={'cor-jump-links'} className={'cor-jump-links'}>
      <div className={'cor-jump-links__container'}>
        <div className={'cor-jump-links__text'}>
          <ContentstackText contentKey={'common[0].shared_recipe_detail_page[0].jump_to'} />
        </div>
        {jumpLinksButtons.map((link, idx) => (
          <button
            key={idx.toString()}
            data-testid={'cor-jump-links__link'}
            className={'cor-jump-links__link'}
            onClick={({ currentTarget }) => scrollToAnchor(currentTarget, link.anchor)}
          >
            {link.label}
          </button>
        ))}
      </div>
    </div>
  );
};
