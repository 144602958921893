import { ProductContext } from 'corporate-components/cor-product-list/context/cor-products-context';
import { useContext } from 'react';

export const useProductContext = () => {
  const { products, toggleSelected, unSelectProducts } = useContext(ProductContext);

  return {
    products,
    toggleSelected,
    unSelectProducts,
  };
};
