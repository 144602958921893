import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import {
  useHideGlobalModalHandler,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';
import { Routes } from 'constants/routes.enum';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackText, ContentstackMessage } from 'components/contentstack';
import { CorButton } from 'components/cor-button';
import {
  ISpecialOrderSku,
  IItemizedSkuInfo,
  IExceededMaximumQuantitySkuInfo,
  IOrderInfo,
  IOutOfStockSkuReorderInfo,
} from 'types/order-details';
import { stripLeadingZeros } from 'utils/string-format';
import { LoadingIndicator } from 'components/loading-indicator';
import { DiscontinuedWithReplacementSlider } from 'features/discontinued-items-with-replacements';
import { selectIsHouseAccountUser, selectIsKeyAccountUser } from 'store/auth/selectors';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { selectDiscontinuedItemsWithReplacementsLoading } from 'store/items-replacements/selectors';
import { clearDiscontinuedItemsReplacements } from 'store/items-replacements/actions';
import { clearOriginalToReplacementSkuMap } from 'store/shopping-cart/actions';
import { useGetOriginalToReplacementsMap } from 'hooks/use-get-original-to-replacement-map';
import iconAdd from 'assets/images/icons/icon-add.svg';
import iconDelete from 'assets/images/icons/icon-delete.svg';

import './reorder-success-modal.scss';

export interface IReorderSuccessModalProps extends IModalProps {
  orderInfo: IOrderInfo;
  onRequestQuoteCtaClick: (sku: string) => void;
  openPreviousModal?: () => void;
}

export const ReorderSuccessModal: FC<IReorderSuccessModalProps> = ({
  isOpen = false,
  onClose = () => {},
  orderInfo: {
    addedItemsCounter,
    specialOrderSkus,
    deactivatedSkus,
    originalToReplacementSkusMap,
    unavailableProprietarySkus,
    exceededMaxQuantitySkus,
    shortSupplyItems,
    outOfStockSkus,
  },
  onRequestQuoteCtaClick,
}) => {
  const contentstackPath = 'modals.0.reorder_success_modal.0';
  const history = useHistory();
  const dispatch = useDispatch();
  const hasDeactivatedOrNotAvailableProprietarySkuIds =
    !isEmpty(deactivatedSkus) || !isEmpty(unavailableProprietarySkus);
  const hasAddedItems = addedItemsCounter !== 0;

  const originalToReplacementMap = useGetOriginalToReplacementsMap(isOpen, originalToReplacementSkusMap);

  const discontinuedItemsWithReplacementsLoading = useSelector(selectDiscontinuedItemsWithReplacementsLoading);

  const isHouseAccountUser = useSelector(selectIsHouseAccountUser);
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);

  const [activeSlide, setActiveSlide] = useState(0);

  const createRequestQuoteClickHandler = (skuKey: string) => () => showRequestQuoteModal({ skuKey });

  const onCloseModal = () => {
    setActiveSlide(0);
    dispatch(clearDiscontinuedItemsReplacements());
    dispatch(clearOriginalToReplacementSkuMap());
    onClose();
  };

  const renderSpecialOrderSku = ({ sku, skuName }: ISpecialOrderSku) => (
    <li className="reorder-success-modal__special-order-sku" key={sku}>
      <div>
        <strong>{skuName}</strong>
        <p>
          <ContentstackMessage type="notifications" messageId="MSG051b" /> {stripLeadingZeros(sku)}
        </p>
      </div>
      {!isHouseAccountUser && !isKeyAccountUser && (
        <CorButton color="text" onClick={() => onRequestQuoteCtaClick(sku)}>
          <ContentstackText contentKey={`${contentstackPath}.special_order_item.request_quote_button_label`} />
        </CorButton>
      )}
    </li>
  );

  const renderExceededMaximumQuantitySku = ({ sku, skuName, maximumQuantity }: IExceededMaximumQuantitySkuInfo) => (
    <li className="reorder-success-modal__exceeded-max-quantity-sku reorder-success-modal__section-message" key={sku}>
      <strong>{skuName}</strong>{' '}
      <ContentstackMessage
        type="notifications"
        messageId="MSG095"
        interpolateParams={{ sku, skuName, maximumQuantity }}
      />
      <p>
        <ContentstackMessage type="notifications" messageId="MSG051b" /> {stripLeadingZeros(sku)}
      </p>
    </li>
  );

  const renderShortSupplyItems = ({ sku, skuName }: IExceededMaximumQuantitySkuInfo) => (
    <li className="reorder-success-modal__short-supply-sku reorder-success-modal__section-message" key={sku}>
      <strong>{skuName}</strong> <ContentstackMessage type="error" messageId="MSG096" interpolateParams={{ skuName }} />
      <p>
        <ContentstackMessage type="notifications" messageId="MSG051b" /> {stripLeadingZeros(sku)}
      </p>
    </li>
  );

  const renderDeactivatedSku = ({ sku, skuName }: IItemizedSkuInfo) => (
    <li className="reorder-success-modal__deactivated-item-sku" key={sku}>
      {skuName && <strong>{skuName}</strong>}
      <p>
        <ContentstackMessage type="notifications" messageId="MSG051b" /> {stripLeadingZeros(sku)}
      </p>
    </li>
  );

  const renderNotAvailableProprietarySku = ({ sku, skuName }: IItemizedSkuInfo) => (
    <li className="reorder-success-modal__deactivated-item-sku" key={sku}>
      {skuName && <strong>{skuName}</strong>}
      <p>
        <ContentstackMessage type="notifications" messageId="MSG051b" /> {stripLeadingZeros(sku)}
      </p>
    </li>
  );

  const renderOutOfStockSku = ({ sku, skuName, productKey, similarItems }: IOutOfStockSkuReorderInfo) => (
    <li className="reorder-success-modal__out-of-stock-sku" key={sku}>
      <div>
        <strong>{skuName}</strong>
        <p>
          <ContentstackMessage type="notifications" messageId="MSG051b" /> {stripLeadingZeros(sku)}
        </p>
      </div>
      {!isEmpty(similarItems) && (
        <Link
          to={
            similarItems.length > 1
              ? `${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku}`
              : `${Routes.ProductDetailsPage}/${similarItems[0].productKey}?selectedSku=${similarItems[0].sku}`
          }
          target="_blank"
          className="cor-button text"
        >
          <ContentstackText contentKey={`${contentstackPath}.out_of_stock_item.select_similar_button_label`} />
        </Link>
      )}
    </li>
  );

  const hideGlobalModal = useHideGlobalModalHandler();
  const showRequestQuoteSuccessModal = useRequestQuoteSuccessMessageModal();

  const showRequestQuoteModal = useRequestQuoteModal({
    requestQuoteSuccessCallBack: () => showRequestQuoteSuccessModal(),
    requestQuoteFailCallBack: hideGlobalModal,
  });

  return (
    <Modal
      isOpen={isOpen}
      className="reorder-success-modal"
      onClose={onCloseModal}
      hideCloseButton={!hasAddedItems && isEmpty(originalToReplacementMap)}
      withBackdropClick
    >
      <>
        <ModalHeader>
          <div className="reorder-success-modal__title">
            {hasAddedItems ? (
              <>
                <img src={iconAdd} alt="icon add" />
                <ContentstackMessage
                  type="success"
                  messageId="MSG078"
                  interpolateParams={{ itemsQuantity: addedItemsCounter }}
                />
              </>
            ) : (
              <>
                <img src={iconDelete} alt="icon delete" />
                <ContentstackMessage type="error" messageId="MSG097" />
              </>
            )}
          </div>
        </ModalHeader>
        <div className="reorder-success-modal__content">
          {!isEmpty(originalToReplacementSkusMap) && originalToReplacementMap && !isEmpty(originalToReplacementMap) && (
            <div className="reorder-success-modal__section reorder-success-modal__section--discontinued reorder-success-modal__section--bordered">
              {discontinuedItemsWithReplacementsLoading ? (
                <LoadingIndicator />
              ) : (
                <>
                  <p className="reorder-success-modal__section-message">
                    <ContentstackMessage type="error" messageId="MSG024" />
                  </p>
                  <DiscontinuedWithReplacementSlider
                    originalToReplacementMap={originalToReplacementMap}
                    createRequestQuoteClickHandler={createRequestQuoteClickHandler}
                    activeSlide={activeSlide}
                    onSlideChange={setActiveSlide}
                  />
                </>
              )}
            </div>
          )}
          {!isEmpty(outOfStockSkus) && (
            <div className="reorder-success-modal__section reorder-success-modal__section--bordered">
              <p className="reorder-success-modal__section-message">
                <ContentstackMessage type="error" messageId="MSG169" />
              </p>
              <ul>{outOfStockSkus.map(renderOutOfStockSku)}</ul>
            </div>
          )}
          {!isEmpty(specialOrderSkus) && (
            <div className="reorder-success-modal__section reorder-success-modal__section--bordered">
              <p className="reorder-success-modal__section-message">
                <ContentstackMessage type="error" messageId={isKeyAccountUser ? 'MSG163' : 'MSG047'} />
              </p>
              <ul>{specialOrderSkus.map(renderSpecialOrderSku)}</ul>
            </div>
          )}
          {!isEmpty(exceededMaxQuantitySkus) && (
            <div className="reorder-success-modal__section reorder-success-modal__section--bordered">
              <ul>{exceededMaxQuantitySkus.map(renderExceededMaximumQuantitySku)}</ul>
            </div>
          )}
          {!isEmpty(shortSupplyItems) && (
            <div className="reorder-success-modal__section reorder-success-modal__section--bordered">
              <ul>{shortSupplyItems.map(renderShortSupplyItems)}</ul>
            </div>
          )}
          {!discontinuedItemsWithReplacementsLoading && hasDeactivatedOrNotAvailableProprietarySkuIds && (
            <div className="reorder-success-modal__section reorder-success-modal__section--bordered">
              <p className="reorder-success-modal__section-message">
                <ContentstackMessage type="error" messageId="MSG025" />
              </p>
              <ul>
                {unavailableProprietarySkus.map(renderNotAvailableProprietarySku)}
                {deactivatedSkus.map(renderDeactivatedSku)}
              </ul>
            </div>
          )}
        </div>
        <ModalFooter>
          <div className="reorder-success-modal__footer">
            {hasAddedItems ? (
              <>
                <CorButton color="secondary" onClick={onClose}>
                  <ContentstackText contentKey={`${contentstackPath}.continue_shopping_button_label`} />
                </CorButton>
                <CorButton onClick={() => history.push(Routes.ShoppingCartPage)}>
                  <ContentstackText contentKey={`${contentstackPath}.view_cart_button_label`} />
                </CorButton>
              </>
            ) : (
              <CorButton className="reorder-success-modal__close-btn" color="secondary" onClick={onClose}>
                <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
              </CorButton>
            )}
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};
