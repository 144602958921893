import { takeLatest, call, cancelled } from 'redux-saga/effects';
import axios from 'axios';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { apiSagaHandler } from 'store/api/sagas';
import { getOrderHistoryActionConstants, getOrderHistory } from 'store/account/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IOrderHistoryPayload {
  limit: number;
  offset: number;
  sortingDirection: string;
  searchText?: string;
}

export function* getOrderHistoryHandler({
  payload: { limit, offset, sortingDirection, searchText },
}: IAction<IOrderHistoryPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);
  const url = `${Endpoints.ORDER_HISTORY}/?limit=${limit}&offset=${offset}&sortDirection=${sortingDirection}&sortFieldName=DATE_CREATED/&searchText=${searchQuery}`;
  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  try {
    yield call(apiSagaHandler, doRequest(getOrderHistory, url, 'get', undefined, undefined, cancelToken));
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
}

export default function* getOrderHistorySaga() {
  yield takeLatest(getOrderHistoryActionConstants[Actions.REQUEST], getOrderHistoryHandler);
}
