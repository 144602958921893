import React from 'react';
import { ReactComponent as IconDownload } from 'assets/images/icons/icon-download-red.svg';
import { ReactComponent as IconEmail } from 'assets/images/icons/icon-email-red.svg';
import { ReactComponent as IconPrint } from 'assets/images/icons/icon-print-red.svg';
import { ReactComponent as IconCaratRight } from 'assets/images/icons/icon-carat-right.svg';

export const enum CorButtonLinkStylesEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  FooterIcon = 'footer-icon',
  Email = 'email',
  Download = 'download',
  Print = 'print',
  SecondaryCaret = 'secondary-caret',
}

export interface ICorButtonLink {
  icon?: React.ReactNode;
  className?: string;
  modifier?: string;
}

const Primary = {
  icon: null,
  className: 'primary',
};

const Secondary = {
  icon: null,
  className: 'secondary',
};

const FooterIcon = {
  icon: null,
  className: 'clean',
  modifier: 'no-fill is-fill no-stroke',
};

const Email = {
  icon: <IconEmail />,
  className: 'secondary',
  modifier: 'no-fill is-fill no-stroke',
};

const Download = {
  icon: <IconDownload />,
  className: 'secondary',
  modifier: 'no-fill is-fill no-stroke',
};

const Print = {
  icon: <IconPrint />,
  className: 'secondary',
  modifier: 'no-fill is-fill no-stroke',
};

const SecondaryCaret = {
  icon: <IconCaratRight />,
  className: 'secondary',
  modifier: 'no-fill is-stroke no-stroke',
};

export const CorButtonLinkStyles = new Map<CorButtonLinkStylesEnum, ICorButtonLink>([
  [CorButtonLinkStylesEnum.Primary, Primary],
  [CorButtonLinkStylesEnum.Secondary, Secondary],
  [CorButtonLinkStylesEnum.FooterIcon, FooterIcon],
  [CorButtonLinkStylesEnum.Email, Email],
  [CorButtonLinkStylesEnum.Download, Download],
  [CorButtonLinkStylesEnum.Print, Print],
  [CorButtonLinkStylesEnum.SecondaryCaret, SecondaryCaret],
]);
