import './cor-form.scss';

import React, { createRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { isEmpty } from 'lodash-es';
import {
  CorFormReset,
  CorFormSubmissionModal,
  CorSubmitButton,
  useCorFormSubmissionModal,
} from 'corporate-components/cor-forms';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { CorTypography } from 'corporate-ui';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useContent } from 'hooks/use-content';
import { getSiteKey } from 'store/captcha/actions';
import { selectSiteKey } from 'store/captcha/selectors';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useLocalStorage } from 'hooks/use-local-storage';
import {
  CorFormControlEnum,
  CorFormControlsSupportedSet,
  CorFormUrlTarget,
  IFormHiddenFiled,
  duplicateIndex,
} from './cor-form.constants';
import useCorForm from './useCorForm';
import { IFormMailTo } from './cor-form.constants';
import { FormObserver } from './cor-dynamic-form-observer';
import { CorFormContentStack } from './cor-form.constants';
import { CorFormFields } from './cor-form-field';
import { deleteIndexForDuplicates } from './cor-form-helper';

const CorForm = ({ onParentModalClose = () => {}, onFormSubmitted = () => {} }) => {
  const { getContentByKey } = useContent();
  const [locale] = useLocalStorage('locale', null);

  const dispatch = useDispatch();
  const { isMobile } = useBreakpoint();
  const { siteKey, loading, failed } = useSelector(selectSiteKey);
  const [isValidCaptcha, setIsValidCaptcha] = React.useState(false);

  const backgroundColor = getContentByKey(CorFormContentStack.BACKGROUND_COLOR_KEY, '')
    ?.toLowerCase()
    ?.replace(new RegExp(' ', 'g'), '-');
  const title = getContentByKey(CorFormContentStack.TITLE_KEY, '');
  const description = getContentByKey(CorFormContentStack.DESCRIPTION_KEY, '');
  const formControls: any[] = getContentByKey(
    CorFormContentStack.FORM_CONTROLS_KEY,
    []
  )?.filter(({ form_field }: any) => CorFormControlsSupportedSet.has(form_field?.field_type));
  const hiddenField = getContentByKey<IFormHiddenFiled>(CorFormContentStack.HIDDEN_FIELD, {})?.value?.reduce(
    (object, { key, value }) => {
      return { ...object, ...(key && value && { [key]: value }) };
    },
    {}
  );

  const isDynamicForm = formControls.some(
    ({ dynamic_rule }) => !!dynamic_rule?.value?.[0].key && !!dynamic_rule?.value?.[0].value
  );

  const [dynamicFormControls, setDynamicFormControls] = useState(formControls);

  const formURL = getContentByKey(CorFormContentStack.FORM_URL_KEY, '');
  const formID = getContentByKey(CorFormContentStack.FORM_ID, '');
  const formMailTo: [] = getContentByKey(CorFormContentStack.EMAIL_TO, '') || [];
  const enableCaptcha = getContentByKey(CorFormContentStack.ENABLE_CAPTCHA, false);
  const mailsToData = formMailTo.map((mail: IFormMailTo) => mail?.email.email_to);
  const isMailTarget = (formURL as CorFormUrlTarget) === CorFormUrlTarget.MAIL;
  const isEuropeTarget = (formURL as CorFormUrlTarget) === CorFormUrlTarget.SALESFORCE_EU;

  const getFormParams = React.useCallback(() => {
    return {
      form_id: formID,
      ...(isEuropeTarget && { locale: locale }),
      ...(isMailTarget && !isEmpty(mailsToData) && { mail_to: mailsToData }),
    };
  }, [formID, isMailTarget, mailsToData, locale, isEuropeTarget]);

  const getFormMailControls = React.useCallback(
    (data) => {
      const emailControls = {};

      dynamicFormControls.forEach(({ form_field }) => {
        emailControls[form_field?.field_label || form_field?.field_name] = data[form_field?.field_name];
      });

      return emailControls;
    },
    [dynamicFormControls]
  );

  const getFormDynamicControls = React.useCallback(
    (data) => {
      const dynamicControls = {};

      dynamicFormControls.forEach(({ form_field, dynamic_field }) => {
        if (!dynamic_field) {
          const fieldName = deleteIndexForDuplicates(form_field?.field_name, duplicateIndex);
          dynamicControls[fieldName] = data[form_field?.field_name];
        }
      });

      return dynamicControls;
    },
    [dynamicFormControls]
  );

  const isLATAMDropdownList = dynamicFormControls.some(
    ({ form_field }) => form_field?.field_type === CorFormControlEnum.LATAM_Dropdown
  );

  const replaceLATAMDropdownListId = React.useCallback(
    (formData) => {
      dynamicFormControls.forEach(({ form_field }) => {
        if (form_field?.field_type === CorFormControlEnum.LATAM_Dropdown) {
          const value = formData[form_field?.field_name];
          formData[value] = 1;
          delete formData[form_field?.field_name];
        }
      });

      return formData;
    },
    [dynamicFormControls]
  );

  const recaptchaRef = createRef<ReCAPTCHA>();

  const modal = useCorFormSubmissionModal();

  const onCloseModalWrapped = React.useCallback(() => {
    modal.handleCloseModal();
    onParentModalClose();
  }, [modal, onParentModalClose]);

  const onOpenModalWrapped = React.useCallback(() => {
    modal.handleOpenModal();
    onFormSubmitted();
  }, [modal, onFormSubmitted]);

  const handleResetReCaptcha = React.useCallback(() => {
    if (enableCaptcha) {
      recaptchaRef?.current?.reset();
      setIsValidCaptcha(false);
    }
  }, [enableCaptcha, recaptchaRef, setIsValidCaptcha]);

  const handleChangeReCaptcha = React.useCallback(
    (token: string | null) => {
      if (token) {
        setIsValidCaptcha(true);
      }
    },
    [setIsValidCaptcha]
  );

  const { handleSubmit, VALIDATION_SCHEMA, INITIAL_VALUES, formState } = useCorForm({
    formControls: dynamicFormControls,
    errorMessageKey: CorFormContentStack.ERROR_MESSAGE_KEY,
    formURL,
    openModal: onOpenModalWrapped,
  });

  const onSubmit = React.useCallback(
    (formData, { resetForm }) => {
      const requestConfig = {};
      const formParams = getFormParams();

      if (enableCaptcha) {
        // @ts-ignore
        const captchaToken = recaptchaRef?.current?.getValue();
        requestConfig['headers'] = {
          'g-recaptcha-response': captchaToken,
        };
        handleResetReCaptcha();
      }

      if (isDynamicForm) {
        formData = getFormDynamicControls(formData);
      }

      if (isMailTarget) {
        formData = getFormMailControls(formData);
      }

      if (hiddenField && !isEmpty(hiddenField)) {
        formData = { ...formData, ...hiddenField };
      }

      if (isLATAMDropdownList) {
        formData = replaceLATAMDropdownListId(formData);
      }

      resetForm({ values: '' });
      return handleSubmit({ ...formData, config_params: { ...formParams } }, requestConfig);
    },
    [
      enableCaptcha,
      recaptchaRef,
      isMailTarget,
      isDynamicForm,
      hiddenField,
      isLATAMDropdownList,
      replaceLATAMDropdownListId,
      handleSubmit,
      handleResetReCaptcha,
      getFormParams,
      getFormMailControls,
      getFormDynamicControls,
    ]
  );

  useEffect(() => {
    if (enableCaptcha && !loading && !failed && !siteKey) {
      dispatch(getSiteKey.request());
    }
  }, [dispatch, enableCaptcha, loading, siteKey, failed]);

  return (
    <>
      <CorFormSubmissionModal
        isOpen={modal.isModalOpened}
        isSuccess={formState.isSuccess}
        onClose={onCloseModalWrapped}
      />
      <section className={classNames('cor-form', { [`cor-bg-color__${backgroundColor}`]: !!backgroundColor })}>
        <div className="cor-form__container grid-container">
          {!!title && (
            <CorTypography variant="h2" className="cor-form__title" data-testid="cor-form__title">
              {title}
            </CorTypography>
          )}
          {!!description && (
            <CorContentstackHtml
              contentKey={CorFormContentStack.DESCRIPTION_KEY}
              className="cor-rich-text cor-form__description"
            />
          )}

          {!!dynamicFormControls?.length && (
            <Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit} validationSchema={VALIDATION_SCHEMA}>
              {(formikProps) => {
                return (
                  <>
                    <Form className="cor-form__form">
                      {isDynamicForm && (
                        <FormObserver
                          dynamicFormControls={dynamicFormControls}
                          setDynamicFormControls={setDynamicFormControls}
                          VALIDATION_SCHEMA={VALIDATION_SCHEMA}
                        />
                      )}
                      <CorFormFields fields={dynamicFormControls} values={formikProps?.values} />
                      {enableCaptcha && siteKey && !loading && (
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={siteKey}
                          onChange={handleChangeReCaptcha}
                          onExpired={handleResetReCaptcha}
                          size={isMobile ? 'compact' : 'normal'}
                        />
                      )}
                      <CorSubmitButton
                        submitButtonKey={CorFormContentStack.SUBMIT_BUTTON_KEY}
                        isDisabled={formState.isLoading || !formikProps.isValid || (enableCaptcha && !isValidCaptcha)}
                      />
                    </Form>
                    <CorFormReset isSuccess={formState.isSuccess} onReset={formikProps.resetForm} />
                  </>
                );
              }}
            </Formik>
          )}
        </div>
      </section>
    </>
  );
};

export default CorForm;
