import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { ShoppingListTypes } from 'constants/shopping-list-types.enum';
import { IShoppingListsState } from './reducer';

export const selectShoppingListsEntity = (state: IState): IShoppingListsState => state.app.shoppingLists;

export const selectIsProductBeingUpdated = createSelector(
  selectShoppingListsEntity,
  (shoppingListsEntity) => shoppingListsEntity.isProductBeingUpdated
);

export const selectHasShoppingListNameDuplicateError = createSelector(
  selectShoppingListsEntity,
  (shoppingListsEntity) => shoppingListsEntity.hasShoppingListNameDuplicateError
);

export const selectIsShoppingListBeingCreated = createSelector(
  selectShoppingListsEntity,
  (shoppingListsEntity) => shoppingListsEntity.isShoppingListBeingCreated
);

export const selectItemsBeingAddedToSL = createSelector(
  selectShoppingListsEntity,
  (shoppingListsEntity) => shoppingListsEntity.itemsBeingAddedToSL
);

export const selectShoppingListDetailsInfo = createSelector(
  selectShoppingListsEntity,
  (shoppingListsEntity) => shoppingListsEntity.shoppingListDetailsInfo
);
export const selectShoppingListDetailsInfoLoading = createSelector(
  selectShoppingListDetailsInfo,
  ({ loading }) => loading
);
export const selectShoppingListDetails = createSelector(
  selectShoppingListDetailsInfo,
  ({ shoppingListDetails }) => shoppingListDetails
);
export const selectShoppingLists = createSelector(
  selectShoppingListsEntity,
  (shoppingListsEntity) => shoppingListsEntity.shoppingListsInfo
);
export const selectShoppingListsLoading = createSelector(selectShoppingLists, ({ loading }) => loading);

export const selectShoppingListsPaginationCurrent = createSelector(
  selectShoppingLists,
  ({ offset, limit }) => offset / limit
);
export const selectShoppingListsPaginationTotal = createSelector(selectShoppingLists, ({ total, limit }) =>
  Math.ceil(total / limit)
);

export const selectShoppingListsRange = createSelector(selectShoppingLists, ({ offset, total, limit }) => {
  return [offset + 1, Math.min(total, offset + limit)];
});

export const selectIsFavoritesShoppingList = createSelector(
  selectShoppingListDetailsInfo,
  (shoppingListDetailsInfo) => shoppingListDetailsInfo.shoppingListDetails.type === ShoppingListTypes.FAVORITES
);

export const selectAddItemsNotificationsInfo = createSelector(
  selectShoppingListsEntity,
  (shoppingListsEntity) => shoppingListsEntity.addedItemsNotificationInfo
);

export const selectAllShoppingLists = createSelector(selectShoppingLists, ({ shoppingLists }) => shoppingLists);

export const selectAllCustomShoppingLists = createSelector(selectShoppingLists, ({ shoppingLists }) =>
  shoppingLists.filter(({ type }) => type !== ShoppingListTypes.FAVORITES)
);
