import { createReducer } from 'store/reducer-creator';
import { Actions } from 'constants/actions.enum';
import * as actions from './actions';
import types from './action-types';

export interface IPaymentFieldTokenState {
  pfToken: string;
}

export const paymentFieldTokenInitialState: IPaymentFieldTokenState = {
  pfToken: '',
};

export const paymentFieldTokenReducer = createReducer<IPaymentFieldTokenState>(
  {
    [actions.getPaymentFieldTokenActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
    }),

    [actions.getPaymentFieldTokenActionConstants[Actions.SUCCESS]]: (state, { payload: { pfToken } }) => ({
      pfToken: pfToken,
    }),
    [actions.getPaymentFieldTokenActionConstants[Actions.FAIL]]: (state, { payload }) => ({
      pfToken: null,
    }),
    [types.CLEAR_PAYMENT_FIELD_TOKEN]: () => ({ pfToken: null }),
    [types.GET_PAYMENT_FIELD_TOKEN]: (state, { payload }) => payload,
  },
  paymentFieldTokenInitialState
);
