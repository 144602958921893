import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/account/actions';
import { Actions } from 'constants/actions.enum';
import types from 'store/account/action-types';
import { IPersonalInfoState } from 'store/account/reducer';
import { IAccountLocation } from 'types/locations';
import { IAccountInformation, ICreditInformation } from 'types/user-info';
import { ORDER_VIEWS } from 'constants/order-view.enum';
import { ACCOUNT_PAYMENTS } from 'constants/account-payments.enum';

export interface IAccountProfileInfoState {
  accountLocations: IAccountLocation[];
  accountInformation: IAccountInformation;
  personalInformation: IPersonalInfoState;
  activeOrderView: typeof ORDER_VIEWS.ORDER | typeof ORDER_VIEWS.PRODUCT;
  activePaymentView:
    | typeof ACCOUNT_PAYMENTS.INVOICES
    | typeof ACCOUNT_PAYMENTS.PAYMENT_HISTORY
    | typeof ACCOUNT_PAYMENTS.PAYMENT_METHODS;
  creditInformation?: ICreditInformation;
  amountOfUsersInLocations: number;
  totalUsersInAccount: number;
  loading: boolean;
  activeEditUserId: string | null;
}

export const accountProfileInfoInitialState = {
  accountLocations: [],
  accountInformation: {
    accountName: '',
    accountNumber: '',
    accountStatus: '',
    paymentTerms: '',
  },
  personalInformation: {
    changePasswordFormServerError: '',
    changePasswordFormIsSubmitting: false,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  loading: false,
  activeOrderView: ORDER_VIEWS.ORDER,
  activePaymentView: ACCOUNT_PAYMENTS.INVOICES,
  amountOfUsersInLocations: 0,
  totalUsersInAccount: 0,
  activeEditUserId: null,
};

export const accountProfileInfo = createReducer<IAccountProfileInfoState>(
  {
    [actions.getAccountInformationActionConstants[Actions.REQUEST]]: (state, { payload: { withLoading } }) => ({
      ...state,
      loading: withLoading,
    }),
    [actions.getOrderHistoryActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getOrderHistoryActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getOrderHistoryActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getOrderHistoryProductsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getOrderHistoryProductsActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getOrderHistoryProductsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getAccountInformationActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      loading: false,
      amountOfUsersInLocations: payload.amountOfUsersInLocations,
      totalUsersInAccount: payload.totalUsersInAccount,
      accountLocations: payload.accountLocations,
      accountInformation: payload.accountInformation,
      personalInformation: { ...state.personalInformation, ...payload.personalInformation },
      creditInformation: payload.creditInformation,
      activeOrderView: state.activeOrderView,
    }),
    [actions.getAccountInformationActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.updatePersonalInfoActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.updatePersonalInfoActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      loading: false,
      personalInformation: { ...payload },
    }),
    [actions.updatePersonalInfoActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.setAccountPasswordActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      personalInformation: {
        ...state.personalInformation,
        changePasswordFormIsSubmitting: true,
      },
    }),
    [actions.setAccountPasswordActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      personalInformation: {
        ...state.personalInformation,
        changePasswordFormIsSubmitting: false,
      },
    }),
    [actions.setAccountPasswordActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      personalInformation: {
        ...state.personalInformation,
        changePasswordFormServerError: error.errorMessages[0],
        changePasswordFormIsSubmitting: false,
      },
    }),
    [types.SET_ACTIVE_ORDER_VIEW]: (state, { payload: { activeOrderView } }) => ({
      ...state,
      activeOrderView: activeOrderView,
    }),
    [types.SET_ACTIVE_PAYMENT_VIEW]: (state, { payload: { activePaymentView } }) => ({
      ...state,
      activePaymentView: activePaymentView,
    }),
    [types.CLEAR_CHANGE_PASSWORD_FORM_SERVER_ERROR]: (state) => ({
      ...state,
      personalInformation: {
        ...state.personalInformation,
        changePasswordFormServerError: '',
      },
    }),
    [types.SET_ACTIVE_EDIT_USER_ID]: (state, { payload: { activeEditUserId } }) => ({
      ...state,
      activeEditUserId: activeEditUserId,
    }),
  },
  accountProfileInfoInitialState
);
