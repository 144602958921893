import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { removeBankAccount, removeBankAccountActionConstants } from 'store/payment-methods/actions';

export interface IRemoveBankAccountPayload {
  accountType: string;
  publicAccountNumber: string;
  publicRoutingNumber: string;
  onSuccessCallBack: () => void;
  onFailCallBack: (errorId: string, messageId: string) => void;
}

export function* removeBankAccountOnSuccessSagaHandler({ payload }: IAction<IRemoveBankAccountPayload>) {
  yield take(removeBankAccountActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* removeBankAccountOnFailSagaHandler({ payload }: IAction<IRemoveBankAccountPayload>) {
  const returnedError = yield take(removeBankAccountActionConstants[Actions.FAIL]);
  const {
    error: { errorId, errorMessageId },
  } = returnedError;
  if (payload.onFailCallBack && returnedError) {
    payload.onFailCallBack(errorId, errorMessageId);
  }
}

export function* removeBankAccountSagaHandler({ payload }: IAction<IRemoveBankAccountPayload>) {
  yield put(doRequest<IRemoveBankAccountPayload>(removeBankAccount, Endpoints.BANK_ACCOUNTS, 'delete', payload));
}

export default function* removeBankAccountSaga() {
  yield takeLatest(removeBankAccountActionConstants[Actions.REQUEST], removeBankAccountOnSuccessSagaHandler);
  yield takeLatest(removeBankAccountActionConstants[Actions.REQUEST], removeBankAccountOnFailSagaHandler);
  yield takeLatest(removeBankAccountActionConstants[Actions.REQUEST], removeBankAccountSagaHandler);
}
