import React from 'react';
import api from 'api';
import { stringify } from 'qs';
import { CorCardsMobile, CorSmallCardTitleCategory } from 'corporate-components/cor-cards';
import {
  mapFeaturedContentEntries,
  mapFeaturedContentUidEntries,
} from 'corporate-components/cor-featured-content/cor-featured-content.mapper';
import {
  CorCards,
  CorCardsCarousel,
  CorCardsCarouselFullWidth,
  CorCardsWithTitleMobile,
} from 'corporate-components/cor-featured-content/components';
import { ICorFeaturedContentEntry } from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContent } from 'hooks/use-content';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';
import {
  CorFeaturedContentCardStyleEnum,
  CorFeaturedContentCardStyleMap,
  CorFeaturedContentContentTypeEnum,
  CorFeaturedContentContentTypeMap,
  CorFeaturedContentSmallCardsSet,
} from 'constants/cor-featured-content.enum';
import { Endpoints } from 'constants/endpoints.enum';

const REFERENCES_KEY = 'featured_content.featured_content.references';
const CARD_DISPLAY_NUMBER_KEY = 'featured_content.featured_content.card_display_number';
const CARD_STYLE_KEY = 'featured_content.featured_content.card_style';
const CAROUSEL_KEY = 'featured_content.featured_content.carousel';
const CONTENT_TYPE_KEY = 'featured_content.featured_content.content_type';
const TITLE_EXCLUSION_KEY = 'featured_content.title_exclusion';

interface IContentStackSearchQuery {
  params?: Record<string, any>;
  enabled?: boolean;
}

const useContentstackSearchQuery = ({ params = {}, enabled = true }: IContentStackSearchQuery) => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!enabled) return;

    api
      .get(Endpoints.CONTENTSTACK_QUERY, {
        params,
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'indices' }),
      })
      .then(({ data: response }) => setData(response?.entries));
  }, [enabled, params]);

  return data;
};

const useFeaturedContentEntries = () => {
  const { getContentByKey } = useContent();

  const titleExclusion = getContentByKey(TITLE_EXCLUSION_KEY, '');
  const contentType = getContentByKey(CONTENT_TYPE_KEY, '');
  const [locale] = useLocalStorage('locale', null);
  const contentTypeName = CorFeaturedContentContentTypeMap.get(contentType as CorFeaturedContentContentTypeEnum) || '';
  const params = React.useMemo(
    () => ({
      limit: '15',
      contentTypeName,
      sort: 'desc=publication_date',
      include: ['modular_blocks.hero.hero'],
      ...(isInternationalSite(locale) && { locale: locale }),
      ...(!![CorFeaturedContentContentTypeEnum.CorporateProducts].includes(
        contentType as CorFeaturedContentContentTypeEnum
      ) && {
        inquiry: JSON.stringify({ tags: { $in: ['corporate-product'] } }),
      }),
    }),
    [contentType, contentTypeName, locale]
  );

  const references = getContentByKey(REFERENCES_KEY, []);
  const pulledEntries = useContentstackSearchQuery({
    enabled: !references?.length,
    params,
  });

  const entries = React.useMemo(() => {
    if (!!references?.length) return mapFeaturedContentUidEntries(references as any[]);
    return mapFeaturedContentEntries({ entries: pulledEntries, contentType });
  }, [contentType, pulledEntries, references]);

  if (!titleExclusion) return entries;
  return entries?.filter((entry) => ![entry.title].includes(titleExclusion));
};

const useFeaturedContentPresentation = (entries: ICorFeaturedContentEntry[]) => {
  const { getContentByKey } = useContent();
  const { isMobile } = useBreakpoint();

  const cardDisplayNumber: number = getContentByKey(CARD_DISPLAY_NUMBER_KEY, 3);
  const carousel = getContentByKey(CAROUSEL_KEY, false);
  const cardStyle: CorFeaturedContentCardStyleEnum = getContentByKey(
    CARD_STYLE_KEY,
    CorFeaturedContentCardStyleEnum.CardWithButton
  );

  const isCarousel = carousel && entries?.length > cardDisplayNumber;
  const maxEntries = isCarousel ? entries?.length : cardDisplayNumber;

  const CardsDesktopVariant = !isCarousel
    ? CorCards
    : entries?.length > 5 && cardDisplayNumber === 5
    ? CorCardsCarouselFullWidth
    : CorCardsCarousel;
  const CardVariant = CorFeaturedContentCardStyleMap.get(cardStyle) || CorSmallCardTitleCategory;
  const CardsVariant = isMobile
    ? [CorFeaturedContentCardStyleEnum.CardWithTitle].includes(cardStyle)
      ? CorCardsWithTitleMobile
      : CorCardsMobile
    : CardsDesktopVariant;

  const isSmallCardVariant = CorFeaturedContentSmallCardsSet.has(cardStyle);

  return { CardVariant, CardsVariant, maxEntries, cardDisplayNumber, isSmallCardVariant };
};

const useFeaturedContent = () => {
  const entries = useFeaturedContentEntries();
  const {
    CardVariant,
    CardsVariant,
    maxEntries,
    cardDisplayNumber,
    isSmallCardVariant,
  } = useFeaturedContentPresentation(entries);

  return {
    entries: entries?.slice(0, maxEntries),
    CardVariant,
    CardsVariant,
    cardDisplayNumber,
    isSmallCardVariant,
  };
};

export default useFeaturedContent;
