import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { getSearchSuggestions, getSearchSuggestionsActionConstants } from 'store/search-products/actions';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

interface ISearchSuggestionsPayload {
  searchString: string;
}

export function* getSearchSuggestionsHandler({ payload: { searchString } }: IAction<ISearchSuggestionsPayload>) {
  const searchQuery = excludeSpecialCharacters(searchString);

  yield put(
    doRequest<ISearchSuggestionsPayload>(
      getSearchSuggestions,
      `${Endpoints.SEARCH_SUGGESTIONS}/${encodeURIComponent(searchQuery)}`,
      'get'
    )
  );
}

export default function* getSearchSuggestionsSaga() {
  yield takeLatest(getSearchSuggestionsActionConstants[Actions.REQUEST], getSearchSuggestionsHandler);
}
