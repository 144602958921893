import { FC, useCallback, useState } from 'react';
import { get, sortBy } from 'lodash-es';
import classNames from 'classnames';
import { useContent } from 'hooks/use-content';
import { CorButton } from 'components/cor-button';
import { CorDistributionCenter } from './components/cor-distribution-center/cor-distribution-center';
import './cor-distribution-map.scss';

export const CorDistributionMap: FC = () => {
  const { getContentByKey } = useContent();
  const distributionMap = getContentByKey('distribution_map.distribution_map.0', {});
  const backgroundColor = getContentByKey('distribution_map.container_background_color', '')
    ?.toLowerCase()
    ?.replace(new RegExp(' ', 'g'), '-');
  const [seeAll, setSeeAll] = useState([false, false]);

  const handleSeeAll = useCallback(
    (index: number) => {
      const newSeeAll = [...seeAll];
      newSeeAll[index] = !newSeeAll[index];
      setSeeAll(newSeeAll);
    },
    [seeAll]
  );

  const distributionCentersGroups = [
    {
      title: get(distributionMap, 'section_title_1', ''),
      description: get(distributionMap, 'section_description_1', ''),
      distributionCenters: sortBy(get(distributionMap, 'distribution_centers_1', []), ['title']),
    },
    {
      title: get(distributionMap, 'section_title_2', ''),
      description: get(distributionMap, 'section_description_2', ''),
      distributionCenters: sortBy(get(distributionMap, 'distribution_centers_2', []), ['title']),
    },
  ];

  return (
    <div className={classNames('cor-distribution-map', { [`cor-bg-color__${backgroundColor}`]: !!backgroundColor })}>
      <div className="cor-distribution-map__container">
        <div className="cor-distribution-map__main">
          <div
            className="cor-distribution-map__map"
            dangerouslySetInnerHTML={{ __html: get(distributionMap, 'google_map_embed') }}
          />
          <div className="cor-distribution-map__title cor-text-color__dark-gray">
            {get(distributionMap, 'distribution_listing_title', '')}
          </div>
        </div>
        {distributionCentersGroups.map((group, index) => (
          <div key={`cor-distribution-map__section-${index}`}>
            <div className="cor-distribution-map__header">
              <div className="cor-distribution-map__subtitle cor-text-color__dark-gray">{group.title}</div>
              <div className="cor-distribution-map__subtitle-description cor-text-color__mid-gray">
                {group.description}
              </div>
            </div>
            <div className={classNames('cor-distribution-map__content', { 'show-all': seeAll[index] })}>
              {group.distributionCenters.map((distributionCenter, index) => (
                <CorDistributionCenter data={distributionCenter} key={`cor-distribution-center__item-${index}`} />
              ))}
            </div>
            {group.distributionCenters.length > 3 && (
              <CorButton
                color="secondary"
                className="cor-distribution-map__btn-see-all"
                onClick={() => handleSeeAll(index)}
              >
                {seeAll[index] ? 'See Less' : 'See All'}
              </CorButton>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
