import { put, take, takeLatest } from 'redux-saga/effects';
import { doRequest } from 'store/api/actions';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { IAction } from 'types/actions';
import { addCreditCard, addCreditCardActionConstants } from 'store/payment-methods/actions';
import { formatLocaleForRequestHeader } from 'utils/format-locale-for-request-header';

export interface IAddCreditCardPayload {
  firstName: string;
  lastName: string;
  pfToken: string;
  zipCode: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: (status: number, messageId: string) => void;
}

export function* addCreditCardOnSuccessSagaHandler({ payload }: IAction<IAddCreditCardPayload>) {
  yield take(addCreditCardActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* addCreditCardOnFailSagaHandler({ payload }: IAction<IAddCreditCardPayload>) {
  const { error } = yield take(addCreditCardActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack && error) {
    payload.onFailCallBack(error.status, error.errorId);
  }
}

export function* addCreditCardHandler({
  payload: { firstName, lastName, pfToken, zipCode },
}: IAction<IAddCreditCardPayload>) {
  const url = `${Endpoints.ADD_CREDIT_CARD}`;
  yield put(
    doRequest(
      addCreditCard,
      url,
      'post',
      { firstName, lastName, pfToken, zipCode },
      { 'Accept-Language': formatLocaleForRequestHeader() }
    )
  );
}

export default function* addCreditCardSaga() {
  yield takeLatest(addCreditCardActionConstants[Actions.REQUEST], addCreditCardOnSuccessSagaHandler);
  yield takeLatest(addCreditCardActionConstants[Actions.REQUEST], addCreditCardOnFailSagaHandler);
  yield takeLatest(addCreditCardActionConstants[Actions.REQUEST], addCreditCardHandler);
}
