import React from 'react';
import { useAlgoliaQueryParams } from 'hooks';
import { ALGOLIA_DEFAULT_PAGE_NUMBER } from 'hooks/use-algolia';

const useCorFilteredListDrawer = () => {
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);
  const handleOpenDrawer = React.useCallback(() => {
    document.body.style.overflow = 'hidden';
    setIsDrawerOpened(true);
  }, []);
  const handleCloseDrawer = React.useCallback(() => {
    document.body.style.overflow = 'auto';
    setIsDrawerOpened(false);
  }, []);

  return { isDrawerOpened, handleCloseDrawer, handleOpenDrawer };
};

const useCorFilteredListPresentation = () => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();

  React.useEffect(() => {
    setQueryParams((prev) => ({
      ...prev,
      page_type: undefined,
      page: queryParams?.page || ALGOLIA_DEFAULT_PAGE_NUMBER,
      query: queryParams?.query || undefined,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useCorFilteredListChips = () => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();

  const handleClearFilter = React.useCallback(() => {
    setQueryParams({ facets: undefined, page: ALGOLIA_DEFAULT_PAGE_NUMBER });
  }, [setQueryParams]);

  const handleRemoveChip = React.useCallback(
    ({ facetKey = '', facetTerm = '' }) => () => {
      setQueryParams((prev) => {
        const newFacetTerms = JSON.stringify(
          JSON.parse(prev?.facets?.[facetKey] || '[]')?.filter((current) => ![current].includes(facetTerm))
        );
        return {
          facets: {
            ...prev.facets,
            [facetKey]: JSON.parse(newFacetTerms)?.length ? newFacetTerms : undefined,
          },
          page: ALGOLIA_DEFAULT_PAGE_NUMBER,
        };
      });
    },
    [setQueryParams]
  );

  const hasChips = React.useMemo(() => {
    return !!Object.keys(queryParams?.facets || {})?.reduce((acc: number, currKey: string) => {
      return acc + JSON.parse(queryParams?.facets?.[currKey] || '[]')?.length;
    }, 0);
  }, [queryParams?.facets]);

  const chips: { facetTerm: string; facetKey: string }[] = React.useMemo(
    () =>
      Object.keys(queryParams?.facets || {})
        ?.map((facetKey) =>
          JSON.parse(queryParams?.facets?.[facetKey] || '[]')?.map((facetTerm) => ({ facetTerm, facetKey }))
        )
        ?.flat(),
    [queryParams?.facets]
  );

  return { handleClearFilter, handleRemoveChip, hasChips, chips };
};

const useCorFilteredList = () => {
  const drawer = useCorFilteredListDrawer();
  const chips = useCorFilteredListChips();

  useCorFilteredListPresentation();

  return { drawer, chips };
};

export default useCorFilteredList;
