import { FC, useRef } from 'react';
import { Field, Formik } from 'formik';
import classNames from 'classnames';
import { useContent } from 'hooks/use-content';
import { IOptionType } from 'components/custom-dropdown';
import { CorButton } from 'components/cor-button';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { SelectField } from 'components/forms/select-field';
import { QuantityControl } from 'components/quantity-control';
import { TextField } from 'components/forms/text-field';
import { CustomTooltip } from 'components/custom-tooltip';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { DASHBOARD_QUOTES_STATUSES } from 'constants/dashboard-quotes-statuses.enum';
import { IManageQuoteValues } from 'types/quotes';
import { getFormattedDeliveryDate, getFormattedExpireDate } from 'utils/date-format';
import calendarGrayIcon from 'assets/images/icons/icon-calendar-gray.svg';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './internal-user-view-quote-modal.scss';

export interface IInternalUserViewQuoteModalProps extends IModalProps {
  quote: IManageQuoteValues;
  openCancelQuoteModal: () => void;
}

export interface IViewQuoteFormValues {
  quantity: string;
  price: string;
  estimatedDeliveryDate: IOptionType[];
  expireDate?: string;
}

export const InternalUserViewQuoteModal: FC<IInternalUserViewQuoteModalProps> = ({
  quote,
  isOpen,
  onClose,
  openCancelQuoteModal,
}) => {
  const contentstackPath = 'modals.0.view_quote_details_modal.0';
  const { getContentByKey } = useContent();
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const {
    price,
    quantity,
    customerMessage,
    msrMessage,
    currencySymbol,
    expireDate,
    status,
    rejectionReason,
    cancellationReason,
    estimatedDelivery,
  } = quote;

  const estimatedDeliveryOption = {
    label: estimatedDelivery
      ? getFormattedDeliveryDate(estimatedDelivery)
      : getContentByKey<string>(`${contentstackPath}.fields.estimated_delivery_date_default_value`, ''),
    value: estimatedDelivery,
  };

  const initialValues: IViewQuoteFormValues = {
    quantity: quantity.toString(),
    price,
    expireDate: expireDate && getFormattedExpireDate(new Date(expireDate)),
    estimatedDeliveryDate: [estimatedDeliveryOption],
  };

  const isCancelledByCustomer = status === DASHBOARD_QUOTES_STATUSES.CANCELLED_BY_CUSTOMER;
  const isCancelledByDawnBeforeApprove = status === DASHBOARD_QUOTES_STATUSES.CANCELLED_BY_DAWN;

  const viewQuoteModalClassName = classNames('internal-user-view-quote-modal', {
    'internal-user-view-quote-modal--short-quote-info': isCancelledByCustomer || isCancelledByDawnBeforeApprove,
  });

  // This view uses disabled form components
  // which can not leave without form context
  // which requires submit handler
  const fakeSubmitHandler = () => {};

  return (
    <Modal
      className={viewQuoteModalClassName}
      size="medium_large"
      isOpen={isOpen}
      onClose={onClose}
      withBackdropClick
      isGlobalModal
    >
      <ModalHeader titleContentstackPath={`${contentstackPath}.modal_title`} />
      <div className="internal-user-view-quote-modal__body">
        {customerMessage && (
          <div className="internal-user-view-quote-modal-messages">
            <p className="internal-user-view-quote-modal__message internal-user-view-quote-modal__customer-message">
              <span className="internal-user-view-quote-modal__info-label">
                <ContentstackText contentKey={`${contentstackPath}.fields.customer_s_message_label`} />
              </span>
              {customerMessage}
            </p>
          </div>
        )}
        <div className="internal-user-view-quote-modal__info">
          <Formik initialValues={initialValues} onSubmit={fakeSubmitHandler}>
            <>
              <div className="internal-user-view-quote-modal__info-block">
                <p className="internal-user-view-quote-modal__info-label">
                  <ContentstackText contentKey={`${contentstackPath}.fields.quote_number_label`} />
                </p>
                <p className="internal-user-view-quote-modal__info-value">{quote.quoteNumber}</p>
              </div>
              <div className="internal-user-view-quote-modal__info-block">
                <p className="internal-user-view-quote-modal__info-label">
                  <ContentstackText contentKey={`${contentstackPath}.fields.quantity_label`} />
                </p>
                <div className="internal-user-view-quote-modal__info-value">
                  <QuantityControl
                    disabled
                    quantity={quantity}
                    contentStackPath={`${contentstackPath}.fields.quantity_control`}
                    inputRef={quantityInputRef}
                    wrapInForm={false}
                    name="quantity"
                  />
                </div>
              </div>
              {!isCancelledByCustomer && !isCancelledByDawnBeforeApprove && (
                <>
                  <div className="internal-user-view-quote-modal__info-block-wrapper">
                    <div className="internal-user-view-quote-modal__info-block">
                      <div className="internal-user-view-quote-modal__info-label info-label-price">
                        <ContentstackText contentKey={`${contentstackPath}.fields.price_label`} />
                        <CustomTooltip tooltipId="view-quote-tooltip--price">
                          <ContentstackText contentKey={`${contentstackPath}.fields.price_tooltip_message`} />
                        </CustomTooltip>
                      </div>
                      <div className="internal-user-view-quote-modal__info-value info-block__price">
                        <TextField
                          disabled
                          id="price"
                          name="price"
                          className="internal-user-view-quote-modal__info-input"
                        >
                          <span className="currency-symbol">{currencySymbol}</span>
                        </TextField>
                      </div>
                    </div>
                    <div className="internal-user-view-quote-modal__info-block">
                      <div className="internal-user-view-quote-modal__info-label info-label-valid-until">
                        <ContentstackText contentKey={`${contentstackPath}.fields.valid_until_label`} />
                        <CustomTooltip tooltipId="edit-shopping-list-name">
                          <ContentstackText contentKey={`${contentstackPath}.fields.valid_until_tooltip_message`} />
                        </CustomTooltip>
                      </div>
                      <div className="internal-user-view-quote-modal__info-value info-block__valid-until">
                        <TextField
                          disabled
                          id="expireDate"
                          name="expireDate"
                          className="internal-user-view-quote-modal__info-input"
                          placeholder={getContentByKey<string>(
                            `${contentstackPath}.fields.valid_until_placeholder`,
                            ''
                          )}
                        >
                          <img src={calendarGrayIcon} alt="calendar icon" />
                        </TextField>
                      </div>
                    </div>
                  </div>
                  <div className="internal-user-view-quote-modal__info-block internal-user-view-quote-modal__estimated-delivery">
                    <p className="internal-user-view-quote-modal__info-label">
                      <ContentstackText contentKey={`${contentstackPath}.fields.estimated_delivery_date_label`} />
                    </p>
                    <div className="internal-user-view-quote-modal__info-value">
                      <Field
                        component={SelectField}
                        value={estimatedDelivery}
                        options={[estimatedDeliveryOption]}
                        name="estimatedDeliveryDate"
                        disabled
                        dropdownIcon={calendarGrayIcon}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          </Formik>
        </div>
        {(msrMessage || rejectionReason || cancellationReason) && (
          <div className="internal-user-view-quote-modal-messages">
            {msrMessage && (
              <p className="internal-user-view-quote-modal__message">
                <span className="internal-user-view-quote-modal__info-label">
                  <ContentstackText contentKey={`${contentstackPath}.fields.msr_message_label`} />
                </span>
                {msrMessage}
              </p>
            )}
            {rejectionReason && (
              <div className="internal-user-view-quote-modal__info-warning">
                <p className="internal-user-view-quote-modal__message">
                  <span className="internal-user-view-quote-modal__info-label">
                    <ContentstackText contentKey={`${contentstackPath}.fields.rejection_reason_label`} />
                  </span>
                  {rejectionReason}
                </p>
              </div>
            )}
            {cancellationReason && (
              <div className="internal-user-view-quote-modal__info-warning">
                <p className="internal-user-view-quote-modal__message">
                  <span className="internal-user-view-quote-modal__info-label">
                    <ContentstackText contentKey={`${contentstackPath}.fields.cancellation_reason_label`} />
                  </span>
                  {cancellationReason}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <ModalFooter>
        {status === DASHBOARD_QUOTES_STATUSES.APPROVED_BY_DAWN ? (
          <>
            <CorButton color="text" className="internal-user-view-quote-modal__button" onClick={onClose}>
              <ContentstackText contentKey={`${contentstackPath}.buttons.go_back_label`} />
            </CorButton>
            <CorButton
              color="secondary"
              className="internal-user-view-quote-modal__button"
              onClick={openCancelQuoteModal}
            >
              <ContentstackText contentKey={`${contentstackPath}.buttons.cancel_quote_label`} />
            </CorButton>
          </>
        ) : (
          <CorButton color="secondary" className="internal-user-view-quote-modal__button" onClick={onClose}>
            <ContentstackText contentKey={`${contentstackPath}.buttons.close_label`} />
          </CorButton>
        )}
      </ModalFooter>
    </Modal>
  );
};
