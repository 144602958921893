import './cor-accordion-component.scss';
import { CorAccordion, ICSCorAccordionInstance } from 'corporate-components/cor-accordion/cor-accordion';
import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CorTypography, CorTabMenu, CorAccordionSelectType } from 'corporate-ui';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { useContent } from 'hooks/use-content';

const BASE_KEY = 'accordion';
const BASE_COLUMN_KEY = 'accordion.accordion[0]';

export interface ICSCorAccordionComponentData {
  accordion: ICSCorAccordionComponent[];
  container_background_color: string;
}

export interface ICSCorAccordionComponent {
  accordion: ICSCorAccordionInstance[];
  heading: string;
  description: string;
  container_background_color: string;
}

export const CorAccordionComponent: FC = () => {
  const { getContentByKey } = useContent();
  const { container_background_color }: ICSCorAccordionComponentData = getContentByKey(
    BASE_KEY,
    {} as ICSCorAccordionComponentData
  );
  const { heading, accordion: accordions, description }: ICSCorAccordionComponent = getContentByKey(
    BASE_COLUMN_KEY,
    {} as ICSCorAccordionComponent
  );
  const [activeAccordion, setActiveAccordion] = useState<CorAccordionSelectType>({} as CorAccordionSelectType);
  const tabButtons: CorAccordionSelectType[] = accordions?.map((accordion, idx) => ({
    value: idx,
    label: accordion.tab_title,
  }));
  const backgroundColor = container_background_color?.replace(' ', '-')?.toLowerCase() || 'white';

  useEffect(() => {
    if (tabButtons?.length) {
      setActiveAccordion(tabButtons[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!accordions?.length || !tabButtons?.length) {
    return null;
  }

  return (
    <div className={classNames('cor-accordion-component', `cor-accordion-component--${backgroundColor}`)}>
      <div className={'cor-accordion-component__wrapper grid-container'}>
        {(heading || description) && (
          <div className="cor-accordion-component__head" data-testid={'cor-accordion-component__head'}>
            {heading && (
              <CorTypography variant={'h1'} color={'dark-gray'} component={'div'}>
                {heading}
              </CorTypography>
            )}
            {description && (
              <div
                data-testid={'cor-accordion-component__description'}
                className={'cor-accordion-component__description cor-rich-text'}
              >
                <CorContentstackHtml contentKey={`${BASE_KEY}.accordion[0].description`} />
              </div>
            )}
          </div>
        )}

        <div className="cor-accordion-component__panels">
          {tabButtons && (
            <div data-testid={'cor-accordion-component__tabs'} className={'cor-accordion-component__tabs'}>
              <CorTabMenu
                active={activeAccordion}
                buttons={tabButtons}
                onClickHandler={(accordion) => setActiveAccordion(accordion)}
              />
            </div>
          )}

          {accordions.map((accordion, accordionIdx) => (
            <div
              key={accordionIdx.toString()}
              data-testid={'cor-accordion-component__accordion'}
              className={classNames('cor-accordion-component__accordion', {
                'is-active': accordionIdx === activeAccordion.value,
              })}
            >
              <CorAccordion accordion={accordion} id={accordionIdx} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
