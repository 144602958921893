import { FC } from 'react';
import YouTube from 'react-youtube';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { VideoSources } from 'constants/video-sources.enum';
import { REACT_YOUTUBE_OPTIONS } from 'constants/react-youtube-options';

import './custom-video.scss';

const VIDEO_SOURCES_PRIORITY = [VideoSources.contentstack, VideoSources.brandfolder, VideoSources.youtube];

export interface IVideoConfig {
  contentstack?: { url: string };
  brandfolder?: { url: string; thumbnail_url?: string }[];
  youtube?: { videoId: string };
}

export interface ICustomVideoProps {
  className?: string;
  videoConfig: IVideoConfig;
  autoPlay?: boolean;
}

export const CustomVideo: FC<ICustomVideoProps> = ({ videoConfig, className, autoPlay }) => {
  const prioritizedVideoSource = VIDEO_SOURCES_PRIORITY.find((source) => !isEmpty(videoConfig[source]));
  const customVideoClassName = classNames(className, 'custom-video', `custom-video--${prioritizedVideoSource}`);

  const renderPrioritizedVideo = () => {
    switch (prioritizedVideoSource) {
      case VideoSources.youtube:
        return (
          videoConfig.youtube && (
            <YouTube
              opts={REACT_YOUTUBE_OPTIONS}
              className="custom-video__youtube-player"
              videoId={videoConfig.youtube.videoId}
            />
          )
        );
      case VideoSources.brandfolder:
        return (
          videoConfig.brandfolder && (
            <video
              autoPlay={autoPlay}
              controls
              src={videoConfig.brandfolder[0].url}
              poster={videoConfig.brandfolder[0].thumbnail_url}
            />
          )
        );
      case VideoSources.contentstack:
        return videoConfig.contentstack && <video autoPlay={autoPlay} controls src={videoConfig.contentstack.url} />;
      default:
        return null;
    }
  };

  return <div className={customVideoClassName}>{renderPrioritizedVideo()}</div>;
};
