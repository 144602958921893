import { all, spawn } from 'redux-saga/effects';
import getAccountInformationSaga from './get-account-information';
import setAccountPasswordSaga from './change-password';
import updatePersonalInfo from './update-personal-info';
import getSalesRepInformationSaga from './get-sales-rep-information';
import getOrderHistorySaga from './order-history';
import getOrderHistoryProductsSaga from './order-history-products';
import orderDetailsSaga from './order-details';
import generateTemporaryPasswordSaga from './generate-temporary-password';
import updatePasswordFromTemporarySaga from './update-password-from-temporary';
import inviteNewUserSaga from './invite-new-user';
import updateUserInfoSaga from './update-user-info';
import getUserInformationSaga from './get-user-info';
import deleteUserSaga from './delete-user';
import reinvitePendingUserSaga from './resend-invitation-to-pending-users';
import getAllOrderHistoryProductSkusSaga from './get-all-order-history-product-skus';

export default function* accountSagas() {
  yield all([
    spawn(getAccountInformationSaga),
    spawn(setAccountPasswordSaga),
    spawn(updatePersonalInfo),
    spawn(getUserInformationSaga),
    spawn(deleteUserSaga),
    spawn(updateUserInfoSaga),
    spawn(getSalesRepInformationSaga),
    spawn(getOrderHistorySaga),
    spawn(orderDetailsSaga),
    spawn(getOrderHistoryProductsSaga),
    spawn(generateTemporaryPasswordSaga),
    spawn(updatePasswordFromTemporarySaga),
    spawn(inviteNewUserSaga),
    spawn(reinvitePendingUserSaga),
    spawn(getAllOrderHistoryProductSkusSaga),
  ]);
}
