import React from 'react';
import { COR_PAGINATION_PAGE_RANGE_TO_DISPLAY } from 'corporate-constants/pagination.constants';
import { CorCardWithPublicationDate } from 'corporate-components/cor-cards';
import { useAlgoliaQueryParams } from 'hooks';
import { useContent } from 'hooks/use-content';
import { ContentstackContext } from 'context/contentstack';
import { Pagination } from 'components/pagination';
import { ICorNewsListingCards } from './cor-news-listing-cards.interface';

const PREFIX_KEY = 'content_list.content_or_filtered_list.0';
const LABELS_KEY = `${PREFIX_KEY}.labels.0.key_value_pair.value`;
const ENTRIES_KEY = 'entries';

const CorNewsListingCards = ({ algoliaCards = [], hasNextPage }: ICorNewsListingCards) => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const { getLabelsByKey } = useContent();
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});

  const handlePageChange = React.useCallback(
    (selectedItem: { selected: number }) => {
      setQueryParams({ page: selectedItem?.selected + 1 });
    },
    [setQueryParams]
  );

  const pagesTotal = React.useMemo(() => (queryParams?.page || 0) + (hasNextPage ? 1 : 0), [
    hasNextPage,
    queryParams?.page,
  ]);

  return (
    <>
      <ContentstackContext.Provider value={{ entries: algoliaCards }}>
        <div className="cor-news-listing__cards" data-testid="cor-news-listing__cards">
          {algoliaCards?.map((_, index) => (
            <CorCardWithPublicationDate key={index.toString()} contentKeyBase={`${ENTRIES_KEY}[${index}]`} />
          ))}
        </div>
      </ContentstackContext.Provider>

      {!!algoliaCards?.length && (
        <ContentstackContext.Provider
          value={{
            labels: {
              pagination: {
                previous_button_label: labels?.pagination_back || 'Back',
                next_button_label: labels?.pagination_next || 'Next',
              },
            },
          }}
        >
          <Pagination
            currentPage={(queryParams?.page || 0) - 1}
            pagesTotal={pagesTotal}
            contentstackBasePath="labels"
            onPageChange={handlePageChange}
            pageRangeToDisplay={COR_PAGINATION_PAGE_RANGE_TO_DISPLAY}
          />
        </ContentstackContext.Provider>
      )}
    </>
  );
};

export default React.memo(CorNewsListingCards);
