import { FC } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, sortBy } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';
import { CustomDropdown, IOptionType } from 'components/custom-dropdown';
import { ContentstackText } from 'components/contentstack';
import { setSelectedSku } from 'store/product-details/actions';
import { selectProductInfo, selectProductSelectedSkuInfo } from 'store/product-details/selectors';
import { useQueryString } from 'hooks/use-query-string';
import { ProductSelectorButton } from 'components/product-selector-button';

import './product-variant-selector.scss';

interface IAvailableVariants {
  [key: string]: IOptionType[];
}

interface IProductVariantSelector {
  modifyUrl?: boolean;
  contentstackPath: string;
  className?: string;
}

export const ProductVariantSelector: FC<IProductVariantSelector> = ({
  modifyUrl = true,
  contentstackPath,
  className: extraClass,
}) => {
  const dispatch = useDispatch();
  const productInfo = useSelector(selectProductInfo);

  const selectedSkuInfo = useSelector(selectProductSelectedSkuInfo);
  const { search } = useLocation();
  let [searchParams, replaceHistorySearch] = useQueryString(search);

  const availableVariants: IAvailableVariants = productInfo?.skus.reduce((obj: any, current) => {
    return {
      ...obj,
      [current.packing]: Array.isArray(obj[current.packing])
        ? [...obj[current.packing], { label: current.skuDetails.weight, value: current.sku }]
        : [{ label: current.skuDetails.weight, value: current.sku }],
    };
  }, {});

  const availableWeights = productInfo?.skus
    .map((item) => item.skuDetails.weight)
    .filter((sku, index, initialArray) => initialArray.indexOf(sku) === index);

  const availablePackings: IOptionType[] = availableVariants
    ? Object.keys(availableVariants)?.map((item) => ({ label: item, value: item }))
    : [];

  const onVariantChange = (variant: string) => {
    searchParams = {
      ...searchParams,
      selectedSku: variant,
    };
    if (modifyUrl) {
      replaceHistorySearch(searchParams);
    }
    dispatch(setSelectedSku(variant));
  };

  const onPackingSelect = (selectedOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
    const selectedOptionLabel = (selectedOption as IOptionType).value;
    const packingSkus = sortBy(
      productInfo.skus.filter((sku) => sku.packing === selectedOptionLabel),
      [(sku) => Number(sku.skuDetails.variantPriority)]
    );
    onVariantChange(packingSkus[0].sku);
  };

  const onVariantSelect = (selectedOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
    onVariantChange((selectedOption as IOptionType).value);
  };

  const shouldRenderVariants =
    !isEmpty(availableVariants) && (availableWeights.length > 1 || availablePackings.length > 1);

  const className = classNames('product-variant-selector', extraClass);

  return shouldRenderVariants ? (
    <div className={className}>
      {availablePackings.length > 1 && availableWeights.length > 1 ? (
        <div className="product-variant-selector__multiple-selectors">
          <div className="product-variant-selector__dropdown-wrapper">
            <p className="product-variant-selector__dropdown-label">
              <ContentstackText contentKey={`${contentstackPath}.package_format_selector_label`} />
            </p>
            <CustomDropdown
              inputId="product-variant-selector__dropdown_package_format--id"
              className="product-variant-selector__dropdown"
              items={availablePackings}
              value={{ label: selectedSkuInfo?.packing, value: selectedSkuInfo?.packing } as IOptionType}
              onChange={onPackingSelect}
            />
          </div>
          <div className="product-variant-selector__dropdown-wrapper">
            <p className="product-variant-selector__dropdown-label">
              <ContentstackText contentKey={`${contentstackPath}.weight_selector_label`} />
            </p>
            <CustomDropdown
              inputId="product-variant-selector__dropdown_weight--id"
              className="product-variant-selector__dropdown"
              items={selectedSkuInfo?.packing ? availableVariants[selectedSkuInfo?.packing] : []}
              value={{ label: selectedSkuInfo?.skuDetails.weight, value: selectedSkuInfo?.sku } as IOptionType}
              onChange={onVariantSelect}
            />
          </div>
        </div>
      ) : availablePackings.length > 1 && availableWeights.length <= 1 ? (
        <div className="product-variant-selector__single-selectors">
          {Object.keys(availableVariants).map((variant) => (
            <ProductSelectorButton
              isActive={availableVariants[variant][0].value === selectedSkuInfo?.sku}
              onVariantSelect={() => onVariantChange(availableVariants[variant][0].value)}
              key={availableVariants[variant][0].value}
            >
              {variant}
            </ProductSelectorButton>
          ))}
        </div>
      ) : (
        <div className="product-variant-selector__single-selectors">
          {Object.values(availableVariants)[0].map((variant) => (
            <ProductSelectorButton
              isActive={variant.value === selectedSkuInfo?.sku}
              onVariantSelect={() => onVariantChange(variant.value)}
              key={variant.value}
            >
              {variant.label} <ContentstackText contentKey={`${contentstackPath}.weight_unit_label`} />
            </ProductSelectorButton>
          ))}
        </div>
      )}
    </div>
  ) : null;
};
