import './cor-cards-carousel.scss';

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { ICorFeaturedContentCardsVariant } from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { useContent } from 'hooks/use-content';

const CARD_DISPLAY_NUMBER_KEY = 'card_display_number';

const CorCardsCarousel = ({ children }: React.PropsWithChildren<ICorFeaturedContentCardsVariant>) => {
  const { getContentByKey } = useContent();
  const cardDisplayNumber = getContentByKey(CARD_DISPLAY_NUMBER_KEY, 3);

  return (
    <div className="grid-container">
      <Swiper
        modules={[Navigation]}
        slidesPerView={cardDisplayNumber}
        spaceBetween={46}
        navigation={true}
        className="cor-featured-content__cards-carousel"
      >
        {!!children &&
          React.Children.map(children, (child: React.ReactNode, index) => (
            <SwiperSlide key={index.toString()}>{child}</SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default React.memo(CorCardsCarousel);
