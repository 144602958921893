import { FC } from 'react';
import classNames from 'classnames';
import { ContentstackImage, ContentstackText } from 'components/contentstack';

import './loading-indicator.scss';

export enum LoadingIndicatorType {
  FULLSIZED = 'fullsized',
}

interface ILoadingIndicatorProps {
  compact?: boolean;
  smallSpinner?: boolean;
  type?: LoadingIndicatorType;
}

export const LoadingIndicator: FC<ILoadingIndicatorProps> = ({ type, compact = false, smallSpinner = false }) => {
  const contentPath = 'common[0].loading_spinner';
  const expandedClassName = compact ? 'compact-view' : 'expanded-view';

  return (
    <div
      className={classNames('loading-indicator', `loading-indicator--${type}`, expandedClassName, {
        'is-small': smallSpinner,
      })}
    >
      <ContentstackImage contentKey={`${contentPath}.icon`} />
      {!compact && (
        <div className="caption">
          <ContentstackText contentKey={`${contentPath}.caption`} />
        </div>
      )}
    </div>
  );
};
