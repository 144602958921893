import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import productDetailsTypes from 'store/product-details/action-types';
import * as actions from './actions';

export interface IQuickShopState {
  totalAmount?: {
    amount?: string;
    currencySymbol?: string;
  };
  loading: boolean;
  isProductBeingUpdated: boolean;
}

export const quickShopInitialState: IQuickShopState = { isProductBeingUpdated: false, loading: false };

const quickShop = createReducer<IQuickShopState>(
  {
    [actions.getSkuTotalAmountActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      totalAmount: {
        ...payload,
      },
      isProductBeingUpdated: false,
    }),
    [actions.getSkuTotalAmountActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.getSkuTotalAmountActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      isProductBeingUpdated: false,
    }),
    [`${productDetailsTypes.GET_PRODUCT_DETAILS}_REQUEST`]: (state) => ({
      ...state,
      loading: true,
    }),
    [`${productDetailsTypes.GET_PRODUCT_DETAILS}_REQUEST_SUCCESS`]: (state) => ({
      ...state,
      loading: false,
    }),
    [`${productDetailsTypes.GET_PRODUCT_DETAILS}_REQUEST_FAIL`]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  quickShopInitialState
);

export default quickShop;
