import {
  CorContentStackLanguagesMap,
  CorContentStackLanguagesURL,
  CorContentStackLanguages,
  CorContentStackLanguageURLMap,
} from 'constants/cor-locale.enum';

export const localeRegex = /^(\/[\w]{2,})(\/[\w]{2})?\b/;

export const getLocaleQuery = () => {
  const globalLocale = localStorage.getItem('locale') || 'null';
  const parsedGLocale = JSON.parse(globalLocale);

  return isInternationalSite(parsedGLocale) ? `?globalLocale=${parsedGLocale}` : '';
};

export const getContentStackURL = (url, params) => {
  if (CorContentStackLanguagesMap.get(url as CorContentStackLanguagesURL)) {
    return '/';
  }

  if (params?.country && !params?.lang) {
    return url.replace(`/${params.country}`, '');
  }

  if (params?.country && params?.lang) {
    const languageUrl: string = `/${params.country}/${params.lang}`;

    if (CorContentStackLanguagesMap.get(languageUrl as CorContentStackLanguagesURL)) {
      return url.replace(languageUrl, '');
    }

    return url.replace(`/${params.country}`, '');
  }

  return url;
};

export const isLocaleURL = (url) => {
  const match = url.match(localeRegex);

  return CorContentStackLanguagesMap.get(match?.[0]) || CorContentStackLanguages.ENGLISH_UNITED_STATES;
};

export const isInternationalSite = (localeCode) => {
  return localeCode && localeCode !== CorContentStackLanguages.ENGLISH_UNITED_STATES;
};

export const getLocaleCodeByURL = (url) => {
  return (
    CorContentStackLanguagesMap.get(url as CorContentStackLanguagesURL) ||
    CorContentStackLanguages.ENGLISH_UNITED_STATES
  );
};

export const getLocaleURLByCode = (code) => {
  return (
    CorContentStackLanguageURLMap.get(code as CorContentStackLanguages) ||
    CorContentStackLanguagesURL.ENGLISH_UNITED_STATES
  );
};
