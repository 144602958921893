import { FC } from 'react';
import { Field, useField, FieldAttributes } from 'formik';
import { template } from 'lodash-es';
import classNames from 'classnames';

import './checkbox-field.scss';

export interface ICheckboxField extends FieldAttributes<any> {
  label?: string;
  name: string;
}

export const CheckboxField: FC<ICheckboxField> = ({ label, ...props }) => {
  const [, meta] = useField(props);
  const compiled = template(label);
  const checkboxFieldClass = classNames('checkbox-field', {
    'checkbox-field--error': meta.error && meta.touched,
  });

  return (
    <div className={checkboxFieldClass}>
      <label className="checkbox-field__label">
        <Field type="checkbox" className="checkbox-field__input" {...props} />
        <span className="checkbox-field__checkmark" />
        <div
          dangerouslySetInnerHTML={{
            __html: compiled(),
          }}
        />
      </label>
    </div>
  );
};
