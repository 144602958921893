import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { deleteUserActionConstants, deleteUser } from '../actions';

export interface IDeleteUserPayload {
  userId: string;
  onSuccessCallback: () => void;
}

export function* deleteUserSagaHandler({ payload: { userId } }: IAction<IDeleteUserPayload>) {
  yield put(doRequest(deleteUser, `${Endpoints.USERS}/${userId}`, 'delete'));
}

export function* deleteUserSuccessCallbackSagaHandler({ payload }: IAction<IDeleteUserPayload>) {
  yield take(deleteUserActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    yield payload.onSuccessCallback();
  }
}

export default function* deleteUserSaga() {
  yield takeLatest(deleteUserActionConstants[Actions.REQUEST], deleteUserSagaHandler);
  yield takeLatest(deleteUserActionConstants[Actions.REQUEST], deleteUserSuccessCallbackSagaHandler);
}
