import './corporate.scss';

import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { CorporatePageType } from 'corporate-constants/page-types.constants';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentResolver } from 'corporate-components/cor-pages/corporate/utils/corporate-component-mapper';
import { useBreadcrumbs } from 'hooks/use-breadcrumbs';
import { Breadcrumbs } from 'components/breadcrumbs';
import { getRecentOrdersInfo } from 'store/home/actions';
import {
  selectIsAuthorised,
  selectIsBlockedAccount,
  selectIsInvoiceOnlyCustomer,
  selectUserLocationId,
} from 'store/auth/selectors';
import { useContent } from 'hooks/use-content';
import { IGetRecentOrdersPayload } from 'store/home/sagas/get-recent-orders';
import { CorporateHead } from './utils/corporate-head';
import { PageResolver } from './utils/corporate-page-mapper';

const PAGE_CLASS_NAMES = {
  [CorporatePageType.Insights]: 'insights',
  [CorporatePageType.News]: 'news',
  [CorporatePageType.Standard]: 'standard',
  [CorporatePageType.Recipes]: 'recipe',
};

export const Corporate: FC = () => {
  const dispatch = useDispatch();
  const userLocationId = useSelector(selectUserLocationId);
  const isBlockedAccount = useSelector(selectIsBlockedAccount);
  const { getContentByKey } = useContent();
  const { breadcrumbs } = useBreadcrumbs();
  const includeBreadcrumb = getContentByKey('include_breadcrumb', true);
  const modularBlocks = getContentByKey('modular_blocks', []);
  const corporatePageType = getContentByKey('contentTypeName', '');
  const common = getContentByKey<any>('common', []);
  const cssPageTypeModifier = `corporate--${PAGE_CLASS_NAMES[corporatePageType]}`;
  const corporatePageClassName = classNames('corporate', cssPageTypeModifier, {
    'corporate--is-blocked-account': isBlockedAccount,
  });
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const currentDetailPageBreadcrumb =
    PAGE_CLASS_NAMES[corporatePageType] !== 'standard'
      ? [
          {
            name: getContentByKey('title', ''),
          },
        ]
      : [];
  const isAuthorized = useSelector(selectIsAuthorised);

  useEffect(() => {
    if (!isAuthorized || isInvoiceOnlyUser) return;

    dispatch(
      getRecentOrdersInfo.request<IGetRecentOrdersPayload>({
        limit: 6,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userLocationId, isAuthorized]);

  return (
    <CorporateHead>
      <div className={corporatePageClassName}>
        {includeBreadcrumb && !!breadcrumbs?.length && (
          <div className={'corporate__breadcrumbs'}>
            <div className="grid-container">
              <Breadcrumbs breadcrumbs={[...breadcrumbs, ...currentDetailPageBreadcrumb]} />
            </div>
          </div>
        )}
        <PageResolver>
          {!!modularBlocks?.length &&
            modularBlocks.map((modularBlock, index) => ComponentResolver(modularBlock, index, common))}
        </PageResolver>
      </div>
    </CorporateHead>
  );
};
