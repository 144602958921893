import {
  ICSUtilityNavData,
  ICSUtilityMenuItem,
  IUtilityNavData,
  IMenuItemProps,
} from 'components/layout/cor-header/cor-header.interface';
import { resolveLink } from 'components/layout/cor-header/cor-header.resolvers';
import { IUserInfo } from 'types/user-info';
import { getUtilityLinkTypes } from 'components/layout/cor-header/utilities/handle-get-counts';
import { Roles } from 'constants/roles.enum';
import { Routes } from 'constants/routes.enum';

const setMenuItems = (
  links: ICSUtilityMenuItem[] = [],
  isDisabled: boolean = false,
  isImpersonation: boolean = false
): IMenuItemProps[] => {
  return links.reduce((memo: any, linkItem) => {
    const { link, icon, icon_hint_text, icon_image } = linkItem;
    const hasImage =
      (icon_image?.brand_folder_image && !!Object.keys(icon_image?.brand_folder_image).length) || !!icon_image?.image;

    const { title, href } = link;

    // In impersonation mode, cart isn't available...
    return isImpersonation && href === Routes.ShoppingCartPage
      ? [...memo]
      : [
          ...memo,
          {
            url: href,
            image: hasImage ? icon_image : null,
            imgUrl: icon?.url || null,
            altText: icon_hint_text || null,
            text: title || null,
            hintText: icon_hint_text,
            type: getUtilityLinkTypes(href),
            isDisabled: isDisabled && href === Routes.ShoppingCartPage,
          },
        ];
  }, []);
};

export const utilityNavMapper = (
  isLoggedInUser: boolean,
  isHouseAccount: boolean,
  isImpersonation: boolean,
  locationDeleted: boolean,
  utilityNavData: ICSUtilityNavData,
  shopLabel: string,
  user?: IUserInfo
): IUtilityNavData => {
  const userRoles = user?.roles;
  const isAdminUser = userRoles?.length
    ? [Roles.SUPER_ADMIN, Roles.ADMIN].some((role) => userRoles.includes(role))
    : false;

  const { firstName, lastName } = user || { firstName: null, lastName: null };
  const { utility_link = [], user_links = [], super_admin_user_links, user_utility_button } = utilityNavData || {};
  const userName = isLoggedInUser && firstName ? `${firstName} ${lastName}` : '';
  const userLinks = isAdminUser ? super_admin_user_links : user_links;
  const userInfo = isLoggedInUser
    ? {
        userName,
        ...setMenuItems(user_utility_button ? [user_utility_button] : [])[0],
        menuOptions: userLinks
          ? userLinks
              .filter((link: ICSUtilityMenuItem) =>
                isHouseAccount ? link.link.href !== Routes.AccountQuotesPage : true
              )
              .map((link: ICSUtilityMenuItem) => resolveLink(link))
          : [],
      }
    : {};

  return {
    isLoggedInUser,
    menuItems: setMenuItems(utility_link, locationDeleted, isImpersonation),
    shopButtonLabel: shopLabel,
    userInfo,
  };
};
