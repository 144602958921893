import { RefObject, createContext } from 'react';

export interface IEquivalentProductsContextValue {
  productReplacementRef: RefObject<HTMLDivElement> | null;
  similarItemsRef: RefObject<HTMLDivElement> | null;
}

export const EquivalentProductsContext = createContext<IEquivalentProductsContextValue>({
  productReplacementRef: null,
  similarItemsRef: null,
});
