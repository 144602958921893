enum quotesActionTypes {
  GET_QUOTES = 'GET_QUOTES',
  REQUEST_QUOTE = 'REQUEST_QUOTE',
  GET_SKU_INFO = 'GET_SKU_INFO',
  RESET_SKU_INFO_DETAILS = 'RESET_SKU_INFO_DETAILS',
  GET_INTERNAL_USER_QUOTES = 'GET_INTERNAL_USER_QUOTES',
  INTERNAL_USER_QUOTES_PAGINATION_CHANGE = 'INTERNAL_USER_QUOTES_PAGINATION_CHANGE',
  CANCEL_QUOTE = 'CANCEL_QUOTE',
  REJECT_QUOTE = 'REJECT_QUOTE',
  ACCEPT_QUOTE = 'ACCEPT_QUOTE',
  SUBMIT_QUOTE_ORDER = 'SUBMIT_QUOTE_ORDER',
  DISMISS_QUOTE = 'DISMISS_QUOTE',
  REVIEW_QUOTE = 'REVIEW_QUOTE',
  APPROVE_QUOTE = 'APPROVE_QUOTE',
  CLEAR_APPROVE_QUOTE_SERVER_ERROR = 'CLEAR_APPROVE_QUOTE_SERVER_ERROR',
  GET_DELIVERY_DATES = 'GET_DELIVERY_DATES',
  GET_QUOTES_REQUESTED = 'GET_QUOTES_REQUESTED',
}

export default quotesActionTypes;
