import { FC, PropsWithChildren } from 'react';
import 'corporate-components/cor-layout/cor-page-layout.scss';

export interface ICorpLivePreviewLayout extends PropsWithChildren {
  classModifier?: string;
}

export const CorLivePreviewLayout: FC<ICorpLivePreviewLayout> = ({ children: main, classModifier }) => {
  return (
    <div
      className={`cor-page-layout cor-page-layout--live-preview ${
        classModifier ? 'cor-page-layout--' + classModifier : ''
      } grid-y`}
      data-testid="page-layout"
    >
      <main className="cor-page-layout__main">{main}</main>
    </div>
  );
};
