import qs from 'qs';
import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { ShoppingListsRequestTypes } from 'constants/shopping-lists-request-types.enum';
import { doRequest } from 'store/api/actions';
import { getHomeShoppingListsConstants, getHomeShoppingLists } from 'store/home/actions';

export function* getHomeShoppingListsHandler() {
  yield put(
    doRequest(
      getHomeShoppingLists,
      `${Endpoints.SHOPPING_LISTS}?${qs.stringify({
        limit: 7,
        type: ShoppingListsRequestTypes.FULL_DETAILS,
        shoppingListType: 'STANDARD',
      })}`,
      'get'
    )
  );
}

export default function* getHomeShoppingListsSaga() {
  yield takeLatest(getHomeShoppingListsConstants[Actions.REQUEST], getHomeShoppingListsHandler);
}
