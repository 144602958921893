import React from 'react';

const CorFormReset = ({ isSuccess = false, onReset = () => {} }) => {
  React.useEffect(() => {
    if (!isSuccess) return;

    onReset();
  }, [isSuccess, onReset]);

  return null;
};

export default React.memo(CorFormReset);
