import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';

export const selectQuickShopEntity = (state: IState): any => state.app.quickShop;

export const selectTotalAmount = createSelector(selectQuickShopEntity, (quickShop) => quickShop.totalAmount);

export const selectSkuTotalAmountLoading = createSelector(
  selectQuickShopEntity,
  ({ isProductBeingUpdated }) => isProductBeingUpdated
);

export const selectQuickShopLoading = createSelector(selectQuickShopEntity, ({ loading }) => loading);
