import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { Endpoints } from 'constants/endpoints.enum';
import { Routes } from 'constants/routes.enum';
import { makeRequest } from 'store/api/sagas';
import actions from 'store/auth/action-types';
import { unloadUser } from 'store/auth/actions';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { CorContentStackLanguages } from 'constants/cor-locale.enum';

export function* logoutSagaHandler() {
  const locale = localStorage.getItem('locale');
  const isCanadaFrenchLocale = locale?.includes(CorContentStackLanguages.FRENCH_CANADA);

  try {
    yield makeRequest({
      url: Endpoints.LOGOUT,
      method: 'get',
      withCredentials: true,
    });
  } catch (e) {
    console.error(e);
  }

  yield put(unloadUser);
  yield put(push(getLocalizedShopUrl(isCanadaFrenchLocale ? Routes.CanadaFrenchLoginPage : Routes.LoginPage)));
}

export default function* logoutSaga() {
  yield takeLatest(actions.LOGOUT, logoutSagaHandler);
}
