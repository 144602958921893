import { all, spawn } from 'redux-saga/effects';
import scheduleCardPaymentSaga from './schedule-card-payment';
import scheduleBankAccountPaymentSaga from './schedule-bank-payment';
import cancelScheduledPaymentSaga from './cancel-scheduled-payment';
import schedulePadPaymentSaga from './schedule-pad-payment';

export default function* schedulePaymentSagas() {
  yield all([
    spawn(scheduleBankAccountPaymentSaga),
    spawn(scheduleCardPaymentSaga),
    spawn(schedulePadPaymentSaga),
    spawn(cancelScheduledPaymentSaga),
  ]);
}
