import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getProductsBySkusActionConstants, getProductsBySkus } from 'store/products/actions';
import { IAction } from 'types/actions';

export interface IGetProductsBySkusPayload {
  skus: string;
}

export function* getProductsBySkusHandler({ payload: { skus } }: IAction<IGetProductsBySkusPayload>) {
  const url = `${Endpoints.CATALOG_SKUS}/${skus}`;
  yield put(doRequest(getProductsBySkus, url, 'get'));
}

export default function* getProductsBySkusSaga() {
  yield takeLatest(getProductsBySkusActionConstants[Actions.REQUEST], getProductsBySkusHandler);
}
