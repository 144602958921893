import './cor-play-button.scss';

import { FC } from 'react';

export const CorPlayButton: FC = () => {
  return (
    <div className={'cor-play-button'}>
      <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_677_1205)">
          <circle cx="60" cy="60" r="30" fill="white" />
        </g>
        <path d="M73 59.5L54.25 70.3253L54.25 48.6747L73 59.5Z" fill="#D8400E" />
        <defs>
          <filter
            id="filter0_d_677_1205"
            x="0"
            y="0"
            width="120"
            height="120"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="15" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_677_1205" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_677_1205" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
