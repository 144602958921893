import React, { useContext } from 'react';
import { useContent } from 'hooks/use-content';
import { ProductReplacementWrapper as AlternativeItemsWrapper } from 'pages/product-details/components/product-replacement/components/product-replacement-wrapper';
import { ISkuInformation } from 'types/order-details';
import { EquivalentProductsContext } from 'context/product-replacement-context';
import { AlternativeItemsLineItem } from 'components/alternative-items-line-item/alternative-items-line-item';

import './similar-items.scss';

export interface ISimilarItemsProps {
  similarItems: ISkuInformation[];
  onRequestQuoteCtaClick?: (skuKey: string, isOpenedWithinQuickShop: boolean) => void;
  contentstackPath?: string;
  // TODO: remove it after migration of QuickShopModal to global approach
  onRequestSimilarItemQuote?: () => void;
  recipeDetailPageContentstackPath?: string;
}

export const SimilarItems: React.FC<ISimilarItemsProps> = ({
  similarItems,
  onRequestQuoteCtaClick,
  contentstackPath,
  onRequestSimilarItemQuote,
  recipeDetailPageContentstackPath,
}) => {
  const { getContentByKey } = useContent();
  const { similarItemsRef } = useContext(EquivalentProductsContext);

  const contentstackBasePath = contentstackPath ? contentstackPath : 'page_content.similar_items';

  return (
    <div ref={similarItemsRef}>
      <AlternativeItemsWrapper
        title={getContentByKey<string>(`${contentstackBasePath}.title`, '')}
        className="similar-items"
      >
        {similarItems.map((item) => (
          <AlternativeItemsLineItem
            alternativeItem={item}
            onRequestQuoteCtaClick={onRequestQuoteCtaClick}
            onRequestSimilarItemQuote={onRequestSimilarItemQuote}
            key={item.sku}
            recipeDetailPageContentstackPath={recipeDetailPageContentstackPath}
            isInCart={item.isInCart}
          />
        ))}
      </AlternativeItemsWrapper>
    </div>
  );
};
