import { FC, PropsWithChildren } from 'react';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { RenderIfNonEmpty } from 'components/conditional-render';

interface IRenderIfNonEmptyContentProps extends PropsWithChildren {
  keys: string[];
}

export const RenderIfNonEmptyContent: FC<IRenderIfNonEmptyContentProps> = ({ keys, children }) => {
  const contentstackContent = useContentstackContent();

  return (
    contentstackContent && (
      <RenderIfNonEmpty data={contentstackContent} keys={keys}>
        {children}
      </RenderIfNonEmpty>
    )
  );
};
