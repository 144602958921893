import { FC } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Routes } from 'constants/routes.enum';
import { CorButton } from 'components/cor-button';
import { CustomTooltip } from 'components/custom-tooltip';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { selectIsKeyAccountUser, selectIsUserLocationDeleted } from 'store/auth/selectors';
import { Permissions } from 'constants/permissions';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { PermissionAccess } from 'components/permission-access';
import { IOutOfStockSimilarItemInfo } from 'types/order-details';
import { HierarchyAccountsSpecialOrderMessage } from 'components/hierarchy-accounts-special-order-message';

import './buy-it-again-item-buttons.scss';

export interface IBuyItAgainItemButtonsProps {
  contentstackPath: string;
  sku: string;
  productKey: string;
  isSpecialOrder: boolean;
  isDiscontinuedWithReplacement: boolean;
  isOutOfStock: boolean;
  outOfStockSimilarItems?: IOutOfStockSimilarItemInfo[];
  active: boolean;
  disabled: boolean;
  onAddToCartClick: () => void;
  onRequestQuoteCtaClick?: () => void;
  openDiscontinuedWithReplacementModal?: () => void;
}

export const BuyItAgainItemButtons: FC<IBuyItAgainItemButtonsProps> = ({
  contentstackPath,
  sku,
  isSpecialOrder,
  isDiscontinuedWithReplacement,
  outOfStockSimilarItems,
  isOutOfStock,
  active,
  disabled,
  onAddToCartClick,
  productKey,
  onRequestQuoteCtaClick,
  openDiscontinuedWithReplacementModal,
}) => {
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);
  const addToCartButtonClass = classNames('buy-it-again-item-buttons__button buy-it-again-item-buttons__add-to-cart', {
    active,
    disabled,
  });

  const isAddToCartDisabled = disabled || isUserLocationDeleted || (isOutOfStock && isEmpty(outOfStockSimilarItems));

  return !isSpecialOrder ? (
    isDiscontinuedWithReplacement ? (
      <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
        <CorButton
          className="buy-it-again-item-buttons__button"
          color="primary"
          onClick={openDiscontinuedWithReplacementModal}
        >
          <ContentstackText contentKey={`${contentstackPath}.see_alternative_button_label`} />
        </CorButton>
      </PermissionAccess>
    ) : isOutOfStock && !isEmpty(outOfStockSimilarItems) ? (
      <Link
        to={
          outOfStockSimilarItems && outOfStockSimilarItems.length > 1
            ? `${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku}`
            : `${Routes.ProductDetailsPage}/${
                outOfStockSimilarItems && outOfStockSimilarItems[0].productKey
              }?selectedSku=${outOfStockSimilarItems && outOfStockSimilarItems[0].sku}`
        }
        target="_blank"
        className="new-button primary buy-it-again-item-buttons__button buy-it-again-item-buttons__select-similar"
      >
        <ContentstackText contentKey={`${contentstackPath}.select_similar_button_label`} />
      </Link>
    ) : (
      <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
        <CorButton
          className={addToCartButtonClass}
          color="tertiary"
          disabled={isAddToCartDisabled}
          onClick={onAddToCartClick}
        >
          <ContentstackText contentKey={`${contentstackPath}.add_to_cart_button_label${active ? '_active' : ''}`} />
        </CorButton>
      </PermissionAccess>
    )
  ) : isKeyAccountUser ? (
    <HierarchyAccountsSpecialOrderMessage />
  ) : (
    <div className="buy-it-again-item-buttons__request-quote">
      <CorButton
        color="tertiary"
        className="buy-it-again-item-buttons__button"
        disabled={isAddToCartDisabled}
        onClick={onRequestQuoteCtaClick}
      >
        <ContentstackText contentKey={`${contentstackPath}.request_quote_button_label`} />
      </CorButton>
      <CustomTooltip tooltipId={`buy-it-again-special-order-${sku}`} overrideTooltipPosition={false}>
        <ContentstackMessage type="tooltips" messageId="MSG107" />
      </CustomTooltip>
    </div>
  );
};
