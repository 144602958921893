import { FC } from 'react';
import { useContent } from 'hooks/use-content';

import './product-status-labels.scss';

interface IProductStatusLabelsProps {
  size?: 'small' | 'large' | 'responsive';
  isDiscontinued?: boolean;
  isSpecialOrder?: boolean;
  isPhasingOut?: boolean;
  isCancelled?: boolean;
  isOutOfStock?: boolean;
  contentstackPath?: string;
}

export const ProductStatusLabels: FC<IProductStatusLabelsProps> = ({
  size = 'responsive',
  isDiscontinued = false,
  isSpecialOrder = false,
  isPhasingOut,
  isCancelled = false,
  isOutOfStock = false,
  contentstackPath,
}) => {
  const { getContentByKey } = useContent();
  const contentStackPath = contentstackPath || 'product_characteristics.product_status_labels[0]';

  return (
    <div className={`product-status-labels product-status-labels--${size}`}>
      <div className="product-status-labels__wrapper">
        {isDiscontinued && !isPhasingOut && (
          <p className="product-status product-status--discontinued">
            {getContentByKey<string>(`${contentStackPath}.discontinued`, '')}
          </p>
        )}
        {isOutOfStock && !isDiscontinued && (
          <p className="product-status product-status--out-of-stock">
            {getContentByKey<string>(`${contentStackPath}.out_of_stock`, '')}
          </p>
        )}
        {isPhasingOut && (
          <p className="product-status product-status--phasing-out">
            {getContentByKey<string>(`${contentStackPath}.phasing_out`, '')}
          </p>
        )}
        {isCancelled && (
          <p className="product-status product-status--cancelled">
            {getContentByKey<string>(`${contentStackPath}.cancelled`, '')}
          </p>
        )}
        {isSpecialOrder && (
          <p className="product-status product-status--special-order">
            {getContentByKey<string>(`${contentStackPath}.special_order`, '')}
          </p>
        )}
      </div>
    </div>
  );
};
