import {
  CorCardWithButton,
  CorCardWithPublicationDate,
  CorCardWithTitle,
  CorSmallCardTitle,
  CorSmallCardTitleCategory,
} from 'corporate-components/cor-cards';
import { ICorFeaturedContentCardVariant } from 'corporate-components/cor-featured-content/cor-featured-content.interface';

export const enum CorFeaturedContentCardStyleEnum {
  CardWithButton = 'Card w/ Button',
  CardWithPublicationDate = 'Card w/ Publication Date',
  CardWithTitle = 'Card w/ Title only',
  SmallCardWithTitle = 'Small Card w/ Title',
  SmallCardWithTitleCategory = 'Small Card w/ Title & Category',
}

export const enum CorFeaturedContentContentTypeEnum {
  CorporateProducts = 'Corporate Products',
  Insights = 'Insights',
  Recipes = 'Recipes',
  News = 'News',
}

export const enum CorFeaturedContentContentTypeUidEnum {
  CorporateProducts = 'standard_page',
  Insights = 'insights_detail_page',
  Recipes = 'recipe_detail_page',
  News = 'news_page',
}

export const CorFeaturedContentContentTypeMap = new Map<CorFeaturedContentContentTypeEnum, string>([
  [CorFeaturedContentContentTypeEnum.CorporateProducts, CorFeaturedContentContentTypeUidEnum.CorporateProducts],
  [CorFeaturedContentContentTypeEnum.Insights, CorFeaturedContentContentTypeUidEnum.Insights],
  [CorFeaturedContentContentTypeEnum.Recipes, CorFeaturedContentContentTypeUidEnum.Recipes],
  [CorFeaturedContentContentTypeEnum.News, CorFeaturedContentContentTypeUidEnum.News],
]);

export const CorFeaturedContentContentTypeUidMap = new Map<
  CorFeaturedContentContentTypeUidEnum,
  CorFeaturedContentContentTypeEnum
>([
  [CorFeaturedContentContentTypeUidEnum.CorporateProducts, CorFeaturedContentContentTypeEnum.CorporateProducts],
  [CorFeaturedContentContentTypeUidEnum.Insights, CorFeaturedContentContentTypeEnum.Insights],
  [CorFeaturedContentContentTypeUidEnum.Recipes, CorFeaturedContentContentTypeEnum.Recipes],
  [CorFeaturedContentContentTypeUidEnum.News, CorFeaturedContentContentTypeEnum.News],
]);

export const CorFeaturedContentCardStyleMap = new Map<
  CorFeaturedContentCardStyleEnum,
  React.FC<ICorFeaturedContentCardVariant>
>([
  [CorFeaturedContentCardStyleEnum.CardWithButton, CorCardWithButton],
  [CorFeaturedContentCardStyleEnum.CardWithPublicationDate, CorCardWithPublicationDate],
  [CorFeaturedContentCardStyleEnum.CardWithTitle, CorCardWithTitle],
  [CorFeaturedContentCardStyleEnum.SmallCardWithTitle, CorSmallCardTitle],
  [CorFeaturedContentCardStyleEnum.SmallCardWithTitleCategory, CorSmallCardTitleCategory],
]);

export const CorFeaturedContentSmallCardsSet = new Set<CorFeaturedContentCardStyleEnum>([
  CorFeaturedContentCardStyleEnum.SmallCardWithTitle,
  CorFeaturedContentCardStyleEnum.SmallCardWithTitleCategory,
]);
