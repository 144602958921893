import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { useContent } from 'hooks/use-content';
import { Routes } from 'constants/routes.enum';
import { ContentstackText } from 'components/contentstack';
import { CustomLink } from 'components/custom-link';
import { INewsItem } from 'types/news-and-notifications';
import iconChevronRed from 'assets/images/icons/icon-chevron-red.svg';
import { selectHasNextOrderDueDate } from 'store/order-cut-off/selectors';
import { selectIsPickupAccountUser } from 'store/auth/selectors';
import { CorButton } from 'components/cor-button';
import { NewsItemDescription } from './news-item-description';

import './news-section.scss';

export const NewsSection: FC = () => {
  const { getContentByKey } = useContent();
  const hasNextOrderDueDate = useSelector(selectHasNextOrderDueDate);
  const isPickupAccount = useSelector(selectIsPickupAccountUser);
  const PATH_TO_CONTENT = 'page_content.news_section';
  const DISPLAYED_NEWS_LIMIT = hasNextOrderDueDate || isPickupAccount ? 3 : 4;
  const newsItems: INewsItem[] = getContentByKey(
    'page_content.news_section.news_and_notifications_list.0.news_and_notifications',
    []
  );
  const [isNewsItemSelected, setNewsItemIsSelected] = useState(false);
  const [selectedNewsItemIndex, setSelectedNewsItemIndex] = useState<number | null>(null);

  const renderNewsTitle = (newsItem: INewsItem) => {
    return !isEmpty(newsItem.heading_link) ? (
      <CustomLink url={newsItem.heading_link}>
        <p className="news-section__item-title-link" title={newsItem.heading}>
          {newsItem.heading}
        </p>
      </CustomLink>
    ) : (
      <p className="news-section__item-title" title={newsItem.heading}>
        {newsItem.heading}
      </p>
    );
  };

  const renderSelectedNewsTitle = (newsItem: INewsItem) => {
    return !isEmpty(newsItem.heading_link) ? (
      <CustomLink url={newsItem.heading_link}>
        <p className="news-section__item-title-link-expanded" title={newsItem.heading}>
          {newsItem.heading}
        </p>
      </CustomLink>
    ) : (
      <p className="news-section__item-title-expanded" title={newsItem.heading}>
        {newsItem.heading}
      </p>
    );
  };

  const renderNewsItems = () =>
    newsItems
      .filter((item) => !isEmpty(item?.publish_details))
      .slice(0, DISPLAYED_NEWS_LIMIT)
      .map((item, index) => {
        return (
          <div className="news-section__item" key={`${item.heading}-${index}`}>
            <div className="news-section__item-content">
              {renderNewsTitle(item)}
              <NewsItemDescription
                content={item.description}
                updateSelected={() => {
                  setSelectedNewsItemIndex(index);
                  setNewsItemIsSelected(true);
                }}
              />
            </div>
          </div>
        );
      });

  const renderSelectedNewsItem = () => {
    if (selectedNewsItemIndex === null) {
      return;
    }

    const selectedNewsItem = newsItems[selectedNewsItemIndex];
    return (
      <div className="news-section__item" key={selectedNewsItem.heading}>
        <div className="news-section__item-content">
          <div className="news-section__item-content-wrapper">
            {renderSelectedNewsTitle(selectedNewsItem)}
            <div
              className="news-section__item-description news-section__item-description--expanded"
              title={selectedNewsItem.description}
            >
              <NewsItemDescription content={selectedNewsItem.description} />
            </div>
          </div>
          <CorButton
            className="text news-section__hide_button"
            onClick={() => {
              selectedNewsItem.selected = false;
              setNewsItemIsSelected(false);
            }}
          >
            <ContentstackText contentKey={`${PATH_TO_CONTENT}.close_button`} />
          </CorButton>
        </div>
      </div>
    );
  };

  return (
    <div className={'news-section ' + (isNewsItemSelected ? 'item-selected' : '')}>
      <div className="news-section__title-wrapper">
        <p className="news-section__title">
          <ContentstackText contentKey="page_content.news_section.heading" />
        </p>
        {newsItems.length > DISPLAYED_NEWS_LIMIT && (
          <div className="news-section__more-notifications-button">
            <Link to={Routes.NewsAndNotificationsPage}>
              <ContentstackText contentKey={`${PATH_TO_CONTENT}.more_notifications_button`} />
            </Link>
            <img src={iconChevronRed} alt="icon expand" />
          </div>
        )}
      </div>

      {isNewsItemSelected ? renderSelectedNewsItem() : renderNewsItems()}
    </div>
  );
};
