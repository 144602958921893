import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getLocationsForMsr, getLocationsForMsrConstants } from 'store/dashboard/actions';

export interface ILocationsForMsrPayload {
  limit?: number;
  offset?: number;
  msrId: string;
}

export function* getLocationsForMsrHandler({ payload: { msrId, limit, offset } }: IAction<ILocationsForMsrPayload>) {
  yield put(
    doRequest(getLocationsForMsr, `${Endpoints.LOCATIONS_BY_MSR_ID}${msrId}?limit=${limit}&offset=${offset}`, 'get')
  );
}

export default function* getLocationsForMsrSaga() {
  yield takeLatest(getLocationsForMsrConstants[Actions.REQUEST], getLocationsForMsrHandler);
}
