import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const submitCreditCardPaymentActionConstants = createConstants(types.SUBMIT_CREDIT_CARD_PAYMENT);
export const submitAchPaymentActionConstants = createConstants(types.SUBMIT_ACH_PAYMENT);
export const submitPadPaymentActionConstants = createConstants(types.SUBMIT_PAD_PAYMENT);

export const submitCreditCardPayment = createRequestActions(submitCreditCardPaymentActionConstants);
export const submitAchPayment = createRequestActions(submitAchPaymentActionConstants);
export const submitPadPayment = createRequestActions(submitPadPaymentActionConstants);

export const clearPaymentTransactionErrors = () => action(types.CLEAR_PAYMENT_TRANSACTION_ERROR, {});
