import './cor-news-listing.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { CorNewsListingCards, CorNewsListingEmpty } from 'corporate-components/cor-news-listing/components';
import { useAlgoliaQueryParams } from 'hooks';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { CONTENTSTACK_DEFAULT_PAGE_NUMBER, useContentstackSearchQuery } from 'hooks/use-contentstack-search-query';
import { LoadingIndicator } from 'components/loading-indicator';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';

const PAGINATION_OFFSET = { mobile: 3, desktop: 9 };

const CorNewsListing = () => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const location = useLocation();
  const { isMobile } = useBreakpoint();
  const [locale] = useLocalStorage('locale', null);

  React.useEffect(() => {
    setQueryParams((prev) => ({
      ...prev,
      page_type: undefined,
      page: queryParams?.page || CONTENTSTACK_DEFAULT_PAGE_NUMBER,
      query: undefined,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.search]);

  const page = React.useMemo(() => queryParams?.page || CONTENTSTACK_DEFAULT_PAGE_NUMBER, [queryParams?.page]);
  const pageOffset = React.useMemo(() => (isMobile ? PAGINATION_OFFSET.mobile : PAGINATION_OFFSET.desktop), [isMobile]);

  const contentstackSearchQuery = useContentstackSearchQuery({
    page,
    pageOffset,
    ...(isInternationalSite(locale) && { locale: locale }),
  });
  const contentstackSearchNextPageQuery = useContentstackSearchQuery({
    page: page + 1,
    pageOffset,
    ...(isInternationalSite(locale) && { locale: locale }),
  });

  const contentstackData = contentstackSearchQuery?.data?.contentstackCards;

  return (
    <div className="cor-news-listing grid-container">
      {contentstackSearchQuery.isLoading && <LoadingIndicator />}
      {contentstackSearchQuery?.isSuccess && (
        <CorNewsListingCards
          algoliaCards={contentstackData}
          hasNextPage={!!contentstackSearchNextPageQuery?.data?.contentstackCards?.length}
        />
      )}
      {(contentstackSearchQuery?.isSuccess || contentstackSearchQuery.isError) &&
        !contentstackSearchQuery?.data?.contentstackCards?.length && <CorNewsListingEmpty />}
    </div>
  );
};

export default React.memo(CorNewsListing);
