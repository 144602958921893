import React, { FC } from 'react';
import { get } from 'lodash-es';
import ReactPlayer from 'react-player'; // ref: https://github.com/CookPete/react-player
import classNames from 'classnames';
import {
  CorContentstackVideoAsset,
  corContentstackVideoLinkResolver,
  ICSCorVideoProps,
} from 'corporate-utils/cor-contentstack-video-link-resolver';
import { CorPlayButton } from 'corporate-ui/cor-play-button';
import { useContentstackContent } from 'hooks/use-contentstack-content';

import './cor-contentstack-video.scss';

type youtubeVarType = 0 | 1 | undefined;

export interface IReactPlayerYoutubeConfig {
  youtube: {
    playerVars: IReactPlayerYoutubeConfigVars;
  };
}

interface ICorContentstackVideoComponentProps {
  contentKey?: string;
  data?: CorContentstackVideoAsset;
  showControls?: boolean;
  showPlayButton?: boolean;
  muted?: boolean;
  youtubeConfig?: IReactPlayerYoutubeConfigVars | null;
  customClass?: string;
  isBackground?: boolean;
}

export interface IReactPlayerYoutubeConfigVars {
  autoplay?: youtubeVarType;
  controls?: youtubeVarType;
  mute?: youtubeVarType;
  loop?: youtubeVarType;
  cc_lang_pref?: youtubeVarType;
  cc_load_policy?: youtubeVarType;
  color?: youtubeVarType;
  disablekb?: youtubeVarType;
  enablejsapi?: youtubeVarType;
  end?: youtubeVarType;
  fs?: youtubeVarType;
  hl?: youtubeVarType;
  iv_load_policy?: youtubeVarType;
  list?: youtubeVarType;
  listType?: youtubeVarType;
  origin?: youtubeVarType;
  playlist?: youtubeVarType;
  playsinline?: youtubeVarType;
  rel?: youtubeVarType;
  start?: youtubeVarType;
  widget_referrer?: youtubeVarType;
}

const REACT_PLAYER_BASE_YOUTUBE_CONFIG: IReactPlayerYoutubeConfigVars = {
  autoplay: 1,
  controls: 1,
  mute: 1,
  loop: 1,
};

const COR_VIDEO_SUPPORT = {
  'video/quicktime': false,
  'video/mp4': true,
};

export const reactPlayerConfig = (config: IReactPlayerYoutubeConfigVars): IReactPlayerYoutubeConfig => {
  return {
    youtube: {
      playerVars: {
        ...REACT_PLAYER_BASE_YOUTUBE_CONFIG,
        ...config,
      },
    },
  };
};

export const CorContentstackVideo: FC<ICorContentstackVideoComponentProps> = ({
  contentKey = 'video',
  data = null,
  showControls = true,
  muted = false,
  youtubeConfig = null,
  customClass = '',
  showPlayButton = false,
  isBackground = false,
}) => {
  const content = useContentstackContent();
  const video: ICSCorVideoProps = !!data ? data : get(content, contentKey, {});
  const { fallbackImage, url, mimetype, isYoutube }: any = data ? video : corContentstackVideoLinkResolver(video);
  const customPlayIcon = showPlayButton ? { playIcon: <CorPlayButton /> } : {};

  // While youtube videos will start playing if [playing] is set to true,
  // Brandfolder videos load an image background with a play button
  // and don't load the actual video until the button is clicked.
  // If [playing] is not set to true, the user will need to click
  // the video's play button as well.

  const props: any = youtubeConfig
    ? {
        config: youtubeConfig ? reactPlayerConfig(youtubeConfig) : undefined,
        loop: isBackground,
      }
    : {
        playing: isBackground || !isYoutube,
        muted: muted || isBackground,
        controls: !isBackground && showControls,
        loop: isBackground,
        light: !isBackground && fallbackImage,
      };

  if (!COR_VIDEO_SUPPORT[mimetype]) {
    console.log(`The mime type (${mimetype}) for this video (${url}) may not be fully supported.`);
  }

  return (
    <div className="cor-contentstack-video">
      <ReactPlayer
        className={classNames('cor-contentstack-video__player', customClass)}
        url={url}
        width="100%"
        height="100%"
        playIcon={null}
        stopOnUnmount={true}
        {...props}
        {...customPlayIcon}
      />
    </div>
  );
};
