import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { requestQuote, requestQuoteActionConstants } from 'store/quotes/actions';

export interface IRequestQuotePayload {
  comment: string;
  quantity: number;
  sku: string;
  onSuccessCallback?: () => void;
  onFailCallback?: (error: any) => void;
}

export function* requestQuoteSuccessSagaHandler({ payload }: IAction<IRequestQuotePayload>) {
  yield take(requestQuoteActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export function* requestQuoteFailSagaHandler({ payload }: IAction<IRequestQuotePayload>) {
  const { error } = yield take(requestQuoteActionConstants[Actions.FAIL]);
  if (payload.onFailCallback) {
    payload.onFailCallback(error.deactivatedItemModel?.skus || []);
  }
}

export function* requestQuoteHandler({ payload: { comment, quantity, sku } }: IAction<IRequestQuotePayload>) {
  yield put(doRequest(requestQuote, `${Endpoints.QUOTES}`, 'post', { comment, quantity, sku }));
}

export default function* requestQuoteSaga() {
  yield takeLatest(requestQuoteActionConstants[Actions.REQUEST], requestQuoteHandler);
  yield takeLatest(requestQuoteActionConstants[Actions.REQUEST], requestQuoteSuccessSagaHandler);
  yield takeLatest(requestQuoteActionConstants[Actions.REQUEST], requestQuoteFailSagaHandler);
}
