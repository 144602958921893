import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { BrowserHistoryBuildOptions, createBrowserHistory } from 'history';
import createRootReducer from './root-reducer';
import rootSaga from './root-saga';

const options: BrowserHistoryBuildOptions = {};
const browserHistory = createBrowserHistory(options);

export const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: browserHistory,
});

const sagaMiddleware = createSagaMiddleware({
  // onError: (error) => console.log('Some error', error),
});

const middlewares = [routerMiddleware, sagaMiddleware];
const enhancers = [applyMiddleware(...middlewares)];

export const store: Store = createStore(
  createRootReducer(browserHistory, routerReducer),
  composeWithDevTools(...enhancers)
);

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
