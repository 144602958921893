import { useEffect, useState } from 'react';
import { Routes } from 'constants/routes.enum';
import { CorContentStackLanguagesURL } from 'constants/cor-locale.enum';

export const ALLOWED_PAGES = [Routes.HomePage, CorContentStackLanguagesURL.ENGLISH_EUROPE];

interface ILocationData {
  regionSite?: string;
  countrySite?: string;
}

export const useLocaleDetection = (locationPathname: string, locale: string, preference: string) => {
  const [locationData, setLocationData] = useState<ILocationData>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SITE_URL}`);

      if (response.ok) {
        const data = await response.json();
        setLocationData(data);
        setLoading(false);
      }
    } catch (error) {
      setError(error as any);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      (!locale && locationPathname === Routes.HomePage) ||
      (!preference && locationPathname === CorContentStackLanguagesURL.ENGLISH_EUROPE)
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPathname, locale]);

  return { locationData, loading, error };
};
