import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { omit } from 'lodash-es';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { MultiValue, SingleValue, components } from 'react-select';
import { useAddOrRemovePaymentMethodSuccesOrErrorModal } from 'hooks/use-add-or-remove-payment-method-success-or-error-modal';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ErrorNotification } from 'components/forms/error-notification';
import { ContentstackText } from 'components/contentstack';
import {
  bankRoutingAndAccountNumberValidator,
  createValidationSchema,
  nameValidator,
  requiredValidator,
  zipCodeValidator,
} from 'utils/validation';
import { useContent } from 'hooks/use-content';
import { TextField } from 'components/forms/text-field';
import { CustomDropdown, IOptionType } from 'components/custom-dropdown';
import { CorButton } from 'components/cor-button';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { addNewBankAccountEftPad, clearAddBankAccountFormEftPadErrors } from 'store/payment-methods/actions';
import { CollapsibleConsentSection } from 'components/collapsible-consent-section';
import {
  selectAddBankAccountFormEftPadServerError,
  selectBankAccountFormEftPadIsSubmitting,
} from 'store/payment-methods/selectors';
import { ModalHeader, ModalFooter } from 'components/modals/common/modal/components';
import { Countries, CountriesFullName } from 'constants/countries.enum';
import iconLock from 'assets/images/icons/icon-credit-card-loc.svg';
import { LoadingIndicator, LoadingIndicatorType } from 'components/loading-indicator';
import { useScrollToRef } from 'hooks/use-scroll-to-ref';

import './add-new-bank-account-eft-pad-modal.scss';

export interface IBankAccountFormValues {
  firstName: string;
  lastName: string;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
  city: string;
  country: string;
  address1: string;
  address2: string;
  zip: string;
  state: string;
}

export interface IAddNewBankAccountEftPadModalProps extends IModalProps {
  isOpen: boolean;
}

export const AddNewBankAccountEftPadModal: FC<IAddNewBankAccountEftPadModalProps> = ({
  isOpen,
  onClose = () => {},
}) => {
  const firstLastNameInputMaxLength = 25;
  const accountNumberMinLength = 5;
  const accountNumberMaxLength = 17;
  const addressMaxLength = 100;
  const cityMaxLength = 42;
  const zipMaxLength = 20;
  const routingNumberMinLength = 1;
  const routingNumberMaxLength = 9;

  const { getMessageText, getContentByKey } = useContent();
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const { addPaymentMethodSuccessModal, addPaymentMethodFailModal } = useAddOrRemovePaymentMethodSuccesOrErrorModal();
  const serverErrorId = useSelector(selectAddBankAccountFormEftPadServerError);
  const isAddNewEftPadAccountLoading = useSelector(selectBankAccountFormEftPadIsSubmitting);

  const contentstackPath = 'modals.0.add_new_bank_account_eft_pad_modal.0';
  const consentTextPath = `${contentstackPath}.consent_section.0.consent_text`;
  const consentCheckboxLabelPath = `${contentstackPath}.consent_section.0.checkbox_label`;
  const clientErrorMessage = getMessageText('error', 'MSG190');

  const [submitted, setSubmitted] = useState(false);
  const [isConsentChecked, setConsentStatus] = useState(false);
  const errorNotificationRef = useRef<HTMLDivElement | null>(null);

  const stateProvinceDropdownOptions = getContentByKey(`${contentstackPath}.state_province_dropdown_options.value`, []);
  const accountTypeDropdownOptions = getContentByKey(
    `${contentstackPath}.account_holder_type_dropdown_options.value`,
    []
  );

  const stateProvinceDropdownOptionsMapped = stateProvinceDropdownOptions.map(({ key }) => ({
    value: key,
    label: key,
  }));

  const accountTypeDropdownOptionsMapped = accountTypeDropdownOptions.map(({ key, value }) => ({
    value: key,
    label: value,
  }));

  const validationSchema = createValidationSchema({
    firstName: nameValidator({
      required: getMessageText('error', 'MSG004'),
      wrongFormat: getMessageText('error', 'MSG005'),
    }),
    lastName: nameValidator({
      required: getMessageText('error', 'MSG004'),
      wrongFormat: getMessageText('error', 'MSG005'),
    }),
    address1: requiredValidator(getMessageText('error_two', 'MSG204')),
    city: requiredValidator(getMessageText('error_two', 'MSG205')),
    zip: zipCodeValidator({
      required: getMessageText('error', 'MSG101'),
      wrongFormat: getMessageText('error_two', 'MSG214'),
      isUserCountryCanada: true,
    }),
    routingNumber: bankRoutingAndAccountNumberValidator({
      required: getMessageText('error', 'MSG184'),
      wrongFormat: getMessageText('error', 'MSG185'),
      min: routingNumberMinLength,
      max: routingNumberMaxLength,
    }),
    accountNumber: bankRoutingAndAccountNumberValidator({
      required: getMessageText('error', 'MSG184'),
      wrongFormat: getMessageText('error', 'MSG185'),
      min: accountNumberMinLength,
      max: accountNumberMaxLength,
    }),
  });

  const formInitialValues: IBankAccountFormValues = {
    firstName: '',
    lastName: '',
    accountNumber: '',
    routingNumber: '',
    accountType: '',
    city: '',
    country: CountriesFullName.CANADA,
    address1: '',
    address2: '',
    zip: '',
    state: '',
  };
  //
  const clearErrors = useCallback(() => {
    setSubmitted(false);

    if (serverErrorId) {
      dispatch(clearAddBankAccountFormEftPadErrors());
    }
  }, [dispatch, serverErrorId]);

  const onAddNewBankAccountFail = useCallback((messageId: string) => {
    addPaymentMethodFailModal({ messageId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConsentStatusChange = () => {
    setConsentStatus((status) => !status);
  };

  const handleNewBankAccountRequest = (values: IBankAccountFormValues) => {
    setSubmitted(true);

    dispatch(
      addNewBankAccountEftPad.request({
        ...values,
        paymentConsent: isConsentChecked,
        country: Countries.CANADA,
        onSuccessCallBack: addPaymentMethodSuccessModal,
        onFailCallBack: onAddNewBankAccountFail,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearAddBankAccountFormEftPadErrors());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useScrollToRef({ ref: errorNotificationRef, isError: serverErrorId });

  const renderOption = (className, children, props) => {
    return (
      <components.Option className={className} {...props}>
        <div className={`${className}-dropdown__item`}>{children}</div>
      </components.Option>
    );
  };

  const stateRenderOption = ({ children, ...props }: any) => {
    return renderOption('state', children, props);
  };

  const accountHolderTypeRenderOption = ({ children, ...props }: any) => {
    return renderOption('accountType', children, props);
  };

  const containsEmptyRequiredFields = (values: IBankAccountFormValues) => {
    return Object.values(omit(values, 'address2')).some((value) => !value);
  };

  const modalClassName = classNames('add-new-bank-account-modal-eft-pad-modal', {
    'add-new-bank-account-modal-eft-pad-modal--with-loading-indicator': isAddNewEftPadAccountLoading,
  });

  return (
    <>
      <Modal
        className={modalClassName}
        isOpen={isOpen}
        onClose={onClose}
        hideCloseButton={isAddNewEftPadAccountLoading}
        withBackdropClick={!isAddNewEftPadAccountLoading}
      >
        {isAddNewEftPadAccountLoading && <LoadingIndicator type={LoadingIndicatorType.FULLSIZED} />}
        <>
          <ModalHeader
            className="add-new-bank-account-modal-eft-pad-modal__header"
            titleContentstackPath={`${contentstackPath}.header`}
          />
          <div className="add-new-bank-account-modal-eft-pad-modal__wrapper">
            <div className="add-new-bank-account-modal-eft-pad-modal__description">
              <ContentstackText contentKey={`${contentstackPath}.description`} />
            </div>
            <div className="add-new-bank-account-modal-eft-pad-modal__required-fields-hint">
              <span className="add-new-bank-account-modal-eft-pad-modal__required-field">&#42;</span>
              <ContentstackText contentKey={`${contentstackPath}.required_fields_hint`} />
            </div>
            {serverErrorId && (
              <div className="grid-x" ref={errorNotificationRef}>
                <div className="cell">
                  <ErrorNotification
                    className="error-notification"
                    messageId={serverErrorId}
                    message={clientErrorMessage}
                  />
                </div>
              </div>
            )}
            <Formik
              validateOnChange={false}
              onSubmit={handleNewBankAccountRequest}
              initialValues={formInitialValues}
              validationSchema={validationSchema}
            >
              {({ dirty, isValid, handleSubmit, values, setFieldValue, setErrors, errors }) => {
                return (
                  <Form
                    className="add-new-bank-account-modal-eft-pad-modal__form"
                    onChange={(event) => {
                      clearErrors();
                      const fieldName = (event.target as HTMLInputElement).name;
                      setErrors(omit(errors, fieldName));
                    }}
                    {...{ handleSubmit, isValid, dirty }}
                  >
                    <div className="add-new-bank-account-modal-eft-pad-modal__contact-name">
                      <TextField
                        className="add-new-bank-account-modal-eft-pad-modal__field"
                        id="firstName"
                        maxLength={firstLastNameInputMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.first_name_label`, '')}
                        name="firstName"
                        placeholder={getContentByKey<string>(`${contentstackPath}.first_name_placeholder`, '')}
                        requiredField
                      />
                      <TextField
                        className="add-new-bank-account-modal-eft-pad-modal__field"
                        id="lastName"
                        maxLength={firstLastNameInputMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.last_name_label`, '')}
                        name="lastName"
                        placeholder={getContentByKey<string>(`${contentstackPath}.last_name_placeholder`, '')}
                        requiredField
                      />
                    </div>
                    <TextField
                      className="add-new-bank-account-modal-eft-pad-modal__field"
                      id="address1"
                      maxLength={addressMaxLength}
                      label={getContentByKey<string>(`${contentstackPath}.address_line_1_label`, '')}
                      name="address1"
                      placeholder={getContentByKey<string>(`${contentstackPath}.address_line_1_placeholder`, '')}
                      requiredField
                    />
                    <TextField
                      className="add-new-bank-account-modal-eft-pad-modal__field"
                      id="address2"
                      maxLength={addressMaxLength}
                      label={getContentByKey<string>(`${contentstackPath}.address_line_2_label`, '')}
                      name="address2"
                      placeholder={getContentByKey<string>(`${contentstackPath}.address_line_2_placeholder`, '')}
                    />
                    <div className="grid-x align-justify">
                      <TextField
                        className="small-8 add-new-bank-account-modal-eft-pad-modal__field add-new-bank-account-modal-eft-pad-modal__city-field"
                        id="city"
                        maxLength={cityMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.city_label`, '')}
                        name="city"
                        placeholder={getContentByKey<string>(`${contentstackPath}.city_placeholder`, '')}
                        requiredField
                      />
                      <TextField
                        className="small-4 add-new-bank-account-modal-eft-pad-modal__field"
                        id="zip"
                        maxLength={zipMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.postal_code_label`, '')}
                        name="zip"
                        placeholder={getContentByKey<string>(`${contentstackPath}.postal_code_placeholder`, '')}
                        requiredField
                      />
                    </div>
                    <div className="grid-x add-new-bank-account-modal-eft-pad-modal__country-block">
                      <TextField
                        className="small-12 medium-7 add-new-bank-account-modal-eft-pad-modal__field"
                        id="country"
                        label={getContentByKey<string>(`${contentstackPath}.country_label`, '')}
                        name="country"
                        disabled
                      />
                      <div className="small-12 medium-5 add-new-bank-account-modal-eft-pad-modal__state-block">
                        <p className="add-new-bank-account-modal-eft-pad-modal__state-label">
                          <ContentstackText contentKey={`${contentstackPath}.state_province_label`} />
                          <span className="add-new-bank-account-modal-eft-pad-modal__required-field">&#42;</span>
                        </p>
                        <CustomDropdown
                          inputId="add-new-bank-account-modal-eft-pad-modal__state-dropdown"
                          name="state"
                          items={stateProvinceDropdownOptionsMapped}
                          renderOption={stateRenderOption}
                          onChange={(stateOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
                            const state = stateOption as IOptionType;
                            setFieldValue('state', state?.value);
                          }}
                          placeholder={getContentByKey<string>(`${contentstackPath}.select_option_label`, '')}
                          className={'add-new-bank-account-modal-eft-pad-modal__state-dropdown'}
                        />
                      </div>
                    </div>
                    <TextField
                      className="add-new-bank-account-modal-eft-pad-modal__field add-new-bank-account-modal-eft-pad-modal__transit-number-field"
                      id="routingNumber"
                      maxLength={routingNumberMaxLength}
                      label={getContentByKey<string>(`${contentstackPath}.transit_number_label`, '')}
                      name="routingNumber"
                      placeholder={getContentByKey<string>(`${contentstackPath}.transit_number_placeholder`, '')}
                      requiredField
                    >
                      {isMobile && (
                        <img
                          className="add-new-bank-account-modal-eft-pad-modal__field-icon"
                          src={iconLock}
                          alt="lock icon"
                        />
                      )}
                    </TextField>
                    <div className="grid-x add-new-bank-account-modal-eft-pad-modal__account-block">
                      <TextField
                        className="small-12 medium-6 add-new-bank-account-modal-eft-pad-modal__field"
                        id="accountNumber"
                        maxLength={accountNumberMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.account_number_label`, '')}
                        name="accountNumber"
                        placeholder={getContentByKey<string>(`${contentstackPath}.account_number_placeholder`, '')}
                        requiredField
                      >
                        <img
                          className="add-new-bank-account-modal-eft-pad-modal__field-icon"
                          src={iconLock}
                          alt="lock icon"
                        />
                      </TextField>
                      <div className="small-12 medium-6 add-new-bank-account-modal-eft-pad-modal__account-holder-type-block">
                        <p className="add-new-bank-account-modal-eft-pad-modal__account-type-label">
                          <ContentstackText contentKey={`${contentstackPath}.account_holder_type_label`} />
                          <span className="add-new-bank-account-modal-eft-pad-modal__required-field">&#42;</span>
                        </p>
                        <CustomDropdown
                          inputId="add-new-bank-account-modal-eft-pad-modal__account-holder-dropdown--id"
                          name="accountType"
                          items={accountTypeDropdownOptionsMapped}
                          renderOption={accountHolderTypeRenderOption}
                          onChange={(accountHolderTypeOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
                            const accountHolderOptionType = accountHolderTypeOption as IOptionType;
                            setFieldValue('accountType', accountHolderOptionType?.value);
                          }}
                          placeholder={getContentByKey<string>(`${contentstackPath}.select_option_label`, '')}
                          className={'add-new-bank-account-modal-eft-pad-modal__account-dropdown'}
                        />
                      </div>
                    </div>
                    <div className="add-new-bank-account-modal-eft-pad-modal__field">
                      <CollapsibleConsentSection
                        isChecked={isConsentChecked}
                        onCheckedStatusChange={handleConsentStatusChange}
                        consentTextPath={consentTextPath}
                        checkboxLabelPath={consentCheckboxLabelPath}
                      />
                    </div>
                    <ModalFooter
                      className="add-new-bank-account-modal-eft-pad-modal__footer"
                      contentstackPath={contentstackPath}
                      cancelButtonHandler={onClose}
                      hasCancelButton
                    >
                      <CorButton
                        className="add-new-bank-account-modal-eft-pad-modal__save_button"
                        disabled={containsEmptyRequiredFields(values) || submitted || !isValid || !isConsentChecked}
                        type="submit"
                      >
                        <ContentstackText contentKey={`${contentstackPath}.save_button_label`} />
                      </CorButton>
                    </ModalFooter>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      </Modal>
    </>
  );
};
