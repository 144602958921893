import { all, spawn } from 'redux-saga/effects';
import getCustomerQuotesSaga from './get-quotes';
import requestQuoteSaga from './request-quote';
import getSkuInfoSaga from './get-sku-info';
import getInternalUserQuotesSaga from './get-internal-user-quotes';
import cancelQuoteSaga from './cancel-quote';
import reviewQuoteSaga from './review-quote';
import approveQuoteSaga from './approve-quote';
import getDeliveryDatesSaga from './get-delivery-dates';
import rejectQuoteSaga from './reject-quote';
import acceptQuoteSaga from './accept-quote';
import submitQuoteOrder from './submit-quote-order';
import dismissQuoteSaga from './dismiss-quote';
import getQuotesRequestedSaga from './get-quotes-requested';

export default function* quotesSagas() {
  yield all([
    spawn(getCustomerQuotesSaga),
    spawn(requestQuoteSaga),
    spawn(getSkuInfoSaga),
    spawn(getInternalUserQuotesSaga),
    spawn(cancelQuoteSaga),
    spawn(reviewQuoteSaga),
    spawn(approveQuoteSaga),
    spawn(getDeliveryDatesSaga),
    spawn(rejectQuoteSaga),
    spawn(acceptQuoteSaga),
    spawn(submitQuoteOrder),
    spawn(dismissQuoteSaga),
    spawn(getQuotesRequestedSaga),
  ]);
}
