import { all, spawn } from 'redux-saga/effects';
import getInvoicesSummarySaga from './get-invoice-summary';
import getInvoicesSaga from './get-invoices';
import getSurchargeSummarySaga from './get-surcharges';
import getInvoicesTotalAmountSaga from './get-invoices-total-amount';

export default function* invoicesSaga() {
  yield all([
    spawn(getInvoicesSummarySaga),
    spawn(getInvoicesSaga),
    spawn(getSurchargeSummarySaga),
    spawn(getInvoicesTotalAmountSaga),
  ]);
}
