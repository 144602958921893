import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import { IInvoiceItem } from 'types/invoices';
import types from './action-types';

export const getInvoicesActionsConstants = createConstants(types.GET_INVOICES);
export const getInvoicesSummaryActionConstants = createConstants(types.GET_INVOICES_SUMMARY);
export const getInvoicesTotalAmountActionConstants = createConstants(types.GET_INVOICES_TOTAL_AMOUNT);
export const getSurchargeActionConstants = createConstants(types.GET_SURCHARGE);

export const getInvoices = createRequestActions(getInvoicesActionsConstants);
export const getInvoicesSummary = createRequestActions(getInvoicesSummaryActionConstants);
export const getInvoicesTotalAmount = createRequestActions(getInvoicesTotalAmountActionConstants);
export const getSurcharge = createRequestActions(getSurchargeActionConstants);

export const setSelectedInvoices = (selectedInvoices: IInvoiceItem[]) =>
  action(types.SET_SELECTED_INVOICES, { selectedInvoices });

export const setInvoiceChangeReason = ({ originalInvoiceNumber, adjustmentReason }) =>
  action(types.SET_INVOICE_CHANGE_REASON, { originalInvoiceNumber, adjustmentReason });

export const updateConfirmedBalance = ({ originalInvoiceNumber, confirmedBalance }) =>
  action(types.UPDATE_CONFIRMED_BALANCE, { originalInvoiceNumber, confirmedBalance });

export const setDefaultConfirmedBalance = ({ originalInvoiceNumber }) =>
  action(types.SET_DEFAULT_CONFIRMED_BALANCE, { originalInvoiceNumber });

export const changePaginationCurrent = (current: number) => {
  return action(types.INVOICES_PAGINATION_CHANGE, {
    current,
  });
};

export const setSelectedPaymentMethod = ({ paymentMethod }) =>
  action(types.SET_SELECTED_PAYMENT_METHOD, { paymentMethod });

export const resetPaymentSummaryCalculations = () => {
  return action(types.RESET_PAYMENT_SUMMARY_CALCULATIONS);
};
