import './cor-cards-carousel-full-width.scss';

import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ICorFeaturedContentCardsVariant } from 'corporate-components/cor-featured-content/cor-featured-content.interface';

const CorCardsCarouselFullWidth = ({ children }: React.PropsWithChildren<ICorFeaturedContentCardsVariant>) => {
  return (
    <div className="grid-container">
      <Swiper
        modules={[Navigation]}
        slidesPerView={5}
        spaceBetween={50}
        navigation={true}
        loop={true}
        className="cor-featured-content__cards-carousel-full-width"
      >
        {!!children &&
          React.Children.map(children, (child: React.ReactNode, index) => (
            <SwiperSlide key={index.toString()}>{child}</SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default React.memo(CorCardsCarouselFullWidth);
