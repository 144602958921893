import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getProductRecommendations, getProductRecommendationsActionConstants } from 'store/shopping-cart/actions';

export function* getProductRecommendationsHandler() {
  yield put(doRequest(getProductRecommendations, Endpoints.PRODUCT_RECOMMENDATIONS, 'get'));
}

export default function* getProductRecommendationsSaga() {
  yield takeLatest(getProductRecommendationsActionConstants[Actions.REQUEST], getProductRecommendationsHandler);
}
