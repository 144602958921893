import { FC } from 'react';
import { ContentstackImage } from 'components/contentstack';

import './basic-header.scss';

export const BasicHeader: FC = () => {
  return (
    <div className="basic-header">
      <div className="grid-container">
        <div className="grid-x align-justify align-middle">
          <ContentstackImage contentKey="header[0].logo" />
        </div>
      </div>
    </div>
  );
};
