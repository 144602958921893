import { all, spawn } from 'redux-saga/effects';
import getCartInfo from 'store/shopping-cart/sagas/get-cart-info';
import addProductToCartSaga from 'store/shopping-cart/sagas/add-product-to-cart';
import addMultipleProductsToCartSaga from 'store/shopping-cart/sagas/add-multiple-products-to-cart';
import setCartMessageViewedSaga from './set-cart-message-viewed';
import changeItemQuantitySaga from './change-item-quantity';
import setItemQuantitySaga from './set-item-quantity';
import deleteCartItemSaga from './delete-cart-item';
import deleteAllCartItemsSaga from './delete-all-cart-items';
import reorderProductsToCartSaga from './reorder-products-to-cart';
import getProductRecommendationsSaga from './get-product-recommendations';
import getPromotionCouponCodeSaga from './get-promotion-coupon-code';
import deletePromotionCouponCodeSaga from './delete-promotion-coupon-code';

export default function* shoppingCartSagas() {
  yield all([
    spawn(getCartInfo),
    spawn(addProductToCartSaga),
    spawn(setCartMessageViewedSaga),
    spawn(changeItemQuantitySaga),
    spawn(setItemQuantitySaga),
    spawn(deleteCartItemSaga),
    spawn(deleteAllCartItemsSaga),
    spawn(reorderProductsToCartSaga),
    spawn(addMultipleProductsToCartSaga),
    spawn(getProductRecommendationsSaga),
    spawn(getPromotionCouponCodeSaga),
    spawn(deletePromotionCouponCodeSaga),
  ]);
}
