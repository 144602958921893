import { FC } from 'react';
import { isEmpty, size } from 'lodash-es';
import { useContent } from 'hooks/use-content';
import { ProductsCarousel } from 'components/products-carousel';
import { ContentstackLink, IContentstackLink } from 'components/contentstack';
import { useFetchProductSolutionCarouselItems } from 'hooks/use-fetch-product-solution-carousel-items';

import './product-solution-carousel.scss';

export interface IProductSku {
  sku: {
    product_sku: string;
  };
}

export const ProductSolutionCarousel: FC = () => {
  const { getContentByKey } = useContent();
  const productsSkus = getContentByKey<(IProductSku | never)[]>('product_solution_carousel.product_skus', []);
  const autoplaySpeed = getContentByKey('product_solution_carousel.carousel_autoplay_speed', 5000);
  const seeMoreLink: IContentstackLink = getContentByKey('product_solution_carousel.see_more_link_text', {});
  const skuList = productsSkus.map((skuItem) => skuItem.sku);

  const { carouselProducts } = useFetchProductSolutionCarouselItems(skuList);

  if (isEmpty(carouselProducts) || size(carouselProducts) < 4) {
    return null;
  }

  return (
    <div className="product-solution-carousel grid-container grid-y">
      <ProductsCarousel
        // since we can potentially have more than 1 carousel need to discuss it with proton
        id="productSolutionCarousel"
        title={getContentByKey<string>('product_solution_carousel.section_title', '')}
        products={carouselProducts}
        contentStackPath="product_solution_carousel.product_item[0]"
        autoplay
        autoplaySpeed={autoplaySpeed}
      />
      {seeMoreLink?.href && (
        <ContentstackLink
          className="product-solution-carousel__see-more"
          contentKey="product_solution_carousel.see_more_link_text"
        />
      )}
    </div>
  );
};
