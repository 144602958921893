import { FC, ReactNode } from 'react';

import './product-label.scss';

export interface IProductLabelProps {
  variant: string;
  children?: ReactNode;
}

export const ProductLabel: FC<IProductLabelProps> = ({ variant, children, ...restProps }) => {
  return (
    <div className={`product-label product-label--${variant}`} {...restProps}>
      <span className="product-label__title">{children}</span>
    </div>
  );
};
