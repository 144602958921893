import { FC } from 'react';
import { isUndefined, isEmpty } from 'lodash-es';
import { ContentstackText } from 'components/contentstack';
import { formatCurrency } from 'utils/currency-format';
import { IDiscounts } from 'types/order-details';
import { DiscountExpandableSection } from 'components/discount-expandable-section';

import './order-summary-discount.scss';

interface IOrderSummaryDiscount {
  discountsExtended?: IDiscounts;
  totalDiscounts?: string;
  contentstackRootPath: string;
}

export const OrderSummaryDiscount: FC<IOrderSummaryDiscount> = ({
  discountsExtended,
  totalDiscounts,
  contentstackRootPath,
}) => {
  const discountTitle = <ContentstackText contentKey={`${contentstackRootPath}.discount_title`} />;

  if (isUndefined(discountsExtended))
    return (
      <div className="order-summary-discount-tbd">
        <span>
          <ContentstackText contentKey={`${contentstackRootPath}.promotion_label`} />
        </span>
        <span>
          <ContentstackText contentKey={`${contentstackRootPath}.price_estimated`} />
        </span>
      </div>
    );

  if (isEmpty(discountsExtended)) return null;

  return (
    <div className="order-summary-discount">
      {totalDiscounts && (
        <DiscountExpandableSection title={discountTitle} discount={totalDiscounts}>
          {discountsExtended &&
            Object.keys(discountsExtended).map((key) => {
              return (
                <div className="order-summary-discount__item" key={key}>
                  <p className="order-summary-discount__item-name">{key}:</p>
                  <p className="order-summary-discount__item-price">-{formatCurrency(discountsExtended[key])}</p>
                </div>
              );
            })}
        </DiscountExpandableSection>
      )}
    </div>
  );
};
