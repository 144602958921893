import './cor-card-with-publication-date.scss';

import { CorTypography } from 'corporate-ui';
import React from 'react';
import { CorContentstackImage, CorContentstackLink } from 'corporate-components/cor-contentstack';
import {
  ICorFeaturedContentCardVariant,
  ICorFeaturedContentEntry,
} from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { useContent } from 'hooks/use-content';

const CorCardWithPublicationDate = ({ contentKeyBase }: ICorFeaturedContentCardVariant) => {
  const { getContentByKey } = useContent();
  const entry: ICorFeaturedContentEntry = getContentByKey(contentKeyBase, {});

  return (
    <CorContentstackLink
      className="cor-featured-content__card-with-publication-date cor-featured-content__card cor-featured-content__card--medium-size"
      contentKey={`${contentKeyBase}.pageUrl`}
      fallbackElement="div"
    >
      <div className="cor-featured-content__card-with-publication-date__image__container cor-featured-content__card__image__container">
        {!!entry?.image && (
          <CorContentstackImage
            contentKey={`${contentKeyBase}.image`}
            className="cor-featured-content__card-with-publication-date__image cor-featured-content__card__image"
          />
        )}
      </div>

      {(!!entry?.category || !!entry?.title || !!entry?.description || !!entry?.publicationDate) && (
        <div className="cor-featured-content__card-with-publication-date__description">
          {!!entry?.category && (
            <CorTypography
              variant="small-caps"
              color="dawn-red"
              className="cor-featured-content__card-with-publication-date__description__category"
              data-testid="cor-featured-content__card-with-publication-date__category"
            >
              {entry?.category}
            </CorTypography>
          )}

          {!!entry?.title && (
            <CorTypography
              variant="h4"
              className="cor-featured-content__card-with-publication-date__description__title"
              data-testid="cor-featured-content__card-with-publication-date__title"
            >
              {entry?.title}
            </CorTypography>
          )}

          {!!entry?.description && (
            <CorTypography
              variant="body-regular"
              color="mid-gray"
              className="cor-featured-content__card-with-publication-date__description__description"
              data-testid="cor-featured-content__card-with-publication-date__description"
            >
              {entry?.description}
            </CorTypography>
          )}

          {!!entry?.publicationDate && (
            <CorTypography
              variant="body-small"
              color="mid-gray"
              className="cor-featured-content__card-with-publication-date__description__publication-date"
              data-testid="cor-featured-content__card-with-publication-date__publication-date"
            >
              {entry?.publicationDate}
            </CorTypography>
          )}
        </div>
      )}
    </CorContentstackLink>
  );
};

export default React.memo(CorCardWithPublicationDate);
