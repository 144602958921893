import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getSkuReplacementsActionConstants = createConstants(types.GET_SKU_REPLACEMENTS);
export const getDiscontinuedItemsReplacementsActionConstants = createConstants(
  types.GET_DISCONTINUED_ITEMS_REPLACEMENTS
);

export const getSkuReplacements = createRequestActions(getSkuReplacementsActionConstants);
export const getDiscontinuedItemsReplacements = createRequestActions(getDiscontinuedItemsReplacementsActionConstants);

export const clearSkuReplacements = () => action(types.CLEAR_SKU_REPLACEMENTS);
export const clearDiscontinuedItemsReplacements = () => action(types.CLEAR_DISCONTINUED_ITEMS_REPLACEMENTS);
