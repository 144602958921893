import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { useContent } from 'hooks/use-content';
import { RecentOrders } from 'features/order-updates/components/recent-orders';
import { BuyItAgain } from 'features/order-updates/components/buy-it-again';
import { ShoppingList } from 'features/order-updates/components/shopping-list';
import {
  selectBuyItAgainProductsLoading,
  selectHomeShoppingListsLoading,
  selectRecentOrdersInfoLoading,
} from 'store/home/selectors';
import { selectIsPickupAccountUser } from 'store/auth/selectors';
import {
  selectIsOrderCutoffNotificationVisible,
  selectIsOrderCutoffNotificationExpired,
  selectHasNextOrderDueDate,
} from 'store/order-cut-off/selectors';
import { LoadingIndicator } from 'components/loading-indicator';
import { NewsSection } from 'features/order-updates/components/news-section';
import { NextOrderMessage } from 'features/order-updates/components/next-order-message';
import { OrderPickupMessage } from 'features/order-updates/components/order-pickup-message/order-pickup-message';
import { OrderCutoffNotification } from 'components/order-cutoff-notification';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { TabsScrollable } from 'components/tabs-scrollable';

import './order-updates.scss';

export const OrderUpdates: FC = () => {
  const { isMobile, isDesktop } = useBreakpoint();
  const isPickupAccount = useSelector(selectIsPickupAccountUser);
  const isCutoffNotificationVisible = useSelector(selectIsOrderCutoffNotificationVisible);
  const isCutoffNotificationExpired = useSelector(selectIsOrderCutoffNotificationExpired);
  const hasNextOrderDueDate = useSelector(selectHasNextOrderDueDate);

  const recentOrdersInfoLoading = useSelector(selectRecentOrdersInfoLoading);
  const buyItAgainProductsLoading = useSelector(selectBuyItAgainProductsLoading);
  const shoppingListsLoading = useSelector(selectHomeShoppingListsLoading);
  const { getContentByKey } = useContent();
  const news = getContentByKey('page_content.news_section.news_and_notifications_list.0.news_and_notifications', []);
  const tabs = getContentByKey('home_tabs', []);

  const [selectedTab, setSelectedTab] = useState<string>('');

  useEffect(() => {
    if (!isEmpty(tabs)) {
      setSelectedTab(tabs[0]['tab']['tab_key']);
    }
  }, [tabs]);

  const tabsMap = {
    recent_orders: <RecentOrders />,
    buy_it_again: <BuyItAgain />,
    shopping_list: <ShoppingList />,
  };

  const tabsList = tabs.map((item) => {
    const tabKey = item['tab']['tab_key'];

    return {
      isSelected: selectedTab === tabKey,
      isDisabled: recentOrdersInfoLoading || buyItAgainProductsLoading || shoppingListsLoading,
      text: isMobile && item['tab']['title_mobile'] ? item['tab']['title_mobile'] : item['tab']['title'],
      clickHandler: () => setSelectedTab(tabKey),
    };
  });

  const orderUpdatesContentClasses = classNames('order-updates__content small-12', {
    'medium-8': !isEmpty(news) || hasNextOrderDueDate || isPickupAccount,
  });

  return (
    <div
      className={classNames('order-updates grid-x', {
        'order-updates--with-cutoff': isCutoffNotificationVisible,
        'order-updates--with-cutoff-expired': isCutoffNotificationVisible && isCutoffNotificationExpired,
      })}
    >
      <div className={orderUpdatesContentClasses}>
        {isMobile && <OrderCutoffNotification page={AccessDefinitions.HOME_ACTIONS} />}
        {isMobile && isPickupAccount && <OrderPickupMessage />}
        {isMobile && !isPickupAccount && hasNextOrderDueDate && <NextOrderMessage />}
        <TabsScrollable tabsList={tabsList} />
        {recentOrdersInfoLoading || buyItAgainProductsLoading || shoppingListsLoading ? (
          <div className="order-updates__loading-indicator-wrapper">
            <LoadingIndicator />
          </div>
        ) : (
          tabsMap[selectedTab]
        )}
      </div>
      <div
        className={classNames('small-12 medium-4 order-updates__notifications-section', {
          'order-updates__notifications-section--standard':
            !isCutoffNotificationExpired && !isCutoffNotificationVisible && !isPickupAccount,
          'order-updates__notifications-section--with-cutoff': isCutoffNotificationVisible,
          'order-updates__notifications-section--with-cutoff-expired':
            isCutoffNotificationVisible && isCutoffNotificationExpired,
          'order-updates__notifications-section--with-pickup-notifications': isPickupAccount,
          'order-updates__notifications-section--without-news': isEmpty(news),
        })}
      >
        {isDesktop && <OrderCutoffNotification page={AccessDefinitions.HOME_ACTIONS} />}
        {isDesktop && isPickupAccount && <OrderPickupMessage fullSize={isEmpty(news)} />}
        {isDesktop && !isPickupAccount && hasNextOrderDueDate && <NextOrderMessage fullSize={isEmpty(news)} />}
        {!isEmpty(news) && <NewsSection />}
      </div>
    </div>
  );
};
