import { FC, useEffect, useState, createRef } from 'react';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { onNestedLinkClick } from 'utils/link-utils';
import { CorButton } from 'components/cor-button';

export interface INewsItemDescriptionProps {
  content: string;
  updateSelected?: () => void;
}

const stripHtml = (html: string) => {
  const noTags = html?.replace(/(<([^>]+)>)/gi, '');
  return noTags?.replace(/&nbsp;/gi, ' ');
};

export const NewsItemDescription: FC<INewsItemDescriptionProps> = ({ content, updateSelected = () => undefined }) => {
  const ref = createRef<HTMLDivElement>();
  const [hasHiddenContent, setHasHiddenContent] = useState(false);
  const noTagsDescription = stripHtml(content);
  const handleClick = () => {
    updateSelected();
  };

  useEffect(() => {
    if (!ref?.current) return;
    setHasHiddenContent(ref.current.scrollHeight > ref.current.offsetHeight);
    const button = ref.current.parentElement?.querySelector('button');

    if (hasHiddenContent) {
      button?.classList.remove('hidden');
    } else {
      button?.classList.add('hidden');
    }
  }, [ref, hasHiddenContent]);

  return (
    <div className="news-section__item-description">
      <div
        ref={ref}
        title={noTagsDescription}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
        onClick={onNestedLinkClick}
      />
      <CorButton className="text news-section__expand-button" onClick={handleClick}>
        <ContentstackText contentKey={'page_content.news_section.more_button'} />
      </CorButton>
    </div>
  );
};
