import './cor-hero-container.scss';
import { CorContentstackImageAsset } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { CorContentstackVideoAsset } from 'corporate-utils/cor-contentstack-video-link-resolver';
import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import {
  CorContentstackVideo,
  IReactPlayerYoutubeConfigVars,
} from 'corporate-components/cor-contentstack/cor-contentstack-video';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';
import { useBreakpoint } from 'hooks/use-breakpoint';

export type AssetType = CorContentstackVideoAsset | CorContentstackImageAsset | undefined;

export type HeroOverlay = 'left' | 'center' | 'right' | undefined;

export type HeroOverlayColor = 'light' | 'dark' | undefined;

export type HeroContainerSize = 'large' | 'medium' | 'small' | 'inset';

export interface IHeroContainerProps extends PropsWithChildren {
  asset: AssetType;
  size: HeroContainerSize;
  overlay?: HeroOverlay;
  overlayColor?: HeroOverlayColor;
  isInset: boolean;
}

const RenderInsetVideo: FC<{
  isMobile: boolean;
  asset: CorContentstackVideoAsset;
}> = ({ isMobile, asset }) => {
  return (
    <div data-testid={'cor-hero-container__video-inset-wrapper'}>
      {isMobile && (
        <img
          src={`${asset.fallbackImage}?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}`}
          alt={asset.title}
          className={'cor-hero-container__image'}
        />
      )}
      {!isMobile && (
        <div className={'cor-hero-container__video-wrapper'}>
          <CorContentstackVideo data={asset} showControls={true} customClass="cor-hero-container__video__foreground" />
        </div>
      )}{' '}
    </div>
  );
};

export const CorHeroContainer: FC<IHeroContainerProps> = ({
  children,
  asset,
  size,
  overlay,
  overlayColor,
  isInset,
}) => {
  const { isMobile } = useBreakpoint();

  const isImageAsset = asset && 'link_url' in asset;
  const isVideoAsset = asset && 'mute' in asset;
  const assetCSSModifier = isImageAsset
    ? 'cor-hero-container--image'
    : isVideoAsset
    ? 'cor-hero-container--video'
    : null;
  const sizeCSSModifier = `cor-hero-container--${size}`;
  const overlayCSSModifier = overlay
    ? `cor-hero-container--overlay ${
        isInset ? 'cor-hero-container--overlay-inset' : ''
      } cor-hero-container--overlay-${overlay}`
    : null;
  const overlayColorCSSModifier =
    overlay && overlayColor ? `cor-hero-container--overlay cor-hero-container--overlay-color-${overlayColor}` : null;
  const youtubeConfig: IReactPlayerYoutubeConfigVars | null =
    isVideoAsset && asset.isYoutube
      ? {
          autoplay: 1,
          controls: isMobile ? 1 : 0,
        }
      : null;

  return (
    <div
      data-testid={'cor-hero-container'}
      className={classNames('cor-hero-container', assetCSSModifier, sizeCSSModifier)}
    >
      {overlayCSSModifier && <div className={classNames(overlayCSSModifier, overlayColorCSSModifier)} />}

      {isVideoAsset && size === 'inset' && <RenderInsetVideo asset={asset} isMobile={isMobile} />}

      {isVideoAsset && size !== 'inset' && (
        <div className={'cor-hero-container__video-wrapper'}>
          <CorContentstackVideo
            data={asset}
            customClass="cor-hero-container__video__background"
            youtubeConfig={youtubeConfig}
            isBackground={!isMobile}
          />
        </div>
      )}

      {!isVideoAsset && isImageAsset && (
        <img className={'cor-hero-container__image'} src={asset?.url} alt={asset?.image_alt_text || ''} />
      )}
      {children}
    </div>
  );
};
