import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { ICategoriesNavigationState } from './reducer';

export const selectCategoriesNavigationEntity = (state: IState): ICategoriesNavigationState =>
  state.app.categoriesNavigation;
export const selectCategoriesNavigation = createSelector(
  selectCategoriesNavigationEntity,
  ({ categories }) => categories
);
export const selectCategoriesNavigationLoading = createSelector(
  selectCategoriesNavigationEntity,
  ({ loading }) => loading
);
export const selectNewArrivalVisibility = createSelector(
  selectCategoriesNavigationEntity,
  ({ showNewArrivals }) => showNewArrivals
);
