import { FC } from 'react';
import { IModalProps } from 'components/modals/common/modal';
import { ContentstackLink, ContentstackMessage } from 'components/contentstack';
import { SuccessErrorMessageModal } from 'components/modals/success-error-message-modal';

import './request-quote-success-message-modal.scss';

export const RequestQuoteSuccessMessageModal: FC<IModalProps> = ({ isOpen, onClose }) => {
  return (
    <SuccessErrorMessageModal
      isOpen={isOpen}
      onClose={onClose}
      type="success"
      messageId="MSG085"
      modalBodyClassname="request-quote-success-message"
    >
      <ContentstackMessage type="success" messageId="MSG086" />{' '}
      <ContentstackLink contentKey="modals.0.request_quote_success_modal.0.content_link" />
    </SuccessErrorMessageModal>
  );
};
