import { FC } from 'react';
import { get, template } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';

export interface IContentstackTextComponentProps {
  contentKey: string;
  interpolateParams?: object;
}

export const ContentstackText: FC<IContentstackTextComponentProps> = ({ contentKey, interpolateParams = {} }) => {
  const content = useContentstackContent();
  const text = get(content, contentKey, '');
  const compiled = template(text);
  return <>{compiled(interpolateParams)}</>;
};
