import { put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { doRequest } from 'store/api/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { getPromotionCouponCode, getPromotionCouponCodeConstants } from '../actions';

export interface IGetPromotionCouponCodePayload {
  discountCode: string;
}

export function* getPromotionCouponCodeSagaHandler({
  payload: { discountCode },
}: IAction<IGetPromotionCouponCodePayload>) {
  yield put(doRequest(getPromotionCouponCode, `${Endpoints.CART_DISCOUNTS}`, 'patch', { discountCode }));
}

export default function* getPromotionCouponCodeSaga() {
  yield takeLatest(getPromotionCouponCodeConstants[Actions.REQUEST], getPromotionCouponCodeSagaHandler);
}
