import { FC, ReactNode } from 'react';
import { Field, ErrorMessage, useField, FieldAttributes } from 'formik';
import classNames from 'classnames';
import { CustomTooltip } from 'components/custom-tooltip';

import './text-field.scss';

export interface ITextField extends FieldAttributes<any> {
  name: string;
  type?: string;
  label?: string;
  description?: string;
  adornment?: string;
  requiredField?: boolean;
  literal?: boolean;
  withHint?: boolean;
  className?: string;
  renderHintContent?: () => ReactNode;
  highlight?: boolean;
  fromPayments?: boolean;
}

export const TextField: FC<ITextField> = ({
  label,
  description,
  adornment,
  type = 'text',
  requiredField = false,
  literal = false,
  withHint = false,
  renderHintContent,
  className,
  children,
  highlight,
  fromPayments,
  ...props
}) => {
  const [field, meta] = useField(props);

  const textFieldClass = classNames('text-field', className, {
    'text-field--error': meta.error && meta.touched,
    'text-field--literal': literal,
  });

  const inputGroupClass = classNames('text-field__input', {
    'text-field__input-highlight': highlight,
  });

  const renderInput = () => {
    return adornment || literal ? (
      <div className="text-field__input-decorated">
        <span className="text-field__input-adornment">{adornment}</span>
        <Field
          type={type}
          className="text-field__input"
          disabled={literal}
          value={field.value}
          data-bluesnap={fromPayments && field?.name}
          {...props}
        />
      </div>
    ) : (
      <div className="text-field__input-group">
        <Field type={type} className={inputGroupClass} data-bluesnap={fromPayments && field?.name} {...props} />
        {children}
      </div>
    );
  };

  return (
    <div className={textFieldClass}>
      {label && (
        <label className="text-field__label" htmlFor={field.name}>
          {label}
          {requiredField && !literal && <span className="text-field__label-asterisk">&#42;</span>}
          {withHint && renderHintContent && (
            <CustomTooltip tooltipId={`${field.name}-tooltip`}>{renderHintContent()}</CustomTooltip>
          )}
        </label>
      )}
      {renderInput()}
      <ErrorMessage className="text-field__error-message" name={field.name} component="p" />
      {description && <p className="text-field__description">{description}</p>}
    </div>
  );
};
