import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { changeShoppingListsOrder, changeShoppingListsOrderActionConstants } from 'store/shopping-lists/actions';
import { IAction } from 'types/actions';

export interface IChangeShoppingListsOrderSagaHandlerPayload {
  currentPage: string;
  newPosition: string;
  quantityOnPage: string;
  shoppingListId: string;
  onSuccessCallback?: () => void;
}

export function* changeShoppingListsOrderSagaHandler({
  payload,
}: IAction<IChangeShoppingListsOrderSagaHandlerPayload>) {
  yield put(
    doRequest(changeShoppingListsOrder, `${Endpoints.SHOPPING_LISTS}/position`, 'patch', {
      ...payload,
    })
  );
}

export function* changeShoppingListsOrderOnSuccessCallBackSagaHandler({
  payload,
}: IAction<IChangeShoppingListsOrderSagaHandlerPayload>) {
  yield take(changeShoppingListsOrderActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export default function* changeShoppingListsOrderSaga() {
  yield takeLatest(changeShoppingListsOrderActionConstants[Actions.REQUEST], changeShoppingListsOrderSagaHandler);
  yield takeLatest(
    changeShoppingListsOrderActionConstants[Actions.REQUEST],
    changeShoppingListsOrderOnSuccessCallBackSagaHandler
  );
}
