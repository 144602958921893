import { FC } from 'react';
import classNames from 'classnames';

import './custom-checkbox.scss';

export interface ICustomCheckboxProps {
  label?: string;
  selected?: boolean;
  onCheckboxClick: () => void;
  disabled?: boolean;
  className?: string;
  id?: string;
  ariaLabel?: string;
}

export const CustomCheckbox: FC<ICustomCheckboxProps> = ({
  label,
  selected = false,
  disabled = false,
  className,
  onCheckboxClick,
  id,
  ariaLabel,
}) => {
  const checkboxFieldClass = classNames(
    'custom-checkbox',
    {
      'custom-checkbox--checked': false,
    },
    className
  );

  return (
    <div className={checkboxFieldClass}>
      <label className="custom-checkbox__label">
        <input
          type="checkbox"
          className="custom-checkbox__input"
          checked={selected}
          disabled={disabled}
          onChange={onCheckboxClick}
          id={id}
          name={id}
          data-testid={id}
          aria-label={label || ariaLabel}
        />
        <span className="custom-checkbox__checkmark" />
        <span className="custom-checkbox__label-text">{label}</span>
      </label>
    </div>
  );
};
