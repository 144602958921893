import './cor-cards-with-title-mobile.scss';

import React from 'react';
import classNames from 'classnames';
import { ICorFeaturedContentCardsVariant } from 'corporate-components/cor-featured-content/cor-featured-content.interface';

const CorCards = ({ children }: React.PropsWithChildren<ICorFeaturedContentCardsVariant>) => {
  return (
    <div className={classNames('cor-featured-content__cards-with-title-mobile', 'grid-container')}>{children}</div>
  );
};

export default React.memo(CorCards);
