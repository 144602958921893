import { ICSBrandFolderImage, ICSImage, ICSLink } from 'types/contentstack';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';
import { contentStackImageTifExtensionHelper, isTifExtension } from './contentstack-image-helper';

export type contentstackImageAsset = {
  url: string;
  link_url?: ICSLink;
  image_alt_text?: string;
  open_in_a_new_tab?: boolean;
};

export const contentstackImageLinkResolver = (image: ICSImage): contentstackImageAsset | undefined => {
  const { image: contentstackImage, brandfolder_image } = image;
  const hasBrandFolderImage: ICSBrandFolderImage[] = brandfolder_image && Array.isArray(brandfolder_image);
  const hasContentStackImage = contentstackImage?.url;

  if (!hasContentStackImage && !hasBrandFolderImage) {
    return undefined;
  }

  if (hasBrandFolderImage && brandfolder_image?.[0]?.cdn_url) {
    if (isTifExtension(brandfolder_image[0].extension)) {
      return {
        ...image,
        url: contentStackImageTifExtensionHelper(brandfolder_image[0].cdn_url, `quality=${IMAGES_COMPRESSION_LEVEL}`),
      };
    }

    return {
      ...image,
      url: `${brandfolder_image[0].cdn_url}?format=${IMAGES_FORMAT}&quality=${IMAGES_COMPRESSION_LEVEL}`,
    };
  }

  if (hasContentStackImage) {
    return {
      ...image,
      url: `${contentstackImage.url}?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}`,
    };
  }

  return undefined;
};
