import { FC } from 'react';
import { CorButton } from 'components/cor-button';
import { Modal } from 'components/modals/common/modal';
import { ModalHeader, ModalFooter } from 'components/modals/common/modal/components';
import { ContentstackText } from 'components/contentstack/contentstack-text';

import './delete-shopping-list-confirm-modal.scss';

export interface IDeleteShoppingListConfirmModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  listName?: string;
}

export const DeleteShoppingListConfirmModal: FC<IDeleteShoppingListConfirmModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  listName = '',
}) => {
  const contentstackPath = 'modals.0.delete_shopping_list_confirmation_modal.0';

  return (
    <Modal className="delete-shopping-list-modal" isOpen={isOpen} size="small" onClose={onClose} withBackdropClick>
      <ModalHeader>
        <h2 className="delete-shopping-list-modal__title">
          <ContentstackText contentKey={`${contentstackPath}.message`} interpolateParams={{ listName }} />
        </h2>
      </ModalHeader>
      <ModalFooter
        className="delete-shopping-list-modal__footer"
        contentstackPath={contentstackPath}
        cancelButtonHandler={onClose}
        hasCancelButton
      >
        <CorButton color="primary delete-shopping-list-modal__delete-button" onClick={onConfirm}>
          <ContentstackText contentKey={`${contentstackPath}.delete_shopping_list_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
