import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { acceptQuote, acceptQuoteActionConstants } from 'store/quotes/actions';
import { IAction } from 'types/actions';

export interface IAcceptQuotePayload {
  quoteNumber: string;
}

export function* acceptQuoteSagaHandler({ payload: { quoteNumber } }: IAction<IAcceptQuotePayload>) {
  yield put(doRequest(acceptQuote, `${Endpoints.QUOTES}/${quoteNumber}/accept`, 'post'));
}

export default function* acceptQuoteSaga() {
  yield takeLatest(acceptQuoteActionConstants[Actions.REQUEST], acceptQuoteSagaHandler);
}
