import { useState, useEffect } from 'react';
import { throttle } from 'lodash-es';

export const BreakpointsConfig = {
  largeDesktop: 1200,
  customTouchDevice: 1024,
  desktop: 960,
};

export const getDeviceBreakpoint = (width: number) => ({
  isMobile: width < BreakpointsConfig.desktop,
  isDesktop: width >= BreakpointsConfig.desktop,
  isLargeDesktop: width >= BreakpointsConfig.largeDesktop,
  isCustomTouchDevice: width >= BreakpointsConfig.customTouchDevice,
});

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getDeviceBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleWindowResize = throttle(() => {
      setBreakpoint(getDeviceBreakpoint(window.innerWidth));
    }, 250);

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('orientationchange', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('orientationchange', handleWindowResize);
    };
  }, []);

  return breakpoint;
};
