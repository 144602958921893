import { CorSmallCardTitleCategory } from 'corporate-components/cor-cards';
import React from 'react';
import { COR_PAGINATION_PAGE_RANGE_TO_DISPLAY } from 'corporate-constants/pagination.constants';
import { useAlgoliaQueryParams } from 'hooks';
import { useContent } from 'hooks/use-content';
import { ContentstackContext } from 'context/contentstack';
import { Pagination } from 'components/pagination';
import { ICorFilteredListCards } from './cor-filtered-list-cards.interface';

const PREFIX_KEY = 'content_list.content_or_filtered_list.0';
const LABELS_KEY = `${PREFIX_KEY}.labels.0.key_value_pair.value`;

const CorFilteredListCards = ({ algoliaMetaData, algoliaCards }: ICorFilteredListCards) => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const { getLabelsByKey } = useContent();
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});

  const handlePageChange = React.useCallback(
    (selectedItem: { selected: number }) => {
      setQueryParams({ page: selectedItem?.selected + 1 });
    },
    [setQueryParams]
  );

  return (
    <>
      <ContentstackContext.Provider value={{ entries: algoliaCards }}>
        <div className="cor-filtered-list__cards">
          {algoliaCards?.map((_, index) => (
            <CorSmallCardTitleCategory
              cardVariant="small-card-title-category-h3"
              contentKeyBase={`entries.${index}`}
              key={index.toString()}
            />
          ))}
        </div>
      </ContentstackContext.Provider>
      {(algoliaMetaData?.page?.total_pages || 0) > 1 && (
        <ContentstackContext.Provider
          value={{
            labels: {
              pagination: {
                previous_button_label: labels?.pagination_back || 'Back',
                next_button_label: labels?.pagination_next || 'Next',
              },
            },
          }}
        >
          <Pagination
            currentPage={(queryParams?.page || 0) - 1}
            pagesTotal={algoliaMetaData?.page?.total_pages || queryParams?.page || 0}
            contentstackBasePath="labels"
            onPageChange={handlePageChange}
            pageRangeToDisplay={COR_PAGINATION_PAGE_RANGE_TO_DISPLAY}
          />
        </ContentstackContext.Provider>
      )}
    </>
  );
};

export default React.memo(CorFilteredListCards);
