import { get, isArray } from 'lodash-es';
import { IMessage } from 'components/contentstack/contentstack-message';
import { useContentstackContent } from './use-contentstack-content';

export const useContent = () => {
  const contentEntity = useContentstackContent();
  const getContentByKey = <T>(contentKey: string, defaultValue: T): T => get(contentEntity, contentKey, defaultValue);

  const getBackgroundColorByKey = <T>(contentKey: string, defaultValue: T): T =>
    get(contentEntity, contentKey, defaultValue)?.toLowerCase()?.replace(new RegExp(' ', 'g'), '-');

  const getMessageText = (type: string, id: string) => {
    const messages: IMessage[] = get(contentEntity, `${type}.0.messages`, []);
    const message = messages.find((message) => message.message_id === id);

    return message?.message_content || '';
  };

  const getLabelsByKey = <T>(contentKey: string, defaultValue: T): T => {
    const labelsRaw = get(contentEntity, contentKey, defaultValue);

    if (!isArray(labelsRaw)) return defaultValue;
    return labelsRaw?.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {});
  };

  return {
    getContentByKey,
    getMessageText,
    getLabelsByKey,
    getBackgroundColorByKey,
  };
};
