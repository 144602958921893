import './cor-card-faq.scss';

import React, { useCallback, SyntheticEvent } from 'react';
import {
  ICorFeaturedContentCardVariant,
  ICorFeaturedContentEntry,
} from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { CorTypography } from 'corporate-ui';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { get } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { useContent } from 'hooks/use-content';
import { isExternalUrl } from 'utils/is-external-url';

const CorCardFAQ = ({ contentKeyBase }: ICorFeaturedContentCardVariant) => {
  const { getContentByKey } = useContent();
  const entry: ICorFeaturedContentEntry = getContentByKey(contentKeyBase, {});
  const history = useHistory();

  const handleRichTextClick = useCallback(
    (event: SyntheticEvent) => {
      if (!(event.target instanceof HTMLAnchorElement)) {
        return;
      }
      event.preventDefault();
      const anchorURL = get(event, 'target.attributes.href.value');
      const anchorTarget = event.target.target;

      if (isExternalUrl(anchorURL)) {
        window.open(anchorURL, anchorTarget);
      } else {
        history.push(anchorURL);
      }
    },
    [history]
  );

  return (
    <div className="cor-featured-content__card-faq">
      {!!entry?.title && (
        <CorTypography
          variant="h4"
          className="cor-featured-content__card-faq__category"
          data-testid="cor-featured-content__card-faq__title"
        >
          <CorContentstackHtml contentKey={`${contentKeyBase}.title`} component="span" onClick={handleRichTextClick} />
        </CorTypography>
      )}

      {!!entry?.description && (
        <CorTypography
          variant="body-large"
          color="mid-gray"
          className="cor-featured-content__card-faq__description"
          data-testid="cor-featured-content__card-faq__description"
        >
          <CorContentstackHtml
            contentKey={`${contentKeyBase}.description`}
            component="span"
            onClick={handleRichTextClick}
          />
        </CorTypography>
      )}
    </div>
  );
};

export default React.memo(CorCardFAQ);
