import { pick } from 'lodash-es';

export const keysToPreload = ['nextOrderDueDate'];

export const flush = () => {
  keysToPreload.forEach((key) => window.localStorage.removeItem(key));
};

export const getPreloadedParams = () => {
  return pick(window.localStorage, keysToPreload);
};

export const cacheAll = (payload: any) => {
  keysToPreload.forEach((key) => {
    window.localStorage.setItem(key, payload[key]);
  });
};

export const cacheNextDueDate = (nextOrderDueDate: string) => {
  window.localStorage.setItem('nextOrderDueDate', nextOrderDueDate);
};
