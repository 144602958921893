import React from 'react';

const useCorFormSubmissionModal = () => {
  const [isModalOpened, setIsModalOpened] = React.useState(false);

  const handleCloseModal = React.useCallback(() => setIsModalOpened(false), []);
  const handleOpenModal = React.useCallback(() => setIsModalOpened(true), []);

  return { isModalOpened, handleCloseModal, handleOpenModal };
};

export default useCorFormSubmissionModal;
