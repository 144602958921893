import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { IAction } from 'types/actions';
import { IOrderDetails } from 'types/order-details';
import { doRequest } from 'store/api/actions';
import { getQuotes, submitQuoteOrder, submitQuoteOrderActionConstants } from 'store/quotes/actions';
import { getCustomerNotifications } from 'store/notifications/actions';

export interface ISubmitQuoteOrderPayload {
  quoteNumber: string;
  poNumber?: string;
  onSuccessCallback: (orderDetails: IOrderDetails) => void;
}

export function* submitQuoteOrderSagaHandler({
  payload: { quoteNumber, poNumber },
}: IAction<ISubmitQuoteOrderPayload>) {
  yield put(doRequest(submitQuoteOrder, Endpoints.SUBMIT_ORDER, 'post', { quoteNumber, poNumber }));
}

export function* submitQuoteOrderOnSuccessCallBackSagaHandler({ payload }: IAction<ISubmitQuoteOrderPayload>) {
  const { payload: orderDetails } = yield take(submitQuoteOrderActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback(orderDetails);
    yield put(getQuotes.request());
    yield put(getCustomerNotifications.request());
  }
}

export default function* submitQuoteOrderSaga() {
  yield takeLatest(submitQuoteOrderActionConstants[Actions.REQUEST], submitQuoteOrderSagaHandler);
  yield takeLatest(submitQuoteOrderActionConstants[Actions.REQUEST], submitQuoteOrderOnSuccessCallBackSagaHandler);
}
