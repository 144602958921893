import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { unlockCsrCustomerAccount, unlockCsrCustomerAccountConstants } from 'store/dashboard/actions';

export interface IUnlockCsrCustomerAccountPayload {
  accountAction: string;
  onSuccessCallback: () => void;
  accountId: string;
}

export function* unlockCsrCustomerAccountHandler({
  payload: { accountId, accountAction },
}: IAction<IUnlockCsrCustomerAccountPayload>) {
  yield put(
    doRequest(unlockCsrCustomerAccount, `${Endpoints.DASHBOARD_CSR_CUSTOMERS}/${accountId}/unlock`, 'put', {
      accountAction,
    })
  );
}

export function* unlockCsrCustomerAccountOnSuccessCallBackSagaHandler({
  payload,
}: IAction<IUnlockCsrCustomerAccountPayload>) {
  yield take(unlockCsrCustomerAccountConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export default function* unlockCsrCustomerAccountHandlerSaga() {
  yield takeLatest(unlockCsrCustomerAccountConstants[Actions.REQUEST], unlockCsrCustomerAccountHandler);
  yield takeLatest(
    unlockCsrCustomerAccountConstants[Actions.REQUEST],
    unlockCsrCustomerAccountOnSuccessCallBackSagaHandler
  );
}
