import { FC } from 'react';
import { ContentstackHtml } from 'components/contentstack/contentstack-html';

import './basic-footer.scss';

export const BasicFooter: FC = () => {
  return (
    <div className="basic-footer">
      <div className="grid-container">
        <div className="grid-x align-justify align-middle">
          <small className="copyright">
            <ContentstackHtml
              contentKey="footer[0].copyright_block[0].text"
              interpolateParams={{ currentYear: new Date().getFullYear() }}
              component="span"
            />
          </small>
        </div>
      </div>
    </div>
  );
};
