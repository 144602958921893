import { FC, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { QueryParamProvider } from 'use-query-params';
import { Router, Switch } from 'react-router-dom';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { history } from 'store';
import { routesConfig } from 'routes/routes.config';
import { mapRoutesConfigToRoutes } from 'routes/routes';
import { LoadingIndicator } from 'components/loading-indicator';
import { SessionTimer } from 'features/session-timer/session-timer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // 30s stale time.
      staleTime: 30000,
    },
  },
});

const App: FC = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Suspense fallback={<LoadingIndicator />}>
              <Switch>{mapRoutesConfigToRoutes(routesConfig)}</Switch>
            </Suspense>
          </QueryParamProvider>
        </Router>
      </QueryClientProvider>
      <SessionTimer />
    </>
  );
};

export default App;
