import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { CorContentstackLink } from 'corporate-components/cor-contentstack';
import { Routes } from 'constants/routes.enum';
import iconArrowRed from 'assets/images/icons/icon-single-arrow-red.svg';
import { ContentstackText } from 'components/contentstack';
import { IconWithHover } from 'components/icon-with-hover';
import { useContent } from 'hooks/use-content';
import { isExternalUrl } from 'utils/is-external-url';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';
import {
  selectIsAuthorised,
  selectIsInvoiceOnlyCustomer,
  selectIsTsrUser,
  selectIsUserCountryCanada,
} from 'store/auth/selectors';
import { useGetUserRole } from 'hooks/use-get-user-role';

import './cor-footer-menu-links.scss';

const MENU_LINKS_KEY = 'footer_link_group.footer_link_set.menu_link';

const CorFooterMenuLinks = () => {
  const { getContentByKey } = useContent();
  const [locale] = useLocalStorage('locale', null);
  const menuLinks = getContentByKey(MENU_LINKS_KEY, []);
  const isTsrUser = useSelector(selectIsTsrUser);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const isUserLoggedIn = useSelector(selectIsAuthorised);
  const isUserCountryCanada = useSelector(selectIsUserCountryCanada);
  const { isSuperAdminOrAdmin } = useGetUserRole();

  if (!menuLinks?.length) return null;
  return (
    <div
      className={classNames('cor-footer__menu-links ', { 'international-site': isInternationalSite(locale) })}
      data-testid="cor-footer__menu-links"
    >
      <ul>
        {menuLinks?.map((menuLink: any, key) => {
          if (
            (isTsrUser || (!isSuperAdminOrAdmin && !isUserCountryCanada && !isInvoiceOnlyUser && isUserLoggedIn)) &&
            menuLink?.menu_section_title?.href.includes(Routes.AccountPayments)
          ) {
            return null;
          }

          return (
            <li
              key={key.toString()}
              className="cor-footer__menu-link__parent"
              data-testid="cor-footer__menu-link__parent"
            >
              <CorContentstackLink
                key={key.toString()}
                contentKey={`${MENU_LINKS_KEY}[${key}].menu_section_title`}
                className={classNames('cor-footer__menu-link__title', {
                  'cor-footer__menu-link__title--no-menu-links': !menuLink?.menu_link_set?.length,
                })}
                opensInNewTab={isExternalUrl(menuLink?.menu_section_title?.href)}
              >
                <ContentstackText contentKey={`${MENU_LINKS_KEY}[${key}].menu_section_title.title`} />{' '}
                <IconWithHover icon={iconArrowRed} hoverIcon={iconArrowRed} alt="Menu Item Icon" />
              </CorContentstackLink>

              {menuLink?.menu_link_set?.map((linkSet: any, k) => (
                <ul key={k.toString()}>
                  {!!linkSet?.menu_link_set_title && (
                    <h4 className="cor-footer__menu-link__sub-title" data-testid="cor-footer__menu-link__sub-title">
                      {linkSet?.menu_link_set_title}
                    </h4>
                  )}
                  {linkSet?.menu_links?.map((link: any, y) => (
                    <li key={y.toString()} data-testid="cor-footer__menu-link__child-link">
                      <CorContentstackLink
                        contentKey={`${MENU_LINKS_KEY}[${key}].menu_link_set[${k}].menu_links[${y}].link`}
                        className={classNames('cor-footer__menu-link__child-link')}
                        opensInNewTab={link?.open_in_a_new_tab}
                      />
                    </li>
                  ))}
                </ul>
              ))}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(CorFooterMenuLinks);
