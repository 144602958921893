import { JsonParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';

const useAlgoliaQueryParams = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    query: StringParam,
    page_type: StringParam,
    facets: JsonParam,
  });

  return { queryParams, setQueryParams };
};

export default useAlgoliaQueryParams;
