import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'components/modals/common/modal';
import { deleteUser } from 'store/account/actions';
import { ContentstackText } from 'components/contentstack';
import { CorButton } from 'components/cor-button';
import { IDeleteUserPayload } from 'store/account/sagas/delete-user';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './remove-user-confirmation-modal.scss';

export interface IRemoveUserConfirmationModalProps {
  isOpen: boolean;
  userId: string;
  onClose: () => void;
  removeUserSuccessCallback: () => void;
}

export const RemoveUserConfirmationModal: FC<IRemoveUserConfirmationModalProps> = ({
  isOpen,
  userId,
  onClose,
  removeUserSuccessCallback,
}) => {
  const contentstackPath = 'modals.0.remove_user_confirmation_modal.0';
  const dispatch = useDispatch();

  const deleteUserHandler = () => {
    dispatch(
      deleteUser.request<IDeleteUserPayload>({ userId, onSuccessCallback: removeUserSuccessCallback })
    );
  };

  return (
    <Modal className="remove-user-confirmation-modal" isOpen={isOpen} onClose={onClose} withBackdropClick>
      <ModalHeader titleContentstackPath={`${contentstackPath}.heading`} />

      <div className="remove-user-confirmation-modal__message">
        <ContentstackText contentKey={`${contentstackPath}.message`} />
      </div>
      <ModalFooter className="remove-user-confirmation-modal__footer">
        <CorButton className="remove-user-confirmation-modal__close-button" color="secondary" onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.cancel_button`} />
        </CorButton>
        <CorButton className="remove-user-confirmation-modal__proceed-button primary" onClick={deleteUserHandler}>
          <ContentstackText contentKey={`${contentstackPath}.proceed_button`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
