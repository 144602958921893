export enum InvoiceStatus {
  OPEN = 'Open',
  PROCESSING = 'Processing',
  CLOSED = 'Closed',
  SCHEDULED = 'Scheduled',
  CANCELLED = 'Cancelled',
}

export enum InvoicesPaginationLimit {
  LIMIT = 10,
  defaultOffset = 0,
}
