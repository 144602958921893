import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import {
  removeProductFromFavorites,
  removeProductFromFavoritesActionConstants,
  updateProductFavoritesStatus,
} from 'store/shopping-lists/actions';
import { IAction } from 'types/actions';

export interface IRemoveProductFromFavoritesPayload {
  sku: string;
  onSuccessCallBack?: () => void;
  filteredLineItems?: string[];
}

export function* removeProductFromFavoritesOnSuccessCallBackSagaHandler({
  payload,
}: IAction<IRemoveProductFromFavoritesPayload>) {
  yield take(removeProductFromFavoritesActionConstants[Actions.SUCCESS]);
  yield put(updateProductFavoritesStatus(payload.sku, false));
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* removeProductFromFavoritesSagaHandler({ payload }: IAction<IRemoveProductFromFavoritesPayload>) {
  const { sku, filteredLineItems } = payload;
  yield put(
    doRequest(removeProductFromFavorites, `${Endpoints.FAVORITES_LIST_ITEMS}/${sku}`, 'delete', { filteredLineItems })
  );
}

export default function* removeProductFromFavoritesSaga() {
  yield takeLatest(
    removeProductFromFavoritesActionConstants[Actions.REQUEST],
    removeProductFromFavoritesOnSuccessCallBackSagaHandler
  );
  yield takeLatest(removeProductFromFavoritesActionConstants[Actions.REQUEST], removeProductFromFavoritesSagaHandler);
}
