import { combineReducers } from 'redux';
import { PAGINATION_SETTINGS } from 'constants/pagination.enum';
import { Actions } from 'constants/actions.enum';
import { DEFAULT_PRICE_SUMMARY_MODEL } from 'constants/price-summary';
import { DEFAULT_ORDER_DETAILS_MODEL } from 'constants/order-details';
import { DEFAULT_ACCOUNT_LOCATION_MODEL } from 'constants/location';
import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/quotes/actions';
import { IOrderDetails, ISkuInformation } from 'types/order-details';
import { IQuote } from 'types/quotes';
import types from './action-types';

export interface ILocation {
  isDeleted: boolean;
  locationId: string;
  locationNumber: string;
  name: string;
  address: {
    address: string;
    city: string;
    country: string;
    state: string;
    zipCode: string;
  };
  superAdmin: {
    accountNumber: string;
  };
}

export interface ISkuInfoState {
  skuDetails: ISkuInformation;
  skuInfoLoading: boolean;
}

export interface ICustomerQuotesState {
  quotes: IQuote[];
  loading: boolean;
}

export interface IApproveQuoteForm {
  isSubmitting: boolean;
  serverError: string;
}

export interface IInternalUserQuotesState {
  quotesInfo: IInternalUserQuotesInfo[];
  limit: number;
  offset: number;
  total: number;
  requested: number;
  loading: boolean;
  selectedQuote: {
    estimatedDeliveryDates?: string[];
  };
  approveQuoteForm: IApproveQuoteForm;
}

export interface IInternalUserQuotesInfo {
  location: ILocation;
  quote: IQuote;
}

export interface IQuoteOrderSummaryState {
  loading: boolean;
  orderDetails: IOrderDetails;
}

export const CustomerQuotesInitialState: ICustomerQuotesState = {
  quotes: [],
  loading: true,
};

export const InternalUserQuotesInitialState: IInternalUserQuotesState = {
  quotesInfo: [],
  limit: PAGINATION_SETTINGS.defaultLimit,
  offset: PAGINATION_SETTINGS.defaultOffset,
  total: 0,
  requested: 0,
  loading: true,
  selectedQuote: {},
  approveQuoteForm: {
    isSubmitting: false,
    serverError: '',
  },
};

export const SkuInfoInitialState: ISkuInfoState = {
  skuDetails: {
    brand: '',
    imageUrl: '',
    name: '',
    packing: '',
    productId: '',
    productKey: '',
    sku: '',
  },
  skuInfoLoading: false,
};

export const QuoteOrderSummaryInitialState: IQuoteOrderSummaryState = {
  loading: true,
  orderDetails: {
    ...DEFAULT_ORDER_DETAILS_MODEL,
    billToLocation: {
      name: '',
      address: {
        ...DEFAULT_ACCOUNT_LOCATION_MODEL,
      },
    },
    shipToLocation: {
      name: '',
      locationNumber: '',
      address: {
        ...DEFAULT_ACCOUNT_LOCATION_MODEL,
      },
    },
    priceSummary: {
      ...DEFAULT_PRICE_SUMMARY_MODEL,
    },
  },
};

export interface IQuotesState {
  customerQuotes: ICustomerQuotesState;
  internalUserQuotes: IInternalUserQuotesState;
  skuInfo: ISkuInfoState;
  quoteOrderSummary: IQuoteOrderSummaryState;
}

export const quotesInitialState: IQuotesState = {
  customerQuotes: CustomerQuotesInitialState,
  internalUserQuotes: InternalUserQuotesInitialState,
  skuInfo: SkuInfoInitialState,
  quoteOrderSummary: QuoteOrderSummaryInitialState,
};

const customerQuotes = createReducer<ICustomerQuotesState>(
  {
    [actions.getQuotesActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getQuotesActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      quotes: [...payload.quoteModelResponses],
      loading: false,
    }),
    [actions.getQuotesActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  quotesInitialState.customerQuotes
);

const internalUserQuotes = createReducer<IInternalUserQuotesState>(
  {
    [actions.getInternalUserQuotesActionConstants[Actions.REQUEST]]: (state, { payload: { withoutLoading } }) => ({
      ...state,
      loading: !withoutLoading,
    }),
    [actions.getInternalUserQuotesActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [actions.getDeliveryDatesConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      selectedQuote: {
        estimatedDeliveryDates: payload,
      },
    }),
    [actions.getDeliveryDatesConstants[Actions.FAIL]]: (state) => ({
      ...state,
      selectedQuote: {
        estimatedDeliveryDates: [],
      },
    }),
    [types.INTERNAL_USER_QUOTES_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      offset: state.limit * payload.current,
    }),
    [actions.approveQuoteActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      approveQuoteForm: {
        ...quotesInitialState.internalUserQuotes.approveQuoteForm,
        isSubmitting: true,
      },
    }),
    [actions.approveQuoteActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      approveQuoteForm: {
        isSubmitting: false,
        serverError: '',
      },
    }),
    [actions.approveQuoteActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      approveQuoteForm: {
        isSubmitting: false,
        serverError: error.errorMessages[0],
      },
    }),
    [types.CLEAR_APPROVE_QUOTE_SERVER_ERROR]: (state) => ({
      ...state,
      approveQuoteForm: {
        ...state.approveQuoteForm,
        serverError: '',
      },
    }),
    [actions.getQuotesRequestedConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getQuotesRequestedConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      requested: payload.requested,
    }),
  },
  quotesInitialState.internalUserQuotes
);

const skuInfo = createReducer<ISkuInfoState>(
  {
    [actions.getSkuInfoActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      skuInfoLoading: true,
    }),
    [actions.getSkuInfoActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      skuDetails: {
        ...state.skuDetails,
        ...payload,
      },
      skuInfoLoading: false,
    }),
    [actions.getSkuInfoActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      skuInfoLoading: false,
    }),
    [types.RESET_SKU_INFO_DETAILS]: () => quotesInitialState.skuInfo,
  },
  quotesInitialState.skuInfo
);

const quoteOrderSummary = createReducer<IQuoteOrderSummaryState>(
  {
    [actions.acceptQuoteActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.acceptQuoteActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      orderDetails: {
        ...payload,
        priceSummary: {
          ...DEFAULT_PRICE_SUMMARY_MODEL,
          ...payload.priceSummary,
        },
      },
      loading: false,
    }),
    [actions.acceptQuoteActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.submitQuoteOrderActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.submitQuoteOrderActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      orderDetails: payload,
      loading: false,
    }),
    [actions.submitQuoteOrderActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.RESET_SKU_INFO_DETAILS]: () => quotesInitialState.quoteOrderSummary,
  },
  quotesInitialState.quoteOrderSummary
);

export default combineReducers({
  customerQuotes,
  internalUserQuotes,
  skuInfo,
  quoteOrderSummary,
});
