import { FC } from 'react';
import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash-es';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { useContent } from 'hooks/use-content';
import { selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';

import './subscriptions.scss';

export interface ISubscriptionsProps {
  isMarkedForNotifications?: boolean;
  hasOrderSubscription?: boolean;
  hasQuoteSubscription?: boolean;
  hideOrderSubscription?: boolean;
}

export const Subscriptions: FC<ISubscriptionsProps> = ({
  isMarkedForNotifications,
  hasOrderSubscription,
  hasQuoteSubscription,
  hideOrderSubscription = false,
}) => {
  const { getContentByKey } = useContent();
  const PATH_TO_CONTENT = 'subscriptions[0]';
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);

  const checkIcon = getContentByKey<string>(`${PATH_TO_CONTENT}.checked_icon.url`, '');
  const bannedIcon = getContentByKey<string>(`${PATH_TO_CONTENT}.unchecked_icon.url`, '');

  const subscriptionText = isMarkedForNotifications ? (
    <ContentstackText contentKey={`${PATH_TO_CONTENT}.subscription_opted_in_text`} />
  ) : (
    <ContentstackText contentKey={`${PATH_TO_CONTENT}.subscription_opted_out_text`} />
  );

  const orderSubscriptionText = hasOrderSubscription ? (
    <ContentstackText contentKey={`${PATH_TO_CONTENT}.has_order_subscription_text`} />
  ) : (
    <ContentstackText contentKey={`${PATH_TO_CONTENT}.has_no_order_subscription_text`} />
  );

  const quoteSubscriptionText = hasQuoteSubscription ? (
    <ContentstackText contentKey={`${PATH_TO_CONTENT}.has_quote_subscription_text`} />
  ) : (
    <ContentstackText contentKey={`${PATH_TO_CONTENT}.has_no_quote_subscription_text`} />
  );

  const subscriptionConfig = [
    {
      flag: isMarkedForNotifications,
      icon: isMarkedForNotifications ? checkIcon : bannedIcon,
      text: subscriptionText,
      hidden: false,
    },
    {
      flag: hasOrderSubscription,
      icon: hasOrderSubscription ? checkIcon : bannedIcon,
      text: orderSubscriptionText,
      hidden: isInvoiceOnlyUser || isShopLocaleInternational(),
    },
    {
      flag: hasQuoteSubscription,
      icon: hasQuoteSubscription ? checkIcon : bannedIcon,
      text: quoteSubscriptionText,
      hidden: isInvoiceOnlyUser || isShopLocaleInternational() ? true : hideOrderSubscription,
    },
  ];

  return (
    <div className="subscriptions">
      {subscriptionConfig.map(
        (subscription, index) =>
          !isUndefined(subscription.flag) &&
          !subscription.hidden && (
            <div className="subscriptions__item" key={index}>
              <img src={subscription.icon} alt="subscription icon" />
              <span className="subscriptions__text">{subscription.text}</span>
            </div>
          )
      )}
    </div>
  );
};
