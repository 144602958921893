import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getLocationsByRecentOrders, getLocationsByRecentOrdersConstants } from 'store/dashboard/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface ILocationsByRecentOrdersPayload {
  searchText?: string;
  limit: number;
  offset: number;
}

export function* getLocationsByRecentOrdersHandler({
  payload: { searchText = '' },
}: IAction<ILocationsByRecentOrdersPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);

  yield put(
    doRequest(
      getLocationsByRecentOrders,
      `${Endpoints.DASHBOARD_MSR_LOCATIONS_BY_RECENT_ORDERS}?searchText=${searchQuery}`,
      'get'
    )
  );
}

export default function* getLocationsByRecentOrdersSaga() {
  yield takeLatest(getLocationsByRecentOrdersConstants[Actions.REQUEST], getLocationsByRecentOrdersHandler);
}
