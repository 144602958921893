import { put, takeLatest, take } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { doRequest } from 'store/api/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { deletePromotionCouponCode, deletePromotionCouponCodeActionConstants } from '../actions';

export interface IDeletePromotionCouponCodePayload {
  discountCode: string;
  onSuccessCallBack?: () => void;
}

export function* deletePromotionCouponCodeOnSuccessSagaHandler({
  payload,
}: IAction<IDeletePromotionCouponCodePayload>) {
  yield take(deletePromotionCouponCodeActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* deletePromotionCouponCodeSagaHandler({
  payload: { discountCode },
}: IAction<IDeletePromotionCouponCodePayload>) {
  yield put(doRequest(deletePromotionCouponCode, `${Endpoints.CART_DISCOUNTS}`, 'delete', { discountCode }));
}

export default function* deletePromotionCouponCodeSaga() {
  yield takeLatest(
    deletePromotionCouponCodeActionConstants[Actions.REQUEST],
    deletePromotionCouponCodeOnSuccessSagaHandler
  );
  yield takeLatest(deletePromotionCouponCodeActionConstants[Actions.REQUEST], deletePromotionCouponCodeSagaHandler);
}
