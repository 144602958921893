import './cor-form-submission-modal.scss';

import React from 'react';
import classNames from 'classnames';
import { CorContentstackHtml, CorContentstackLink } from 'corporate-components/cor-contentstack';
import { CorTypography } from 'corporate-ui';
import { ContentstackImage, ContentstackText } from 'components/contentstack';
import { Modal } from 'components/modals/common/modal';
import { useContent } from 'hooks/use-content';

interface ICorFormSubmissionModal {
  isOpen: boolean;
  onClose?(): void;
  isSuccess?: boolean;
  submissionCTAsKey?: string;
  submissionSuccessKey?: string;
  submissionFailureKey?: string;
}

const CorFormSubmissionModal = ({
  isOpen = false,
  onClose = () => {},
  isSuccess = true,
  submissionCTAsKey = 'form.form[0].form_submission.submission_cta',
  submissionSuccessKey = 'form.form[0].form_submission.submission_success_message',
  submissionFailureKey = 'form.form[0].form_submission.submission_failure_message',
}: ICorFormSubmissionModal) => {
  const { getContentByKey } = useContent();
  const submissionCTAs: any[] = getContentByKey(submissionCTAsKey, []);

  return (
    <Modal
      className="cor-form-submission-modal"
      isOpen={isOpen}
      withBackdropClick
      size="medium_large"
      hideCloseButton={false}
      onClose={onClose}
    >
      <CorContentstackHtml
        contentKey={isSuccess ? submissionSuccessKey : submissionFailureKey}
        className={classNames('cor-rich-content', 'cor-rich-text', 'cor-form-submission-modal__text__container')}
        data-testid="text__container"
      />
      {!!submissionCTAs?.length && (
        <div className="cor-form-submission-modal__cta__container" data-testid="cta__container">
          {submissionCTAs?.map((cta, index) => {
            return (
              <div className="cor-form-submission-modal__cta" key={index.toString()}>
                <CorContentstackLink contentKey={`${submissionCTAsKey}[${index}].cta_title`} className="cor-text-link">
                  {!!cta?.cta_icon && (
                    <ContentstackImage contentKey={`${submissionCTAsKey}[${index}].cta_icon.image`} />
                  )}
                  <CorTypography variant="h4" className="cor-text-link__caret">
                    <ContentstackText contentKey={`${submissionCTAsKey}[${index}].cta_title.title`} />
                  </CorTypography>
                </CorContentstackLink>

                <CorTypography color="mid-gray">
                  <ContentstackText contentKey={`${submissionCTAsKey}[${index}].cta_description`} />
                </CorTypography>
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default React.memo(CorFormSubmissionModal);
