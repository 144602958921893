import { FC } from 'react';
import { get, template } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';

export interface IContentstackMessageComponentProps {
  type: 'notifications' | 'success' | 'error' | 'tooltips' | 'error_two';
  messageId: string;
  interpolateParams?: object;
}

export interface IMessage {
  message_id: string;
  message_content: string;
}

export const ContentstackMessage: FC<IContentstackMessageComponentProps> = ({
  type,
  messageId,
  interpolateParams = {},
}) => {
  const content = useContentstackContent();
  const path = `${type}.0.messages`;
  const messages: IMessage[] = get(content, path, []);
  const message = messages.find((message) => message.message_id === messageId);
  const compiled = template(message?.message_content);

  return <>{compiled(interpolateParams)}</>;
};
