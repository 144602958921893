import axios from 'axios';
import { take, takeLatest, call, cancelled } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { Limit } from 'constants/limit.enum';
import { ISortParams } from 'types/product-sort-options';
import { apiSagaHandler } from 'store/api/sagas';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { ISelectedFacet } from 'types/facets';
import { sendViewProductList } from 'utils/analytics';
import { getProducts, getProductsActionConstants } from '../actions';

interface IFilterParam {
  attribute: string;
  values: any[];
}

export interface IGetProductsPayload {
  categoryKeys?: string[];
  facets: ISelectedFacet[];
  filters: IFilterParam[];
  limit: Limit;
  offset: number;
  sortOptions: ISortParams;
}

export function* getProductsSagaHandler({ payload }: IAction<IGetProductsPayload>) {
  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  try {
    yield call(
      apiSagaHandler,
      doRequest<IGetProductsPayload>(getProducts, Endpoints.GET_PRODUCTS, 'post', payload, undefined, cancelToken)
    );
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
}

export function* getProductsSuccessSagaHandler() {
  const {
    payload: {
      plpProducts: { result },
    },
  } = yield take(getProductsActionConstants[Actions.SUCCESS]);
  if (result) {
    sendViewProductList(result);
  }
}

export default function* getProductsSaga() {
  yield takeLatest(getProductsActionConstants[Actions.REQUEST], getProductsSagaHandler);
  yield takeLatest(getProductsActionConstants[Actions.REQUEST], getProductsSuccessSagaHandler);
}
