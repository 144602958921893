import './cor-card-with-title.scss';

import { CorTypography } from 'corporate-ui';
import React from 'react';
import { CorContentstackImage, CorContentstackLink } from 'corporate-components/cor-contentstack';
import {
  ICorFeaturedContentCardVariant,
  ICorFeaturedContentEntry,
} from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { useContent } from 'hooks/use-content';

const CorCardWithTitle = ({ contentKeyBase }: ICorFeaturedContentCardVariant) => {
  const { getContentByKey } = useContent();
  const entry: ICorFeaturedContentEntry = getContentByKey(contentKeyBase, {});

  return (
    <div className="cor-featured-content__card-with-title cor-featured-content__card cor-featured-content__card--medium-size">
      <CorContentstackLink
        className="cor-featured-content__card-with-title__image__container cor-featured-content__card__image__container"
        contentKey={`${contentKeyBase}.pageUrl`}
        fallbackElement="div"
      >
        {!!entry?.image && (
          <CorContentstackImage
            contentKey={`${contentKeyBase}.image`}
            className="cor-featured-content__card-with-title__image cor-featured-content__card__image"
          />
        )}
      </CorContentstackLink>
      {!!entry?.title && (
        <div className="cor-featured-content__card-with-title__description">
          {!!entry?.title && (
            <CorContentstackLink className="cor-text-link" contentKey={`${contentKeyBase}.pageUrl`}>
              <CorTypography
                variant="h4"
                className="cor-featured-content__card-with-title__description__title cor-text-link__caret"
                data-testid="cor-featured-content__card-with-title__title"
              >
                {entry?.title}
              </CorTypography>
            </CorContentstackLink>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(CorCardWithTitle);
