import { FC } from 'react';
import { useContent } from 'hooks/use-content';
import { CustomVideo, IVideoConfig } from 'components/custom-video';

export interface IContentstackVideoProps {
  contentKey: string;
  autoPlay?: boolean;
}

export const ContentstackVideo: FC<IContentstackVideoProps> = ({ contentKey, autoPlay }) => {
  const { getContentByKey } = useContent();
  const videoConfig = getContentByKey<IVideoConfig>(contentKey, {});

  return <CustomVideo autoPlay={autoPlay} className="contentstack-video" videoConfig={videoConfig} />;
};
