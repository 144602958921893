import { combineReducers } from 'redux';
import { createReducer } from 'store/reducer-creator';
import types from 'store/ui/action-types';

export interface IUIState {
  headerUI: IHeaderUIState;
}

interface IHeaderUIState {
  isCollapsed: boolean;
}

export const headerUIInitialState: IHeaderUIState = {
  isCollapsed: false,
};

export const uiStateInitialState = {
  headerUI: headerUIInitialState,
};

const headerUI = createReducer<IHeaderUIState>(
  {
    [types.SET_IS_HEADER_COLLAPSED]: (state, { payload }) => ({
      isCollapsed: payload.isCollapsed,
    }),
  },
  headerUIInitialState
);

export default combineReducers<IUIState>({
  headerUI,
});
