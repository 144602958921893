import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { setAccountPassword, setAccountPasswordActionConstants } from '../actions';

export interface ISetPasswordPayload extends ISetPasswordData {
  resetForm: () => void;
  onSuccessCallback: () => void;
}

interface ISetPasswordData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

let resetFormHandler: () => void;
let successCallback: () => void;

export function* setAccountPasswordHandler({
  payload: { resetForm, onSuccessCallback, ...requestData },
}: IAction<ISetPasswordPayload>) {
  resetFormHandler = resetForm;
  successCallback = onSuccessCallback;
  yield put(doRequest<ISetPasswordData>(setAccountPassword, `${Endpoints.ACCOUNT_PASSWORD}`, 'put', requestData));
}

export function setAccountPasswordSuccessHandler() {
  resetFormHandler();
  successCallback();
}

export default function* setAccountPasswordSaga() {
  yield takeLatest(setAccountPasswordActionConstants[Actions.REQUEST], setAccountPasswordHandler);
  yield takeLatest(setAccountPasswordActionConstants[Actions.SUCCESS], setAccountPasswordSuccessHandler);
}
