import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getLocationsByAccountNumber, getLocationsByAccountNumberConstants } from 'store/dashboard/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface ILocationsByAccountNumberPayload {
  limit?: number;
  offset?: number;
  searchText: string;
}

export function* getLocationsByAccountNumberHandler({
  payload: { searchText, limit, offset },
}: IAction<ILocationsByAccountNumberPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);

  yield put(
    doRequest(
      getLocationsByAccountNumber,
      `${Endpoints.DASHBOARD_CSR_LOCATIONS}/?searchText=${searchQuery}&limit=${limit}&offset=${offset}`,
      'get'
    )
  );
}

export default function* getLocationsByAccountNumberSaga() {
  yield takeLatest(getLocationsByAccountNumberConstants[Actions.REQUEST], getLocationsByAccountNumberHandler);
}
