import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getCutOffNotificationTimer, getCutOffNotificationTimerActionConstants } from 'store/order-cut-off/actions';

export interface ICartCutOffNotificationPayload {
  isTimerRequired: boolean;
  showNextOrderDueDate: boolean;
}

export function* getCartCutOffNotificationHandler({
  payload: { isTimerRequired, showNextOrderDueDate },
}: IAction<ICartCutOffNotificationPayload>) {
  const url = `${Endpoints.CUT_OFF_NOTIFICATION}?isTimerRequired=${isTimerRequired}&showNextOrderDueDate=${showNextOrderDueDate}`;
  yield put(doRequest(getCutOffNotificationTimer, url, 'get'));
}

export default function* getCartCutOffNotificationSaga() {
  yield takeLatest(getCutOffNotificationTimerActionConstants[Actions.REQUEST], getCartCutOffNotificationHandler);
}
