import React from 'react';
import { ICSCorButton, ICSCorButtonsGroup } from 'corporate-interfaces';
import { corGroupElementsByLimit } from 'corporate-utils/cor-array-helpers';
import { CorButtonsGroupRow } from 'components/cor-buttons-group/components/cor-buttons-group-row';
import { useContent } from 'hooks/use-content';

import './cor-buttons-group.scss';

const BASE_KEY = 'button';

export const CorButtonsGroup = () => {
  const { getContentByKey } = useContent();
  const buttonData: ICSCorButtonsGroup = getContentByKey(BASE_KEY, {} as ICSCorButtonsGroup);
  const { button_group } = buttonData;
  const buttonGroupByLimit = corGroupElementsByLimit(button_group, 4);
  return (
    <div className="cor-buttons-group" data-testid="buttons-group">
      {buttonGroupByLimit.map((buttons: ICSCorButton[], index) => (
        <CorButtonsGroupRow buttons={buttons} key={`btn-group-row-${index}`} />
      ))}
    </div>
  );
};
