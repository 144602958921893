import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { rejectQuote, rejectQuoteActionConstants, getQuotes } from 'store/quotes/actions';
import { getCustomerNotifications } from 'store/notifications/actions';
import { IAction } from 'types/actions';

export interface IRejectQuotePayload {
  quoteNumber: string;
  rejectReason?: string;
  onSuccessCallback: () => void;
}

export function* rejectQuoteSagaHandler({ payload: { quoteNumber, rejectReason } }: IAction<IRejectQuotePayload>) {
  yield put(doRequest(rejectQuote, `${Endpoints.QUOTES}/${quoteNumber}/reject`, 'patch', { rejectReason }));
}

export function* rejectQuoteOnSuccessCallBackSagaHandler({ payload }: IAction<IRejectQuotePayload>) {
  yield take(rejectQuoteActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    yield payload.onSuccessCallback();
    yield put(getQuotes.request());
    yield put(getCustomerNotifications.request());
  }
}

export default function* rejectQuoteSaga() {
  yield takeLatest(rejectQuoteActionConstants[Actions.REQUEST], rejectQuoteSagaHandler);
  yield takeLatest(rejectQuoteActionConstants[Actions.REQUEST], rejectQuoteOnSuccessCallBackSagaHandler);
}
