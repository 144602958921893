import React from 'react';
import {
  useContentStackSearchQueryQuery,
  IContentstackSearchQueryQueryProps,
} from 'queries/use-contentstack-search-query-query';
import { CorFeaturedContentContentTypeUidEnum } from 'constants/cor-featured-content.enum';

export const CONTENTSTACK_DEFAULT_CONTENT_TYPE = CorFeaturedContentContentTypeUidEnum.News;
export const CONTENTSTACK_DEFAULT_PAGE_NUMBER = 1;
export const CONTENTSTACK_DEFAULT_PAGE_OFFSET = 9;

export const useContentstackSearchQuery = ({
  page = CONTENTSTACK_DEFAULT_PAGE_NUMBER,
  contentTypeName = CONTENTSTACK_DEFAULT_CONTENT_TYPE,
  pageOffset = CONTENTSTACK_DEFAULT_PAGE_OFFSET,
  locale,
}) => {
  const contentstackParams: IContentstackSearchQueryQueryProps = React.useMemo(() => {
    const limit = pageOffset;
    const offset = pageOffset * (page - 1);

    return {
      pageOffset,
      contentTypeName,
      currentPage: page,
      limit,
      skip: offset,
      include: ['modular_blocks.hero.hero'],
      sort: 'desc=publication_date',
      ...(locale && { locale: locale }),
    };
  }, [contentTypeName, page, pageOffset, locale]);

  const contentstackSearchQueryQuery = useContentStackSearchQueryQuery(contentstackParams);
  return contentstackSearchQueryQuery;
};
