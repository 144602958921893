import { put, take, takeLatest } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import {
  addProductsFromMarketingShoppingList,
  addProductsFromMarketingShoppingListActionConstants,
} from 'store/shopping-lists/actions';
import { IDeactivatedLineItemDetails } from 'types/order-details';

export interface IAddProductsFromMarketingShoppingListPayload {
  resourceShoppingListId: string;
  destinationShoppingLists: string[];
  onSuccessCallback: (
    selectedShoppingLists: string[],
    itemCounter: number,
    deactivatedLineItems: IDeactivatedLineItemDetails[]
  ) => void;
  onFailCallback: (deactivatedLineItems: string[]) => void;
}

export function* addProductsFromMarketingShoppingListHandler({
  payload: { resourceShoppingListId, destinationShoppingLists },
}: IAction<IAddProductsFromMarketingShoppingListPayload>) {
  yield put(
    doRequest(addProductsFromMarketingShoppingList, Endpoints.SHOPPING_LISTS_CONVERT_MARKETING_TO_CUSTOM, 'put', {
      resourceShoppingListId,
      destinationShoppingLists,
    })
  );
}

export function* addProductsFromMarketingShoppingListSuccessSagaHandler({
  payload,
}: IAction<IAddProductsFromMarketingShoppingListPayload>) {
  const {
    payload: { itemCounter, deactivatedLineItems },
  } = yield take(addProductsFromMarketingShoppingListActionConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallback) {
    payload.onSuccessCallback(payload.destinationShoppingLists, itemCounter, deactivatedLineItems);
  }
}

export function* addProductsFromMarketingShoppingListFailSagaHandler({
  payload,
}: IAction<IAddProductsFromMarketingShoppingListPayload>) {
  const { error } = yield take(addProductsFromMarketingShoppingListActionConstants[Actions.FAIL]);
  if (payload.onFailCallback) {
    payload.onFailCallback(error.deactivatedItemModel?.skus || []);
  }
}

export default function* addProductsFromMarketingShoppingListSaga() {
  yield takeLatest(
    addProductsFromMarketingShoppingListActionConstants[Actions.REQUEST],
    addProductsFromMarketingShoppingListHandler
  );
  yield takeLatest(
    addProductsFromMarketingShoppingListActionConstants[Actions.REQUEST],
    addProductsFromMarketingShoppingListSuccessSagaHandler
  );

  yield takeLatest(
    addProductsFromMarketingShoppingListActionConstants[Actions.REQUEST],
    addProductsFromMarketingShoppingListFailSagaHandler
  );
}
