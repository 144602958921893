import { useEffect } from 'react';

export const useBodyScrollLock = (flag: boolean = true) => {
  useEffect(() => {
    const notScrollableClassName = 'not-scrollable';
    document.body.classList[flag ? 'add' : 'remove'](notScrollableClassName);

    return () => {
      document.body.classList.remove(notScrollableClassName);
    };
  }, [flag]);
};
