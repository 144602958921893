import { takeLatest, put, take } from 'redux-saga/effects';
import { isUndefined, omitBy } from 'lodash-es';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { submitAchPaymentActionConstants, submitAchPayment } from 'store/payment-transactions/actions';
import { IPaymentDocument } from 'components/modals/schedule-payment-modal';

export interface ISubmitAchPaymentPayload {
  accountType: string;
  depositComment?: string;
  isDeposit?: boolean;
  depositAmount?: number;
  paymentDocuments?: IPaymentDocument[];
  publicAccountNumber: string;
  publicRoutingNumber: string;
  scheduledDate?: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: (messageId: string) => void;
}

export function* submitAchPaymentSuccessSagaHandler({ payload }: IAction<ISubmitAchPaymentPayload>) {
  yield take(submitAchPaymentActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* submitAchPaymentFailSagaHandler({ payload }: IAction<ISubmitAchPaymentPayload>) {
  const { error } = yield take(submitAchPaymentActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack && error) {
    payload.onFailCallBack(error.errorMessageId);
  }
}

export function* submitAchPaymentSagaHandler({
  payload: {
    paymentDocuments,
    accountType,
    publicAccountNumber,
    publicRoutingNumber,
    scheduledDate,
    isDeposit,
    depositComment,
    depositAmount,
  },
}: IAction<ISubmitAchPaymentPayload>) {
  const requestPayload = omitBy(
    {
      paymentDocuments,
      accountType,
      publicAccountNumber,
      publicRoutingNumber,
      scheduledDate,
      isDeposit,
      depositComment,
      depositAmount,
    },
    isUndefined
  );

  yield put(
    doRequest<Partial<ISubmitAchPaymentPayload>>(submitAchPayment, Endpoints.SUBMIT_ACH_PAYMENT, 'post', requestPayload)
  );
}

export default function* submitAchPaymentSaga() {
  yield takeLatest(submitAchPaymentActionConstants[Actions.REQUEST], submitAchPaymentSuccessSagaHandler);
  yield takeLatest(submitAchPaymentActionConstants[Actions.REQUEST], submitAchPaymentFailSagaHandler);
  yield takeLatest(submitAchPaymentActionConstants[Actions.REQUEST], submitAchPaymentSagaHandler);
}
