import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectAlgoliaProductsSuggestionsConfig } from 'store/auth/selectors';
import { selectAlgoliaCorporateSuggestionsConfig } from 'store/guest/selectors';
import { useAlgoliaSearch } from './useAlgoliaSearch';

export const useAlgoliaClient = (isLoggedInUser: boolean, hitsPerPage: number) => {
  const { algoliaApplicationId, algoliaApplicationProductIndexName, securedApiKey } = useSelector(
    selectAlgoliaProductsSuggestionsConfig
  );
  const {
    allCustomersSecuredApiKey,
    algoliaApplicationCorporateIndexName,
    algoliaApplicationId: corAlgoliaApplicationId,
  } = useSelector(selectAlgoliaCorporateSuggestionsConfig);
  const productSuggestions = useAlgoliaSearch(
    'suggestions',
    algoliaApplicationId,
    securedApiKey,
    algoliaApplicationProductIndexName,
    isLoggedInUser ? hitsPerPage : 0
  );
  const corporateSuggestions = useAlgoliaSearch(
    'corporate-suggestions',
    corAlgoliaApplicationId || algoliaApplicationId,
    allCustomersSecuredApiKey,
    algoliaApplicationCorporateIndexName,
    hitsPerPage
  );

  const getSources = useCallback(() => {
    return isLoggedInUser ? [productSuggestions] : [corporateSuggestions];
  }, [isLoggedInUser, productSuggestions, corporateSuggestions]);

  return { getSources };
};
