import Contentstack, { Region, Stack } from 'contentstack';
import ContentstackLivePreview from '@contentstack/live-preview-utils';

export const onEntryChange = ContentstackLivePreview.onEntryChange;

let enableLivePreview: boolean;
let livePreviewId: string;

const hasStartedLivePreview = () => livePreviewId !== undefined;

const shouldEnableLivePreview = () =>
  enableLivePreview && (Boolean(process.env.REACT_APP_CONTENTSTACK_LIVE_PREVIEW) || false);

let stack: Stack;

const initStack = () => {
  stack = Contentstack.Stack({
    api_key: process.env.REACT_APP_CONTENTSTACK_API_KEY || '',
    delivery_token: process.env.REACT_APP_CONTENTSTACK_DELIVERY_TOKEN || '',
    environment: process.env.REACT_APP_CONTENTSTACK_ENVIRONMENT || '',
    region: Region.US,
    live_preview: {
      management_token: process.env.REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN || '',
      enable: shouldEnableLivePreview(),
      host: process.env.REACT_APP_CONTENTSTACK_API_HOST || 'api.contentstack.io',
    },
  });

  stack.setHost(process.env.REACT_APP_CONTENTSTACK_API_HOST || 'api.contentstack.io');
};

const initLivePreview = () => {
  const csLivePreview = ContentstackLivePreview.init({
    stackDetails: {
      apiKey: process.env.REACT_APP_CONTENTSTACK_API_KEY || '',
      environment: process.env.REACT_APP_CONTENTSTACK_ENVIRONMENT || '',
    },
    ssr: false,
    enable: shouldEnableLivePreview(),
    clientUrlParams: {
      host: process.env.REACT_APP_CONTENTSTACK_APP_HOST || 'app.contentstack.com',
    },
    stackSdk: {
      live_preview: {
        management_token: process.env.REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN || '',
        enable: shouldEnableLivePreview(),
        host: process.env.REACT_APP_CONTENTSTACK_API_HOST || 'api.contentstack.io',
      },
      environment: process.env.REACT_APP_CONTENTSTACK_ENVIRONMENT || '',
      headers: {
        api_key: process.env.REACT_APP_CONTENTSTACK_API_KEY || '',
      },
    },
  }) as any;

  csLivePreview.then((livePreview) => {
    livePreviewId = livePreview.config.stackSdk.live_preview.live_preview;
    initStack();
    stack.livePreviewQuery({
      live_preview: livePreview.config.stackSdk.live_preview.live_preview,
      content_type_uid: livePreview.config.stackSdk.live_preview.content_type_uid,
    });
  });
};

const tryStartLivePreview = (delay, maxtries, retry = 1) => {
  if (!hasStartedLivePreview()) {
    initLivePreview();
    if (maxtries > retry) {
      setTimeout(() => tryStartLivePreview(delay * (retry + 1), maxtries, retry + 1), delay);
    }
  }
};

initStack();

const waitUntil = (condition, checkInterval = 100) => {
  return new Promise<void>((resolve) => {
    if (condition()) resolve();
    let interval = setInterval(() => {
      if (!condition()) return;
      clearInterval(interval);
      resolve();
    }, checkInterval);
  });
};

export default {
  startLivePreview(enable) {
    enableLivePreview = enable;
    tryStartLivePreview(1000, 4);
  },
  /**
   *
   * fetches all the entries from specific content-type
   * @param {* content-type uid} contentTypeUid
   *
   */
  async getEntry({ contentTypeUid, url, referenceFieldPath, forceWaitForLivePreview }) {
    if (forceWaitForLivePreview) {
      await waitUntil(hasStartedLivePreview);
    }
    return new Promise((resolve, reject) => {
      const query = stack.ContentType(contentTypeUid).Query();
      if (referenceFieldPath) query.includeReference(referenceFieldPath);
      query
        .where('url', url)
        .includeOwner()
        .toJSON()
        .find()
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },
};
