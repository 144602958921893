export enum AccessDefinitions {
  // functionality access
  SHOPPING_CART_ACTIONS = 'SHOPPING_CART_ACTIONS',
  HOME_ACTIONS = 'HOME_ACTIONS',

  // page access
  SHOPPING_CART_PAGE_ACCESS = 'SHOPPING_CART_PAGE_ACCESS',
  DASHBOARD_QUOTES_PAGE_ACCESS = 'DASHBOARD_QUOTES_PAGE_ACCESS',
  SPECIAL_ORDERS_PAGE_ACCESS = 'SPECIAL_ORDERS_PAGE_ACCESS',
}
