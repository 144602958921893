import { FC, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import { components, MultiValue, OptionProps, SingleValue, ValueContainerProps } from 'react-select';
import { CorTypography } from 'corporate-ui';
import { CustomDropdown, ILinkOptionType, IOptionType } from 'components/custom-dropdown';

import './cor-select-field.scss';

interface ISelectFieldProps extends FieldProps {
  onChange: (option: MultiValue<IOptionType> | SingleValue<IOptionType>) => void;
  onBlur: () => void;
  options: IOptionType[] | ILinkOptionType[];
  value: string;
  className?: string;
  name: string;
  dropdownIcon?: string;
  renderOption?: (props: any) => JSX.Element;
  disabled: boolean;
  placeholder?: string;
  isRequired?: boolean;
}

interface ICorSelectFieldProps extends ISelectFieldProps {
  label?: string;
}

const SelectField: FC<ISelectFieldProps> = ({
  options,
  field,
  onChange,
  onBlur,
  value,
  form: { setFieldValue, setFieldTouched },
  className,
  name,
  dropdownIcon,
  renderOption,
  disabled = false,
  placeholder = '',
}) => {
  const ref = useRef<any>();
  const defaultValue = (options: IOptionType[] | ILinkOptionType[], value: string) => {
    return options ? options.find((option: IOptionType | ILinkOptionType) => option.value === value) : '';
  };

  const onChangeHandler = useCallback(
    (option: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
      setFieldValue(field.name, (option as any)?.value);

      if (onChange) {
        onChange(option);
      }
    },
    [field.name, onChange, setFieldValue]
  );

  useEffect(() => {
    if (!!field.value) return;

    ref.current?.select.clearValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const onBlurHandler = useCallback(() => {
    if (onBlur) onBlur();

    Promise.resolve(() => {
      setFieldTouched(field.name);
    });
  }, [field.name, onBlur, setFieldTouched]);

  const [, meta] = useField(field.name);

  const selectClass = classNames('cor-select-field', className, {
    'cor-select-field--error': !meta.value && meta.error && meta.touched,
  });

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <span className="cor-select-field__dropdown-icon" />
        </components.DropdownIndicator>
      )
    );
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
    const CustomValueContainer = useCallback(
      () => (
        <>
          <div className="cor-select-field__icon">
            <img src={dropdownIcon} alt="dropdown icon" />
          </div>
          <components.ValueContainer {...props}>{children}</components.ValueContainer>
        </>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return dropdownIcon ? (
      <CustomValueContainer />
    ) : (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  };

  const Option = ({ children, ...props }: OptionProps<any>) => {
    return renderOption ? (
      renderOption({ children, ...props })
    ) : (
      <components.Option {...props}>{children}</components.Option>
    );
  };

  return (
    <>
      <CustomDropdown
        {...field}
        ref={ref}
        components={{ DropdownIndicator, ValueContainer, Option }}
        value={(defaultValue(options, value) as IOptionType) || ''}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        name={name}
        items={options}
        classNamePrefix="cor-select-field"
        className={selectClass}
        isDisabled={disabled}
        placeholder={placeholder}
        isSearchable={false}
      />
      <div className="cor-select-field__error-message__container">
        <ErrorMessage className="cor-select-field__error-message" name={field.name} component="p" />
      </div>
    </>
  );
};

export const CorSelectField = ({ label = '', isRequired = false, ...props }: Partial<ICorSelectFieldProps>) => {
  return (
    <div className="cor-select-field__container">
      <CorTypography
        color="mid-gray"
        variant="small-caps"
        component="label"
        className="cor-select-field__label"
        htmlFor={props.name}
      >
        {!!label ? label.concat(isRequired ? '*' : '') : ''}
      </CorTypography>
      <Field component={SelectField} {...props} />
    </div>
  );
};
