import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getSalesRepInformation, getSalesRepInformationActionConstants } from 'store/account/actions';

export function* getSalesRepInformationSagaHandler() {
  yield put(doRequest(getSalesRepInformation, Endpoints.SALES_REP_INFORMATION, 'get'));
}

export default function* getSalesRepInformationSaga() {
  yield takeLatest(getSalesRepInformationActionConstants[Actions.REQUEST], getSalesRepInformationSagaHandler);
}
