import { takeLatest, take, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { cutoffNotificationViewed, cutoffNotificationViewedActionConstants } from 'store/order-cut-off/actions';
import { IAction } from 'types/actions';

export interface ICutoffNotificationViewedPayload {
  reload?: boolean;
}

export function* cutoffNotificationViewedSagaHandler() {
  yield put(doRequest(cutoffNotificationViewed, `${Endpoints.CUT_OFF_NOTIFICATION_VIEWED}`, 'patch'));
}

export function* cutoffNotificationViewedSuccessSagaHandler({ payload }: IAction<ICutoffNotificationViewedPayload>) {
  yield take(cutoffNotificationViewedActionConstants[Actions.SUCCESS]);

  if (payload.reload) {
    window.location.reload();
  }
}

export default function* getCartExpiredNotificationViewedSaga() {
  yield takeLatest(cutoffNotificationViewedActionConstants[Actions.REQUEST], cutoffNotificationViewedSagaHandler);
  yield takeLatest(
    cutoffNotificationViewedActionConstants[Actions.REQUEST],
    cutoffNotificationViewedSuccessSagaHandler
  );
}
