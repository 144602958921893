import { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import './discount-expandable-section.scss';

export interface IExpandableSectionProps extends PropsWithChildren {
  title: string | ReactNode;
  forceIsExpanded?: boolean;
  discount: string;
}

export const DiscountExpandableSection: FC<IExpandableSectionProps> = ({
  title,
  discount,
  forceIsExpanded = false,
  children,
}) => {
  const [isOpened, setIsOpened] = useState(forceIsExpanded);

  useEffect(() => {
    setIsOpened(forceIsExpanded);
  }, [forceIsExpanded]);

  const onTitleClick = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const expandableSectionClassName = classNames('discount-expandable-section', {
    'discount-expandable-section--expanded': isOpened,
  });

  return (
    <div className={expandableSectionClassName}>
      <div className="discount-expandable-section__title" onClick={onTitleClick}>
        <span className="discount-expandable-section__expand-icon" />
        <div className="discount-expandable-section__discount">
          <p>{title}</p>
          <p>-{discount}</p>
        </div>
      </div>
      <CSSTransition in={isOpened} timeout={200} classNames="expandable-section__inner">
        <div className="discount-expandable-section__inner">{children}</div>
      </CSSTransition>
    </div>
  );
};
