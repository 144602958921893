import { put, take, takeLatest } from 'redux-saga/effects';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { Actions } from 'constants/actions.enum';
import { scheduleCreditCardPayment, scheduleCreditCardPaymentActionConstants } from 'store/schedule-payment/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { IPaymentDocument } from 'components/modals/schedule-payment-modal';

export interface IScheduleCardPaymentPayload {
  paymentDocuments: IPaymentDocument[];
  cardLastFourDigits: string;
  cardType: string;
  scheduledDate: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: () => void;
}

export function* scheduleCardPaymentOnSuccessSagaHandler({ payload }: IAction<IScheduleCardPaymentPayload>) {
  yield take(scheduleCreditCardPaymentActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* scheduleCardPaymentOnFailSagaHandler({ payload }: IAction<IScheduleCardPaymentPayload>) {
  yield take(scheduleCreditCardPaymentActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack) {
    payload.onFailCallBack();
  }
}

export function* scheduleCardPaymentHandler({
  payload: { paymentDocuments, cardLastFourDigits, cardType, scheduledDate },
}: IAction<IScheduleCardPaymentPayload>) {
  const url = `${Endpoints.SUBMIT_CREDIT_CARD_PAYMENT}`;
  yield put(
    doRequest(scheduleCreditCardPayment, url, 'post', { paymentDocuments, cardLastFourDigits, cardType, scheduledDate })
  );
}

export default function* scheduleCardPaymentSaga() {
  yield takeLatest(scheduleCreditCardPaymentActionConstants[Actions.REQUEST], scheduleCardPaymentOnSuccessSagaHandler);
  yield takeLatest(scheduleCreditCardPaymentActionConstants[Actions.REQUEST], scheduleCardPaymentOnFailSagaHandler);
  yield takeLatest(scheduleCreditCardPaymentActionConstants[Actions.REQUEST], scheduleCardPaymentHandler);
}
