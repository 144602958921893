import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getQuotesRequested, getQuotesRequestedConstants } from 'store/quotes/actions';

export function* getQuotesRequestedHandler() {
  yield put(doRequest(getQuotesRequested, `${Endpoints.DASHBOARD_QUOTES}/info`, 'get'));
}

export default function* getQuotesRequestedSaga() {
  yield takeLatest(getQuotesRequestedConstants[Actions.REQUEST], getQuotesRequestedHandler);
}
