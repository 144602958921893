import { takeLatest, put, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { push } from 'redux-first-history';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { Routes } from 'constants/routes.enum';
import { IAction } from 'types/actions';
import { CheckoutSteps } from 'types/checkout';
import { doRequest } from 'store/api/actions';
import {
  resetSapBackupStatus,
  setPoNumber,
  submitOrder,
  submitOrderActionConstants,
  setSelectedDeliveryDate,
} from 'store/checkout/actions';
import { selectCheckoutInfo } from 'store/checkout/selectors';
import { sendCheckoutStep, sendPurchase } from 'utils/analytics';
import { selectIsInternalUser } from 'store/auth/selectors';
import config from 'config/app';
import types from 'store/checkout/action-types';
import { resetPromotion } from 'store/shopping-cart/actions';

let isInternalUser: boolean;

export function* submitOrderRequestSagaHandler() {
  isInternalUser = yield select(selectIsInternalUser);
  const { poNumber, selectedDeliveryDate } = yield select(selectCheckoutInfo);
  yield put(doRequest(submitOrder, Endpoints.SUBMIT_ORDER, 'post', { poNumber, selectedDeliveryDate }));
}

export function* goToOrderSubmittionHandler({ payload }: IAction<any>) {
  if (payload.poNumber) yield put(setPoNumber(payload.poNumber));
  if (payload.selectedDeliveryDate) yield put(setSelectedDeliveryDate(payload.selectedDeliveryDate));
  yield put(push(Routes.OrderConfirmationPage));
}

export function* submitOrderFailSagaHandler({ error: { status } }: AnyAction) {
  if (![401, 403].includes(status)) {
    yield put(push(Routes.ShoppingCartPage));
    return;
  }

  if (!isInternalUser) {
    setTimeout(() => {
      window.location.href = `${config.apiUrl}${Endpoints.LOGOUT}`;
    }, 1000);
  } else {
    yield put(push(Routes.ShoppingCartPage));
  }

  yield put(resetPromotion());
}

export function* submitOrderSuccessSagaHandler() {
  const checkoutInfo = yield select(selectCheckoutInfo);

  if (!checkoutInfo.offline && !checkoutInfo.orderNumber) {
    yield put(push(Routes.ShoppingCartPage));
    return;
  }

  sendCheckoutStep(CheckoutSteps.SUBMIT_ORDER, checkoutInfo.lineItems);
  sendPurchase(checkoutInfo);
  yield put(resetPromotion());

  if (checkoutInfo.isSapBackup) {
    yield put(push(Routes.CheckoutPage));
    yield put(resetSapBackupStatus());
  }
}

export default function* submitOrderSaga() {
  yield takeLatest(types.GO_TO_ORDER_SUBMITTION, goToOrderSubmittionHandler);
  yield takeLatest(submitOrderActionConstants[Actions.REQUEST], submitOrderRequestSagaHandler);
  yield takeLatest(submitOrderActionConstants[Actions.SUCCESS], submitOrderSuccessSagaHandler);
  yield takeLatest(submitOrderActionConstants[Actions.FAIL], submitOrderFailSagaHandler);
}
