import { put, takeLatest } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getCustomerNotifications, getCustomerNotificationsActionConstants } from 'store/notifications/actions';

export function* getCustomerNotificationsSagaHandler() {
  yield put(doRequest(getCustomerNotifications, Endpoints.NOTIFICATIONS, 'get'));
}

export default function* getCustomerNotificationsSaga() {
  yield takeLatest(getCustomerNotificationsActionConstants[Actions.REQUEST], getCustomerNotificationsSagaHandler);
}
