import { FC, PropsWithChildren } from 'react';
import { isEmpty } from 'lodash-es';
import { Helmet } from 'react-helmet';
import { ICSCorImage } from 'corporate-interfaces';
import { ICSCorPageConfiguration } from 'corporate-interfaces';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { useContent } from 'hooks/use-content';

interface ICorporateHeadProps extends PropsWithChildren {}

export const CorporateHead: FC<ICorporateHeadProps> = ({ children }) => {
  const { getContentByKey } = useContent();
  const pageConfiguration: ICSCorPageConfiguration = getContentByKey(
    'page_configuration',
    {} as ICSCorPageConfiguration
  );
  const { seo_metadata, social_media_metadata } = pageConfiguration;
  const pageUrl = window.location.toString();
  const image = corContentstackImageLinkResolver((social_media_metadata?.image as ICSCorImage) || '');

  return (
    <>
      {!isEmpty(pageConfiguration) && (
        <Helmet>
          <meta property="og:type" content="website" />
          {/* Meta tags for SEO */}
          {seo_metadata?.meta_title && <meta name="title" content={seo_metadata?.meta_title} />}
          {seo_metadata?.meta_description && <meta name="description" content={seo_metadata?.meta_description} />}
          {seo_metadata?.keywords && <meta name="keywords" content={seo_metadata?.keywords.toString()} />}
          {seo_metadata?.canonical && <link rel="canonical" href={seo_metadata?.canonical} />}
          {seo_metadata?.no_index && <meta name="robots" content="noindex" />}
          {seo_metadata?.no_follow && <meta name="robots" content="nofollow" />}
          {/* Meta tags for Social Media */}
          {social_media_metadata?.title && <meta property="og:title" content={social_media_metadata?.title} />}
          {social_media_metadata?.description && (
            <meta property="og:description" content={social_media_metadata?.description} />
          )}
          {image && <meta property="og:image" content={image?.url} />}
          {image && <meta property="og:image:width" content="640" />}
          {image && <meta property="og:image:height" content="360" />}
          {image && <meta property="og:image:type" content="image/jpg" />}
          {pageUrl && <meta property="og:url" content={pageUrl} />}
          {image?.image_alt_text && <meta property="og:image:alt" content={image?.image_alt_text} />}
          {/* Meta tags for Facebook */}
          {image && <link rel="image_src" href={image?.url} />}
          {/* Meta Tags for Twitter */}
          {image && <meta name="twitter:image" content={image?.url} />}
          {image && <meta name="twitter:card" content="summary_large_image" />}
          {image?.image_alt_text && <meta name="twitter:image:alt" content={image?.image_alt_text} />}
          {social_media_metadata?.description && (
            <meta name="twitter:description" content={social_media_metadata?.description} />
          )}
          {social_media_metadata?.title && <meta name="twitter:title" content={social_media_metadata?.title} />}
        </Helmet>
      )}
      {children}
    </>
  );
};
