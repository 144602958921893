import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getShoppingListDetails, getShoppingListDetailsActionConstants } from 'store/shopping-lists/actions';
import { IAction } from 'types/actions';
import { Routes } from 'constants/routes.enum';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IGetShoppingListDetailsPayload {
  shoppingListId: string;
  searchText?: string;
}

export function* getShoppingListDetailsHandler({
  payload: { shoppingListId, searchText = '' },
}: IAction<IGetShoppingListDetailsPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);
  const url = `${Endpoints.SHOPPING_LISTS}/${shoppingListId}/?searchText=${searchQuery}`;
  yield put(doRequest(getShoppingListDetails, url, 'get'));
}

export function* getShoppingListDetailsFailHandler({ payload }: IAction<IGetShoppingListDetailsPayload>) {
  yield put(push(Routes.NotFoundPage));
}

export default function* getShoppingListDetailsSaga() {
  yield takeLatest(getShoppingListDetailsActionConstants[Actions.REQUEST], getShoppingListDetailsHandler);
  yield takeLatest(getShoppingListDetailsActionConstants[Actions.FAIL], getShoppingListDetailsFailHandler);
}
