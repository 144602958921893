import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import {
  getCsrLocationsWithAssignedAccountsByName,
  getCsrLocationsWithAssignedAccountsByNameConstants,
} from 'store/dashboard/actions';
import { PAGINATION_SETTINGS } from 'constants/pagination.enum';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface ILocationsWithAssignedAccountsByNamePayload {
  limit: number;
  offset: number;
  searchText?: string;
}

export function* getLocationsWithAssignedAccountsByNameHandler({
  payload: { searchText = '', limit = PAGINATION_SETTINGS.defaultLimit, offset = PAGINATION_SETTINGS.defaultOffset },
}: IAction<ILocationsWithAssignedAccountsByNamePayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);

  yield put(
    doRequest(
      getCsrLocationsWithAssignedAccountsByName,
      `${Endpoints.DASHBOARD_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_NAME}?searchText=${searchQuery}&limit=${limit}&offset=${offset}`,
      'get'
    )
  );
}

export default function* getLocationsWithAssignedAccountsByNameSaga() {
  yield takeLatest(
    getCsrLocationsWithAssignedAccountsByNameConstants[Actions.REQUEST],
    getLocationsWithAssignedAccountsByNameHandler
  );
}
