import { FC, useEffect } from 'react';
import { useContent } from 'hooks/use-content';

export const PageLeaveConfirmationModal: FC = () => {
  const { getContentByKey } = useContent();
  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');

  useEffect(() => {
    const message = 'message';
    let transitionIsConfirmed = false;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (transitionIsConfirmed) return;
      event.returnValue = message;

      return handlePageLeave();
    };

    const handlePageLeave = () => {
      if (transitionIsConfirmed) return;
      return message;
    };

    const handleClick = (event: any) => {
      const linkElement = event.target.closest('a');

      if (linkElement && linkElement.target !== '_blank') {
        transitionIsConfirmed = window.confirm(leavingPageModalText);

        if (!transitionIsConfirmed) event.preventDefault();
      }
    };

    const subscribeForPageLeaveAndShowConfirmationModal = () => {
      window.addEventListener('click', handleClick, true);
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('pagehide', handlePageLeave);
      window.addEventListener('visibilitychange', handlePageLeave);
    };

    subscribeForPageLeaveAndShowConfirmationModal();

    return () => {
      window.removeEventListener('click', handleClick, true);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handlePageLeave);
      window.removeEventListener('visibilitychange', handlePageLeave);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
