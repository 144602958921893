import { FC, RefObject } from 'react';
import classNames from 'classnames';
import { IOptionType } from 'components/custom-dropdown';
import { FullScreenBanner, IFullScreenBannerProps } from 'components/full-screen-banner';

import './mobile-account-menu-banner.scss';

export interface IMobileAccountMenuBannerProps extends IFullScreenBannerProps {
  welcomeMessage: string;
  accountMenuOptions: IOptionType[];
  onLogOutClick: () => void;
  toggleRef: RefObject<any>;
}

export const MobileAccountMenuBanner: FC<IMobileAccountMenuBannerProps> = ({
  welcomeMessage,
  accountMenuOptions,
  onLogOutClick,
  isOpen,
  onClose,
  toggleRef,
  ...fullScreenBannerProps
}) => {
  const DASHBOARD_MOBILE_HEADER_HEIGHT = 76;
  const SCREEN_TOP_OFFSET = 13;

  const calculateTopPosition = () => {
    if (toggleRef.current) {
      const rect = toggleRef.current.getBoundingClientRect();

      return rect.top - SCREEN_TOP_OFFSET;
    }

    return DASHBOARD_MOBILE_HEADER_HEIGHT;
  };

  return (
    <FullScreenBanner
      className="mobile-account-menu-banner"
      offsetTop={calculateTopPosition()}
      isOpen={isOpen}
      onClose={onClose}
      toggleRef={toggleRef}
      {...fullScreenBannerProps}
    >
      <h3 className="mobile-account-menu-banner__welcome-message">{welcomeMessage}</h3>
      <div className="mobile-account-menu-banner__top-bar">
        <button className="mobile-account-menu-banner__close-button button-reset" onClick={onClose}>
          <i />
        </button>
      </div>

      <div className="mobile-account-menu-banner__content">
        <ul className="mobile-account-menu-banner__menu-list">
          {accountMenuOptions.map((item) => {
            return (
              <li
                className={classNames('mobile-account-menu-banner__menu-item')}
                key={item.value}
                onClick={() => {
                  onLogOutClick();
                }}
              >
                <span>{item.label}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </FullScreenBanner>
  );
};
