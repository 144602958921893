import { Routes } from 'constants/routes.enum';
import { allowedShopLocales, SHOP_LANGUAGES } from 'constants/shop-locales';
import { getLocaleURLByCode } from './get-locale-params';

export const getLocalizedShopUrl = (route: Routes) => {
  const locale = localStorage.getItem('locale') as SHOP_LANGUAGES;
  if (locale && allowedShopLocales.includes(JSON.parse(locale))) {
    const localeUrl = getLocaleURLByCode(JSON.parse(locale));
    return `${localeUrl}${route}`;
  }

  return route;
};
