import { IUserInfo } from 'types/user-info';
import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import { getUserInfoActionConstants } from 'store/auth/actions';
import types from './action-types';

export interface IAuthState {
  user: IUserInfo;
}

export const authInitialState: IAuthState = {
  user: {
    loaded: false,
  },
};

const auth = createReducer<IAuthState>(
  {
    [getUserInfoActionConstants[Actions.REQUEST]]: (state) => ({
      user: { ...state.user, loaded: false },
    }),
    [getUserInfoActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      user: { ...state.user, ...payload, loaded: true },
    }),
    [getUserInfoActionConstants[Actions.FAIL]]: (state) => ({
      user: { ...state.user, loaded: true },
    }),
    [types.UNLOAD_USER]: () => authInitialState,
  },
  authInitialState
);

export default auth;
