import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/you-may-also-need-items/actions';
import { Actions } from 'constants/actions.enum';
import { ISkuInformation } from 'types/order-details';
import types from 'store/you-may-also-need-items/action-types';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import { ILineItem } from 'types/line-item';

export interface IYouMayAlsoNeedItemsState {
  loading: boolean;
  youMayAlsoNeedItemsData: ISkuInformation[];
}

export const youMayAlsoNeedItemsInitialState: IYouMayAlsoNeedItemsState = {
  loading: false,
  youMayAlsoNeedItemsData: [],
};

const youMayAlsoNeedItems = createReducer<IYouMayAlsoNeedItemsState>(
  {
    [actions.getYouMayAlsoNeedItemsConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
      youMayAlsoNeedItemsData: [],
    }),
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      youMayAlsoNeedItemsData: state.youMayAlsoNeedItemsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
    }),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      youMayAlsoNeedItemsData: state.youMayAlsoNeedItemsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
    }),
    [actions.getYouMayAlsoNeedItemsConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      loading: false,
      youMayAlsoNeedItemsData: payload,
    }),
    [actions.getYouMayAlsoNeedItemsConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
      youMayAlsoNeedItemsData: [],
    }),
    [types.CLEAR_YOU_MAY_ALSO_NEED_ITEMS]: (state) => ({ ...state, youMayAlsoNeedItemsData: [] }),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      youMayAlsoNeedItemsData: state.youMayAlsoNeedItemsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
    }),
  },
  youMayAlsoNeedItemsInitialState
);

export default youMayAlsoNeedItems;
