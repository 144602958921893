export enum UTILITY_LINK_TYPES {
  CART = 'cart',
  NOTIFICATIONS = 'notifications',
}

export enum HEADER_STATIC_LABELS {
  CORPORATE_NAV = 'Corporate Nav',
  SHOP_LABEL = 'Shop',
  ALL_CATEGORIES = 'All Categories',
  SEARCH = 'Search',
  UTILITY_MENU = 'Utility Menu',
  ACCOUNT = 'Account',
  ACCOUNT_HI = 'Hi,',
}

export enum HEADER_CONTENTSTACK_CONFIG {
  PARAM_AUTHENTICATED = 'authenticated-header',
  PARAM_UNAUTHENTICATED = 'unauthenticated-header',
  TYPE = 'unified_header',
  REF_CORP = 'header_menu_reference',
  REF_SUPPORT = 'support_block',
  REF_SHOP = 'shop_header_menu_reference',
  LABELS = 'header_labels',
}

export enum HEADER_FUNCTIONAL_CLASSES {
  CORPORATE_NAV_CLASS = 'corporate-mobile-nav',
}

export enum ALL_PRODUCTS_KEYS {
  NEW_ARRIVALS = 'new-arrivals',
  ADDITIONAL_PRODUCTS = 'additional-products',
  NEW_ARRIVALS_QUERY_STRING = 'filters=new-arrivals',
}

export enum ALL_PRODUCTS_LABELS {
  NEW_ARRIVALS = 'New Arrivals',
}
