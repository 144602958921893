import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { submitAccountRetrieval, submitAccountRetrievalActionConstants } from '../actions';

export interface ISubmitAccountRetrievalPayload {
  invoiceId: string;
  invoiceAmount: string;
}

export function* submitAccountRetrievalSagaHandler({ payload }: IAction<ISubmitAccountRetrievalPayload>) {
  yield put(
    doRequest<ISubmitAccountRetrievalPayload>(submitAccountRetrieval, Endpoints.ACCOUNT_RETRIEVAL, 'post', payload)
  );
}

export default function* submitAccountRetrievalSaga() {
  yield takeLatest(submitAccountRetrievalActionConstants[Actions.REQUEST], submitAccountRetrievalSagaHandler);
}
