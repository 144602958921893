import { takeLatest, put } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { setCartMessageViewed, setCartMessageViewedActionConstants } from 'store/shopping-cart/actions';

export interface ISetCartMessageViewedPayload {
  type?: 'ALL' | 'DISCONTINUED_WITH_REPLACEMENT';
}

export function* setCartMessageViewedSagaHandler({ payload }: IAction<ISetCartMessageViewedPayload>) {
  yield put(doRequest(setCartMessageViewed, `${Endpoints.SET_CART_MESSAGE_VIEWED}?type=${payload.type}`, 'patch'));
}

export default function* setCartMessageViewedSaga() {
  yield takeLatest(setCartMessageViewedActionConstants[Actions.REQUEST], setCartMessageViewedSagaHandler);
}
