import { all, spawn } from 'redux-saga/effects';
import submitLoginSaga from './submit-login';
import submitLocationSaga from './submit-location';
import getLocationsSaga from './get-locations';
import getAnalyticsVariablesSaga from './get-session-params';

export default function* customerLoginSagas() {
  yield all([
    spawn(submitLoginSaga),
    spawn(submitLocationSaga),
    spawn(getLocationsSaga),
    spawn(getAnalyticsVariablesSaga),
  ]);
}
