import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/dashboard/actions';
import { Actions } from 'constants/actions.enum';
import types from 'store/dashboard/action-types';
import { IFormWithServerErrors } from 'store/dashboard/reducer';
import { csrCustomerFormInitialState } from './csr-customer-email-form';

export const csrCustomerPasswordForm = createReducer<IFormWithServerErrors>(
  {
    [actions.changeCustomerPasswordConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isSubmitting: true,
      serverError: '',
    }),
    [actions.changeCustomerPasswordConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isSubmitting: false,
      serverError: '',
    }),
    [actions.changeCustomerPasswordConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      isSubmitting: false,
      serverError: error.errorMessage || '',
    }),
    [types.CLEAR_CSR_FORM_SERVER_ERRORS]: (state) => ({
      ...state,
      isSubmitting: false,
      serverError: '',
    }),
  },
  csrCustomerFormInitialState
);
