import { FC } from 'react';
import { onNestedLinkClick } from 'utils/link-utils';
import { useContent } from 'hooks/use-content';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { RenderIfNonEmptyContent } from 'components/conditional-render';
import { ContentstackImage, ContentstackText } from 'components/contentstack';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';

import './modify-your-order-modal.scss';

export const ModifyYourOrderModal: FC<IModalProps> = ({ isOpen = false, onClose = () => {} }) => {
  const contentstackPath = 'modals.0.modify_your_order_modal.0';
  const { getContentByKey } = useContent();
  const href = getContentByKey<string>(`${contentstackPath}.learn_more_link`, '');

  return (
    <Modal className="modify-your-order-modal" isOpen={isOpen} onClose={onClose} withBackdropClick>
      <ModalHeader titleContentstackPath={`${contentstackPath}.heading`} />
      <div className="modify-your-order-modal__content">
        <RenderIfNonEmptyContent keys={[`${contentstackPath}.description`]}>
          <div
            className="modify-your-order-modal__description"
            dangerouslySetInnerHTML={{
              __html: getContentByKey<string>(`${contentstackPath}.description`, ''),
            }}
            onClick={(e) => onNestedLinkClick(e, true, onClose)}
          />
        </RenderIfNonEmptyContent>
        <RenderIfNonEmptyContent keys={[`${contentstackPath}.note_text`]}>
          <div className="modify-your-order-modal__note-text">
            <ContentstackImage
              contentKey={`${contentstackPath}.note_icon`}
              className="modify-your-order-modal__note-text-icon"
            />
            <ContentstackText contentKey={`${contentstackPath}.note_text`} />
          </div>
        </RenderIfNonEmptyContent>
      </div>
      <RenderIfNonEmptyContent keys={[`${contentstackPath}.learn_more_button`]}>
        <ModalFooter>
          <a href={href} target="_blank" rel="noopener noreferrer">
            <CorButton color="primary modify-your-order-modal__learn-more-button" onClick={() => onClose()}>
              <ContentstackText contentKey={`${contentstackPath}.learn_more_button`} />
            </CorButton>
          </a>
        </ModalFooter>
      </RenderIfNonEmptyContent>
    </Modal>
  );
};
