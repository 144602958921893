import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectIsBlockedAccount } from 'store/auth/selectors';
import { NavigationTiles } from 'components/navigation-tiles';
import { OrderUpdates } from 'features/order-updates';
import { clearHomeTabsData } from 'store/home/actions';
import { ASpotBanner } from './components/a-spot-banner';
import { FeaturedProductsCarousel } from './components/featured-products-carousel';
import { RecentlyViewedItemsCarousel } from './components/recently-viewed-items-carousel';

import './home.scss';

export const Home: FC = () => {
  const isBlockedAccount = useSelector(selectIsBlockedAccount);
  const homePageClassName = classNames('home', {
    'home--is-blocked-account': isBlockedAccount,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearHomeTabsData());
    };
  }, [dispatch]);

  return (
    <div className={homePageClassName}>
      <div className="order-updates-wrapper">
        <div className="grid-container">
          <OrderUpdates />
        </div>
      </div>
      <ASpotBanner />
      <div className="grid-container page-content">
        <FeaturedProductsCarousel />
        <NavigationTiles contentstackPath="page_content.navigation_tiles_list.0" />
        <RecentlyViewedItemsCarousel />
      </div>
    </div>
  );
};
