enum orderCutOffActionTypes {
  GET_CART_EXPIRED_NOTIFICATION_VIEWED = 'GET_CART_EXPIRED_NOTIFICATION_VIEWED',
  CUTOFF_NOTIFICATION_VIEWED = 'CUTOFF_NOTIFICATION_VIEWED',
  GET_CUTOFF_NOTIFICATION_DATETIME = 'GET_CUTOFF_NOTIFICATION_DATETIME',
  CLEAR_CUTOFF_NOTIFICATION_DATETIME = 'CLEAR_CUTOFF_NOTIFICATION_DATETIME',
  SET_CUTOFF_NOTIFICATION_VISIBLE = 'SET_CUTOFF_NOTIFICATION_VISIBLE',
  SET_CUTOFF_NOTIFICATION_EXPIRED = 'SET_CUTOFF_NOTIFICATION_EXPIRED',
}

export default orderCutOffActionTypes;
