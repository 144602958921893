import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { dismissQuote, dismissQuoteActionConstants, getQuotes } from 'store/quotes/actions';
import { IAction } from 'types/actions';

interface IDismissQuotePayload {
  quoteNumber: string;
}

export function* dismissQuoteSagaHandler({ payload: { quoteNumber } }: IAction<IDismissQuotePayload>) {
  yield put(doRequest(dismissQuote, `${Endpoints.QUOTES}/${quoteNumber}/dismiss`, 'patch'));
}

export function* dismissQuoteOnSuccessCallBackSagaHandler() {
  yield take(dismissQuoteActionConstants[Actions.SUCCESS]);
  yield put(getQuotes.request());
}

export default function* dismissQuoteSaga() {
  yield takeLatest(dismissQuoteActionConstants[Actions.REQUEST], dismissQuoteSagaHandler);
  yield takeLatest(dismissQuoteActionConstants[Actions.REQUEST], dismissQuoteOnSuccessCallBackSagaHandler);
}
