import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getLocationsByDayOfWeek, getLocationsByDayOfWeekConstants } from 'store/dashboard/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IGetLocationsByDayOfWeekPayload {
  searchText?: string;
}

export function* getLocationsByDayOfWeekHandler({
  payload: { searchText = '' },
}: IAction<IGetLocationsByDayOfWeekPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText?.trim());

  yield put(
    doRequest(
      getLocationsByDayOfWeek,
      `${Endpoints.DASHBOARD_MSR_LOCATIONS_BY_DAY_OF_WEEK}?searchText=${searchQuery}`,
      'get'
    )
  );
}

export default function* getLocationsByDayOfWeekSaga() {
  yield takeLatest(getLocationsByDayOfWeekConstants[Actions.REQUEST], getLocationsByDayOfWeekHandler);
}
