import './cor-chip.scss';

import { CorTypography } from 'corporate-ui';
import React from 'react';
import iconClose from 'assets/images/icons/icon-close.svg';
import { IconWithHover } from 'components/icon-with-hover';

const CorChip = ({ label = '', onClick = () => {} }) => {
  return (
    <div className="cor-chip" role="button">
      {!!label && (
        <CorTypography variant="body-small" color="dark-gray">
          {label}
        </CorTypography>
      )}

      <IconWithHover
        icon={iconClose}
        hoverIcon={iconClose}
        alt="Close Chip"
        className="cor-chip__close-button"
        onClick={onClick}
      />
    </div>
  );
};

export default React.memo(CorChip);
