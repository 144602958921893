import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/more-products-from-brand/actions';
import { Actions } from 'constants/actions.enum';
import { ISkuInformation } from 'types/order-details';
import types from 'store/more-products-from-brand/action-types';

export interface IMoreProductsFromBrandState {
  loading: boolean;
  moreProductsFromBrand: ISkuInformation[];
}

export const moreProductsFromBrandInitialState: IMoreProductsFromBrandState = {
  loading: false,
  moreProductsFromBrand: [],
};

const moreProductsFromBrand = createReducer<IMoreProductsFromBrandState>(
  {
    [actions.getMoreProductsFromBrandConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
      moreProductsFromBrand: [],
    }),
    [actions.getMoreProductsFromBrandConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      loading: false,
      moreProductsFromBrand: payload,
    }),
    [actions.getMoreProductsFromBrandConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
      moreProductsFromBrand: [],
    }),
    [types.CLEAR_MORE_PRODUCTS_FROM_BRAND]: (state) => ({ ...state, moreProductsFromBrand: [] }),
  },
  moreProductsFromBrandInitialState
);

export default moreProductsFromBrand;
