import {
  FC,
  SyntheticEvent,
  KeyboardEvent,
  ClipboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { ErrorMessage, Field, Formik, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { MultiValue, SingleValue } from 'react-select';
import classNames from 'classnames';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { omit } from 'lodash-es';
import { filterFormikErrors, pricePattern } from 'utils/validation';
import { getFormattedDeliveryDate, getFormattedExpireDate } from 'utils/date-format';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { useContent } from 'hooks/use-content';
import { TextField } from 'components/forms/text-field';
import { CustomTooltip } from 'components/custom-tooltip';
import { CorButton } from 'components/cor-button';
import { QuantityControl } from 'components/quantity-control';
import { SkuQuantityLimitationAlert } from 'components/sku-quantity-limitation-alert';
import { SkuQuantityLimitationWarning } from 'components/sku-quantity-limitation-warning';
import { ContentstackMessage } from 'components/contentstack';
import { SelectField } from 'components/forms/select-field';
import { IManageQuoteValues } from 'types/quotes';
import { clearApproveQuoteFormServerError, getDeliveryDates } from 'store/quotes/actions';
import { createValidationSchema, priceValidator, expireDateValidator, quoteCommentValidator } from 'utils/validation';
import { FormWithValidationOnSubmit, IFormState } from 'components/forms/form-with-validation-on-submit';
import { IOptionType } from 'components/custom-dropdown';
import { ErrorNotification } from 'components/forms/error-notification';
import { checkIfDateIsWithinRange } from 'utils/check-if-date-is-within-range';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { selectActiveQuoteDeliveryDate, selectApproveQuoteForm } from 'store/quotes/selectors';
import calendarIcon from 'assets/images/icons/icon-calendar.svg';

import './manage-quote-modal.scss';

export interface IManageQuoteModalProps extends IModalProps {
  quote: IManageQuoteValues;
  onApproveSubmit: (values: any, resetForm: () => void) => void;
  openCancelRequestModal: () => void;
}

export interface IManageQuoteFormValues {
  quantity: string;
  price: string;
  expireDate: string;
  estimatedDeliveryDate: IOptionType[];
  comment?: string;
}

export const ManageQuoteModal: FC<IManageQuoteModalProps> = ({
  isOpen = false,
  onClose = () => {},
  quote,
  openCancelRequestModal,
  onApproveSubmit,
}) => {
  const contentStackPath = 'modals.0.manage_request_modal.0';
  const { getContentByKey, getMessageText } = useContent();
  const dispatch = useDispatch();

  const quantityInputRef = useRef<HTMLInputElement>(null);
  const { locationId, quantity, customerMessage, currencySymbol } = quote;
  const minimumOrderQuantity = quote?.minimumOrderQuantity || 1;
  const hasMinimumQuantityLimitation = Number(minimumOrderQuantity) > 1;
  const hasMaximumQuantityLimitation = !!quote?.maximumQuantity && quote?.maximumQuantity > 0;
  const isShortSupplySku = quote?.maximumQuantity === 0;

  const estimatedDeliveryDateOptionsDefault = {
    value: '',
    label: getContentByKey(`${contentStackPath}.estimated_delivery_date_default_value`, ''),
  };

  const getExpireDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 7);

    return getFormattedExpireDate(today);
  };
  const expireDate = useMemo(getExpireDate, []);

  const initialValues: IManageQuoteFormValues = {
    quantity: quantity.toString(),
    price: '',
    expireDate,
    estimatedDeliveryDate: [estimatedDeliveryDateOptionsDefault],
  };

  const expireDateValidatorConfig = {
    required: getMessageText('error', 'MSG070'),
    wrongFormat: getMessageText('error', 'MSG071'),
  };

  const validationSchema = createValidationSchema({
    price: priceValidator({
      required: getMessageText('error', 'MSG070'),
      wrongFormat: getMessageText('error', 'MSG071'),
    }),
    expireDate: expireDateValidator(expireDateValidatorConfig),
    quantity: Yup.string(),
    comment: quoteCommentValidator(
      {
        required: getMessageText('error', 'MSG070'),
      },
      'quantity',
      quantity.toString()
    ),
  });

  const estimatedDeliveryDates = useSelector(selectActiveQuoteDeliveryDate);
  const { isSubmitting, serverError } = useSelector(selectApproveQuoteForm);

  const [estimatedDeliveryDateOptions, setEstimatedDeliveryDateOptions] = useState([
    estimatedDeliveryDateOptionsDefault,
  ]);
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState(estimatedDeliveryDateOptionsDefault.value);
  const [isValidUntilDateWithinRange, setIsValidUntilDateWithinRange] = useState(true);

  const isValidUntilFieldEmpty = (values: IManageQuoteFormValues) => values.expireDate === '';

  const updateEstimatedDeliveryDateOptions = () => {
    const newOptions = estimatedDeliveryDates?.map((date) => {
      return {
        value: date,
        label: getFormattedDeliveryDate(date),
      };
    });

    if (newOptions?.length) {
      setEstimatedDeliveryDateOptions([estimatedDeliveryDateOptionsDefault, ...newOptions]);
    } else {
      setEstimatedDeliveryDateOptions([estimatedDeliveryDateOptionsDefault]);
    }
  };

  useEffect(() => {
    updateEstimatedDeliveryDateOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedDeliveryDates, estimatedDeliveryDateOptionsDefault.label]);

  useEffect(() => {
    if (locationId && expireDate) {
      dispatch(getDeliveryDates.request({ locationId, quoteExpireDate: expireDate }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locationId, expireDate]);

  const onSubmit = (
    values: IManageQuoteFormValues,
    { resetForm, setTouched }: FormikHelpers<IManageQuoteFormValues>
  ) => {
    const isValidUntilDateWithinRange = checkIfDateIsWithinRange(new Date(values.expireDate), 30);

    setIsValidUntilDateWithinRange(isValidUntilDateWithinRange);

    if (isValidUntilDateWithinRange) {
      onApproveSubmit(values, () => {
        resetForm();
        setTouched({}, false);
        setEstimatedDeliveryDate(estimatedDeliveryDateOptionsDefault.value);
        setIsValidUntilDateWithinRange(true);
      });
    }
  };

  const clearServerError = useCallback(() => {
    if (serverError) {
      dispatch(clearApproveQuoteFormServerError());
    }
  }, [serverError, dispatch]);

  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');

  const closeModalHandler = (
    dataWasChanged: boolean,
    { resetForm, setTouched }: Partial<FormikHelpers<IManageQuoteFormValues>>
  ) => {
    const resetFormToInitialState = () => {
      resetForm && resetForm();
      setTouched && setTouched({}, false);
      setEstimatedDeliveryDate(estimatedDeliveryDateOptionsDefault.value);
      setIsValidUntilDateWithinRange(true);
    };

    if (dataWasChanged) {
      const closingIsAllowed = window.confirm(leavingPageModalText);
      if (closingIsAllowed) {
        resetFormToInitialState();
        dispatch(clearApproveQuoteFormServerError());
        onClose();
      }

      return;
    }

    resetFormToInitialState();
    onClose();
  };

  const containsEmptyRequiredFields = (values: IManageQuoteFormValues, withComment: boolean) =>
    Object.values(withComment ? values : omit(values, 'comment')).some((value) => value === '');

  const priceFieldPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const isNotNumericKeyCode = e.which < 48 || e.which > 57;
    const isEnterPressed = e.key === 'Enter';
    const isDotPressed = e.key === '.';
    if (isNotNumericKeyCode && !isEnterPressed && !isDotPressed) {
      e.preventDefault();
    }
  };

  const priceFieldPasteHandler = (e: ClipboardEvent) => {
    if (!pricePattern.test(e.clipboardData.getData('Text'))) {
      e.preventDefault();
    }
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(props) => {
        const {
          dirty,
          handleSubmit,
          values,
          errors,
          setFieldValue,
          setErrors,
          isValid,
          touched,
          resetForm,
          handleBlur,
          setTouched,
        } = props;
        const syncQuantityWithFormik = (newQuantity?: number) => {
          newQuantity && setFieldValue('quantity', newQuantity);
          if (String(quantity) === String(newQuantity)) {
            setErrors({ ...errors, comment: undefined });
            setTouched({ ...touched, comment: false });
          }
        };

        return (
          <Modal
            isOpen={isOpen}
            size="medium_large"
            onClose={() =>
              closeModalHandler(dirty, {
                resetForm,
                setTouched,
              })
            }
            className="manage-quote-modal"
            withBackdropClick
            isGlobalModal
          >
            <ModalHeader>
              <h1 className="manage-quote-modal__title">
                <ContentstackText contentKey={`${contentStackPath}.manage_request_modal_header`} />
              </h1>
            </ModalHeader>
            <div className="manage-quote-modal__message">
              {customerMessage && (
                <p className="manage-quote-modal__customers-message">
                  <span className="manage-quote-modal__message-title">
                    <ContentstackText contentKey={`${contentStackPath}.customers_message_title`} />
                  </span>
                  {customerMessage}
                </p>
              )}
            </div>
            <FormWithValidationOnSubmit
              onChange={(event: SyntheticEvent) => {
                clearServerError();
                setErrors(filterFormikErrors(errors, (event.target as HTMLInputElement).name));
              }}
              {...{ handleSubmit, isValid, dirty }}
            >
              {({ setInvalidFormIsSubmitted, invalidFormIsSubmitted }: IFormState) => {
                const createFieldOnClickHandler = (fieldName: keyof IManageQuoteFormValues) => () => {
                  if (errors[fieldName] || serverError) {
                    clearServerError();
                    setInvalidFormIsSubmitted(false);
                    setErrors({ ...errors, [fieldName]: undefined });
                  }
                };
                const commentRequired = String(values.quantity) !== String(quantity);
                const commentRequiredAndEmpty = commentRequired && Boolean(!values.comment?.length);

                return (
                  <>
                    <div className="manage-quote-modal__content-wrapper">
                      {(invalidFormIsSubmitted || serverError || !isValidUntilDateWithinRange) && (
                        <ErrorNotification
                          message={
                            serverError ||
                            (!isValidUntilDateWithinRange && getMessageText('error', 'MSG072')) ||
                            getMessageText('error', 'MSG041')
                          }
                        />
                      )}
                      {isShortSupplySku && (
                        <SkuQuantityLimitationWarning className="manage-quote-modal__short-supply-warning" bordered>
                          <ContentstackMessage type="notifications" messageId="MSG091" />
                        </SkuQuantityLimitationWarning>
                      )}
                      <div className="manage-quote-modal__info">
                        <div className="manage-quote-modal__info-block">
                          <p className="manage-quote-modal__info-label">
                            <ContentstackText contentKey={`${contentStackPath}.quote_number_label`} />
                          </p>
                          <p className="manage-quote-modal__info-value--quote-number">{quote.quoteNumber}</p>
                        </div>
                        <div className="manage-quote-modal__info-block">
                          <p className="manage-quote-modal__info-label">
                            <ContentstackText contentKey={`${contentStackPath}.quantity_label`} />
                          </p>
                          <div className="manage-quote-modal__info-value">
                            <QuantityControl
                              quantity={quantity}
                              minQuantity={minimumOrderQuantity}
                              maxQuantity={quote?.maximumQuantity}
                              contentStackPath={contentStackPath}
                              inputRef={quantityInputRef}
                              wrapInForm={false}
                              isFormik={true}
                              name="quantity"
                              onIncreaseQuantityHandler={syncQuantityWithFormik}
                              onDecreaseQuantityHandler={syncQuantityWithFormik}
                              setCurrentQuantity={syncQuantityWithFormik}
                              // TODO: P12020-12794 remove after done
                              // setDefaultQuantity={syncQuantityWithFormik}
                              disabled={isShortSupplySku}
                            />
                          </div>
                        </div>
                        <div className="manage-quote-modal__info-block-wrapper">
                          <div className="manage-quote-modal__info-block">
                            <div className="manage-quote-modal__info-label info-label-price">
                              <ContentstackText contentKey={`${contentStackPath}.price_label`} />
                              <CustomTooltip tooltipId="manage-quote-tooltip--price">
                                <ContentstackMessage type="tooltips" messageId="MSG110" />
                              </CustomTooltip>
                            </div>
                            <div className="manage-quote-modal__info-value info-block__price">
                              <TextField
                                id="price"
                                label=""
                                name="price"
                                className="manage-quote-modal__info-input"
                                onClick={createFieldOnClickHandler('price')}
                                onKeyPress={priceFieldPressHandler}
                                onPaste={priceFieldPasteHandler}
                                placeholder={getContentByKey<string>(`${contentStackPath}.price_field_placeholder`, '')}
                                disabled={isShortSupplySku}
                              >
                                <span className="currency-symbol">{currencySymbol}</span>
                              </TextField>
                            </div>
                          </div>
                          <div className="manage-quote-modal__info-block">
                            <div className="manage-quote-modal__info-label info-label-valid-until">
                              <ContentstackText contentKey={`${contentStackPath}.valid_until_label`} />
                              <CustomTooltip tooltipId="manage-quote-tooltip--valid-until">
                                <ContentstackMessage type="tooltips" messageId="MSG109" />
                              </CustomTooltip>
                            </div>
                            <div className="manage-quote-modal__info-value info-block__valid-until">
                              <TextField
                                id="expireDate"
                                label=""
                                name="expireDate"
                                component={InputMask}
                                mask="99/99/99"
                                maskPlaceholder={null}
                                value={values['expireDate']}
                                className={classNames('manage-quote-modal__info-input', {
                                  'text-field--error': !isValidUntilDateWithinRange,
                                })}
                                placeholder={getContentByKey<string>(
                                  `${contentStackPath}.valid_until_field_placeholder`,
                                  ''
                                )}
                                onClick={createFieldOnClickHandler('expireDate')}
                                onChange={(e: SyntheticEvent) => {
                                  let value = (e.target as HTMLInputElement).value;

                                  if (values['expireDate'] !== value) {
                                    setIsValidUntilDateWithinRange(true);
                                    setFieldValue('expireDate', value);
                                    setFieldValue('estimatedDeliveryDate', estimatedDeliveryDateOptionsDefault);
                                    setEstimatedDeliveryDate(estimatedDeliveryDateOptionsDefault.value);
                                  }
                                }}
                                onBlur={(e: SyntheticEvent) => {
                                  let error;

                                  try {
                                    expireDateValidator(expireDateValidatorConfig).validateSync(values['expireDate']);
                                  } catch (e) {
                                    error = e;
                                  }

                                  if (!error && checkIfDateIsWithinRange(new Date(values.expireDate), 30)) {
                                    dispatch(
                                      getDeliveryDates.request({ locationId, quoteExpireDate: values.expireDate })
                                    );
                                  } else {
                                    setEstimatedDeliveryDateOptions([estimatedDeliveryDateOptionsDefault]);
                                  }
                                  handleBlur(e);
                                }}
                                disabled={isShortSupplySku}
                              >
                                <img src={calendarIcon} alt="" />
                              </TextField>
                            </div>
                          </div>
                        </div>
                        <div className="manage-quote-modal__info-block manage-quote-modal__estimated-delivery">
                          <p className="manage-quote-modal__info-label">
                            <ContentstackText contentKey={`${contentStackPath}.estimated_delivery_date_label`} />
                          </p>
                          <div className="manage-quote-modal__info-value">
                            <Field
                              component={SelectField}
                              value={estimatedDeliveryDate}
                              options={estimatedDeliveryDateOptions}
                              name="estimatedDeliveryDate"
                              onChange={(
                                estimatedDeliveryDateOption: MultiValue<IOptionType> | SingleValue<IOptionType>
                              ) => {
                                const newOption = estimatedDeliveryDateOption as IOptionType;
                                const value = newOption.value;
                                setFieldValue('estimatedDeliveryDate', newOption);
                                setEstimatedDeliveryDate(value);
                              }}
                              disabled={isValidUntilFieldEmpty(values) || isShortSupplySku}
                            />
                          </div>
                        </div>
                      </div>
                      {!isShortSupplySku && (hasMinimumQuantityLimitation || hasMaximumQuantityLimitation) && (
                        <div className="manage-quote-modal__quantity-notifications">
                          {hasMinimumQuantityLimitation && (
                            <SkuQuantityLimitationAlert className="manage-quote-modal__quantity-notification">
                              <ContentstackMessage
                                type="notifications"
                                messageId="MSG077"
                                interpolateParams={{
                                  minimumOrderQuantity: minimumOrderQuantity,
                                }}
                              />
                            </SkuQuantityLimitationAlert>
                          )}
                          {hasMaximumQuantityLimitation && (
                            <SkuQuantityLimitationAlert className="manage-quote-modal__quantity-notification">
                              <ContentstackMessage
                                type="notifications"
                                messageId="MSG092"
                                interpolateParams={{
                                  maximumOrderQuantity: quote?.maximumQuantity,
                                }}
                              />
                            </SkuQuantityLimitationAlert>
                          )}
                        </div>
                      )}
                      <div className="manage-quote-modal__comment">
                        {commentRequired && (
                          <p className="text-field__error-message error-message-hint">
                            <ContentstackMessage type="notifications" messageId="MSG111" />
                          </p>
                        )}
                        <label className="manage-quote-modal__comment-label" htmlFor="comment">
                          <ContentstackText contentKey={`${contentStackPath}.message_field_label`} />
                        </label>
                        <Field
                          as="textarea"
                          rows={4}
                          maxLength={300}
                          className={classNames('manage-quote-modal__comment-input', 'textarea-field', {
                            'textarea-field--error': errors['comment'] && touched['comment'],
                          })}
                          id="comment"
                          name="comment"
                          onClick={createFieldOnClickHandler('comment')}
                          placeholder={getContentByKey<string>(`${contentStackPath}.message_field_placeholder`, '')}
                          disabled={isShortSupplySku}
                        />
                        <ErrorMessage className="text-field__error-message" name="comment" component="p" />
                      </div>
                    </div>

                    <ModalFooter className="manage-quote-modal__modal-footer">
                      <CorButton
                        className="manage-quote-modal__button text"
                        onClick={() =>
                          closeModalHandler(dirty, {
                            resetForm,
                            setTouched,
                          })
                        }
                      >
                        <ContentstackText contentKey={`${contentStackPath}.go_back_button_label`} />
                      </CorButton>
                      <CorButton className="manage-quote-modal__button secondary" onClick={openCancelRequestModal}>
                        <ContentstackText contentKey={`${contentStackPath}.cancel_request_button_label`} />
                      </CorButton>
                      <CorButton
                        type="submit"
                        className="manage-quote-modal__approve-button"
                        disabled={
                          isSubmitting ||
                          commentRequiredAndEmpty ||
                          containsEmptyRequiredFields(values, commentRequired) ||
                          isShortSupplySku
                        }
                      >
                        <ContentstackText
                          contentKey={`${contentStackPath}.send_to_customer_for_approval_button_label`}
                        />
                      </CorButton>
                    </ModalFooter>
                  </>
                );
              }}
            </FormWithValidationOnSubmit>
          </Modal>
        );
      }}
    </Formik>
  );
};
