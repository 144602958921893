import { format } from 'date-fns';
import { isDate } from 'lodash-es';
import { Routes } from 'constants/routes.enum';
import {
  IAlgoliaCard,
  IAlgoliaContentTypesEnum,
  IAlgoliaTagsEnum,
  IPageType,
  IProduct,
} from './use-algolia-query.interface';

const filtersCategoryMapper = (entry: IPageType) => {
  return entry?.filter_tags?.filter((tag) => tag)?.join(' | ') || '';
};

const newsCategoryMapper = () => {
  return 'News';
};

const CategoryMapperMap = new Map([
  [IAlgoliaContentTypesEnum.RecipeDetailPage, filtersCategoryMapper],
  [IAlgoliaContentTypesEnum.InsightsDetailPage, filtersCategoryMapper],
  [IAlgoliaContentTypesEnum.NewsPage, newsCategoryMapper],
]);

interface IAlgoliaQueryMapperProps {
  entry: IPageType;
  categoryTag: IAlgoliaTagsEnum | undefined;
}

export const algoliaQueryMapper = ({ entry, categoryTag }: IAlgoliaQueryMapperProps): IAlgoliaCard => {
  return {
    pageUrl: { href: entry.url, title: entry.meta_title },
    title: entry.title,
    category: CategoryMapperMap.get(entry.type)?.(entry) || '',
    description:
      categoryTag && [IAlgoliaTagsEnum.CorporateProducts].includes(categoryTag)
        ? ''
        : entry.short_description || entry.meta_description,
    image: {
      image: {
        url: entry.search_image || entry.background_image,
        title: entry.title,
      },
      brand_folder_image: [
        {
          cdn_url: entry.search_brand_folder_image || entry.background_brand_folder_image,
          title: entry.title,
        },
      ],
      image_alt_text: entry.search_image_alt_text || entry.background_image_alt_text,
    },
    ...(isDate(entry.publication_date) &&
      entry.publication_date && {
        publicationDate: format(new Date(entry.publication_date), 'MMMM d, yyyy'),
      }),
  };
};

export const algoliaQueryShopProductsMapper = (entry: IProduct): IAlgoliaCard => {
  const sku = entry?.skus?.[0];
  const price = `${sku?.price?.currencySymbol} ${sku?.price?.amount}`;

  return {
    pageUrl: { href: `${Routes.ProductDetailsPage}/${parseInt(sku?.sku || '')}`, title: entry.name },
    title: entry.name,
    ...(!!sku?.price && { description: price }),
    image: { image: { url: entry.imageUrl, title: entry.name }, image_alt_text: entry.name },
    sku: sku,
  };
};
