import { combineReducers } from 'redux';
import { csrCustomerEmailForm } from './csr-customer-email-form';
import { csrCustomerPasswordForm } from './csr-customer-password-form';
import { hasUnsavedChanges } from './has-unsaved-changes';

export const csrCustomerForms = combineReducers({
  csrCustomerEmailForm,
  csrCustomerPasswordForm,
  hasUnsavedChanges,
});
