import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ProductImage, ISize } from 'components/product-image';

import './line-item-image.scss';

export interface ILineItemImageProps extends PropsWithChildren {
  title: string;
  desktopSize: ISize;
  mobileSize?: ISize;
  src: string;
  className?: string;
  renderIndicator?: () => ReactNode;
  renderStatus?: () => ReactNode;
  withOverlay?: boolean;
}

export const LineItemImage: FC<ILineItemImageProps> = ({
  title,
  src,
  mobileSize,
  desktopSize,
  children,
  className: extraClass,
  renderIndicator,
  renderStatus,
  withOverlay = false,
  ...restProps
}) => {
  const { isMobile } = useBreakpoint();
  const imageSize = isMobile && mobileSize ? mobileSize : desktopSize;
  const className = classNames('line-item-image__wrapper', extraClass);

  return (
    <div
      className={className}
      style={{ minHeight: `${imageSize.height}px`, width: `${imageSize.width}px` }}
      {...restProps}
    >
      <ProductImage
        src={src}
        altText={title}
        mobileSize={mobileSize}
        desktopSize={desktopSize}
        withOverlay={withOverlay}
      />
      {renderIndicator && <div className="line-item-image__indicator">{renderIndicator()}</div>}
      {renderStatus && <div className="line-item-image__status">{renderStatus()}</div>}
    </div>
  );
};
