import { FC, KeyboardEvent, RefObject, SyntheticEvent, ChangeEvent, useEffect, useState } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { ContentstackImage } from 'components/contentstack';

import './quantity-control.scss';

interface IQuantityControlProps {
  quantity: number;
  minQuantity?: number;
  maxQuantity?: number;
  className?: string;
  disabled?: boolean;
  onDecreaseQuantityHandler?: (quantity?: number) => void;
  onIncreaseQuantityHandler?: (quantity?: number) => void;
  // TODO: P12020-12794 remove after done
  setDefaultQuantity?: () => void;
  setCurrentQuantity?: (quantity: number) => void;
  contentStackPath: string;
  inputRef: RefObject<HTMLInputElement>;
  wrapInForm?: boolean;
  isFormik?: boolean;
  name?: string;
  forceChange?: boolean;
}

export const QuantityControl: FC<IQuantityControlProps> = ({
  quantity,
  minQuantity = 1,
  maxQuantity,
  onDecreaseQuantityHandler,
  onIncreaseQuantityHandler,
  setDefaultQuantity,
  setCurrentQuantity,
  contentStackPath,
  inputRef,
  className,
  disabled = false,
  wrapInForm = true,
  isFormik = false,
  name,
  forceChange = false,
}) => {
  const [itemQuantity, setQuantity] = useState(quantity);

  useEffect(() => {
    if (forceChange) {
      setQuantity(quantity);
    }
  }, [quantity, forceChange]);

  const quantityKeyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const isNotNumericKeyCode = e.which < 48 || e.which > 57;
    const isEnterPressed = e.key === 'Enter';
    if (isNotNumericKeyCode && !isEnterPressed) {
      e.preventDefault();
    }
  };

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const increaseQuantity = () => {
    const quantityIncreasedValue = itemQuantity + 1;
    setQuantity(quantityIncreasedValue);
    onIncreaseQuantityHandler && onIncreaseQuantityHandler(quantityIncreasedValue);
  };

  const decreaseQuantity = () => {
    const parsedQuantity = itemQuantity;

    if (parsedQuantity <= 1) {
      setQuantity(1);
      return;
    }
    const decreasedQuantityValue = parsedQuantity - 1;
    setQuantity(decreasedQuantityValue);
    onDecreaseQuantityHandler && onDecreaseQuantityHandler(decreasedQuantityValue);
  };

  const changeQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    setQuantity(Number(currentValue));
  };

  const onBlurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    const quantityWithoutLeadingZero = currentValue.replace(/^0+/, '');

    if (minQuantity && Number(currentValue) < Number(minQuantity)) {
      setQuantity(minQuantity);
      setCurrentQuantity && setCurrentQuantity(minQuantity);
      return;
    }

    if (maxQuantity && Number(currentValue) > Number(maxQuantity)) {
      setQuantity(maxQuantity);
      setCurrentQuantity && setCurrentQuantity(maxQuantity);
      return;
    }

    setQuantity(Number(quantityWithoutLeadingZero));
    setCurrentQuantity && setCurrentQuantity(itemQuantity);
  };

  const quantityClassNames = classNames('quantity-control', 'grid-x', className, { disabled });

  const fieldAttributes = {
    className: 'quantity-control__input',
    type: 'number',
    value: String(itemQuantity),
    onChange: changeQuantity,
    onBlur: onBlurHandler,
    onKeyPress: quantityKeyPressHandler,
    'aria-label': 'Quantity',
    'data-testid': 'quantity-input',
    name,
  };

  const renderInput = () =>
    isFormik ? <Field {...fieldAttributes} innerRef={inputRef} /> : <input {...fieldAttributes} ref={inputRef} />;

  const isMinusButtonDisabled = disabled || Number(itemQuantity) <= Number(minQuantity);
  const isPlusButtonDisabled = disabled || Number(itemQuantity) >= Number(maxQuantity);

  return (
    <div className={quantityClassNames}>
      <button
        className="quantity-control__button-minus"
        type="button"
        aria-label="Decrease quantity"
        onClick={decreaseQuantity}
        disabled={isMinusButtonDisabled}
      >
        {isMinusButtonDisabled ? (
          <ContentstackImage contentKey={`${contentStackPath}.disabled_minus_icon`} />
        ) : (
          <ContentstackImage contentKey={`${contentStackPath}.minus_icon`} />
        )}
      </button>
      {!wrapInForm ? (
        renderInput()
      ) : (
        <form onSubmit={submitHandler} data-testid="quantity-control-form">
          {renderInput()}
        </form>
      )}
      <button
        className="quantity-control__button-plus"
        type="button"
        onClick={increaseQuantity}
        aria-label="Increase quantity"
        disabled={isPlusButtonDisabled}
      >
        {isPlusButtonDisabled ? (
          <ContentstackImage contentKey={`${contentStackPath}.disabled_plus_icon`} />
        ) : (
          <ContentstackImage contentKey={`${contentStackPath}.plus_icon`} />
        )}
      </button>
    </div>
  );
};
