import './cor-clear-filter-button.scss';

import { CorTypography } from 'corporate-ui';
import React from 'react';
import { useContent } from 'hooks/use-content';

const PREFIX_KEY = 'content_list.content_or_filtered_list.0';
const LABELS_KEY = `${PREFIX_KEY}.labels.0.key_value_pair.value`;

const CorClearFilterButton = ({ onClick = () => {} }) => {
  const { getLabelsByKey } = useContent();
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});

  return (
    <button className="cor-clear-filter-button" onClick={onClick}>
      <CorTypography variant="body-regular-link" component="span">
        {labels?.button_clear_filters || 'Clear Filters'}
      </CorTypography>
    </button>
  );
};

export default React.memo(CorClearFilterButton);
