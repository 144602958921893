import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getSkuInfo, getSkuInfoActionConstants } from 'store/quotes/actions';
import { IAction } from 'types/actions';

export interface IGetSkuInfoPayload {
  sku: string;
}

export function* getSkuInfoHandler({ payload: { sku } }: IAction<IGetSkuInfoPayload>) {
  const url = `${Endpoints.SKU_DETAILS}/${sku}/information`;
  yield put(doRequest(getSkuInfo, url, 'get'));
}

export default function* getSkuInfoSaga() {
  yield takeLatest(getSkuInfoActionConstants[Actions.REQUEST], getSkuInfoHandler);
}
