import { AnyAction } from 'redux';
import { IReducers } from 'types/reducers';

export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';
export const setDefaultStateAction = () => ({ type: SET_DEFAULT_STATE });

export const createReducer = <S>(reducers: IReducers<S>, initialState: S) => {
  return (state: S = initialState, action: AnyAction): S => {
    if (Reflect.has(reducers, action.type)) {
      return reducers[action.type](state, action);
    }
    if (action.type === SET_DEFAULT_STATE) {
      return initialState;
    }
    return state;
  };
};
