import qs from 'qs';
import { put, takeLatest } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getPaymentHistory, getPaymentHistoryActionConstants } from 'store/payment-history/actions';
import { IAction } from 'types/actions';

export interface IGetPaymentHistory {
  limit?: number;
  offset?: number;
}
export function* getPaymentHistorySagaHandler({ payload: { limit, offset } }: IAction<IGetPaymentHistory>) {
  const url = `${Endpoints.PAYMENT_HISTORY}/?${qs.stringify({
    limit,
    offset,
  })}`;

  yield put(doRequest(getPaymentHistory, url, 'get'));
}

export default function* getPaymentHistorySaga() {
  yield takeLatest(getPaymentHistoryActionConstants[Actions.REQUEST], getPaymentHistorySagaHandler);
}
