import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { CustomCheckbox } from 'components/custom-checkbox';
import { confirmCsrAccountUpdate, setHasUnsavedChanges, unlockCsrCustomerAccount } from 'store/dashboard/actions';
import { AccountActionsTypes } from 'constants/account-actions.enum';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { useContent } from 'hooks/use-content';
import { selectIsAccountNumberSearch } from 'store/dashboard/selectors';
import { AccountStatuses } from 'constants/account-statuses';
import { ContentstackMessage } from 'components/contentstack';
import iconSuccess from 'assets/images/icons/icon-success-indicator.svg';
import { ICsrFormProps } from '../customer-location-edit-section';

import './account-actions.scss';

export const AccountActions: FC<ICsrFormProps> = ({ accountAtLocation, locationId, onCancel }) => {
  const contentstackPath = 'modals.0.customer_location_item_edit_modal.0';
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();

  const isAccountNumberSearch = useSelector(selectIsAccountNumberSearch);
  const [isUnlockAccountCheckboxSelected, setUnlockAccountCheckboxSelected] = useState(false);
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);

  const unlockAccountSuccessHandler = () => {
    setUnlockAccountCheckboxSelected(false);
    setSuccessMessageVisible(true);
    dispatch(setHasUnsavedChanges(false));
    dispatch(
      confirmCsrAccountUpdate(locationId, accountAtLocation.id, isAccountNumberSearch, {
        accountStatus: AccountStatuses.ACTIVE,
      })
    );
  };

  const handleUnlockAccountCheckboxToggle = () => {
    setUnlockAccountCheckboxSelected(!isUnlockAccountCheckboxSelected);
    dispatch(setHasUnsavedChanges(!isUnlockAccountCheckboxSelected));
  };

  const unlockAccount = () => {
    dispatch(
      unlockCsrCustomerAccount.request({
        accountAction: AccountActionsTypes.UNLOCK,
        onSuccessCallback: unlockAccountSuccessHandler,
        accountId: accountAtLocation.id,
      })
    );
  };

  useEffect(() => {
    setSuccessMessageVisible(false);
  }, [accountAtLocation.id]);

  return (
    <div className="account-actions">
      <CustomCheckbox
        className={classNames('account-actions__action', {
          'account-actions__action--disabled': isSuccessMessageVisible,
        })}
        disabled={isSuccessMessageVisible}
        selected={isUnlockAccountCheckboxSelected}
        label={getContentByKey(`${contentstackPath}.account_actions_tab.unlock_account_checkbox_label`, '')}
        onCheckboxClick={handleUnlockAccountCheckboxToggle}
      />
      {isSuccessMessageVisible && (
        <span className="account-actions__success-message">
          <img src={iconSuccess} className="account-actions__success-message-icon" alt="cross icon" />
          <ContentstackMessage type="success" messageId="MSG058" />
        </span>
      )}
      <div className="account-actions__buttons">
        <CorButton className="secondary account-actions__close" onClick={onCancel}>
          <ContentstackText contentKey={`${contentstackPath}.cancel_button_label`} />
        </CorButton>
        <CorButton
          className="account-actions__save"
          disabled={!isUnlockAccountCheckboxSelected}
          onClick={unlockAccount}
        >
          <ContentstackText contentKey={`${contentstackPath}.save_changes_button_label`} />
        </CorButton>
      </div>
    </div>
  );
};
