import { CorTextField } from 'corporate-components/cor-forms';
import { CorFormControlsComponentMap } from 'corporate-components/cor-form/cor-form.constants';
import { filterEmptyDynamicRules } from 'corporate-components/cor-form/cor-form-helper';

export const CorFormFields = ({ fields, values }) => {
  return fields?.map(({ form_field, dynamic_field }, index) => {
    const FormControl = CorFormControlsComponentMap.get(form_field?.field_type) || CorTextField;
    const options = filterEmptyDynamicRules(form_field?.dropdown_selection_content?.value);

    return (
      !dynamic_field && (
        <FormControl
          className="cor-form-field"
          key={`${form_field?.field_name}-${index}`}
          name={form_field?.field_name}
          label={form_field?.field_label}
          placeholder={form_field?.field_placeholder_text}
          isRequired={form_field?.mandatory_field}
          {...(!!options?.length && { value: values?.[form_field?.field_name] })}
          {...(!!options?.length && { options })}
        />
      )
    );
  });
};
