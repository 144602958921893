import { FC, PropsWithChildren } from 'react';
import { PageLayout } from 'components/layout';
import { InternalUserHeader } from 'components/layout/internal-user-header';
import { InternalUserFooter } from 'components/layout/internal-user-footer';

interface IInternalUserLayoutProps extends PropsWithChildren {}

export const InternalUserLayout: FC<IInternalUserLayoutProps> = ({ children }) => (
  <PageLayout classModifier="default" header={InternalUserHeader} footer={InternalUserFooter}>
    {children}
  </PageLayout>
);
