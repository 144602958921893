import { put, take, takeLatest } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import {
  createShoppingListWithProducts,
  createShoppingListWithProductsActionConstants,
} from 'store/shopping-lists/actions';
import { IDeactivatedLineItemDetails } from 'types/order-details';

export interface IListLineItem {
  quantity: number;
  sku: string;
}

export interface ICreateShoppingListWithProductsPayload {
  lineItems: IListLineItem[];
  name: string;
  onSuccessCallback: (
    shoppingListName: string,
    addedItemsCounter: number,
    deactivatedLineItems: IDeactivatedLineItemDetails[]
  ) => void;
  onFailCallback: (deactivatedLineItems: string[], status: number) => void;
}

export function* createShoppingListWithProductsSuccessSagaHandler({
  payload,
}: IAction<ICreateShoppingListWithProductsPayload>) {
  const {
    payload: { addedItemsCounter, deactivatedLineItems },
  } = yield take(createShoppingListWithProductsActionConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallback) {
    payload.onSuccessCallback(payload.name, addedItemsCounter, deactivatedLineItems);
  }
}

export function* createShoppingListWithProductsFailSagaHandler({
  payload,
}: IAction<ICreateShoppingListWithProductsPayload>) {
  const { error } = yield take(createShoppingListWithProductsActionConstants[Actions.FAIL]);
  if (payload.onFailCallback) {
    payload.onFailCallback(error.deactivatedItemModel?.skus || [], error.status);
  }
}

export function* createShoppingListWithProductsHandler({
  payload: { lineItems, name },
}: IAction<ICreateShoppingListWithProductsPayload>) {
  yield put(doRequest(createShoppingListWithProducts, Endpoints.SHOPPING_LISTS, 'post', { lineItems, name }));
}

export default function* createShoppingListWithProductsSaga() {
  yield takeLatest(
    createShoppingListWithProductsActionConstants[Actions.REQUEST],
    createShoppingListWithProductsHandler
  );
  yield takeLatest(
    createShoppingListWithProductsActionConstants[Actions.REQUEST],
    createShoppingListWithProductsSuccessSagaHandler
  );

  yield takeLatest(
    createShoppingListWithProductsActionConstants[Actions.REQUEST],
    createShoppingListWithProductsFailSagaHandler
  );
}
