import { IBaseLocationAddress } from 'types/locations';

export const formatPhoneNumber = (phoneNumber: string): string =>
  phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const formatAddressIntoLine = ({ city, state, zipCode, address }: Partial<IBaseLocationAddress>): string =>
  [address, city, `${state} ${zipCode}`].filter((str) => str?.trim().length).join(', ');

export const stripLeadingZeros = (value: string = ''): string => value.replace(/^0+/, '');

export const excludeSpecialCharacters = (query: string = '') => query.replace(/[^0-9a-zA-Z ]/g, '');
