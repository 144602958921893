import { all, spawn } from 'redux-saga/effects';
import getProductsSaga from './get-products';
import getTemporaryFiltersInfoSaga from './get-temporary-filters-info';
import getCarouselProductsSaga from './get-carousel-products';
import updateTemporaryFiltersSaga from './update-temporary-filters';
import getProductsBySkusSaga from './get-products-by-skus';

export default function* customerRegistrationSagas() {
  yield all([
    spawn(getProductsSaga),
    spawn(getCarouselProductsSaga),
    spawn(getTemporaryFiltersInfoSaga),
    spawn(updateTemporaryFiltersSaga),
    spawn(getProductsBySkusSaga),
  ]);
}
