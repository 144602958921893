import { takeLatest, select, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import qs from 'qs';
import { Routes } from 'constants/routes.enum';
import { Limit } from 'constants/limit.enum';
import { mapCategoryKeyToUrl } from 'utils/map-category-key';
import productsActionTypes from 'store/products/action-types';
import {
  productsEntitySelector,
  selectCategoryKey,
  selectProductsSelectedFacets,
  selectTemporaryProductsSelectedFacets,
  selectTemporarySelectedCategoryKey,
} from 'store/products/selectors';
import { isEqualSelectedFacets, mapSelectedFacetsToSearchParams } from 'utils/facet-utils';
import { setTemporarySelectedFilters, setSelectedFilters } from 'store/products/actions';

export function* initTemporaryFiltersSagaHandler() {
  const productsStore = yield select(productsEntitySelector);
  yield put(setTemporarySelectedFilters(productsStore));
}

export function* confirmTemporaryFiltersSagaHandler() {
  const productsStore = yield select(productsEntitySelector);
  const selectedFacets = yield select(selectTemporaryProductsSelectedFacets);
  const selectedCategory = yield select(selectTemporarySelectedCategoryKey);
  const prevSelectedFacets = yield select(selectProductsSelectedFacets);
  const prevSelectedCategory = yield select(selectCategoryKey);

  const wasFacetsChanged = !isEqualSelectedFacets(selectedFacets, prevSelectedFacets);
  const wasCategoryChanged = selectedCategory && selectedCategory !== prevSelectedCategory;
  const routerLocation = yield select((state) => state.router.location);
  const updatedSelectedFacetsSearchParams = mapSelectedFacetsToSearchParams(selectedFacets);

  const updatedMeta = {
    offset: wasFacetsChanged || wasCategoryChanged ? 0 : productsStore.meta.offset,
    limit: wasCategoryChanged ? Limit.TwentyFour : productsStore.meta.limit,
    sortOptions: wasCategoryChanged ? {} : productsStore.meta.sortOptions,
  };

  const searchParams = qs.parse(routerLocation.search, { ignoreQueryPrefix: true });

  const updatedSearchParams = {
    facets: updatedSelectedFacetsSearchParams,
    offset: wasFacetsChanged || wasCategoryChanged ? undefined : searchParams.offset,
    filters: searchParams.filters ? searchParams.filters : undefined,
    limit: wasCategoryChanged ? undefined : searchParams.limit,
    sortDirection: wasCategoryChanged ? undefined : searchParams.sortDirection,
    sortField: wasCategoryChanged ? undefined : searchParams.sortField,
  };

  yield put(
    setSelectedFilters({
      ...productsStore,
      selectedFacets,
      selectedCategory: productsStore.temporarySelectedCategory,
      facets: productsStore.temporaryFacets,
      meta: { ...productsStore.meta, ...updatedMeta },
    })
  );
  yield put(
    push({
      ...routerLocation,
      pathname: `${Routes.ProductListPage}${selectedCategory ? `/${mapCategoryKeyToUrl(selectedCategory)}` : ''}`,
      search: qs.stringify(updatedSearchParams),
    })
  );
}

export default function* updateTemporaryFiltersSaga() {
  yield takeLatest(productsActionTypes.INIT_TEMPORARY_SELECTED_FILTERS, initTemporaryFiltersSagaHandler);
  yield takeLatest(productsActionTypes.CONFIRM_TEMPORARY_SELECTED_FILTERS, confirmTemporaryFiltersSagaHandler);
}
