import { FC, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CustomRadioButton } from 'components/custom-radio-button';
import { IOptionType } from 'components/custom-dropdown';
import { CorButton } from 'components/cor-button';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './sort-customer-locations-options-mobile-modal.scss';

export interface ISortCustomerLocationsOptionsMobileModalProps extends IModalProps {
  isOpen: boolean;
  sortingOptions: IOptionType[];
  selectedMobileSortingOption: IOptionType;
  handleOpen: () => void;
  handleClose: () => void;
  handleSortingChange: (option: IOptionType) => void;
  selectSortingChange: (option: IOptionType) => void;
}

export const SortCustomerLocationsOptionsMobileModal: FC<ISortCustomerLocationsOptionsMobileModalProps> = ({
  isOpen,
  sortingOptions,
  selectedMobileSortingOption,
  handleClose,
  handleSortingChange,
  selectSortingChange,
}) => {
  const [mobileSortingOption, setMobileSortingOption] = useState(selectedMobileSortingOption);

  const contentstackPath = 'modals.0.sort_modal.0';

  const updateMobileSortingChange = (option: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
    const newMobileOption = option as IOptionType;
    setMobileSortingOption(newMobileOption);

    handleSortingChange({ label: newMobileOption.label, value: newMobileOption.value });
  };

  return (
    <>
      <Modal className="sort-customer-locations-options-mobile-modal__sort-modal" isOpen={isOpen} onClose={handleClose}>
        <ModalHeader contentstackPathIcon={`${contentstackPath}.modal_heading`}>
          <h2 className="sort-customer-locations-options-mobile-modal__sort-modal-heading">
            <ContentstackText contentKey={`${contentstackPath}.modal_heading`} />
          </h2>
        </ModalHeader>
        <div className="sort-customer-locations-options-mobile-modal__sort-modal-body">
          {sortingOptions.map((item) => (
            <CustomRadioButton
              checked={mobileSortingOption.value === item.value}
              onChange={() => updateMobileSortingChange(item)}
              key={item.value}
            >
              {item.label}
            </CustomRadioButton>
          ))}
        </div>
        <ModalFooter className="sort-customer-locations-options-mobile-modal__sort-modal-actions">
          <CorButton onClick={() => selectSortingChange(mobileSortingOption)}>
            <ContentstackText contentKey={`${contentstackPath}.submit_button`} />
          </CorButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
