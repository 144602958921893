import { FC } from 'react';
import { useContent } from 'hooks/use-content';

import './product-indicators.scss';

interface IProductIndicatorProps {
  size?: 'small' | 'medium' | 'large';
  isLowInStock?: boolean;
  isNoLongerAvailable?: boolean;
  extendedOutOfStockLabel?: boolean;
  seasonalLabel?: string;
  seasonalLabelColor?: string;
}

export const ProductIndicators: FC<IProductIndicatorProps> = ({
  size = 'small',
  isLowInStock = false,
  isNoLongerAvailable = false,
  extendedOutOfStockLabel = false,
  seasonalLabel,
  seasonalLabelColor,
}) => {
  const { getContentByKey } = useContent();
  const contentstackPath = 'product_characteristics.product_indicators[0]';

  if (seasonalLabel && seasonalLabelColor) {
    return (
      <div
        className={`product-indicators product-indicators--seasonal product-indicators--${size}`}
        style={{ background: seasonalLabelColor }}
      >
        {seasonalLabel}
      </div>
    );
  }

  if (isNoLongerAvailable) {
    return (
      <div className={`product-indicators product-indicators--out-of-stock product-indicators--${size}`}>
        {extendedOutOfStockLabel
          ? getContentByKey<string>(`${contentstackPath}.out_of_stock_2`, '')
          : getContentByKey<string>(`${contentstackPath}.out_of_stock_1`, '')}
      </div>
    );
  }

  if (isLowInStock) {
    return (
      <div className={`product-indicators product-indicators--low-in-stock product-indicators--${size}`}>
        {getContentByKey<string>(`${contentstackPath}.low_in_stock`, '')}
      </div>
    );
  }

  return null;
};
