import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from 'store/shopping-cart/action-types';
import { IOriginalToReplacementSkusMap } from './reducer';

export const getCartInfoActionConstants = createConstants(types.GET_CART_INFO);
export const setCartMessageViewedActionConstants = createConstants(types.SET_CART_MESSAGE_VIEWED);
export const addProductToCartActionConstants = createConstants(types.ADD_PRODUCT_TO_CART);
export const addMultipleProductsToCartActionConstants = createConstants(types.ADD_MULTIPLE_PRODUCTS_TO_CART);
export const changeCartItemQuantityActionConstants = createConstants(types.CHANGE_CART_ITEM_QUANTITY);
export const setCartItemQuantityActionConstants = createConstants(types.SET_CART_ITEM_QUANTITY);
export const deleteCartItemActionConstants = createConstants(types.DELETE_CART_ITEM);
export const deleteAllCartItemsActionConstants = createConstants(types.DELETE_ALL_CART_ITEMS);
export const reorderProductsActionConstants = createConstants(types.REORDER_PRODUCTS);
export const getProductRecommendationsActionConstants = createConstants(types.GET_PRODUCT_RECOMMENDATIONS);
export const getPromotionCouponCodeConstants = createConstants(types.GET_PROMOTION_COUPON_CODE);
export const deletePromotionCouponCodeActionConstants = createConstants(types.DELETE_PROMOTION_COUPON_CODE);

export const getCartInfo = createRequestActions(getCartInfoActionConstants);
export const setCartMessageViewed = createRequestActions(setCartMessageViewedActionConstants);
export const addProductToCart = createRequestActions(addProductToCartActionConstants);
export const addMultipleProductsToCart = createRequestActions(addMultipleProductsToCartActionConstants);
export const changeCartItemQuantity = createRequestActions(changeCartItemQuantityActionConstants);
export const setCartItemQuantity = createRequestActions(setCartItemQuantityActionConstants);
export const deleteCartItem = createRequestActions(deleteCartItemActionConstants);
export const deleteAllCartItems = createRequestActions(deleteAllCartItemsActionConstants);
export const reorderProducts = createRequestActions(reorderProductsActionConstants);
export const getProductRecommendations = createRequestActions(getProductRecommendationsActionConstants);
export const getPromotionCouponCode = createRequestActions(getPromotionCouponCodeConstants);
export const deletePromotionCouponCode = createRequestActions(deletePromotionCouponCodeActionConstants);

export const clearOriginalToReplacementSkuMap = () => action(types.CLEAR_ORIGINAL_TO_REPLACEMENT_SKU_MAP);
export const resetPromotion = () => action(types.RESET_PROMOTIONAL);
export const setReorderOriginalToReplacementSkuMap = (originalToReplacementSkusMap: IOriginalToReplacementSkusMap) =>
  action(types.SET_REORDER_ORIGINAL_TO_REPLACEMENT_SKU_MAP, originalToReplacementSkusMap);
export const setIsPromotionalCodeValid = (isPromotionalCodeValid: boolean) =>
  action(types.SET_IS_PROMOTIONAL_CODE_VALID, { isPromotionalCodeValid });
