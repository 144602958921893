import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { removeBankAccountEftPad, removeBankAccountEftPadActionConstants } from 'store/payment-methods/actions';

export interface IRemoveBankAccountEftPadPayload {
  accountType: string;
  publicAccountNumber: string;
  routingNumber: string;
  onSuccessCallBack: () => void;
  onFailCallBack: (errorId: string, messageId: string) => void;
}

export function* removeBankAccountEftPadOnSuccessSagaHandler({ payload }: IAction<IRemoveBankAccountEftPadPayload>) {
  yield take(removeBankAccountEftPadActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* removeBankAccountEftPadOnFailSagaHandler({ payload }: IAction<IRemoveBankAccountEftPadPayload>) {
  const returnedError = yield take(removeBankAccountEftPadActionConstants[Actions.FAIL]);
  const {
    error: { errorId, errorMessageId },
  } = returnedError;
  if (payload.onFailCallBack && returnedError) {
    payload.onFailCallBack(errorId, errorMessageId);
  }
}

export function* removeBankAccountEftPadSagaHandler({ payload }: IAction<IRemoveBankAccountEftPadPayload>) {
  yield put(
    doRequest<IRemoveBankAccountEftPadPayload>(
      removeBankAccountEftPad,
      Endpoints.BANK_ACCOUNTS_EFT_PAD,
      'delete',
      payload
    )
  );
}

export default function* removeBankAccountEftPadSaga() {
  yield takeLatest(
    removeBankAccountEftPadActionConstants[Actions.REQUEST],
    removeBankAccountEftPadOnSuccessSagaHandler
  );
  yield takeLatest(removeBankAccountEftPadActionConstants[Actions.REQUEST], removeBankAccountEftPadOnFailSagaHandler);
  yield takeLatest(removeBankAccountEftPadActionConstants[Actions.REQUEST], removeBankAccountEftPadSagaHandler);
}
