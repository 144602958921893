import axios from 'axios';
import { takeLatest, call, cancelled } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { apiSagaHandler } from 'store/api/sagas';
import { doRequest } from 'store/api/actions';
import { Limit } from 'constants/limit.enum';
import { ISortParams } from 'types/product-sort-options';
import { IAction } from 'types/actions';
import { ISelectedFacet } from 'types/facets';
import { getLocaleQuery } from 'utils/get-locale-params';
import { getSearchTemporaryFiltersInfo, getSearchTemporaryFiltersInfoActionConstants } from '../actions';

export interface IGetProductsPayload {
  text?: string;
  categoryKeys?: string[];
  facets: ISelectedFacet[];
  limit: Limit;
  offset: number;
  sortOptions: ISortParams;
}

export function* getSearchTemporaryFiltersInfoSagaHandler({ payload }: IAction<IGetProductsPayload>) {
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const temporaryFiltersInfoPayload = {
    ...payload,
    // limit, offset, sortOptions set in this way as they are unused in this case
    // limit should be set to at list one product to get correct facets state
    limit: 1,
    offset: 0,
    sortOptions: {},
  };

  try {
    yield call(
      apiSagaHandler,
      doRequest<IGetProductsPayload>(
        getSearchTemporaryFiltersInfo,
        `${Endpoints.GET_PRODUCTS}${getLocaleQuery()}`,
        'post',
        temporaryFiltersInfoPayload,
        undefined,
        cancelToken
      )
    );
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
}

export default function* getSearchTemporaryFiltersInfoSaga() {
  yield takeLatest(
    getSearchTemporaryFiltersInfoActionConstants[Actions.REQUEST],
    getSearchTemporaryFiltersInfoSagaHandler
  );
}
