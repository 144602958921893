import { FC } from 'react';
import { get } from 'lodash-es';
import { ICSCorImage } from 'corporate-interfaces';
import { isTifExtension } from 'corporate-utils/cor-contantstack-image-helper';
import {
  CorContentstackImageAsset,
  corContentstackImageLinkResolver,
} from 'corporate-utils/cor-contentstack-image-link-resolver';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';
import { getImageExtension } from 'utils/contentstack-image-helper';

interface ICorContentstackImageComponentProps {
  contentKey: string;
  data?: any;
}

export const CorContentstackImage: FC<
  ICorContentstackImageComponentProps & React.ImgHTMLAttributes<HTMLImageElement>
> = ({ contentKey = 'image', data, ...props }) => {
  const content = useContentstackContent();
  const rawImage: ICSCorImage = !!data ? data : get(content, contentKey, {});
  const image: CorContentstackImageAsset =
    corContentstackImageLinkResolver(rawImage) || ({} as CorContentstackImageAsset);
  const imageUrl = isTifExtension(getImageExtension(image.url))
    ? image.url.replace(getImageExtension(image.url), `jpg?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}`)
    : image.url;

  return !!image?.url ? (
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    <img src={imageUrl} alt={image?.image_alt_text} {...props} />
  ) : null;
};
