import { createRef, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ContentstackHtml } from 'components/contentstack/contentstack-html';
import { selectIsPickupAccountUser } from 'store/auth/selectors';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack';

import './site-wide-banner.scss';

export const SiteWideBanner: FC = () => {
  const { getContentByKey } = useContent();
  const isPickupAccountUser = useSelector(selectIsPickupAccountUser);
  const { isMobile } = useBreakpoint();
  const siteWideBannerContentKey = `common[0].site_wide_banner[0].${
    isPickupAccountUser ? 'content_for_pick_up_users' : 'content'
  }`;
  const ref = createRef<HTMLDivElement>();

  const [isBannerExpanded, setIsBannerExpanded] = useState(false);
  const [hasHiddenContent, setHasHiddenContent] = useState(false);

  useEffect(() => {
    if (!ref?.current) return;
    setHasHiddenContent(ref.current.scrollHeight > ref.current.offsetHeight);
    const button = ref.current.parentElement?.querySelector('.site-wide-banner__content-toggle');
    if (hasHiddenContent || isBannerExpanded) {
      button?.classList.remove('hidden');
    } else {
      button?.classList.add('hidden');
    }
  }, [ref, hasHiddenContent, isBannerExpanded]);

  const siteWideBannerContent = getContentByKey<string>(siteWideBannerContentKey, '');

  const bannerContentClasses = classNames('site-wide-banner__inner', {
    'site-wide-banner__inner--collapsed': isMobile && !isBannerExpanded,
    'site-wide-banner__inner--expanded': isBannerExpanded,
  });

  return isEmpty(siteWideBannerContent) ? null : (
    <div className="site-wide-banner">
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="site-wide-banner__content small-12 medium-11">
            <div className={bannerContentClasses} ref={ref}>
              <ContentstackHtml contentKey={siteWideBannerContentKey} />
            </div>
            {isMobile && (
              <CorButton
                color="link-2"
                className="site-wide-banner__content-toggle"
                onClick={() => setIsBannerExpanded(!isBannerExpanded)}
              >
                <ContentstackText
                  contentKey={`common[0].site_wide_banner[0].${
                    isBannerExpanded ? 'hide_button_label' : 'more_button_label'
                  }`}
                />
              </CorButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
