import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getPaymentHistoryActionConstants = createConstants(types.GET_PAYMENT_HISTORY);

export const getPaymentHistory = createRequestActions(getPaymentHistoryActionConstants);
export const changePaginationCurrent = (current: number) => {
  return action(types.PAYMENT_HISTORY_PAGINATION_CHANGE, {
    current,
  });
};
