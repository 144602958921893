import { Component, PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from 'constants/routes.enum';

const CHUNK_LOAD_ERROR_NAME = 'ChunkLoadError';
const TYPE_ERROR = 'TypeError';
const CHUNK_LOAD_ERROR_OCCURRED_STORAGE_KEY = 'CHUNK_LOAD_ERROR_OCCURRED';

const hasChunkLoadErrorOccurredBefore = () =>
  window.localStorage.getItem(CHUNK_LOAD_ERROR_OCCURRED_STORAGE_KEY) === 'true';
const setChunkLoadErrorOccurred = (flag: boolean) =>
  window.localStorage.setItem(CHUNK_LOAD_ERROR_OCCURRED_STORAGE_KEY, String(flag));

export class ErrorBoundary extends Component<PropsWithChildren> {
  public state: {
    hasError: boolean;
    error?: Error;
  } = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  public render() {
    const { error, hasError } = this.state;
    if (hasError && error?.name === CHUNK_LOAD_ERROR_NAME) {
      if (hasChunkLoadErrorOccurredBefore()) {
        setChunkLoadErrorOccurred(false);
      } else {
        setChunkLoadErrorOccurred(true);
        window.location.reload();
      }
    } else if (hasError && error?.name === TYPE_ERROR) {
      console.error(error);
      return <Redirect to={Routes.NotFoundPage} />;
    }

    return this.props.children;
  }
}
