import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from 'store/checkout/action-types';

export const validateCartActionConstants = createConstants(types.VALIDATE_CART);
export const submitOrderActionConstants = createConstants(types.SUBMIT_ORDER);

export const validateCart = createRequestActions(validateCartActionConstants);
export const submitOrder = createRequestActions(submitOrderActionConstants);
export const goToOrderSubmittion = (poNumber: string, selectedDeliveryDate: string) =>
  action(types.GO_TO_ORDER_SUBMITTION, { poNumber, selectedDeliveryDate });
export const resetSapBackupStatus = () => action(types.RESET_SAP_BACKUP_STATUS);
export const setPoNumber = (poNumber: string) => action(types.SET_PO_NUMBER, { poNumber });
export const setSelectedDeliveryDate = (selectedDeliveryDate: string) =>
  action(types.SET_SELECTED_DELIVERY_DATE, { selectedDeliveryDate });
export const clearGeneralErrorMessage = () => action(types.CLEAR_GENERAL_ERROR_MESSAGE);
