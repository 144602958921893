import React, { FC, PropsWithChildren, useCallback } from 'react';
import { getShareLink } from 'corporate-components/cor-share-social/utils/cor-share-links';

import './cor-share-button.scss';

const CorShareButtonDialogConfig = {
  width: '550px',
  height: '400px',
  location: 'no',
  toolbar: 'no',
  status: 'no',
  directories: 'no',
  menubar: 'no',
  scrollbars: 'yes',
  resizable: 'no',
  centerscreen: 'yes',
  chrome: 'yes',
};

interface ICorShareButtonProps extends PropsWithChildren {
  shareUrl: string;
  url: string;
  title: string;
}

export const CorShareButton: FC<ICorShareButtonProps> = ({ shareUrl, url, title, children, ...props }) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const shareSocialUrl = getShareLink(url, title, shareUrl);
      window.open(
        shareSocialUrl,
        '',
        Object.entries(CorShareButtonDialogConfig)
          .map((record) => record.join('='))
          .join(', ')
      );
    },
    [url, title, shareUrl]
  );

  return (
    <button {...props} onClick={handleClick} className="cor-share-button">
      {children}
    </button>
  );
};
