import { put, take, takeLatest } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import {
  createShoppingListWithProductsFromMarketingShoppingList,
  createShoppingListWithProductsFromMarketingShoppingListActionConstants,
} from 'store/shopping-lists/actions';
import { IDeactivatedLineItemDetails } from 'types/order-details';

export interface ICreateShoppingListWithProductsFromMarketingShoppingListPayload {
  name: string;
  resourceShoppingListId: string;
  onSuccessCallback: (
    shoppingListName: string,
    itemCounter: number,
    deactivatedLineItems: IDeactivatedLineItemDetails[]
  ) => void;
  onFailCallback: (deactivatedLineItems: string[], status: number) => void;
}

export function* createShoppingListWithProductsFromMarketingShoppingListHandler({
  payload: { name, resourceShoppingListId },
}: IAction<ICreateShoppingListWithProductsFromMarketingShoppingListPayload>) {
  yield put(
    doRequest(
      createShoppingListWithProductsFromMarketingShoppingList,
      Endpoints.SHOPPING_LISTS_CONVERT_MARKETING_TO_CUSTOM,
      'post',
      {
        name,
        resourceShoppingListId,
      }
    )
  );
}

export function* createShoppingListWithProductsFromMarketingShoppingListSuccessSagaHandler({
  payload,
}: IAction<ICreateShoppingListWithProductsFromMarketingShoppingListPayload>) {
  const {
    payload: { addedItemsCounter, deactivatedLineItems },
  } = yield take(createShoppingListWithProductsFromMarketingShoppingListActionConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallback) {
    payload.onSuccessCallback(payload.name, addedItemsCounter, deactivatedLineItems);
  }
}

export function* createShoppingListWithProductsFromMarketingShoppingListFailSagaHandler({
  payload,
}: IAction<ICreateShoppingListWithProductsFromMarketingShoppingListPayload>) {
  const { error } = yield take(createShoppingListWithProductsFromMarketingShoppingListActionConstants[Actions.FAIL]);
  if (payload.onFailCallback) {
    payload.onFailCallback(error.deactivatedItemModel?.skus || [], error.status);
  }
}

export default function* createShoppingListWithProductsFromMarketingShoppingListSaga() {
  yield takeLatest(
    createShoppingListWithProductsFromMarketingShoppingListActionConstants[Actions.REQUEST],
    createShoppingListWithProductsFromMarketingShoppingListHandler
  );
  yield takeLatest(
    createShoppingListWithProductsFromMarketingShoppingListActionConstants[Actions.REQUEST],
    createShoppingListWithProductsFromMarketingShoppingListSuccessSagaHandler
  );

  yield takeLatest(
    createShoppingListWithProductsFromMarketingShoppingListActionConstants[Actions.REQUEST],
    createShoppingListWithProductsFromMarketingShoppingListFailSagaHandler
  );
}
