import { createContext, FC, PropsWithChildren, useCallback, useReducer } from 'react';

enum modalsActionTypes {
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
}

export interface IModalState {
  isOpen: boolean;
  config?: {
    ModalComponent?: FC;
    modalComponentProps?: any;
  };
}

export interface IGlobalModalContext {
  modal: IModalState;
  showGlobalModal: (modalProps: any) => void; // show/replace global modal component
  hideGlobalModal: () => void; // hide any global modal opened
}

interface IGlobalModalState extends PropsWithChildren {}

const globalModalStateDefaultValue = {
  isOpen: false,
};

const globalModalStateReducer = (state, action) => {
  switch (action.type) {
    case modalsActionTypes.SHOW_MODAL:
      return { isOpen: true, config: action.payload };
    case modalsActionTypes.HIDE_MODAL:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export const GlobalModalStateContext = createContext<IGlobalModalContext>({
  modal: globalModalStateDefaultValue,
  showGlobalModal: () => {},
  hideGlobalModal: () => {},
});

export const GlobalModalState: FC<IGlobalModalState> = ({ children }) => {
  const [modal, dispatch] = useReducer(globalModalStateReducer, globalModalStateDefaultValue);
  const showGlobalModal = useCallback(
    (payload: any) =>
      dispatch({
        type: modalsActionTypes.SHOW_MODAL,
        payload,
      }),
    [dispatch]
  );
  const hideGlobalModal = useCallback(() => {
    dispatch({
      type: modalsActionTypes.HIDE_MODAL,
    });
  }, [dispatch]);

  return (
    <GlobalModalStateContext.Provider
      value={{
        modal,
        showGlobalModal,
        hideGlobalModal,
      }}
    >
      {children}
    </GlobalModalStateContext.Provider>
  );
};
