import { FC, SyntheticEvent, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserName } from 'store/auth/selectors';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { TextField } from 'components/forms/text-field';
import { SubmitButton } from 'components/forms/submit-button';
import { ErrorNotification } from 'components/forms/error-notification';
import { useContent } from 'hooks/use-content';
import { formatDate } from 'utils/date-format';
import { CorButton } from 'components/cor-button';
import {
  resetHasShoppingListNameDuplicateError,
  setShoppingListNameDuplicateError,
} from 'store/shopping-lists/actions';
import {
  selectHasShoppingListNameDuplicateError,
  selectIsShoppingListBeingCreated,
} from 'store/shopping-lists/selectors';
import { selectProductSelectedSkuInfo } from 'store/product-details/selectors';
import { ISku } from 'types/product';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './create-shopping-list-modal.scss';

export interface ICreateShoppingListModalProps extends IModalProps {
  submitHandler: (values: { shoppingListName: string }, selectedSku?: ISku) => void;
  sku?: ISku;
}

export const CreateShoppingListModal: FC<ICreateShoppingListModalProps> = ({
  isOpen = false,
  onClose = () => {},
  submitHandler,
  sku,
}) => {
  const contentstackPath = 'modals.0.create_shopping_list_modal.0';
  const { firstName, lastName } = useSelector(selectUserName);
  const isShoppingListBeingCreated = useSelector(selectIsShoppingListBeingCreated);
  const isShoppingListNameDuplicated = useSelector(selectHasShoppingListNameDuplicateError);
  const selectedSku = useSelector(selectProductSelectedSkuInfo);
  const todayDateFormatted = formatDate(new Date());
  const dispatch = useDispatch();
  const { getMessageText } = useContent();
  const itemToBeAddedToNewShoppingList = !selectedSku ? sku : selectedSku;

  useEffect(() => {
    return () => {
      dispatch(resetHasShoppingListNameDuplicateError());
    };
  }, [dispatch]);

  return (
    <Modal size="small" className="create-shopping-list-modal" isOpen={isOpen} onClose={onClose} withBackdropClick>
      <ModalHeader titleContentstackPath={`${contentstackPath}.heading`} />

      <Formik
        validateOnChange={false}
        initialValues={{ shoppingListName: `${firstName}-${lastName}-${todayDateFormatted}` }}
        onSubmit={(values) => submitHandler(values, itemToBeAddedToNewShoppingList)}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              {isShoppingListNameDuplicated && (
                <ErrorNotification
                  className="create-name-modal__general-error"
                  message={getMessageText('error', 'MSG057')}
                />
              )}
              <Form>
                <div className="create-shopping-list-modal__form">
                  <TextField
                    className="create-shopping-list-modal__input"
                    id="shoppingListName"
                    label="Shopping List Name"
                    name="shoppingListName"
                    maxLength={35}
                    onChange={(e: SyntheticEvent) => {
                      setFieldValue('shoppingListName', (e.target as HTMLInputElement).value);
                      dispatch(setShoppingListNameDuplicateError(false));
                    }}
                    highlight={isShoppingListNameDuplicated}
                  />
                </div>
                <ModalFooter className="create-shopping-list-modal__buttons">
                  <CorButton className="create-shopping-list-modal__cancel link-1" onClick={onClose}>
                    <ContentstackText contentKey={`${contentstackPath}.cancel_button_label`} />
                  </CorButton>
                  <SubmitButton
                    className="create-shopping-list-modal__create-list"
                    isDisabled={!values.shoppingListName || isShoppingListBeingCreated}
                  >
                    <ContentstackText contentKey={`${contentstackPath}.create_shopping_list_button_label`} />
                  </SubmitButton>
                </ModalFooter>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
