import { FC } from 'react';
import { Modal } from 'components/modals/common/modal';
import { ContentstackText, ContentstackMessage } from 'components/contentstack';
import { CorButton } from 'components/cor-button';

import './timestamp-modal.scss';

export interface ITimestampModalProps {
  isOpen: boolean;
  onClose: () => void;
  creationDate?: string;
  requestedDate?: string;
}

export const TimestampModal: FC<ITimestampModalProps> = ({ isOpen, onClose, creationDate, requestedDate }) => {
  const contentstackPath = 'modals.0.timestamp_modal.0';

  return (
    <Modal size="small" isOpen={isOpen} className="order-timestamp-modal" onClose={onClose} withBackdropClick>
      <div className="order-timestamp-modal__text">
        <span className="creation-date">
          <ContentstackMessage type="tooltips" messageId="MSG108a" />
        </span>
        {creationDate}
      </div>
      {requestedDate && (
        <div className="order-timestamp-modal__text">
          <span className="requested-date">
            <ContentstackMessage type="tooltips" messageId="MSG108b" />
          </span>
          {requestedDate}
        </div>
      )}
      <CorButton className="secondary order-timestamp-modal__close-button" onClick={onClose}>
        <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
      </CorButton>
    </Modal>
  );
};
