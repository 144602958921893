import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getCartExpiredNotificationViewedActionConstants = createConstants(
  types.GET_CART_EXPIRED_NOTIFICATION_VIEWED
);
export const cutoffNotificationViewedActionConstants = createConstants(types.CUTOFF_NOTIFICATION_VIEWED);
export const getCutOffNotificationTimerActionConstants = createConstants(types.GET_CUTOFF_NOTIFICATION_DATETIME);

export const getCartExpiredNotificationViewed = createRequestActions(getCartExpiredNotificationViewedActionConstants);
export const getCutOffNotificationTimer = createRequestActions(getCutOffNotificationTimerActionConstants);
export const cutoffNotificationViewed = createRequestActions(cutoffNotificationViewedActionConstants);

export const clearCutOffNotificationTimer = () => action(types.CLEAR_CUTOFF_NOTIFICATION_DATETIME);

export const showNotificationTimer = (isVisible: boolean) =>
  action(types.SET_CUTOFF_NOTIFICATION_VISIBLE, { isVisible });

export const setNotificationTimerExpired = (isExpired: boolean) =>
  action(types.SET_CUTOFF_NOTIFICATION_EXPIRED, { isExpired });
