import { createSelector } from 'reselect';
import { IState } from '../root-reducer';
import { ICustomerNotificationsState } from './reducer';

export const selectCustomerNotificationsEntity = (state: IState): ICustomerNotificationsState =>
  state.app.customerNotifications;

export const selectCustomerNotifications = createSelector(
  selectCustomerNotificationsEntity,
  (notifications) => notifications.notificationItems
);
