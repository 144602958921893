import { FC } from 'react';
import { get, template } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';
import { IMessage } from '../contentstack-message';

export interface IContentstackRichTextMessageComponentProps {
  messageId: string;
  component?: keyof JSX.IntrinsicElements;
  interpolateParams?: object;
  className?: string;
}

export const ContentstackRichTextMessage: FC<IContentstackRichTextMessageComponentProps> = ({
  messageId,
  component = 'div',
  className,
  interpolateParams = {},
}) => {
  const content = useContentstackContent();
  const messages: IMessage[] = get(content, 'rich_text_messages.0.messages', []);
  let html = get(
    messages?.find((message) => message.message_id === messageId) as IMessage | null,
    'message_content',
    ''
  );

  html = ([
    ['&lt;%', '<%'],
    ['%&gt;', '%>'],
    [
      '(.*src=")(.*\\/\\/images.contentstack.io\\/v3\\/assets.*?)(".*)',
      `$1$2?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}$3`,
    ],
  ] as Array<[string, string]>).reduce(
    (acc, replacementPair) => acc.replace(new RegExp(replacementPair[0], 'g'), replacementPair[1]),
    html
  );
  const Tag = component;
  const compiled = template(html);

  return (
    <Tag
      className={className}
      dangerouslySetInnerHTML={{
        __html: compiled(interpolateParams),
      }}
    />
  );
};
