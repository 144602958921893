import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getSkuSubtotalPrice, getSkuSubtotalPriceConstants } from 'store/product-details/actions';
import { IAction } from 'types/actions';

export interface IGetSkuSubtotalPricePayload {
  sku: string;
  amount?: number;
}

export function* getSkuSubtotalPriceHandler({ payload: { sku, amount } }: IAction<IGetSkuSubtotalPricePayload>) {
  const url = `${Endpoints.SKU_SUBTOTAL_PRICE}/${sku}?amount=${amount || ''}`;
  yield put(doRequest(getSkuSubtotalPrice, url, 'get'));
}

export default function* getSkuSubtotalPriceSaga() {
  yield takeLatest(getSkuSubtotalPriceConstants[Actions.REQUEST], getSkuSubtotalPriceHandler);
}
