import { FC } from 'react';
import { useContent } from 'hooks/use-content';
import { CustomLink } from 'components/custom-link';
import { VideoSources } from 'constants/video-sources.enum';
import iconPlayButtonSrc from 'assets/images/icons/icon-play-button.svg';
import { useVideoModal } from 'hooks/use-global-modal';
import { IVideoConfig } from 'components/custom-video';
import { ICSImage } from 'types/contentstack';
import { contentstackImageLinkResolver } from 'utils/contentstack-image-link-resolver';

import './navigation-tile.scss';

export interface INavigationTileProps {
  contentstackPath: string;
  title: string;
  category: string;
  description: string;
  image: ICSImage;
  link: string;
}

export const NavigationTile: FC<INavigationTileProps> = ({
  contentstackPath,
  title,
  category,
  description,
  image,
  link,
}) => {
  const { getContentByKey } = useContent();
  const videoTileConfig = getContentByKey<any>(`${contentstackPath}.video.0`, {});
  const hasVideo = Object.values(VideoSources).some((source) => videoTileConfig[source]);

  const resolveNavigationTileImage = contentstackImageLinkResolver(image);

  const showVideoModal = useVideoModal({
    className: 'navigation-tile-modal',
    text: {
      title: getContentByKey<string>(`${contentstackPath}.modal.title`, ''),
      description: getContentByKey<string>(`${contentstackPath}.modal.description`, ''),
      closeButtonLabel: getContentByKey<string>('modals.0.video_modal.0.close_button_label', ''),
    },
    videoConfig: getContentByKey<IVideoConfig>(`${contentstackPath}.video.0`, {}),
  });

  return (
    <section className="navigation-tile">
      <div className="navigation-tile__inner">
        <CustomLink url={link}>
          <div
            className="navigation-tile__content"
            style={{
              background: `center / cover no-repeat url(${resolveNavigationTileImage?.url})`,
            }}
          >
            <div className="navigation-tile__text">
              {category && <p className="navigation-tile__category">{category}</p>}
              {title && <p className="navigation-tile__title">{title}</p>}
              {description && <p className="navigation-tile__description">{description}</p>}
            </div>
          </div>
        </CustomLink>
        {hasVideo && (
          <button className="button-reset navigation-tile__video-button" onClick={() => showVideoModal()}>
            <img src={iconPlayButtonSrc} alt="Play" />
          </button>
        )}
      </div>
    </section>
  );
};
