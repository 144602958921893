import { put, take, takeLatest } from 'redux-saga/effects';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { Actions } from 'constants/actions.enum';
import { schedulePadPayment, schedulePadPaymentActionConstants } from 'store/schedule-payment/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { IPaymentDocument } from 'components/modals/schedule-payment-modal';

export interface ISchedulePadPaymentPayload {
  paymentDocuments: IPaymentDocument[];
  accountType: string;
  publicAccountNumber: string;
  routingNumber: string;
  scheduledDate: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: () => void;
}

export function* schedulePadPaymentOnSuccessSagaHandler({ payload }: IAction<ISchedulePadPaymentPayload>) {
  yield take(schedulePadPaymentActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* schedulePadPaymentOnFailSagaHandler({ payload }: IAction<ISchedulePadPaymentPayload>) {
  yield take(schedulePadPaymentActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack) {
    payload.onFailCallBack();
  }
}

export function* schedulePadPaymentHandler({
  payload: { paymentDocuments, accountType, publicAccountNumber, routingNumber, scheduledDate },
}: IAction<ISchedulePadPaymentPayload>) {
  yield put(
    doRequest(schedulePadPayment, Endpoints.SUBMIT_PAD_PAYMENT, 'post', {
      paymentDocuments,
      accountType,
      publicAccountNumber,
      routingNumber,
      scheduledDate,
    })
  );
}

export default function* schedulePadPaymentSaga() {
  yield takeLatest(schedulePadPaymentActionConstants[Actions.REQUEST], schedulePadPaymentOnSuccessSagaHandler);
  yield takeLatest(schedulePadPaymentActionConstants[Actions.REQUEST], schedulePadPaymentOnFailSagaHandler);
  yield takeLatest(schedulePadPaymentActionConstants[Actions.REQUEST], schedulePadPaymentHandler);
}
