import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MultiValue, SingleValue, components } from 'react-select';
import { isEmpty } from 'lodash-es';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfMonth,
  format,
  intlFormat,
  isThisMonth,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { useContent } from 'hooks/use-content';
import { Routes } from 'constants/routes.enum';
import { IInvoiceItem } from 'types/invoices';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { selectIsUserCountryCanada } from 'store/auth/selectors';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';
import { getDaysInterval, getTomorrowsDate } from 'utils/date-format';
import { CustomDropdown } from 'components/custom-dropdown';
import {
  scheduleBankAccountPayment,
  scheduleCreditCardPayment,
  schedulePadPayment,
} from 'store/schedule-payment/actions';
import { useSuccessErrorMessageModal } from 'hooks/use-global-modal';
import { IScheduleCardPaymentPayload } from 'store/schedule-payment/sagas/schedule-card-payment';
import { IScheduleBankAccountPaymentPayload } from 'store/schedule-payment/sagas/schedule-bank-payment';
import { ISchedulePadPaymentPayload } from 'store/schedule-payment/sagas/schedule-pad-payment';
import { IBankAccounts, ICreditCards } from 'store/payment-methods/reducers';
import { ContentstackHtml, ContentstackMessage, ContentstackText } from 'components/contentstack';
import { ISelectedPaymentMethod } from 'store/invoices/reducers';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { useLocalStorage } from 'hooks/use-local-storage';
import { InvoiceStatus } from 'constants/invoices.enum';
import { getLocaleURLByCode } from 'utils/get-locale-params';
import { IComments } from 'pages/payment-summary/payment-summary';

import './schedule-payment-modal.scss';

export interface IPaymentDocument {
  adjustmentReason?: string;
  comment?: string;
  confirmedBalance?: number;
  documentKey: string;
}
export interface ISchedulePaymentModalProps extends IModalProps {
  paymentDocuments: IInvoiceItem[];
  paymentMethod: ISelectedPaymentMethod | null;
  commentsInputValue?: IComments[];
}

export interface IDateInterval {
  dateTimeFrom: string;
  dateTimeTo: string;
}

export interface IDropdownOptions {
  label: string;
  value: number;
}

export const SchedulePaymentModal: FC<ISchedulePaymentModalProps> = ({
  isOpen,
  onClose,
  paymentDocuments,
  paymentMethod,
  commentsInputValue,
}) => {
  const daysRange = 60;
  const contentStackPath = 'modals.[0].schedule_payment_modal[0]';
  const datesInInterval: IDateInterval = getDaysInterval(daysRange);
  const { dateTimeFrom, dateTimeTo } = datesInInterval;
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();
  const setDate = (month: number, day: number, year: number) => {
    const newDate = new Date(year, month, day);
    /*
     When we select next month with a selected value of 31 days, and the new month have only 30 days,
     new Date will be equal first day of month + 1.
     So we just set a new mont with 1 day value.
     */
    if (newDate.getMonth() !== month) {
      return new Date(year, month, 1);
      /*
     When we set the date and then change back month when this day in not available, we should set date to dateTimeFrom.
     */
    } else if (newDate < new Date(dateTimeFrom)) {
      return new Date(dateTimeFrom);
    }
    return newDate;
  };
  const dateTomorrow = parseISO(getTomorrowsDate());
  const [day, setDay] = useState<number>(dateTomorrow.getDate());
  const [month, setMonth] = useState<number>(dateTomorrow.getMonth());
  const [year, setYear] = useState<number>(dateTomorrow.getFullYear());
  const [remainingDays, setRemainingDays] = useState<IDropdownOptions[]>([]);
  const defaultDay: IDropdownOptions = {
    label: dateTomorrow.getDate().toString(),
    value: dateTomorrow.getDate(),
  };
  const [dayValue, setDayValue] = useState<IDropdownOptions>(defaultDay);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [bankAccountDetails, setBankAccountDetails] = useState<IBankAccounts>();
  const [creditCardDetails, setCreditCardDetails] = useState<ICreditCards>();
  const [scheduledPaymentDate, setScheduledPaymentDate] = useState<Date>();
  const [isPaymentMethodCreditCard, setIsPaymentMethodCreditCard] = useState(false);
  const history = useHistory();
  const [, setTransactionStatus] = useLocalStorage('transactionStatus', InvoiceStatus.OPEN);
  const isUserCountryCanada = useSelector(selectIsUserCountryCanada);
  const [locale] = useLocalStorage('locale', null);

  const getMonthsWithInInterval = (dateTimeFrom: string, dateTimeTo: string) =>
    eachMonthOfInterval({
      start: parseISO(dateTimeFrom),
      end: parseISO(dateTimeTo),
    });

  const getYearsWithInInterval = (dateTimeFrom: string, dateTimeTo: string) =>
    eachYearOfInterval({
      start: parseISO(dateTimeFrom),
      end: parseISO(dateTimeTo),
    });

  const monthDropDownOptions: IDropdownOptions[] = getMonthsWithInInterval(dateTimeFrom, dateTimeTo).map((date) => ({
    label: intlFormat(date, { month: 'long' }, { locale: locale }),
    value: date.getMonth(),
  }));

  const yearDropDownOptions: IDropdownOptions[] = getYearsWithInInterval(dateTimeFrom, dateTimeTo).map((date) => ({
    label: date.getFullYear().toString(),
    value: date.getFullYear(),
  }));

  const getRemainingDaysOfMonth = (date: Date, dateTimeTo: string, month: number) =>
    eachDayOfInterval({
      start: isThisMonth(date) ? dateTomorrow : startOfMonth(date),
      end: month === parseISO(dateTimeTo).getMonth() ? parseISO(dateTimeTo) : endOfMonth(date),
    });

  const onChangeMonth = (selectionOption: MultiValue<IDropdownOptions> | SingleValue<IDropdownOptions>) => {
    const newValue = selectionOption as IDropdownOptions;
    setMonth(newValue.value);
  };
  const onChangeYear = (selectionOption: MultiValue<IDropdownOptions> | SingleValue<IDropdownOptions>) => {
    const newValue = selectionOption as IDropdownOptions;
    setYear(newValue.value);
  };

  useEffect(() => {
    if (month) {
      const defaultDate = setDate(month, day, year);
      const dates = getRemainingDaysOfMonth(defaultDate, dateTimeTo, month).map((date) => ({
        label: date.getDate().toString(),
        value: date.getDate(),
      }));
      setRemainingDays(dates);
      setDayValue({ label: dates[0].label, value: dates[0].value });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  const onChangeDay = (selectionOption: MultiValue<IDropdownOptions> | SingleValue<IDropdownOptions>) => {
    const newValue = selectionOption as IDropdownOptions;
    setDayValue(newValue);
  };

  useEffect(() => {
    if (dayValue) {
      setDay(dayValue.value);
    }
  }, [dayValue]);

  useEffect(() => {
    if (day && month && year) {
      setScheduledPaymentDate(setDate(month, day, year));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year]);

  const dropDownRenderOptions = ({ children, ...props }: any) => (
    <components.Option {...props}>{children}</components.Option>
  );

  const showSchedulePaymentSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
    modalTitle: getContentByKey(`${contentStackPath}.success_message_title`, ''),
    children: (
      <ContentstackHtml
        component="span"
        contentKey={`${contentStackPath}.success_message_new`}
        interpolateParams={{ localeUrl: getLocaleURLByCode(locale) }}
      />
    ),
  });

  const showSchedulePaymentFailModal = useSuccessErrorMessageModal({
    type: 'error',
    modalTitle: getContentByKey(`${contentStackPath}.error_message_title`, ''),
    children: <ContentstackMessage messageId={'MSG189'} type="error" />,
    autoClose: false,
    hasCrossButton: true,
  });

  const checkPaymentMethodType = (paymentMethod: ICreditCards | IBankAccounts): paymentMethod is ICreditCards => {
    return !isEmpty(paymentMethod['cardType']);
  };

  useEffect(() => {
    if (paymentMethod) {
      if (checkPaymentMethodType(paymentMethod)) {
        setIsPaymentMethodCreditCard(true);
        setCreditCardDetails(paymentMethod);
      } else {
        setBankAccountDetails(paymentMethod);
      }
    }
  }, [paymentMethod]);

  const requiredPaymentDocumentDetails = paymentDocuments.map(({ adjustmentReason, confirmedBalance, documentKey }) => {
    const paymentInvoiceComment = commentsInputValue?.find((invoice) => {
      return invoice?.selectedDocumentKey === documentKey && !!invoice?.comment;
    });
    return {
      adjustmentReason,
      confirmedBalance,
      documentKey,
      ...(paymentInvoiceComment?.comment && { comment: paymentInvoiceComment.comment }),
    };
  });

  const onSuccessfulSchedulePayment = () => {
    showSchedulePaymentSuccessModal();
    setTimeout(() => {
      history.push(getLocalizedShopUrl(Routes.AccountPayments));
    }, 3000);
  };

  const scheduleBankPayment = () => {
    if (bankAccountDetails && scheduledPaymentDate) {
      const { accountType, publicRoutingNumber, publicAccountNumber } = bankAccountDetails;
      !isUserCountryCanada
        ? dispatch(
            scheduleBankAccountPayment.request<IScheduleBankAccountPaymentPayload>({
              paymentDocuments: requiredPaymentDocumentDetails,
              accountType: accountType,
              publicRoutingNumber: publicRoutingNumber,
              publicAccountNumber: publicAccountNumber,
              scheduledDate: format(scheduledPaymentDate, 'yyyy-MM-dd'),
              onSuccessCallBack: onSuccessfulSchedulePayment,
              onFailCallBack: showSchedulePaymentFailModal,
            })
          )
        : dispatch(
            schedulePadPayment.request<ISchedulePadPaymentPayload>({
              paymentDocuments: requiredPaymentDocumentDetails,
              accountType: accountType,
              routingNumber: publicRoutingNumber,
              publicAccountNumber: publicAccountNumber,
              scheduledDate: format(scheduledPaymentDate, 'yyyy-MM-dd'),
              onSuccessCallBack: onSuccessfulSchedulePayment,
              onFailCallBack: showSchedulePaymentFailModal,
            })
          );
    }
  };

  const scheduleCreditCard = () => {
    if (creditCardDetails && scheduledPaymentDate) {
      const { cardLastFourDigits, cardType } = creditCardDetails;

      dispatch(
        scheduleCreditCardPayment.request<IScheduleCardPaymentPayload>({
          cardLastFourDigits: cardLastFourDigits,
          cardType: cardType,
          paymentDocuments: requiredPaymentDocumentDetails,
          scheduledDate: format(scheduledPaymentDate, 'yyyy-MM-dd'),
          onSuccessCallBack: onSuccessfulSchedulePayment,
          onFailCallBack: showSchedulePaymentFailModal,
        })
      );
    }
  };

  const submitScheduledPayment = () => {
    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);
    setTransactionStatus(InvoiceStatus.SCHEDULED);
    isPaymentMethodCreditCard ? scheduleCreditCard() : scheduleBankPayment();
  };

  return (
    <>
      <Modal className="schedule-payment-modal" isOpen={isOpen} onClose={onClose} withBackdropClick>
        <ModalHeader iconType="error" contentstackPathIcon={`${contentStackPath}.calendar_icon`}>
          <ContentstackText contentKey={`${contentStackPath}.header_title`} />
        </ModalHeader>
        <div className="schedule-payment-modal__content-wrapper">
          <div className="schedule-payment-modal__description">
            <ContentstackHtml contentKey={`${contentStackPath}.description`} />
          </div>
          <div className="schedule-payment-modal__content">
            <CustomDropdown
              className="schedule-payment-modal__month"
              inputId="schedule-payment-modal__month--id"
              items={monthDropDownOptions}
              onChange={onChangeMonth}
              defaultValue={monthDropDownOptions[0]}
              renderOption={dropDownRenderOptions}
            />
            <div className="schedule-payment-modal__day-and-year">
              <CustomDropdown
                className="schedule-payment-modal__day"
                inputId="schedule-payment-modal__day--id"
                items={remainingDays}
                onChange={onChangeDay}
                defaultValue={defaultDay}
                value={dayValue}
                renderOption={dropDownRenderOptions}
              />
              <CustomDropdown
                className="schedule-payment-modal__year"
                inputId="schedule-payment-modal__year--id"
                items={yearDropDownOptions}
                onChange={onChangeYear}
                defaultValue={yearDropDownOptions[0]}
                renderOption={dropDownRenderOptions}
              />
            </div>
          </div>
        </div>
        <ModalFooter hasCancelButton cancelButtonHandler={onClose} contentstackPath={contentStackPath}>
          <CorButton onClick={submitScheduledPayment}>
            <ContentstackText contentKey={`${contentStackPath}.submit_button_label`} />
          </CorButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
