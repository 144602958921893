import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import './cor-two-column-details.scss';

export interface ICorTwoColumnDetailsProps extends PropsWithChildren {
  className?: string;
}

export const CorTwoColumnDetails: FC<ICorTwoColumnDetailsProps> = ({ children, className }) => {
  return (
    <div data-testid={'cor-two-column-details'} className={classNames('cor-two-column-details', className)}>
      {children}
    </div>
  );
};
