import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const scheduleCreditCardPaymentActionConstants = createConstants(types.SCHEDULE_CREDIT_CARD_PAYMENT);
export const scheduleBankAccountPaymentActionConstants = createConstants(types.SCHEDULE_BANK_ACCOUNT_PAYMENT);
export const cancelScheduledPaymentActionConstants = createConstants(types.CANCEL_SCHEDULED_PAYMENT);
export const schedulePadPaymentActionConstants = createConstants(types.SCHEDULE_PAD_PAYMENT);

export const scheduleCreditCardPayment = createRequestActions(scheduleCreditCardPaymentActionConstants);
export const scheduleBankAccountPayment = createRequestActions(scheduleBankAccountPaymentActionConstants);
export const cancelScheduledPayment = createRequestActions(cancelScheduledPaymentActionConstants);
export const schedulePadPayment = createRequestActions(schedulePadPaymentActionConstants);
