import './search-results.page.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAlgoliaQueryParams } from 'hooks';
import { Breadcrumbs } from 'components/breadcrumbs';
import { useBreadcrumbs } from 'hooks/use-breadcrumbs';
import { ALGOLIA_DEFAULT_PAGE_TYPE } from 'hooks/use-algolia';
import { CorMainPage, CorListPage, CorFilterButtons } from './components';

const SearchResultsPage = () => {
  const { breadcrumbs } = useBreadcrumbs();
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const location = useLocation();

  React.useEffect(() => {
    setQueryParams((prev) => ({
      ...prev,
      page_type: queryParams?.page_type || ALGOLIA_DEFAULT_PAGE_TYPE,
      page: queryParams?.page || undefined,
      query: queryParams?.query || undefined,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.search]);

  return (
    <div className="search-results-page">
      <section className="grid-container ">
        <Breadcrumbs breadcrumbs={breadcrumbs} className="search-results-page__breadcrumb" />
      </section>

      <CorFilterButtons />

      {[ALGOLIA_DEFAULT_PAGE_TYPE].includes(queryParams?.page_type || '') && <CorMainPage />}
      {![ALGOLIA_DEFAULT_PAGE_TYPE].includes(queryParams?.page_type || '') && <CorListPage />}
    </div>
  );
};

export default React.memo(SearchResultsPage);
