import { all, spawn } from 'redux-saga/effects';
import getProductDetailsSaga from './get-product-details';
import getSkuSubtotalPriceSaga from './get-sku-subtotal-price';
import putRecentlyViewedItemSaga from './put-recently-viewed-item';
import getProductRecipesSaga from './get-product-recipes';

export default function* productDetailsSagas() {
  yield all([
    spawn(getProductDetailsSaga),
    spawn(getSkuSubtotalPriceSaga),
    spawn(putRecentlyViewedItemSaga),
    spawn(getProductRecipesSaga),
  ]);
}
