import { put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getMoreProductsFromBrand, getMoreProductsFromBrandConstants } from 'store/more-products-from-brand/actions';
import { Actions } from 'constants/actions.enum';

export interface IGetMoreProductsFromBrandPayload {
  brand: string;
  selectedSku: string;
}

export function* getMoreProductsFromBrandHandler({ payload }: IAction<IGetMoreProductsFromBrandPayload>) {
  yield put(doRequest(getMoreProductsFromBrand, `${Endpoints.MORE_FROM_BRAND}`, 'post', payload));
}

export default function* getMoreProductsFromBrandSaga() {
  yield takeLatest(getMoreProductsFromBrandConstants[Actions.REQUEST], getMoreProductsFromBrandHandler);
}
