import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getAccountInformationActionConstants = createConstants(types.GET_ACCOUNT_INFORMATION);
export const setAccountPasswordActionConstants = createConstants(types.SET_ACCOUNT_PASSWORD);
export const updatePersonalInfoActionConstants = createConstants(types.UPDATE_PERSONAL_INFO);
export const updateUserInfoActionConstants = createConstants(types.UPDATE_USER_INFO);
export const getUserInformationActionConstants = createConstants(types.GET_USER_INFORMATION);
export const deleteUserActionConstants = createConstants(types.DELETE_USER);
export const getOrderHistoryActionConstants = createConstants(types.GET_ORDER_HISTORY);
export const getOrderHistoryProductsActionConstants = createConstants(types.GET_ORDER_HISTORY_PRODUCTS);
export const getOrderDetailsActionConstants = createConstants(types.GET_ORDER_DETAILS);
export const cancelOrderActionConstants = createConstants(types.CANCEL_ORDER);
export const generateTemporaryPasswordActionConstants = createConstants(types.GENERATE_TEMPORARY_PASSWORD);
export const updatePasswordFromTemporaryActionConstants = createConstants(types.UPDATE_PASSWORD_FROM_TEMPORARY);
export const inviteNewUserConstants = createConstants(types.INVITE_NEW_USER);
export const resendInvitationToPendingUsersActionConstants = createConstants(types.RESEND_INVITATION_TO_PENDING_USERS);
export const getAllSkusProductsListActionConstants = createConstants(types.GET_ALL_SKUS_PRODUCTS_LIST);

export const getAccountInformation = createRequestActions(getAccountInformationActionConstants);
export const setAccountPassword = createRequestActions(setAccountPasswordActionConstants);
export const updatePersonalInfo = createRequestActions(updatePersonalInfoActionConstants);
export const updateUserInfo = createRequestActions(updateUserInfoActionConstants);
export const getUserInformation = createRequestActions(getUserInformationActionConstants);
export const deleteUser = createRequestActions(deleteUserActionConstants);
export const generateTemporaryPassword = createRequestActions(generateTemporaryPasswordActionConstants);
export const updatePasswordFromTemporary = createRequestActions(updatePasswordFromTemporaryActionConstants);
export const inviteNewUser = createRequestActions(inviteNewUserConstants);
export const resendInvitationToPendingUsers = createRequestActions(resendInvitationToPendingUsersActionConstants);
export const clearChangePasswordFormServerError = () => action(types.CLEAR_CHANGE_PASSWORD_FORM_SERVER_ERROR, {});
export const clearUpdatedUserInfo = () => action(types.CLEAR_UPDATED_USER_INFO, {});
export const closeChangePasswordModal = () => action(types.CLOSE_CHANGE_PASSWORD_MODAL, {});

export const getSalesRepInformationActionConstants = createConstants(types.GET_SALES_REP_INFORMATION);
export const getSalesRepInformation = createRequestActions(getSalesRepInformationActionConstants);
export const getOrderHistory = createRequestActions(getOrderHistoryActionConstants);
export const getOrderHistoryProducts = createRequestActions(getOrderHistoryProductsActionConstants);
export const getOrderDetails = createRequestActions(getOrderDetailsActionConstants);
export const cancelOrder = createRequestActions(cancelOrderActionConstants);
export const getAllSkusProductsList = createRequestActions(getAllSkusProductsListActionConstants);
export const setOrderCancellationDenied = (isOrderCancellationDenied: boolean) =>
  action(types.SET_ORDER_CANCELLATION_DENIED, { isOrderCancellationDenied });

export const changePaginationCurrent = (current: number) =>
  action(types.ORDER_HISTORY_PAGINATION_CHANGE, {
    current,
  });

export const changeOrderProductsPaginationCurrent = (current: number) =>
  action(types.ORDER_HISTORY_PRODUCTS_PAGINATION_CHANGE, {
    current,
  });

export const setActiveOrderView = (activeOrderView: boolean) =>
  action(types.SET_ACTIVE_ORDER_VIEW, { activeOrderView });

export const setActivePaymentView = (activePaymentView: boolean) =>
  action(types.SET_ACTIVE_PAYMENT_VIEW, { activePaymentView });

export const resetInvitationEmailStatus = () => action(types.RESET_INVITATION_EMAIL_STATUS);

export const clearAllSkusProductsList = () => action(types.CLEAR_ALL_SKUS_PRODUCTS_LIST);

export const setActiveEditUserId = (activeEditUserId: string | null) =>
  action(types.SET_ACTIVE_EDIT_USER_ID, { activeEditUserId });
