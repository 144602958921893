import 'corporate-components/cor-pages/corporate/pages/recipes/recipes.page.scss';

import { Children, FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { CorChefNotes } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-chef-notes';
import { CorIngredientsList } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-ingredients-list';
import { CorJumpLinks } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-jump-links';
import { CorProductList } from 'corporate-components/cor-product-list';
import { CorDetailHeroBanner } from 'corporate-components/cor-detail-hero-banner';
import { ICSCorImage } from 'corporate-interfaces';
import { CorDetailRelatedContent } from 'corporate-components/cor-detail-related-content';
import { CorTwoColumnDetails } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-two-column-details';
import { CorShareSocial } from 'corporate-components/cor-share-social';
import { useContent } from 'hooks/use-content';
import {
  CorFeaturedContentCardStyleEnum,
  CorFeaturedContentContentTypeEnum,
} from 'constants/cor-featured-content.enum';
import { selectIsAuthorised } from 'store/auth/selectors';

const ColumnWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {Children.map(children, (child) => {
        if (
          child &&
          typeof child === 'object' &&
          'props' in child &&
          child?.props?.value &&
          'button' in child?.props?.value
        ) {
          return (
            <CorTwoColumnDetails>
              <div />
              {child}
            </CorTwoColumnDetails>
          );
        }
        return child;
      })}
    </>
  );
};

export const RecipesPage: FC<PropsWithChildren> = ({ children }) => {
  const { getContentByKey } = useContent();
  const isAuthorised: boolean = useSelector(selectIsAuthorised);

  return (
    <>
      <CorDetailHeroBanner
        heading={getContentByKey('title', '')}
        tags={getContentByKey('superheading', '')}
        description={getContentByKey('short_description', '')}
        image={getContentByKey('banner_image', {} as ICSCorImage)}
        iconTitleGroup={getContentByKey('icon_title_group', [])}
      />

      <CorJumpLinks />

      {isAuthorised && <CorProductList />}

      <CorIngredientsList />

      <ColumnWrapper children={children} />

      <CorChefNotes />

      <CorTwoColumnDetails>
        {/*  empty container for the left column*/}
        <div />
        <CorShareSocial />
      </CorTwoColumnDetails>

      <CorDetailRelatedContent
        baseKey="related_recipes_section"
        relatedContentKey="related_recipes_section.related_recipe"
        contentType={CorFeaturedContentContentTypeEnum.Recipes}
        className="cor-detail-related-content__recipes"
        cardDisplayNumber={4}
        cardStyle={CorFeaturedContentCardStyleEnum.SmallCardWithTitleCategory}
        carousel
        textAlignment="Center"
        titleExclusion={getContentByKey('title', '')}
      />
    </>
  );
};
