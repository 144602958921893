import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { changeLineItemsOrder, changeLineItemsOrderActionConstants } from 'store/shopping-lists/actions';
import { IAction } from 'types/actions';

export interface IChangeLineItemsOrderSagaHandlerPayload {
  shoppingListId: string;
  lineItemIds: string[];
}

export function* changeLineItemsOrderSagaHandler({ payload }: IAction<IChangeLineItemsOrderSagaHandlerPayload>) {
  const { shoppingListId, lineItemIds } = payload;

  yield put(
    doRequest(changeLineItemsOrder, `${Endpoints.SHOPPING_LISTS}/${shoppingListId}/items/order`, 'patch', {
      lineItemIds,
    })
  );
}

export default function* changeLineItemsOrderSaga() {
  yield takeLatest(changeLineItemsOrderActionConstants[Actions.REQUEST], changeLineItemsOrderSagaHandler);
}
