import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import {
  getCsrLocationsWithAssignedAccountsByDayOfWeek,
  getCsrLocationsWithAssignedAccountsByDayOfWeekConstants,
} from 'store/dashboard/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IGetLocationsWithAssignedAccountsByDayOfWeekPayload {
  searchText?: string;
}

export function* getLocationsWithAssignedAccountsByDayOfWeekHandler({
  payload: { searchText = '' },
}: IAction<IGetLocationsWithAssignedAccountsByDayOfWeekPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText?.trim());

  yield put(
    doRequest(
      getCsrLocationsWithAssignedAccountsByDayOfWeek,
      `${Endpoints.DASHBOARD_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_DAY_OF_WEEK}?searchText=${searchQuery}`,
      'get'
    )
  );
}

export default function* getLocationsWithAssignedAccountsByDayOfWeekSaga() {
  yield takeLatest(
    getCsrLocationsWithAssignedAccountsByDayOfWeekConstants[Actions.REQUEST],
    getLocationsWithAssignedAccountsByDayOfWeekHandler
  );
}
