import { FC, PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { CorButton } from 'components/cor-button';
import { CustomRadioButton } from 'components/custom-radio-button';
import { ContentstackText } from 'components/contentstack';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { SelectShoppingListActionModalEnum } from './select-shopping-list-action-modal-enum';

import './select-shopping-list-action-modal.scss';

export interface IActionOption {
  label: string;
  action: () => void;
  disabled?: boolean;
  icon?: string;
  hoverIcon?: string;
  isHidden?: boolean;
}

export interface ICustomActionOptionProps extends PropsWithChildren {
  onChange: () => void;
  icon?: string;
  hoverIcon?: string;
  hasHoverIcon?: boolean;
  isHidden?: boolean;
}

export interface ISelectShoppingListActionModalProps extends IModalProps {
  contentstackPath: string;
  options: IActionOption[];
  onClose: () => void;
  usageContext?: string;
  areOptionsClickable: boolean;
}

export const SelectShoppingListActionModal: FC<ISelectShoppingListActionModalProps> = ({
  isOpen,
  onClose,
  options,
  contentstackPath,
  usageContext,
  areOptionsClickable,
  ...modalProps
}) => {
  const { isDesktop } = useBreakpoint();
  const { getContentByKey } = useContent();
  const filteredOptions = options.filter((option) => !option.isHidden);
  const defaultSelectedOption = areOptionsClickable ? null : filteredOptions.find((option) => !option.disabled) || null;
  const [selectedOption, setSelectedOption] = useState<IActionOption | null>(defaultSelectedOption);

  useEffect(() => {
    if (!areOptionsClickable) {
      setSelectedOption(defaultSelectedOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areOptionsClickable, isOpen]);

  useEffect(() => {
    if (areOptionsClickable && selectedOption !== null) {
      selectedOption.action();
    }
  }, [areOptionsClickable, selectedOption]);

  const handleSelectModalClose = () => {
    onClose();
    setSelectedOption(defaultSelectedOption);
  };

  const isEditingShoppingList = usageContext === SelectShoppingListActionModalEnum.EDIT_SHOPPING_LIST;
  const modalHeader = isEditingShoppingList
    ? getContentByKey(`${contentstackPath}.mobile_edit_list_heading`, '')
    : getContentByKey(`${contentstackPath}.heading`, '');

  const modalClassName = classNames('select-shopping-list-action-modal', {
    'select-shopping-list-action-modal--edit-shopping-list': isEditingShoppingList,
    'select-shopping-list-action-modal--desktop': isDesktop,
  });

  return (
    <Modal
      parentClassName="select-shopping-list-action-modal__wrapper"
      className={modalClassName}
      isOpen={isOpen}
      onClose={handleSelectModalClose}
      {...modalProps}
    >
      {!isDesktop && <ModalHeader className="select-shopping-list-action-modal__heading">{modalHeader}</ModalHeader>}
      <div className="select-shopping-list-action-modal__options">
        {filteredOptions.map((option) =>
          !isEditingShoppingList ? (
            <CustomRadioButton
              key={option.label}
              disabled={option.disabled}
              checked={selectedOption?.label === option.label}
              onChange={() => setSelectedOption(option)}
            >
              {option.label}
            </CustomRadioButton>
          ) : (
            <CustomActionOption
              key={option.label}
              icon={option.icon}
              hoverIcon={option.hoverIcon}
              onChange={() => setSelectedOption(option)}
              hasHoverIcon
            >
              {option.label}
            </CustomActionOption>
          )
        )}
      </div>
      {!isDesktop && (
        <ModalFooter>
          <CorButton
            className="select-shopping-list-action-modal__button"
            onClick={() => {
              handleSelectModalClose();
              selectedOption?.action();
            }}
          >
            <ContentstackText contentKey={`${contentstackPath}.done_button_label`} />
          </CorButton>
        </ModalFooter>
      )}
    </Modal>
  );
};

export const CustomActionOption: FC<ICustomActionOptionProps> = ({
  children,
  onChange,
  icon,
  hoverIcon,
  hasHoverIcon,
  isHidden,
}) => {
  const { isDesktop } = useBreakpoint();

  return !isHidden ? (
    <div className="custom-action-option" onClick={onChange}>
      <img src={icon} alt="action icon" className="custom-action-option__icon" />
      {isDesktop && hasHoverIcon && (
        <img src={hoverIcon} alt="action icon hover" className="custom-action-option__icon-hover" />
      )}
      <span>{children}</span>
    </div>
  ) : null;
};
