import { FC, PropsWithChildren } from 'react';
import { CorFooter } from 'corporate-components';
import { PageLayout } from 'components/layout';
import { CorHeader } from 'components/layout/cor-header';

export const DefaultLayout: FC<PropsWithChildren> = ({ children }) => (
  <PageLayout classModifier="default" header={CorHeader} footer={CorFooter}>
    {children}
  </PageLayout>
);
