enum shoppingListsActionTypes {
  ADD_TO_FAVORITES = 'ADD_TO_FAVORITES',
  REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES',
  UPDATE_PRODUCT_FAVORITES_STATUS = 'UPDATE_PRODUCT_FAVORITES_STATUS',
  GET_SHOPPING_LISTS = 'GET_SHOPPING_LISTS',
  SHOPPING_LISTS_PAGINATION_CHANGE = 'SHOPPING_LISTS_PAGINATION_CHANGE',
  GET_SHOPPING_LIST_DETAILS = 'GET_SHOPPING_LIST_DETAILS',
  RESET_SHOPPING_LIST_DETAILS = 'RESET_SHOPPING_LIST_DETAILS',
  UPDATE_SHOPPING_LIST_NAME = 'UPDATE_SHOPPING_LIST_NAME',
  UPDATE_SHOPPING_LIST_ITEM = 'UPDATE_SHOPPING_LIST_ITEM',
  DELETE_SHOPPING_LIST_ITEM = 'DELETE_SHOPPING_LIST_ITEM',
  ADD_ITEMS_TO_SHOPPING_LISTS = 'ADD_ITEMS_TO_SHOPPING_LISTS',
  ADD_ITEMS_FROM_MARKETING_SHOPPING_LISTS = 'ADD_ITEMS_FROM_MARKETING_SHOPPING_LISTS',
  CREATE_SHOPPING_LIST_WITH_PRODUCTS = 'CREATE_SHOPPING_LIST_WITH_PRODUCTS',
  CREATE_SHOPPING_LIST_WITH_PRODUCTS_FROM_MARKETING_SHOPPING_LISTS = 'CREATE_SHOPPING_LIST_WITH_PRODUCTS_FROM_MARKETING_SHOPPING_LISTS',
  CREATE_EMPTY_SHOPPING_LIST = 'CREATE_EMPTY_SHOPPING_LIST',
  IMPORT_SHOPPING_LIST = 'IMPORT_SHOPPING_LIST',
  SET_ADDED_ITEMS_NOTIFICATION_INFO = 'SET_ADDED_ITEMS_NOTIFICATION_INFO',
  DELETE_SHOPPING_LIST = 'DELETE_SHOPPING_LIST',
  ADD_LIST_TO_CART = 'ADD_LIST_TO_CART',
  CHANGE_LINE_ITEMS_ORDER = 'CHANGE_LINE_ITEMS_ORDER',
  CHANGE_SHOPPING_LISTS_ORDER = 'CHANGE_SHOPPING_LISTS_ORDER',
  GET_SHOPPING_LISTS_SORTED_BY_NAME = 'GET_SHOPPING_LISTS_SORTED_BY_NAME',
  CLEAR_SHOPPING_LISTS_SORTED_BY_NAME = 'CLEAR_SHOPPING_LISTS_SORTED_BY_NAME',
  GET_MARKETING_SHOPPING_LISTS = 'GET_MARKETING_SHOPPING_LISTS',
  UPDATE_SHOPPING_LIST_DETAILS_ITEMS_ORDER = 'UPDATE_SHOPPING_LIST_DETAILS_ITEMS_ORDER',
  HAS_SHOPPING_LIST_NAME_DUPLICATE_ERROR = 'HAS_SHOPPING_LIST_NAME_DUPLICATE_ERROR',
  RESET_HAS_SHOPPING_LIST_NAME_DUPLICATE_ERROR = 'RESET_HAS_SHOPPING_LIST_NAME_DUPLICATE_ERROR',
}

export default shoppingListsActionTypes;
