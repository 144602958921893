import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import iconWarning from 'assets/images/icons/icon-warning-rounded-yellow.svg';

import './sku-quantity-limitation-warning.scss';

export interface ISkuQuantityLimitationWarningProps extends PropsWithChildren {
  className?: string;
  bordered?: boolean;
}

export const SkuQuantityLimitationWarning: FC<ISkuQuantityLimitationWarningProps> = ({
  children,
  className,
  bordered = false,
}) => {
  return (
    <div
      className={classNames('sku-quantity-limitation-warning', className, {
        'sku-quantity-limitation-warning--bordered': bordered,
      })}
    >
      <img src={iconWarning} alt="icon warning" className="sku-quantity-limitation-warning__icon" />
      {children}
    </div>
  );
};
