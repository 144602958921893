import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import {
  getCartExpiredNotificationViewed,
  getCartExpiredNotificationViewedActionConstants,
} from 'store/order-cut-off/actions';
import { IAction } from 'types/actions';

export interface IGetCartExpiredNotificationViewedPayload {
  reload?: boolean;
}

export function* getCartExpiredNotificationViewedSagaHandler() {
  yield put(doRequest(getCartExpiredNotificationViewed, `${Endpoints.CUT_OFF_EXPIRED_NOTIFICATION_VIEWED}`, 'patch'));
}

export function* getCartExpiredNotificationViewedSuccessSagaHandler({
  payload,
}: IAction<IGetCartExpiredNotificationViewedPayload>) {
  yield take(getCartExpiredNotificationViewedActionConstants[Actions.SUCCESS]);

  if (payload.reload) {
    window.location.reload();
  }
}

export default function* getCartExpiredNotificationViewedSaga() {
  yield takeLatest(
    getCartExpiredNotificationViewedActionConstants[Actions.REQUEST],
    getCartExpiredNotificationViewedSagaHandler
  );
  yield takeLatest(
    getCartExpiredNotificationViewedActionConstants[Actions.REQUEST],
    getCartExpiredNotificationViewedSuccessSagaHandler
  );
}
