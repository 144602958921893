import { FC } from 'react';
import { times } from 'lodash-es';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { NavigationTile } from 'components/navigation-tiles/components/navigation-tile';
import { ICSImage } from 'types/contentstack';

import './navigation-tiles.scss';

export interface INavigationTilesProps {
  contentstackPath: string;
  numberOfTiles?: number;
}

export const NavigationTiles: FC<INavigationTilesProps> = ({ contentstackPath, numberOfTiles }) => {
  const { getContentByKey } = useContent();
  const { isDesktop } = useBreakpoint();
  const fallbackImage: ICSImage = getContentByKey(`${contentstackPath}.fallback_default_image`, {});
  const tilesDisplayed = numberOfTiles || isDesktop ? 3 : 2;

  const navigationTilesArray = times(3).map((n, i) => {
    const tileContentstackPath = `${contentstackPath}.navigation_tile_item.${i}`;

    return {
      contentstackPath: tileContentstackPath,
      title: getContentByKey(`${tileContentstackPath}.title`, ''),
      category: getContentByKey(`${tileContentstackPath}.category`, ''),
      description: getContentByKey(`${tileContentstackPath}.description`, ''),
      image: getContentByKey(`${tileContentstackPath}.tile_image`, fallbackImage),
      link: getContentByKey(`${tileContentstackPath}.url`, ''),
    };
  });

  const filteredNavTiles = navigationTilesArray.filter((tile) => tile.category || tile.title || tile.description);

  return (
    <div className="navigation-tiles">
      {filteredNavTiles.slice(0, tilesDisplayed).map((tile, i) => (
        <NavigationTile {...tile} key={i} />
      ))}
    </div>
  );
};
