export const getElementTopOffset = (elem: HTMLElement): number => {
  const box = elem.getBoundingClientRect();
  const body = document.body;
  const docEl = document.documentElement;
  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const clientTop = docEl.clientTop || body.clientTop || 0;
  const top = box.top + scrollTop - clientTop;

  return Math.round(top);
};
