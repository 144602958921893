import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import { IMultipleLineItem } from 'store/shopping-cart/sagas/add-multiple-products-to-cart';
import types from 'store/account/action-types';
import * as actions from '../actions';

export const orderHistoryProductsAllSkusInitialState = {
  skuInfos: [],
  loading: false,
};

interface IOrderHistoryAllProductsPayload {
  payload: IOrderHistoryProductsAllSkusState;
}

export interface IOrderHistoryProductsAllSkusState {
  skuInfos: IMultipleLineItem[];
  loading: boolean;
}

export const orderHistoryProductsAllSkus = createReducer<IOrderHistoryProductsAllSkusState>(
  {
    [actions.getAllSkusProductsListActionConstants[Actions.REQUEST]]: (state: IOrderHistoryProductsAllSkusState) => ({
      ...state,
      loading: true,
    }),
    [actions.getAllSkusProductsListActionConstants[Actions.SUCCESS]]: (
      state: IOrderHistoryProductsAllSkusState,
      { payload }: IOrderHistoryAllProductsPayload
    ) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [actions.getAllSkusProductsListActionConstants[Actions.FAIL]]: (state: IOrderHistoryProductsAllSkusState) => ({
      ...state,
      loading: false,
    }),
    [types.CLEAR_ALL_SKUS_PRODUCTS_LIST]: (state) => ({ ...state, skuInfos: [] }),
  },
  orderHistoryProductsAllSkusInitialState
);
