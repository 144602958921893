import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ContentstackImage, ContentstackText } from 'components/contentstack';

import './modal-header.scss';

interface IModalHeaderProps extends PropsWithChildren {
  iconType?: 'error' | 'success' | 'error_two';
  contentstackPath?: string;
  titleContentstackPath?: string;
  contentstackPathIcon?: string;
  className?: string;
}

// TODO: make contentstackPath the same for all images then remove extra contentstackPathIcon prop
export const ModalHeader: FC<IModalHeaderProps> = ({
  iconType,
  contentstackPath,
  contentstackPathIcon,
  titleContentstackPath,
  children,
  className,
}) => {
  return (
    <div className={classNames('modal-header', className)}>
      <div className="modal-header__title">
        {iconType && <ContentstackImage contentKey={contentstackPathIcon || `${contentstackPath}.${iconType}_icon`} />}
        <div className="modal-header__title-text">
          {titleContentstackPath && <ContentstackText contentKey={titleContentstackPath} />}
          {children}
        </div>
      </div>
    </div>
  );
};
