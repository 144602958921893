import { FC } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectAddCreditCardFormIsSubmitting } from 'store/payment-methods/selectors';
import { ContentstackHtml, ContentstackText } from 'components/contentstack';
import { AddCreditCardForm } from 'components/forms/add-payment-card-form';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ModalHeader } from 'components/modals/common/modal/components';
import { LoadingIndicator, LoadingIndicatorType } from 'components/loading-indicator';

import './add-payment-card-modal.scss';

export interface IAddPaymentCardModalProps extends IModalProps {}

export const AddPaymentCardModal: FC<IAddPaymentCardModalProps> = ({ isOpen = false, onClose = () => {} }) => {
  const isAddNewCreditCardSubmitting = useSelector(selectAddCreditCardFormIsSubmitting);
  const contentStackPath = 'modals.[0].add_payment_card_modal[0]';
  const modalClassName = 'add-payment-card-modal';
  const addCardClassName = classNames(`${modalClassName}__modal`, {
    'add-payment-card-modal__modal--with-loading-indicator': isAddNewCreditCardSubmitting,
  });

  return (
    <Modal
      isOpen={isOpen}
      parentClassName={modalClassName}
      className={addCardClassName}
      onClose={onClose}
      hideCloseButton={isAddNewCreditCardSubmitting}
      withBackdropClick={!isAddNewCreditCardSubmitting}
    >
      {isAddNewCreditCardSubmitting && <LoadingIndicator type={LoadingIndicatorType.FULLSIZED} />}
      <>
        <ModalHeader className={`${modalClassName}__header`}>
          <ContentstackText contentKey={`${contentStackPath}.header`} />
        </ModalHeader>

        <div className={`${modalClassName}__wrapper`}>
          <div className={`${modalClassName}__description`}>
            <ContentstackHtml contentKey={`${contentStackPath}.description`} />
          </div>
          <AddCreditCardForm contentStackPath={contentStackPath} onClose={onClose} />
        </div>
      </>
    </Modal>
  );
};
