import { ICSCorImage, ICSCorLinkURL } from 'corporate-interfaces';
import { corContentStackImageTifExtensionHelper, isTifImage } from 'corporate-utils/cor-contantstack-image-helper';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';

export type CorContentstackImageAsset = {
  url: string;
  link_url?: ICSCorLinkURL;
  image_alt_text?: string;
  open_in_a_new_tab?: boolean;
};

export const corContentstackImageLinkResolver = (image: ICSCorImage): CorContentstackImageAsset | undefined => {
  const { image: contentstackImage, brand_folder_image } = image;
  const hasBrandFolderImage = brand_folder_image && Array.isArray(brand_folder_image);
  const hasContentStackImage = contentstackImage?.url;

  if (!hasContentStackImage && !hasBrandFolderImage) {
    return undefined;
  }

  if (hasBrandFolderImage && brand_folder_image?.[0]?.cdn_url) {
    if (isTifImage(brand_folder_image[0])) {
      return {
        ...image,
        url: corContentStackImageTifExtensionHelper(
          brand_folder_image[0].cdn_url,
          `quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}`
        ),
      };
    }

    return {
      ...image,
      url: `${brand_folder_image[0].cdn_url}?format=${IMAGES_FORMAT}&quality=${IMAGES_COMPRESSION_LEVEL}`,
    };
  }

  if (hasContentStackImage) {
    return {
      ...image,
      url: `${contentstackImage.url}?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}`,
    };
  }

  return undefined;
};
