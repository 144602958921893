import { FC, HTMLAttributes } from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { isString } from 'lodash-es';
import { isExternalUrl } from 'utils/is-external-url';

export interface ICustomLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  url: string | undefined;
  onClickHandler?: () => void;
}

export const CustomLink: FC<ICustomLinkProps> = (props) => {
  const { url, children, onClickHandler, ...restProps } = props;
  const href = isString(url) ? url : '';
  const isExternalLink: boolean = isExternalUrl(href);

  return isExternalLink ? (
    <a href={href} target="_blank" rel="noopener noreferrer" {...restProps}>
      {children}
    </a>
  ) : (
    <Link to={href as LocationDescriptor} {...restProps} onClick={() => onClickHandler && onClickHandler()}>
      {children}
    </Link>
  );
};
