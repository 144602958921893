import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { addNewBankAccount, addNewBankAccountActionConstants } from 'store/payment-methods/actions';

export interface IAddNewBankAccountPayload {
  firstName: string;
  companyName?: string;
  lastName: string;
  pfToken: string;
  accountNumberLastFiveDigits: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: (messageId: string) => void;
}

export function* addNewBankAccountOnSuccessSagaHandler({ payload }: IAction<IAddNewBankAccountPayload>) {
  yield take(addNewBankAccountActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* addNewBankAccountOnFailSagaHandler({ payload }: IAction<IAddNewBankAccountPayload>) {
  const { error } = yield take(addNewBankAccountActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack && error) {
    payload.onFailCallBack(error.errorMessageId);
  }
}

export function* addNewBankAccountSagaHandler({ payload }: IAction<IAddNewBankAccountPayload>) {
  yield put(doRequest<IAddNewBankAccountPayload>(addNewBankAccount, Endpoints.BANK_ACCOUNTS, 'post', payload));
}

export default function* addNewBankAccountSaga() {
  yield takeLatest(addNewBankAccountActionConstants[Actions.REQUEST], addNewBankAccountOnSuccessSagaHandler);
  yield takeLatest(addNewBankAccountActionConstants[Actions.REQUEST], addNewBankAccountOnFailSagaHandler);
  yield takeLatest(addNewBankAccountActionConstants[Actions.REQUEST], addNewBankAccountSagaHandler);
}
