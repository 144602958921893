import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from 'utils/check-permissions';
import { selectUserRole } from 'store/auth/selectors';

interface IPermissionAccessProps extends PropsWithChildren {
  requiredPermissions: string[];
}

export const PermissionAccess: FC<IPermissionAccessProps> = ({ requiredPermissions, children }) => {
  const userRoles = useSelector(selectUserRole);

  return <>{checkPermissions(userRoles, requiredPermissions) ? children : null}</>;
};
