import React, { FC } from 'react';
import './icons-menu.scss';
import { CorContentstackLink } from 'corporate-components';
import { IMenuItemProps } from 'components/layout/cor-header/cor-header.interface';
import { isExternalUrl } from 'utils/is-external-url';
import { Routes } from 'constants/routes.enum';
import { HeaderIcon } from 'components/layout/cor-header/components/header-icon';

export interface IIconsMenuProps {
  items: IMenuItemProps[];
  handleNotificationsPanelClick: () => void;
  closeHamburgerMenuHandler?: (url: string) => void;
}

export const IconsMenu: FC<IIconsMenuProps> = ({ items, handleNotificationsPanelClick, closeHamburgerMenuHandler }) => {
  return (
    <div className={'icons-menu'}>
      <ul className={'icons-menu__list'}>
        {items &&
          items?.length > 0 &&
          items.map((item: IMenuItemProps, index: number) => {
            const isNotificationIcon = item.url === Routes.NewsAndNotificationsPage;
            if (isNotificationIcon) {
              return (
                <li
                  key={item?.url || 'icons-menu' + index}
                  className={'icons-menu__item'}
                  onClick={handleNotificationsPanelClick}
                >
                  <HeaderIcon
                    iconContentKey="image"
                    data={item.image}
                    counter={item.counter}
                    customClass="notification-counter icons-menu__icon"
                  />
                </li>
              );
            }
            return (
              <li key={item?.url || 'icons-menu' + index} className={'icons-menu__item'}>
                <CorContentstackLink
                  data-testid={'icon-menu-link'}
                  contentKey={''}
                  data={{ href: item.url, title: item.text || '' }}
                  aria-label={item.text || item.altText || ''}
                  opensInNewTab={item.url ? isExternalUrl(item.url) : false}
                  onClick={() => closeHamburgerMenuHandler && item?.url && closeHamburgerMenuHandler(item.url)}
                >
                  {item?.imgUrl ? (
                    <img className={'icons-menu__icon'} src={item.imgUrl} alt={item.altText || ''} />
                  ) : (
                    item.text
                  )}
                </CorContentstackLink>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
