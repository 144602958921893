import { FC, ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { OrderSummary } from 'features/order-summary';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { formatCurrency } from 'utils/currency-format';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { LoadingIndicator } from 'components/loading-indicator';
import { PageLeaveConfirmationModal } from 'components/page-leave-confirmation-modal';
import { PO_NAME_CHARACTER_LIMIT } from 'constants/purchase-order-name';
import { acceptQuote } from 'store/quotes/actions';
import { selectQuoteOrderSummary } from 'store/quotes/selectors';
import { IAcceptQuotePayload } from 'store/quotes/sagas/accept-quote';
import formatOrderSummary from 'utils/format-order-summary';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './quote-order-summary-modal.scss';

export interface IQuoteOrderSummaryModalProps extends IModalProps {
  quoteNumber: string;
  onQuoteOrderSubmit: (poNumber?: string) => void;
}

export const QuoteOrderSummaryModal: FC<IQuoteOrderSummaryModalProps> = ({
  isOpen,
  onClose,
  quoteNumber,
  onQuoteOrderSubmit,
}) => {
  const contentstackPath = 'modals.0.quote_order_summary_modal.0';
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();
  const { isDesktop } = useBreakpoint();
  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');
  const [poNumber, setPoNumber] = useState('');
  const handlePoNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const poNumberValue = event.target.value;
    const poNumberValueValid = poNumberValue.replace(/[\^|]/g, '');
    setPoNumber(poNumberValueValid);
  };
  const { orderDetails, loading } = useSelector(selectQuoteOrderSummary);

  useEffect(() => {
    dispatch(
      acceptQuote.request<IAcceptQuotePayload>({
        quoteNumber,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleModalClose = () => {
    const closingIsAllowed = window.confirm(leavingPageModalText);

    if (closingIsAllowed && onClose) {
      onClose();
    }
  };

  return (
    <Modal className="quote-order-summary-modal" isOpen={isOpen} onClose={handleModalClose} withBackdropClick>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ModalHeader titleContentstackPath={`${contentstackPath}.heading`} />
          <div className="quote-order-summary-modal__content-wrapper">
            <div className="quote-order-summary-modal__order-summary">
              {orderDetails && (
                <OrderSummary
                  contentstackRootPath={`${contentstackPath}.order_summary[0]`}
                  {...formatOrderSummary({
                    isSummaryAvailable: !orderDetails.offline,
                    priceSummary: orderDetails.priceSummary,
                    summaryNotAvailableStatus: getContentByKey(
                      `${contentstackPath}.order_summary[0].price_estimated`,
                      ''
                    ),
                  })}
                />
              )}
            </div>
            <div className="quote-order-summary-modal__submit-section">
              <div className="quote-order-summary-modal__po-number">
                <label className="quote-order-summary-modal__po-number-label">
                  <ContentstackText contentKey={`${contentstackPath}.order_summary[0].po_number`} />
                </label>
                <input
                  aria-label={getContentByKey<string>(`${contentstackPath}.order_summary[0].po_number`, '')}
                  className="quote-order-summary-modal__po-number-input"
                  placeholder={getContentByKey<string>(
                    `${contentstackPath}.order_summary[0].po_number_field_placeholder`,
                    ''
                  )}
                  type="text"
                  maxLength={PO_NAME_CHARACTER_LIMIT}
                  value={poNumber}
                  onChange={handlePoNumberChange}
                />
                {!isDesktop && (
                  <div className="quote-order-summary-modal__final-sale-warning">
                    <ContentstackText contentKey={`${contentstackPath}.final_sale_warning`} />
                  </div>
                )}
              </div>
              {isDesktop && (
                <>
                  <CorButton onClick={() => onQuoteOrderSubmit(poNumber)}>
                    <ContentstackText contentKey={`${contentstackPath}.submit_order_button_label`} />
                  </CorButton>
                  <div className="quote-order-summary-modal__final-sale-warning">
                    <ContentstackText contentKey={`${contentstackPath}.final_sale_warning`} />
                  </div>
                </>
              )}
            </div>
          </div>
          {!isDesktop && (
            <div className="quote-order-summary-modal__footer-wrapper">
              <ModalFooter className="quote-order-summary-modal__footer">
                <div className="quote-order-summary-modal__footer-total">
                  {orderDetails.offline ? (
                    <ContentstackText contentKey={`${contentstackPath}.tbd_label`} />
                  ) : (
                    formatCurrency(orderDetails.priceSummary.total)
                  )}
                </div>
                <CorButton onClick={() => onQuoteOrderSubmit(poNumber)}>
                  <ContentstackText contentKey={`${contentstackPath}.submit_order_button_label`} />
                </CorButton>
              </ModalFooter>
            </div>
          )}
        </>
      )}
      <PageLeaveConfirmationModal />
    </Modal>
  );
};
