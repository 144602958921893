import { Roles } from './roles.enum';
import { AccessDefinitions } from './access-definitions.enum';

export const Permissions = {
  // functionality access

  [AccessDefinitions.SHOPPING_CART_ACTIONS]: [
    Roles.BLOCKED,
    Roles.CSR,
    Roles.MSR,
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.USER,
    Roles.MSM,
    Roles.HOUSE_ACCOUNT,
    Roles.PICKUP_ACCOUNT,
    Roles.KEY_ACCOUNT,
    Roles.ORDER_GUIDE,
  ],

  // page access
  [AccessDefinitions.SHOPPING_CART_PAGE_ACCESS]: [
    Roles.BLOCKED,
    Roles.CSR,
    Roles.MSR,
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.USER,
    Roles.MSM,
    Roles.HOUSE_ACCOUNT,
    Roles.PICKUP_ACCOUNT,
    Roles.KEY_ACCOUNT,
    Roles.ORDER_GUIDE,
  ],
  [AccessDefinitions.DASHBOARD_QUOTES_PAGE_ACCESS]: [
    Roles.MSR,
    Roles.MSM,
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.USER,
    Roles.PICKUP_ACCOUNT,
    Roles.KEY_ACCOUNT,
    Roles.ORDER_GUIDE,
  ],
  [AccessDefinitions.SPECIAL_ORDERS_PAGE_ACCESS]: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.USER,
    Roles.BLOCKED,
    Roles.GUEST,
    Roles.MSR,
    Roles.MSM,
    Roles.CSR,
    Roles.TSR,
    Roles.PICKUP_ACCOUNT,
    Roles.KEY_ACCOUNT,
    Roles.ORDER_GUIDE,
  ],
};
