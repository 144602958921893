import { FC } from 'react';
import ReactPaginate from 'react-paginate';
import { ContentstackText } from 'components/contentstack';
import { useBreakpoint } from 'hooks/use-breakpoint';

import './pagination.scss';

export interface IPaginationProps {
  currentPage: number;
  pagesTotal: number;
  contentstackBasePath?: string;
  onPageChange: (selectedItem: { selected: number }) => void;
  disableInitialCallback?: boolean;
  pageRangeToDisplay?: { desktop: number; mobile: number };
}

export const Pagination: FC<IPaginationProps> = ({
  currentPage,
  pagesTotal,
  onPageChange,
  contentstackBasePath,
  disableInitialCallback = false,
  pageRangeToDisplay = { desktop: 3, mobile: 1 },
}) => {
  const { isDesktop } = useBreakpoint();
  const pageRangeDisplayed = isDesktop ? pageRangeToDisplay.desktop : pageRangeToDisplay.mobile;

  const contentstackPath = contentstackBasePath ? contentstackBasePath : 'page_content';
  return (
    <ReactPaginate
      initialPage={currentPage}
      forcePage={currentPage}
      pageCount={pagesTotal}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={1}
      previousLabel={
        <>
          <div className="pagination-item__icon pagination-item__arrow-left" />
          <span>
            <ContentstackText contentKey={`${contentstackPath}.pagination.previous_button_label`} />
          </span>
        </>
      }
      nextLabel={
        <>
          <span>
            <ContentstackText contentKey={`${contentstackPath}.pagination.next_button_label`} />
          </span>
          <div className="pagination-item__icon pagination-item__arrow-right" />
        </>
      }
      containerClassName="pagination"
      pageClassName="pagination-item pagination-item--page"
      pageLinkClassName="pagination-link"
      previousLinkClassName="pagination-link"
      nextLinkClassName="pagination-link"
      previousClassName="pagination-item pagination-item--prev"
      nextClassName="pagination-item pagination-item--next"
      breakClassName="pagination-item pagination-item--etc"
      activeClassName="pagination-item--active"
      disabledClassName="pagination-item--disabled"
      onPageChange={onPageChange}
      disableInitialCallback={disableInitialCallback}
    />
  );
};
