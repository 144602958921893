import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getCarouselProductsActionConstants, getCarouselProducts } from 'store/products/actions';
import { IAction } from 'types/actions';

export interface IGetCarouselProductsPayload {
  skus: string;
}

export function* getCarouselProductsHandler({ payload: { skus } }: IAction<IGetCarouselProductsPayload>) {
  const url = `${Endpoints.CAROUSEL_PRODUCTS}/${skus}`;
  yield put(doRequest(getCarouselProducts, url, 'get'));
}

export default function* getCarouselProductsSaga() {
  yield takeLatest(getCarouselProductsActionConstants[Actions.REQUEST], getCarouselProductsHandler);
}
