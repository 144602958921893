import { createReducer } from 'store/reducer-creator';
import { getSiteKeyActionConstants } from 'store/captcha/actions';
import { Actions } from 'constants/actions.enum';

export interface ICaptchaState {
  siteKey: string;
  loading: boolean;
  failed: boolean;
}

export const captchaInitialState: ICaptchaState = {
  siteKey: '',
  loading: false,
  failed: false,
};

const captcha = createReducer<ICaptchaState>(
  {
    [getSiteKeyActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [getSiteKeyActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      siteKey: payload,
      loading: false,
      failed: false,
    }),
    [getSiteKeyActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
      failed: true,
    }),
  },
  captchaInitialState
);

export default captcha;
