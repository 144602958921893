import { useEffect, useState } from 'react';

export const useImageLoading = (imageSrc: string | undefined) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageLoadErrorOccurred, setImageLoadErrorOccurred] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
    setImageLoadErrorOccurred(false);

    if (imageSrc) {
      const image = new Image();
      image.onload = () => setImageLoaded(true);
      image.onerror = () => {
        setImageLoaded(true);
        setImageLoadErrorOccurred(true);
      };
      image.src = imageSrc;
    }
  }, [imageSrc]);

  return {
    imageLoaded,
    imageLoadErrorOccurred,
  };
};
