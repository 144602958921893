import { RefObject, useEffect, useCallback } from 'react';

export const useOutsideClick = (
  innerRef: RefObject<HTMLDivElement>,
  visible: boolean,
  onOuterClick?: (e?: MouseEvent | TouchEvent) => void,
  withBackdropClick?: boolean,
  excludeFromOutsideClickContentRef?: RefObject<any>
) => {
  const handleClick = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (onOuterClick && innerRef.current && !innerRef.current.contains(e.target as Node)) {
        excludeFromOutsideClickContentRef?.current
          ? !excludeFromOutsideClickContentRef.current.contains(e.target) && onOuterClick(e)
          : onOuterClick(e);
      }
    },
    [innerRef, excludeFromOutsideClickContentRef, onOuterClick]
  );

  useEffect(() => {
    if (innerRef.current && visible && withBackdropClick) {
      document.addEventListener('mousedown', handleClick, true);
      document.addEventListener('touchstart', handleClick, true);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick, true);
      document.removeEventListener('touchstart', handleClick, true);
    };
  }, [onOuterClick, innerRef, visible, handleClick, withBackdropClick]);
};
