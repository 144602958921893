import './cor-typography.scss';

import React from 'react';
import classNames from 'classnames';
import { isObject, isString } from 'lodash-es';
import { ICorTypography } from './cor-typography.interface';

const CorTypography = ({
  color = 'dark-gray',
  variant = 'body-regular',
  children,
  component = 'p',
  ...props
}: React.PropsWithChildren<ICorTypography & React.HTMLProps<HTMLElement>>) => {
  const className = isString(props.className)
    ? props.className
    : isObject(props.className)
    ? { ...(props.className as Object) }
    : '';

  return React.createElement(
    component,
    {
      ...props,
      className: classNames('cor-typography', `cor-typography__${variant}`, `cor-text-color__${color}`, className),
    },
    children
  );
};

export default React.memo(CorTypography);
