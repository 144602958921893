import React from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useContent } from 'hooks/use-content';
import {
  CorBasicValidator,
  CorEmailValidator,
  CorIsCheckedValidator,
  CorPhoneValidator,
  createValidationSchema,
  CorConfirmValidator,
} from 'utils/validation';
import { CorFormControlEnum } from './cor-form.constants';

interface IUseCorForm {
  formControls: any[];
  errorMessageKey?: string;
  formURL: string;
  openModal(): void;
}

const useCorForm = ({
  formControls = [],
  errorMessageKey = 'form.form.0.error_messages',
  formURL,
  openModal,
}: IUseCorForm) => {
  const { getMessageText } = useContent();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const CorFormControlsValidatorMap = React.useMemo(
    () =>
      new Map<CorFormControlEnum, any>([
        [CorFormControlEnum.SingleLineEntry, CorBasicValidator],
        [CorFormControlEnum.DropdownList, CorBasicValidator],
        [CorFormControlEnum.MultiLineEntryField, CorBasicValidator],
        [
          CorFormControlEnum.EmailAddressEntryField,
          CorEmailValidator({ wrongFormat: getMessageText(errorMessageKey, 'CORMSG301') }),
        ],
        [
          CorFormControlEnum.PhoneNumberEntryField,
          CorPhoneValidator({ wrongFormat: getMessageText(errorMessageKey, 'CORMSG302') }),
        ],
        [CorFormControlEnum.Checkbox, CorIsCheckedValidator],
        [CorFormControlEnum.ConfirmField, CorConfirmValidator],
        [CorFormControlEnum.LATAM_Dropdown, CorBasicValidator],
      ]),
    [errorMessageKey, getMessageText]
  );

  const VALIDATION_SCHEMA = React.useMemo(() => {
    const validators = formControls?.reduce((acc, { form_field, dynamic_field }) => {
      const validator = CorFormControlsValidatorMap.get(form_field?.field_type) || CorBasicValidator;

      if (dynamic_field) return { ...acc, [form_field?.field_name]: undefined };

      if ([CorFormControlEnum.ConfirmField].includes(form_field?.field_type)) {
        return {
          ...acc,
          [form_field?.field_name]: !!form_field?.confirmed_field_name
            ? validator({
                field_name: form_field?.confirmed_field_name,
                wrongFormat: getMessageText(errorMessageKey, 'CORMSG304'),
              }).required(getMessageText(errorMessageKey, 'CORMSG300'))
            : CorBasicValidator,
        };
      }

      if (!form_field?.mandatory_field) return { ...acc, [form_field?.field_name]: validator };

      if ([CorFormControlEnum.Checkbox].includes(form_field?.field_type))
        return {
          ...acc,
          [form_field?.field_name]: validator.oneOf([true], getMessageText(errorMessageKey, 'CORMSG300')),
        };

      return {
        ...acc,
        [form_field?.field_name]: validator.required(getMessageText(errorMessageKey, 'CORMSG300')),
      };
    }, {});

    return createValidationSchema(validators);
  }, [CorFormControlsValidatorMap, errorMessageKey, formControls, getMessageText]);

  const INITIAL_VALUES = React.useMemo(
    () =>
      formControls?.reduce(
        (acc, { form_field }) => ({
          ...acc,
          [form_field?.field_name]: [CorFormControlEnum.Checkbox].includes(form_field?.field_type) ? false : '',
        }),
        {}
      ),
    [formControls]
  );

  const handleSubmit = React.useCallback(
    async (formData, requestConfig) => {
      const requestConfigToSend = !isEmpty(requestConfig) ? requestConfig : undefined;
      setIsLoading(true);
      setIsSuccess(false);

      try {
        await axios.post(formURL, formData, requestConfigToSend);
        setIsSuccess(true);
      } catch (error) {
        setIsSuccess(false);
      }

      setIsLoading(false);
      openModal();
    },
    [formURL, openModal]
  );

  return { handleSubmit, VALIDATION_SCHEMA, INITIAL_VALUES, formState: { isLoading, isSuccess } };
};

export default useCorForm;
