import { SHOP_LANGUAGES } from 'constants/shop-locales';
import { formatDateLocalized, getFormattedDeliveryDate } from 'utils/date-format';
import { formatCurrency, formatCurrencyLocalized, relocateCurrencyMinusSign } from 'utils/currency-format';
import { currencySymbols } from 'constants/currency-symbols';
import { useLocalStorage } from './use-local-storage';
import { useContent } from './use-content';

export const usePaymentFormatters = () => {
  const [locale] = useLocalStorage('locale', null);
  const { getContentByKey } = useContent();

  const formatDate = (date: string) => {
    return locale === SHOP_LANGUAGES.FRENCH_CANADA
      ? formatDateLocalized(date, getContentByKey('common[0].months_short[0]', {}), locale)
      : getFormattedDeliveryDate(date, true);
  };

  const formatLineItemCurrency = (currency: string, balance: number) => {
    return locale === SHOP_LANGUAGES.FRENCH_CANADA
      ? formatCurrencyLocalized({ currencySymbol: currencySymbols[currency], amount: String(balance) })
      : relocateCurrencyMinusSign(
          formatCurrency({
            currencySymbol: currencySymbols[currency],
            amount: String(balance),
          })
        );
  };

  const formatCurrencyTotals = (amount: string) => {
    return locale === SHOP_LANGUAGES.FRENCH_CANADA
      ? formatCurrencyLocalized({ currencySymbol: currencySymbols.CAD, amount: String(amount) })
      : relocateCurrencyMinusSign(
          formatCurrency({
            currencySymbol: currencySymbols.USD,
            amount: String(amount),
          })
        );
  };

  return {
    formatDate,
    formatLineItemCurrency,
    formatCurrencyTotals,
  };
};
