export enum Roles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
  BLOCKED = 'BLOCKED',
  GUEST = 'GUEST',
  MSR = 'MSR',
  CSR = 'CSR',
  MSM = 'MSM',
  TSR = 'TSR',
  HOUSE_ACCOUNT = 'HOUSE_ACCOUNT',
  PICKUP_ACCOUNT = 'PICKUP_ACCOUNT',
  KEY_ACCOUNT = 'KEY_ACCOUNT',
  ORDER_GUIDE = 'ORDER_GUIDE',
  SUPER_ADMIN_INVOICE_ONLY = 'SUPER_ADMIN_INVOICE_ONLY',
}
