import { all, spawn } from 'redux-saga/effects';
import resendVerificationSaga from './resend-verification';
import submitAccountRetrievalSaga from './submit-account-retrieval';
import validationWithCustomerNumberAndZipCodeSaga from './validation-with-customer-number-and-zip-code';
import submitPersonalInformationSaga from './submit-personal-information';
import verifyEmailByTokenSaga from './verify-email-by-token';
import resendEmailToVerifyAccountSaga from './resend-email-to-verify-account';
import registerAdditionalUserSaga from './register-additional-user';

export default function* customerRegistrationSagas() {
  yield all([
    spawn(submitAccountRetrievalSaga),
    spawn(validationWithCustomerNumberAndZipCodeSaga),
    spawn(submitPersonalInformationSaga),
    spawn(verifyEmailByTokenSaga),
    spawn(resendEmailToVerifyAccountSaga),
    spawn(registerAdditionalUserSaga),
    spawn(resendVerificationSaga),
  ]);
}
