import { FC } from 'react';
import { ReactComponent as IconPhone } from 'assets/images/icons/icon-phone.svg';
import './cor-distribution-center.scss';

interface IZipCodes {
  service_zipcode: string[];
}

interface IDistributionCenter {
  title: string;
  street: string;
  city: string;
  contact_email: string;
  distribution_center_url: string;
  locale: string;
  state: string;
  zipcode: string;
  main_phone: string;
  country: string;
  location_type: string;
  secondary_phone: string;
  service_zipcodes: IZipCodes[];
}

export interface ICorDistributionCenterProps {
  data: IDistributionCenter;
}

export const CorDistributionCenter: FC<ICorDistributionCenterProps> = ({ ...props }) => {
  const distributionCenter = props?.data;
  const sanitizedPhoneNumber = distributionCenter?.main_phone?.replace(/[^0-9]/g, '');
  return (
    <div className="cor-distribution-center">
      <div className="cor-distribution-center__title cor-text-color__dark-gray">{distributionCenter.title}</div>
      <div className="cor-distribution-center__address cor-text-color__mid-gray">
        <div>{distributionCenter.street}</div>
        <div>
          {distributionCenter.city}, {distributionCenter.state?.split(':').pop()}, {distributionCenter.zipcode}
        </div>
      </div>
      <div className="cor-distribution-center__contact-info cor-text-color__mid-gray">
        <a href={`tel:${sanitizedPhoneNumber}`}>
          <IconPhone className="cor-distribution-center__icon" /> {distributionCenter.main_phone}
        </a>
      </div>
    </div>
  );
};
