export const enum SHOP_LANGUAGES {
  ENGLISH_UNITED_STATES = 'en-us',
  ENGLISH_CANADA = 'en-ca',
  FRENCH_CANADA = 'fr-ca',
}

export const allowedShopLocales = [SHOP_LANGUAGES.FRENCH_CANADA, SHOP_LANGUAGES.ENGLISH_CANADA];

export const shopCountriesAllowed = ['ca'];

export const shopLanguagesAllowed = ['fr'];
