import './cor-filter-search-term.scss';

import React from 'react';
import { useAlgoliaQueryParams } from 'hooks';
import { get } from 'lodash-es';
import { PREFIX_KEY } from 'corporate-components/cor-filtered-list/cor-filtered-list-constants';
import iconSearch from 'assets/images/icons/icon-search-red.svg';
import { ALGOLIA_DEFAULT_PAGE_NUMBER } from 'hooks/use-algolia';
import { useContentstackContent } from 'hooks/use-contentstack-content';

const CorFilterSearchTerm = () => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const content = useContentstackContent();
  const searchPlaceholderText = get(content, `${PREFIX_KEY}.search_placeholder`, '');

  const handleFormSubmit = React.useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();

      const formData = new FormData(e.target as HTMLFormElement);
      const query = (formData.get('cor-filtered-list__search-term') as string) || undefined;
      setQueryParams({ query, page: ALGOLIA_DEFAULT_PAGE_NUMBER, facets: undefined });
    },
    [setQueryParams]
  );

  return (
    <form className="cor-filter-search-term" onSubmit={handleFormSubmit}>
      <fieldset>
        <input
          type="text"
          className="cor-filter-search-term__input"
          placeholder={searchPlaceholderText}
          name="cor-filtered-list__search-term"
          autoComplete="off"
          defaultValue={queryParams?.query || ''}
        />
        <button type="submit" className="cor-filter-search-term__search-button">
          <img src={iconSearch} alt="search button icon" className="cor-filter-search-term__search-icon" />
        </button>
      </fieldset>
    </form>
  );
};

export default React.memo(CorFilterSearchTerm);
