import { all, spawn } from 'redux-saga/effects';
import searchProductsSaga from './search-products';
import getSearchSuggestionsSaga from './get-search-suggestions';
import getSearchTemporaryFiltersInfoSaga from './get-search-temporary-filters-info';
import updateSearchTemporaryFiltersSaga from './update-search-temporary-filters';

export default function* searchProductsSagas() {
  yield all([
    spawn(searchProductsSaga),
    spawn(getSearchSuggestionsSaga),
    spawn(getSearchTemporaryFiltersInfoSaga),
    spawn(updateSearchTemporaryFiltersSaga),
  ]);
}
