import { FC } from 'react';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackText, ContentstackMessage } from 'components/contentstack';
import { CorButton } from 'components/cor-button';
import iconAdd from 'assets/images/icons/icon-add.svg';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import {
  IOrderedProductImage,
  OrderedProductImage,
} from 'pages/account/components/order-history/components/ordered-product-image';

import './proprietary-item-modal.scss';

export interface IProprietaryItemModalProps extends IModalProps {
  isOpen: boolean;
  onClose: () => void;
  skuName: string;
  itemNumber: string;
  lineItemImageProps: IOrderedProductImage;
}

export const ProprietaryItemModal: FC<IProprietaryItemModalProps> = ({
  isOpen,
  onClose,
  skuName,
  itemNumber,
  lineItemImageProps,
}) => {
  const contentstackPath = 'modals.0.proprietary_item_modal.0';
  const productImageSizes = {
    desktopSize: {
      height: 88,
      width: 88,
    },
    mobileSize: {
      height: 76,
      width: 76,
    },
  };

  return (
    <Modal isOpen={isOpen} className="proprietary-item-modal" onClose={onClose} withBackdropClick>
      <ModalHeader>
        <div className="proprietary-item-modal__title">
          <img src={iconAdd} alt="icon add" />
          <ContentstackText contentKey={`${contentstackPath}.heading`} />
        </div>
      </ModalHeader>
      <div className="proprietary-item-modal__content">
        <p className="proprietary-item-modal__message">
          <ContentstackMessage type="notifications" messageId="MSG051a" />
        </p>
        <div className="proprietary-item-modal__product">
          <OrderedProductImage {...{ ...lineItemImageProps, ...productImageSizes }} />
          <div>
            <div className="proprietary-item-modal__product-title">{skuName}</div>
            <div className="proprietary-item-modal__product-sku">
              <ContentstackMessage type="notifications" messageId="MSG051b" /> {itemNumber}
            </div>
          </div>
        </div>
      </div>
      <ModalFooter className="proprietary-item-modal__footer">
        <CorButton color="secondary" onClick={onClose} className="proprietary-item-modal__close-btn">
          <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
