import { FC, PropsWithChildren } from 'react';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { RenderIfEveryNonEmpty } from 'components/conditional-render';

interface IRenderIfEveryNonEmptyContentProps extends PropsWithChildren {
  keys: string[];
}

export const RenderIfEveryNonEmptyContent: FC<IRenderIfEveryNonEmptyContentProps> = ({ keys, children }) => {
  const contentstackContent = useContentstackContent();

  return (
    contentstackContent && (
      <RenderIfEveryNonEmpty data={contentstackContent} keys={keys}>
        {children}
      </RenderIfEveryNonEmpty>
    )
  );
};
