import './cor-filter-dropdown-option.scss';

import React from 'react';
import { CustomCheckbox } from 'components/custom-checkbox';

const CorFilterDropdownOption = ({ label = '', onCheckboxClick = () => {}, isSelected = false }) => {
  return (
    <CustomCheckbox
      selected={isSelected}
      onCheckboxClick={onCheckboxClick}
      label={label}
      className="cor-filter-dropdown-option"
    />
  );
};

export default React.memo(CorFilterDropdownOption);
