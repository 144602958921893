import { FC, PropsWithChildren } from 'react';
import { CorFooter } from 'corporate-components';
import { PageLayout } from 'components/layout';
import { CheckoutHeader } from 'components/layout/checkout-header';

interface ICheckoutPageLayoutProps extends PropsWithChildren {}

export const CheckoutPageLayout: FC<ICheckoutPageLayoutProps> = ({ children }) => (
  <PageLayout classModifier="default" header={CheckoutHeader} footer={CorFooter}>
    {children}
  </PageLayout>
);
