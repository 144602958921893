import './news.page.scss';

import { FC, PropsWithChildren } from 'react';
import { CorDetailHeroBanner, CorDetailRelatedContent } from 'corporate-components';
import { ICSCorImage } from 'corporate-interfaces';
import { useContent } from 'hooks/use-content';
import { CorFeaturedContentContentTypeEnum } from 'constants/cor-featured-content.enum';

export const NewsPage: FC<PropsWithChildren> = ({ children }) => {
  const { getContentByKey } = useContent();
  return (
    <>
      <CorDetailHeroBanner
        heading={getContentByKey('title', '')}
        tags={getContentByKey('superheading', '')}
        description={getContentByKey('short_description', '')}
        image={getContentByKey('banner_image', {} as ICSCorImage)}
        dateAuthor={getContentByKey('date_and_author', '')}
        iconTitleGroup={getContentByKey('icon_title_group', [])}
      />
      {children || null}

      <CorDetailRelatedContent
        baseKey="related_news_section"
        relatedContentKey="related_news_section.related_news"
        contentType={CorFeaturedContentContentTypeEnum.News}
        className="cor-detail-related-content__news"
        titleExclusion={getContentByKey('title', '')}
      />
    </>
  );
};
