import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { sendVerificationEmail, sendVerificationEmailActionConstants } from 'store/reset-password/actions';
import { IAction } from 'types/actions';

export interface ISendVerificationEmailPayload {
  email: string;
}

export function* sendVerificationEmailSagaHandler({ payload }: IAction<ISendVerificationEmailPayload>) {
  yield put(doRequest(sendVerificationEmail, `${Endpoints.PASSWORDS}/${payload.email}`, 'post'));
}

export default function* sendVerificationEmailSaga() {
  yield takeLatest(sendVerificationEmailActionConstants[Actions.REQUEST], sendVerificationEmailSagaHandler);
}
