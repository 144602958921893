import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getProductRecipes, getProductRecipesActionConstants } from '../actions';

const MAXIMUM_RECIPES_TO_SHOW = 15;

export interface IGetProductRecipesPayload {
  text?: string;
}

export function* getProductRecipesSagaHandler({ payload: { text } }: IAction<IGetProductRecipesPayload>) {
  yield put(
    doRequest(getProductRecipes, Endpoints.GET_PRODUCTS, 'post', {
      facets: [],
      categoryKeys: [],
      limit: MAXIMUM_RECIPES_TO_SHOW,
      offset: 0,
      sortOptions: {},
      text,
    })
  );
}

export default function* getProductsSaga() {
  yield takeLatest(getProductRecipesActionConstants[Actions.REQUEST], getProductRecipesSagaHandler);
}
