import { CorHero } from 'corporate-components';

export const ASpotBanner = () => {
  const PATH_TO_CONTENT = 'page_content.a_spot_banner[0]';

  return (
    <div className="a-spot-banner">
      <CorHero contantStackPath={PATH_TO_CONTENT} />
    </div>
  );
};
