import { IAlgoliaResponseMeta } from 'queries/use-algolia-query';

const ALGOLIA_PAGINATION_ADJUSTMENT = 1;

export const parseAlgoliaPagination = (data?: IAlgoliaResponseMeta) => {
  const from =
    ((data?.page?.current || 0) - ALGOLIA_PAGINATION_ADJUSTMENT) * (data?.page?.size || 0) +
    ALGOLIA_PAGINATION_ADJUSTMENT;
  const to =
    from + (data?.page?.size || 0) > (data?.page?.total_results || 0)
      ? data?.page?.total_results || 0
      : from - ALGOLIA_PAGINATION_ADJUSTMENT + (data?.page?.size || 0);

  return { from, to, total: data?.page?.total_results || 0 };
};
