import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { changeCartItemQuantityActionConstants, changeCartItemQuantity } from 'store/shopping-cart/actions';
import { IAction } from 'types/actions';

export interface IChangeItemQuantityPayload {
  quantity: number;
  sku: string;
}

export function* changeItemQuantitySagaHandler({ payload }: IAction<IChangeItemQuantityPayload>) {
  yield put(doRequest<IChangeItemQuantityPayload>(changeCartItemQuantity, Endpoints.CART, 'put', payload));
}

export default function* changeItemQuantitySaga() {
  yield takeLatest(changeCartItemQuantityActionConstants[Actions.REQUEST], changeItemQuantitySagaHandler);
}
