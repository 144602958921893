import { FC, useState } from 'react';
import classNames from 'classnames';
import { IUserPersonalInformation } from 'types/user-info';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { IOptionType } from 'components/custom-dropdown';
import { ContentstackText } from 'components/contentstack';
import { AccountStatusBadge } from 'pages/dashboard/components/customer-locations-item/components/account-status-badge';

import './select-location-user-modal.scss';

export interface ISelectLocationUserModalProps extends IModalProps {
  groupedUsersAtLocation: { label: string; options: IOptionType[] }[];
  defaultUser: IUserPersonalInformation;
  onUserSelect: (user: IUserPersonalInformation) => void;
}

export const SelectLocationUserModal: FC<ISelectLocationUserModalProps> = ({
  groupedUsersAtLocation,
  defaultUser,
  onUserSelect,
  ...props
}) => {
  const contentstackPath = 'modals.0.select_location_user_modal.0';
  const [activeUser, setActiveUser] = useState(defaultUser);

  return (
    <Modal className="select-location-user-modal" withBackdropClick {...props}>
      <h4 className="select-location-user-modal__heading">
        <ContentstackText contentKey={`${contentstackPath}.heading`} />
      </h4>
      <div className="select-location-user-modal__content">
        {groupedUsersAtLocation
          .filter((group) => group.options.length)
          .map((group) => (
            <div key={group.label} className="select-location-user-modal__group">
              <div className="select-location-user-modal__group-label">{`${group.label} (${group.options.length})`}</div>
              <ul>
                {group.options.map((userOption) => {
                  const userPersonalInformation = (userOption as unknown) as IUserPersonalInformation;

                  return (
                    <li
                      key={userOption.value}
                      className={classNames('select-location-user-modal__option-label', {
                        active: activeUser.id === userOption.value,
                      })}
                      onClick={() => setActiveUser(userPersonalInformation)}
                    >
                      {userOption.label}
                      <AccountStatusBadge accountStatus={userPersonalInformation.accountStatus} />
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
      </div>
      <CorButton className="select-location-user-modal__done-button" onClick={() => onUserSelect(activeUser)}>
        <ContentstackText contentKey={`${contentstackPath}.done_button_label`} />
      </CorButton>
    </Modal>
  );
};
