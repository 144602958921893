import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { setCartItemQuantityActionConstants, setCartItemQuantity } from 'store/shopping-cart/actions';
import { IAction } from 'types/actions';

export interface ISetItemQuantityPayload {
  quantity: number;
  sku: string;
}

export function* setItemQuantitySagaHandler({ payload }: IAction<ISetItemQuantityPayload>) {
  yield put(doRequest<ISetItemQuantityPayload>(setCartItemQuantity, Endpoints.CART, 'patch', payload));
}

export default function* setItemQuantitySaga() {
  yield takeLatest(setCartItemQuantityActionConstants[Actions.REQUEST], setItemQuantitySagaHandler);
}
