import { FC, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { template } from 'lodash-es';
import { useContent } from 'hooks/use-content';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useBreakpoint } from 'hooks/use-breakpoint';
import {
  selectIsImpersonateModeOn,
  selectIsInternalUser,
  selectIsInvoiceOnlyCustomer,
  selectUser,
} from 'store/auth/selectors';
import { AccountMenuSelect } from 'components/account-menu';
import config from 'config/app';
import { Endpoints } from 'constants/endpoints.enum';
import { flush as flushAnalyticsSessionVars } from 'utils/analytics/cache-preload';
import { selectCustomerLocations } from 'store/customer-login/selectors';
import { MobileAccountMenuBanner } from 'features/my-account-menu/components/mobile-account-menu-banner';
import IconUser from 'assets/images/icons/icon-user.svg';
import { logout } from 'store/auth/actions';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';

import './my-account-menu.scss';

export interface IMyAccountMenuProps {
  logoutDisabled?: boolean;
  contentstackPath?: string;
}

export interface IUserLocation {
  label: string;
  value: string;
  isDeleted?: boolean;
}

export const MyAccountMenu: FC<IMyAccountMenuProps> = ({ logoutDisabled = false, contentstackPath = 'header[0]' }) => {
  const { isDesktop } = useBreakpoint();
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();
  const [showSegmentationPrice, toggleSegmentationPrice] = useLocalStorage('showSegmentationPrice', false);
  const user = useSelector(selectUser);
  const customerLocations = useSelector(selectCustomerLocations);
  const userLocation = customerLocations.find((item) => item.value === user.locationId);
  const isInternalUser = useSelector(selectIsInternalUser);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const accountMenuOptions = [
    {
      label: getContentByKey<string>(`${contentstackPath}.log_out_title`, ''),
      value: getContentByKey<string>(`${contentstackPath}.log_out_title`, ''),
    },
  ];

  const welcomeMessageTemplate = template(
    getContentByKey<string>(
      `${contentstackPath}.${isInternalUser ? 'welcome_message' : 'welcome_message_invoice_only'}`,
      ''
    )
  );
  const welcomeMessage = welcomeMessageTemplate({ name: user.firstName || user.employeeFirstName });

  const handleLogOutClick = useCallback(() => {
    if (isImpersonateModeOn) {
      return;
    }

    if (showSegmentationPrice) {
      toggleSegmentationPrice(false);
    }

    if (!logoutDisabled && !isInvoiceOnlyUser && !isShopLocaleInternational()) {
      window.location.href = `${config.apiUrl}${Endpoints.LOGOUT}`;
      sessionStorage.setItem('quote-notification-state', 'true');
    }

    if (isInvoiceOnlyUser || isShopLocaleInternational()) {
      dispatch(logout);
    }

    flushAnalyticsSessionVars();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, logoutDisabled, isImpersonateModeOn]);

  const toggleRef = useRef<HTMLButtonElement>(null);
  const [isFullScreenBannerOpened, setFullScreenBannerOpened] = useState(false);

  return (
    <div className="my-account-menu">
      {isDesktop ? (
        <AccountMenuSelect
          accountMenuOptions={accountMenuOptions}
          welcomeMessage={welcomeMessage}
          logoutHandler={handleLogOutClick}
        />
      ) : (
        <>
          <button
            className={classNames('dropdown-button', {
              'deleted-location': userLocation?.isDeleted,
            })}
            ref={toggleRef}
            onClick={() => setFullScreenBannerOpened(!isFullScreenBannerOpened)}
          >
            <img className="dropdown-button__icon" src={IconUser} alt="dropdown-button-icon" />
            <p className="dropdown-button__label">{welcomeMessage}</p>
            <div className="dropdown-button__indicator">
              <i className="dropdown-button__dropdown-icon" />
            </div>
          </button>
          <MobileAccountMenuBanner
            welcomeMessage={welcomeMessage}
            toggleRef={toggleRef}
            isOpen={isFullScreenBannerOpened}
            onClose={() => {
              setFullScreenBannerOpened(false);
            }}
            accountMenuOptions={accountMenuOptions}
            onLogOutClick={handleLogOutClick}
          />
        </>
      )}
    </div>
  );
};
