import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useAddOrRemovePaymentMethodSuccesOrErrorModal } from 'hooks/use-add-or-remove-payment-method-success-or-error-modal';
import { ModalHeader, ModalFooter } from 'components/modals/common/modal/components';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { formatPaymentAccountNumber } from 'utils/format-payment-account-number';
import { selectPaymentMethodLoading } from 'store/payment-methods/selectors';
import { CorButton } from 'components/cor-button';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ContentstackText } from 'components/contentstack';
import { IBankAccounts } from 'store/payment-methods/reducers';
import { getPaymentMethods, removeBankAccount, removeBankAccountEftPad } from 'store/payment-methods/actions';
import { selectIsUserCountryCanada } from 'store/auth/selectors';
import { useLocalStorage } from 'hooks/use-local-storage';
import { BankAccountType, PadBankAccountType } from 'constants/payment-methods.enum';
import { CorContentStackLanguages } from 'constants/cor-locale.enum';
import { useContent } from 'hooks/use-content';
import { LoadingIndicator } from 'components/loading-indicator';

import './remove-payment-bank-account-modal.scss';

export interface IRemovePaymentBankAccountModalProps extends IModalProps {
  isOpen: boolean;
  onClose: () => void;
  bankAccount: IBankAccounts;
}

export const RemovePaymentBankAccountModal: FC<IRemovePaymentBankAccountModalProps> = ({
  isOpen,
  onClose,
  bankAccount,
}) => {
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const contentstackPath = 'modals.0.remove_payment_bank_account_modal.0';
  const { accountType, publicAccountNumber, publicRoutingNumber } = bankAccount;
  const [locale] = useLocalStorage('locale', null);
  const {
    removePaymentMethodSuccessModal,
    removePaymentMethodFailModal,
  } = useAddOrRemovePaymentMethodSuccesOrErrorModal();
  const { getContentByKey } = useContent();

  const [isRemoveBankAccSubmitted, setIsRemoveBankAccSubmitted] = useState(false);
  const isUserCountryCanada = useSelector(selectIsUserCountryCanada);
  const isUserLocaleCanadaFrench = locale === CorContentStackLanguages.FRENCH_CANADA;
  const isPaymentMethodLoading = useSelector(selectPaymentMethodLoading);

  const routingNumberLabel = isUserCountryCanada
    ? `${contentstackPath}.institution_and_transit_number`
    : `${contentstackPath}.${isMobile ? 'mobile_routing_number_label' : 'desktop_routing_number_label'}`;

  const accountGroupClassName = classNames('remove-payment-bank-account-modal__account-group', {
    'remove-payment-bank-account-modal__account-group--is-canada-french': isUserLocaleCanadaFrench,
  });

  const accountInfoContanierClassName = classNames('remove-payment-bank-account-modal__account-info-container', {
    'remove-payment-bank-account-modal__account-info-container--is-canada-french': isUserLocaleCanadaFrench,
  });

  const onRemoveAccountButtonClick = () => {
    if (isRemoveBankAccSubmitted) return;

    if (isUserCountryCanada) {
      dispatch(
        removeBankAccountEftPad.request({
          accountType,
          publicAccountNumber,
          routingNumber: publicRoutingNumber,
          onSuccessCallBack: removePaymentMethodSuccessModal,
          onFailCallBack: removePaymentMethodFailModal,
        })
      );
    } else {
      dispatch(
        removeBankAccount.request({
          accountType,
          publicAccountNumber,
          publicRoutingNumber,
          onSuccessCallBack: removePaymentMethodSuccessModal,
          onFailCallBack: removePaymentMethodFailModal,
        })
      );
    }

    dispatch(getPaymentMethods.request());
    setIsRemoveBankAccSubmitted(true);
  };

  const renderAccountHolderType = (type: string): string =>
    type.includes(PadBankAccountType.PERSONAL) || type.includes(BankAccountType.PERSONAL)
      ? getContentByKey(`${contentstackPath}.personal_account_holder_type`, '')
      : getContentByKey(`${contentstackPath}.business_account_holder_type`, '');

  return (
    <Modal
      size="medium"
      className="remove-payment-bank-account-modal"
      isOpen={isOpen}
      onClose={onClose}
      withBackdropClick={!isPaymentMethodLoading}
      hideCloseButton={isPaymentMethodLoading}
    >
      {isPaymentMethodLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ModalHeader>
            <ContentstackText contentKey={`${contentstackPath}.header`} />
          </ModalHeader>
          <div className="remove-payment-bank-account-modal__content">
            <div className="remove-payment-bank-account-modal__description">
              <ContentstackText contentKey={`${contentstackPath}.description`} />
            </div>
            <div className={accountInfoContanierClassName}>
              <div className={accountGroupClassName}>
                <div className="remove-payment-bank-account-modal__account-group-label">
                  <ContentstackText
                    contentKey={`${contentstackPath}.${
                      isUserLocaleCanadaFrench && isMobile ? 'mobile_' : ''
                    }account_number_label`}
                  />
                </div>
                <div className="remove-payment-bank-account-modal__account-group-info">
                  {formatPaymentAccountNumber(publicAccountNumber)}
                </div>
              </div>
              <div className={accountGroupClassName}>
                <div className="remove-payment-bank-account-modal__account-group-label">
                  <ContentstackText contentKey={routingNumberLabel} />
                </div>
                <div className="remove-payment-bank-account-modal__account-group-info">
                  {formatPaymentAccountNumber(publicRoutingNumber)}
                </div>
              </div>
              <div className={accountGroupClassName}>
                <div className="remove-payment-bank-account-modal__account-group-label">
                  <ContentstackText contentKey={`${contentstackPath}.account_holder_type_label`} />
                </div>
                <div className="remove-payment-bank-account-modal__account-group-info">
                  {renderAccountHolderType(accountType)}
                </div>
              </div>
            </div>
          </div>
          <ModalFooter contentstackPath={contentstackPath} cancelButtonHandler={() => onClose()} hasCancelButton>
            <CorButton
              className="remove-payment-bank-account-modal__remove_button"
              disabled={isRemoveBankAccSubmitted}
              onClick={onRemoveAccountButtonClick}
            >
              <ContentstackText contentKey={`${contentstackPath}.remove_button_label`} />
            </CorButton>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
