import { useCallback, useEffect, useState } from 'react';
import api from 'api';
import qs from 'qs';
import { Endpoints } from 'constants/endpoints.enum';

interface IContentstackParams {
  contentTypeName: string;
  url: string;
  locale?: string;
}

export const useContentstackSearchUrl = ({ contentTypeName, url, locale }: IContentstackParams) => {
  const [content, setContent] = useState({});
  const [status, setStatus] = useState('idle');

  const handleAPI = useCallback(async () => {
    if (!contentTypeName || !url) {
      return;
    }
    try {
      const {
        data: [pageContent],
      } = await api.get(
        `${Endpoints.CONTENTSTACK_URLS}?${qs.stringify({
          params: url,
          contentTypeName,
          include: [''],
          ...(locale && { locale: locale }),
        })}`
      );
      if (pageContent) {
        setContent(pageContent);
        setStatus('success');
        return;
      }
    } catch (e) {
      console.error(e);
      setStatus('error');
    }
  }, [contentTypeName, url, locale]);

  useEffect(() => {
    if (!contentTypeName || !url) return;
    handleAPI();
  }, [contentTypeName, url, handleAPI]);

  return { content, status };
};
