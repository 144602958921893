import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { IInviteNewUserPayload } from 'types/user-info';
import { inviteNewUser, inviteNewUserConstants } from '../actions';

export function* inviteNewUserSagaHandler({ payload }: IAction<IInviteNewUserPayload>) {
  yield put(doRequest<IInviteNewUserPayload>(inviteNewUser, Endpoints.INVITE_USER, 'post', payload));
}

export function* inviteNewUserFailCallBackSagaHandler({ payload }: IAction<IInviteNewUserPayload>) {
  const { error } = yield take(inviteNewUserConstants[Actions.FAIL]);
  if (payload.onFailCallback && error) {
    payload.onFailCallback(error.status, error.errorMessageId);
  }
}

export function* inviteNewUserSuccessCallBackSagaHandler({ payload }: IAction<IInviteNewUserPayload>) {
  yield take(inviteNewUserConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export default function* inviteNewUserSaga() {
  yield takeLatest(inviteNewUserConstants[Actions.REQUEST], inviteNewUserSagaHandler);
  yield takeLatest(inviteNewUserConstants[Actions.REQUEST], inviteNewUserSuccessCallBackSagaHandler);
  yield takeLatest(inviteNewUserConstants[Actions.REQUEST], inviteNewUserFailCallBackSagaHandler);
}
