import { FC, PropsWithChildren } from 'react';
import { someIsNotEmpty } from 'utils/conditions';

interface IRenderIfNonEmptyProps extends PropsWithChildren {
  data: {
    [key: string]: any;
  };
  keys: string[];
}

export const RenderIfNonEmpty: FC<IRenderIfNonEmptyProps> = ({ data, keys, children }) =>
  someIsNotEmpty(data, keys) ? <>{children}</> : null;
