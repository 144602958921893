import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/dashboard/actions';
import { Actions } from 'constants/actions.enum';
import types from 'store/dashboard/action-types';
import { IFormWithServerErrors } from 'store/dashboard/reducer';

export const csrCustomerFormInitialState: IFormWithServerErrors = {
  isSubmitting: false,
  serverErrorId: '',
};

export const csrCustomerEmailForm = createReducer<IFormWithServerErrors>(
  {
    [actions.changeCustomerEmailConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isSubmitting: true,
      serverErrorId: '',
    }),
    [actions.changeCustomerEmailConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
    }),
    [actions.changeCustomerEmailConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: error.errorMessageId || '',
    }),
    [types.CLEAR_CSR_FORM_SERVER_ERRORS]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
    }),
  },
  csrCustomerFormInitialState
);
