import { action } from 'typesafe-actions';
import { Limit } from 'constants/limit.enum';
import { ISortParams } from 'types/product-sort-options';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import { IFilter } from 'types/facets';
import types from './action-types';
import { ISearchProductsState } from './reducer';

export const searchProductsActionConstants = createConstants(types.SEARCH_PRODUCTS);
export const getSearchSuggestionsActionConstants = createConstants(types.GET_SEARCH_SUGGESTIONS);
export const getSearchTemporaryFiltersInfoActionConstants = createConstants(types.GET_SEARCH_TEMPORARY_FILTERS_INFO);

export const searchProducts = createRequestActions(searchProductsActionConstants);

export const getSearchSuggestions = createRequestActions(getSearchSuggestionsActionConstants);
export const getSearchTemporaryFiltersInfo = createRequestActions(getSearchTemporaryFiltersInfoActionConstants);
export const clearSearchSuggestions = action(types.CLEAR_SEARCH_SUGGESTIONS);

export const changeSearchPaginationCurrent = (current: number) => {
  return action(types.SEARCH_PRODUCTS_PAGINATION_CHANGE, {
    current,
  });
};

export const setSearchProductsSortParams = (selectedSortField: ISortParams) => {
  return action(types.SET_SEARCH_PRODUCTS_SORT_PARAMS, {
    sortParams: selectedSortField,
  });
};

export const setSearchProductsLimit = (selectedLimit: Limit) => {
  return action(types.SET_SEARCH_PRODUCTS_LIMIT, {
    limit: selectedLimit,
  });
};

export const setSearchSelectedFacets = (selectedFacets: IFilter[]) =>
  action(types.SET_SEARCH_SELECTED_FACETS, {
    selectedFacets,
  });

export const setSearchSelectedCategories = (selectedCategoryKey: string[]) =>
  action(types.SET_SEARCH_SELECTED_CATEGORIES, {
    selectedCategoryKey,
  });

export const clearSearchSelectedFilters = (resetToInitial?: boolean) =>
  action(types.CLEAR_SEARCH_SELECTED_FILTERS, { resetToInitial });

export const resetSearchDawnBrands = (dawnBrands: string[]) => action(types.RESET_SEARCH_DAWN_BRANDS, { dawnBrands });

export const setSearchSelectedFilters = (payload: ISearchProductsState) =>
  action(types.SET_SEARCH_SELECTED_FILTERS, payload);

export const initSearchTemporarySelectedFilters = () => action(types.INIT_SEARCH_TEMPORARY_SELECTED_FILTERS);

export const setSearchTemporarySelectedFilters = (payload: ISearchProductsState) =>
  action(types.SET_SEARCH_TEMPORARY_SELECTED_FILTERS, payload);

export const resetSearchTemporarySelectedFilters = () => action(types.RESET_SEARCH_TEMPORARY_SELECTED_FILTERS);

export const confirmSearchTemporarySelectedFilters = () => action(types.CONFIRM_SEARCH_TEMPORARY_SELECTED_FILTERS);

export const setSearchTemporarySelectedCategories = (selectedCategoryKey: string[]) =>
  action(types.SET_SEARCH_TEMPORARY_SELECTED_CATEGORIES, {
    selectedCategoryKey,
  });

export const setSearchTemporarySelectedFacets = (selectedFacets: IFilter[]) =>
  action(types.SET_SEARCH_TEMPORARY_SELECTED_FACETS, {
    selectedFacets,
  });

export const clearSearchTemporarySelectedFilters = () => action(types.CLEAR_SEARCH_TEMPORARY_SELECTED_FILTERS);

export const clearSearchState = () => action(types.CLEAR_SEARCH_STATE);
