import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { cancelQuote, cancelQuoteActionConstants } from 'store/quotes/actions';
import { IAction } from 'types/actions';

export interface ICancelQuotePayload {
  quoteNumber: string;
  cancelReason?: string;
  onSuccessCallback: () => void;
}

export function* cancelQuoteSagaHandler({ payload: { quoteNumber, cancelReason } }: IAction<ICancelQuotePayload>) {
  yield put(doRequest(cancelQuote, `${Endpoints.QUOTES}/${quoteNumber}/cancel`, 'patch', { cancelReason }));
}

export function* cancelQuoteOnSuccessCallBackSagaHandler({ payload }: IAction<ICancelQuotePayload>) {
  yield take(cancelQuoteActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    yield payload.onSuccessCallback();
  }
}

export default function* cancelQuoteSaga() {
  yield takeLatest(cancelQuoteActionConstants[Actions.REQUEST], cancelQuoteSagaHandler);
  yield takeLatest(cancelQuoteActionConstants[Actions.REQUEST], cancelQuoteOnSuccessCallBackSagaHandler);
}
