import { isEmpty } from 'lodash-es';
import { shopCountriesAllowed, shopLanguagesAllowed } from 'constants/shop-locales';
import { Routes } from 'constants/routes.enum';

export const checkIfShopUrlValid = (lang?: string, country?: string, pathName?: string) => {
  const isRouteTranslated = checkIfShopUrlTranslated(lang, pathName);
  const isDefaultLocaleSet = isEmpty(lang) && isEmpty(country);
  const isCountryParamPassed = country && shopCountriesAllowed.includes(country);
  const isLangParamPassed = lang && shopLanguagesAllowed.includes(lang);

  return (isCountryParamPassed || isLangParamPassed || isDefaultLocaleSet) && isRouteTranslated;
};

export const checkIfShopUrlTranslated = (lang?: string, pathName?: string): boolean => {
  const routesRequiredForTranslation = {
    fr: [
      Routes.AccountPayments,
      Routes.PaymentSummary,
      Routes.PaymentDetails,
      Routes.CustomerRegistrationPage,
      Routes.LoginPage,
      Routes.ProfilePage,
      Routes.CustomerRegistrationSuccessPage,
      Routes.ResetPasswordVerificationPage,
    ],
  };

  if (!lang || !pathName) {
    return true;
  }

  const routesToCheck = routesRequiredForTranslation[lang];

  return !routesToCheck || !routesToCheck.some((route: Routes) => pathName.includes(route));
};
