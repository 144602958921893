import { useState } from 'react';
import classNames from 'classnames';
import iconChevron from 'assets/images/icons/icon-chevron-black.svg';
import './collapsible-consent-section.scss';
import { CustomCheckbox } from 'components/custom-checkbox';
import { useContent } from 'hooks/use-content';
import { ContentstackHtml } from 'components/contentstack';

interface ICollapsibleConsentSectionProps {
  isChecked: boolean;
  consentTextPath: string;
  checkboxLabelPath: string;
  onCheckedStatusChange: () => void;
}

export const CollapsibleConsentSection = ({
  isChecked,
  onCheckedStatusChange,
  consentTextPath,
  checkboxLabelPath,
}: ICollapsibleConsentSectionProps) => {
  const formClassName = 'collapsible-consent-section';

  const [isOpen, setIsOpen] = useState(false);
  const { getContentByKey } = useContent();

  const collapseContentClass = classNames(`${formClassName}__content`, {
    expanded: isOpen,
    collapsed: !isOpen,
  });
  const collapseIconClass = classNames(`${formClassName}__icon-image`, {
    'arrow-up': isOpen,
    'arrow-down': !isOpen,
  });

  const toggleOpen = () => {
    setIsOpen((state) => !state);
  };

  return (
    <div className={formClassName}>
      <div className={`${formClassName}__container`}>
        <div className={collapseContentClass}>
          <ContentstackHtml contentKey={consentTextPath} />
        </div>
        <div className={`${formClassName}__icon`} onClick={toggleOpen}>
          <img className={collapseIconClass} src={iconChevron} alt="icon expand" />
        </div>
      </div>
      <CustomCheckbox
        className={`${formClassName}__checkbox`}
        selected={isChecked}
        label={getContentByKey<string>(checkboxLabelPath, '')}
        onCheckboxClick={onCheckedStatusChange}
      />
    </div>
  );
};
