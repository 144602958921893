import { FC, PropsWithChildren, Ref } from 'react';
import classNames from 'classnames';
import { useBodyScrollLock } from 'hooks/use-body-scroll-lock';
import { ModalSize } from '../../modal';

export interface IModalContainerProps extends PropsWithChildren {
  contentRef: Ref<any>;
  parentClassName?: string;
  className?: string;
  onClose?: () => void;
  size?: ModalSize;
  hideCloseButton?: boolean;
}

export const ModalContainer: FC<IModalContainerProps> = ({
  children,
  parentClassName,
  className,
  onClose,
  contentRef,
  size = 'medium',
  hideCloseButton = false,
}) => {
  useBodyScrollLock();

  const modalSizeToGridNumbersMap = {
    small: {
      offset: 4,
      columnsNumber: 4,
    },
    medium: {
      offset: 3,
      columnsNumber: 6,
    },
    medium_large: {
      offset: 2,
      columnsNumber: 8,
    },
    large: {
      offset: 1,
      columnsNumber: 10,
    },
  };

  const modalContentClass = `${className} modal__content cell medium-offset-${modalSizeToGridNumbersMap[size].offset}
    medium-${modalSizeToGridNumbersMap[size].columnsNumber}`;

  return (
    <div className={classNames('modal', parentClassName)}>
      <div className="modal__container">
        <div className="grid-container">
          <div className="grid-x">
            <div className={modalContentClass} ref={contentRef}>
              {children}
              {onClose && !hideCloseButton && (
                <button type="button" className="modal__close-button" aria-label="Close" onClick={onClose} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
