export const handleOpenMenuBodyStyling = ({ overflow, margin }: { overflow?: string; margin?: string }) => {
  if (margin) {
    document.body.style.marginTop = margin;
  }

  if (overflow) {
    document.body.style.overflow = overflow;
  }
};

export const buildMobileLabel = (label: string) =>
  label
    ? label
        .split(' ')
        .map((word) => word[0])
        .join('')
    : null;

export const buildDesktopLabel = (label: string) => (label ? label.split(' ')[0] : null);

export const getHeaderNotificationsClass = (isProduction, isImpersonation) => {
  const stringIs = !isProduction || isImpersonation ? 'is' : '';
  const stringNotification = !isProduction ? '-notification' : '';
  const stringImpersonation = isImpersonation ? '-impersonation' : '';
  return stringIs + stringNotification + stringImpersonation;
};
