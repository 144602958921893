import { FC, createContext, useState, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { selectCategoriesNavigation } from 'store/categories-navigation/selectors';
import { ICategory } from 'store/categories-navigation/reducer';

export interface INavigationStateContext {
  selectedL1: ICategory | null;
  selectedL2: ICategory | null;
  selectedCategoryHeading?: string;
  visibleCategories: ICategory[];
  hasSelectedCategory: boolean;
  handleCategorySelect: (category: ICategory) => void;
  resetCategoriesMenu: () => void;
  resetL2Category: () => void;
}

export const NavigationState = createContext<INavigationStateContext>({
  selectedL1: null,
  selectedL2: null,
  visibleCategories: [],
  hasSelectedCategory: false,
  handleCategorySelect: () => {},
  resetCategoriesMenu: () => {},
  resetL2Category: () => {},
});

export const NavigationStateContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const categories = useSelector(selectCategoriesNavigation);
  const [selectedL1, setSelectedL1] = useState<ICategory | null>(null);
  const [selectedL2, setSelectedL2] = useState<ICategory | null>(null);
  const resetCategoriesMenu = () => {
    setSelectedL1(null);
    setSelectedL2(null);
  };
  const resetL2Category = () => {
    setSelectedL2(null);
  };
  const hasSelectedCategory = Boolean(selectedL1 || selectedL2);
  let handleCategorySelect = setSelectedL1;
  let visibleCategories = categories;
  let selectedCategoryHeading;

  if (selectedL2) {
    selectedCategoryHeading = selectedL2.categoryName;
    visibleCategories = selectedL2.children;
    handleCategorySelect = () => {};
  } else if (selectedL1) {
    selectedCategoryHeading = selectedL1.categoryName;
    visibleCategories = selectedL1.children;
    handleCategorySelect = setSelectedL2;
  }

  return (
    <NavigationState.Provider
      value={{
        selectedL1,
        selectedL2,
        selectedCategoryHeading,
        visibleCategories,
        hasSelectedCategory,
        handleCategorySelect,
        resetCategoriesMenu,
        resetL2Category,
      }}
    >
      {children}
    </NavigationState.Provider>
  );
};

export const NavigationStateContextConsumer = NavigationState.Consumer;
