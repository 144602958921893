import { FC } from 'react';

import './cor-product-status-labels.scss';

interface ICorProductStatusLabelsProps {
  labelContentKey: Record<string, string> | undefined;
  size?: 'small' | 'large' | 'responsive';
  isDiscontinued?: boolean;
  isSpecialOrder?: boolean;
  isPhasingOut?: boolean;
  isOutOfStock?: boolean;
}

export const CorProductStatusLabels: FC<ICorProductStatusLabelsProps> = ({
  size = 'responsive',
  isDiscontinued = false,
  isSpecialOrder = false,
  isPhasingOut = false,
  isOutOfStock = false,
  labelContentKey,
}) => {
  return (
    <>
      <div className={`cor-product-status-labels cor-product-status-labels--${size}`}>
        <div className="cor-product-status-labels__wrapper">
          {isDiscontinued && (
            <p className="cor-product-status cor-product-status--discontinued">{labelContentKey?.discontinued}</p>
          )}
          {isOutOfStock && (
            <p className="cor-product-status cor-product-status--out-of-stock">{labelContentKey?.out_of_stock}</p>
          )}
          {isPhasingOut && (
            <p className="cor-product-status cor-product-status--phasing-out">{labelContentKey?.phasing_out}</p>
          )}
          {isSpecialOrder && (
            <p className="cor-product-status cor-product-status--special-order">{labelContentKey?.special_order}</p>
          )}
        </div>
      </div>
    </>
  );
};
