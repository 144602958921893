import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getBuyItAgainProducts, getBuyItAgainProductsConstants } from 'store/home/actions';

export function* getBuyItAgainProductsHandler() {
  yield put(doRequest(getBuyItAgainProducts, `${Endpoints.BUY_IT_AGAIN}`, 'get'));
}

export default function* getBuyItAgainProductsSaga() {
  yield takeLatest(getBuyItAgainProductsConstants[Actions.REQUEST], getBuyItAgainProductsHandler);
}
