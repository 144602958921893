import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import * as actions from './actions';
import types from './action-types';

export interface IOptOutFormState {
  submittedSuccessfully: boolean;
}

export const optOutFormInitialState: IOptOutFormState = {
  submittedSuccessfully: false,
};

const optOutForm = createReducer<IOptOutFormState>(
  {
    [actions.optOutFormActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      submittedSuccessfully: true,
    }),
    [types.RESET_OPT_OUT_FORM_STATE]: () => optOutFormInitialState,
  },
  optOutFormInitialState
);

export default optOutForm;
