import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getQuotes, getQuotesActionConstants } from 'store/quotes/actions';

export function* getQuotesSagaHandler() {
  yield put(doRequest(getQuotes, Endpoints.QUOTES, 'get'));
}

export default function* getQuotesSaga() {
  yield takeLatest(getQuotesActionConstants[Actions.REQUEST], getQuotesSagaHandler);
}
