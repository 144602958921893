import { FC, SyntheticEvent, HTMLAttributes, ReactNode, useState } from 'react';
import classNames from 'classnames';

import './button.scss';

type iconType = string | object;
type iconAlignType = boolean;

export interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
  color?: string;
  className?: string;
  onClick?: (event: SyntheticEvent) => void;
  type?: 'button' | 'submit' | 'reset';
  children: ReactNode;
  icon?: iconType;
  isIconOnly?: boolean;
  iconPosition?: 'left' | 'right';
  hoverIcon?: iconType;
  disabled?: boolean;
}

export const Button: FC<IButtonProps> = ({
  color = 'primary',
  className,
  onClick,
  type = 'button',
  children,
  icon = '',
  isIconOnly = false,
  iconPosition = 'left',
  hoverIcon = '',
  disabled = false,
  ...buttonProps
}) => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = (): void => {
    setHover(true);
  };

  const onMouseLeave = (): void => {
    setHover(false);
  };

  const handleClick = (event: SyntheticEvent): void => {
    if (onClick) {
      onClick(event);
    }
  };

  const getIcon = (): iconType => {
    const currentIcon = hover && hoverIcon ? hoverIcon : icon;

    if (currentIcon && typeof currentIcon === 'string') {
      return <img src={currentIcon} data-testid="button icon" alt="button icon" />;
    }

    return currentIcon;
  };

  const resultClass: string = classNames('new-button', color, className, { 'new-button--withIcon': icon });

  const isLeftIcon: iconAlignType = iconPosition === 'left' || isIconOnly;
  const isRightIcon: iconAlignType = iconPosition === 'right' && !isIconOnly;
  return (
    <button
      className={resultClass}
      onClick={handleClick}
      type={type}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      data-testid="button"
      {...buttonProps}
    >
      <>
        {isLeftIcon ? getIcon() : null}
        {!isIconOnly ? <span className="content">{children}</span> : null}
        {isRightIcon ? getIcon() : null}
      </>
    </button>
  );
};
