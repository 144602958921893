import { CorContentStackLanguages } from 'constants/cor-locale.enum';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { Routes } from 'constants/routes.enum';
import { useLocalStorage } from './use-local-storage';

interface IUseGetTranslatedUrl {
  caFrenchUrl: Routes;
  usEnglishUrl: Routes;
}

export const useGetTranslatedUrl = () => {
  const [locale] = useLocalStorage('locale', null);

  return {
    getTranslatedUrl: ({ caFrenchUrl, usEnglishUrl }: IUseGetTranslatedUrl) =>
      locale === CorContentStackLanguages.FRENCH_CANADA
        ? getLocalizedShopUrl(caFrenchUrl)
        : getLocalizedShopUrl(usEnglishUrl),
  };
};
