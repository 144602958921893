import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { IHomeState } from 'store/home/reducer';
import { ShoppingListTypes } from 'constants/shopping-list-types.enum';

export const selectHomeEntity = (state: IState): IHomeState => state.app.home;
export const selectRecentOrdersInfo = createSelector(selectHomeEntity, ({ recentOrdersInfo }) => recentOrdersInfo);
export const selectRecentOrdersInfoLoading = createSelector(selectRecentOrdersInfo, ({ loading }) => loading);

export const selectRecentOrdersInfoOrders = createSelector(
  selectHomeEntity,
  ({ recentOrdersInfo }) => recentOrdersInfo.orders
);

export const selectRecentlyViewedItems = createSelector(
  selectHomeEntity,
  ({ recentlyViewedItems }) => recentlyViewedItems
);

export const selectBuyItAgainProducts = createSelector(
  selectHomeEntity,
  ({ buyItAgainProducts }) => buyItAgainProducts.products
);

export const selectBuyItAgainProductsLoading = createSelector(
  selectHomeEntity,
  ({ buyItAgainProducts }) => buyItAgainProducts.loading
);

export const selectHomeShoppingListsLoading = createSelector(
  selectHomeEntity,
  ({ homeShoppingLists }) => homeShoppingLists.loading
);

export const selectHomeShoppingLists = createSelector(selectHomeEntity, ({ homeShoppingLists }) =>
  homeShoppingLists?.lists?.filter(({ type }) => type !== ShoppingListTypes.FAVORITES)
);

export const selectHomeShoppingListsTotal = createSelector(
  selectHomeEntity,
  ({ homeShoppingLists }) => homeShoppingLists?.total
);
