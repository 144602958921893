import './cor-chef-notes.scss';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack';
import { jumpLinkLabelToAnchor } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-jump-links';
import { CorTypography } from 'corporate-ui';
import {
  CorContentstackImageAsset,
  corContentstackImageLinkResolver,
} from 'corporate-utils/cor-contentstack-image-link-resolver';
import { FC } from 'react';
import { CorTwoColumnDetails } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-two-column-details';
import { ReactComponent as IconTwitter } from 'assets/images/icons/icon-twitter.svg';
import { ReactComponent as IconLinkedIn } from 'assets/images/icons/icon-linkedin.svg';
import { ReactComponent as IconFacebook } from 'assets/images/icons/icon-facebook.svg';
import { useContent } from 'hooks/use-content';

const BASE_KEY = 'additional_notes_section';
const BASE_KEY_CHEF = 'additional_notes_section.chef_bio[0]';

type SocialNetworks = 'Instagram' | 'Twitter' | 'Facebook' | 'LinkedIn';

const SocialIcons = {
  Instagram: '',
  Twitter: IconTwitter,
  Facebook: IconFacebook,
  LinkedIn: IconLinkedIn,
};

interface IChefSocialProps {
  className: string;
  socialIcon: SocialNetworks;
  linkUrl: string;
}

const ChefSocial: FC<IChefSocialProps> = ({ className, socialIcon, linkUrl }) => {
  const Icon = SocialIcons[socialIcon];
  return (
    <a className={className} href={linkUrl}>
      <span className={`${className}-label`}>{`${socialIcon} link`}</span>
      <Icon />
    </a>
  );
};

export const CorChefNotes: FC = () => {
  const { getContentByKey } = useContent();
  const label = getContentByKey(`${BASE_KEY}.jumplink_label`, '');
  const id = `${jumpLinkLabelToAnchor(label)}`;
  const chefNameLocation = getContentByKey(`${BASE_KEY_CHEF}.chef_name_and_location`, '');
  const chefSocialIcon = getContentByKey(`${BASE_KEY_CHEF}.chef_social_link_type`, '');
  const chefSocialUrl = getContentByKey(`${BASE_KEY_CHEF}.chef_social_link_url`, '');
  const chefNotes = getContentByKey(`${BASE_KEY}.additional_notes_description`, '');
  const chefBio = getContentByKey(`${BASE_KEY_CHEF}.chef_bio`, '');
  const chefImage: CorContentstackImageAsset | undefined = corContentstackImageLinkResolver(
    getContentByKey(`${BASE_KEY_CHEF}.chef_image`, {})
  );

  if (!chefNotes && !chefBio) {
    return null;
  }

  return (
    <div className={'cor-chef-notes'} id={id}>
      <CorTwoColumnDetails>
        {/*  empty container for the left column*/}
        <div />

        <div>
          <div className={'cor-chef-notes__section'}>
            <div className={'cor-chef-notes__additional-details'}>
              <CorContentstackHtml
                className={'cor-chef-notes__additional-details-rich-text'}
                data-testid={'cor-chef-notes__additional-details'}
                contentKey={`${BASE_KEY}.additional_notes_description`}
              />
            </div>

            <div className={'cor-chef-notes__chef-container'}>
              <CorTypography
                className={'cor-chef-notes__chef-container-label'}
                color={'mid-gray'}
                variant={'small-caps'}
                component={'div'}
              >
                {'RECIPE DEVELOPED BY'}
              </CorTypography>

              <CorTypography
                className={'cor-chef-notes__chef-name-location'}
                color={'dark-gray'}
                variant={'primary-button-label'}
                component={'div'}
              >
                {chefNameLocation}
                {chefSocialIcon && (
                  <ChefSocial
                    className={'cor-chef-notes__chef-social'}
                    socialIcon={chefSocialIcon}
                    linkUrl={chefSocialUrl}
                  />
                )}
              </CorTypography>

              {chefSocialIcon && (
                <ChefSocial
                  className={'cor-chef-notes__chef-social'}
                  socialIcon={chefSocialIcon}
                  linkUrl={chefSocialUrl}
                />
              )}

              <CorTypography
                className={'cor-chef-notes__chef-bio'}
                color={'mid-gray'}
                variant={'body-small'}
                component={'div'}
              >
                {chefBio}
              </CorTypography>

              <div className="cor-chef-notes__chef-image-container">
                <img className={'cor-chef-notes__chef-image'} src={chefImage?.url} alt={chefImage?.image_alt_text} />
              </div>
            </div>
          </div>
        </div>
      </CorTwoColumnDetails>
    </div>
  );
};
