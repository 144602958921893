import { CreditCardTypeImageUrl } from './credit-card-type-image-url.enum';

export const CreditCardImageUrl = {
  AMEX: CreditCardTypeImageUrl.AMEX,
  DINERS: CreditCardTypeImageUrl.DINERS,
  DISCOVER: CreditCardTypeImageUrl.DISCOVER,
  JCB: CreditCardTypeImageUrl.JCB,
  MASTERCARD: CreditCardTypeImageUrl.MASTERCARD,
  VISA: CreditCardTypeImageUrl.VISA,
};
