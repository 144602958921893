import { FC } from 'react';
import { Layouts } from 'constants/layouts.enum';
import { RouteWrapper } from 'components/router/route-wrapper';

export interface IContentstackParams {
  contentTypeName: string;
  url: string;
  include: string[];
  locale?: string;
}

export interface IAppRoute {
  path: string;
  component: FC<any>;
  routes?: [IAppRoute];
  exact?: boolean;
  isPrivate?: boolean;
  isRestricted?: boolean;
  isInternal?: boolean;
  layout?: Layouts;
  unauthorizedLayout?: Layouts;
  contentstackParams?: IContentstackParams;
  unauthorizedContentstackParams?: IContentstackParams;
  requiredPermissions?: string[];
  hasDynamicContentstackPageUrl?: boolean;
  additionalContentTypeToFetch?: { id: string; pathToContent: string; include: string[] }[];
}

export const mapRoutesConfigToRoutes = (routesConfig: IAppRoute[]) =>
  routesConfig.map((route, i) => {
    return <RouteWrapper key={i} {...route} />;
  });
