import { IOrderDetails } from 'types/order-details';
import { DEFAULT_PRICE_SUMMARY_MODEL } from 'constants/price-summary';
import { DEFAULT_ACCOUNT_LOCATION_MODEL } from 'constants/location';

export const DEFAULT_ORDER_DETAILS_MODEL: IOrderDetails = {
  ctId: '',
  orderNumber: '',
  invoiceNumber: '',
  poNumber: '',
  orderDate: '',
  isSpecial: false,
  isCancelled: false,
  quoteNumber: '',
  billToLocation: {
    name: '',
    address: {
      ...DEFAULT_ACCOUNT_LOCATION_MODEL, // To be destructured separately in the place of usage
    },
  },
  shipToLocation: {
    name: '',
    locationNumber: '',
    address: {
      ...DEFAULT_ACCOUNT_LOCATION_MODEL, // To be destructured separately in the place of usage
    },
  },
  accountInformation: {
    accountStatus: '',
    paymentTerms: '',
  },
  priceSummary: {
    ...DEFAULT_PRICE_SUMMARY_MODEL, // To be destructured separately in the place of usage
  },
  expectedDeliveryDate: '',
  lineItems: [],
  isCancellable: false,
  isOrderCancellationDenied: false,
  offline: false,
  creationDate: '',
  requestedFutureDeliveryDate: '',
  futureDate: false,
  firstName: '',
  lastName: '',
};
