import { ICSContentstackAsset } from 'corporate-interfaces';
import {
  ICSHeaderCorpNavMenu,
  ICSHeaderMenu,
  ICSResourceMenuItem,
  ICSUtilityNavData,
  IMainMenuLink,
  IMainMenuResource,
  IMainMenuResourceItem,
} from './cor-header.interface';

export const resolveCorporateMenu = (reference: ICSHeaderMenu[]): ICSHeaderCorpNavMenu => {
  const ref = (reference && reference[0]) || null;
  const group = ref ? ref?.menu_group : null;
  const links = group && group[0] ? group[0]?.menu_link : [];

  return {
    label: ref?.label,
    links: links?.map((link) => ({
      ...link,
      mobileOnly: false,
    })),
  };
};

export const resolveShopMenu = (reference: ICSUtilityNavData): ICSHeaderCorpNavMenu => {
  const {
    shop_header_menu_reference,
    new_arrivals_link,
    sticky_header_products_menu_label,
    mobile_search_panel_title,
  } = reference || null;
  const group =
    shop_header_menu_reference && shop_header_menu_reference[0] ? shop_header_menu_reference[0]?.menu_group : null;
  const links = group && group[0] ? group[0]?.menu_link : [];

  return {
    new_arrivals_link,
    sticky_header_products_menu_label,
    mobile_search_panel_title,
    links: links?.map((link) => ({
      ...link,
      mobileOnly: false,
    })),
  };
};

export const resolveAsset = (data: ICSContentstackAsset): IMainMenuResourceItem => {
  return {
    type: data?.content_type,
    file_size: data?.file_size,
    src: data?.url,
    title: data?.title,
  };
};

export const resolveLink = (data: any): IMainMenuLink => {
  return {
    label: data.link?.title,
    url: data.link?.href,
    openInNewTab: data.open_in_a_new_tab,
  };
};

export const resolveBrandfolderAsset = (data: any): IMainMenuResourceItem => {
  return {
    type: data?.extension,
    file_size: String(data?.size),
    src: data?.cdn_url,
    title: data?.filename,
  };
};

export const resolveResourceMenuItem = (data: ICSResourceMenuItem): IMainMenuResource => {
  return {
    title: data?.resource_title,
    subtitle: data?.resource_subtitle,
    url: data?.resource_link,
    icon: data?.resource_image ? resolveAsset(data?.resource_image) : undefined,
    brandfolderIcon: data?.brandfolder_resource_image
      ? resolveBrandfolderAsset(data?.brandfolder_resource_image[0])
      : undefined,
    imageCover: data?.is_full_image,
    file: data?.contentstack_resource_file ? resolveAsset(data?.contentstack_resource_file) : null,
  };
};
