import { FC, MutableRefObject, useCallback, useRef } from 'react';
import { get } from 'lodash-es';
import Select, { components, Props, GroupBase, ValueContainerProps } from 'react-select';
import classNames from 'classnames';

import './custom-dropdown.scss';

export interface IOptionType {
  label: string;
  value: any;
}

export interface ILinkOptionType {
  label: string;
  value: any;
  href: string;
  title: string;
}

export interface ICustomDropdownProps extends Partial<Props<IOptionType>> {
  items: IOptionType[] | ILinkOptionType[] | ReadonlyArray<GroupBase<IOptionType>>;
  dropdownIcon?: string;
  renderOption?: (props: any) => JSX.Element;
  hasValueAlt?: boolean;
  multiValueTitle?: string;
  dropdownLabel?: string;
  label?: string;
  ref?: MutableRefObject<any | undefined>;
}

export const CustomDropdown: FC<ICustomDropdownProps> = ({
  defaultValue,
  items,
  value,
  isSearchable = false,
  hideSelectedOptions = false,
  onChange,
  dropdownIcon,
  className,
  isDisabled = false,
  isMulti = false,
  closeMenuOnSelect = true,
  renderOption,
  formatGroupLabel,
  formatOptionLabel,
  multiValueTitle,
  isClearable = false,
  placeholder,
  hasValueAlt = false,
  inputId,
  onBlur,
  classNamePrefix = 'select',
  ref,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownClass = classNames('select', className, { 'select--multiple-values': isMulti });
  const DropdownIndicator = (props: any) => {
    const valueProp = props.getValue();

    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <label className="select__label" htmlFor={inputId}>
            {get(valueProp, '0.label')}
          </label>
          <span className="select__dropdown-icon" />
        </components.DropdownIndicator>
      )
    );
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps<IOptionType>) => {
    const valueProp = props.getValue();

    const CustomValueContainer = useCallback(
      () => (
        <>
          <div className="select__icon">
            <img src={dropdownIcon} alt="dropdown icon" />
          </div>
          <components.ValueContainer {...props}>
            <div
              title={hasValueAlt ? get(valueProp, '0.value') : null}
              className="select__single-value-container-wrapper"
            >
              {children}
            </div>
          </components.ValueContainer>
        </>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return dropdownIcon ? (
      <CustomValueContainer />
    ) : (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  };

  const Option = ({ children, ...props }: any) => {
    return renderOption ? (
      renderOption({ children, ...props })
    ) : (
      <components.Option {...props}>{children}</components.Option>
    );
  };

  const MultiValue = ({ children, ...props }: any) => {
    return props.selectProps.value.length > 0 ? (
      <components.ValueContainer {...props}>
        {props.selectProps.value.length} {multiValueTitle}
      </components.ValueContainer>
    ) : (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  };

  const dropdownComponentsMap = [
    {
      DropdownIndicator,
      ValueContainer,
      Option,
    },
    { DropdownIndicator, MultiValue, Option },
  ];

  const getMaxMenuHeight = () => {
    const defaultHeight = 300;
    const optionItemHeight = 44;

    const rect = containerRef.current?.getBoundingClientRect();
    const height = document.documentElement.clientHeight - (rect?.bottom ?? 0) - optionItemHeight;

    return height > defaultHeight ? defaultHeight : Math.round(height / optionItemHeight) * optionItemHeight;
  };

  return (
    <div className={dropdownClass} ref={containerRef}>
      <Select
        ref={ref}
        inputId={inputId}
        components={!isMulti ? dropdownComponentsMap[0] : dropdownComponentsMap[1]}
        classNamePrefix={classNamePrefix}
        value={value}
        defaultValue={defaultValue}
        options={items}
        isSearchable={isSearchable}
        onChange={onChange}
        hideSelectedOptions={hideSelectedOptions}
        isDisabled={isDisabled}
        isMulti={isMulti}
        onBlur={onBlur}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        placeholder={placeholder}
        formatGroupLabel={formatGroupLabel}
        formatOptionLabel={formatOptionLabel}
        blurInputOnSelect={false}
        maxMenuHeight={getMaxMenuHeight()}
      />
    </div>
  );
};
