import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/notifications/actions';
import { ICustomerNotificationItem } from 'types/notifications';

export interface ICustomerNotificationsState {
  notificationItems: ICustomerNotificationItem[];
  loading: boolean;
}

export const customerNotificationsInitialState: ICustomerNotificationsState = {
  notificationItems: [],
  loading: false,
};

const customerNotifications = createReducer<ICustomerNotificationsState>(
  {
    [actions.getCustomerNotificationsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getCustomerNotificationsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      notificationItems: { ...payload },
      loading: false,
    }),
  },
  customerNotificationsInitialState
);

export default customerNotifications;
