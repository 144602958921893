import { createSelector } from 'reselect';
import { sortBy } from 'lodash-es';
import { IState } from 'store/root-reducer';
import { IProductDetailsState } from './reducer';

export const productsEntitySelector = (state: IState): IProductDetailsState => state.app.productDetails;

export const selectProductDetailsProductInfo = createSelector(
  productsEntitySelector,
  (productDetails) => productDetails.productInfo
);

export const selectProductInfoLoading = createSelector(selectProductDetailsProductInfo, ({ loading }) => loading);

export const selectProductInfo = createSelector(selectProductDetailsProductInfo, ({ productInfo }) => productInfo);

export const selectProductSelectedSkuInfo = createSelector(selectProductDetailsProductInfo, ({ productInfo }) => {
  const getSelectedSku = () => {
    return (
      productInfo?.skus.find(({ isSelected }) => isSelected) ||
      productInfo?.skus.find(({ isDefault }) => isDefault) ||
      sortBy(productInfo?.skus, [(sku) => Number(sku.skuDetails.variantPriority)])[0]
    );
  };

  return Number(productInfo?.skus.length) > 1 ? getSelectedSku() : productInfo?.skus[0];
});
export const selectProductQuantity = createSelector(productsEntitySelector, ({ productQuantity }) => productQuantity);

export const selectProductSkuSubtotalLoading = createSelector(
  productsEntitySelector,
  ({ productSkuSubtotal }) => productSkuSubtotal.loading
);

export const selectProductSkuSubtotal = createSelector(
  productsEntitySelector,
  selectProductSelectedSkuInfo,
  ({ productSkuSubtotal }, selectedSkuInfo) =>
    productSkuSubtotal.subtotal || (selectedSkuInfo && selectedSkuInfo.priceModel)
);

export const selectGroupSelectors = createSelector(
  selectProductInfo,
  (productInfo) => productInfo.groupSelectors || []
);

export const selectGroupSelectorsCurrentValues = createSelector(
  selectProductSelectedSkuInfo,
  (selectedSkuInfo) => selectedSkuInfo.groupSelectorsCurrentValues || []
);

export const selectGroupSelectorsMapped = createSelector(
  selectGroupSelectors,
  selectGroupSelectorsCurrentValues,
  (groupSelectors, groupSelectorsCurrentValues) => {
    const mappedGroupSelectors = (arr, initialValue = []) =>
      arr.reduce((acc, selector) => {
        const index = acc.findIndex((item) => item.type === selector.type);
        if (index === -1) {
          acc = [...acc, { type: selector.type, values: [selector.value] }];
        } else {
          if (!acc[index].values.includes(selector.value)) {
            acc[index].values.push(selector.value);
          }
        }

        if (
          selector.children &&
          groupSelectorsCurrentValues?.find((item) => item.type === selector.type && item.value === selector.value)
        ) {
          acc = mappedGroupSelectors(selector.children, acc);
        }

        return acc;
      }, initialValue);

    return mappedGroupSelectors(groupSelectors).filter((item) => item.values.length > 1);
  }
);

export const selectProductRecipes = createSelector(productsEntitySelector, ({ productRecipes }) => productRecipes);
