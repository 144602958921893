import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import * as actions from './actions';

export interface ICategory {
  categoryKey: string;
  categoryName: string;
  breadcrumbs: string[];
  children: ICategory[];
  count: number;
}

export interface ICategoriesNavigationState {
  categories: ICategory[];
  loading: boolean;
  showNewArrivals: boolean;
}

export const categoriesNavigationInitialState = {
  categories: [],
  loading: false,
  showNewArrivals: false,
};

const categoriesNavigation = createReducer<ICategoriesNavigationState>(
  {
    [actions.getCategoriesNavigationActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { categories, isNewArrivalPresent } }
    ) => ({
      ...state,
      categories,
      loading: false,
      showNewArrivals: isNewArrivalPresent,
    }),
    [actions.getCategoriesNavigationActionConstants[Actions.REQUEST]]: (state) => ({ ...state, loading: true }),
    [actions.getCategoriesNavigationActionConstants[Actions.FAIL]]: (state) => ({ ...state, loading: false }),
  },
  categoriesNavigationInitialState
);

export default categoriesNavigation;
