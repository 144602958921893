import { FC } from 'react';
import { get } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';

interface IContentstackImageComponentProps {
  contentKey: string;
  className?: string;
}

export interface IContentstackImage {
  url?: string;
  title?: string;
}

export const ContentstackImage: FC<IContentstackImageComponentProps> = ({ contentKey, className }) => {
  const content = useContentstackContent();
  const image: IContentstackImage = get(content, contentKey, {});
  return image.url ? (
    <img
      src={`${image.url}?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}`}
      alt={image.title}
      className={className}
    />
  ) : null;
};
