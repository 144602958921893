import React, { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { isExternalUrl } from 'utils/is-external-url';
import { CorContentStackLanguageURLMap } from 'constants/cor-locale.enum';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';
import { Routes } from 'constants/routes.enum';
import { isAbsoluteLink } from 'utils/link-utils';
import { CorButtonLinkStyles, CorButtonLinkStylesEnum } from './cor-button-link.constants';

import './cor-button-link.scss';

export interface ICorButtonLinkProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  children?: ReactNode;
  color?: CorButtonLinkStylesEnum | string;
  isIconOnly?: boolean;
  iconUrl?: string;
  iconPosition?: 'left' | 'right' | string;
  clickHandler?: (event) => void;
  asset?: string;
}

export const CorButtonLink: FC<ICorButtonLinkProps> = ({
  children,
  isIconOnly,
  iconPosition = 'left',
  iconUrl,
  color = CorButtonLinkStylesEnum.Primary,
  className,
  href,
  clickHandler,
  asset,
  ...anchorProps
}) => {
  const [locale] = useLocalStorage('locale', null);
  const localeUrl = CorContentStackLanguageURLMap.get(locale);
  const nonEnglishInternationalSite = isInternationalSite(locale) && !String(locale).includes('en');
  const isAbsoluteUrl = !!href && isAbsoluteLink(href);
  const isExternalLink: boolean = useMemo(() => isExternalUrl(href || asset || ''), [href, asset]);
  const LinkComponent = isExternalLink || isAbsoluteUrl ? 'a' : Link;
  const hrefAttribute = isExternalLink || isAbsoluteUrl ? 'href' : 'to';
  const relativePath = href?.replace(window.location.origin, '');
  const buttonStyle =
    CorButtonLinkStyles.get(color as CorButtonLinkStylesEnum) ||
    CorButtonLinkStyles.get(CorButtonLinkStylesEnum.Primary);
  const printHandler = ($event) => {
    $event.preventDefault();
    window.print();
  };

  const classes = classNames('cor-button', 'cor-button-link', className, buttonStyle?.className, {
    'cor-button--withIcon': !!iconUrl || !!buttonStyle?.icon,
    [`cor-button--withIcon__${iconPosition}`]: !!iconPosition && (!!iconUrl || !!buttonStyle?.icon),
    'cor-button--is-international': nonEnglishInternationalSite,
  });

  const childrenComponent = useMemo(
    () => (
      <>
        {!!iconUrl && <img src={iconUrl} data-testid="button-icon" alt="button icon" />}
        {!!buttonStyle?.icon && (
          <div className={`cor-button-link__icon baseline ${buttonStyle.modifier}`}>{buttonStyle?.icon}</div>
        )}
        {!isIconOnly && <span className="content">{children}</span>}
      </>
    ),
    [children, iconUrl, isIconOnly, buttonStyle]
  );

  if (!!buttonStyle && buttonStyle === CorButtonLinkStyles.get(CorButtonLinkStylesEnum.Print)) {
    clickHandler = printHandler;
  }

  return React.createElement(
    LinkComponent,
    {
      className: classes,
      // @ts-ignore:next-line
      'data-testid': 'button',
      [hrefAttribute]:
        asset || isAbsoluteUrl
          ? href
          : isInternationalSite(localeUrl)
          ? `${localeUrl}${relativePath === Routes.HomePage ? '' : relativePath}`
          : href,
      onClick: ($event) => clickHandler && clickHandler($event),
      ...anchorProps,
    },
    childrenComponent
  );
};
