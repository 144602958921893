import { put, takeLatest } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import {
  getMsrLocationsCartStatusAndOrdersForMsm,
  getMsrLocationsCartStatusAndOrdersForMsmConstants,
} from 'store/dashboard/actions';
import { IAction } from 'types/actions';

export interface IGetCartStatusAndOrdersForMsmPayload {
  msrId: string;
  locationsId: string[];
}

export function* getMsrLocationsCartStatusAndOrdersForMsmHandler({
  payload: { msrId, locationsId },
}: IAction<IGetCartStatusAndOrdersForMsmPayload>) {
  yield put(
    doRequest(
      getMsrLocationsCartStatusAndOrdersForMsm,
      `${Endpoints.DASHBOARD_MSM_MSR_LOCATIONS_CART_STATUS_ORDERS}`,
      'post',
      { msrId, locationsId }
    )
  );
}
export default function* getMsrLocationsCartStatusAndOrdersForMsmSaga() {
  yield takeLatest(
    getMsrLocationsCartStatusAndOrdersForMsmConstants[Actions.REQUEST],
    getMsrLocationsCartStatusAndOrdersForMsmHandler
  );
}
