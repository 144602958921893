import './cor-directions.scss';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack';
import { jumpLinkLabelToAnchor } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-jump-links';
import { CorTwoColumnDetails } from 'corporate-components/cor-pages/corporate/pages/recipes/components/cor-two-column-details';
import { CorTypography } from 'corporate-ui';
import { FC } from 'react';
import { useContent } from 'hooks/use-content';

const BASE_KEY = 'directions.directions_2_column_group';

export const CorDirections: FC = () => {
  const { getContentByKey } = useContent();
  const label = getContentByKey(`${BASE_KEY}.jumplink_label`, '');
  const rightContentColumn = getContentByKey(`${BASE_KEY}.directions_right_column_content`, []);
  const anchor = jumpLinkLabelToAnchor(label);
  const directionsTitleLabel = getContentByKey(`${BASE_KEY}.directions_left_column_content[0]`, '');
  return (
    <div id={anchor} className={'cor-directions'} data-testid={'cor-directions'}>
      <CorTwoColumnDetails>
        {directionsTitleLabel ? (
          <CorTypography
            data-testid={'cor-directions__title'}
            className="cor-directions__title"
            variant={'h2'}
            color={'dark-gray'}
          >
            <CorContentstackHtml contentKey={`${BASE_KEY}.directions_left_column_content[0]`} />
          </CorTypography>
        ) : (
          <div>{/* empty container for the left column */}</div>
        )}
        <div className={'cor-directions__rich-text'}>
          <div className={'cor-directions__directions-text'}>
            {!!rightContentColumn.length &&
              rightContentColumn.map((_, idx) => (
                <CorContentstackHtml
                  key={idx.toString()}
                  data-testid={'cor-directions__directions-text'}
                  contentKey={`${BASE_KEY}.directions_right_column_content[${idx}]`}
                />
              ))}
          </div>
          <div className={'cor-directions__additional-details'}>
            <CorContentstackHtml
              data-testid={'cor-directions__additional-details'}
              contentKey={`${BASE_KEY}.additional_details`}
            />
          </div>
        </div>
      </CorTwoColumnDetails>
    </div>
  );
};
