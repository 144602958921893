import { put, take, takeLatest } from 'redux-saga/effects';
import { doRequest } from 'store/api/actions';
import { Actions } from 'constants/actions.enum';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { deleteCreditCard, deleteCreditCardActionConstants } from 'store/payment-methods/actions';

export interface IDeleteCreditCardPayload {
  cardLastFourDigits: string;
  cardType: string;
  onSuccessCallBack: () => void;
  onFailCallBack: (errorId: string, errorMessageId: string) => void;
}

export function* deleteCreditCardOnSuccessSagaHandler({ payload }: IAction<IDeleteCreditCardPayload>) {
  yield take(deleteCreditCardActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* deleteCreditCardOnFailSagaHandler({ payload }: IAction<IDeleteCreditCardPayload>) {
  const returnedError = yield take(deleteCreditCardActionConstants[Actions.FAIL]);
  const {
    error: { errorId, errorMessageId },
  } = returnedError;
  if (payload.onFailCallBack && returnedError) {
    payload.onFailCallBack(errorId, errorMessageId);
  }
}

export function* deleteCreditCardHandler({
  payload: { cardLastFourDigits, cardType },
}: IAction<IDeleteCreditCardPayload>) {
  const url = `${Endpoints.DELETE_CREDIT_CARD}`;
  yield put(doRequest(deleteCreditCard, url, 'delete', { cardLastFourDigits, cardType }));
}

export default function* deleteCreditCardSaga() {
  yield takeLatest(deleteCreditCardActionConstants[Actions.REQUEST], deleteCreditCardOnSuccessSagaHandler);
  yield takeLatest(deleteCreditCardActionConstants[Actions.REQUEST], deleteCreditCardOnFailSagaHandler);
  yield takeLatest(deleteCreditCardActionConstants[Actions.REQUEST], deleteCreditCardHandler);
}
