import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { IPaymentTransaction, IPaymentTransactionsState } from './reducer';

export const selectPaymentTransactionsEntity = (state: IState): IPaymentTransactionsState =>
  state.app.paymentTransations;

export const selectCreditCardPaymentInfo = createSelector(
  selectPaymentTransactionsEntity,
  ({ creditCardPayment }): IPaymentTransaction => creditCardPayment
);

export const selectAchPaymentInfo = createSelector(
  selectPaymentTransactionsEntity,
  ({ achPayment }): IPaymentTransaction => achPayment
);

export const selectPaymentTransactionError = createSelector(
  selectPaymentTransactionsEntity,
  ({ paymentTransactionError }) => paymentTransactionError.serverError
);
export const selectPaymentTransactionLoading = createSelector(
  selectPaymentTransactionsEntity,
  ({ loading }) => loading
);
