import { put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getSimilarItems, getSimilarItemsConstants } from 'store/similar-items/actions';
import { Actions } from 'constants/actions.enum';

export interface IGetSimilarItemsPayload {
  skus: string;
}

export function* getSimilarItemsHandler({ payload: { skus } }: IAction<IGetSimilarItemsPayload>) {
  const url = `${Endpoints.CATALOG_SKUS}/${skus}`;
  yield put(doRequest(getSimilarItems, url, 'get'));
}

export default function* getSimilarItemsSaga() {
  yield takeLatest(getSimilarItemsConstants[Actions.REQUEST], getSimilarItemsHandler);
}
