import { createSelector } from 'reselect';
import { IGuestState } from 'store/guest/reducer';
import { IState } from 'store/root-reducer';

export const selectGuest = (state: IState): IGuestState => state.app.guest;

export const selectAlgoliaCorporateSuggestionsConfig = createSelector(selectGuest, ({ keys }) => ({
  algoliaApplicationId: keys?.algoliaApplicationId,
  allCustomersSecuredApiKey: keys?.algoliaGlobalContentSecuredApiKey,
  algoliaApplicationCorporateIndexName: keys?.algoliaGlobalIndex,
}));
