import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useContent } from 'hooks/use-content';

export const DocumentHead: FC = () => {
  const { getContentByKey } = useContent();
  return (
    <Helmet>
      <title>{getContentByKey('title', '')}</title>
      <meta name="description" content={getContentByKey('page_meta_tags.description', '')} />
      <meta name="keywords" content={getContentByKey('page_meta_tags.keywords', '')} />
    </Helmet>
  );
};
