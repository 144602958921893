import { FC, ReactNode } from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

interface ISubmitButtonProps {
  children: ReactNode;
  type?: 'submit' | 'button';
  isDisabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export const SubmitButton: FC<ISubmitButtonProps> = ({
  children,
  type = 'submit',
  isDisabled,
  onClick,
  className,
  ...props
}) => {
  const { isValid, dirty, isSubmitting } = useFormikContext();
  const classes = classNames('button', className);
  // This is temporary solution not to broke current registration functionality
  const isButtonDisabled = isDisabled !== undefined ? isDisabled : !(isValid && dirty) || isSubmitting;

  return (
    <button type={type} disabled={isButtonDisabled} onClick={onClick} {...props} className={classes}>
      {children}
    </button>
  );
};
