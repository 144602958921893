import { createReducer } from 'store/reducer-creator';
import { Actions } from 'constants/actions.enum';
import types from './action-types';
import * as actions from './actions';

export interface IPaymentTransaction {
  ctPaymentId: string;
  soldTo: string;
}

export interface IPaymentTransactionServerError {
  serverError: string;
}

export interface IPaymentTransactionsState {
  creditCardPayment: IPaymentTransaction;
  achPayment: IPaymentTransaction;
  padPayment: IPaymentTransaction;
  paymentTransactionError: IPaymentTransactionServerError;
  loading: boolean;
}

export const paymentTransactionsInitialState: IPaymentTransactionsState = {
  creditCardPayment: {
    ctPaymentId: '',
    soldTo: '',
  },
  achPayment: {
    ctPaymentId: '',
    soldTo: '',
  },
  padPayment: {
    ctPaymentId: '',
    soldTo: '',
  },
  paymentTransactionError: { serverError: '' },
  loading: false,
};

export const paymentTransationsReducer = createReducer<IPaymentTransactionsState>(
  {
    [actions.submitCreditCardPaymentActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.submitCreditCardPaymentActionConstants[Actions.SUCCESS]]: (state, { payload: { creditCardPayment } }) => ({
      ...state,
      creditCardPayment: creditCardPayment,
      loading: false,
    }),
    [actions.submitAchPaymentActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.submitPadPaymentActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.submitCreditCardPaymentActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      paymentTransactionError: {
        serverError: error.errorMessageId,
      },
      loading: false,
    }),
    [actions.submitAchPaymentActionConstants[Actions.SUCCESS]]: (state, { payload: { achPayment } }) => ({
      ...state,
      achPayment: achPayment,
      loading: false,
    }),
    [actions.submitPadPaymentActionConstants[Actions.SUCCESS]]: (state, { payload: { padPayment } }) => ({
      ...state,
      padPayment: padPayment,
      loading: false,
    }),
    [actions.submitAchPaymentActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      paymentTransactionError: {
        serverError: error.errorMessageId,
      },
      loading: false,
    }),
    [actions.submitPadPaymentActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      paymentTransactionError: {
        serverError: error.errorMessageId,
      },
      loading: false,
    }),
    [types.CLEAR_PAYMENT_TRANSACTION_ERROR]: (state) => ({
      ...state,
      paymentTransactionError: {
        serverError: '',
      },
    }),
  },
  paymentTransactionsInitialState
);
