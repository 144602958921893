export interface IFacet {
  key: string;
  label: string;
  values?: string[];
  selectedValues: string[];
  count?: number;
  terms: { [facetLabel: string]: number | undefined };
  entries?: IFacetEntry[];
}

export interface IFacetEntry {
  name: string;
  count?: number;
}

export interface ISelectedFacet {
  key: string;
  values: string[];
}

export interface IFilter {
  key: string;
  value: string;
  count?: number;
}

export interface ISearchFacets {
  [key: string]: string[];
}

export interface ISearchParamFilter {
  key: string;
  value: string;
}

export enum FacetLabelsEnum {
  PRODUCT_STATUS = 'PRODUCT STATUS',
}

export enum SelectedFacetLabelsEnum {
  ARTICLE_STATUS = 'article_status',
}
