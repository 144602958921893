import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { addShoppingListToCart, addShoppingListToCartActionConstants } from 'store/shopping-lists/actions';
import { getCartInfo } from 'store/shopping-cart/actions';
import { IAction } from 'types/actions';
import { IShoppingListProduct } from 'types/product';

export interface IAddShoppingListToCartPayload {
  shoppingListId: string;
  locationId?: string;
  totalQuantity: number;
  onSuccessCallback?: () => void;
  items?: IShoppingListProduct[];
}

export function* addShoppingListToCartOnSuccessCallBackSagaHandler({
  payload,
}: IAction<IAddShoppingListToCartPayload>) {
  yield take(addShoppingListToCartActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export function* addShoppingListToCartSagaHandler({ payload }: IAction<IAddShoppingListToCartPayload>) {
  const { shoppingListId } = payload;
  yield put(doRequest(addShoppingListToCart, Endpoints.CART, 'POST', { shoppingListId }));
}

export function* addShoppingListToCartSuccessSagaHandler() {
  yield put(doRequest(getCartInfo, Endpoints.CART, 'get'));
}

export default function* addShoppingListToCartSaga() {
  yield takeLatest(
    addShoppingListToCartActionConstants[Actions.REQUEST],
    addShoppingListToCartOnSuccessCallBackSagaHandler
  );
  yield takeLatest(addShoppingListToCartActionConstants[Actions.REQUEST], addShoppingListToCartSagaHandler);
  yield takeLatest(addShoppingListToCartActionConstants[Actions.SUCCESS], addShoppingListToCartSuccessSagaHandler);
}
