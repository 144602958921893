import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContent } from 'hooks/use-content';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack';
import { IGuideItem } from 'types/guide-tile';
import { selectIsUserLocationDeleted } from 'store/auth/selectors';
import { useCreateList } from 'hooks/use-create-list';
import { useAddToShoppingList } from 'hooks/use-add-to-shopping-list';
import { EmptyShoppingListSlider } from './components/empty-shopping-list-slider';

import './empty-shopping-list.scss';

export const EmptyShoppingList = () => {
  const contentStackPath = 'page_content.shopping_list[0].empty_shopping_list[0]';
  const { isDesktop, isMobile } = useBreakpoint();
  const { getContentByKey } = useContent();
  const [activeTab, setActiveTab] = useState(0);
  const guideTabs: IGuideItem[] = getContentByKey(`${contentStackPath}.guide_tiles`, []);
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);

  const { createEmptyListHandler: createListHandler } = useCreateList({});

  const openCreateListModalHandler = () => {
    showCreateShoppingListModal();
  };

  const { showCreateShoppingListModal } = useAddToShoppingList({
    createListHandler,
    forceInitialRequest: false,
    openCreateListModalHandler,
  });

  const renderTabIcon = () => {
    return guideTabs.map(({ icon, title }, id) => (
      <li
        className={classNames('empty-shopping-list__content__tabs-item', {
          active: id === activeTab,
        })}
        key={id}
        onClick={() => setActiveTab(id)}
      >
        <div className="empty-shopping-list__content__tabs-item__icon-wrapper">
          <img src={icon?.url} alt={title} />
        </div>
      </li>
    ));
  };

  const renderDesktopEmptyShoppingList = () => {
    return (
      <div className="empty-shopping-list__content">
        <div className="grid-x">
          <ul className="cell medium-3 empty-shopping-list__content__tabs">{renderTabIcon()}</ul>
          <div className="cell medium-9 empty-shopping-list__content__main">
            <div className="empty-shopping-list__content__main-header">
              <div className="empty-shopping-list__content__main-header-title">
                <ContentstackText contentKey={`${contentStackPath}.title`} />
              </div>
              <div className="empty-shopping-list__content__main-header-description">
                <ContentstackText contentKey={`${contentStackPath}.description`} />
              </div>
            </div>
            <div
              className="empty-shopping-list__content__main-description"
              dangerouslySetInnerHTML={{
                __html: guideTabs[activeTab].description,
              }}
            />
            <div className="empty-shopping-list__content__main-button">
              <CorButton
                color="pripary"
                className="medium-2"
                disabled={isUserLocationDeleted}
                onClick={() => {
                  openCreateListModalHandler();
                }}
              >
                <ContentstackText contentKey={`${contentStackPath}.create_list_button`} />
              </CorButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="empty-shopping-list">
      {isDesktop && renderDesktopEmptyShoppingList()}
      {isMobile && (
        <EmptyShoppingListSlider
          guideTabs={guideTabs}
          contentPath={contentStackPath}
          deletedLocation={isUserLocationDeleted}
          openCreateListModal={openCreateListModalHandler}
        />
      )}
    </div>
  );
};
