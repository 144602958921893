import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Routes } from 'constants/routes.enum';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackText, ContentstackMessage } from 'components/contentstack';
import { IExceededMaximumQuantitySkuInfo } from 'types/order-details';
import { stripLeadingZeros } from 'utils/string-format';
import iconAdd from 'assets/images/icons/icon-add.svg';
import iconDelete from 'assets/images/icons/icon-delete.svg';
import { CorButton } from 'components/cor-button';

import './add-exceeded-item-to-cart-modal.scss';

export interface IAddExceededItemToCartModalProps extends IModalProps {
  addedItemsCounter: number;
  exceededMaxQuantitySku: IExceededMaximumQuantitySkuInfo;
}

export const AddExceededItemToCartModal: FC<IAddExceededItemToCartModalProps> = ({
  isOpen = false,
  onClose = () => {},
  addedItemsCounter,
  exceededMaxQuantitySku,
}) => {
  const history = useHistory();
  const hasAddedItems = addedItemsCounter !== 0;
  const contentstackPath = 'modals.0.add_exceeded_item_to_cart_modal.0';

  const { sku, skuName, maximumQuantity } = exceededMaxQuantitySku;

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="add-exceeded-item-to-cart-modal"
        onClose={onClose}
        hideCloseButton={!hasAddedItems}
        withBackdropClick
      >
        <>
          {sku && (
            <div className="add-exceeded-item-to-cart-modal__title">
              {hasAddedItems ? (
                <>
                  <img src={iconAdd} alt="icon add" />
                  <ContentstackText
                    contentKey={`${contentstackPath}.heading`}
                    interpolateParams={{ addedItemsCounter }}
                  />
                </>
              ) : (
                <>
                  <img src={iconDelete} alt="icon delete" />
                  <ContentstackText contentKey={`${contentstackPath}.fail_heading`} />
                </>
              )}
            </div>
          )}

          <div className="add-exceeded-item-to-cart-modal__content">
            {!isEmpty(exceededMaxQuantitySku) && (
              <div className="add-exceeded-item-to-cart-modal__section add-exceeded-item-to-cart-modal__section--bordered">
                <div className="add-exceeded-item-to-cart-modal__exceeded-max-quantity-sku">
                  <strong>{skuName}</strong>{' '}
                  {maximumQuantity === 0 ? (
                    <ContentstackMessage type="error" messageId="MSG096" interpolateParams={{ skuName }} />
                  ) : (
                    <ContentstackMessage
                      type="notifications"
                      messageId="MSG095"
                      interpolateParams={{ sku, skuName, maximumQuantity }}
                    />
                  )}
                  <p>
                    <ContentstackMessage type="notifications" messageId="MSG051b" /> {stripLeadingZeros(sku)}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="add-exceeded-item-to-cart-modal__footer">
            {hasAddedItems ? (
              <>
                <CorButton color="secondary" onClick={onClose}>
                  <ContentstackText contentKey={`${contentstackPath}.continue_shopping_button_label`} />
                </CorButton>
                <CorButton onClick={() => history.push(Routes.ShoppingCartPage)}>
                  <ContentstackText contentKey={`${contentstackPath}.view_cart_button_label`} />
                </CorButton>
              </>
            ) : (
              <CorButton className="add-exceeded-item-to-cart-modal__close-btn" color="secondary" onClick={onClose}>
                <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
              </CorButton>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};
