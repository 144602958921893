import './cor-tab-menu.scss';

import React, { FC } from 'react';
import classNames from 'classnames';
import { CorAccordionSelectType, CorSelect } from 'corporate-ui';
import { CorButton } from 'components/cor-button';
import { useBreakpoint } from 'hooks/use-breakpoint';

export type CorTabMenuType = {
  value: number;
  label: string;
};

export interface ICorTabMenuProps {
  className?: string;
  buttons: CorTabMenuType[];
  active: CorTabMenuType;
  onClickHandler: (tab: CorTabMenuType) => void;
}

export const CorTabMenu: FC<ICorTabMenuProps> = ({ buttons, active, onClickHandler, className }) => {
  const { isMobile } = useBreakpoint();
  if (isMobile) {
    return (
      <CorSelect
        options={buttons}
        value={active}
        onChangeHandler={(option) => onClickHandler(option as CorAccordionSelectType)}
      />
    );
  }

  return (
    <div className={classNames('cor-tab-menu', className)}>
      {buttons.map((accordion) => (
        <CorButton
          key={accordion.value.toString()}
          className={classNames('cor-tab-menu__tab', {
            'is-active': accordion.value === active.value,
          })}
          color={'tertiary'}
          onClick={() => onClickHandler(accordion)}
        >
          {accordion.label}
        </CorButton>
      ))}
    </div>
  );
};
