import { createContext, FC, PropsWithChildren, useCallback, useReducer } from 'react';

export enum StickySectionsActions {
  SET_ENVIRONMENT_NOTIFICATION_HEIGHT = 'SET_ENVIRONMENT_NOTIFICATION_HEIGHT',
  SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT',
  SET_ORDER_CUTOFF_NOTIFICATION_HEIGHT = 'SET_ORDER_CUTOFF_NOTIFICATION_HEIGHT',
  SET_ACCOUNT_WARNING_HEIGHT = 'SET_ACCOUNT_WARNING_HEIGHT',
  SET_NAV_HEADER_HEIGHT = 'SET_NAV_HEADER_HEIGHT',
  SET_SHOPPING_CART_TOTAL_SECTION_HEIGHT = 'SET_SHOPPING_CART_TOTAL_SECTION_HEIGHT',
  SET_SHOPPING_LIST_STICKY_HEADER_HEIGHT = 'SET_SHOPPING_LIST_STICKY_HEADER_HEIGHT',
  SET_CHECKOUT_HEADER_HEIGHT = 'SET_CHECKOUT_HEADER_HEIGHT',
  SET_IMPERSONATION_HEADER_HEIGHT = 'SET_IMPERSONATION_HEADER_HEIGHT',
  SET_PURCHASED_PRODUCTS_ACTIONS_HEIGHT = 'SET_PURCHASED_PRODUCTS_ACTIONS_HEIGHT',
  SET_ORDER_SUMMARY_HEIGHT = 'SET_ORDER_SUMMARY_HEIGHT',
  SET_SELECTED_SHOPPING_LIST_ITEMS_HEIGHT = 'SET_SELECTED_SHOPPING_LIST_ITEMS_HEIGHT',
  SET_PRODUCT_LIST_VIEW_ACTIONS_BLOCK_HEIGHT = 'SET_PRODUCT_LIST_VIEW_ACTIONS_BLOCK_HEIGHT',
}

export enum StickySectionsKeys {
  environmentNotification = 'environmentNotification',
  header = 'header',
  navHeader = 'navHeader',
  accountWarning = 'accountWarning',
  orderCutoffNotification = 'orderCutoffNotification',
  shoppingCartTotalSection = 'shoppingCartTotalSection',
  shoppingListStickyHeader = 'shoppingListStickyHeader',
  productListViewActionsBlock = 'productListViewActionsBlock',
  checkoutHeader = 'checkoutHeader',
  impersonationHeader = 'impersonationHeader',
  purchasedProductsActions = 'purchasedProductsActions',
  orderSummary = 'orderSummary',
  selectedShoppingListItems = 'selectedShoppingListItems',
}

const setActionBySectionKey = {
  [StickySectionsKeys.environmentNotification]: StickySectionsActions.SET_ENVIRONMENT_NOTIFICATION_HEIGHT,
  [StickySectionsKeys.header]: StickySectionsActions.SET_HEADER_HEIGHT,
  [StickySectionsKeys.navHeader]: StickySectionsActions.SET_NAV_HEADER_HEIGHT,
  [StickySectionsKeys.accountWarning]: StickySectionsActions.SET_ACCOUNT_WARNING_HEIGHT,
  [StickySectionsKeys.orderCutoffNotification]: StickySectionsActions.SET_ORDER_CUTOFF_NOTIFICATION_HEIGHT,
  [StickySectionsKeys.shoppingCartTotalSection]: StickySectionsActions.SET_SHOPPING_CART_TOTAL_SECTION_HEIGHT,
  [StickySectionsKeys.shoppingListStickyHeader]: StickySectionsActions.SET_SHOPPING_LIST_STICKY_HEADER_HEIGHT,
  [StickySectionsKeys.productListViewActionsBlock]: StickySectionsActions.SET_PRODUCT_LIST_VIEW_ACTIONS_BLOCK_HEIGHT,
  [StickySectionsKeys.checkoutHeader]: StickySectionsActions.SET_CHECKOUT_HEADER_HEIGHT,
  [StickySectionsKeys.impersonationHeader]: StickySectionsActions.SET_IMPERSONATION_HEADER_HEIGHT,
  [StickySectionsKeys.purchasedProductsActions]: StickySectionsActions.SET_PURCHASED_PRODUCTS_ACTIONS_HEIGHT,
  [StickySectionsKeys.orderSummary]: StickySectionsActions.SET_ORDER_SUMMARY_HEIGHT,
  [StickySectionsKeys.selectedShoppingListItems]: StickySectionsActions.SET_SELECTED_SHOPPING_LIST_ITEMS_HEIGHT,
};

export type StickyActionType = `${StickySectionsActions}`;
export type StickySectionKeysType = `${StickySectionsKeys}`;

interface IStickySection {
  height: number;
  isVisibleOnCurrentPage: boolean;
  priority: number;
}

interface IStickySectionsState {
  stickySectionsHeights?: {
    [key: string]: IStickySection;
  };
  setStickySectionHeight: (payload: ISetStickySectionHeightPayload) => void;
  getOffset: (sectionKey: StickySectionKeysType) => number;
  getCurrentPageStickySectionsHeight: () => number;
}

const stickySectionsStateDefaultValue = {
  stickySectionsHeights: {
    [StickySectionsKeys.environmentNotification]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 0,
    },
    [StickySectionsKeys.impersonationHeader]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 1,
    },
    [StickySectionsKeys.header]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 2,
    },
    [StickySectionsKeys.checkoutHeader]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 2,
    },
    [StickySectionsKeys.navHeader]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 3,
    },
    [StickySectionsKeys.accountWarning]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 4,
    },
    [StickySectionsKeys.orderCutoffNotification]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 5,
    },
    [StickySectionsKeys.purchasedProductsActions]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 5,
    },
    [StickySectionsKeys.selectedShoppingListItems]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 5,
    },
    [StickySectionsKeys.productListViewActionsBlock]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 5,
    },
    [StickySectionsKeys.shoppingCartTotalSection]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 6,
    },
    [StickySectionsKeys.orderSummary]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 6,
    },
    [StickySectionsKeys.shoppingListStickyHeader]: {
      height: 0,
      isVisibleOnCurrentPage: false,
      priority: 6,
    },
  },
  setStickySectionHeight: () => {},
  getOffset: () => 0,
  getCurrentPageStickySectionsHeight: () => 0,
};
interface ISetStickySectionHeightPayload {
  sectionHeight: number;
  sectionKey: string;
}

const stickySectionsReducer = (state, action) => {
  switch (action.type) {
    case action.type:
      return {
        ...state,
        stickySectionsHeights: {
          ...state.stickySectionsHeights,
          [action.payload.sectionKey]: {
            ...state.stickySectionsHeights[action.payload.sectionKey],
            height: action.payload.sectionHeight,
            isVisibleOnCurrentPage: true,
          },
        },
      };
    default:
      return state;
  }
};

export const StickySectionsHeightsStateContext = createContext<IStickySectionsState>(stickySectionsStateDefaultValue);

export const StickySectionsHeightsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [stickySectionsHeightsState, dispatch] = useReducer(stickySectionsReducer, stickySectionsStateDefaultValue);

  const setStickySectionHeight = useCallback(
    (payload: ISetStickySectionHeightPayload) => {
      if (payload.sectionHeight !== stickySectionsHeightsState.stickySectionsHeights[payload.sectionKey]?.height) {
        dispatch({
          type: setActionBySectionKey[payload.sectionKey],
          payload,
        });
      }

      if (payload.sectionHeight === stickySectionsHeightsState.stickySectionsHeights[payload.sectionKey]?.height) {
        dispatch({
          type: setActionBySectionKey[payload.sectionKey],
          payload,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getOffset = useCallback(
    (sectionKey: StickySectionKeysType) => {
      return Object.keys(stickySectionsHeightsState.stickySectionsHeights).reduce((acc, currentValue) => {
        if (
          stickySectionsHeightsState.stickySectionsHeights[sectionKey].priority >
            stickySectionsHeightsState.stickySectionsHeights[currentValue].priority &&
          stickySectionsHeightsState.stickySectionsHeights[currentValue].isVisibleOnCurrentPage
        ) {
          acc += stickySectionsHeightsState.stickySectionsHeights[currentValue].height;
        }
        return acc;
      }, 0);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [stickySectionsHeightsState]
  );

  const getCurrentPageStickySectionsHeight = useCallback(() => {
    return Object.keys(stickySectionsHeightsState.stickySectionsHeights).reduce((acc, currentValue) => {
      if (stickySectionsHeightsState.stickySectionsHeights[currentValue].isVisibleOnCurrentPage) {
        acc += stickySectionsHeightsState.stickySectionsHeights[currentValue].height;
      }
      return acc;
    }, 0);
  }, [stickySectionsHeightsState]);

  return (
    <StickySectionsHeightsStateContext.Provider
      value={{
        setStickySectionHeight,
        getOffset,
        getCurrentPageStickySectionsHeight,
      }}
    >
      {children}
    </StickySectionsHeightsStateContext.Provider>
  );
};
