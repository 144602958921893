import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { contactMsrActionConstants, contactMsr } from 'store/msr/actions';
import { IAction } from 'types/actions';

export interface IContactMsrPayload {
  sku: string;
  onSuccessCallback?: () => void;
  onFailCallback?: () => void;
}

export function* contactMsrHandler({ payload: { sku } }: IAction<IContactMsrPayload>) {
  const url = `${Endpoints.CONTACT_MSR}/${sku}`;
  yield put(doRequest(contactMsr, url, 'get'));
}

export function* contactMsrOnSuccessCallBackSagaHandler({ payload }: IAction<IContactMsrPayload>) {
  yield take(contactMsrActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export function* contactMsrOnFailCallbackSagaHandler({ payload }: IAction<IContactMsrPayload>) {
  yield take(contactMsrActionConstants[Actions.FAIL]);
  if (payload.onFailCallback) {
    payload.onFailCallback();
  }
}

export default function* contactMsrSaga() {
  yield takeLatest(contactMsrActionConstants[Actions.REQUEST], contactMsrHandler);
  yield takeLatest(contactMsrActionConstants[Actions.REQUEST], contactMsrOnSuccessCallBackSagaHandler);
  yield takeLatest(contactMsrActionConstants[Actions.REQUEST], contactMsrOnFailCallbackSagaHandler);
}
