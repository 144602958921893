import { CorCardFAQ, CorCardWithPublicationDate, CorSmallCardTitleCategory } from 'corporate-components/cor-cards';
import { IAlgoliaContentTypesEnum, IAlgoliaTagsEnum } from 'queries/use-algolia-query';

export const SearchResultsContentTypeComponentsMap = new Map([
  [IAlgoliaContentTypesEnum.InsightsDetailPage, CorCardWithPublicationDate],
  [IAlgoliaContentTypesEnum.RecipeDetailPage, CorSmallCardTitleCategory],
]);

export const SearchResultsTagsComponentsMap = new Map([
  [IAlgoliaTagsEnum.FAQ, CorCardFAQ],
  [IAlgoliaTagsEnum.CorporateProducts, CorSmallCardTitleCategory],
]);
