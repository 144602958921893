import { FC } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { Roles } from 'constants/roles.enum';
import { IAccountLocation } from 'types/locations';
import { IUserAtLocationInfo } from 'types/user-info';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { ContentstackHtml, ContentstackText } from 'components/contentstack';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { getFormattedDeliveryDate } from 'utils/date-format';
import { selectUserRole } from 'store/auth/selectors';
import { selectActiveEditUserId } from 'store/account/selectors';
import { setActiveEditUserId } from 'store/account/actions';
import { Subscriptions } from 'pages/account/components/subscriptions';
import iconTrashBin from 'assets/images/icons/icon-trash-bin.svg';
import iconTrashBinDisabled from 'assets/images/icons/icon-trash-bin-gray.svg';
import iconPencil from 'assets/images/icons/icon-pencil.svg';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './location-users-info-modal.scss';

export interface ILocationUsersInfoModalProps extends IModalProps {
  location: IAccountLocation;
  onEditUserClick: (user: IUserAtLocationInfo | null) => void;
  resendInvitationEmail: (user: IUserAtLocationInfo | null) => void;
  onDeleteUserClick: (user: IUserAtLocationInfo | null) => void;
}

export const LocationUsersInfoModal: FC<ILocationUsersInfoModalProps> = ({
  isOpen,
  onClose,
  location,
  onEditUserClick,
  onDeleteUserClick,
  resendInvitationEmail,
}) => {
  const contentstackPath = 'modals.0.location_users_info_modal.0';
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();
  const { isMobile } = useBreakpoint();
  const userRole = useSelector(selectUserRole);
  const selectedUserId = useSelector(selectActiveEditUserId);
  const { admins = [], users = [], pendingUsers = [] } = location;

  const selectedUser = [...admins, ...users, ...pendingUsers].find((user) => user.id === selectedUserId) || null;

  const closeHandler = () => {
    onClose && onClose();
    dispatch(setActiveEditUserId(null));
  };

  const isPendingUser = (user: IUserAtLocationInfo | null) =>
    user && pendingUsers.some((pendingUser) => pendingUser.id === user.id);

  const renderUsersListsGroup = (listName: string, list: IUserAtLocationInfo[]) =>
    isEmpty(list) ? null : (
      <div className="location-users-info-modal__users-lists-group" key={listName}>
        <p className="location-users-info-modal__users-lists-group-title">{`${listName} (${list.length})`}</p>
        <ul className="location-users-info-modal__users-list">
          {list.map((user) => {
            const { id, firstName, lastName, email, isNew } = user;

            return (
              <li
                key={id}
                className={classNames('location-users-info-modal__users-list-item', {
                  active: selectedUser?.id === id,
                })}
                onClick={() => {
                  dispatch(setActiveEditUserId(user.id));
                }}
              >
                {isNew && <ContentstackText contentKey={`${contentstackPath}.new_user_mark`} />}
                {!isPendingUser(user) && firstName && lastName ? `${firstName} ${lastName}` : email}
              </li>
            );
          })}
        </ul>
      </div>
    );

  const renderUsersLists = () => (
    <div className="location-users-info-modal__users-lists">
      {[
        renderUsersListsGroup(getContentByKey(`${contentstackPath}.user_group_titles.admins`, ''), admins),
        renderUsersListsGroup(getContentByKey(`${contentstackPath}.user_group_titles.users`, ''), users),
        renderUsersListsGroup(getContentByKey(`${contentstackPath}.user_group_titles.pending`, ''), pendingUsers),
      ]}
    </div>
  );

  const renderUserInfo = () => {
    if (!selectedUser) return null;
    const {
      firstName,
      lastName,
      locations,
      email,
      phoneNumber,
      accountType,
      registeredDate,
      hasOrderSubscription,
      hasQuoteSubscription,
    } = selectedUser;
    const isAdmin = [Roles.SUPER_ADMIN, Roles.ADMIN].some((role) => accountType?.includes(role));

    return (
      <div className="location-users-info-modal__user-info">
        <p className="location-users-info-modal__user-info-title">
          <ContentstackText contentKey={`${contentstackPath}.user_info.title`} />
        </p>
        {!isPendingUser(selectedUser) && firstName && lastName && (
          <p className="location-users-info-modal__user-info-name">{`${firstName} ${lastName}`}</p>
        )}
        <section className="location-users-info-modal__user-info-section">
          <p>
            <ContentstackText contentKey={`${contentstackPath}.user_info.location_access_label`} />
          </p>
          <div>
            {locations.map((location) => (
              <>
                {location.isDeleted ? (
                  <p className="location-users-info-modal__user-info-section--deleted" key={location.locationId}>
                    {location.name} <ContentstackText contentKey="common[0].deleted_location_label" />
                  </p>
                ) : (
                  <p key={location.locationId}>{location.name}</p>
                )}
              </>
            ))}
          </div>
        </section>
        <section className="location-users-info-modal__user-info-section">
          <p>
            <ContentstackText contentKey={`${contentstackPath}.user_info.contact_label`} />
          </p>
          <div>
            <p>{email}</p>
            <p>{phoneNumber}</p>
          </div>
        </section>
        {registeredDate && (
          <section className="location-users-info-modal__user-info-section">
            <p>
              <ContentstackText contentKey={`${contentstackPath}.user_info.registered_date_label`} />
            </p>
            <div>
              <p>{getFormattedDeliveryDate(registeredDate, true, false, true)}</p>
            </div>
          </section>
        )}
        {isAdmin && (
          <Subscriptions hasOrderSubscription={hasOrderSubscription} hasQuoteSubscription={hasQuoteSubscription} />
        )}
      </div>
    );
  };

  return (
    <Modal
      className="location-users-info-modal"
      size="medium_large"
      isOpen={isOpen}
      onClose={closeHandler}
      withBackdropClick
    >
      <ModalHeader>
        <ContentstackText contentKey={`${contentstackPath}.heading`} />
        <p>{location.name}</p>
      </ModalHeader>
      {isMobile ? (
        selectedUser ? (
          <>
            <div className="location-users-info-modal__content">
              <div className="location-users-info-modal__user-info-wrapper">
                {renderUserInfo()}
                {!userRole.includes(Roles.TSR) && (
                  <div className="location-users-info-modal__mobile-user-actions">
                    {selectedUser.isCanBeDeleted ? (
                      <button
                        className="button-reset location-users-info-modal__mobile-delete-user-button"
                        onClick={() => onDeleteUserClick(selectedUser)}
                      >
                        <img src={iconTrashBin} alt="delete-user-button" />
                      </button>
                    ) : (
                      <img
                        src={iconTrashBinDisabled}
                        alt="delete-user-button"
                        className="location-users-info-modal__mobile-delete-user-button--disabled"
                      />
                    )}
                    {!isPendingUser(selectedUser) && (
                      <button
                        className="button-reset location-users-info-modal__mobile-edit-user-button"
                        disabled={isEmpty(selectedUser)}
                        onClick={() => onEditUserClick(selectedUser)}
                      >
                        <img src={iconPencil} alt="edit-user-button" />
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <ModalFooter>
              <div className="location-users-info-modal__buttons">
                <CorButton
                  color="text"
                  className="location-users-info-modal__back-button"
                  onClick={() => {
                    dispatch(setActiveEditUserId(null));
                  }}
                >
                  <ContentstackText contentKey={`${contentstackPath}.back_button_label`} />
                </CorButton>
                {isPendingUser(selectedUser) ? (
                  <CorButton
                    className="location-users-info-modal__resend-email-button"
                    color="primary"
                    onClick={() => resendInvitationEmail(selectedUser)}
                  >
                    <ContentstackText contentKey={`${contentstackPath}.resend_invitation_button`} />
                  </CorButton>
                ) : (
                  <CorButton className="location-users-info-modal__close-button" onClick={closeHandler}>
                    <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
                  </CorButton>
                )}
              </div>
            </ModalFooter>
          </>
        ) : (
          <>
            <div className="location-users-info-modal__content">
              <p className="location-users-info-modal__description">
                <ContentstackText contentKey={`${contentstackPath}.description`} />
              </p>
              <div className="location-users-info-modal__info-section">{renderUsersLists()}</div>
              <p className="location-users-info-modal__caption">
                <ContentstackText contentKey={`${contentstackPath}.caption`} />
              </p>
            </div>
            <ModalFooter>
              <CorButton className="location-users-info-modal__close-button" onClick={closeHandler}>
                <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
              </CorButton>
            </ModalFooter>
          </>
        )
      ) : (
        <>
          <div className="location-users-info-modal__content">
            <p className="location-users-info-modal__description">
              <ContentstackText contentKey={`${contentstackPath}.description`} />
            </p>
            <div className="location-users-info-modal__info-section">
              {renderUsersLists()}
              {selectedUser ? (
                renderUserInfo()
              ) : (
                <div className="location-users-info-modal__guide">
                  <ContentstackHtml contentKey={`${contentstackPath}.guide`} />
                </div>
              )}
            </div>
            <p className="location-users-info-modal__caption">
              <ContentstackText contentKey={`${contentstackPath}.caption`} />
            </p>
          </div>
          <ModalFooter className="location-users-info-modal__footer">
            <div className="location-users-info-modal__buttons">
              {!userRole.includes(Roles.TSR) && (
                <div className="location-users-info-modal__desktop-user-actions">
                  <CorButton
                    color={isEmpty(selectedUser) ? 'primary' : 'secondary'}
                    disabled={isEmpty(selectedUser) || !selectedUser?.isCanBeDeleted}
                    onClick={() => onDeleteUserClick(selectedUser)}
                  >
                    <ContentstackText contentKey={`${contentstackPath}.delete_user_button_label`} />
                  </CorButton>
                  {!isPendingUser(selectedUser) && (
                    <CorButton
                      color={isEmpty(selectedUser) ? 'primary' : 'secondary'}
                      disabled={isEmpty(selectedUser)}
                      onClick={() => onEditUserClick(selectedUser)}
                    >
                      <ContentstackText contentKey={`${contentstackPath}.edit_user_info_button_label`} />
                    </CorButton>
                  )}
                  {isPendingUser(selectedUser) && (
                    <CorButton color="secondary" onClick={() => resendInvitationEmail(selectedUser)}>
                      <ContentstackText contentKey={`${contentstackPath}.resend_invitation_button`} />
                    </CorButton>
                  )}
                </div>
              )}
              <CorButton className="location-users-info-modal__close-button" onClick={closeHandler} aria-label="Close">
                <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
              </CorButton>
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
