import { pick, mapValues } from 'lodash-es';
import { IOrderSummary } from 'types/order-details';
import { formatCurrency } from 'utils/currency-format';

interface IFormatOrderSummaryParams {
  isSummaryAvailable: boolean;
  priceSummary: IOrderSummary;
  summaryNotAvailableStatus: string;
}

export default function formatOrderSummary({
  isSummaryAvailable,
  priceSummary,
  summaryNotAvailableStatus,
}: IFormatOrderSummaryParams) {
  const orderSummaryPrices = pick(priceSummary, [
    'total',
    'subTotal',
    'additionalCharges',
    'shippingSurcharge',
    'totalTaxes',
    'totalDiscounts',
  ]);
  const orderSummary = isSummaryAvailable
    ? {
        ...mapValues(orderSummaryPrices, (price) => price && formatCurrency(price)),
      }
    : {
        ...mapValues(orderSummaryPrices, () => summaryNotAvailableStatus),
        subTotal: formatCurrency(orderSummaryPrices.subTotal),
      };
  return {
    ...orderSummary,
    totalItemsQuantity: priceSummary.totalItemsQuantity,
    discountsExtended: priceSummary.discountsExtended,
    additionalChargesExtended: priceSummary.additionalChargesExtended,
  };
}
