import {
  HostedPaymentFieldsErrorCodes,
  HostedPaymentFieldsErrorCodesDescriptions,
  InvalidHostedPaymentFieldMsg,
} from './hosted-payment-fields-error-codes.enum';

export interface IErrorCodeItems {
  code: string;
  description: string;
}
export interface IInvalidErrorMgs {
  ccn: string;
  cvv: string;
  exp: string;
}

export interface IPaymentsFieldsErrorCodes {
  EMPTY: IErrorCodeItems;
  INVALID: IErrorCodeItems;
  EXPIRED_TOKEN: IErrorCodeItems;
  CC_TYPE_NOT_SUPPORTED: IErrorCodeItems;
  INVALID_CC_NUMBER: IErrorCodeItems;
  TOKEN_NOT_FOUND: IErrorCodeItems;
  NO_PAYMENT_DETAILS_LINKED_TO_TOKEN: IErrorCodeItems;
}

export const HostedPaymentFieldInvalidMsg: IInvalidErrorMgs = {
  ccn: InvalidHostedPaymentFieldMsg.CCN,
  cvv: InvalidHostedPaymentFieldMsg.CVV,
  exp: InvalidHostedPaymentFieldMsg.EXP,
};

export const HostedFieldsPaymentErrorCodes: IPaymentsFieldsErrorCodes = {
  EMPTY: {
    code: HostedPaymentFieldsErrorCodes.EMPTY,
    description: HostedPaymentFieldsErrorCodesDescriptions.EMPTY,
  },
  INVALID: {
    code: HostedPaymentFieldsErrorCodes.INVALID,
    description: HostedPaymentFieldsErrorCodesDescriptions.INVALID,
  },
  EXPIRED_TOKEN: {
    code: HostedPaymentFieldsErrorCodes.EXPIRED_TOKEN,
    description: HostedPaymentFieldsErrorCodesDescriptions.EXPIRED_TOKEN,
  },
  CC_TYPE_NOT_SUPPORTED: {
    code: HostedPaymentFieldsErrorCodes.CC_TYPE_NOT_SUPPORTED,
    description: HostedPaymentFieldsErrorCodesDescriptions.CC_TYPE_NOT_SUPPORTED,
  },
  INVALID_CC_NUMBER: {
    code: HostedPaymentFieldsErrorCodes.INVALID_CC_NUMBER,
    description: HostedPaymentFieldsErrorCodesDescriptions.INVALID_CC_NUMBER,
  },
  TOKEN_NOT_FOUND: {
    code: HostedPaymentFieldsErrorCodes.TOKEN_NOT_FOUND,
    description: HostedPaymentFieldsErrorCodesDescriptions.TOKEN_NOT_FOUND,
  },
  NO_PAYMENT_DETAILS_LINKED_TO_TOKEN: {
    code: HostedPaymentFieldsErrorCodes.NO_PAYMENT_DETAILS_LINKED_TO_TOKEN,
    description: HostedPaymentFieldsErrorCodesDescriptions.NO_PAYMENT_DETAILS_LINKED_TO_TOKEN,
  },
};
