export enum HostedPaymentFieldsErrorCodes {
  EMPTY = '10',
  INVALID = '10',
  EXPIRED_TOKEN = '14040',
  CC_TYPE_NOT_SUPPORTED = '22013',
  INVALID_CC_NUMBER = '14002',
  TOKEN_NOT_FOUND = '14041',
  NO_PAYMENT_DETAILS_LINKED_TO_TOKEN = '14042',
}

export enum HostedPaymentFieldsErrorCodesDescriptions {
  EMPTY = 'empty',
  INVALID = 'invalid',
  EXPIRED_TOKEN = 'Token is expired',
  CC_TYPE_NOT_SUPPORTED = 'CC type is not supported by the merchant',
  INVALID_CC_NUMBER = 'invalid CC number',
  TOKEN_NOT_FOUND = 'Could not find token',
  NO_PAYMENT_DETAILS_LINKED_TO_TOKEN = 'Token is not associated with a payment method, please verify your client integration or contact BlueSnap support.',
}

export enum InvalidHostedPaymentFieldMsg {
  CCN = 'MSG197',
  CVV = 'MSG198',
  EXP = 'MSG199',
}
