import { FC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from 'store';
import { selectIsAuthorised } from 'store/auth/selectors';
import { Routes } from 'constants/routes.enum';
import { useGetTranslatedUrl } from 'hooks/use-get-translated-url';

export const PrivateRoute: FC<RouteProps> = ({ render, ...rest }) => {
  const isAuthorised = useSelector(selectIsAuthorised);
  const { getTranslatedUrl } = useGetTranslatedUrl();
  const loginPageUrl = getTranslatedUrl({
    usEnglishUrl: Routes.LoginPage,
    caFrenchUrl: Routes.CanadaFrenchLoginPage,
  });

  const renderFunction = isAuthorised
    ? render
    : () => (
        <Redirect
          to={{
            pathname: loginPageUrl,
            state: { from: history.location },
          }}
        />
      );

  return <Route {...rest} render={renderFunction} />;
};
