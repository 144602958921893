enum customerLoginActionTypes {
  SUBMIT_LOGIN = 'SUBMIT_LOGIN',
  GET_CUSTOMER_LOCATIONS = 'GET_CUSTOMER_LOCATIONS',
  SUBMIT_LOCATION = 'SUBMIT_LOCATION',
  SUBMIT_LOCATION_INVOICE_ONLY = 'SUBMIT_LOCATION_INVOICE_ONLY',
  CLEAR_SERVER_ERRORS = 'CLEAR_SERVER_ERRORS',
  GET_ANALYTICS_VARIABLES = 'GET_ANALYTICS_VARIABLES',
  SET_CLIENT_IDLE_STATE = 'SET_CLIENT_IDLE_STATE',
  RESET_LOGIN_ATTEMPT_COUNTER = 'RESET_LOGIN_ATTEMPT_COUNTER',
}

export default customerLoginActionTypes;
