import { createReducer } from 'store/reducer-creator';
import types from './action-types';

export interface IGeolocationRedirect {
  isOpen: boolean;
  countrySite?: string | null;
  regionSite: string | null;
}

export const geolocationRedirectInitialState: IGeolocationRedirect = {
  isOpen: false,
  countrySite: null,
  regionSite: null,
};

const geolocationRedirect = createReducer<IGeolocationRedirect>(
  {
    [types.SHOW_GEOLOCATION_REDIRECT_MODAL]: (state, { payload }) => payload,
    [types.HIDE_GEOLOCATION_REDIRECT_MODAL]: () => geolocationRedirectInitialState,
  },
  geolocationRedirectInitialState
);

export default geolocationRedirect;
