export const LIVE_PREVIEW_SHOP_URL = '/shop';

export enum ShopLivePreviewUrls {
  Shop_Home = 'home',
  Home_Banner = 'a-spot-banner',
  Home_Order_Updates = 'order-updates',
  Home_Products_Carousel = 'products-carousel',
  Home_Navigation_Tiles_List = 'navigation-tiles-list',
  Home_Buy_It_Again = 'buy-it-again',
  Home_Shopping_List = 'shopping-list',
  Home_News_Notifications = 'news-and-notifications',
  Home_News_Notification_Item = 'news-and-notification-item',
  Home_Order_Cutoff_Notification = 'order-cutoff-notification',
}

export const ShopContentTypes = new Map([
  [ShopLivePreviewUrls.Shop_Home, 'home_page'],
  [ShopLivePreviewUrls.Home_Banner, 'a_spot_banner'],
  [ShopLivePreviewUrls.Home_Order_Updates, 'order_updates'],
  [ShopLivePreviewUrls.Home_Products_Carousel, 'products-carousel'],
  [ShopLivePreviewUrls.Home_Navigation_Tiles_List, 'navigation_tiles_list'],
  [ShopLivePreviewUrls.Home_Buy_It_Again, 'buy_it_again'],
  [ShopLivePreviewUrls.Home_Shopping_List, 'shopping_list'],
  [ShopLivePreviewUrls.Home_News_Notifications, 'news_section'],
  [ShopLivePreviewUrls.Home_News_Notification_Item, 'news_and_notifications'],
  [ShopLivePreviewUrls.Home_Order_Cutoff_Notification, 'order_cutoff_notification'],
]);
