import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { reviewQuote, reviewQuoteActionConstants } from 'store/quotes/actions';
import { IAction } from 'types/actions';

interface IReviewQuotesPayload {
  quoteNumber: string;
  onSuccessCallback?: () => void;
}

export function* reviewQuoteSagaHandler({ payload: { quoteNumber } }: IAction<IReviewQuotesPayload>) {
  yield put(doRequest(reviewQuote, `${Endpoints.DASHBOARD_QUOTES}/${quoteNumber}/review`, 'patch'));
}

export function* reviewQuoteSuccessSagaHandler({ payload }: IAction<IReviewQuotesPayload>) {
  yield take(reviewQuoteActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    yield payload.onSuccessCallback();
  }
}

export default function* reviewQuoteQuoteSaga() {
  yield takeLatest(reviewQuoteActionConstants[Actions.REQUEST], reviewQuoteSagaHandler);
  yield takeLatest(reviewQuoteActionConstants[Actions.REQUEST], reviewQuoteSuccessSagaHandler);
}
