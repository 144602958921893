import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectGeolocationRedirect } from 'store/geolocation-modal/selector';
import { useGeolocationRedirectModal, useHideGlobalModalHandler } from 'hooks/use-global-modal';
import { hideGeolocationRedirect } from 'store/geolocation-modal/actions';

export const StandardPage: FC<PropsWithChildren> = ({ children }) => {
  const { isOpen, countrySite, regionSite } = useSelector(selectGeolocationRedirect);
  const closeGlobalModal = useHideGlobalModalHandler();
  const dispatch = useDispatch();
  const showGeolocationRedirectModal = useGeolocationRedirectModal({
    isOpen,
    countrySite: countrySite || '',
    regionSite: regionSite || '',
    onClose: () => {
      closeGlobalModal();
      dispatch(hideGeolocationRedirect());
    },
  });

  useEffect(() => {
    if (isOpen) {
      showGeolocationRedirectModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return <>{children || null}</>;
};
