import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { submitPadPaymentActionConstants, submitPadPayment } from 'store/payment-transactions/actions';
import { IPaymentDocument } from 'components/modals/schedule-payment-modal';

export interface ISubmitPadPaymentPayload {
  accountType: string;
  depositAmount?: number;
  depositComment?: string;
  isDeposit?: boolean;
  paymentDocuments?: IPaymentDocument[];
  publicAccountNumber: string;
  routingNumber: string;
  scheduledDate?: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: (messageId: string) => void;
}

export function* submitPadPaymentSuccessSagaHandler({ payload }: IAction<ISubmitPadPaymentPayload>) {
  yield take(submitPadPaymentActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* submitPadPaymentFailSagaHandler({ payload }: IAction<ISubmitPadPaymentPayload>) {
  const { error } = yield take(submitPadPaymentActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack && error) {
    payload.onFailCallBack(error.errorMessageId);
  }
}

export function* submitPadPaymentSagaHandler({
  payload: {
    paymentDocuments,
    accountType,
    publicAccountNumber,
    routingNumber,
    scheduledDate,
    isDeposit,
    depositComment,
    depositAmount,
  },
}: IAction<ISubmitPadPaymentPayload>) {
  yield put(
    doRequest<ISubmitPadPaymentPayload>(submitPadPayment, Endpoints.SUBMIT_PAD_PAYMENT, 'post', {
      paymentDocuments,
      accountType,
      publicAccountNumber,
      routingNumber,
      scheduledDate,
      isDeposit,
      depositComment,
      depositAmount,
    })
  );
}

export default function* submitPadPaymentSaga() {
  yield takeLatest(submitPadPaymentActionConstants[Actions.REQUEST], submitPadPaymentSuccessSagaHandler);
  yield takeLatest(submitPadPaymentActionConstants[Actions.REQUEST], submitPadPaymentFailSagaHandler);
  yield takeLatest(submitPadPaymentActionConstants[Actions.REQUEST], submitPadPaymentSagaHandler);
}
