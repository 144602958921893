import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getAnalyticsVariables, getAnalyticsVariablesActionConstants } from 'store/customer-login/actions';
import { cacheAll as cacheAnalyticsPreloads } from 'utils/analytics/cache-preload';
import { clearCachedParams } from 'utils/analytics';

export interface ISessionParamsPayload {
  name: string;
  locationId: string;
  locationNumber: string;
  nextOrderDueDate: string;
}

export function* loadGASessionParams() {
  clearCachedParams();
  try {
    yield put(getAnalyticsVariables.request());
    const preloads = yield take(getAnalyticsVariablesActionConstants[Actions.SUCCESS]);
    cacheAnalyticsPreloads(preloads.payload);
  } catch (error) {
    // TODO: do we have a pattern for handling/reporting this kind of error?
    console.error('GA preload fetch failed', error);
  }
}

export function* getAnalyticsVariablesRequestHandler() {
  yield put(doRequest(getAnalyticsVariables, Endpoints.ANALYTICS_PRELOAD, 'get'));
}

export default function* getAnalyticsVariablesSaga() {
  yield takeLatest(getAnalyticsVariablesActionConstants[Actions.REQUEST], getAnalyticsVariablesRequestHandler);
}
