import './cor-filter-expandable-section.scss';

import React from 'react';
import { ExpandableSection } from 'components/expandable-section';
import { IconWithHover } from 'components/icon-with-hover';
import iconCaretRed from 'assets/images/icons/icon-caret-down-red.svg';

const CorFilterExpandableSection = ({
  children,
  buttonLabel,
  isDefaultExpanded = false,
}: React.PropsWithChildren<{ buttonLabel?: string; isDefaultExpanded?: boolean }>) => {
  return (
    <ExpandableSection
      forceIsExpanded={isDefaultExpanded}
      title={buttonLabel}
      className="cor-filter-expandable-section"
      unmountOnExit={false}
      ExpandableIcon={
        <IconWithHover
          icon={iconCaretRed}
          hoverIcon={iconCaretRed}
          alt="Expandable Icon"
          className="expandable-section__custom-expand-icon"
        />
      }
    >
      {children}
    </ExpandableSection>
  );
};

export default React.memo(CorFilterExpandableSection);
