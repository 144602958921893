import { put, take, takeLatest } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { createEmptyShoppingList, createEmptyShoppingListActionConstants } from 'store/shopping-lists/actions';

export interface ICreateEmptyShoppingListPayload {
  name: string;
  onSuccessCallback: (shoppingListName: string, id: string) => void;
  onFailCallback: (status: number) => void;
}

export function* createEmptyShoppingListSuccessSagaHandler({ payload }: IAction<ICreateEmptyShoppingListPayload>) {
  const {
    payload: { id },
  } = yield take(createEmptyShoppingListActionConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallback) {
    payload.onSuccessCallback(payload.name, id);
  }
}

export function* createEmptyShoppingListFailSagaHandler({ payload }: IAction<ICreateEmptyShoppingListPayload>) {
  const { error } = yield take(createEmptyShoppingListActionConstants[Actions.FAIL]);
  if (payload.onFailCallback && error) {
    payload.onFailCallback(error.status);
  }
}

export function* createEmptyShoppingListHandler({ payload: { name } }: IAction<ICreateEmptyShoppingListPayload>) {
  yield put(doRequest(createEmptyShoppingList, Endpoints.SHOPPING_LISTS, 'post', { name }));
}

export default function* createEmptyShoppingListSaga() {
  yield takeLatest(createEmptyShoppingListActionConstants[Actions.REQUEST], createEmptyShoppingListHandler);
  yield takeLatest(createEmptyShoppingListActionConstants[Actions.REQUEST], createEmptyShoppingListSuccessSagaHandler);

  yield takeLatest(createEmptyShoppingListActionConstants[Actions.REQUEST], createEmptyShoppingListFailSagaHandler);
}
