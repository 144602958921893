import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';

export const selectCheckoutInfo = (state: IState) => state.app.checkoutInfo;

export const selectGeneralErrorMessage = createSelector(
  selectCheckoutInfo,
  (checkoutInfo) => checkoutInfo.generalErrorMessage
);

export const selectLineItemsFormatted = createSelector(selectCheckoutInfo, ({ lineItems }) => {
  return lineItems.map((item) => ({
    sku: item.itemNumber,
    quantity: item.quantity,
  }));
});

export const selectAdditionalChargesExtended = createSelector(
  selectCheckoutInfo,
  (checkoutInfo) => checkoutInfo.priceSummary.additionalChargesExtended
);
