import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getInvoicesTotalAmount, getInvoicesTotalAmountActionConstants } from 'store/invoices/actions';

export interface IGetInovicesSummaryPayload {
  invoiceBalances: number[];
}

export function* getInvoicesTotalAmountSagaHandler({ payload }: IAction<IGetInovicesSummaryPayload>) {
  yield put(
    doRequest<IGetInovicesSummaryPayload>(getInvoicesTotalAmount, Endpoints.INVOICES_TOTAL_AMOUNT, 'post', payload)
  );
}

export default function* getInvoicesTotalAmountSaga() {
  yield takeLatest(getInvoicesTotalAmountActionConstants[Actions.REQUEST], getInvoicesTotalAmountSagaHandler);
}
