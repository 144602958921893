import { all, spawn } from 'redux-saga/effects';
import getCartCutOffNotificationSaga from './get-cutoff-notification-timer';
import cutoffNotificationViewedSaga from './cutoff-notification-viewed';
import getCartExpiredNotificationViewedSaga from './get-cart-expired-notification-viewed';

export default function* orderCutOffSagas() {
  yield all([
    spawn(getCartCutOffNotificationSaga),
    spawn(cutoffNotificationViewedSaga),
    spawn(getCartExpiredNotificationViewedSaga),
  ]);
}
