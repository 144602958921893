import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getSurcharge, getSurchargeActionConstants } from 'store/invoices/actions';

export interface IGetSurchargeOnlyPayload {
  invoiceBalances: number[];
  paymentMethod: string;
  surchargesOnly: boolean;
}

export function* getSurchargeSagaHandler({ payload }: IAction<IGetSurchargeOnlyPayload>) {
  yield put(
    doRequest<IGetSurchargeOnlyPayload>(
      getSurcharge,
      `${Endpoints.INVOICES_SUMMARY}?includeSurcharges=true&surchargesOnly=true`,
      'post',
      payload
    )
  );
}

export default function* getSurchargeSummarySaga() {
  yield takeLatest(getSurchargeActionConstants[Actions.REQUEST], getSurchargeSagaHandler);
}
