import { Routes } from 'constants/routes.enum';
import { shopCountriesAllowed, shopLanguagesAllowed } from 'constants/shop-locales';

export const checkIfAccountUrlValid = (pathname: string): boolean => {
  let isAccountPathnameValid = true;
  if (pathname.includes(Routes.ProfilePage)) {
    const pathnameWithoutLocale = pathname
      .split('/')
      .filter((value) => ![...shopCountriesAllowed, ...shopLanguagesAllowed].includes(value))
      .join('/');
    isAccountPathnameValid = Object.values(Routes).includes(pathnameWithoutLocale as Routes);
  }
  return isAccountPathnameValid;
};
