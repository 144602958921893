import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { selectPaymentMethodLoading } from 'store/payment-methods/selectors';
import { deleteCreditCard, getPaymentMethods } from 'store/payment-methods/actions';
import { IDeleteCreditCardPayload } from 'store/payment-methods/saga/delete-credit-card';
import { useAddOrRemovePaymentMethodSuccesOrErrorModal } from 'hooks/use-add-or-remove-payment-method-success-or-error-modal';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { ContentstackText } from 'components/contentstack';
import { formatPaymentAccountNumber } from 'utils/format-payment-account-number';
import { CreditCardImageUrl } from 'constants/credit-card-type-imge-url';
import { LoadingIndicator } from 'components/loading-indicator';
import { CorButton } from 'components/cor-button';
import { formatMonth, formatYear } from 'utils/format-payment-method-date-display';

import './remove-payment-card-modal.scss';

export interface IRemovePaymentCardModalProps extends IModalProps {
  cardLastFourDigits: string;
  cardType: string;
  expirationMonth: string;
  expirationYear: string;
}

export const RemovePaymentCardModal: FC<IRemovePaymentCardModalProps> = ({
  isOpen = false,
  onClose = () => {},
  cardLastFourDigits,
  cardType,
  expirationMonth,
  expirationYear,
}) => {
  const parentClassName = 'remove-payment-card-modal';
  const contentStackPath = 'modals.[0].remove_payment_card_modal[0]';
  const { isMobile, isDesktop } = useBreakpoint();
  const [isRemovePaymentCardSubmitted, setIsRemovePaymentCardSubmitted] = useState(false);
  const dispatch = useDispatch();
  const formattedMonth = formatMonth(expirationMonth);
  const formattedYear = formatYear(expirationYear);
  const isPaymentMethodLoading = useSelector(selectPaymentMethodLoading);

  const {
    removePaymentMethodSuccessModal,
    removePaymentMethodFailModal,
  } = useAddOrRemovePaymentMethodSuccesOrErrorModal();

  const deleteCreditCardHandler = () => {
    if (isRemovePaymentCardSubmitted) return;
    dispatch(
      deleteCreditCard.request<IDeleteCreditCardPayload>({
        cardLastFourDigits: cardLastFourDigits,
        cardType: cardType,
        onFailCallBack: removePaymentMethodFailModal,
        onSuccessCallBack: removePaymentMethodSuccessModal,
      })
    );
    dispatch(getPaymentMethods.request());
    setIsRemovePaymentCardSubmitted(true);
  };

  return (
    <Modal
      size="medium"
      parentClassName={parentClassName}
      isOpen={isOpen}
      onClose={onClose}
      withBackdropClick={!isPaymentMethodLoading}
      hideCloseButton={isPaymentMethodLoading}
    >
      {isPaymentMethodLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ModalHeader>
            <ContentstackText contentKey={`${contentStackPath}.header`} />
          </ModalHeader>
          <div className={`${parentClassName}__content`}>
            <div className={`${parentClassName}__description`}>
              <ContentstackText contentKey={`${contentStackPath}.description`} />
            </div>
            <div className={`${parentClassName}__card-details`}>
              <div className={`${parentClassName}__card-labels`}>
                <div className={`${parentClassName}__label`}>
                  <ContentstackText
                    contentKey={`${contentStackPath}.${isMobile ? 'mobile_' : ''}account_number_label`}
                  />
                </div>

                <div className={`${parentClassName}__label`}>
                  {isMobile ? (
                    <div className={`${parentClassName}__card-img`}>
                      <img src={CreditCardImageUrl[cardType]} alt="cardType" />
                    </div>
                  ) : (
                    <ContentstackText contentKey={`${contentStackPath}.card_type_label`} />
                  )}
                </div>
                <div className={`${parentClassName}__label`}>
                  <ContentstackText contentKey={`${contentStackPath}.expiration_date_label`} />
                </div>
              </div>

              <div className={`${parentClassName}__card-data`}>
                <div className={`${parentClassName}__value`}>{formatPaymentAccountNumber(cardLastFourDigits)}</div>
                <div className={`${parentClassName}__card-type`}>
                  {isDesktop && (
                    <div className={`${parentClassName}__card-img`}>
                      <img src={CreditCardImageUrl[cardType]} alt="cardType" />
                    </div>
                  )}
                  <div className={`${parentClassName}__value`}>{cardType}</div>
                </div>
                <div className={`${parentClassName}__value`}>
                  <ContentstackText
                    contentKey={`${contentStackPath}.expiration_date`}
                    interpolateParams={{ expirationMonth: formattedMonth, expirationYear: formattedYear }}
                  />
                </div>
              </div>
            </div>
            <ModalFooter className={`${parentClassName}__buttons`}>
              <div className={`${parentClassName}__cancel-button`} onClick={onClose}>
                <ContentstackText contentKey={`${contentStackPath}.cancel_button_label`} />
              </div>
              <div className={`${parentClassName}__remove`}>
                <CorButton onClick={deleteCreditCardHandler} disabled={isRemovePaymentCardSubmitted}>
                  <ContentstackText contentKey={`${contentStackPath}.remove_button_label`} />
                </CorButton>
              </div>
            </ModalFooter>
          </div>
        </>
      )}
    </Modal>
  );
};
