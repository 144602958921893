import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addProductToCart } from 'store/shopping-cart/actions';
import { IAddProductToCartPayload } from 'store/shopping-cart/sagas/add-product-to-cart';

export const useAddToCart = () => {
  const dispatch = useDispatch();
  const [isAddToCartButtonActive, setIsAddToCartButtonActive] = useState(false);
  const [isAddToCartButtonDisabled, setIsAddToCartButtonDisabled] = useState(false);

  const addToCart = (sku: string, quantity: number = 1) => {
    dispatch(
      addProductToCart.request<IAddProductToCartPayload>({ quantity, sku })
    );
  };

  const createAddToCartClickHandler = (sku: string, quantity?: number) => () => {
    setIsAddToCartButtonActive(true);
    addToCart(sku, quantity);
    setIsAddToCartButtonDisabled(true);
    setTimeout(() => {
      setIsAddToCartButtonActive(false);
      setIsAddToCartButtonDisabled(false);
    }, 1000);
  };

  return {
    isAddToCartButtonActive,
    isAddToCartButtonDisabled,
    createAddToCartClickHandler,
  };
};
