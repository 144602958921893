import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getLocationsForMsm, getLocationsForMsmConstants } from 'store/dashboard/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IGetLocationsByDayOfWeekPayload {
  searchText?: string;
}

export function* getLocationsForMsmHandler({ payload: { searchText = '' } }: IAction<IGetLocationsByDayOfWeekPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText?.trim());

  yield put(doRequest(getLocationsForMsm, `${Endpoints.DASHBOARD_MSM_LOCATIONS}/?searchText=${searchQuery}`, 'get'));
}

export default function* getLocationsForMsmSaga() {
  yield takeLatest(getLocationsForMsmConstants[Actions.REQUEST], getLocationsForMsmHandler);
}
