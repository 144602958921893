import './cor-filter-button.scss';

import React from 'react';
import classNames from 'classnames';

const CorFilterButton = ({ buttonLabel = '', isSelected = false, handleOnClick = () => {} }) => {
  return (
    <button
      className={classNames(
        'search-results-page__filter-button',
        {
          'search-results-page__filter-button--selected': isSelected,
        },
        'cor-typography',
        'cor-typography__button-label'
      )}
      onClick={handleOnClick}
      data-testid="search-results-page__filter-button"
    >
      {buttonLabel}
    </button>
  );
};

export default React.memo(CorFilterButton);
