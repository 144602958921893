import './cor-filter-button.scss';

import React from 'react';
import classNames from 'classnames';
import { useAlgoliaQueryParams } from 'hooks';
import { ALGOLIA_DEFAULT_PAGE_NUMBER } from 'hooks/use-algolia';
import { IconWithHover } from 'components/icon-with-hover';
import iconCaretDown from 'assets/images/icons/icon-caret-down-grey.svg';
import { ICorFilterButtonProps } from './cor-filter-button.interface';

const CorFilterButton = (
  {
    isDropdown = false,
    isDropdownOpened = false,
    handleToggleDropdown = () => {},
    buttonLabel = '',
    facetKey,
    facetTerms = [],
  }: ICorFilterButtonProps,
  ref: any
) => {
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const [isButtonOpened, setIsButtonOpened] = React.useState(false);

  const handleToggleButton = React.useCallback(() => setIsButtonOpened((prev) => !prev), []);

  const isOpened = React.useMemo(() => (isDropdown ? isDropdownOpened : isButtonOpened), [
    isDropdown,
    isDropdownOpened,
    isButtonOpened,
  ]);

  const isSelected = React.useMemo(() => !!JSON.parse(queryParams?.facets?.[facetKey as string] || '[]').length, [
    facetKey,
    queryParams?.facets,
  ]);

  const handleToggle = React.useCallback(() => (isDropdown ? handleToggleDropdown() : handleToggleButton()), [
    handleToggleButton,
    handleToggleDropdown,
    isDropdown,
  ]);

  const handleButtonClick = React.useCallback(() => {
    handleToggle();

    if (isDropdown) return;
    setQueryParams({
      facets: {
        ...(queryParams?.facets || {}),
        [facetKey as string]: isSelected ? undefined : JSON.stringify([facetTerms?.[0]]),
      },
      page: ALGOLIA_DEFAULT_PAGE_NUMBER,
    });
  }, [facetKey, facetTerms, handleToggle, isDropdown, isSelected, queryParams?.facets, setQueryParams]);

  return (
    <button
      ref={ref}
      className={classNames('cor-filter-button', {
        'cor-filter-button--opened': isDropdown && isOpened,
        'cor-filter-button--selected': isSelected,
      })}
      onClick={handleButtonClick}
    >
      {buttonLabel}
      {isDropdown && <IconWithHover icon={iconCaretDown} hoverIcon={iconCaretDown} alt="Filter Caret" />}
    </button>
  );
};

export default React.forwardRef(CorFilterButton);
