import { isEmpty } from 'lodash-es';
import { getErrorMessageKeys } from 'utils/cart-messages';
import { ICartInfo } from 'store/shopping-cart/reducer';

export const getCartInfoSummary = (cartInfo: ICartInfo) => {
  const {
    addedItemsCounter,
    specialOrderSkus,
    deactivatedSkus,
    originalToReplacementSkusMap,
    unavailableProprietarySkus,
    exceededMaxQuantitySkus,
    shortSupplyItems,
    outOfStockSkus,
  } = cartInfo;
  const errors = getErrorMessageKeys(cartInfo);

  return {
    addedItemsCounter,
    errors,
    hasAddedItems: addedItemsCounter > 0,
    hasErrors: errors.length > 0,
    hasSomeInvalidItems: [
      specialOrderSkus,
      deactivatedSkus,
      originalToReplacementSkusMap,
      unavailableProprietarySkus,
      exceededMaxQuantitySkus,
      shortSupplyItems,
      outOfStockSkus,
    ].some((list) => !isEmpty(list)),
  };
};
