import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollTopOnClick = () => {
  const { pathname } = useLocation();
  const clickHandler = useCallback(
    ($event: React.SyntheticEvent<HTMLAnchorElement>, targetPathname) => {
      if (pathname !== targetPathname) {
        return;
      }
      $event.preventDefault();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    [pathname]
  );

  return clickHandler;
};
