import { ISearchKey } from 'types/guest';
import { createReducer } from 'store/reducer-creator';
import { Actions } from 'constants/actions.enum';
import { getSearchKeysActionConstants } from 'store/guest/actions';

export interface IGuestState {
  keys: ISearchKey;
  loading: boolean;
}

export const guestInitialState: IGuestState = {
  keys: {} as ISearchKey,
  loading: false,
};

const guest = createReducer<IGuestState>(
  {
    [getSearchKeysActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [getSearchKeysActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      keys: payload,
      loading: false,
    }),
    [getSearchKeysActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  guestInitialState
);

export default guest;
