import { IPriceModel } from 'types/product';

export const formatCurrency = (model?: IPriceModel): string => {
  return model ? `${model.currencySymbol}${Number(model.amount).toFixed(2)}` : '';
};

export const formatCurrencyLocalized = (model?: IPriceModel): string => {
  return model ? `${Number(model.amount).toFixed(2)} ${model.currencySymbol}`.replace('.', ',') : '';
};

export const formatSegmentationCurrency = (model: IPriceModel): string => {
  return `${model.currencySymbol}${Number(model.segmentationAmount).toFixed(2)}`;
};

export const relocateCurrencyMinusSign = (currency: string): string => {
  const symbolToRelocate = '-';

  return currency.includes(symbolToRelocate)
    ? `${symbolToRelocate}${currency.replace(symbolToRelocate, '')}`
    : currency;
};
