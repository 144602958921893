import { FC } from 'react';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './cancel-order-confirm-modal.scss';

export interface ICancelOrderConfirmModalProps extends IModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOrderCancel: () => void;
}

export const CancelOrderConfirmModal: FC<ICancelOrderConfirmModalProps> = ({
  isOpen = false,
  onClose = () => {},
  onOrderCancel,
}) => {
  const contentstackPath = 'modals.0.cancel_order_modal.0';

  const onOrderCancelClick = () => {
    onOrderCancel();
    onClose();
  };

  return (
    <Modal className="cancel-order-confirm-modal" isOpen={isOpen} size="medium" onClose={onClose}>
      <ModalHeader titleContentstackPath={`${contentstackPath}.heading`} />
      <div className="cancel-order-confirm-modal__content">
        <div className="cancel-order-confirm-modal__subtext">
          <ContentstackText contentKey={`${contentstackPath}.subtext`} />
        </div>
      </div>

      <ModalFooter className="cancel-order-confirm-modal__button-group">
        <CorButton className="text cancel-order-confirm-modal__go-back-button" onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.go_back_button_label`} />
        </CorButton>
        <CorButton className="cancel-order-confirm-modal__cancel-button" onClick={onOrderCancelClick}>
          <ContentstackText contentKey={`${contentstackPath}.cancel_order_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
