import './cor-cards.scss';

import React from 'react';
import classNames from 'classnames';
import { ICorFeaturedContentCardsVariant } from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { useContent } from 'hooks/use-content';

const ENTRIES_KEY = 'entries';

const CorCards = ({ children }: React.PropsWithChildren<ICorFeaturedContentCardsVariant>) => {
  const { getContentByKey } = useContent();
  const entries = getContentByKey(ENTRIES_KEY, []);

  return (
    <div
      className={classNames(
        'cor-featured-content__cards--no-carousel',
        'grid-container',
        `cor-featured-content__cards--columns-${entries.length}`
      )}
    >
      {children}
    </div>
  );
};

export default React.memo(CorCards);
