import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectUser } from 'store/auth/selectors';
import { useHideGlobalModalHandler, useLocationsSelectConfirmationModal } from 'hooks/use-global-modal';
import { submitLocation } from 'store/customer-login/actions';
import { ISubmitLocationPayload } from 'store/customer-login/sagas/submit-location';
import './utility-nav-user-submenu.scss';

export const UtilityNavUserSubmenu = ({ locations }: any) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const SCROLL_LIMIT = 20;
  const userLocation = locations.find((item) => item.value === user.locationId);
  const defaultLocation = userLocation?.isDeleted ? { ...userLocation, label: `*${userLocation.label}` } : userLocation;
  const [preselectedLocation, setPreselectedLocation] = useState(defaultLocation);
  const showLocationsSelectConfirmationModal = useLocationsSelectConfirmationModal();
  const closeGlobalModal = useHideGlobalModalHandler();
  const [hasSelectedLocationChanged, setSelectedLocationChanged] = useState(false);

  const processLocations = locations.map((location: any) => {
    return {
      ...location,
      label: location.label.split(' - ') || [],
      isSelected: location.value === preselectedLocation?.value || false,
    };
  });

  useEffect(() => {
    if (hasSelectedLocationChanged) {
      showLocationsSelectConfirmationModal({
        onLocationSubmit,
        preselectedLocation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedLocation]);

  const onLocationSelect = (location: any) => {
    setPreselectedLocation(location);

    if (defaultLocation?.value !== location.value) {
      showLocationsSelectConfirmationModal({
        onLocationSubmit,
        preselectedLocation,
      });
    }
    setSelectedLocationChanged(true);
  };

  const onLocationSubmit = () => {
    dispatch(
      submitLocation.request<ISubmitLocationPayload>({ locationNumber: preselectedLocation?.value })
    );
    setPreselectedLocation(defaultLocation);
    setSelectedLocationChanged(false);
    closeGlobalModal();
  };

  return (
    <div className={'user-locations-panel__container'}>
      <div
        className={classNames('user-locations-panel__scroll', { 'is-scroll': processLocations.length > SCROLL_LIMIT })}
      >
        <ul>
          {processLocations.map((location, index) => {
            const selectedModifierClass = classNames('user-location', {
              'is-selected': location.isSelected,
            });
            return (
              <li key={`${location.value}-${index}`} className={selectedModifierClass}>
                {location.isSelected ? (
                  <div className="user-location__selected">
                    {location.label.map((loc, index) => (
                      <span className={'loc-item'} key={`loc-${index}`}>
                        {loc}
                      </span>
                    ))}
                  </div>
                ) : (
                  <button className="user-location__item" onClick={() => onLocationSelect(location)}>
                    {location.label.map((loc, index) => (
                      <span
                        key={`location-label-${index}`}
                        className={classNames('loc-item', {
                          addy: !location.isSelected && index === location.label.length - 1,
                        })}
                      >
                        {loc}
                      </span>
                    ))}
                  </button>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
