import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { compact } from 'lodash';
import { stringify } from 'qs';
import { ICSUtilityNavData } from 'components/layout/cor-header/cor-header.interface';
import { Endpoints } from 'constants/endpoints.enum';
import { Routes } from 'constants/routes.enum';
import { CS_CACHED_CONTENT_PREFIX } from 'utils/clear-cached-contentstack-content';
import api from '../api';

interface IUseContentstackSearchTagsProps {
  contentstackParams: string;
  include?: string[];
  contentTypeName: string;
  locale?: string;
  cacheContent?: boolean;
}

export const useContentstackSearchTags = (
  additionalContentTypeToFetch: IUseContentstackSearchTagsProps[] | undefined,
  cacheContent = false
): ICSUtilityNavData[] => {
  const { pathname: locationPathname } = useLocation();
  const [content, setContent] = useState<ICSUtilityNavData[]>([]);

  const fetch = useCallback(async () => {
    if (!additionalContentTypeToFetch || !Array.isArray(additionalContentTypeToFetch)) {
      return [];
    }

    const additionalContent = await Promise.all(
      additionalContentTypeToFetch.map(async ({ contentstackParams, contentTypeName = '', include = [], locale }) => {
        if (!contentstackParams || !contentTypeName) {
          return;
        }

        const cachedContent = sessionStorage.getItem(`${CS_CACHED_CONTENT_PREFIX}-${contentTypeName}`);
        if (cachedContent) {
          return JSON.parse(cachedContent);
        }

        try {
          const params = {
            params: contentstackParams,
            contentTypeName: contentTypeName || '',
            ...(locale && { locale: locale }),
            ...(!!include?.length && { include }),
          };
          const { data } = await api.get(Endpoints.CONTENTSTACK_TAGS, {
            params,
            paramsSerializer: (params) => stringify(params, { arrayFormat: 'indices' }),
          });

          if (data) {
            const content = additionalContentTypeToFetch.length > 1 ? { [contentstackParams]: data[0] } : data[0];

            if (
              (locationPathname.includes(Routes.ShopHomePage) || locationPathname.includes(Routes.CanadaFrenchShop)) &&
              cacheContent
            ) {
              sessionStorage.setItem(`${CS_CACHED_CONTENT_PREFIX}-${contentTypeName}`, JSON.stringify(content));
            }
            return content;
          }
        } catch (e) {
          console.error(e);
          return [];
        }
      })
    );
    setContent(compact(additionalContent) as ICSUtilityNavData[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(additionalContentTypeToFetch)]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return content;
};
