import qs from 'qs';
import { takeLatest, put } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { excludeSpecialCharacters } from 'utils/string-format';
import { getAccountInformation, getAccountInformationActionConstants } from '../actions';

export interface IGetAccountInformationPayload {
  searchText?: string;
}

export function* getAccountInformationSagaHandler({ payload }: IAction<IGetAccountInformationPayload>) {
  const searchQuery = excludeSpecialCharacters(payload.searchText);

  yield put(
    doRequest(
      getAccountInformation,
      `${Endpoints.ACCOUNT_INFORMATION}?${qs.stringify({
        searchText: searchQuery,
      })}`,
      'get'
    )
  );
}

export default function* getAccountInformationSaga() {
  yield takeLatest(getAccountInformationActionConstants[Actions.REQUEST], getAccountInformationSagaHandler);
}
