import { Actions } from 'constants/actions.enum';
import { IGeneratedConstants } from 'types/actions';

const withSuffix = (type: string, suffix: string): string => `${type}_${suffix}`;

export const createConstants = (type: string): IGeneratedConstants => ({
  [Actions.REQUEST]: withSuffix(type, Actions.REQUEST),
  [Actions.SUCCESS]: withSuffix(type, `${Actions.REQUEST}_${Actions.SUCCESS}`),
  [Actions.FAIL]: withSuffix(type, `${Actions.REQUEST}_${Actions.FAIL}`),
});
