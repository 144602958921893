import './cor-select.scss';

import { FC } from 'react';
import Select from 'react-select';

export type CorAccordionSelectType = { label: string; value: any };

export interface ICorSelectProps {
  onChangeHandler: (option) => void;
  value: CorAccordionSelectType;
  options: CorAccordionSelectType[];
  isSearchable?: boolean;
}

export const CorSelect: FC<ICorSelectProps> = ({ onChangeHandler, isSearchable = true, ...rest }) => {
  return (
    <Select
      {...rest}
      isSearchable={isSearchable}
      onChange={(option) => onChangeHandler(option as CorAccordionSelectType)}
      classNamePrefix="cor-select"
      className={'cor-select'}
    />
  );
};
