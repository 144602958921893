import { ICSCorButton } from 'corporate-interfaces';

export type CorContentstackAsset = {
  url: string;
  cdn_url?: string;
  extension?: string;
  mimetype?: string;
  size?: number;
};

export const corContentstackAssetLinkResolver = (button: ICSCorButton): CorContentstackAsset | undefined => {
  const { brand_folder_asset, contentstack_asset } = button;
  const hasBrandFolderAsset = brand_folder_asset && Array.isArray(brand_folder_asset) && brand_folder_asset.length > 0;
  const hasContentStackAsset = contentstack_asset?.url;

  if (!hasContentStackAsset && !hasBrandFolderAsset) {
    return undefined;
  }

  const url = (hasBrandFolderAsset ? brand_folder_asset[0].cdn_url : contentstack_asset?.url) || '';

  return {
    ...button,
    url,
  };
};
