enum actionTypes {
  VALIDATE_CART = 'VALIDATE_CART',
  SUBMIT_ORDER = 'SUBMIT_ORDER',
  GO_TO_ORDER_SUBMITTION = 'GO_TO_ORDER_SUBMITTION',
  SET_PO_NUMBER = 'SET_PO_NUMBER',
  RESET_SAP_BACKUP_STATUS = 'RESET_SAP_BACKUP_STATUS',
  CLEAR_GENERAL_ERROR_MESSAGE = 'CLEAR_GENERAL_ERROR_MESSAGE',
  SET_SELECTED_DELIVERY_DATE = 'SET_SELECTED_DELIVERY_DATE',
}

export default actionTypes;
