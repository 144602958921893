import { isEmpty } from 'lodash-es';
import { PAGINATION_SETTINGS } from 'constants/pagination.enum';
import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import { rootReducerActionTypes } from 'store/root-reducer-actions';
import types from 'store/account/action-types';
import * as actions from 'store/account/actions';
import { addProductToCartActionConstants } from 'store/shopping-cart/actions';
import { IProductItem } from 'types/product';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import { ILineItem } from 'types/line-item';
import { IDeactivatedLineItemDetails, IProprietaryItemsWithoutPriceDetails } from 'types/order-details';
import * as shoppingListsActions from 'store/shopping-lists/actions';

export const orderHistoryProductsInitialState = {
  limit: PAGINATION_SETTINGS.defaultLimit,
  offset: PAGINATION_SETTINGS.defaultOffset,
  skuInfos: [],
  totalProducts: 0,
  loading: false,
  isProductsListBeingUpdated: false,
};

interface IPaginationPayload {
  payload: {
    current: number;
  };
}

interface IOrderHistoryProductsPayload {
  payload: IOrderHistoryProductsState;
}

export interface IOrderHistoryProductsState {
  loading: boolean;
  limit: number;
  offset: number;
  skuInfos: IProductItem[];
  totalProducts: number;
  isProductsListBeingUpdated: boolean;
}

const updateLineItemsOnAddToShoppingListSuccess = (payload: any, state: IOrderHistoryProductsState) => {
  if (
    !isEmpty(payload.deactivatedLineItems) ||
    !isEmpty(payload.proprietarySkusWithoutPrice) ||
    !isEmpty(payload.shortSupplyItems)
  ) {
    return {
      ...state,
      skuInfos: state.skuInfos.map((skuInfo) => ({
        ...skuInfo,
        isDeactivated:
          payload.deactivatedLineItems.some((item: IDeactivatedLineItemDetails) =>
            skuInfo.number.includes(item.itemNumber)
          ) || skuInfo.isDeactivated,
        isProprietaryWithoutPrice:
          payload.proprietarySkusWithoutPrice.some((item: IProprietaryItemsWithoutPriceDetails) =>
            skuInfo.number.includes(item.sku)
          ) || skuInfo.isProprietaryWithoutPrice,
        maximumQuantity: payload.shortSupplyItems.some((item: any) => skuInfo.number.includes(item.sku))
          ? 0
          : skuInfo.maximumQuantity || undefined,
      })),
    };
  } else {
    return state;
  }
};

const updateLineItemsOnAddMultipleProductsToCartSuccess = (payload: any, state: IOrderHistoryProductsState) => {
  if (
    !isEmpty(payload.deactivatedSkus) ||
    !isEmpty(payload.unavailableProprietarySkus) ||
    !isEmpty(payload.shortSupplyItems)
  ) {
    return {
      ...state,
      skuInfos: state.skuInfos.map((skuInfo) => ({
        ...skuInfo,
        isDeactivated:
          payload.deactivatedSkus.some((item: any) => skuInfo.number.includes(item.sku)) || skuInfo.isDeactivated,
        isProprietaryWithoutPrice:
          payload.unavailableProprietarySkus.some((item: any) => skuInfo.number.includes(item.sku)) ||
          skuInfo.isProprietaryWithoutPrice,
        maximumQuantity: payload.shortSupplyItems.some((item: any) => skuInfo.number.includes(item.sku))
          ? 0
          : skuInfo.maximumQuantity || undefined,
      })),
    };
  } else {
    return state;
  }
};

const updateLineItemsOnAddToShoppingListFail = (error: any, state: IOrderHistoryProductsState) => {
  if (error.status === 400 && (!isEmpty(error.deactivatedItems) || !isEmpty(error.proprietaryItemsWithoutPrice))) {
    return {
      ...state,
      skuInfos: state.skuInfos.map((skuInfo) => ({
        ...skuInfo,
        isDeactivated:
          error.deactivatedItems?.some((item: string) => skuInfo.number.includes(item)) || skuInfo.isDeactivated,
        isProprietaryWithoutPrice:
          error.proprietaryItemsWithoutPrice?.some((item: IProprietaryItemsWithoutPriceDetails) =>
            skuInfo.number.includes(item.sku)
          ) || skuInfo.isProprietaryWithoutPrice,
      })),
    };
  } else {
    return state;
  }
};

const updateLineItemsOnFail = (error: any, state: IOrderHistoryProductsState) => {
  if (
    error.status === 400 &&
    (!isEmpty(error.deactivatedItems) ||
      !isEmpty(error.proprietaryItemsWithoutPrice) ||
      !isEmpty(error.shortSupplyItems))
  ) {
    return {
      ...state,
      isProductsListBeingUpdated: false,
      skuInfos: state.skuInfos.map((skuInfo) => ({
        ...skuInfo,
        isDeactivated:
          error.deactivatedItems?.some((item: string) => skuInfo.number.includes(item)) || skuInfo.isDeactivated,
        isProprietaryWithoutPrice:
          error.proprietaryItemsWithoutPrice?.some((item: IProprietaryItemsWithoutPriceDetails) =>
            skuInfo.number.includes(item.sku)
          ) || skuInfo.isProprietaryWithoutPrice,
        maximumQuantity:
          error?.shortSupplyItems &&
          error.shortSupplyItems.some((shortSupplyItem: any) => skuInfo.number.includes(shortSupplyItem.sku))
            ? 0
            : skuInfo.maximumQuantity || undefined,
      })),
    };
  } else {
    return {
      ...state,
      isProductsListBeingUpdated: false,
    };
  }
};

export const orderHistoryProducts = createReducer<IOrderHistoryProductsState>(
  {
    [actions.getOrderHistoryProductsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getOrderHistoryProductsActionConstants[Actions.SUCCESS]]: (
      state,
      { payload }: IOrderHistoryProductsPayload
    ) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [actions.getOrderHistoryProductsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.ORDER_HISTORY_PRODUCTS_PAGINATION_CHANGE]: (state, { payload }: IPaginationPayload) => ({
      ...state,
      offset: state.limit * payload.current,
    }),
    [rootReducerActionTypes.RESET_LOCATION_RELATED_DATA]: (state) => ({
      ...state,
      orderHistoryProducts: orderHistoryProductsInitialState,
    }),
    [addProductToCartActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isProductsListBeingUpdated: true,
    }),
    [addProductToCartActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isProductsListBeingUpdated: false,
    }),
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      skuInfos: state.skuInfos.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.number),
      })),
    }),
    [actions.getAccountInformationActionConstants[Actions.SUCCESS]]: (state) => state,
    [shoppingListsActions.addItemsToShoppingListsActionConstants[Actions.SUCCESS]]: (state, { payload }: any) =>
      updateLineItemsOnAddToShoppingListSuccess(payload, state),
    [shoppingCartActions.addMultipleProductsToCartActionConstants[Actions.SUCCESS]]: (state, { payload }: any) =>
      updateLineItemsOnAddMultipleProductsToCartSuccess(payload, state),
    [shoppingListsActions.addItemsToShoppingListsActionConstants[Actions.FAIL]]: (state, { error }: any) =>
      updateLineItemsOnAddToShoppingListFail(error, state),
    [shoppingCartActions.addProductToCartActionConstants[Actions.FAIL]]: (state, { error }: any) =>
      updateLineItemsOnFail(error, state),
  },
  orderHistoryProductsInitialState
);
