import qs from 'qs';
import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getInvoicesActionsConstants, getInvoices } from 'store/invoices/actions';

export interface IGetInvoicesPayload {
  fromDate?: string;
  toDate?: string;
  soldToCustomer?: string;
  limit?: number;
  offset?: number;
}

export function* getInvoicesSagaHandler({ payload: { limit, offset } }: IAction<IGetInvoicesPayload>) {
  yield put(
    doRequest<IGetInvoicesPayload>(
      getInvoices,
      `${Endpoints.INVOICES}?${qs.stringify({
        limit,
        offset,
      })}`,
      'get'
    )
  );
}

export default function* getInvoicesSaga() {
  yield takeLatest(getInvoicesActionsConstants[Actions.REQUEST], getInvoicesSagaHandler);
}
