import { createReducer } from 'store/reducer-creator';
import { Actions } from 'constants/actions.enum';
import { IInvoiceItem } from 'types/invoices';
import { InvoicesPaginationLimit } from 'constants/invoices.enum';
import { IBankAccounts, ICreditCards } from 'store/payment-methods/reducers';
import * as actions from './actions';
import types from './action-types';

export type ISelectedPaymentMethod = ICreditCards | IBankAccounts;

export interface IInvoiceSummary {
  creditMemos: number;
  creditMemosApply: number;
  orderTotal: number;
  subTotal: number;
  totalPayAmount: number;
  surcharges: number;
}

export interface IInvoicesState {
  invoicesSummary: IInvoiceSummary;
  invoices: IInvoiceItem[];
  selectedInvoices: IInvoiceItem[];
  limit: number;
  offset: number;
  total: number;
  sortDirection: string;
  isDataSyncInProgress: boolean;
  isPaymentDocumentsUpdateFailed: boolean;
  creditLimit: number;
  totalOutstandingBalance: number;
  selectedPaymentMethod: ISelectedPaymentMethod | null;
}

const invoicesSummaryInitialState = {
  creditMemos: 0,
  creditMemosApply: 0,
  orderTotal: 0,
  subTotal: 0,
  totalPayAmount: 0,
  surcharges: 0,
};

export const invoicesInitialState: IInvoicesState = {
  invoicesSummary: invoicesSummaryInitialState,
  invoices: [],
  limit: InvoicesPaginationLimit.LIMIT,
  selectedInvoices: [],
  offset: InvoicesPaginationLimit.defaultOffset,
  total: 0,
  sortDirection: '',
  isDataSyncInProgress: false,
  isPaymentDocumentsUpdateFailed: false,
  creditLimit: 0,
  totalOutstandingBalance: 0,
  selectedPaymentMethod: null,
};

export const invoicesReducer = createReducer<IInvoicesState>(
  {
    [actions.getInvoicesActionsConstants[Actions.REQUEST]]: (state) => ({
      ...state,
    }),
    [actions.getInvoicesActionsConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      ...payload,
      invoices: payload.invoices?.map(({ key, ...invoice }) => ({ ...invoice, documentKey: key })) ?? [],
    }),
    [actions.getInvoicesSummaryActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      invoicesSummary: {
        ...state.invoicesSummary,
        ...payload,
      },
    }),
    [actions.getInvoicesTotalAmountActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      invoicesSummary: {
        ...state.invoicesSummary,
        ...payload,
        creditMemos: payload.creditMemosApply,
      },
    }),
    [types.RESET_PAYMENT_SUMMARY_CALCULATIONS]: (state) => ({
      ...state,
      invoicesSummary: invoicesSummaryInitialState,
    }),
    [types.SET_SELECTED_PAYMENT_METHOD]: (state, { payload }) => ({
      ...state,
      selectedPaymentMethod: payload.paymentMethod,
    }),
    [actions.getSurchargeActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      invoicesSummary: {
        ...state.invoicesSummary,
        ...payload,
      },
    }),
    [types.SET_SELECTED_INVOICES]: (state, { payload: { selectedInvoices } }) => ({
      ...state,
      selectedInvoices,
    }),
    [types.SET_INVOICE_CHANGE_REASON]: (state, { payload: { originalInvoiceNumber, adjustmentReason } }) => ({
      ...state,
      selectedInvoices: state.selectedInvoices.map((invoice) => {
        if (invoice.originalInvoiceNumber === originalInvoiceNumber) {
          return {
            ...invoice,
            adjustmentReason,
          };
        }

        return invoice;
      }),
    }),
    [types.UPDATE_CONFIRMED_BALANCE]: (state, { payload: { originalInvoiceNumber, confirmedBalance } }) => ({
      ...state,
      selectedInvoices: state.selectedInvoices.map((invoice) => {
        if (invoice.originalInvoiceNumber === originalInvoiceNumber) {
          return {
            ...invoice,
            confirmedBalance,
          };
        }

        return invoice;
      }),
    }),
    [types.SET_DEFAULT_CONFIRMED_BALANCE]: (state, { payload: { originalInvoiceNumber } }) => ({
      ...state,
      selectedInvoices: state.selectedInvoices.map((invoice) => {
        if (invoice.originalInvoiceNumber === originalInvoiceNumber) {
          return {
            ...invoice,
            confirmedBalance: invoice.balance,
          };
        }

        return invoice;
      }),
    }),
    [types.INVOICES_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      offset: state.limit * payload.current,
    }),
  },
  invoicesInitialState
);

export default invoicesReducer;
