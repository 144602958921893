import { FC, useEffect } from 'react';
import classNames from 'classnames';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './success-error-message-modal.scss';

export interface ISuccessErrorMessageModalProps extends IModalProps {
  autoClose?: boolean;
  messageId?: string;
  modalTitle?: string;
  modalBodyClassname?: string;
  modalClassName?: string;
  hasCrossButton?: boolean;
  messageInterpolateParams?: object;
  type: 'success' | 'error' | 'error_two';
}

export const SuccessErrorMessageModal: FC<ISuccessErrorMessageModalProps> = ({
  isOpen = false,
  onClose = () => {},
  autoClose = true,
  type,
  messageId,
  children,
  hasCrossButton,
  messageInterpolateParams,
  modalBodyClassname,
  modalTitle,
  modalClassName = '',
}) => {
  const contentstackPath = 'modals.0.success_error_modal.0';

  const hasCloseButton = !autoClose;

  useEffect(() => {
    if (!autoClose) {
      return;
    }
    let timer: number;
    timer = window.setTimeout(() => isOpen && onClose(), 3000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const modalClass = classNames('success-error-message-modal', modalClassName, {
    'success-error-message-modal--no-close-button': !hasCloseButton,
  });

  const modalBodyClasses = classNames('success-error-message-modal__body', modalBodyClassname);
  const showContentstackMessage = messageId && type;

  const messageType = type.includes('error') ? 'error' : type;

  return (
    <Modal className={modalClass} isOpen={isOpen} onClose={onClose} hideCloseButton={!hasCrossButton} withBackdropClick>
      <div>
        <ModalHeader
          className={classNames('success-error-message-modal__header', {
            'success-error-message-modal__header-title-only': !children,
          })}
          iconType={messageType}
          contentstackPathIcon={`${contentstackPath}.${messageType}_icon`}
        >
          {showContentstackMessage ? (
            <ContentstackMessage type={type} messageId={messageId} interpolateParams={messageInterpolateParams} />
          ) : (
            <>{modalTitle}</>
          )}
        </ModalHeader>
        {children && <div className={modalBodyClasses}>{children}</div>}
      </div>
      {hasCloseButton && (
        <ModalFooter>
          <CorButton color="tertiary success-error-message-modal__close" onClick={onClose}>
            <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
          </CorButton>
        </ModalFooter>
      )}
    </Modal>
  );
};
