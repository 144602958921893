import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getProductDetails, getProductDetailsActionConstants } from 'store/product-details/actions';
import { IAction } from 'types/actions';

export interface IGetProductDetailsPayload {
  productId: string;
  selectedSku?: string;
}

export function* getProductDetailsHandler({
  payload: { productId, selectedSku = '' },
}: IAction<IGetProductDetailsPayload>) {
  const url = `${Endpoints.PRODUCT_DETAILS}/${productId}?selectedSku=${selectedSku}`;
  yield put(doRequest(getProductDetails, url, 'get'));
}

export default function* getProductDetailsSaga() {
  yield takeLatest(getProductDetailsActionConstants[Actions.REQUEST], getProductDetailsHandler);
}
