import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { putRecentlyViewedItem, putRecentlyViewedItemConstants } from 'store/product-details/actions';
import { IAction } from 'types/actions';

export interface IPutRecentlyViewedItemPayload {
  sku: string;
}

export function* putRecentlyViewedItemHandler({ payload: { sku } }: IAction<IPutRecentlyViewedItemPayload>) {
  yield put(doRequest(putRecentlyViewedItem, `${Endpoints.RECENTLY_VIEWED_ITEMS}/${sku}`, 'put'));
}

export default function* putRecentlyViewedItemSaga() {
  yield takeLatest(putRecentlyViewedItemConstants[Actions.REQUEST], putRecentlyViewedItemHandler);
}
