import { FC } from 'react';
import { Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack';

import './remove-items-from-cart-confirm-modal.scss';

export interface IRemoveItemsConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const RemoveItemsFromCartConfirmModal: FC<IRemoveItemsConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const contentstackPath = 'modals.0.remove_all_confirmation_modal.0';

  return (
    <Modal className="remove-items-from-cart-confirm-modal" isOpen={isOpen} onClose={onClose} size="small">
      <h2 className="remove-items-from-cart-confirm-modal__heading">
        <ContentstackText contentKey={`${contentstackPath}.heading`} />
      </h2>
      <div className="remove-items-from-cart-confirm-modal__actions">
        <CorButton color="text" className="remove-items-from-cart-confirm-modal__cancel-button" onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.cancel_button_label`} />
        </CorButton>
        <CorButton
          type="button"
          className="remove-items-from-cart-confirm-modal__remove-all-button"
          onClick={onConfirm}
        >
          <ContentstackText contentKey={`${contentstackPath}.confirm_button_label`} />
        </CorButton>
      </div>
    </Modal>
  );
};
