import { FC, PropsWithChildren } from 'react';

import './page-layout.scss';

export interface IPageLayout extends PropsWithChildren {
  header: FC;
  footer: FC;
  classModifier?: string;
}

export const PageLayout: FC<IPageLayout> = ({ header: Header, children: main, footer: Footer, classModifier }) => {
  return (
    <div
      className={`page-layout ${classModifier ? 'page-layout--' + classModifier : ''} grid-y`}
      data-testid="page-layout"
    >
      <header className="page-layout__header">
        <Header />
      </header>
      <main className="page-layout__main">{main}</main>
      <footer className="page-layout__footer">
        <Footer />
      </footer>
    </div>
  );
};
