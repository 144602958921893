import { FC, useEffect, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, sortBy } from 'lodash-es';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack';
import { useContent } from 'hooks/use-content';
import { CustomDropdown, IOptionType } from 'components/custom-dropdown';
import { getInvoicesSummary, setDefaultConfirmedBalance, setInvoiceChangeReason } from 'store/invoices/actions';
import { useGlobalModalState } from 'hooks/use-global-modal-state';
import { selectSelectedInvoices, selectSelectedPaymentMethod } from 'store/invoices/selectors';
import { CountryCodes, PaymentMethods } from 'constants/payment-methods.enum';
import { useLocalStorage } from 'hooks/use-local-storage';
import { SHOP_LANGUAGES } from 'constants/shop-locales';

import './shortpay-reasons-modal.scss';

interface ICSReasonKeyValue {
  key: string;
  value: string;
}

interface ICSReasonsList {
  value: ICSReasonKeyValue[];
}

export interface IShortpayReasonsModalProps extends IModalProps {
  originalInvoiceNumber: string;
}

export const ShortpayReasonsModal: FC<IShortpayReasonsModalProps> = ({
  isOpen,
  onClose,
  originalInvoiceNumber,
  ...modalProps
}) => {
  const { getContentByKey } = useContent();
  const [locale] = useLocalStorage('locale', null);
  const selectedPaymentMethod = useSelector(selectSelectedPaymentMethod);
  const countryCode = locale === SHOP_LANGUAGES.ENGLISH_UNITED_STATES ? CountryCodes.US : CountryCodes.CANADA;
  const dropdownTitle = getContentByKey('modals.0.shortpay_reasons_modal.0.reason_dropdown_default_option', '');

  const [reasonOption, setReasonOption] = useState<IOptionType>({ label: dropdownTitle, value: dropdownTitle });
  const dispatch = useDispatch();
  const { hideGlobalModal } = useGlobalModalState();
  const selectedInvoices = useSelector(selectSelectedInvoices);

  const reasonsList = getContentByKey<ICSReasonsList>(
    'modals.0.shortpay_reasons_modal.0.reason_dropdown_options_list',
    {
      value: [],
    }
  );

  const reasonsListRenamed = !isEmpty(reasonsList.value)
    ? reasonsList.value.map((reason) => ({ label: reason?.value, value: reason?.key }))
    : [];

  const getReasonLabelByCode = (code) => {
    if (!code) return;
    return reasonsList?.value?.find((reason) => reason.key === code)?.value;
  };

  useEffect(() => {
    const invoice = selectedInvoices.find((invoiceItem) => invoiceItem.originalInvoiceNumber === originalInvoiceNumber);

    if (!isEmpty(invoice?.adjustmentReason)) {
      setReasonOption({
        label: getReasonLabelByCode(invoice?.adjustmentReason) || '',
        value: invoice?.adjustmentReason,
      });
      return;
    }
    setReasonOption({
      label: dropdownTitle,
      value: dropdownTitle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalInvoiceNumber, selectedInvoices]);

  const onReasonChange = (sortingOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
    const newOption = sortingOption as IOptionType;
    setReasonOption(newOption);
  };

  const submitReason = () => {
    const isPaymentMethodCreditCard = selectedPaymentMethod && !isEmpty(selectedPaymentMethod['cardType']);

    dispatch(setInvoiceChangeReason({ originalInvoiceNumber, adjustmentReason: reasonOption?.value }));
    dispatch(
      getInvoicesSummary.request({
        invoiceBalances: selectedInvoices.map((invoice) => invoice.confirmedBalance),
        countryCode,
        paymentMethod: isPaymentMethodCreditCard ? PaymentMethods.CREDIT_CARD : PaymentMethods.ACH_BANK_ACCOUNT,
      })
    );

    hideGlobalModal();
  };

  const closeModal = () => {
    dispatch(
      setDefaultConfirmedBalance({
        originalInvoiceNumber,
      })
    );
    hideGlobalModal();
  };

  return (
    <Modal className="shortpay-reasons-modal" isOpen={isOpen} onClose={closeModal} {...modalProps}>
      <ModalHeader titleContentstackPath={'modals.0.shortpay_reasons_modal.0.heading'} />
      <div className="shortpay-reasons-modal__content">
        <p className="shortpay-reasons-modal__description">
          <ContentstackText contentKey="modals.0.shortpay_reasons_modal.0.description" />
        </p>
        <CustomDropdown
          inputId="shortpay-reasons-modal__reasons-dropdown--id"
          className="shortpay-reasons-modal__reasons-dropdown"
          items={sortBy(reasonsListRenamed, 'label')}
          onChange={onReasonChange}
          value={reasonOption}
          defaultValue={reasonOption}
        />
      </div>
      <ModalFooter
        contentstackPath="modals.0.shortpay_reasons_modal.0"
        cancelButtonHandler={closeModal}
        hasCancelButton
      >
        <CorButton
          onClick={submitReason}
          disabled={reasonOption?.value === dropdownTitle}
          className="invite-new-user-modal__send-invite-button"
          type="submit"
        >
          <ContentstackText contentKey="modals.0.shortpay_reasons_modal.0.submit_button_label" />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
