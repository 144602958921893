import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import {
  getCsrLocationsWithAssignedAccountsByRecentOrders,
  getCsrLocationsWithAssignedAccountsByRecentOrdersConstants,
} from 'store/dashboard/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface ILocationsWithAssignedAccountsByRecentOrdersPayload {
  searchText?: string;
  limit: number;
  offset: number;
}

export function* getLocationsWithAssignedAccountsByRecentOrdersHandler({
  payload: { searchText = '' },
}: IAction<ILocationsWithAssignedAccountsByRecentOrdersPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);

  yield put(
    doRequest(
      getCsrLocationsWithAssignedAccountsByRecentOrders,
      `${Endpoints.DASHBOARD_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_RECENT_ORDERS}?searchText=${searchQuery}`,
      'get'
    )
  );
}

export default function* getLocationsWithAssignedAccountsByRecentOrdersSaga() {
  yield takeLatest(
    getCsrLocationsWithAssignedAccountsByRecentOrdersConstants[Actions.REQUEST],
    getLocationsWithAssignedAccountsByRecentOrdersHandler
  );
}
