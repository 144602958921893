import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import iconStar from 'assets/images/icons/icon-star.svg';

import './product-replacement-wrapper.scss';

export interface IProductReplacementWrapperProps extends PropsWithChildren {
  title: string;
  className?: string;
}

export const ProductReplacementWrapper: FC<IProductReplacementWrapperProps> = ({ children, title, className }) => {
  return (
    <div className={classNames('product-replacement-wrapper', className)}>
      <div className="product-replacement-wrapper__title">
        <img src={iconStar} alt="icon star" />
        {title}
      </div>
      <div className="product-replacement-wrapper__content">{children}</div>
    </div>
  );
};
