import React from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { useSelector } from 'react-redux';
import { ProductSelectorButton } from 'components/product-selector-button';
import { CustomDropdown, IOptionType } from 'components/custom-dropdown';
import { ContentstackText } from 'components/contentstack';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { IGroupSelector } from 'types/product';
import {
  selectGroupSelectors,
  selectGroupSelectorsCurrentValues,
  selectGroupSelectorsMapped,
} from 'store/product-details/selectors';

import './product-group-selector.scss';

const OPTIONS_QUANTITY_TO_USE_BUTTON = 6;

interface IProductGroupSelectorProps {
  onSelectorChange: (sku: string, productKey: string) => void;
}

interface ISelectedProductFound {
  sku: string;
  productKey: string;
}

export const ProductGroupSelector: React.FC<IProductGroupSelectorProps> = ({ onSelectorChange }) => {
  const { isDesktop, isMobile } = useBreakpoint();
  const BUTTON_LABEL_CHARS_LIMIT = 9;

  const groupSelectorsList = useSelector(selectGroupSelectorsMapped);
  const groupSelectors = useSelector(selectGroupSelectors);
  const groupSelectorsCurrentValues = useSelector(selectGroupSelectorsCurrentValues);
  const selectorsToUseDropdown = ['bag_size', 'box_size', 'storage_bag_size'];

  const currentSelectorValue = (type: string) => {
    if (!groupSelectorsCurrentValues) {
      return;
    }
    const currentIndex = groupSelectorsCurrentValues.findIndex((item) => item.type === type);
    return groupSelectorsCurrentValues[currentIndex];
  };

  const transformValuesToOptions = (type, arr: string[]) => arr.map((item) => ({ label: item, value: item }));
  const findSelector = (preselectedSelector, selectorsList) =>
    selectorsList.find((item) => item.type === preselectedSelector.type && item.value === preselectedSelector.value);

  const redirectToSelectedSkuPdp = (type, value) => {
    const valueAlreadySelected =
      currentSelectorValue(type)?.type === type && currentSelectorValue(type)?.value === value;

    if (valueAlreadySelected) {
      return;
    }

    if (groupSelectorsCurrentValues && groupSelectors) {
      const preselectedSelectors = groupSelectorsCurrentValues.map((currentValue) => ({ ...currentValue }));

      const currentIndex = preselectedSelectors.findIndex((item) => item.type === type);
      preselectedSelectors[currentIndex].value = value;

      const selectedProduct = preselectedSelectors.reduce((acc: any, value, index) => {
        const currentSelector = index === 0 ? findSelector(value, groupSelectors) : findSelector(value, acc.children);

        acc = currentSelector && index <= currentIndex ? currentSelector : acc.children[0];

        if (acc.product) {
          return acc.product;
        }

        return acc;
      }, {} as IGroupSelector | ISelectedProductFound);

      onSelectorChange(selectedProduct.sku, selectedProduct.productKey);
    }
  };

  const onDropdownSelect = (type: string, selectedOption?: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
    redirectToSelectedSkuPdp(type, (selectedOption as IOptionType).value);
  };

  return (
    <div className="product-group-selector">
      {groupSelectorsList.map(({ type, values }) => {
        return (
          <div className="product-group-selector__selector-block" key={type}>
            <div className="product-group-selector__label">
              <ContentstackText contentKey={`product_group_selector[0].${type}`} />
            </div>
            {values.length <= OPTIONS_QUANTITY_TO_USE_BUTTON &&
              !selectorsToUseDropdown.includes(type) &&
              isDesktop &&
              values.map((item) => {
                return (
                  <ProductSelectorButton
                    isActive={groupSelectorsCurrentValues?.find((item) => item.type === type)?.value === item}
                    onVariantSelect={() => redirectToSelectedSkuPdp(type, item)}
                    className="product-group-selector__button"
                    title={item.length > BUTTON_LABEL_CHARS_LIMIT ? item : ''}
                    key={item}
                  >
                    <span>
                      {item.length > BUTTON_LABEL_CHARS_LIMIT
                        ? `${item.substring(0, BUTTON_LABEL_CHARS_LIMIT)}...`
                        : item}
                    </span>
                  </ProductSelectorButton>
                );
              })}
            {(values.length > OPTIONS_QUANTITY_TO_USE_BUTTON || selectorsToUseDropdown.includes(type) || isMobile) && (
              <CustomDropdown
                inputId="product-group-selector__dropdown--id"
                className="product-group-selector__dropdown"
                items={transformValuesToOptions(type, values)}
                value={
                  {
                    label: currentSelectorValue(type)?.value,
                    value: currentSelectorValue(type)?.value,
                  } as IOptionType
                }
                onChange={(selectedOption?: MultiValue<IOptionType> | SingleValue<IOptionType>) =>
                  onDropdownSelect(type, selectedOption)
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
