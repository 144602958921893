import './cor-search-results-empty.scss';

import { CorTypography } from 'corporate-ui';
import { useContent } from 'hooks/use-content';

const LABELS_KEY = 'labels.0.key_value_pair.value';

const CorSearchResultsEmpty = () => {
  const { getLabelsByKey } = useContent();
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});

  return (
    <div className="cor-search-results__empty">
      <CorTypography variant="h2" component="h2" data-testid="cor-search-results__empty__title">
        {labels?.not_found_title || 'No Results Found'}
      </CorTypography>
      <CorTypography variant="body-large" data-testid="cor-search-results__empty__description">
        {labels?.not_found_description || 'Please try another search to see results.'}
      </CorTypography>
    </div>
  );
};

export default CorSearchResultsEmpty;
