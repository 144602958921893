import { createSelector } from 'reselect';
import { isEmpty } from 'lodash-es';
import { IState } from 'store/root-reducer';
import { ILineItem } from 'types/line-item';
import { IShoppingCartState } from './reducer';

export const selectShoppingCartEntity = (state: IState): IShoppingCartState => state.app.cartInfo;

export const selectLineItems = createSelector(
  selectShoppingCartEntity,
  (shoppingCartEntity) => shoppingCartEntity.cartInfo.lineItems
);

export const selectCartInfo = createSelector(
  selectShoppingCartEntity,
  (shoppingCartEntity) => shoppingCartEntity.cartInfo.info
);

export const selectItemsCounter = createSelector(selectCartInfo, (shoppingCartInfo) => shoppingCartInfo.cartCounter);

export const selectShoppingCartLoading = createSelector(
  selectShoppingCartEntity,
  (shoppingCartEntity) => shoppingCartEntity.cartInfo.loading
);

export const selectShowCartNotificationFlag = createSelector(selectCartInfo, (info) => {
  const {
    lineItemPriceUpdates,
    isPresentDeactivatedItem,
    exceededMaximumQuantityItemsSkuAndSkuNames,
    shortSupplyItemsSkuAndSkuNames,
    outOfStockItemsSkuAndSkuNames,
  } = info;
  return (
    !isEmpty(lineItemPriceUpdates) ||
    isPresentDeactivatedItem ||
    !isEmpty(exceededMaximumQuantityItemsSkuAndSkuNames) ||
    !isEmpty(shortSupplyItemsSkuAndSkuNames) ||
    !isEmpty(outOfStockItemsSkuAndSkuNames)
  );
});

export const selectIsProductBeingUpdated = createSelector(
  selectShoppingCartEntity,
  (shoppingCartEntity) => shoppingCartEntity.cartInfo.isProductBeingUpdated
);

export const selectFormattedLineItems = createSelector(selectLineItems, (lineItems) => {
  return lineItems.map((item: ILineItem) => ({
    sku: item.itemNumber,
    quantity: item.quantity,
  }));
});

export const selectOriginalToReplacementItemsMap = createSelector(
  selectShoppingCartEntity,
  ({ cartInfo }) => cartInfo.originalToReplacementSkusMap
);

export const selectReorderOriginalToReplacementItemsMap = createSelector(
  selectShoppingCartEntity,
  ({ cartInfo }) => cartInfo.reorderOriginalToReplacementSkusMap
);

export const selectDeactivatedItem = createSelector(
  selectShoppingCartEntity,
  ({ cartInfo }) => cartInfo.deactivatedItemsSkus
);

export const selectDidYouForgetSomethingProducts = createSelector(
  selectShoppingCartEntity,
  ({ productRecommendations }) => productRecommendations
);

export const selectDiscountDetails = createSelector(
  selectShoppingCartEntity,
  ({ cartInfo }) => cartInfo.coupon.discountDetails
);

export const selectIsPromotionalCodeValid = createSelector(
  selectShoppingCartEntity,
  ({ cartInfo }) => cartInfo.coupon.isPromotionalCodeValid
);
