export enum GAParamTypes { // see https://developers.google.com/gtagjs/reference/parameter
  array = 'array',
  boolean = 'boolean',
  currency = 'currency',
  integer = 'integer',
  number = 'number',
  string = 'string',
}

export enum GAProductActions { // https://developers.google.com/gtagjs/reference/event
  AddPaymentInfo = 'add_payment_info',
  AddToCart = 'add_to_cart',
  AddToWishlist = 'add_to_wishlist',
  BeginCheckout = 'begin_checkout',
  CheckoutProgress = 'checkout_progress',
  GenerateLead = 'generate_lead',
  Login = 'login',
  Purchase = 'purchase',
  Refund = 'refund',
  RemoveFromCart = 'remove_from_cart',
  Search = 'search',
  SelectContent = 'select_content',
  SetCheckoutOption = 'set_checkout_option',
  Share = 'share',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list',
  ViewPromotion = 'view_promotion',
  ViewRefund = 'view_refund',
  ViewSearchResults = 'view_search_results',
}

export interface IGAActionParams {
  id: string;
  affiliation?: string;
  value?: number;
  tax?: number;
  shipping?: number;
  items?: IGAProduct[];
  checkout_step?: number;
  checkout_option?: string;
  coupon?: string;
}

export interface IGAImpression {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  variant?: string;
  price?: number;
  list?: string;
  position?: number;
}

export interface IGAProduct {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  variant?: string;
  price?: number;
  quantity: number;
  coupon?: string;
  list_position?: number;
}
