import { FC } from 'react';
import { get, isEmpty, template } from 'lodash-es';
import classNames from 'classnames';
import iconWarning from 'assets/images/icons/icon-warning.svg';
import { IMessage } from 'components/contentstack';
import { useContentstackContent } from 'hooks/use-contentstack-content';

import './error-notification.scss';

export interface IErrorNotificationProps {
  message?: string;
  className?: string;
  messageId?: string;
  interpolateParams?: object;
}

export const ErrorNotification: FC<IErrorNotificationProps> = ({
  message = '',
  className,
  messageId,
  interpolateParams,
}) => {
  const content = useContentstackContent();

  const errorNotificationClassName = classNames('error-notification', className);
  const messages: IMessage[] = get(content, 'error.0.messages', []);
  const errorMessagesTwo: IMessage[] = get(content, 'error_two.0.messages', []);
  const errorMessage =
    messages.find((message) => message.message_id === messageId) ||
    errorMessagesTwo.find((message) => message.message_id === messageId);
  const errorMessageCompiled = template(errorMessage?.message_content);

  if (isEmpty(message) && isEmpty(messageId)) {
    return null;
  }

  return (
    <div className={errorNotificationClassName}>
      <div className="error-notification__icon">
        <img src={iconWarning} alt="notification warning icon" />
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: !isEmpty(messageId) ? errorMessageCompiled(interpolateParams) : message,
        }}
      />
    </div>
  );
};
