import React from 'react';
import { Popover } from 'react-tiny-popover';
import { CorFilterButton } from '../cor-filter-button';
import { CorFilterDropdownOptions } from '../cor-filter-dropdown-options';
import { ICorFilterDropdownProps } from './cor-filter-dropdown.interface';

const CorFilterDropdown = ({ buttonLabel = '', facetKey, facetTerms = [], pageType }: ICorFilterDropdownProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

  const handleToggleDropdown = React.useCallback(() => {
    setIsDropdownOpened((prev) => !prev);
  }, []);

  const handleCloseDropdown = React.useCallback(() => {
    setIsDropdownOpened(false);
  }, []);

  return (
    <Popover
      isOpen={isDropdownOpened}
      positions={['bottom', 'left']}
      align="start"
      padding={16}
      onClickOutside={handleCloseDropdown}
      content={
        <CorFilterDropdownOptions
          facetKey={facetKey}
          facetTerms={facetTerms}
          onCloseDropdown={handleCloseDropdown}
          pageType={pageType}
        />
      }
    >
      <CorFilterButton
        isDropdown
        isDropdownOpened={isDropdownOpened}
        handleToggleDropdown={handleToggleDropdown}
        buttonLabel={buttonLabel}
        facetKey={facetKey}
        facetTerms={facetTerms}
      />
    </Popover>
  );
};

export default React.memo(CorFilterDropdown);
