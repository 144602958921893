import { takeLatest, take, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getCartInfo, reorderProducts, reorderProductsActionConstants } from 'store/shopping-cart/actions';
import { IAction } from 'types/actions';

export interface IReorderProductToCartPayload {
  orderId: string;
  onFailCallback?: () => void;
}

export function* reorderProductsToCartSagaHandler({ payload }: IAction<IReorderProductToCartPayload>) {
  const { orderId } = payload;
  const url = `${Endpoints.CART}?orderId=${orderId}`;

  yield put(doRequest<IReorderProductToCartPayload>(reorderProducts, url, 'patch'));
}

export function* reorderProductsToCartSuccessSagaHandler() {
  yield take(reorderProductsActionConstants[Actions.SUCCESS]);
  yield put(doRequest(getCartInfo, Endpoints.CART, 'get'));
}

export function* reorderProductsToCartFailSagaHandler({ payload }: IAction<IReorderProductToCartPayload>) {
  yield take(reorderProductsActionConstants[Actions.FAIL]);

  if (payload.onFailCallback) {
    payload.onFailCallback();
  }
}

export default function* reorderProductsToCartSaga() {
  yield takeLatest(reorderProductsActionConstants[Actions.REQUEST], reorderProductsToCartSagaHandler);
  yield takeLatest(reorderProductsActionConstants[Actions.REQUEST], reorderProductsToCartSuccessSagaHandler);
  yield takeLatest(reorderProductsActionConstants[Actions.REQUEST], reorderProductsToCartFailSagaHandler);
}
