import './cor-leadership-tile.scss';

import { CorContentstackHtml } from 'corporate-components/cor-contentstack';
import { ICSCorImage } from 'corporate-interfaces';
import {
  CorContentstackImageAsset,
  corContentstackImageLinkResolver,
} from 'corporate-utils/cor-contentstack-image-link-resolver';
import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { CorTypography } from 'corporate-ui';
import { useBreakpoint } from 'hooks/use-breakpoint';

export interface ICorLeadershipTileProps {
  id: string;
  isActive: boolean;
  tilesWrapper: string;
  activeStateHandler: () => void;
  closeHandler: () => void;
  picture?: ICSCorImage;
  name?: string;
  jobTitle?: string;
  description?: string;
}

const SharedElements = ({
  image,
  name,
  jobTitle,
}: {
  image?: CorContentstackImageAsset;
  name?: string;
  jobTitle?: string;
}) => {
  const { isMobile } = useBreakpoint();
  return (
    <>
      {image?.url && (
        <div className="cor-leadership-tile__image">
          <img src={image?.url} alt={image?.image_alt_text || ''} />
        </div>
      )}
      <div className={'cor-leadership-tile__info'}>
        {name && (
          <CorTypography variant={'h4'} color={'dark-gray'} className="cor-leadership-tile__name">
            {name}
          </CorTypography>
        )}
        {jobTitle && (
          <CorTypography
            variant={isMobile ? 'body-small' : 'body-regular'}
            color={'mid-gray'}
            className={'cor-leadership-tile__job'}
          >
            {jobTitle}
          </CorTypography>
        )}
      </div>
    </>
  );
};

export const CorLeadershipTile: FC<ICorLeadershipTileProps> = ({
  picture,
  jobTitle,
  name,
  description,
  isActive,
  activeStateHandler,
  closeHandler,
  tilesWrapper,
}) => {
  const [position, setPosition] = useState<'left' | 'right'>();
  const image: CorContentstackImageAsset | undefined = picture ? corContentstackImageLinkResolver(picture) : undefined;

  const showPopUp = useCallback((element, handler) => {
    const currentLeftPos = element.getBoundingClientRect().left;
    const parentElementBoundingClientRect = element.closest(tilesWrapper).getBoundingClientRect();
    const currentParentCenterPos = parentElementBoundingClientRect.width / 2 + parentElementBoundingClientRect.left;
    setPosition(currentLeftPos >= currentParentCenterPos ? 'left' : 'right');
    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closePopUp = useCallback(($event, handler) => {
    $event.preventDefault();
    handler();
  }, []);

  const mouseOutClosePopUp = useCallback(($event, handler) => {
    const isInsideDescription = !!$event.target.closest('.cor-leadership-tile');
    if (isInsideDescription) {
      handler();
    }
  }, []);

  return (
    <div
      onMouseLeave={($event) => mouseOutClosePopUp($event, closeHandler)}
      className={classNames('cor-leadership-tile', `cor-leadership-tile--${position}`, {
        'is-active': isActive,
      })}
    >
      <div className={classNames('cor-leadership-tile__container')}>
        {(picture || name || jobTitle) && (
          <div
            data-testid="cor-leadership-tile__thumbnail"
            className="cor-leadership-tile__thumbnail"
            onClick={($event) => showPopUp($event.currentTarget, activeStateHandler)}
            onMouseOver={($event) => showPopUp($event.currentTarget, activeStateHandler)}
          >
            {<SharedElements image={image} jobTitle={jobTitle} name={name} />}
          </div>
        )}

        {description && isActive && (
          <div
            data-testid={'cor-leadership-tile__active-description'}
            className={'cor-leadership-tile__active-description'}
          >
            {(picture || name || jobTitle) && (
              <div data-testid="cor-leadership-tile__thumbnail-active" className="cor-leadership-tile__thumbnail">
                {<SharedElements image={image} jobTitle={jobTitle} name={name} />}
              </div>
            )}
            <div className={'cor-leadership-tile__description'}>
              <div className="cor-leadership-tile__description-container">
                <CorContentstackHtml contentKey={'biography'} />
              </div>
              <button
                onClick={($event) => closePopUp($event, closeHandler)}
                className={'cor-leadership-tile__close-button'}
              >
                close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
