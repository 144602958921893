import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { resendInvitationToPendingUsersActionConstants, resendInvitationToPendingUsers } from 'store/account/actions';
import { IResendInvitationToPendingUserPayload } from 'types/user-info';
import { IAction } from 'types/actions';

function* reinvitePendingUserSagaHandler({ payload }: IAction<IResendInvitationToPendingUserPayload>) {
  yield put(doRequest(resendInvitationToPendingUsers, `${Endpoints.REINVITE_USER}`, 'put', payload));
}

export default function* reinvitePendingUserSaga() {
  yield takeLatest(resendInvitationToPendingUsersActionConstants[Actions.REQUEST], reinvitePendingUserSagaHandler);
}
