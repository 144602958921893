import { useEffect } from 'react';

export const useScrollToRef = ({ isError, ref }) => {
  useEffect(() => {
    if (ref.current && isError) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};
