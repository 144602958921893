import { FC } from 'react';
import classNames from 'classnames';
import { Modal, IModalProps } from 'components/modals/common/modal';
import { CustomVideo, IVideoConfig } from 'components/custom-video';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';

import './video-modal.scss';

export interface IVideoModalProps extends IModalProps {
  className?: string;
  text: {
    title: string;
    description: string;
    closeButtonLabel: string;
  };
  videoConfig: IVideoConfig;
  autoPlay?: boolean;
}

export const VideoModal: FC<IVideoModalProps> = ({
  isOpen,
  onClose,
  text,
  videoConfig,
  className,
  autoPlay = true,
}) => {
  return (
    <Modal
      className={classNames(className, 'video-modal')}
      isOpen={isOpen}
      onClose={onClose}
      size="medium_large"
      withBackdropClick
    >
      <ModalHeader>{text.title && <h2 className="video-modal__title">{text.title}</h2>}</ModalHeader>
      <div className="video-modal__video">
        <CustomVideo autoPlay={autoPlay} videoConfig={videoConfig} />
      </div>
      {text.description && (
        <div className="video-modal__description" dangerouslySetInnerHTML={{ __html: text.description }} />
      )}
      <ModalFooter>
        <CorButton className="video-modal__close-button" color="secondary" onClick={onClose}>
          {text.closeButtonLabel}
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
