import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCartInfo } from 'store/shopping-cart/selectors';
import { getCartInfoSummary } from 'utils/get-cart-info-summary';
import {
  useReorderSuccessModal,
  useAllItemsAddedToCartResultMessageModal,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';
import { ICartInfo } from 'store/shopping-cart/reducer';

interface IUseReorderProps {
  showControlsInAddToCartNotification?: boolean;
  onAddToCartNotificationClose?: () => void;
  mapCartInfo?: (cartInfo: ICartInfo) => ICartInfo;
  recipeDetailContentstackPath?: string;
}

export const useReorder = (props?: IUseReorderProps) => {
  const { recipeDetailContentstackPath, showControlsInAddToCartNotification, mapCartInfo = (info) => info } =
    props || {};
  const dispatch = useDispatch();
  const cartInfo = useSelector(selectCartInfo);
  const showReorderSuccessModal = useReorderSuccessModal({
    onRequestQuoteCtaClick: (skuKey) => showRequestQuoteModal({ skuKey }),
  });
  const showAllItemsAddedToCartResultMessageModal = useAllItemsAddedToCartResultMessageModal({
    showControls: showControlsInAddToCartNotification,
  });
  const showRequestQuoteSuccessMessageModal = useRequestQuoteSuccessMessageModal({
    onClose: () => showReorderSuccessModal({ orderInfo: cartInfo }),
  });
  const showRequestQuoteModal = useRequestQuoteModal({
    requestQuoteSuccessCallBack: () => showRequestQuoteSuccessMessageModal(),
    onClose: () => showReorderSuccessModal({ orderInfo: cartInfo }),
    recipeDetailPageContentstackPath: recipeDetailContentstackPath,
  });

  useEffect(() => {
    const { addedItemsCounter, errors, hasAddedItems, hasErrors, hasSomeInvalidItems } = getCartInfoSummary(cartInfo);

    if (hasSomeInvalidItems) {
      showReorderSuccessModal({ orderInfo: mapCartInfo(cartInfo) });
    } else if (hasAddedItems || hasErrors) {
      showAllItemsAddedToCartResultMessageModal({ messages: { successfullyAddedItems: addedItemsCounter, errors } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, cartInfo]);
};
