import './cor-cards-mobile.scss';

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ICorFeaturedContentCardsVariant } from 'corporate-components/cor-featured-content/cor-featured-content.interface';

const CorCardsMobile = ({ children, isSmallCardVariant }: React.PropsWithChildren<ICorFeaturedContentCardsVariant>) => {
  return (
    <div className="grid-container cor-featured-content__cards-mobile">
      <Swiper slidesPerView="auto" spaceBetween={12}>
        {React.Children.map(children, (child: React.ReactNode, index) => (
          <SwiperSlide key={index.toString()}>{child}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default React.memo(CorCardsMobile);
