import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { changeCustomerEmail, changeCustomerEmailConstants } from 'store/dashboard/actions';

interface IChangeEmailData {
  newEmail: string;
}

export interface IChangeEmailPayload extends IChangeEmailData {
  accountId: string;
  onSuccessCallback?: () => void;
}

export function* changeCustomerEmailAddressHandler({
  payload: { accountId, ...requestData },
}: IAction<IChangeEmailPayload>) {
  yield put(
    doRequest<IChangeEmailData>(
      changeCustomerEmail,
      `${Endpoints.DASHBOARD_CSR_CUSTOMERS}/${accountId}/emails`,
      'put',
      requestData
    )
  );
}

export function* changeCustomerEmailAddressSuccessHandler({ payload }: IAction<IChangeEmailPayload>) {
  yield take(changeCustomerEmailConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export default function* changeCustomerEmailAddressSaga() {
  yield takeLatest(changeCustomerEmailConstants[Actions.REQUEST], changeCustomerEmailAddressHandler);
  yield takeLatest(changeCustomerEmailConstants[Actions.REQUEST], changeCustomerEmailAddressSuccessHandler);
}
