import './cor-footer-accordion.scss';

import React from 'react';
import { CorContentstackLink } from 'corporate-components/cor-contentstack';
import classNames from 'classnames';
import { ContentstackText } from 'components/contentstack';
import { IconWithHover } from 'components/icon-with-hover';
import iconArrowRed from 'assets/images/icons/icon-single-arrow-red.svg';

const CorFooterAccordion = ({ contentKey = '', menuItems = [] }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div
      className={classNames('grid-container cor-footer__menu-link__container', {
        'menu-link__container--expanded': isExpanded,
      })}
    >
      <CorContentstackLink
        contentKey={`${contentKey}.menu_section_title`}
        className={classNames('cor-footer__menu-link__title', {
          'cor-footer__menu-link__title--no-menu-links': !menuItems?.length,
          'cor-footer__menu-link__title--expanded': isExpanded,
        })}
        fallbackElement="div"
        role="button"
        aria-expanded={isExpanded}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <ContentstackText contentKey={`${contentKey}.menu_section_title.title`} />{' '}
        <IconWithHover icon={iconArrowRed} hoverIcon={iconArrowRed} alt="Menu Item Icon" />
      </CorContentstackLink>
      <div
        className={classNames('cor-footer__menu-link__content', {
          'cor-footer__menu-link__content--expanded': isExpanded,
        })}
      >
        {menuItems?.map((linkSet: any, k) => (
          <ul key={k.toString()}>
            {!!linkSet?.menu_link_set_title && (
              <h4 className="cor-footer__menu-link__sub-title">{linkSet?.menu_link_set_title}</h4>
            )}
            {linkSet?.menu_links?.map(({ opens_in_a_new_tab = false }: any, y) => (
              <li key={y.toString()} className="cor-footer__menu-link__item">
                <CorContentstackLink
                  contentKey={`${contentKey}.menu_link_set[${k}].menu_links[${y}].link`}
                  className={classNames('cor-footer__menu-link__child-link')}
                  opensInNewTab={opens_in_a_new_tab}
                />
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default React.memo(CorFooterAccordion);
