import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getRecentlyViewedItems, getRecentlyViewedItemsConstants } from 'store/home/actions';

export function* getRecentlyViewedItemsHandler() {
  yield put(doRequest(getRecentlyViewedItems, Endpoints.RECENTLY_VIEWED_ITEMS, 'get'));
}

export default function* getRecentlyViewedItemsSaga() {
  yield takeLatest(getRecentlyViewedItemsConstants[Actions.REQUEST], getRecentlyViewedItemsHandler);
}
