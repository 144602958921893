import './cor-footer-social-networks.scss';

import React from 'react';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { CorContentstackLink } from 'corporate-components/cor-contentstack';
import { ContentstackImage } from 'components/contentstack';
import { IconWithHover } from 'components/icon-with-hover';
import { useContent } from 'hooks/use-content';

const LOGO_KEY = 'footer_logo_group.image';
const SOCIAL_NETWORKS_KEY = 'social_links';

const CorFooterSocialNetworks = () => {
  const { getContentByKey } = useContent();
  const socialNetworks: any[] = getContentByKey(SOCIAL_NETWORKS_KEY, []);
  const logo: any = getContentByKey(LOGO_KEY, {});

  return (
    <div className="grid-container cor-footer-mobile__social-networks">
      <CorContentstackLink
        fallbackElement="div"
        contentKey="footer_logo_group.image.link_url"
        className="social-networks__logo"
        opensInNewTab={!!logo?.opens_in_a_new_tab}
        data-testid="logo"
      >
        <ContentstackImage contentKey="footer_logo_group.image.image" />
      </CorContentstackLink>

      {!!socialNetworks?.length && (
        <div className="cor-footer__social-networks" data-testid="social-networks">
          {socialNetworks?.map(({ icon }, index) => {
            const iconUrl = corContentstackImageLinkResolver(icon)?.url || '';

            return (
              <CorContentstackLink
                key={index.toString()}
                contentKey={`${SOCIAL_NETWORKS_KEY}[${index}].icon.link_url`}
                opensInNewTab
              >
                <IconWithHover icon={iconUrl} hoverIcon={iconUrl} alt={icon?.link_url?.title} />
              </CorContentstackLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(CorFooterSocialNetworks);
