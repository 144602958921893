import qs from 'qs';
import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getInternalUserQuotes, getInternalUserQuotesActionConstants } from 'store/quotes/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IGetQuotesPayload {
  withoutLoading?: boolean;
  limit: number;
  offset: number;
  sortDirection?: string;
  statuses?: string;
  searchText?: string;
}

export function* getInternalUserQuotesSagaHandler({
  payload: { limit, offset, sortDirection, statuses, searchText },
}: IAction<IGetQuotesPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);

  yield put(
    doRequest(
      getInternalUserQuotes,
      `${Endpoints.DASHBOARD_QUOTES}?${qs.stringify(
        {
          limit,
          offset,
          sortDirection,
          statuses,
          searchText: searchQuery,
        },
        { skipNulls: true }
      )}`,
      'get'
    )
  );
}

export default function* getInternalUserQuotesSaga() {
  yield takeLatest(getInternalUserQuotesActionConstants[Actions.REQUEST], getInternalUserQuotesSagaHandler);
}
