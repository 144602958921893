import { FC, useCallback } from 'react';
import { Formik } from 'formik';
import { pick } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { clearChangePasswordFormServerError } from 'store/account/actions';
import { selectPersonalInformation } from 'store/account/selectors';
import { PasswordField } from 'components/forms/password-field';
import { FormWithValidationOnSubmit, IFormState } from 'components/forms/form-with-validation-on-submit';
import { ErrorNotification } from 'components/forms/error-notification';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackText } from 'components/contentstack';
import { useContent } from 'hooks/use-content';
import {
  requiredValidator,
  newPasswordValidator,
  confirmPasswordValidator,
  createValidationSchema,
} from 'utils/validation';
import { SubmitButton } from 'components/forms/submit-button';
import { CorButton } from 'components/cor-button';

import './change-password-modal.scss';

export interface IChangePasswordModalProps extends IModalProps {
  onSave: (values: IChangePasswordFormValues, { resetForm }: FormikHelpers<IChangePasswordFormValues>) => void;
}

export interface IChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const initialValues: IChangePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const PATH_TO_MODAL = 'modals.0.change_password_modal.0';
const PATH_TO_FORM = `${PATH_TO_MODAL}.change_password_form.0`;

export const ChangePasswordModal: FC<IChangePasswordModalProps> = ({ isOpen = false, onSave, onClose = () => {} }) => {
  const { getContentByKey, getMessageText } = useContent();
  const dispatch = useDispatch();
  const { changePasswordFormServerError, changePasswordFormIsSubmitting } = useSelector(selectPersonalInformation);

  const validationSchema = createValidationSchema({
    oldPassword: requiredValidator(getMessageText('error', 'MSG010')),
    newPassword: newPasswordValidator(
      {
        required: getMessageText('error', 'MSG010'),
        wrongFormat: getMessageText('error', 'MSG011'),
      },
      'confirmPassword'
    ),
    confirmPassword: confirmPasswordValidator(
      {
        required: getMessageText('error', 'MSG010'),
        wrongFormat: getMessageText('error', 'MSG011'),
        doNotMatch: getMessageText('error', 'MSG012'),
      },
      'newPassword'
    ),
  });

  const clearServerError = useCallback(() => {
    if (changePasswordFormServerError) {
      dispatch(clearChangePasswordFormServerError());
    }
  }, [changePasswordFormServerError, dispatch]);

  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');

  const closeModalHandler = (dataWasChanged: boolean, resetForm: () => void) => {
    if (dataWasChanged) {
      const closingIsAllowed = window.confirm(leavingPageModalText);

      if (closingIsAllowed) {
        resetForm();
        dispatch(clearChangePasswordFormServerError());
        onClose();
      }

      return;
    }

    onClose();
  };

  const containsEmptyRequiredFields = (values: IChangePasswordFormValues) =>
    Object.values(values).some((value) => value === '');

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSave}
    >
      {(props) => {
        const { values, handleChange, resetForm, dirty, errors, setErrors, setFieldValue } = props;
        return (
          <FormWithValidationOnSubmit
            className={changePasswordFormServerError ? 'change-password-modal__form--invalid' : ''}
            {...pick(props, ['handleSubmit', 'isValid', 'dirty'])}
            onChange={() => {
              clearServerError();
            }}
          >
            {({ setInvalidFormIsSubmitted, invalidFormIsSubmitted }: IFormState) => {
              const createFieldOnClickHandler = (fieldName: keyof IChangePasswordFormValues) => () => {
                if (errors[fieldName] || changePasswordFormServerError) {
                  clearServerError();
                  setInvalidFormIsSubmitted(false);
                  setFieldValue(fieldName, '');
                  setErrors({ ...errors, [fieldName]: undefined });
                }
              };
              return (
                <>
                  <Modal
                    className="change-password-modal"
                    isOpen={isOpen}
                    onClose={() => {
                      closeModalHandler(dirty, resetForm);
                      setInvalidFormIsSubmitted(false);
                    }}
                    withBackdropClick
                  >
                    <ModalHeader>
                      <h2 className="change-password-modal__title">
                        <ContentstackText contentKey={`${PATH_TO_FORM}.title`} />
                      </h2>
                    </ModalHeader>
                    <div className="change-password-modal__content">
                      {(invalidFormIsSubmitted || changePasswordFormServerError) && (
                        <ErrorNotification
                          message={changePasswordFormServerError || getMessageText('error', 'MSG019')}
                        />
                      )}

                      <div>
                        <p className="change-password-modal__description">
                          <ContentstackText contentKey={`${PATH_TO_FORM}.description`} />
                        </p>
                      </div>

                      <div className="change-password-modal__fields-container">
                        <PasswordField
                          id="oldPassword"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.current_password_field_label`, '')}
                          name="oldPassword"
                          onChange={handleChange}
                          onClick={createFieldOnClickHandler('oldPassword')}
                          onFocus={() => {
                            const { oldPassword, ...restErrors } = errors;
                            if (oldPassword || changePasswordFormServerError) {
                              clearServerError();
                              setFieldValue('oldPassword', '');
                              setInvalidFormIsSubmitted(false);
                              setErrors(restErrors);
                            }
                          }}
                          passwordVisibilityToggle
                          autoComplete
                        />
                        <PasswordField
                          id="newPassword"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.new_password_field_label`, '')}
                          name="newPassword"
                          onClick={createFieldOnClickHandler('newPassword')}
                          description={getContentByKey<string>(`${PATH_TO_FORM}.new_password_description`, '')}
                          onFocus={() => {
                            const { newPassword, ...restErrors } = errors;
                            if (newPassword || changePasswordFormServerError) {
                              setFieldValue('newPassword', '');
                              clearServerError();
                              setInvalidFormIsSubmitted(false);
                              setErrors(restErrors);
                            }
                          }}
                          passwordVisibilityToggle
                          autoComplete
                        />
                        <PasswordField
                          id="confirmPassword"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.confirm_password_field_label`, '')}
                          name="confirmPassword"
                          onClick={createFieldOnClickHandler('confirmPassword')}
                          onFocus={() => {
                            const { confirmPassword, ...restErrors } = errors;
                            clearServerError();
                            if (confirmPassword || changePasswordFormServerError) {
                              setFieldValue('confirmPassword', '');
                              setInvalidFormIsSubmitted(false);
                              setErrors(restErrors);
                            }
                          }}
                          passwordVisibilityToggle
                          autoComplete
                        />
                      </div>
                    </div>
                    <ModalFooter className="change-password-modal__footer">
                      <SubmitButton
                        className="personal-info__submit-btn"
                        type="submit"
                        isDisabled={changePasswordFormIsSubmitting || containsEmptyRequiredFields(values)}
                      >
                        <ContentstackText contentKey={`${PATH_TO_FORM}.save_button_title`} />
                      </SubmitButton>
                      <CorButton
                        className="text personal-info__cancel-btn"
                        onClick={() => closeModalHandler(dirty, resetForm)}
                      >
                        <ContentstackText contentKey={`${PATH_TO_FORM}.cancel_button_title`} />
                      </CorButton>
                    </ModalFooter>
                  </Modal>
                </>
              );
            }}
          </FormWithValidationOnSubmit>
        );
      }}
    </Formik>
  );
};
