import { FC } from 'react';
import classNames from 'classnames';
import { useContent } from 'hooks/use-content';
import { AccountStatuses } from 'constants/account-statuses';

import './account-status-badge.scss';

export interface IAccountStatusBadgeProps {
  accountStatus: AccountStatuses;
  isShort?: boolean;
}

export const AccountStatusBadge: FC<IAccountStatusBadgeProps> = ({ accountStatus, isShort = false }) => {
  const { getContentByKey } = useContent();
  const label = getContentByKey<string>(`account_statuses.0.status_${accountStatus.toLowerCase()}_label`, '');

  return accountStatus === AccountStatuses.ACTIVE ? null : (
    <div
      className={classNames('account-status-badge', `account-status-badge--${accountStatus.toLowerCase()}`, {
        'account-status-badge--short': isShort,
      })}
    >
      {isShort ? label[0] : label}
    </div>
  );
};
