import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { ContentstackImage, ContentstackText, ContentstackLink } from 'components/contentstack';
import { StickyView } from 'components/sticky-view';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { EnvironmentNotification } from 'components/environment-notification';
import { ImpersonationModeHeader } from 'components/impersonation-mode-header';
import { SiteWideBanner } from 'components/site-wide-banner';
import { useContent } from 'hooks/use-content';
import { selectIsInternalUser } from 'store/auth/selectors';
import { useEnvironment } from 'hooks/use-environment';
import { useStickyOffsets } from 'hooks/use-sticky-offsets';
import { StickySectionsKeys } from 'context/sticky-sections-heights-context';

import './checkout-header.scss';

export const CheckoutHeader = () => {
  const { getContentByKey } = useContent();
  const { isDesktop } = useBreakpoint();
  const { isProduction } = useEnvironment();
  const isInternalUser = useSelector(selectIsInternalUser);
  const logoUrl = getContentByKey<string>('header[0].header_checkout_link', '');

  const ref = useRef(null);
  const { offset } = useStickyOffsets({
    ref,
    sectionKey: StickySectionsKeys.checkoutHeader,
  });
  return (
    <>
      {!isProduction && <EnvironmentNotification />}
      {isInternalUser && <ImpersonationModeHeader />}
      <StickyView offsetTop={offset}>
        <div className="checkout-header" ref={ref}>
          <div className="grid-container checkout-header__container">
            <div className="checkout-header__content">
              <a href={logoUrl} className="checkout-header__logo" rel="noopener noreferrer">
                <ContentstackImage contentKey="header[0].header_checkout_logo" />
              </a>

              <div className="checkout-header__secure-block">
                <div className="checkout-header__secure-icon">
                  <ContentstackImage contentKey="header[0].header_checkout_text.checkout_header_icon" />
                </div>

                <p className="checkout-header__secure-status">
                  {isDesktop ? (
                    <ContentstackText contentKey="header[0].header_checkout_text.header_text_desktop" />
                  ) : (
                    <ContentstackText contentKey="header[0].header_checkout_text.header_text_mobile" />
                  )}
                </p>
              </div>

              {isDesktop && (
                <div className="checkout-header__continue-shopping-link">
                  <ContentstackLink contentKey="header[0].header_checkout_continue_shopping" />
                </div>
              )}
            </div>
          </div>
        </div>
      </StickyView>
      <SiteWideBanner />
    </>
  );
};
