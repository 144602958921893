import { takeLatest, put } from 'redux-saga/effects';
import { IAction } from 'types/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { impersonationStart, impersonationStartActionConstants } from 'store/auth/actions';
import { loadGASessionParams } from 'store/customer-login/sagas/get-session-params';

export interface IImpersonationStartPayload {
  accountId: string;
  locationId: string;
  redirectOnSuccessHandler: () => void;
}

let redirectOnSuccess: () => void;

function* impersonationStartSagaHandler({
  payload: { redirectOnSuccessHandler, ...data },
}: IAction<IImpersonationStartPayload>) {
  redirectOnSuccess = redirectOnSuccessHandler;
  yield put(doRequest(impersonationStart, Endpoints.IMPERSONATION_START, 'post', data));
}

function* impersonationStartRequestSuccessSagaHandler() {
  yield loadGASessionParams();
  redirectOnSuccess();
}

export default function* impersonationStartSaga() {
  yield takeLatest(impersonationStartActionConstants[Actions.REQUEST], impersonationStartSagaHandler);
  yield takeLatest(impersonationStartActionConstants[Actions.SUCCESS], impersonationStartRequestSuccessSagaHandler);
}
