import { format } from 'date-fns';
import {
  CorFeaturedContentContentTypeEnum,
  CorFeaturedContentContentTypeUidEnum,
} from 'constants/cor-featured-content.enum';
import { ICorFeaturedContentEntry } from './cor-featured-content.interface';

const corporateProductsMapper = (entry): ICorFeaturedContentEntry => {
  const image = entry?.modular_blocks?.find((currModularBlock) => {
    if (!currModularBlock?.hero?.hero) return false;
    return !!currModularBlock?.hero?.hero?.[0]?.hero_content?.[0]?.background_image;
  });

  return {
    pageUrl: { href: entry?.url },
    category: entry?.tags?.[0],
    title: entry?.title,
    description: entry?.short_description,
    image: image?.hero?.hero?.[0]?.hero_content?.[0]?.background_image || entry?.page_configuration?.search_metadata,
  };
};

const InsightsTagsAllowedSet = new Set(['news_events', 'bakery_advice', 'bakery_spotlights', 'inspiration']);
const InsightsTagsBooleanMap = new Map([
  ['news_events', 'News & Events'],
  ['bakery_spotlights', 'Bakery Spotlights'],
]);
const insightsMapper = (entry): ICorFeaturedContentEntry => {
  const category = Object.keys(entry?.filter_tags || {})
    ?.reduce((acc: string[], currKey: string) => {
      if (!InsightsTagsAllowedSet.has(currKey)) return [...acc];
      if (InsightsTagsBooleanMap.has(currKey) && !!entry?.filter_tags?.[currKey])
        return [...acc, InsightsTagsBooleanMap.get(currKey)];
      if (!entry?.filter_tags?.[currKey]?.length) return [...acc];

      return [...acc, entry?.filter_tags?.[currKey]?.[0]];
    }, [])
    ?.slice(0, 3)
    ?.join(' | ');

  return {
    pageUrl: { href: entry?.url },
    category,
    title: entry?.title,
    description: entry?.short_description,
    ...(!!entry?.publication_date && { publicationDate: format(new Date(entry.publication_date), 'MMMM d, yyyy') }),
    image:
      entry?.banner_image?.image || entry?.banner_image?.brand_folder_image?.length
        ? entry?.banner_image
        : entry?.page_configuration?.search_metadata,
  };
};

const recipesMapper = (entry): ICorFeaturedContentEntry => {
  return {
    pageUrl: { href: entry?.url },
    category: entry?.filter_tags?.product_type?.[0],
    title: entry?.title,
    description: entry?.short_description,
    ...(!!entry?.publication_date && { publicationDate: format(new Date(entry.publication_date), 'MMMM d, yyyy') }),
    image:
      entry?.banner_image?.image || entry?.banner_image?.brand_folder_image?.length
        ? entry?.banner_image
        : entry?.page_configuration?.search_metadata,
  };
};

const newsMapper = (entry): ICorFeaturedContentEntry => {
  return {
    pageUrl: { href: entry?.url },
    category: 'News',
    title: entry?.title,
    description: entry?.short_description,
    ...(!!entry?.publication_date && { publicationDate: format(new Date(entry.publication_date), 'MMMM d, yyyy') }),
    image:
      entry?.banner_image?.image || entry?.banner_image?.brand_folder_image?.length
        ? entry?.banner_image
        : entry?.page_configuration?.search_metadata,
  };
};

const EntriesContentTypeUidMapperMap = new Map([
  [CorFeaturedContentContentTypeUidEnum.CorporateProducts, corporateProductsMapper],
  [CorFeaturedContentContentTypeUidEnum.Insights, insightsMapper],
  [CorFeaturedContentContentTypeUidEnum.Recipes, recipesMapper],
  [CorFeaturedContentContentTypeUidEnum.News, newsMapper],
]);

export const EntriesContentTypeMapperMap = new Map([
  [CorFeaturedContentContentTypeEnum.CorporateProducts, corporateProductsMapper],
  [CorFeaturedContentContentTypeEnum.Insights, insightsMapper],
  [CorFeaturedContentContentTypeEnum.Recipes, recipesMapper],
  [CorFeaturedContentContentTypeEnum.News, newsMapper],
]);

export const mapFeaturedContentEntries = ({ entries = [], contentType = '' }: any): ICorFeaturedContentEntry[] => {
  return entries
    ?.filter(() => EntriesContentTypeMapperMap.has(contentType))
    ?.map((entry) => {
      const mapper = EntriesContentTypeMapperMap.get(contentType) || corporateProductsMapper;
      return mapper(entry);
    });
};

export const mapFeaturedContentUidEntries = (entries: any[] = []): ICorFeaturedContentEntry[] => {
  return entries
    ?.filter((entry) => EntriesContentTypeUidMapperMap.has(entry?._content_type_uid))
    ?.map((entry) => {
      const mapper = EntriesContentTypeUidMapperMap.get(entry?._content_type_uid) || corporateProductsMapper;
      return mapper(entry);
    });
};
