import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import {
  submitPersonalInformation,
  submitPersonalInformationActionConstants,
} from 'store/customer-registration/actions';
import { IAction } from 'types/actions';

export interface ISubmitPersonalInfoPayload {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  isAcceptTermsAndConditions: boolean;
  isMarkedForNotifications: boolean;
  userId?: string;
  salesOrg?: string;
  token?: string | null;
  lang?: string | null;
}

export function* submitPersonalInformationSagaHandler({ payload }: IAction<ISubmitPersonalInfoPayload>) {
  const locale = yield localStorage.getItem('locale');

  yield put(
    doRequest<ISubmitPersonalInfoPayload>(submitPersonalInformation, Endpoints.CREATE_PROFILE, 'post', {
      ...payload,
      lang: JSON.parse(locale),
    })
  );
}

export default function* submitPersonalInformationSaga() {
  yield takeLatest(submitPersonalInformationActionConstants[Actions.REQUEST], submitPersonalInformationSagaHandler);
}
