import './cor-detail-related-content.scss';

import classNames from 'classnames';
import { CorFeaturedContent } from 'corporate-components';
import { ContentstackContext } from 'context/contentstack';
import {
  CorFeaturedContentCardStyleEnum,
  CorFeaturedContentContentTypeEnum,
} from 'constants/cor-featured-content.enum';
import useCorDetailRelatedContent from './useCorDetailRelatedContent';

const CorDetailRelatedContent = ({
  baseKey = 'related_insights_section',
  relatedContentKey = 'related_insights_section.related_insights',
  contentType = CorFeaturedContentContentTypeEnum.Insights,
  className = '',
  cardDisplayNumber = 3,
  cardStyle = CorFeaturedContentCardStyleEnum.CardWithPublicationDate,
  carousel = false,
  textAlignment = 'Left',
  titleExclusion = '',
}) => {
  const contextAttributes = useCorDetailRelatedContent({
    baseKey,
    relatedContentKey,
    contentType,
    cardDisplayNumber,
    cardStyle,
    carousel,
    textAlignment,
    titleExclusion,
  });

  return (
    <ContentstackContext.Provider value={{ ...contextAttributes }}>
      <CorFeaturedContent className={classNames('cor-detail-related-content', { [className]: !!className })} />
    </ContentstackContext.Provider>
  );
};

export default CorDetailRelatedContent;
