import { FC } from 'react';
import { CorContentstackVideo } from 'corporate-components/cor-contentstack/cor-contentstack-video';
import './cor-video-component.scss';
import {
  CorContentstackVideoAsset,
  corContentstackVideoLinkResolver,
  ICSCorVideoProps,
} from 'corporate-utils/cor-contentstack-video-link-resolver';
import { get } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';

export const CorVideoComponent: FC = () => {
  const content = useContentstackContent();
  const video: ICSCorVideoProps = get(content, 'video', {});
  const videoData: CorContentstackVideoAsset = corContentstackVideoLinkResolver({
    ...video,
    fallbackImage: video.fallback_image?.url,
  });

  return (
    <section className="cor-video-component">
      <div className="grid-container">
        <div className="cor-video-component__video">
          <CorContentstackVideo data={videoData} muted={videoData.mute} />
        </div>
      </div>
    </section>
  );
};
