import {
  CorFeaturedContentCardStyleEnum,
  CorFeaturedContentContentTypeEnum,
} from 'constants/cor-featured-content.enum';
import { useContent } from 'hooks/use-content';

interface ICorDetailRelatedContent {
  baseKey: string;
  relatedContentKey: string;
  contentType: CorFeaturedContentContentTypeEnum;
  cardDisplayNumber: number;
  cardStyle: CorFeaturedContentCardStyleEnum;
  carousel: boolean;
  textAlignment: string;
  titleExclusion: string;
}

const useCorDetailRelatedContent = ({
  baseKey = '',
  relatedContentKey = '',
  contentType = CorFeaturedContentContentTypeEnum.Insights,
  cardDisplayNumber,
  cardStyle,
  carousel,
  textAlignment,
  titleExclusion,
}: ICorDetailRelatedContent) => {
  const { getContentByKey, getLabelsByKey } = useContent();
  const title = getContentByKey(`${baseKey}.section_title`, '');
  const references = getContentByKey(relatedContentKey, []);
  const labels = getLabelsByKey<Record<string, string>>(`${baseKey}.see_all_button.0.key_value_pair.value`, {});

  return {
    featured_content: {
      featured_content: {
        container_background_color: 'White',
        references,
        content_type: contentType,
        card_display_number: cardDisplayNumber,
        card_style: cardStyle,
        carousel,
      },
      title,
      text_alignment: textAlignment,
      button: {
        button: {
          href: labels?.['related_content.see_all_button_url'],
          title: labels?.['related_content.see_all_button_label'],
        },
        button_style: 'Secondary',
        open_in_a_new_tab: false,
        button_row: false,
        button_icon: {},
        brand_folder_asset: {},
      },
      title_exclusion: titleExclusion,
    },
  };
};

export default useCorDetailRelatedContent;
