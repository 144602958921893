import CorTypography from 'corporate-ui/cor-typography/cor-typography';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { FilterMenuTypeEnum } from 'corporate-components/cor-filtered-list/cor-filtered-list.config';
import { FilterMenuTypeMap } from 'corporate-components/cor-filtered-list/cor-filtered-list.mapper';
import { useAlgoliaQueryParams } from 'hooks';
import classNames from 'classnames';
import * as contentStackConstant from 'corporate-components/cor-filtered-list/cor-filtered-list-constants';
import { CorFilterExpandableSection } from 'corporate-components/cor-filtered-list/components/cor-filter-expandable-section';
import { CorFilterDropdownOption } from 'corporate-components/cor-filtered-list/components/cor-filter-dropdown-option';
import { CorClearFilterButton } from 'corporate-components/cor-filtered-list/components/cor-clear-filter-button';
import { IFacet } from 'queries/use-algolia-query';
import { useContent } from 'hooks/use-content';
import { selectTemporaryFacets } from 'store/cor-temporary-facets/selectors';
import { setTemporarySelectedFilters } from 'store/cor-temporary-facets/actions';
import { ALGOLIA_DEFAULT_PAGE_NUMBER } from 'hooks/use-algolia';
import { IconWithHover } from 'components/icon-with-hover';
import iconCaretLeft from 'assets/images/icons/icon-caret-left-red.svg';
import useAlgolia from 'hooks/use-algolia';

import './cor-filters-drawer.scss';

const CorFiltersDrawer = ({ onClose = () => {} }) => {
  const dispatch = useDispatch();
  const { getContentByKey, getLabelsByKey } = useContent();
  const [facetsData, setFacets] = useState<IFacet[]>([]);
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const temporaryFacets = useSelector(selectTemporaryFacets);
  const labels = getLabelsByKey<Record<string, string>>(contentStackConstant.LABELS_KEY, {});
  const filterMenuType = getContentByKey<FilterMenuTypeEnum>(
    contentStackConstant.FILTER_MENU_TYPE_KEY,
    FilterMenuTypeEnum.Recipes
  );
  const pageType = FilterMenuTypeMap.get(filterMenuType);
  const [selectionsState, setSelectionsState] = useState<Record<string, string[]>>(() =>
    Object.keys(queryParams?.facets || {}).reduce((acc, curr) => {
      return { ...acc, [curr]: JSON.parse(queryParams?.facets?.[curr] || '[]') };
    }, {})
  );

  const handleSelection = useCallback(
    ({ facetKey = '', facetTerm = '' }: { facetKey: string; facetTerm: string }) => () => {
      setSelectionsState((prev) => ({
        ...prev,
        [facetKey]: (prev?.[facetKey] || [])?.includes(facetTerm)
          ? prev?.[facetKey]?.filter((current) => ![current].includes(facetTerm))
          : [...(prev?.[facetKey] || []), facetTerm],
      }));
    },
    []
  );

  const facets = useMemo(
    () =>
      Object.keys(selectionsState).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: !!(selectionsState?.[curr] || [])?.length ? JSON.stringify(selectionsState?.[curr]) : undefined,
        }),
        {}
      ),
    [selectionsState]
  );

  const handleClearFilter = useCallback(() => {
    setQueryParams({ facets: undefined, page: ALGOLIA_DEFAULT_PAGE_NUMBER });
    setSelectionsState({});
  }, [setQueryParams]);

  const onConfirmFacets = () => {
    setQueryParams({ facets, page: ALGOLIA_DEFAULT_PAGE_NUMBER });
    onClose();
  };

  const setFacetTerms = () => {
    let facets = {};

    algoliaFacetsData?.facets.forEach((facet) => {
      if (facet.key) facets[facet.key] = Object.keys(facet.terms);
    });

    return facets;
  };

  const algoliaFacetsQuery = useAlgolia({
    page: ALGOLIA_DEFAULT_PAGE_NUMBER,
    facets: facets,
    pageType,
  });

  const algoliaFacetsData = algoliaFacetsQuery?.data?.globalPageTypeContents?.[0];

  useEffect(() => {
    if (algoliaFacetsData?.facets) dispatch(setTemporarySelectedFilters(setFacetTerms()));
    if (algoliaFacetsData?.facets && isEmpty(facetsData)) setFacets(algoliaFacetsData?.facets);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algoliaFacetsData?.facets]);

  return (
    <div className={classNames('cor-filters-drawer', 'cor-filters-drawer--opened')}>
      <div className="cor-filters-drawer__header">
        <div className="grid-container">
          <div>
            <button
              className="cor-filters-drawer__header-button cor-filters-drawer__header-button--close-drawer"
              onClick={onClose}
            >
              <IconWithHover icon={iconCaretLeft} hoverIcon={iconCaretLeft} alt="Close Filter Drawer" />
            </button>
            <button
              className="cor-filters-drawer__header-button cor-filters-drawer__header-button--confirm"
              onClick={onConfirmFacets}
            >
              <CorTypography variant="body-regular-link" component="span">
                {labels?.button_apply_filters}
              </CorTypography>
            </button>
          </div>

          <CorTypography variant="h3" data-testid="cor-filters-drawer__title">
            {labels?.filters || 'Filters'}
          </CorTypography>
          <CorClearFilterButton onClick={handleClearFilter} />
        </div>
      </div>
      <div className="cor-filters-drawer__body">
        {temporaryFacets &&
          !isEmpty(facetsData) &&
          facetsData.map((facet) => {
            if (isEmpty(facet)) return null;

            const facetTerms = temporaryFacets?.[facet.key];
            const isBooleanControl = facetTerms?.some((facetTerm) => ['true', 'false'].includes(facetTerm));

            if (
              !facetTerms?.length ||
              (isBooleanControl && !facetTerms?.some((facetTerm) => ['true'].includes(facetTerm)))
            )
              return null;

            return (
              <CorFilterExpandableSection
                key={facet.label.concat('true')}
                buttonLabel={facet.label}
                isDefaultExpanded={!!selectionsState?.[facet.key]}
              >
                {facetTerms
                  .map((facetTerm) => {
                    return (
                      <CorFilterDropdownOption
                        key={facetTerm}
                        label={isBooleanControl ? facet.label : facetTerm}
                        onCheckboxClick={handleSelection({
                          facetKey: facet.key,
                          facetTerm: isBooleanControl ? 'true' : facetTerm,
                        })}
                        isSelected={
                          isBooleanControl
                            ? selectionsState?.[facet.key]?.includes('true')
                            : selectionsState?.[facet.key]?.includes(facetTerm)
                        }
                      />
                    );
                  })
                  ?.slice(0, isBooleanControl ? 1 : facetTerms.length)}
              </CorFilterExpandableSection>
            );
          })}
      </div>
    </div>
  );
};

export default React.memo(CorFiltersDrawer);
