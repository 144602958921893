import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { generateTemporaryPassword, generateTemporaryPasswordActionConstants } from '../actions';

export interface IGenerateTemporaryPasswordPayload {
  internalId: string;
  onSuccess: (password: string) => void;
}

export interface ITemporaryPasswordPayload {
  password: string;
}

let successHandler: (password: string) => void = () => {};

export function* generateTemporaryPasswordHandler({
  payload: { internalId, onSuccess },
}: IAction<IGenerateTemporaryPasswordPayload>) {
  successHandler = onSuccess;
  yield put(doRequest(generateTemporaryPassword, `${Endpoints.GENERATE_TEMPORARY_PASSWORD}/${internalId}`, 'put'));
}

export function generateTemporaryPasswordSuccessHandler({ payload }: IAction<ITemporaryPasswordPayload>) {
  successHandler(payload.password);
}

export default function* generateTemporaryPasswordSaga() {
  yield takeLatest(generateTemporaryPasswordActionConstants[Actions.REQUEST], generateTemporaryPasswordHandler);
  yield takeLatest(generateTemporaryPasswordActionConstants[Actions.SUCCESS], generateTemporaryPasswordSuccessHandler);
}
