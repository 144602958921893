import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { optOutForm, optOutFormActionConstants } from 'store/opt-out/actions';
import { IAction } from 'types/actions';

export interface IOptOutPayload {
  name: string;
  email: string;
  requests: string[];
}

export function* optOutFormSagaHandler({ payload }: IAction<IOptOutPayload>) {
  yield put(doRequest<IOptOutPayload>(optOutForm, Endpoints.OPT_OUT, 'post', payload));
}

export default function* optOutFormSaga() {
  yield takeLatest(optOutFormActionConstants[Actions.REQUEST], optOutFormSagaHandler);
}
