import { combineReducers } from 'redux';
import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import { ISkuInformation } from 'types/order-details';
import { ILineItem } from 'types/line-item';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import * as actions from './actions';
import types from './action-types';

interface ISkuReplacementsState {
  loading: boolean;
  skuReplacementsData: ISkuInformation[];
}

interface IDiscontinuedItemsWithReplacementsState {
  loading: boolean;
  discontinuedItemsWithReplacements: ISkuInformation[];
}

export interface IItemsReplacementsState {
  skuReplacements: ISkuReplacementsState;
  discontinuedItemsWithReplacements: IDiscontinuedItemsWithReplacementsState;
}

const skuReplacementsInitialState: ISkuReplacementsState = {
  loading: false,
  skuReplacementsData: [],
};

const discontinuedItemsWithReplacementsInitialState: IDiscontinuedItemsWithReplacementsState = {
  loading: false,
  discontinuedItemsWithReplacements: [],
};

export const itemsReplacementsInitialState: IItemsReplacementsState = {
  skuReplacements: skuReplacementsInitialState,
  discontinuedItemsWithReplacements: discontinuedItemsWithReplacementsInitialState,
};

const skuReplacements = createReducer<ISkuReplacementsState>(
  {
    [actions.getSkuReplacementsActionConstants[Actions.REQUEST]]: () => ({
      loading: true,
      skuReplacementsData: [],
    }),
    [actions.getSkuReplacementsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      loading: false,
      skuReplacementsData: payload,
    }),
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      skuReplacementsData: state.skuReplacementsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
    }),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      skuReplacementsData: state.skuReplacementsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
    }),
    [actions.getSkuReplacementsActionConstants[Actions.FAIL]]: () => ({
      loading: false,
      skuReplacementsData: [],
    }),
    [types.CLEAR_SKU_REPLACEMENTS]: () => skuReplacementsInitialState,
  },
  skuReplacementsInitialState
);

const discontinuedItemsWithReplacements = createReducer<IDiscontinuedItemsWithReplacementsState>(
  {
    [actions.getDiscontinuedItemsReplacementsActionConstants[Actions.REQUEST]]: () => ({
      loading: true,
      discontinuedItemsWithReplacements: [],
    }),
    [actions.getDiscontinuedItemsReplacementsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      loading: false,
      discontinuedItemsWithReplacements: payload,
    }),
    [actions.getDiscontinuedItemsReplacementsActionConstants[Actions.FAIL]]: () => ({
      loading: false,
      discontinuedItemsWithReplacements: [],
    }),
    [types.CLEAR_DISCONTINUED_ITEMS_REPLACEMENTS]: () => discontinuedItemsWithReplacementsInitialState,
  },
  discontinuedItemsWithReplacementsInitialState
);

export default combineReducers({
  skuReplacements,
  discontinuedItemsWithReplacements,
});
