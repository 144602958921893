import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'config/app';
import { selectUser } from 'store/auth/selectors';
import { IUserInfo } from 'types/user-info';
import { getLocaleQuery } from 'utils/get-locale-params';
import algoliaQuerySelect from './algolia-query.select';
import { IAlgoliaProps, IAlgoliaRawResponse, IAlgoliaResponse } from './use-algolia-query.interface';

const fetchAlgoliaRecords = async (props: IAlgoliaProps) => {
  const { categoryKeys, pageOffset, isTotalShopProductsCounted, ...otherProps } = props;

  const { data } = await axios.post<IAlgoliaRawResponse>(
    `${config.apiUrl}/catalog/search${getLocaleQuery()}`,
    otherProps,
    {
      withCredentials: true,
    }
  );

  return data;
};

const useAlgoliaQuery = (props: IAlgoliaProps) => {
  const user: IUserInfo = useSelector(selectUser);

  return useQuery<IAlgoliaRawResponse, Error, IAlgoliaResponse>(
    [
      'bff.algolia',
      props?.facets,
      props?.filters,
      props?.limit,
      props?.offset,
      props?.sortOptions,
      props?.text,
      props.pageOffset,
      user.internalId,
    ],
    () => fetchAlgoliaRecords(props),
    {
      select: algoliaQuerySelect({
        categoryKeys: props?.categoryKeys,
        pageOffset: props.pageOffset,
        isTotalShopProductsCounted: props?.isTotalShopProductsCounted,
      }),
    }
  );
};

export default useAlgoliaQuery;
