import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { Routes } from 'constants/routes.enum';
import { clearCachedParams, resetUserIdBeenSentState, resetInternalUserRole } from 'utils/analytics';
import { getUserInfo, impersonationStop, impersonationStopActionConstants } from 'store/auth/actions';

function* impersonationStopSagaHandler() {
  yield put(doRequest(impersonationStop, Endpoints.IMPERSONATION_STOP, 'post'));
}

function* impersonationStopRequestSuccessFailSagaHandler() {
  const currentLocation = window.location.pathname;
  clearCachedParams();
  resetUserIdBeenSentState();
  resetInternalUserRole();

  if (currentLocation.includes(Routes.DashboardPage)) {
    yield put(getUserInfo.request());
  } else {
    yield put(push(Routes.DashboardPage));
  }
}

export default function* impersonationStopSaga() {
  yield takeLatest(impersonationStopActionConstants[Actions.REQUEST], impersonationStopSagaHandler);
  yield takeLatest(
    [impersonationStopActionConstants[Actions.SUCCESS], impersonationStopActionConstants[Actions.FAIL]],
    impersonationStopRequestSuccessFailSagaHandler
  );
}
