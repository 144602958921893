import { FC } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { CorButton } from 'components/cor-button';
import { CustomTooltip } from 'components/custom-tooltip';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { selectIsUserLocationDeleted } from 'store/auth/selectors';

import './product-replacement-line-item-buttons.scss';

export interface IProductReplacementLineItemButtonsProps {
  contentstackPath: string;
  sku: string;
  isSpecialOrder: boolean;
  active: boolean;
  disabled: boolean;
  onAddToCartClick: () => void;
  onRequestQuoteClick: () => void;
}

export const ProductReplacementLineItemButtons: FC<IProductReplacementLineItemButtonsProps> = ({
  contentstackPath,
  sku,
  isSpecialOrder,
  active,
  disabled,
  onAddToCartClick,
  onRequestQuoteClick,
}) => {
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);

  const addToCartButtonClass = classNames('product-replacement-line-item-buttons__add-to-cart-btn', {
    active,
    disabled,
  });

  return !isSpecialOrder ? (
    <CorButton
      className={addToCartButtonClass}
      color="tertiary"
      disabled={disabled || isUserLocationDeleted}
      onClick={onAddToCartClick}
    >
      <ContentstackText contentKey={`${contentstackPath}.add_to_cart_button_label${active ? '_active' : ''}`} />
    </CorButton>
  ) : (
    <div className="product-replacement-line-item-buttons__request-quote-btn">
      <CorButton color="tertiary" disabled={isUserLocationDeleted} onClick={onRequestQuoteClick}>
        <ContentstackText contentKey={`${contentstackPath}.request_quote_button_label`} />
      </CorButton>
      <CustomTooltip tooltipId={`product-replacement-special-order-${sku}`} overrideTooltipPosition={false}>
        <ContentstackMessage type="tooltips" messageId="MSG107" />
      </CustomTooltip>
    </div>
  );
};
