import { all, spawn } from 'redux-saga/effects';
import getShoppingListsSaga from './get-shopping-lists';
import addProductToFavoritesSaga from './add-product-to-favorites';
import removeProductFromFavoritesSaga from './remove-product-from-favorites';
import getShoppingListDetailsSaga from './get-shopping-list-details';
import updateShoppingListNameSaga from './update-shopping-list-name';
import updateShoppingListItemSaga from './update-shopping-list-item';
import deleteShoppingListItemSaga from './delete-shopping-list-item';
import addProductsToShoppingListsSaga from './add-products-to-shopping-lists';
import createShoppingListWithProductsSaga from './create-shopping-list-with-products';
import createEmptyShoppingListSaga from './create-empty-shopping-list';
import importShoppingListSaga from './import-shopping-list';
import deleteShoppingListSaga from './delete-shopping-list';
import addShoppingListToCartSaga from './add-list-to-cart';
import changeLineItemsOrderSaga from './change-line-items-order';
import changeShoppingListsOrderSaga from './change-shopping-lists-order';
import addProductsFromMarketingShoppingListSaga from './add-products-from-marketing-shopping-list';
import createShoppingListWithProductsFromMarketingShoppingListSaga from './create-shopping-list-with-products-from-marketing-shopping-list';

export default function* shoppingListSagas() {
  yield all([
    spawn(getShoppingListsSaga),
    spawn(getShoppingListDetailsSaga),
    spawn(addProductToFavoritesSaga),
    spawn(removeProductFromFavoritesSaga),
    spawn(updateShoppingListNameSaga),
    spawn(updateShoppingListItemSaga),
    spawn(deleteShoppingListItemSaga),
    spawn(addProductsToShoppingListsSaga),
    spawn(createShoppingListWithProductsSaga),
    spawn(createEmptyShoppingListSaga),
    spawn(importShoppingListSaga),
    spawn(deleteShoppingListSaga),
    spawn(addShoppingListToCartSaga),
    spawn(changeLineItemsOrderSaga),
    spawn(changeShoppingListsOrderSaga),
    spawn(addProductsFromMarketingShoppingListSaga),
    spawn(createShoppingListWithProductsFromMarketingShoppingListSaga),
  ]);
}
