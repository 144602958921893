import { useQuery } from '@tanstack/react-query';
import { stringify } from 'qs';
import api from 'api';
import { Endpoints } from 'constants/endpoints.enum';
import contentstackSearchQueryQuerySelect from './contentstack-search-query-query.select';
import {
  IContentstackSearchQueryQuery,
  IContentstackSearchQueryQueryProps,
} from './use-contentstack-search-query-query.interface';

const fetchContentStackSearchQuery = async (props: IContentstackSearchQueryQueryProps) => {
  const { currentPage, pageOffset, ...params } = props;

  const { data } = await api.get(Endpoints.CONTENTSTACK_QUERY, {
    params,
    paramsSerializer: (args) => stringify(args, { arrayFormat: 'indices' }),
  });

  return data;
};

const useContentstackSearchQueryQuery = (props: IContentstackSearchQueryQueryProps) => {
  return useQuery<any, Error, IContentstackSearchQueryQuery>(
    [
      'bff.search.query',
      props?.limit,
      props?.skip,
      props?.contentTypeName,
      props?.sort,
      props?.inquiry,
      props?.include,
      props?.locale,
    ],
    () => fetchContentStackSearchQuery(props),
    {
      select: contentstackSearchQueryQuerySelect({ contentTypeName: props.contentTypeName }),
    }
  );
};

export default useContentstackSearchQueryQuery;
