export enum Layouts {
  Default = 1,
  Checkout,
  Auth,
  InternalUser,
  Basic,
  Corporate,
  LivePreview,
  AccountLayout,
}
