import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const sendNewPasswordActionConstants = createConstants(types.RESET_PASSWORD_SEND_NEW_PASSWORD);
export const validateTokenActionConstants = createConstants(types.RESET_PASSWORD_VALIDATE_TOKEN);
export const sendVerificationEmailActionConstants = createConstants(types.RESET_PASSWORD_SEND_VERIFICATION_EMAIL);

export const sendNewPassword = createRequestActions(sendNewPasswordActionConstants);
export const validateToken = createRequestActions(validateTokenActionConstants);
export const sendVerificationEmail = createRequestActions(sendVerificationEmailActionConstants);

export const resetEmailSendStatus = () => action(types.RESET_PASSWORD_RESET_EMAIL_SEND_STATUS);
export const setResetPasswordLinkExpired = (flag: boolean) => action(types.SET_RESET_PASSWORD_LINK_EXPIRED, flag);
