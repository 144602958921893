import { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CorFooter } from 'corporate-components';
import { CorPageLayout } from 'corporate-components/cor-layout/cor-page-layout';
import { CorHeader } from 'components/layout/cor-header';
import { selectIsAuthorised, selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { getCartInfo } from 'store/shopping-cart/actions';
import { Routes } from 'constants/routes.enum';
import { getCategoriesNavigation } from 'store/categories-navigation/actions';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import { useLocalStorage } from 'hooks/use-local-storage';

interface ICorporateLayoutProps extends PropsWithChildren {}

export const CorporateLayout: FC<ICorporateLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const isLoggedInUser: boolean = useSelector(selectIsAuthorised);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const location = useLocation();
  const [locale] = useLocalStorage('locale', null);

  useEffect(() => {
    if (!isLoggedInUser || isInvoiceOnlyUser || isShopLocaleInternational()) return;

    dispatch(
      getCartInfo.request({
        fullValidation: location.pathname === Routes.ShoppingCartPage,
      })
    );
    dispatch(getCategoriesNavigation.request());
  }, [dispatch, isLoggedInUser, isInvoiceOnlyUser, location.pathname, locale]);

  return (
    <CorPageLayout classModifier="corporate" header={CorHeader} footer={CorFooter}>
      {children}
    </CorPageLayout>
  );
};
