import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { IInvoiceItem } from 'types/invoices';
import { IInvoicesState, ISelectedPaymentMethod } from './reducers';

export const selectInvoicesEntity = (state: IState): IInvoicesState => state.app.invoices;

export const selectIsPaymentDocumentsUpdateFailed = (state: IState) =>
  state.app.invoices.isPaymentDocumentsUpdateFailed;

export const selectIsDataSyncInProgress = (state: IState) => state.app.invoices.isDataSyncInProgress;

export const selectInvoices = createSelector(selectInvoicesEntity, ({ invoices }): IInvoiceItem[] => invoices);

export const selectCreditLimit = createSelector(selectInvoicesEntity, ({ creditLimit }) => creditLimit);
export const selectPaginationCurrent = createSelector(selectInvoicesEntity, ({ offset, limit }) => offset / limit);
export const selectPaginationTotal = createSelector(selectInvoicesEntity, ({ total, limit }) =>
  Math.ceil(total / limit)
);

export const selectTotalOutstandingBalance = createSelector(
  selectInvoicesEntity,
  ({ totalOutstandingBalance }) => totalOutstandingBalance
);

export const selectInvoicesSummary = createSelector(
  selectInvoicesEntity,
  (invoicesEntity) => invoicesEntity.invoicesSummary
);

export const selectSelectedInvoices = createSelector(selectInvoicesEntity, ({ selectedInvoices }) => selectedInvoices);

export const selectSelectedPaymentMethod = (state: IState): ISelectedPaymentMethod | null =>
  state.app.invoices.selectedPaymentMethod;
