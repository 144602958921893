enum productDetailsActionTypes {
  GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS',
  RESET_PRODUCT_DETAILS = 'RESET_PRODUCT_DETAILS',
  SET_SELECTED_SKU = 'SET_SELECTED_SKU',
  GET_SKU_SUBTOTAL_PRICE = 'GET_SKU_SUBTOTAL_PRICE',
  SET_PRODUCT_QUANTITY = 'SET_PRODUCT_QUANTITY',
  PUT_RECENTLY_VIEWED_ITEM = 'PUT_RECENTLY_VIEWED_ITEM',
  GET_PRODUCT_RECIPES = 'GET_PRODUCT_RECIPES',
  CLEAR_PRODUCT_RECIPES = 'CLEAR_PRODUCT_RECIPES',
}

export default productDetailsActionTypes;
