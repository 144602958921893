import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getSkuTotalAmount, getSkuTotalAmountActionConstants } from 'store/quick-shop/actions';
import { IAction } from 'types/actions';

export interface IGetSkuTotalAmountPayload {
  skuNum: string;
  amount?: number;
}

export function* getSkuTotalAmountHandler({ payload: { skuNum, amount } }: IAction<IGetSkuTotalAmountPayload>) {
  const url = `${Endpoints.SKU_SUBTOTAL_PRICE}/${skuNum}/?amount=${amount || ''}`;
  yield put(doRequest(getSkuTotalAmount, url, 'get'));
}

export default function* getSkuTotalAmountSaga() {
  yield takeLatest(getSkuTotalAmountActionConstants[Actions.REQUEST], getSkuTotalAmountHandler);
}
