import { FC } from 'react';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';
import { IModalProps } from 'components/modals/common/modal';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { SuccessErrorMessageModal } from 'components/modals/success-error-message-modal';
import { stripLeadingZeros } from 'utils/string-format';
import { Routes } from 'constants/routes.enum';
import { IOutOfStockSkuReorderInfo } from 'types/order-details';

import './add-out-of-stock-to-cart-error-modal.scss';

export interface IAddOutOfStockToCartErrorModalProps extends IModalProps {
  outOfStockSkus: IOutOfStockSkuReorderInfo[];
}

export const AddOutOfStockToCartErrorModal: FC<IAddOutOfStockToCartErrorModalProps> = ({
  isOpen,
  onClose,
  outOfStockSkus = [],
}) => {
  const contentstackPath = 'modals.0.add_out_of_stock_to_cart_error_modal.0';

  return (
    <SuccessErrorMessageModal
      isOpen={isOpen}
      onClose={onClose}
      type="error"
      messageId="MSG097"
      autoClose={false}
      className="add-out-of-stock-to-cart-error-modal"
    >
      <p className="add-out-of-stock-to-cart-error-modal__section-message">
        <ContentstackMessage type="error" messageId="MSG169" />
      </p>
      <ul className="add-out-of-stock-to-cart-error-modal__items-list">
        {outOfStockSkus.map(({ productKey, sku, skuName, similarItems }) => {
          return (
            <li className="add-out-of-stock-to-cart-error-modal__out-of-stock-sku" key={sku}>
              <div>
                <strong>{skuName}</strong>
                <p>
                  <ContentstackMessage type="notifications" messageId="MSG051b" /> {stripLeadingZeros(sku)}
                </p>
              </div>
              {!isEmpty(similarItems) && (
                <Link
                  to={
                    similarItems.length > 1
                      ? `${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku}`
                      : `${Routes.ProductDetailsPage}/${similarItems[0].productKey}?selectedSku=${similarItems[0].sku}`
                  }
                  target="_blank"
                  className="cor-button text"
                >
                  <ContentstackText contentKey={`${contentstackPath}.select_similar_button_label`} />
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </SuccessErrorMessageModal>
  );
};
