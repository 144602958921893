import React, { FC } from 'react';

import './icon-menu-arrow.scss';

export interface IIconMenuArrow {
  color?: string;
  dir?: 'left' | 'right' | 'up' | 'down';
  width?: number | 'auto';
  height?: number | 'auto';
}

export const IconMenuArrow: FC<IIconMenuArrow> = ({ color = 'black', dir = 'right', width, height = 14 }) => {
  const direction = {
    left: 180,
    right: 0,
    up: 90,
    down: 270,
  };

  // in order to prevent distorting the arrow, allow only width or height -- but not both.
  const iconWidth = !!width ? { width: `${width}px` } : {};
  const iconHeight = !width ? { height: `${height}px` } : {};

  return (
    <svg
      aria-hidden={true}
      className="icon-menu-arrow"
      viewBox="0 0 6 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transition: '', transform: `rotate(${direction[dir]}deg)` }}
      {...iconHeight}
      {...iconWidth}
    >
      <polygon
        fill={color}
        points="1.41421356 9.4176273 0 8.00341374 3.29441049 4.70862405 0 1.41421357 1.41421356 0 6.12302721 4.70881365"
      />
    </svg>
  );
};
