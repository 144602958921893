import qs from 'qs';
import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { ShoppingListsRequestTypes } from 'constants/shopping-lists-request-types.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import {
  getShoppingLists,
  getShoppingListsActionConstants,
  getMarketingShoppingLists,
  getMarketingShoppingListsActionConstants,
  getAllShoppingLists,
  getAllShoppingListsActionConstants,
} from 'store/shopping-lists/actions';

export interface IShoppingListsPayload {
  limit?: number;
  offset?: number;
  type?: ShoppingListsRequestTypes.FULL_DETAILS | ShoppingListsRequestTypes.WITHOUT_ITEMS_AND_PRICES;
}

export function* getShoppingListsHandler({ payload: { limit, offset, type } }: IAction<IShoppingListsPayload>) {
  yield put(
    doRequest(
      getShoppingLists,
      `${Endpoints.SHOPPING_LISTS}?${qs.stringify({
        limit,
        offset: offset || '',
        type,
        shoppingListType: 'STANDARD',
      })}`,
      'get'
    )
  );
}

export function* getMarketingShoppingListsHandler() {
  yield put(
    doRequest(
      getMarketingShoppingLists,
      `${Endpoints.SHOPPING_LISTS}?${qs.stringify({
        shoppingListType: 'MARKETING',
      })}`,
      'get'
    )
  );
}

export function* getAllShoppingListsHandler() {
  yield put(
    doRequest(
      getAllShoppingLists,
      `${Endpoints.SHOPPING_LISTS}?${qs.stringify({
        type: 'WITHOUT_ITEMS_AND_PRICES',
        shoppingListType: 'ALL',
      })}`,
      'get'
    )
  );
}

export default function* getShoppingListsSaga() {
  yield takeLatest(getShoppingListsActionConstants[Actions.REQUEST], getShoppingListsHandler);
  yield takeLatest(getAllShoppingListsActionConstants[Actions.REQUEST], getAllShoppingListsHandler);
  yield takeLatest(getMarketingShoppingListsActionConstants[Actions.REQUEST], getMarketingShoppingListsHandler);
}
