import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { deleteShoppingList, deleteShoppingListActionConstants } from 'store/shopping-lists/actions';
import { IAction } from 'types/actions';

export interface IDeleteShoppingListSagaHandlerPayload {
  shoppingListId: string;
  onSuccessCallBack?: () => void;
}

export function* deleteShoppingListOnSuccessSagaHandler({ payload }: IAction<IDeleteShoppingListSagaHandlerPayload>) {
  yield take(deleteShoppingListActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* deleteShoppingListSagaHandler({ payload }: IAction<IDeleteShoppingListSagaHandlerPayload>) {
  const { shoppingListId } = payload;
  yield put(doRequest(deleteShoppingList, `${Endpoints.SHOPPING_LISTS}/${shoppingListId}`, 'delete'));
}

export default function* deleteShoppingListSaga() {
  yield takeLatest(deleteShoppingListActionConstants[Actions.REQUEST], deleteShoppingListOnSuccessSagaHandler);
  yield takeLatest(deleteShoppingListActionConstants[Actions.REQUEST], deleteShoppingListSagaHandler);
}
