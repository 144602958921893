import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import './custom-radio-button.scss';

export interface ICustomRadioButtonProps extends PropsWithChildren {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  uncheckable?: boolean;
}

export const CustomRadioButton: FC<ICustomRadioButtonProps> = ({
  children,
  checked,
  disabled = false,
  onChange,
  uncheckable = false,
}) => {
  const labelClasses = classNames('custom-radio-button__label', {
    'custom-radio-button__label--disabled': disabled,
  });

  return (
    <label className="custom-radio-button">
      <input
        className="custom-radio-button__input"
        type={uncheckable ? 'checkbox' : 'radio'}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="custom-radio-button__checkmark" />
      <span className={labelClasses}>{children}</span>
    </label>
  );
};
