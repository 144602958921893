import { createSelector } from 'reselect';
import { ILocationCardProps } from 'components/locations-list/location-card';
import { IState } from 'store/root-reducer';
import { IAccountInformation, ICreditInformation } from 'types/user-info';
import { IAccountState, IPersonalInfoState, IEditingUserInformation, IUpdatedLocations } from './reducer';
import { ISalesRepInformationState } from './reducers/sales-rep-information';
import { IAccountProfileInfoState } from './reducers/account-information';
import { IOrderHistoryState } from './reducers/order-history';
import { IOrderHistoryProductsState } from './reducers/order-history-products';

export const selectAccountEntity = (state: IState): IAccountState => state.app.account;
export const selectAccountInformationEntity = createSelector(
  selectAccountEntity,
  ({ accountProfileInfo }): IAccountProfileInfoState => accountProfileInfo
);

export const selectAccountInformationLoading = createSelector(selectAccountInformationEntity, ({ loading }) => loading);
export const selectEditingUserInformation = createSelector(
  selectAccountEntity,
  ({ editingUserInformation }): IEditingUserInformation => editingUserInformation
);

export const selectUpdatedUserLocations = createSelector(
  selectEditingUserInformation,
  ({ updatedLocations }): IUpdatedLocations | undefined => updatedLocations
);

export const selectAccountLocations = createSelector(
  selectAccountInformationEntity,
  ({ accountLocations }): ILocationCardProps[] =>
    accountLocations.map(({ name, isDeleted, locationId, address: { address, city, state, zipCode } }) => ({
      name,
      isDeleted,
      locationId,
      street: address,
      city,
      state,
      zipCode,
    }))
);
export const selectRawAccountLocations = createSelector(
  selectAccountInformationEntity,
  ({ accountLocations }) => accountLocations
);
export const selectAccountInformation = createSelector(
  selectAccountInformationEntity,
  ({ accountInformation }): IAccountInformation => accountInformation
);

export const selectCreditInformation = createSelector(
  selectAccountInformationEntity,
  (accountInformation): ICreditInformation | undefined => accountInformation.creditInformation
);

export const selectPersonalInformation = createSelector(
  selectAccountInformationEntity,
  ({ personalInformation }): IPersonalInfoState => personalInformation
);
export const selectSalesRepInformationEntity = createSelector(
  selectAccountEntity,
  ({ salesRepInformation }): ISalesRepInformationState => salesRepInformation
);
export const selectOrderHistoryEntity = createSelector(
  selectAccountEntity,
  ({ orderHistory }: any): IOrderHistoryState => orderHistory
);
export const selectOrderHistoryProductsEntity = createSelector(
  selectAccountEntity,
  ({ orderHistoryProducts }: any): IOrderHistoryProductsState => orderHistoryProducts
);

export const selectOrderHistoryLoading = createSelector(selectOrderHistoryEntity, ({ loading }) => loading);

export const selectPaginationCurrent = createSelector(selectOrderHistoryEntity, ({ offset, limit }) => offset / limit);
export const selectPaginationTotal = createSelector(selectOrderHistoryEntity, ({ total, limit }) =>
  Math.ceil(total / limit)
);
export const selectCurrentOrdersRange = createSelector(selectOrderHistoryEntity, ({ offset, total, limit }) => {
  return [offset + 1, Math.min(total, offset + limit)];
});

export const selectOrderHistoryProductsLoading = createSelector(
  selectOrderHistoryProductsEntity,
  ({ loading }) => loading
);

export const selectAllOrderedProductSkusLoading = createSelector(
  selectAccountEntity,
  ({ orderHistoryProductsAllSkus }) => orderHistoryProductsAllSkus.loading
);

export const selectProductsPaginationCurrent = createSelector(
  selectOrderHistoryProductsEntity,
  ({ offset, limit }) => offset / limit
);
export const selectProductsPaginationTotal = createSelector(
  selectOrderHistoryProductsEntity,
  ({ totalProducts, limit }) => Math.ceil(totalProducts / limit)
);

export const selectOrderDetailsEntity = createSelector(
  selectAccountEntity,
  ({ orderDetails }: IAccountState) => orderDetails
);

export const selectActiveOrderView = createSelector(selectAccountInformationEntity, ({ activeOrderView }) => {
  return activeOrderView;
});

export const selectActivePaymentView = createSelector(selectAccountInformationEntity, ({ activePaymentView }) => {
  return activePaymentView;
});

export const selectActiveEditUserId = createSelector(selectAccountInformationEntity, ({ activeEditUserId }) => {
  return activeEditUserId;
});

export const selectIsProductsListBeingUpdated = createSelector(
  selectOrderHistoryProductsEntity,
  ({ isProductsListBeingUpdated }) => isProductsListBeingUpdated
);

export const selectInvitationEmailStatus = createSelector(
  selectAccountEntity,
  ({ isInvitationEmailSent }) => isInvitationEmailSent
);

export const selectUsersInLocationCount = createSelector(
  selectAccountInformationEntity,
  ({ amountOfUsersInLocations, totalUsersInAccount }) => ({
    current: amountOfUsersInLocations,
    total: totalUsersInAccount,
  })
);

export const selectAllOrderedProductSkus = createSelector(
  selectAccountEntity,
  ({ orderHistoryProductsAllSkus }) => orderHistoryProductsAllSkus.skuInfos
);
