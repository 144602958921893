import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContent } from 'hooks/use-content';
import { ProductsCarousel } from 'components/products-carousel';
import { selectCarouselProducts } from 'store/products/selectors';
import { IGetCarouselProductsPayload } from 'store/products/sagas/get-carousel-products';
import { getCarouselProducts } from 'store/products/actions';

interface IProductSku {
  sku: {
    product_sku: string;
  };
}

export const FeaturedProductsCarousel: FC = () => {
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();
  const productsSkus = getContentByKey<(IProductSku | never)[]>('page_content.products_carousel[0].products_skus', []);
  const autoplaySpeed = getContentByKey('page_content.products_carousel[0].autoplay_speed', 5000);
  const skuList = productsSkus.map((skuItem) => skuItem.sku.product_sku);
  const carouselProducts = useSelector(selectCarouselProducts);

  useEffect(() => {
    if (skuList.length > 0) {
      dispatch(
        getCarouselProducts.request<IGetCarouselProductsPayload>({ skus: skuList.join(',') })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productsSkus]);

  return (
    <ProductsCarousel
      id="featureProductCarousel"
      title={getContentByKey<string>('page_content.products_carousel[0].header_text', '')}
      products={carouselProducts}
      usageContext="featured-products"
      autoplay
      autoplaySpeed={autoplaySpeed}
    />
  );
};
