import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import {
  getCartInfo,
  addMultipleProductsToCart,
  addMultipleProductsToCartActionConstants,
} from 'store/shopping-cart/actions';
import { IAction } from 'types/actions';

export interface IMultipleLineItem {
  sku: string;
  quantity: number;
}

export interface IAddMultipleProductsToCartPayload {
  lineItems: IMultipleLineItem[];
  onErrorCallBack?: () => void;
  onSuccessCallBack?: () => void;
}

export function* addMultipleProductsToCartOnSuccessCallBackSagaHandler({
  payload,
}: IAction<IAddMultipleProductsToCartPayload>) {
  yield take(addMultipleProductsToCartActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* addMultipleProductsToCartOnFailCallBackSagaHandler({
  payload,
}: IAction<IAddMultipleProductsToCartPayload>) {
  yield take(addMultipleProductsToCartActionConstants[Actions.FAIL]);
  if (payload.onErrorCallBack) {
    payload.onErrorCallBack();
  }
}

export function* addMultipleProductsToCartSagaHandler({ payload }: IAction<IAddMultipleProductsToCartPayload>) {
  const { lineItems } = payload;
  yield put(
    doRequest<IAddMultipleProductsToCartPayload>(addMultipleProductsToCart, Endpoints.CART_ADD_MULTIPLE, 'patch', {
      lineItems,
    })
  );
}
export function* addMultipleProductsToCartSuccessSagaHandler() {
  yield put(doRequest(getCartInfo, Endpoints.CART, 'get'));
}

export default function* addMultipleProductsToCartSaga() {
  yield takeLatest(
    addMultipleProductsToCartActionConstants[Actions.REQUEST],
    addMultipleProductsToCartOnSuccessCallBackSagaHandler
  );
  yield takeLatest(
    addMultipleProductsToCartActionConstants[Actions.REQUEST],
    addMultipleProductsToCartOnFailCallBackSagaHandler
  );

  yield takeLatest(addMultipleProductsToCartActionConstants[Actions.REQUEST], addMultipleProductsToCartSagaHandler);
  yield takeLatest(
    addMultipleProductsToCartActionConstants[Actions.SUCCESS],
    addMultipleProductsToCartSuccessSagaHandler
  );
}
